import { SocketConnection } from '@/common/services/sockets/socketTableConnection';
import { TNullable } from '@/common/types';

import { ETableSocketEvents } from '#/services/sockets/hooks/event-hooks/constants';

import { rouletteSocket } from './SocketConnection';

export class RouletteController {
   private socket: SocketConnection;

   constructor(socket: SocketConnection) {
      this.socket = socket;
   }

   public sendDealerFeedback = (feedbackData: {
      feedbackId: string;
      rating: number;
      comment: TNullable<string>;
   }): void => {
      this.socket.emit(ETableSocketEvents.DealerFeedbackLeft, feedbackData);
   };
}

export const rouletteEventEmitController = new RouletteController(rouletteSocket);
