import { useEffect, useState } from 'react';
import { StreamChat, DefaultGenerics, Channel } from 'stream-chat';

import { TNullable } from '@/common/types';

import { connectChat } from '../connectChat';

interface IConnectChat {
   isAdmin?: boolean;
   physicalTableUUID: string;
   userId: string;
   userName: string;
   userToken: string;
}
export const useConnectChat = ({
   userId,
   userName,
   userToken,
   isAdmin,
   physicalTableUUID,
}: IConnectChat) => {
   const [chat, setChat] = useState<
      TNullable<{
         chat: StreamChat<DefaultGenerics>;
         channel: Channel<DefaultGenerics>;
      }>
   >(null);

   useEffect(() => {
      const getChat = async () => {
         const { chat, channel } = await connectChat({
            userId,
            userName,
            userToken,
            isAdmin,
            physicalTableUUID,
         });

         setChat({ chat, channel });
      };

      getChat();
   }, []);

   return chat;
};
