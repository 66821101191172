// @ts-nocheck
import styled from 'styled-components/macro';

export const HorizontalWinnerListContainer = styled.div`
   --animation-start-point: calc(100vw + 10%);

   text-transform: uppercase;
   color: ${({ theme }) => theme.colors?.white};
   display: grid;
   justify-content: left;
   align-items: end;
   gap: 20px;
   grid-template-columns: auto auto;
   animation: 9s linear 1 normal forwards horizontal;
   width: 100%;

   @keyframes horizontal {
      from {
         transform: translateX(var(--animation-start-point));
      }
      to {
         transform: translateX(-100%);
      }
   }
`;

export const HorizontalWinnerListInner = styled.div`
   display: inline-flex;
   margin-right: 0px;
`;

export const Amount = styled.div`
   color: ${({ theme }) => theme.colors?.main};
   white-space: nowrap;
`;

export const Username = styled.div`
   white-space: nowrap;
   max-width: 100%;
`;

export const WinnerListItem = styled.div`
   --list-item-fz: 13px;
   font-size: var(--list-item-fz);
   padding-right: 20px;
   display: grid;
   gap: 8px;
   grid-template-columns: auto auto;
`;
