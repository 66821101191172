// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const QualityList = styled.div`
   display: flex;
   justify-content: space-between;
   margin-bottom: 15px;

   ${orientation.portrait`
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;

      & > label {
         margin-bottom: 10px;
      }
   `}

   ${orientation.mobileLandscape`
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;

      & > label {
         margin-bottom: 10px;
      }
   `}
`;
