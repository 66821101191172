import { getStatisticsByRange } from '@/common/modules/Statistics';
import { TStatistics } from '@/common/services/api/statisticsAPI/types';

import { useStatisticsActions } from '#/modules/Statistics/state';

export const useStatisticsUpdates = (): ((socket: TStatistics) => void) => {
   const { handleUpdateStatisticsFromSocket } = useStatisticsActions();

   return (statistics) => {
      const statisticsByRange = getStatisticsByRange(statistics);
      handleUpdateStatisticsFromSocket(statisticsByRange);
   };
};
