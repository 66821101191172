import { Control, EControl } from '../Control';

import * as S from './Control.styles';

interface ISoundControl {
  isMuted: boolean;
  toggleMute: () => void;
  variantButton: string;
}

export const SoundControl = ({ isMuted, toggleMute }: ISoundControl) => (
  <S.StyledButton isMuted={isMuted}>
    <Control
      dataTestId="sound-control"
      size="44px"
      onClick={toggleMute}
      // !IMPORTANT! firstly sound should be set and then be played
      playTapSound={() => {}}
      className="unmute_control"
      variant={isMuted ? EControl.MuteVolume : EControl.UnmuteVolume}
    />
  </S.StyledButton>
);
