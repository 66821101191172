// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { applyZIndex } from '@/common/ui/theme/mixins';

import { orientation } from '../theme/mixins';

export const ModalActionsWrapper = styled.div`
  --width: 356px;
  --height: 216px;

  ${applyZIndex('feedback')};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  background: rgba(3, 3, 3, 0.8);
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.3),
    0 12px 24px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: var(--width);

  ${({ isFeedbackScreenShown }) =>
    isFeedbackScreenShown &&
    css`
      height: var(--height);
    `};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    border-radius: 4px 4px 0 0;
  }

  ${orientation.desktopLandscape`
    position: absolute;
    top: 20px;
    left: 0;
    transform: none;
   `}

  ${orientation.portrait`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  `}

  ${orientation.mobileLandscape`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  `}
`;

export const Title = styled.h4`
  grid-area: title;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  color: #fff;
`;

export const Message = styled.div`
  grid-area: message;
  text-align: center;
  font-style: normal;
  font-size: 14px;
  line-height: 140%;
  color: #fff;
`;

export const ModalContent = styled.div`
  display: grid;
  gap: 10px;
  width: 100%;
  grid-auto-columns: 100%;
  grid-template-areas:
    'title'
    'message'
    'content'
    'button';
`;

export const Content = styled.div`
  grid-area: content;
`;

export const Button = styled.button`
  --btn-padding: 8px 40px;

  grid-area: button;
  width: fit-content;
  padding: var(--btn-padding);
  background: none;
  font-size: 14px;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.main};
  margin: 0 auto;

  ${({ theme, disabled }) =>
    disabled &&
    css`
      border-color: ${theme.colors.grey};
      opacity: 0.6;
      pointer-events: none;
    `};

  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const ActionIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 10px;
`;
