import { useTranslation } from '#/translates';

import { BetConfirmedNotificationView } from '../BetConfirmedNotificationView/BetConfirmedNotificationView';

export const SendPartialBetsNotification = () => {
   const { t } = useTranslation();

   return (
      <BetConfirmedNotificationView title={t('notifications.betConfirmed.success.sendSomeBets')} />
   );
};
