import { useState } from 'react';

import { TNullable } from '@/common/types';

export const useSwipeDirection = ({ onTopSwipeCallback }: { onTopSwipeCallback: () => void }) => {
   const [touchStart, setTouchStart] = useState<TNullable<number>>(null);
   const [touchEnd, setTouchEnd] = useState<TNullable<number>>(null);

   const minSwipeDistance = 50;

   const onTouchStart = (event: TouchEvent): void => {
      setTouchEnd(null);
      setTouchStart(event.targetTouches[0].clientY);
   };

   const onTouchMove = (event: TouchEvent): void => {
      setTouchEnd(event.targetTouches[0].clientY);
   };

   const onTouchEnd = (): void => {
      if (!touchStart || !touchEnd) {
         return;
      }

      const distance = touchStart - touchEnd;
      const isTopSwipe = distance > minSwipeDistance;
      const isBottomSwipe = distance < -minSwipeDistance;

      if (isTopSwipe || isBottomSwipe) {
         isTopSwipe && onTopSwipeCallback();
      }
   };

   return {
      onTouchStart,
      onTouchMove,
      onTouchEnd,
   };
};
