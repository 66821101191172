import { useEffect, useState } from 'react';

export const useCurrentTime = ({
   initialTime = new Date(),
   getServerTimeDelta,
}: {
   initialTime?: Date;
   getServerTimeDelta: () => Promise<number>;
}) => {
   const [currentTime, setCurrentTime] = useState(initialTime);

   useEffect(() => {
      let interval;

      getServerTimeDelta().then((delta) => {
         interval = setInterval(() => {
            setCurrentTime(new Date(Date.now() + delta));
         }, 1000);
      });

      return () => clearInterval(interval);
   }, []);

   return currentTime;
};
