import { FC } from 'react';

import { useSelectedChipSelector } from '@/common/modules/ChipTray';
import { IPlayChipSound } from '@/common/entities';

import { mapConfigCommonBets } from '#/core';
import { betsCreator } from '#/core/betsCreator';
import { useHoverSpecialBet } from '#/modules/Favourite/components/SpecialBets/hooks/useHoverSpecialBet';
import { getSpecialBets } from '#/modules/Favourite/components/StraightUpBets';
import { useTranslation } from '#/translates';
import { withChipSound } from '#/hocs';

import { CenterStatisticsBar } from '../../CenterStatisticsBar';
import { StatisticsBar } from '../../StatisticsBar';

import { IStatisticByColor } from './types';
import * as S from './StatisticByColor.styles';

export const StatisticByColor: FC<IStatisticByColor & IPlayChipSound> = ({
   red,
   black,
   green,
   playChipSound,
}) => {
   const { t } = useTranslation();
   const { specialBetsObject } = getSpecialBets();
   const hoverChildElementsRef = useHoverSpecialBet();
   const { value: chipValue } = useSelectedChipSelector();

   const handleStatisticByColorClick = (cellNumber: number) => (): void => {
      const { id, type, numbers } = mapConfigCommonBets[cellNumber];
      betsCreator.makeCommonBet({ id, type, numbers, amount: chipValue });
      playChipSound();
   };

   return (
      <S.StatisticByColorWrapper ref={hoverChildElementsRef}>
         <StatisticsBar
            dataTestId="statistic-black"
            label={t('bettingMap.black')}
            color="#82828233"
            direction="left"
            percentage={black.percentage}
            onClick={handleStatisticByColorClick(black.betId)}
            highlightCells={specialBetsObject[black.betId].numbers}
         />
         <CenterStatisticsBar
            dataTestId="statistic-zero"
            label={'0'}
            color="#23b04e"
            highlightCells={[0]}
            percentage={green.percentage}
            onClick={handleStatisticByColorClick(0)}
         />
         <StatisticsBar
            dataTestId="statistic-red"
            label={t('bettingMap.red')}
            color="#ff0000"
            direction="right"
            percentage={red.percentage}
            onClick={handleStatisticByColorClick(red.betId)}
            highlightCells={specialBetsObject[red.betId].numbers}
         />
      </S.StatisticByColorWrapper>
   );
};

export const StatisticByColorWithChipSound = withChipSound(StatisticByColor);
