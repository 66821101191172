// @ts-nocheck
import styled from 'styled-components/macro';

export const Label = styled.label`
   display: flex;
   align-items: center;
   cursor: pointer;
`;

export const Input = styled.input`
   display: grid;
   place-items: center;
   position: relative;
   appearance: none;
   width: 19px;
   height: 19px;
   border-radius: 50%;
   margin-right: 10px;
   cursor: pointer;
   background-color: #3d3d3d;

   &:checked {
      &:before {
         content: '';
         height: 11px;
         width: 11px;
         border-radius: 50px;
         background-color: ${({ theme }) => theme.colors.yellow};
      }
   }
`;
