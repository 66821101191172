import {
   AutoplayControl,
   StatisticsControl,
   SwitchTrackControl,
} from '#/modules/Control/components';
import { VolumeControl } from '#/modules/Control/components/VolumeControl';
import { CollapseBettingAreaControl } from '#/modules/Control/components/CollapseBettingAreaControl';
import { generalVolume } from '#/core/sounds';

import * as S from './RightColumn.styles';

export const RightColumn = () => (
   <S.RightColumnContainer>
      <VolumeControl generalVolume={generalVolume} />
      <S.CollapseTrackControl>
         <CollapseBettingAreaControl />
      </S.CollapseTrackControl>
      <S.RightControlsContainer>
         <AutoplayControl />
         <StatisticsControl />
         <SwitchTrackControl />
      </S.RightControlsContainer>
   </S.RightColumnContainer>
);
