export const colors = {
   hot: '#EC1C2499',
   cold: '#00ADEE99',
   grey: '#A6A8AB',
   hover: '#A6A8AB80',
};

export const config = [
   {
      number: 0,
      path: 'M181.87 28.23C177.91 27.9 173.9 27.72 169.85 27.72C165.8 27.72 161.8 27.9 157.83 28.23L169.85 169.41L181.87 28.23Z',
   },

   {
      number: 1,
      path: 'M63.1201 263.35C68.4301 269.4 74.2501 274.99 80.5101 280.07L169.86 169.41L63.1201 263.36V263.35Z',
   },

   {
      number: 2,
      path: 'M296.55 105.53C292.9 98.34 288.65 91.5 283.88 85.08L169.86 169.41L296.55 105.53Z',
   },

   {
      number: 3,
      path: 'M134.14 32.25C126.3 34.28 118.72 36.96 111.47 40.24L169.86 169.41L134.14 32.25Z',
   },

   {
      number: 4,
      path: 'M267.96 67.05C262.17 61.51 255.91 56.46 249.24 51.96L169.86 169.41L267.96 67.05Z',
   },

   {
      number: 5,
      path: 'M145.81 309.68C153.63 311.01 161.66 311.72 169.86 311.72V169.41L145.81 309.68Z',
   },

   {
      number: 6,
      path: 'M308.75 199.34C310.4 191.59 311.41 183.6 311.73 175.43L169.85 169.4L308.75 199.33V199.34Z',
   },

   {
      number: 7,
      path: 'M55.8302 85.08C51.0502 91.5 46.8102 98.34 43.1602 105.53L169.85 169.41L55.8302 85.08Z',
   },

   {
      number: 8,
      path: 'M217.27 303.6C224.92 300.89 232.27 297.55 239.25 293.63L169.86 169.42L217.27 303.61V303.6Z',
   },

   {
      number: 9,
      path: 'M27.9805 175.44C28.3005 183.61 29.3105 191.59 30.9605 199.35L169.86 169.42L27.9805 175.45V175.44Z',
   },

   {
      number: 10,
      path: 'M169.86 311.71C178.06 311.71 186.09 311.01 193.91 309.67L169.86 169.4V311.71Z',
   },

   {
      number: 11,
      path: 'M259.21 280.07C265.47 275 271.29 269.4 276.6 263.35L169.86 169.4L259.21 280.06V280.07Z',
   },

   {
      number: 12,
      path: 'M90.4798 51.96C83.8198 56.46 77.5598 61.52 71.7598 67.05L169.86 169.41L90.4798 51.96Z',
   },

   {
      number: 13,
      path: 'M290.92 243.95C295.11 237.13 298.74 229.93 301.75 222.41L169.86 169.41L290.92 243.95Z',
   },

   {
      number: 14,
      path: 'M37.96 222.41C40.97 229.93 44.6 237.13 48.79 243.95L169.85 169.41L37.96 222.41Z',
   },

   {
      number: 15,
      path: 'M228.25 40.24C221 36.96 213.42 34.28 205.57 32.25L169.86 169.41L228.25 40.24Z',
   },

   {
      number: 16,
      path: 'M100.47 293.62C107.45 297.54 114.8 300.88 122.45 303.59L169.86 169.4L100.47 293.61V293.62Z',
   },

   {
      number: 17,
      path: 'M310.68 151.38C309.64 143.3 307.92 135.44 305.58 127.85L169.86 169.41L310.68 151.38Z',
   },

   {
      number: 18,
      path: 'M34.14 127.84C31.8 135.43 30.08 143.29 29.04 151.37L169.86 169.4L34.14 127.84Z',
   },

   {
      number: 19,
      path: 'M249.24 51.96C242.62 47.49 235.6 43.56 228.25 40.24L169.86 169.41L249.24 51.96Z',
   },

   {
      number: 20,
      path: 'M48.79 243.95C53.02 250.83 57.81 257.32 63.11 263.35L169.85 169.4L48.79 243.95Z',
   },

   {
      number: 21,
      path: 'M283.88 85.08C279.08 78.63 273.76 72.6 267.96 67.06L169.86 169.42L283.88 85.09V85.08Z',
   },

   {
      number: 22,
      path: 'M29.0296 151.38C28.2596 157.38 27.8496 163.5 27.8496 169.72C27.8496 171.64 27.8996 173.54 27.9696 175.44L169.85 169.41L29.0296 151.38Z',
   },

   {
      number: 23,
      path: 'M193.91 309.68C201.95 308.31 209.75 306.26 217.27 303.6L169.86 169.41L193.91 309.68Z',
   },

   {
      number: 24,
      path: 'M122.45 303.6C129.97 306.26 137.77 308.31 145.81 309.68L169.86 169.41L122.45 303.6Z',
   },

   {
      number: 25,
      path: 'M305.58 127.84C303.19 120.1 300.16 112.64 296.55 105.53L169.86 169.41L305.58 127.85V127.84Z',
   },

   {
      number: 26,
      path: 'M157.84 28.23C149.73 28.91 141.81 30.26 134.14 32.25L169.85 169.41L157.83 28.23H157.84Z',
   },

   {
      number: 27,
      path: 'M169.86 169.41L301.75 222.41C304.71 215.01 307.06 207.3 308.75 199.34L169.85 169.41H169.86Z',
   },

   {
      number: 28,
      path: 'M71.7598 67.05C65.9598 72.59 60.6298 78.62 55.8398 85.07L169.86 169.4L71.7598 67.05Z',
   },

   {
      number: 29,
      path: 'M43.1599 105.53C39.5499 112.64 36.5199 120.1 34.1299 127.84L169.85 169.4L43.1599 105.53Z',
   },

   {
      number: 30,
      path: 'M239.25 293.62C246.3 289.66 252.97 285.13 259.21 280.07L169.86 169.41L239.25 293.62Z',
   },

   {
      number: 31,
      path: 'M30.96 199.34C32.65 207.3 35 215 37.96 222.41L169.85 169.41L30.96 199.34Z',
   },

   {
      number: 32,
      path: 'M205.57 32.25C197.9 30.26 189.98 28.91 181.87 28.23L169.85 169.41L205.57 32.25Z',
   },
   {
      number: 33,
      path: 'M80.5 280.07C86.74 285.13 93.42 289.67 100.46 293.62L169.85 169.41L80.5 280.07Z',
   },

   {
      number: 34,
      path: 'M311.73 175.44C311.81 173.54 311.85 171.64 311.85 169.72C311.85 163.51 311.45 157.38 310.67 151.38L169.85 169.41L311.73 175.44Z',
   },
   {
      number: 35,
      path: 'M111.47 40.24C104.12 43.56 97.1005 47.49 90.4805 51.96L169.86 169.41L111.47 40.24Z',
   },

   {
      number: 36,
      path: 'M276.6 263.35C281.9 257.31 286.7 250.82 290.92 243.95L169.86 169.41L276.6 263.36V263.35Z',
   },
];
