import { TChip } from '@/common/ui/Chip/entities';

import { TLimits } from '#/modules/TableCapacity';
import { ETypeBet } from '#/state/features/bets';

export const getStraightBetMinValue = ({
   chips,
   betTypeLimits,
}: {
   chips: TChip[];
   betTypeLimits: TLimits;
}) => {
   const straightBetLimits = betTypeLimits[ETypeBet.STRAIGHT];

   if (!straightBetLimits || !chips.length) {
      return null;
   }
   const minChipValue = chips.find(({ value }) => value === straightBetLimits.min);

   if (!minChipValue) {
      return null;
   }

   return minChipValue;
};
