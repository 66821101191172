import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { handleSavePreviousRoundBets } from './savePreviousRoundBetsMiddleware/effect';

export const savePreviousRoundBetsMiddleware = createListenerMiddleware();

const savePreviousRoundBetsActionType = 'savePreviousRoundBets';
export const savePreviousRoundBetsAction = createAction(savePreviousRoundBetsActionType);

savePreviousRoundBetsMiddleware.startListening({
   type: savePreviousRoundBetsActionType,
   effect: handleSavePreviousRoundBets,
});
