import { memo } from 'react';

import { FadeBox } from '@/common/ui/FadeBox';

import { FADE_DURATION } from '../../constants/animations';

import { SendAllBetsNotification, SendPartialBetsNotification } from './components';
import { useShowBetConfirmedNotification } from './hooks';
import { useBetConfirmed } from './state';
import * as S from './BetConfirmedNotification.styles';

export const BetConfirmedNotification = memo(() => {
   const { isSendAllBets, isShowNotification } = useBetConfirmed();
   useShowBetConfirmedNotification();

   return (
      <FadeBox duration={FADE_DURATION} isVisible={isShowNotification} style={S.FadeBoxStyles}>
         {isSendAllBets ? <SendAllBetsNotification /> : <SendPartialBetsNotification />}
      </FadeBox>
   );
});

BetConfirmedNotification.displayName = 'BetConfirmedNotification';
