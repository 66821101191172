import { FC, memo } from 'react';

import { Chip } from '@/common/ui';
import { autoKeys } from '@/common/utils';

import { useGameConfigStore } from '#/services/api/gameConfigApiSlice/state';

import { useHighlightChipByMinLimit } from '../../../hooks';
import { IRaceTrackChip } from '../../types';

interface IRaceTrackChips {
   chips: IRaceTrackChip[];
}

export const RaceTrackChips: FC<IRaceTrackChips> = memo(({ chips }) => {
   const isHighlightChip = useHighlightChipByMinLimit();
   const { gameConfig } = useGameConfigStore();

   return (
      <>
         {autoKeys(
            chips.map(({ x, y, betValue, size, type, cellId }: IRaceTrackChip) => {
               const whenChipHasCoordsAndSize = x && y && size;

               if (whenChipHasCoordsAndSize) {
                  return (
                     <Chip
                        data-testid={`racetrack-chip-place-id-${cellId}-amount-${betValue}`}
                        chips={gameConfig.chipTray}
                        highlightShadow={isHighlightChip({ betValue, type })}
                        x={x}
                        y={y}
                        chipNumber={betValue}
                        width={size}
                        height={size}
                        style={{
                           pointerEvents: 'none',
                        }}
                     />
                  );
               }
            }),
         )}
      </>
   );
});

RaceTrackChips.displayName = 'RaceTrackChips';
