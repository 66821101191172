// @ts-nocheck
import styled from 'styled-components/macro';

export const Icon = styled.svg`
  max-width: 70px;
  max-height: 70px;
  width: 100%;
  height: 100%;
  fill: none;
`;
