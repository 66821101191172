import { usePlayersCount } from '#/state/features/playersCount';

import { PlayerCountEvent } from './schemes';

export const usePlayersCountEvent = (): ((socket: PlayerCountEvent) => void) => {
   const { handleUpdatePlayersCount } = usePlayersCount();

   return ({ count }) => {
      handleUpdatePlayersCount(count);
   };
};
