// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const StatisticsBarWrapper = styled.div`
   width: ${({ percentage }) => percentage}%;
   min-width: min-content;
   transition: width 0.3s linear;
`;

export const Progress = styled.div`
   width: 100%;
   height: 35px;
   display: flex;
   align-items: center;
   justify-content: ${({ direction }) => direction};
   padding: 0 5px;
   background-color: ${({ color }) => color};
   transition: 0.5s ease-in-out;
   cursor: pointer;

   ${orientation.mobileLandscape`
      height: 22px;
   `}
`;

export const Percentage = styled.div`
   margin-bottom: 3px;
   color: #fff;
   text-align: ${({ direction }) => direction};
   font-size: 16px;

   ${orientation.mobileLandscape`
      font-size: 12px;
   `}
`;

export const Label = styled.div`
   color: #ddd;
   font-size: 16px;

   ${orientation.mobileLandscape`
      font-size: 12px;
   `}
`;
