import { Bet, TableCapacity, TableCapacityProgressBar, WinHistory } from '#/modules';
import { Balance } from '#/modules/Balance/Balance';
import { WinHistoryWithTableCapacityGroup } from '#/layout/componentsGroup';

import * as S from './BottomColumn.styles';

export const BottomColumn = () => (
   <S.BottomColumnContainer>
      <S.TopRow>
         <WinHistoryWithTableCapacityGroup>
            <WinHistory />
            <TableCapacityProgressBar />
         </WinHistoryWithTableCapacityGroup>
      </S.TopRow>
      <S.BottomRow>
         <Balance />
         <Bet />
         <TableCapacity />
      </S.BottomRow>
   </S.BottomColumnContainer>
);
