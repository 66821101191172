import { useLayoutEffect } from 'react';

const MAIN_CONTAINER_SELECTOR = '.main-container';
const BETTING_CONTAINER_SELECTOR = '#betting-container';
const VIDEO_CONTAINER_SELECTOR = '#video-container';

const createCollapsedBettingAreaScale = () => {
   const videoContainer = document.querySelector(VIDEO_CONTAINER_SELECTOR);
   const mainContainer = document.querySelector(MAIN_CONTAINER_SELECTOR);
   const bettingContainer = document.querySelector(BETTING_CONTAINER_SELECTOR);

   if (!mainContainer || !bettingContainer || !videoContainer) {
      return;
   }

   const videoHeight = videoContainer.clientHeight + 70;
   const browserHeight = mainContainer.clientHeight - videoHeight;
   const elementHeight = bettingContainer.clientHeight;

   // // Calculate the scale factor
   const scaleFactor = browserHeight / elementHeight;

   const $variablesContainer = document.querySelector(':root');

   if ($variablesContainer) {
      ($variablesContainer as HTMLElement).style.setProperty(
         '--betting-container-scale',
         `${scaleFactor}`,
      );
   }
};

export const useBettingAreaScale = () => {
   useLayoutEffect(() => {
      window.addEventListener('resize', createCollapsedBettingAreaScale);
      createCollapsedBettingAreaScale();

      return () => window.removeEventListener('resize', createCollapsedBettingAreaScale);
   }, []);
};
