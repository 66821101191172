import { useEffect } from 'react';

import { useWindowEvent } from '@/common/hooks';

const _calculateScale = ({ wrapper, element }): number => {
   return Math.min(
      wrapper.clientWidth / element.clientWidth,
      wrapper.clientHeight / element.clientHeight,
   );
};

const _createResizeParams = ({ $element, $wrapper }) => ({
   element: {
      clientWidth: $element.clientWidth,
      clientHeight: $element.clientHeight,
   },
   wrapper: {
      clientWidth: $wrapper.clientWidth,
      clientHeight: $wrapper.clientHeight,
   },
});

const VIDEO_CONTAINER_SELECTOR = '.video-container';
const MAIN_CONTAINER_SELECTOR = '.main-container';

const _doResize = ({ element, wrapper }): void => {
   const areElementsReady: boolean = element.clientWidth > 0 && element.clientHeight > 0;

   if (areElementsReady) {
      const $variablesContainer = document.querySelector(':root');

      if ($variablesContainer) {
         const scale: number = _calculateScale({ wrapper, element });
         ($variablesContainer as HTMLElement).style.setProperty('--scale', `${scale}`);
      }
   }
};

export const useScale = (): void => {
   useEffect(() => {
      const $element = document.querySelector(VIDEO_CONTAINER_SELECTOR);
      const $wrapper = document.querySelector(MAIN_CONTAINER_SELECTOR);

      if (!$element || !$wrapper) {
         return;
      }

      const areElementsReady: boolean = $element.clientWidth > 0 && $element.clientHeight > 0;

      if (areElementsReady) {
         window.addEventListener('resize', () =>
            _doResize(_createResizeParams({ $element, $wrapper })),
         );

         // added initial scale
         _doResize(_createResizeParams({ $element, $wrapper }));
      }

      return (): void => {
         // @ts-ignore
         window.removeEventListener('resize', _doResize);
      };
   }, []);
};

const mavibetRescale = ($element, $wrapper): void => {
   const rescale = (): void => _doResize(_createResizeParams({ $element, $wrapper }));

   // rescale our UI on the latest tick
   setTimeout(rescale, 0);

   // rescale our UI with delay because of mavibet script inconsistency
   setTimeout(rescale, 100);
};

export const useMobileLandscapeScale = ({
   isLayoutGridLoaded,
}: {
   isLayoutGridLoaded: boolean;
}) => {
   const handleMobileLandscapeScale = () => {
      const $element = document.querySelector(VIDEO_CONTAINER_SELECTOR);
      const $wrapper = document.querySelector(MAIN_CONTAINER_SELECTOR);

      if (!$element || !$wrapper) {
         return;
      }

      const areElementsReady: boolean = $element.clientWidth > 0 && $element.clientHeight > 0;

      if (areElementsReady) {
         _doResize(_createResizeParams({ $element, $wrapper }));

         // rescale after mavibet script updates
         mavibetRescale($element, $wrapper);
      }
   };

   useEffect(() => {
      if (isLayoutGridLoaded) {
         handleMobileLandscapeScale();
      }
   }, [handleMobileLandscapeScale, isLayoutGridLoaded]);

   useWindowEvent('resize', handleMobileLandscapeScale);
};
