import { TNullable } from '@/common/types';

import { TCallBet, TCommonBet } from '#/state/features/bets/types';
import { isCallBetType } from '#/state/features/bets/type-guards';

import { mergeBetChips } from '../mergeBetChips';
import { sumAmountTwoCallBets } from '../sumAmountTwoCallBets';

const _sumCommonBets = (summedBet: TCommonBet, newBet: TCommonBet) => {
   const summedBetCopy = { ...summedBet };

   summedBetCopy.amount += newBet.amount;
   summedBetCopy.chips = mergeBetChips(summedBetCopy.chips, newBet.chips);

   return summedBetCopy;
};

export const sumBetsById = (bets): TCommonBet[] => {
   const summedBets = bets.reduce((total, bet) => {
      const cachedBet: TNullable<TCallBet | TCommonBet> = total?.[bet.id] ?? null;

      if (cachedBet) {
         const { id } = cachedBet;

         if (isCallBetType(cachedBet)) {
            total[id] = sumAmountTwoCallBets(cachedBet, bet as TCallBet);
         } else {
            total[id] = _sumCommonBets(cachedBet, bet as TCommonBet);
         }
      } else {
         total[bet.id] = { ...bet };
      }

      return total;
   }, {});

   return Object.values(summedBets);
};
