import { FC, useState } from 'react';

import { autoKeys } from '@/common/utils';
import { useSelectedChipSelector } from '@/common/modules/ChipTray/state';
import { IPlayChipSound } from '@/common/entities';

import { betsCreator } from '#/core/betsCreator';
import { callBetsConfig } from '#/core/config';
import { useHoverSpecialBet } from '#/modules/Favourite/components/SpecialBets/hooks/useHoverSpecialBet';
import { LimitTooltipWrapper } from '#/modules/LimitTooltip';
import { WaitForTheNextStateTooltip } from '#/modules/Tooltips/TooltipPortal/components/WaitForTheNextStateTooltip';
import { ETypeBet } from '#/state/features/bets';
import { useSmallVideoMode } from '#/modules/LiveStream/state';
import { withChipSound } from '#/hocs';

import { IStatistic, IStatisticType } from './types';
import * as S from './StatisticType.styles';

const getSideBetHightLightCells = (statisticType: IStatistic) => {
   return callBetsConfig[ETypeBet[statisticType.type]]?.reduce(
      (acc: number[], val: { id: number; numbers: number[]; type: ETypeBet }) => [
         ...acc,
         ...(val?.numbers ?? []),
      ],
      [],
   );
};

export const StatisticType: FC<IStatisticType & IPlayChipSound> = ({
   statisticsTypes,
   playChipSound,
}) => {
   const { isSmallVideo } = useSmallVideoMode();
   const hoverChildElementsRef = useHoverSpecialBet();
   const { value: chipValue } = useSelectedChipSelector();
   const [type, setType] = useState<IStatistic>(statisticsTypes[0]);

   const handleSetBetClick = (statisticType: IStatistic): void => {
      setType(statisticType);
      betsCreator.makeCallBet({
         type: ETypeBet[statisticType.type],
         amount: chipValue,
      });
      playChipSound();
   };

   return (
      <S.Wrapper ref={hoverChildElementsRef}>
         <S.StatisticTypeWrapper>
            {autoKeys(
               statisticsTypes.map((statisticType: IStatistic) => (
                  <LimitTooltipWrapper>
                     {({ handleShowMaxBetTooltip }) => (
                        <WaitForTheNextStateTooltip>
                           <S.StatisticType
                              data-testid={`statistic-${statisticType.type.toLowerCase()}`}
                              isSmallVideo={isSmallVideo}
                              handleAction={(e) => {
                                 handleSetBetClick(statisticType);
                                 // TODO investigate reason of array instead of event object
                                 handleShowMaxBetTooltip(e[0]);
                              }}
                              $isActive={type.type === statisticType.type}
                              data-highlight-cells={getSideBetHightLightCells(statisticType)}
                           >
                              <S.StatisticLabel>
                                 {statisticType.label.toLowerCase()}
                              </S.StatisticLabel>
                              <S.StatisticValue>{`${statisticType.value}%`}</S.StatisticValue>
                           </S.StatisticType>
                        </WaitForTheNextStateTooltip>
                     )}
                  </LimitTooltipWrapper>
               )),
            )}
         </S.StatisticTypeWrapper>
      </S.Wrapper>
   );
};

export const StatisticTypeWithChipSound = withChipSound(StatisticType);
