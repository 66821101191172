import React from 'react';

import { useGameState } from '@/common/state/gameState';

import { Loader } from '#/modules/Loader';
import { useTranslation } from '#/translates';
import { useSmallVideoMode } from '#/modules/LiveStream/state';

import { useVideoLoading } from './state';
import * as S from './VideoLoadingScreen.styles';

export const VideoLoadingScreen = () => {
   const { t } = useTranslation();
   const { isSmallVideo } = useSmallVideoMode();
   const { isShowVideoLoader } = useVideoLoading();
   const { isWaitingForConnectionGameStatus } = useGameState();

   if (!isShowVideoLoader || isWaitingForConnectionGameStatus) {
      return null;
   }

   return (
      <>
         <S.Mask data-testid="video" />
         <Loader isCentered={isSmallVideo} title={t('video.pleaseWait')} />
      </>
   );
};
