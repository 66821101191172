import { z } from 'zod';

const betSchema = z.object({
   uuid: z.string(),
   created: z.string(),
   updated: z.string(),
   amount: z.number(),
   currency: z.string(),
   round_id: z.string(),
   user_id: z.string(),
   game_table_id: z.string(),
   placed_time: z.string(),
   chips: z.array(z.string()),
});

export const straightBetParams = betSchema.and(
   z.object({
      type: z.literal('straight'),
      params: z.object({
         number: z.string(),
      }),
   }),
);

export const columnBetParams = betSchema.and(
   z.object({
      type: z.literal('column'),
      params: z.object({
         column: z.number(),
      }),
   }),
);

export const cornerBetParams = betSchema.and(
   z.object({
      type: z.literal('corner'),
      params: z.object({
         corner: z.string(),
      }),
   }),
);

export const splitBetParams = betSchema.and(
   z.object({
      type: z.literal('split'),
      params: z.object({
         split: z.string(),
      }),
   }),
);

export const dozenBetParams = betSchema.and(
   z.object({
      type: z.literal('dozen'),
      params: z.object({
         dozen: z.number(),
      }),
   }),
);

export const highLowBetParams = betSchema.and(
   z.object({
      type: z.literal('highLow'),
      params: z.object({
         highLow: z.union([z.literal('low'), z.literal('high')]),
      }),
   }),
);

export const parityBetParams = betSchema.and(
   z.object({
      type: z.literal('parity'),
      params: z.object({
         parity: z.union([z.literal('even'), z.literal('odd')]),
      }),
   }),
);

export const colorBetParams = betSchema.and(
   z.object({
      type: z.literal('color'),
      params: z.object({
         color: z.union([z.literal('red'), z.literal('black')]),
      }),
   }),
);

export const lineBetParams = betSchema.and(
   z.object({
      type: z.literal('line'),
      params: z.object({
         line: z.string(),
      }),
   }),
);

export const streetBetParams = betSchema.and(
   z.object({
      type: z.literal('street'),
      params: z.object({
         street: z.string(),
      }),
   }),
);

export const callBetParams = betSchema.and(
   z.object({
      type: z.union([
         z.literal('tiers'),
         z.literal('orphelins'),
         z.literal('voisin'),
         z.literal('jeuZero'),
      ]),
   }),
);

export const userBetsByRoundIdSchema = z
   .union([
      straightBetParams,
      columnBetParams,
      cornerBetParams,
      splitBetParams,
      dozenBetParams,
      highLowBetParams,
      parityBetParams,
      colorBetParams,
      lineBetParams,
      streetBetParams,
      callBetParams,
   ])
   .array();
