import { isValidError, isValidException, isValidNetworkError } from '../../errors';

class ResponseCreator {
   private readonly DEFAULT_ERROR_MESSAGE = 'Something went wrong';
   private readonly FETCH_ERROR_MESSAGE = 'Internet Connection Lost';

   createSuccessResponse = <T extends { data: unknown }>(data: T) => ({
      data: {
         isSuccessful: true,
         data: data.data,
         isException: false,
         exception: null,
         isError: false,
         error: null,
      },
   });

   createExceptionResponse = <T>(response: T) => ({
      error: {
         isSuccessful: false,
         data: null,
         isException: true,
         exception: isValidException(response)
            ? response.error.details.message
            : this.DEFAULT_ERROR_MESSAGE,
         isError: false,
         error: null,
      },
   });

   createErrorResponse = <T>(response: T) => {
      if (isValidNetworkError(response)) {
         return {
            error: {
               isSuccessful: false,
               data: null,
               isException: false,
               exception: null,
               isError: true,
               error: this.FETCH_ERROR_MESSAGE,
            },
         };
      }

      if (isValidError(response)) {
         return {
            error: {
               isSuccessful: false,
               data: null,
               isException: false,
               exception: null,
               isError: true,
               error: response.error.details.message,
            },
         };
      }

      return {
         error: {
            data: null,
            exception: null,
            error: this.DEFAULT_ERROR_MESSAGE,
            isError: true,
            isSuccessful: false,
            isException: false,
         },
      };
   };
}

export const responseCreator = new ResponseCreator();
