// will be fixed after adding tsconfig to packages folder
import { TStatistics } from '@/common/services/api/statisticsAPI/types';

import { checkIsCancelRound } from '../../../../utils/checkIsCancelRound';
import { winNumberReverseDTO } from '../../../../utils/winNumberDTO';

const _fromFractionalToIntegral = (obj) => {
   const newObj = {};

   for (const key in obj) {
      newObj[key] = Math.round(obj[key] * 100);
   }

   return newObj;
};

export const statisticsAdapter = ({
   colorOdds,
   numberOdds,
   columnOdds,
   thirdsOdds,
   halvesOdds,
   oddEvenOdds,
   frequentNumbers,
   specialBetsOdds,
}) => {
   const hotNumbers = frequentNumbers.hot.reduce((acc, { number }) => {
      if (checkIsCancelRound(number)) {
         return acc;
      }

      acc[number] = { type: 'hot' };

      return acc;
   }, {});
   const coldNumbers = frequentNumbers.cold.reduce((acc, { number }) => {
      if (checkIsCancelRound(number)) {
         return acc;
      }

      acc[number] = { type: 'cold' };

      return acc;
   }, {});

   const statisticsNumbers = numberOdds.map((item) => ({
      ...item,
      ...hotNumbers[item.number],
      ...coldNumbers[item.number],
   }));

   return {
      color: _fromFractionalToIntegral(colorOdds),
      dozen: _fromFractionalToIntegral(thirdsOdds),
      pair: _fromFractionalToIntegral(oddEvenOdds),
      column: _fromFractionalToIntegral(columnOdds),
      lowHigh: _fromFractionalToIntegral(halvesOdds),
      callBets: _fromFractionalToIntegral(specialBetsOdds),
      statisticsByPockets: statisticsNumbers?.map(({ odds, number, ...props }) => ({
         odds: odds * 100,
         number: winNumberReverseDTO(number),
         ...props,
      })),
   };
};

export const getStatisticsByRange = (statistics: TStatistics) => {
   const rangesMap = {
      0: 100,
      1: 200,
      2: 500,
      3: 1000,
   };

   return statistics?.reduce((acc, item, index) => {
      acc[rangesMap[index]] = statisticsAdapter(item.statistics);
      return acc;
   }, {});
};
