import { Box } from '@/common/ui';

import { LineStatistics } from '../StatisticsComponents';
import { StatisticsWheel } from '../StatisticsComponents/StatisticsWheel/StatisticsWheel';
import * as S from '../../Statistics.styles';

import { StatisticsBySections } from './components';
import { useSelectStatisticsByRange } from './hooks';

export const InnerStatistics = () => {
   const { statistics } = useSelectStatisticsByRange();

   if (!statistics) {
      return <S.EmptyStatistics />;
   }

   return (
      <S.InnerStatistics>
         <div>
            <Box marginBottom="20px">
               <LineStatistics />
            </Box>
            <S.StatisticsWheelContainer>
               <StatisticsWheel />
            </S.StatisticsWheelContainer>
         </div>
         <StatisticsBySections />
      </S.InnerStatistics>
   );
};
