// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { isHoverSupports, orientation } from '@/common/ui/theme/mixins';

export const Round = styled.div`
   border-radius: 4px;
   color: ${({ theme }) => theme.colors.mediumGrey};
   width: 100%;
   padding: var(--autoplay-control-padding);
   display: flex;
   align-content: center;
   justify-content: center;
   font-size: var(--autoplay-control-fz);
   cursor: pointer;
   transition: 0.2s;
   position: relative;

   &:after {
      content: '';
      width: 1px;
      height: 22px;
      border-right: 1px solid ${({ theme }) => theme.colors.grey};
      position: absolute;
      right: 0;
   }

   &:last-of-type:after {
      border: none;
   }

   ${isHoverSupports`
      &:hover {
         color: ${({ theme }) => theme.colors.black[50]};
         background: ${({ theme }) => theme.colors.yellow};
      }
   `}

   ${({ isSelected }) =>
      isSelected &&
      css`
         color: ${({ theme }) => theme.colors.black[50]};
         background: ${({ theme }) => theme.colors.yellow};
      `};
`;

export const RoundControlsWrapper = styled.div`
   display: flex;
   padding: 6px 8px;
   background: rgba(130, 130, 130, 0.2);
`;

export const RoundControlLabel = styled.div`
   color: ${({ theme }) => theme.colors?.white};
   font-size: var(--autoplay-label-fz);
   padding-bottom: 10px;

   ${orientation.portrait`
      --autoplay-label-fz: 16px;
      font-weight: 600;
   `}

   ${orientation.mobileLandscape`
      --autoplay-label-fz: 16px;
      font-weight: 600;
   `}
`;
