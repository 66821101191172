import { ErrorBoundary } from '@/common/dev/ErrorBoundary';
import { useGameState } from '@/common/state/gameState';

import { CursorTooltip } from '#/components';
import { useCollapsedActivePlayingAreaState } from '#/modules/Track/state';

import { useCollapsedTrack } from '../Control/components/SwitchTrackControl';

import { CollapsedTrack, Track } from './components';
import { useTrackComponentState, useBettingAreaScale } from './hooks';
import { useTrackState } from './state';

export const TrackComponent = () => {
   const { trackType } = useTrackState();
   const { isBettingTimeState, isWaitingForConnectionGameStatus, isLastBetsState } = useGameState();
   const isBettingMapDisabled =
      isWaitingForConnectionGameStatus || !(isBettingTimeState || isLastBetsState);

   const { isCollapsedActivePlayingArea } = useCollapsedActivePlayingAreaState();
   const isCollapsedTrack = useCollapsedTrack();
   const TrackTypeComponent = isCollapsedTrack ? CollapsedTrack : Track;
   useBettingAreaScale();

   return (
      <ErrorBoundary>
         <CursorTooltip style={{ position: 'relative' }}>
            {({ className, onClick }) => (
               <TrackTypeComponent
                  onClick={onClick}
                  trackType={trackType}
                  isCollapsedActivePlayingArea={isCollapsedActivePlayingArea}
                  isDisabled={isBettingMapDisabled}
                  trackStore={useTrackComponentState}
                  className={`${trackType} ${className}`}
               />
            )}
         </CursorTooltip>
      </ErrorBoundary>
   );
};
