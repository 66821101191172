import { BetConfig } from '#/core/config/BetConfig';
import { roulettePocketsList } from '#/core/roulette';
import { ETypeBet } from '#/state/features/bets';

import { splitPocketsByChunk } from '../utils/splitPocketsByChunk';

class StreetBetsConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.STREET;
   protected readonly multiplier = 3;
   readonly config = this.generateConfig({
      pocketsList: roulettePocketsList,
      chunkSize: this.multiplier,
      startId: 74,
   });

   generateConfig({
      pocketsList,
      chunkSize,
      startId,
   }: {
      pocketsList: number[];
      chunkSize: number;
      startId: number;
   }) {
      const streetConfigWithZero = [
         { id: 72, numbers: [0, 1, 2], type: ETypeBet.STREET },
         { id: 73, numbers: [0, 2, 3], type: ETypeBet.STREET },
      ];

      return [
         ...streetConfigWithZero,
         ...splitPocketsByChunk({
            pocketsList,
            chunkSize,
            startId,
            type: this.type,
            rouletteMapRows: 3,
         }),
      ];
   }
}

export const streetConfig = new StreetBetsConfig();
