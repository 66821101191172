import { useGameState } from '@/common/state/gameState';
import { useNotifyInactivePlayer } from '@/common/state/gameState/gameState';

import { SessionClosedOverlay } from './components/SessionClosedOverlay';
import { ErrorOverlay } from './components/ErrorOverlay';
import { MaintenanceOverlay } from './components/MaintenanceOverlay';
import { InactiveGameTableOverlay } from './components/InactiveGameTableOverlay';
import { NotifyInactivePlayerOverlay } from './components/NotifyInactivePlayerOverlay';
import { LostConnectionOverlay } from './components/LostConnectionOverlay';

export const LayoutOverlay = ({ children }) => {
   const {
      isSessionClosedDueToInactivity,
      isErrorGameStatus,
      isMaintenanceMode,
      isInactiveGameTableMode,
      isConnectionLostMode,
      isLoginFromAnotherPlace,
   } = useGameState();

   const isNotifyInactivePlayer = useNotifyInactivePlayer();

   if (isErrorGameStatus) {
      return <ErrorOverlay />;
   }
   if (isMaintenanceMode) {
      return <MaintenanceOverlay />;
   }

   if (isInactiveGameTableMode) {
      return <InactiveGameTableOverlay />;
   }
   if (isConnectionLostMode) {
      return <LostConnectionOverlay />;
   }
   if (isSessionClosedDueToInactivity || isLoginFromAnotherPlace) {
      return <SessionClosedOverlay />;
   }

   return (
      <>
         {isNotifyInactivePlayer && <NotifyInactivePlayerOverlay />}
         {children}
      </>
   );
};
