import { z } from 'zod';

export const cancelBetSchema = z.object({
   amount: z.string(),
   totalBalance: z.string(),
   gameTableId: z.string(),
   roundId: z.string(),
   currency: z.string(),
});

export type CancelBetEvent = z.infer<typeof cancelBetSchema>;
