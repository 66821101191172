// @ts-nocheck
import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  --tooltip-container-width: 15ch;
  --tooltip-container-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --tooltip-content-text-color: ${({ theme }) => theme.colors?.main};
  --tooltip-content-text-fz: var(--tooltip-content-text-fz-var, 14px);
  --tooltip-content-bg-color: rgba(45, 45, 45, 0.8);

  position: relative;
  display: inline-flex;
  width: unset;
`;

export const TooltipContent = styled.span`
  position: relative;
  padding: 10px;
  border-radius: 18px;
  background-color: var(--tooltip-content-bg-color);
  box-shadow: var(--tooltip-container-box-shadow);
  font-size: var(--tooltip-content-text-fz);
  color: var(--tooltip-content-text-color);
  text-align: center;
  font-weight: 500;
`;

export const Children = styled.div`
  width: inherit;
  display: flex;
`;

export const TooltipContainer = styled.div`
  position: absolute;
  bottom: 70%;
  width: var(--tooltip-container-width);
  margin-left: 0;
  display: flex;
  align-items: center;
  z-index: 1;

  ${({ position }: { position: string }) => {
    switch (position) {
      case 'right':
        return css`
          justify-content: left;
          right: unset;
          left: 95%;
        `;
      case 'left':
        return css`
          justify-content: right;
          left: unset;
          right: 95%;
        `;
      case 'center':
        return css`
          justify-content: center;
          left: 50%;
          transform: translate(-50%, -50%);
        `;
      case 'bottom-left':
        return css`
          justify-content: right;
          left: unset;
          right: 100%;
          bottom: -30%;
        `;
      case 'bottom-right':
        return css`
          justify-content: left;
          left: unset;
          left: 100%;
          bottom: -30%;
        `;
      default:
        return css``;
    }
  }}
`;
