// @ts-nocheck
import styled, { css } from 'styled-components/macro';

export const HoverPlace = styled.path`
   transform-origin: center;
   transition: transform 0.5s;
   transform: scale(0);
   cursor: pointer;

   ${({ scale }) =>
      scale &&
      css`
         transform: scale(${scale});
      `}
`;
