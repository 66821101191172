import { FC } from 'react';

import cubeGifIcon from '../../assets/cube.gif';

import * as S from './CircleLoader.styles';

const radius = 54;

const getSVGCircleLoaderValue = (percents: number, radius: number): Record<string, number> => {
   const pi = 3.14;
   const strokeDasharrayValue = 2 * pi * radius;
   const strokeDashoffsetValue = strokeDasharrayValue * ((100 - percents) / 100);
   return {
      strokeDasharrayValue,
      strokeDashoffsetValue,
   };
};

interface ICircleLoaderProps {
   percents: number;
}

export const CircleLoader: FC<ICircleLoaderProps> = ({ percents }) => {
   const { strokeDashoffsetValue, strokeDasharrayValue } = getSVGCircleLoaderValue(
      percents,
      radius,
   );

   return (
      <S.CircleLoaderWrapper>
         <S.CircleLoader viewBox="0 0 120 120">
            <circle cx="60" cy="60" r={radius} fill="none" stroke="#807a4e" strokeWidth="4" />
            <circle
               cx="60"
               cy="60"
               r={radius}
               fill="none"
               stroke="#E7D981"
               strokeWidth="4"
               strokeDasharray={`${strokeDasharrayValue}px`}
               strokeDashoffset={`${strokeDashoffsetValue}px`}
            />
         </S.CircleLoader>
         <S.CubeSpinner src={cubeGifIcon} alt="loading..." />
      </S.CircleLoaderWrapper>
   );
};
