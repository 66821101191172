import { FC } from 'react';

import * as S from './MobileBettingPanelView.styles';

interface IMobileBettingPanelProps {
  ChipTray: JSX.Element;
  LeftControl: JSX.Element;
  RightControl: JSX.Element;
}

export const MobileBettingPanelView: FC<IMobileBettingPanelProps> = ({
  ChipTray,
  LeftControl,
  RightControl,
}) => (
  <>
    <S.MobileBettingPanelLeftControl>{LeftControl}</S.MobileBettingPanelLeftControl>
    <S.MobileBettingPanelGridChipTray>{ChipTray}</S.MobileBettingPanelGridChipTray>
    <S.MobileBettingPanelRightControl>{RightControl}</S.MobileBettingPanelRightControl>
  </>
);
