// @ts-nocheck
import styled from 'styled-components/macro';

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: none;
`;
