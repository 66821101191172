import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ETrackType, switchTypeTrack } from '#/modules/Track';
import { ERangeLevelLimitEnum } from '#/modules/Track/RaceTrack/constants';
import {
   TDoubleBet,
   TExcludeBetFromBetList,
   type IBetsState,
   addNewBets,
   chipZIndexes,
   getBetIdx,
   removeBet,
   undoBet,
} from '#/state/features/bets';

interface IBetConstructorState extends IBetsState {
   rangeLevel: number;
   trackType: ETrackType;
}

const initialState: IBetConstructorState = {
   totalBets: {},
   zIndexes: {},
   betsHistory: [],
   rangeLevel: 0,
   trackType: ETrackType.Betting,
};

export const betConstructorSlice = createSlice({
   name: 'betConstructorSlice',
   initialState,
   reducers: {
      toggleTrackType: (state): void => {
         state.trackType = switchTypeTrack[state.trackType];
      },
      removeBetById: (state, action: PayloadAction<number>) => {
         const [totalBets, betsHistory] = removeBet({
            totalBets: state.totalBets,
            betsHistory: state.betsHistory,
            betId: action.payload,
         });

         state.totalBets = totalBets;
         state.betsHistory = betsHistory;
         state.zIndexes = chipZIndexes.removeZIndex(state.zIndexes, action.payload);
      },
      addBets: (state, action: PayloadAction<TExcludeBetFromBetList<TDoubleBet>[]>): void => {
         const [commands, betsHistory] = addNewBets({
            totalBets: state.totalBets,
            betsHistory: state.betsHistory,
            newBets: action.payload,
         });

         state.zIndexes = chipZIndexes.updateZIndexes({
            zIndexes: state.zIndexes,
            updateZIndexes: getBetIdx(action.payload),
         });
         state.totalBets = commands;
         state.betsHistory = betsHistory;
      },

      addDoubleBet: (state, action): void => {
         const [totalBets, betsHistory] = action.payload;

         state.totalBets = totalBets;
         state.betsHistory = betsHistory;
      },

      resetBets: (state): void => {
         state.betsHistory = [];
         state.totalBets = {};
         state.zIndexes = {};
      },

      undo: (state): void => {
         const [commands, betsHistory] = undoBet({
            totalBets: state.totalBets,
            betsHistory: state.betsHistory,
         });

         state.totalBets = commands;
         state.betsHistory = betsHistory;
         state.zIndexes = chipZIndexes.getPreviousZIndexes(state.zIndexes);
      },
      increaseRangeLevel: (state): void => {
         if (state.rangeLevel < ERangeLevelLimitEnum.Max) {
            state.rangeLevel = state.rangeLevel + 1;
         }
      },
      decreaseRangeLevel: (state): void => {
         if (state.rangeLevel > ERangeLevelLimitEnum.Min) {
            state.rangeLevel = state.rangeLevel - 1;
         }
      },
   },
});
