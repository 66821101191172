import styled from 'styled-components/macro';

import { applyZIndex } from '../../../../ui/theme/mixins';

export const DesktopBettingPanelGrid = styled.div`
  --timer-transform: translateY(-20px);

  display: grid;
  grid-template-columns: 64px 590px 64px;
  height: 100%;
  align-items: center;
  justify-content: center;
  grid-column-gap: 34px;
  grid-template-areas:
    '. timer .'
    'leftControl chipTray rightControl';
`;

export const DesktopBettingPanelTimer = styled.div`
  ${applyZIndex('bettingTimer')}
  position: relative;
  display: flex;
  grid-area: timer;
  align-items: center;
  justify-content: center;
  transform: var(--timer-transform);
  min-height: 30px;

  &:active {
    cursor: move;
  }
`;

export const DesktopBettingPanelLeftControl = styled.div`
  transform: var(--timer-transform);
  grid-area: leftControl;
`;

export const DesktopBettingPanelRightControl = styled.div`
  transform: var(--timer-transform);
  grid-area: rightControl;
`;

export const DesktopBettingPanelGridChipTray = styled.div`
  transform: var(--timer-transform);
  grid-area: chipTray;

  .swiper-wrapper {
    width: 590px;
  }
`;
