interface IRange {
   end: number;
   start: number;
   step?: number;
}
export const rangeNumbers = ({ start, end, step = 1 }: IRange): number[] => {
   const result: number[] = [];

   for (let i = start; i <= end; i += step) {
      result.push(i);
   }

   return result;
};
