import { logger } from '../logger';

export const hexToRgba = (hexColor: string, alpha: number): string => {
   if (!hexColor.startsWith('#')) {
      logger.log('You should pass color in HEX format');
      return hexColor;
   }

   const HEX_COLOR_LENGTH = 7;
   if (hexColor.length < HEX_COLOR_LENGTH) {
      // eslint-disable-next-line no-param-reassign
      hexColor = hexColor.padEnd(HEX_COLOR_LENGTH, '0');
   }

   // eslint-disable-next-line no-param-reassign
   hexColor = hexColor.replace('#', '');

   const r = parseInt(hexColor.substring(0, 2), 16);
   const g = parseInt(hexColor.substring(2, 4), 16);
   const b = parseInt(hexColor.substring(4, 6), 16);

   return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
