import { TNullable } from '@/common/types';

import { TCallBet, TCommonBet } from '#/state/features/bets';

import { IBalance, IBalanceActions, IBalanceModel } from './types';

export class Balance implements IBalance {
   private readonly balanceModel: IBalanceModel;
   private readonly balanceActions: IBalanceActions;

   constructor({
      balanceModel,
      balanceActions,
   }: {
      balanceModel: IBalanceModel;
      balanceActions: IBalanceActions;
   }) {
      this.balanceModel = balanceModel;
      this.balanceActions = balanceActions;
   }

   private getInitialBalance = (): number => {
      return this.balanceModel.initialBalance();
   };

   public canPlaceBets = (betsAmount: number): boolean => {
      const currentBalance = this.getBalance();

      return betsAmount <= currentBalance;
   };

   public getBalance = () => {
      return this.balanceModel.balance();
   };

   public decreaseBalance = (value: number): void => {
      const currentBalance = this.getBalance();

      const updatedBalance = currentBalance - value;

      this.balanceActions.handleUpdateBalanceFromUI(updatedBalance);
   };

   public increaseBalance = (value: number): void => {
      const currentBalance = this.getBalance();
      const updatedBalance = currentBalance + value;

      this.balanceActions.handleUpdateBalanceFromUI(updatedBalance);
   };

   public updateBalanceByAutoplay = (balance: number): void => {
      this.balanceActions.handleUpdateBalanceAutoplayFromUI(balance);
   };

   public isBalancePositive = (balance: number): boolean => {
      return balance >= 0;
   };

   public resetBalance = (): void => {
      const initialBalance = this.getInitialBalance();
      this.balanceActions.handleUpdateBalanceFromUI(initialBalance);
   };

   public validateBetsByPlayerBalance = (
      bets: {
         bet: TNullable<TCallBet | TCommonBet>;
         isBetBelowMinLimit: boolean;
         isBetAboveMaxLimit: boolean;
      }[],
   ): boolean => {
      const betsTotalAmount = bets.reduce(
         (totalAmount, { bet }) => totalAmount + (bet?.amount ?? 0),
         0,
      );
      return this.canPlaceBets(betsTotalAmount);
   };
}
