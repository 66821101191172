import { FC, memo } from 'react';
import { useGameStateSelector } from 'services/api/gameConfigApiSlice/state';

import { isPortraitMode } from '@/common/hooks';
import { IPlayChipSound } from '@/common/entities';

import { useBetConstructorOrientationContext } from '#/modules/Favourite/components/BetConstructor/context';
import { ITrackProps } from '#/modules/Track';
import { withChipSound } from '#/hocs';

import { RaceTrackChips, RaceTrackRangeControls, RaceTrackSvg } from './components';
import {
   useCalibrateChips,
   useChipSize,
   useClickRaceTrack,
   useHotAndCold,
   useHoverRacetrack,
   useRaceTrackContainer,
   useWinningNumber,
} from './hooks';
import { ANIMATION_DURATION_SEC } from './hooks/useWinningNumber/constants';
import { IRaceTrackChip } from './types';
import * as S from './RaceTrack.styles';

export const RaceTrack: FC<ITrackProps & IPlayChipSound> = memo(
   ({
      className,
      isDisabled,
      trackStore,
      isCollapsedActivePlayingArea = false,
      onClick = null,
      trackContainerId,
      showSmallModeRacetrack,
      dataTestId,
      playChipSound,
   }) => {
      const betConstructorOrientation = useBetConstructorOrientationContext();
      const raceTrackContainerRef = useRaceTrackContainer(trackContainerId);
      const { gameConfig } = useGameStateSelector() ?? {};
      const { colors } = gameConfig ?? {};

      useHotAndCold({ raceTrackContainerRef, isDisabled });
      useClickRaceTrack({ raceTrackContainerRef, trackStore, playChipSound });
      useHoverRacetrack({ raceTrackContainerRef, trackStore });
      useWinningNumber({
         raceTrackContainerRef,
         trackContainerId,
         animationDurationOnMs: ANIMATION_DURATION_SEC * 1_000,
      });

      const chipSize =
         useChipSize({
            raceTrackContainerRef,
            trackStore,
         }) ?? 0;

      const chips = useCalibrateChips({
         chipSize,
         raceTrackContainerRef,
         trackStore,
      });

      const addChipSizeToChipsList = ({
         chips,
         chipSize,
      }: {
         chips: IRaceTrackChip[];
         chipSize: number;
      }) => chips.map((chip) => ({ ...chip, size: chipSize }));

      return (
         <S.RaceTrackWrapper
            id={trackContainerId}
            isDisabled={isDisabled}
            isCollapsedActivePlayingArea={isCollapsedActivePlayingArea}
            showSmallModeRacetrack={showSmallModeRacetrack}
            isPortrait={showSmallModeRacetrack || isPortraitMode(betConstructorOrientation)}
            data-testid={dataTestId}
            className={`${trackContainerId} ${className}`}
            {...(onClick && { onClick })}
         >
            <RaceTrackRangeControls trackStore={trackStore} />
            <RaceTrackSvg
               trackContainerId={trackContainerId}
               showSmallModeRacetrack={showSmallModeRacetrack}
               isDisabled={isDisabled}
               colorsConfiguration={colors}
            >
               <RaceTrackChips chips={addChipSizeToChipsList({ chips, chipSize })} />
            </RaceTrackSvg>
         </S.RaceTrackWrapper>
      );
   },
);
export const RaceTrackWithChipSound = withChipSound(RaceTrack);

RaceTrack.displayName = 'RaceTrack';
