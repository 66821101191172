// @ts-nocheck
import styled from 'styled-components/macro';

const List = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
`;

export const AmountList = styled(List)``;

export const UsernameList = styled(List)`
   overflow: hidden;
`;

export const VerticalWinnerListInner = styled.div`
   --list-item-fz: 13px;
   font-size: var(--list-item-fz);
   display: grid;
   grid-template-columns: auto 1fr;
   gap: 16px;
   width: 100%;
   height: 100%;
   margin: 0px auto;
   animation: vertical 9s linear 0s 1 normal forwards running;

   @keyframes vertical {
      from {
         transform: translateY(100%);
      }

      to {
         transform: translateY(-100%);
      }
   }
`;

export const SpaceLine = styled.div`
   background-color: ${({ theme }) => theme.colors?.main};
   height: 1px;
   margin-bottom: 12px;
   margin: 0 auto;
`;

export const Wrapper = styled.div`
   text-transform: uppercase;
   color: ${({ theme }) => theme.colors?.white};
   max-width: 100%;
   height: 200px;
   overflow: hidden;
`;

export const Amount = styled.div`
   color: ${({ theme }) => theme.colors?.main};
   white-space: nowrap;
`;

export const Username = styled.div`
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
   max-width: 100%;
`;
