import { Portal } from '@/common/ui';

import { useTranslation } from '#/translates';

import { WarningIcon } from './assets';
import * as S from './WarningTooltip.styles';

export const WarningTooltip = ({ shouldShowNotification, message, selector }) => {
   const { t } = useTranslation();

   return (
      <Portal>
         <S.WarningNotificationWrapper
            className={selector}
            data-test-visibility={shouldShowNotification ? 'visible' : 'hidden'}
            shouldShowNotification={shouldShowNotification}
         >
            <WarningIcon />
            <S.WarningNotificationContent data-testid={selector}>
               {t(message)}
            </S.WarningNotificationContent>
         </S.WarningNotificationWrapper>
      </Portal>
   );
};
