import { logger } from '@/common/utils/logger';
import { TLobbyRedirectUrl } from '@/common/entities';

export const handleGoToLobby = (lobbyUrl: TLobbyRedirectUrl): void => {
   if (!lobbyUrl) {
      logger.error('lobbyUrl not found');
      return;
   }
   try {
      const newWindow = window.open(lobbyUrl, '_top'); // the last param "top" means that we open new page in the current browser tab

      if (!newWindow) {
         logger.error('Unable to open the lobby window.');
         return;
      }

      newWindow.focus();
   } catch (error) {
      if (error instanceof Error) {
         logger.error(`An error occurred while trying to open the lobby: ${error.message}`);
      }
   }
};
