import {
   MessageRepliesCountButton,
   useChannelActionContext,
   useMessageContext,
} from 'stream-chat-react';

export const RepliesCountButton = () => {
   const { message } = useMessageContext();
   const { openThread } = useChannelActionContext();

   return (
      <MessageRepliesCountButton
         // to be able to open the thread, we need to provide the message context
         // thread gets the message id and thread object to link it
         onClick={() => openThread(message)}
         reply_count={message.reply_count}
      />
   );
};
