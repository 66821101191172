import { formatCurrency } from 'helpers';
import { useMemo } from 'react';

import { useTableCapacity } from '#/modules/TableCapacity/state';
import { ETypeBet } from '#/state/features/bets';

const limitTableRows = [
   {
      title: 'Straight Up',
      pays: '35:1',
      bets: [ETypeBet.STRAIGHT],
   },
   {
      title: 'Split',
      pays: '17:1',
      bets: [ETypeBet.SPLIT],
   },
   {
      title: 'Street',
      pays: '11:1',
      bets: [ETypeBet.STREET],
   },
   {
      title: 'Corner/Basket',
      pays: '8:1',
      bets: [ETypeBet.CORNER],
   },
   {
      title: 'Line',
      pays: '5:1',
      bets: [ETypeBet.LINE],
   },
   {
      title: 'Column/Dozen',
      pays: '2:1',
      bets: [ETypeBet.COLUMN, ETypeBet.DOZEN],
   },
   {
      title: 'Red/Black',
      pays: '1:1',
      bets: [ETypeBet.RED, ETypeBet.BLACK],
   },
   {
      title: 'Even/Odd',
      pays: '1:1',
      bets: [ETypeBet.EVEN, ETypeBet.ODD],
   },
   {
      title: '1-18/19-36',
      pays: '1:1',
      bets: [ETypeBet.LOW, ETypeBet.HIGH],
   },
];

export const useLimitsTableRows = () => {
   const { betLimitsByType = {} } = useTableCapacity();

   return useMemo(() => {
      const limitTableRowsWithMinAndMax = limitTableRows.map(({ title, pays, bets }) => {
         const [betType] = bets;
         const { min, max } = betLimitsByType[betType];

         return [
            title,
            pays,
            formatCurrency(min, { isCompact: true }),
            formatCurrency(max, { isCompact: true }),
         ];
      });

      return limitTableRowsWithMinAndMax;
   }, [betLimitsByType]);
};
