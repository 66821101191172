import { useEffect, useState } from 'react';

import { useDealerRateState } from '#/modules/DealerRating/state';

const BLINKING_INTERVAL = 5000;
const BLINKING_REMOVE_INTERVAL = 1000;

export const useBlinkingStartTimer = () => {
   const { handleSetLeavingFeedbackButtonShown, isLeavingFeedbackButtonShown } =
      useDealerRateState();

   const [isBlinking, setIsBlinking] = useState(isLeavingFeedbackButtonShown);
   const [blinkCount, setBlinkCount] = useState(0);

   useEffect(() => {
      if (!isLeavingFeedbackButtonShown) {
         return;
      }
      const pulseInterval = setInterval(() => {
         if (!isLeavingFeedbackButtonShown) {
            return;
         }
         if (blinkCount < 3) {
            setBlinkCount((prevCount) => prevCount + 1);
            setIsBlinking(true);
         } else {
            setIsBlinking(false);
            handleSetLeavingFeedbackButtonShown(false);
            clearInterval(pulseInterval);
         }
      }, BLINKING_INTERVAL);

      return () => clearInterval(pulseInterval);
   }, [blinkCount, isLeavingFeedbackButtonShown]);

   useEffect(() => {
      if (!isLeavingFeedbackButtonShown) {
         return;
      }

      const pulseInterval = setInterval(() => {
         setIsBlinking(false);
      }, BLINKING_REMOVE_INTERVAL);

      return () => clearInterval(pulseInterval);
   }, [isBlinking]);

   return {
      isBlinking,
      setIsBlinking,
   };
};
