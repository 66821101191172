import { Component, ReactNode } from 'react';

import { logger } from '@/common/utils';

interface Props {
   children?: ReactNode;
}

interface State {
   hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
   state: State = {
      hasError: false,
   };

   static getDerivedStateFromError(): State {
      return { hasError: true };
   }

   componentDidCatch(error) {
      logger.error(error);
   }

   render(): JSX.Element {
      if (this.state.hasError) {
         return <div style={{ color: '#fff', fontSize: 80 }}>App has an error</div>;
      }

      return this.props.children as JSX.Element;
   }
}
