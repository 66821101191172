import { useBlinkingStartTimer } from '../../components/RatingStars/hooks';
import { useDealerRateState } from '../../state';
import { RatingStarsBlinking } from '../RatingStarsBlinking';
import { DealerRateButton } from '../DealerRateButton';

import * as S from './DealerRatingStars.styles';

interface IDealerRatingStarsProps {
   buttonTitle: string;
}

export const DealerRatingStars = ({ buttonTitle }: IDealerRatingStarsProps) => {
   const {
      dealerRate,
      handleSetLeavingFeedbackButtonShown,
      isLeavingFeedbackAvailable,
      isLeavingFeedbackButtonShown,
      handleSetIsModalShown,
   } = useDealerRateState();
   const { isBlinking } = useBlinkingStartTimer();

   const handleShowModal = () => {
      handleSetLeavingFeedbackButtonShown(false);
      handleSetIsModalShown(true);
   };

   return (
      <S.DealerRatingWrapper>
         <S.RatingStarsWrapper
            data-testid={`dealer-rating-stars-value-${dealerRate}`}
            isActionDisabled={!isLeavingFeedbackAvailable}
         >
            <RatingStarsBlinking
               filledStars={dealerRate}
               handleShowModal={handleShowModal}
               isBlinking={isBlinking}
            />
         </S.RatingStarsWrapper>
         <DealerRateButton
            buttonTitle={buttonTitle}
            handleShowModal={handleShowModal}
            isBlinking={isBlinking}
            isLeavingFeedbackButtonShown={isLeavingFeedbackButtonShown}
         />
      </S.DealerRatingWrapper>
   );
};
