import { useEffect, useState } from 'react';

import { TSetTimeout, TNullable } from '@/common/types';
import { useGameState } from '@/common/state/gameState';

import { useWin } from '../state';
import { ANIMATION_DURATION } from '../constants';

export const useWinSumAnimation = () => {
   const [isStartWinSumAnimation, setIsStartWinSumAnimation] = useState(false);
   const { isRoundResultState } = useGameState();
   const { winSum } = useWin();

   const handleStartAnimation = () => setIsStartWinSumAnimation(true);
   const handleEndAnimation = () => setIsStartWinSumAnimation(false);

   useEffect(() => {
      let animationTimeout: TNullable<TSetTimeout> = null;

      if (animationTimeout) {
         clearTimeout(animationTimeout);
      }

      if (isRoundResultState && winSum) {
         handleStartAnimation();
         animationTimeout = setTimeout(handleEndAnimation, ANIMATION_DURATION);
      }
   }, [isRoundResultState, winSum]);
   return { isStartWinSumAnimation };
};
