import { useState } from 'react';

import { TNullable } from '@/common/types';

export const useSectorPointerEvents = () => {
   // todo: refactor this hook
   const [selectedCell, handleSelectedCell] = useState<TNullable<SVGPathElement>>(null);

   const enum EPointerEvents {
      Down = 'pointerdown',
      Move = 'pointermove',
      Out = 'pointerout',
   }

   const setSectionHighlight = ({
      target,
      isVisible,
   }: {
      target: TNullable<SVGPathElement>;
      isVisible: boolean;
   }) => {
      if (!target) {
         return;
      }
      const section = target.parentNode?.firstChild as SVGPathElement;

      if (section) {
         section.style.opacity = isVisible ? '1' : '0';
      }
   };

   const onPointerAction = (event: PointerEvent) => {
      const target = event.target as SVGPathElement;

      switch (event.type) {
         case EPointerEvents.Down:
            target.releasePointerCapture(event.pointerId);
            setSectionHighlight({ target, isVisible: true });
            handleSelectedCell(target);
            break;
         case EPointerEvents.Out:
            setSectionHighlight({ target, isVisible: false });
            break;
         case EPointerEvents.Move:
            if (selectedCell !== event.target) {
               setSectionHighlight({ target: selectedCell, isVisible: false });
            }
            setSectionHighlight({ target, isVisible: true });
            handleSelectedCell(target);
            break;
      }
   };

   return {
      onPointerMove: onPointerAction,
      onPointerDown: onPointerAction,
      onPointerOut: onPointerAction,
   };
};
