import { TListenerApiDispatchType } from '@/common/types';
import { contractValidator, statisticsAPI, unwrapResponse } from '@/common/services/api';
import { clientApiSlice } from '@/common/services/clientAPI/clientApiSlice';
import { getStatisticsSchema } from '@/common/services/api/statisticsAPI/schemes';
import { getStatisticsByRange } from '@/common/modules/Statistics';

import { statisticsSlice } from '#/modules/Statistics';
import { TRootState } from '#/state/types';

export const getStatistics = async (
   dispatch: TListenerApiDispatchType,
   getState: () => unknown,
) => {
   const { gameConfig } = getState() as TRootState;

   const statisticsResponse = await dispatch(
      statisticsAPI(clientApiSlice).endpoints.getStatisticsByGameTableId.initiate({
         gameTableId: gameConfig.gameConfig.gameTableId,
      }),
   );
   // @ts-ignore
   const { data, isSuccessful } = unwrapResponse(statisticsResponse);

   contractValidator({
      schema: getStatisticsSchema,
      data,
      path: 'getStatistics API',
   });

   if (isSuccessful) {
      const rangeStatistics = getStatisticsByRange(data);
      dispatch(statisticsSlice.actions.updateStatistics(rangeStatistics));
   }
};
