import { FC, ReactNode } from 'react';

import * as S from './HorizontalTransparentMask.styles';

interface IHorizontalTransparentMaskProps {
   children?: ReactNode;
}
export const HorizontalTransparentMask: FC<IHorizontalTransparentMaskProps> = ({ children }) => (
   <S.WrapperTop>
      <S.WrapperBottom>{children}</S.WrapperBottom>
   </S.WrapperTop>
);
