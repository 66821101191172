import { useCallback, useEffect, useRef } from 'react';

import { useGameState } from '@/common/state/gameState';
import { useWindowEvent } from '@/common/hooks';
import { TNullable } from '@/common/types';
import { safeJsonParse } from '@/common/utils/safeJsonParse';

import { betsCreatorByPlaceTrack } from '#/core/betsCreator';
import {
   THandleShowMaxBetTooltip,
   useLimitTooltipActions,
   useLimitTooltipSelector,
} from '#/modules/LimitTooltip';
import { BETTING_MAP_CLASSNAME } from '#/modules/Track/BettingMap/constants';
import { BettingMapRefType } from '#/modules/Track/BettingMap/hooks/useBettingMapRef/types';
import { TrackContainerId } from '#/modules/Track/types';
import { ITrackComponentState } from '#/state/features/bets';

import { isBetInCallBet } from './utils/isBetInCallBet';
import { useHighlightCells } from './useHighlightCells';
import { useDropChip } from './useDropChip';
import { useDragChip } from './useDragChip';
import { useDataTransfer } from './useDataTransfer';
import { DataTransfer } from './types';
import { dragChipId } from './constants';

interface IDragAndDrop {
   handleShowMaxBetTooltip: TNullable<THandleShowMaxBetTooltip>;
   trackContainerId: TrackContainerId;
   trackStore: () => ITrackComponentState;
   wrapperRef: BettingMapRefType;
}

export const useDragAndDrop = ({
   wrapperRef,
   trackStore,
   handleShowMaxBetTooltip,
   trackContainerId,
}: IDragAndDrop) => {
   const currentBettingMap = useRef<BettingMapRefType>(null);
   const anotherBettingMaps = useRef<HTMLElement[]>([]);
   const chipId = `${trackContainerId}_${dragChipId}`;
   const { placeTrack } = trackStore();
   const betsCreator = betsCreatorByPlaceTrack(placeTrack);
   const {
      movingChip,
      showDragChip,
      hideDragChip,
      moveDragChip,
      highlightChip,
      removeHighlightChip,
   } = useDragChip(chipId);
   const { isNoMoreBetsState } = useGameState();
   const { setDataTransfer, getDataTransfer } = useDataTransfer();
   const { highlightCells, removeHighlightCells } = useHighlightCells(wrapperRef);
   const handleOnDropChip = useDropChip({
      trackStore,
      handleShowMaxBetTooltip,
   });

   const activeTooltip = useLimitTooltipSelector();
   const { handleHideTooltip } = useLimitTooltipActions();

   useEffect(() => {
      if (isNoMoreBetsState) {
         hideDragChip();
      }
   }, [isNoMoreBetsState]);

   const getEventTarget = useCallback((event: DragEvent) => event.target as HTMLElement, []);

   const isBettingMapCell = (event: DragEvent): boolean => {
      const eventTarget = getEventTarget(event);

      return Boolean(eventTarget.dataset.highlightCells);
   };

   const getHighlightCells = (highlightCells: string) => highlightCells?.split(',')?.map(Number);

   const onDragStart = (event: DragEvent): void => {
      if (!wrapperRef) {
         return;
      }

      const eventTarget = getEventTarget(event);
      const { amount, betId, highlightCells, type = '', chipPlace = '' } = eventTarget.dataset;

      const callBetList = betsCreator.getCallBets();

      if (isBetInCallBet({ callBetList, betId: Number(betId) })) {
         return;
      }

      if (wrapperRef.contains(eventTarget)) {
         const allBettingMapsComponents = Array.from<HTMLElement>(
            document.querySelectorAll(`.${BETTING_MAP_CLASSNAME}`),
         );

         for (const node of allBettingMapsComponents) {
            const isCurrentBettingMap = node === wrapperRef;

            if (isCurrentBettingMap) {
               currentBettingMap.current = node;
            } else {
               node.style.setProperty('pointer-events', 'none');
               anotherBettingMaps.current.push(node);
            }
         }

         showDragChip(event);

         setDataTransfer({
            event,
            data: {
               amount: Number(amount),
               chipPlace: safeJsonParse(chipPlace),
               betId: Number(betId),
               highlightCells: getHighlightCells(highlightCells ?? ''),
               type,
            },
         });

         // if max bet tooltip is visible, hide it after starting dragging
         if (activeTooltip) {
            handleHideTooltip();
         }
      }
   };

   const onDragEnd = (event: DragEvent): void => {
      if (!wrapperRef) {
         return;
      }

      const eventTarget = getEventTarget(event);

      if (wrapperRef.contains(eventTarget)) {
         hideDragChip();
         anotherBettingMaps.current.forEach((node) => {
            node.style.removeProperty('pointer-events');
         });

         anotherBettingMaps.current = [];
         currentBettingMap.current = null;
      }
   };

   const onDragEnter = (event: DragEvent): void => {
      if (!wrapperRef) {
         return;
      }

      const isHighlightCells = wrapperRef.contains(event.target as Node);

      removeHighlightCells(event);

      if (isBettingMapCell(event)) {
         isHighlightCells && highlightCells(event);
         removeHighlightChip();
      } else {
         highlightChip();
      }
   };

   const onDragOver = (event: DragEvent): void => {
      event.stopPropagation();

      if (event.preventDefault) {
         event.preventDefault();
      }

      moveDragChip(event);
   };

   const onDrop = (event: DragEvent): void => {
      const eventTarget = getEventTarget(event);

      if (isNoMoreBetsState) {
         return;
      }

      if (currentBettingMap.current === wrapperRef) {
         const whenChipIsOutsideBettingMap = !isBettingMapCell(event);

         if (whenChipIsOutsideBettingMap) {
            const id = getDataTransfer(event).betId;

            betsCreator.removeBetById(id);

            return;
         }
         const getDraggableCommand = (): DataTransfer => getDataTransfer(event);
         const getDroppableCommand = (): DataTransfer => {
            const {
               amount,
               highlightCells,
               betId,
               chipPlace = '',
               type = '',
            } = eventTarget.dataset;

            return {
               amount: Number(amount),
               betId: Number(betId),
               type,
               highlightCells: getHighlightCells(highlightCells ?? ''),
               chipPlace: safeJsonParse(chipPlace),
            };
         };

         handleOnDropChip({
            draggableCommand: getDraggableCommand(),
            droppableCommand: getDroppableCommand(),
            event,
         });
      }
   };

   const onDragLeave = (event: DragEvent): void => {
      removeHighlightCells(event);
   };

   useWindowEvent('dragstart', onDragStart);
   useWindowEvent('dragenter', onDragEnter);
   useWindowEvent('dragleave', onDragLeave);
   useWindowEvent('dragover', onDragOver);
   useWindowEvent('dragend', onDragEnd);
   useWindowEvent('drop', onDrop);

   return {
      movingChip,
   };
};
