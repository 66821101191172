import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TRootState } from '#/state/types';

interface IPlayersCountState {
   count: number;
}

const initialState: IPlayersCountState = {
   count: 0,
};

export const playersCountSlice = createSlice({
   name: 'playersCount',
   initialState,
   reducers: {
      updatePlayersCount: (state, action: PayloadAction<number>): void => {
         state.count = action.payload;
      },
   },
});

export const usePlayersCountActions = () => {
   const dispatch = useDispatch();

   const { updatePlayersCount } = playersCountSlice.actions;

   const handleUpdatePlayersCount = (playersCount: number): void => {
      dispatch(updatePlayersCount(playersCount));
   };

   return {
      handleUpdatePlayersCount,
   };
};

const usePlayersCountSelector = (): number =>
   useSelector(({ playersCount }: TRootState) => playersCount?.count);

export const usePlayersCount = () => {
   const count = usePlayersCountSelector();
   const { handleUpdatePlayersCount } = usePlayersCountActions();

   return { count, handleUpdatePlayersCount };
};
