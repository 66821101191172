import { gameStateSlice } from '@/common/state/gameState';

import { createAutoplay } from '#/modules/Autoplay/hooks';
import { TRootState } from '#/state/types';

export const handleReconnect = (_, listenerApi) => {
   const { dispatch } = listenerApi;
   const state = listenerApi.getOriginalState() as TRootState;
   const { autoplay } = createAutoplay(dispatch, state.autoplay);
   const isAutoplayStarted = autoplay.isAutoplayStarted();
   const { setGameStatusAsSuccess } = gameStateSlice.actions;

   dispatch(setGameStatusAsSuccess());
   if (isAutoplayStarted) {
      autoplay.handleStateAutoplay(false);
      autoplay.handleShowNotification();
   }
};
