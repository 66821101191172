import { memo } from 'react';

import { isMobileDevice } from '@/common/utils/checkDevice';

import { useAnnouncementsState } from '#/modules/Announcements/state';

import * as S from './Announcements.styles';
import { useAnnouncements } from './hooks';

export const Announcements = memo(() => {
   const announcementSubtitle = useAnnouncements();
   const { isShowAnnouncementSubtitles } = useAnnouncementsState();

   // isMobileDevice() - We don't show announcements subtitle on mobile devices, only play sounds
   // isShowAnnouncementSubtitles - we can disable announcement subtitles from settings
   if (!isShowAnnouncementSubtitles || isMobileDevice() || !announcementSubtitle) {
      return null;
   }

   return <S.AnnouncementsWrapper>{announcementSubtitle}</S.AnnouncementsWrapper>;
});
