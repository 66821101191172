import { TCallBet } from '#/state/features/bets/types';

export const isBetInCallBet = ({
   callBetList,
   betId,
}: {
   callBetList: TCallBet[];
   betId: number;
}) => {
   const callBetInternalBetIdx = callBetList.flatMap((callBet) =>
      callBet.extractedCommand.map((bet) => bet.id),
   );

   return callBetInternalBetIdx.includes(betId);
};
