import { EBetControl } from './constants';

export const icons: Record<EBetControl, JSX.Element> = {
  [EBetControl.Undo]: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.4406 16.3334C26.0264 15.7476 26.0264 14.7978 25.4406 14.212C24.8548 13.6263 23.9051 13.6263 23.3193 14.212L18.5761 18.9553C17.9903 19.5411 17.9903 20.4908 18.5761 21.0766L23.3193 25.8199C23.9051 26.4057 24.8548 26.4057 25.4406 25.8199C26.0264 25.2341 26.0264 24.2843 25.4406 23.6986L23.1625 21.4204L25.1977 21.4205L27.6554 21.4206H27.6555C29.4252 21.4204 31.0926 21.4204 32.8884 21.4204C34.4872 21.4204 36.0702 21.7355 37.5472 22.3475C39.0241 22.9596 40.366 23.8567 41.4961 24.9875L41.4968 24.9882C42.6277 26.1184 43.5248 27.4602 44.1368 28.9371C44.749 30.4142 45.0642 31.9973 45.0642 33.596C45.0642 35.1946 44.749 36.7776 44.1368 38.2547C43.5248 39.7317 42.6277 41.0736 41.4968 42.2037L41.4961 42.2044C40.366 43.3352 39.0241 44.2323 37.5472 44.8444C36.0702 45.4565 34.4872 45.7715 32.8884 45.7715C32.285 45.7715 31.7507 45.7713 31.2213 45.7711H31.2161C30.1732 45.7708 29.149 45.7705 27.6544 45.7715C26.4347 45.7723 25.0199 45.772 23.7815 45.7717L22.2493 45.7715C21.4209 45.7715 20.7493 46.4431 20.7493 47.2715C20.7493 48.0999 21.4209 48.7715 22.2493 48.7715L23.7784 48.7717H23.7843C25.022 48.772 26.4372 48.7723 27.6564 48.7715C29.152 48.7705 30.1745 48.7708 31.2181 48.7711C31.7479 48.7713 32.2831 48.7715 32.8884 48.7715C34.8813 48.7715 36.8546 48.3788 38.6957 47.6159C40.5367 46.8529 42.2094 45.7347 43.6182 44.325C45.0275 42.9164 46.1455 41.244 46.9083 39.4032C47.6712 37.5623 48.0642 35.589 48.0642 33.596C48.0642 31.603 47.6712 29.6296 46.9083 27.7886C46.1455 25.9479 45.0275 24.2755 43.6182 22.8669C42.2094 21.4573 40.5367 20.339 38.6957 19.5761C36.8546 18.8131 34.8813 18.4204 32.8884 18.4204C31.0926 18.4204 29.4252 18.4204 27.6554 18.4206H27.6553L25.1979 18.4205L23.3536 18.4204L25.4406 16.3334Z"
    />
  ),
  [EBetControl.Rebet]: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.3086 16.5C44.3086 15.6716 44.9802 15 45.8086 15C46.637 15 47.3086 15.6716 47.3086 16.5V24.485C47.3086 25.3134 46.637 25.985 45.8086 25.985H37.8236C36.9952 25.985 36.3236 25.3134 36.3236 24.485C36.3236 23.6565 36.9952 22.985 37.8236 22.985H42.103C40.9109 21.8825 39.5319 20.9987 38.0292 20.376C36.3598 19.6842 34.5704 19.3281 32.7633 19.3281C30.9562 19.3281 29.1669 19.6842 27.4975 20.376C25.828 21.0678 24.3112 22.0818 23.0338 23.3601L23.0331 23.3608C21.7549 24.6382 20.7409 26.1549 20.0491 27.8243C19.3572 29.4938 19.0009 31.2833 19.0009 33.0902C19.0009 34.8972 19.3572 36.6866 20.0491 38.3561C20.7409 40.0256 21.7549 41.5424 23.0331 42.8197L23.0338 42.8204C24.3112 44.0987 25.828 45.1127 27.4975 45.8045C29.1669 46.4963 30.9562 46.8524 32.7633 46.8524C34.5704 46.8524 36.3598 46.4963 38.0292 45.8045C39.6987 45.1127 41.2155 44.0987 42.4928 42.8204C43.0784 42.2345 44.0282 42.2342 44.6142 42.8197C45.2001 43.4053 45.2004 44.3551 44.6149 44.9411C43.0589 46.4981 41.2113 47.7332 39.1777 48.5759C37.1442 49.4187 34.9646 49.8524 32.7633 49.8524C30.5621 49.8524 28.3825 49.4187 26.349 48.5759C24.3154 47.7332 22.4678 46.4981 20.9118 44.9411C19.3551 43.3852 18.1202 41.5379 17.2776 39.5046C16.435 37.4712 16.0009 35.2916 16.0009 33.0902C16.0009 30.8889 16.435 28.7092 17.2776 26.6758C18.1202 24.6425 19.3551 22.7953 20.9118 21.2394C22.4678 19.6824 24.3154 18.4473 26.349 17.6046C28.3825 16.7618 30.5621 16.3281 32.7633 16.3281C34.9646 16.3281 37.1442 16.7618 39.1777 17.6046C41.0794 18.3926 42.8185 19.5239 44.3086 20.9407V16.5Z"
      fill="white"
    />
  ),
  [EBetControl.Double]: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.5768 35.0077L41.2509 38.2752H48.1123V43.7742H30.7069V43.6148L37.6846 34.0513C37.7553 33.9548 37.825 33.8598 37.8936 33.7663C39.8925 31.0413 40.9796 29.5595 40.9796 27.5561C40.9796 25.9224 39.8942 24.8863 37.9947 24.8863C35.5525 24.8863 32.994 26.5998 31.4434 27.8749V21.7384C33.2653 20.1843 36.1727 18.9092 39.6228 18.9092C44.3909 18.9092 47.3758 21.8579 47.3758 26.281C47.3758 29.3891 45.6314 32.0589 43.5768 35.0077ZM24.0585 26.3694H27.9234L23.3747 32.9778L28.2207 40.0451H24.1477L21.2936 35.7619L18.4098 40.0451H14.5449L19.3314 33.0696L14.8125 26.3694H18.8855L21.4422 30.2549L24.0585 26.3694Z"
    />
  ),
};
