// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { applyZIndex, orientation } from '@/common/ui/theme/mixins';
import { IBettingMapColor, getBettingMapConfig } from '@/common/roulette/utils/getBettingMapConfig';

import { generateGridLandscape, generateGridPortrait } from '#/modules/Track/BettingMap/bet-grids';

import { ChipWrapper } from '../Bet/Bet.styles';

const portraitStyles = css`
   --bm-big-cell-width: 26.026%;
   --bm-big-cell-height: 7.39%;
   --bm-small-cell-width: 10.961%;
   --bm-small-cell-height: 5.65%;
   --betting-map-width: 100%;
   --betting-map-height: 100%;
   --betting-map-cell-small-fz: 16px;
   --betting-map-cell-normal-fz: 26px;
   --chip-size: 40px;

   grid-template-rows: var(--bm-small-cell-height) repeat(12, var(--bm-big-cell-height)) var(
         --bm-small-cell-height
      );
   grid-template-columns: repeat(2, var(--bm-small-cell-width)) repeat(3, var(--bm-big-cell-width));
   grid-template-areas:
      '. . f_0 f_0 f_0'
      'f_40 f_37 f_1 f_2 f_3'
      'f_40 f_37 f_4 f_5 f_6'
      'f_41 f_37 f_7 f_8 f_9'
      'f_41 f_37 f_10 f_11 f_12'
      'f_42 f_38 f_13 f_14 f_15'
      'f_42 f_38 f_16 f_17 f_18'
      'f_43 f_38 f_19 f_20 f_21'
      'f_43 f_38 f_22 f_23 f_24'
      'f_44 f_39 f_25 f_26 f_27'
      'f_44 f_39 f_28 f_29 f_30'
      'f_45 f_39 f_31 f_32 f_33'
      'f_45 f_39 f_34 f_35 f_36'
      '. . f_46 f_47 f_48';

   ${generateGridPortrait()};
`;

export const BettingMapContainer = styled.div`
   ${applyZIndex('track')};

   --betting-map-width: 892px;
   --betting-map-height: 332px;
   --betting-map-bg-default: rgba(0, 0, 0, 1);
   ${({
      colorsConfiguration,
      isDisabled,
   }: {
      isDisabled: boolean;
      colorsConfiguration: IBettingMapColor & { transparency: number };
   }) => {
      const {
         redBaseSectorBackgroundColor,
         blackBaseSectorBackgroundColor,
         redBackgroundColor,
         blackBackgroundColor,
         zeroBackgroundColor,
         blackNumberColor,
         redNumberColor,
         zeroNumberColor,
         bordersColor,
         hotShadowColor,
         coldShadowColor,
         sideAreaTextColor,
      } = getBettingMapConfig({
         isDisabled,
         config: colorsConfiguration ?? {},
      });

      return css`
         --side-area-betting-map-number-color: ${sideAreaTextColor};
         --betting-map-base-sector-bg-red: ${redBaseSectorBackgroundColor};
         --betting-map-base-sector-bg-black: ${blackBaseSectorBackgroundColor};
         --betting-map-bg-red: ${redBackgroundColor};
         --betting-map-bg-black: ${blackBackgroundColor};
         --betting-map-bg-zero: ${zeroBackgroundColor};
         --black-betting-map-number-color: ${blackNumberColor};
         --red-betting-map-number-color: ${redNumberColor};
         --zero-betting-map-number-color: ${zeroNumberColor};
         --betting-map-border-color: ${bordersColor};
         --hot-betting-map-shadow-color: ${hotShadowColor};
         --cold-betting-map-shadow-color: ${coldShadowColor};
      `;
   }}

   --betting-map-cell-small-fz: 24px;
   --betting-map-cell-normal-fz: 42px;

   --bm-big-cell-width: 7.5%;
   --bm-big-cell-height: 26%;

   --bm-small-cell-width: 5%;
   --bm-small-cell-height: 11%;

   --chip-size: 60px;

   box-sizing: border-box;
   position: relative;
   width: var(--betting-map-width);
   height: var(--betting-map-height);
   margin: 0 auto;
   display: grid;
   grid-template-columns: var(--bm-small-cell-width) repeat(12, var(--bm-big-cell-width)) var(
         --bm-small-cell-width
      );
   grid-template-rows: repeat(3, var(--bm-big-cell-height)) repeat(2, var(--bm-small-cell-height));
   justify-content: center;

   grid-template-areas:
      'f_0 f_3 f_6 f_9 f_12 f_15 f_18 f_21 f_24 f_27 f_30 f_33 f_36 f_48'
      'f_0 f_2 f_5 f_8 f_11 f_14 f_17 f_20 f_23 f_26 f_29 f_32 f_35 f_47'
      'f_0 f_1 f_4 f_7 f_10 f_13 f_16 f_19 f_22 f_25 f_28 f_31 f_34 f_46'
      '. f_37 f_37 f_37 f_37 f_38 f_38 f_38 f_38 f_39 f_39 f_39 f_39 .'
      '. f_40 f_40 f_41 f_41 f_42 f_42 f_43 f_43 f_44 f_44 f_45 f_45 .';

   transform-origin: bottom;
   transition: transform 0.7s;

   .cell {
      display: flex;
      align-items: center;
      justify-content: center;
   }

   ${generateGridLandscape()};

   ${({ isDisabled }) =>
      isDisabled &&
      css`
         * {
            pointer-events: none;
         }
         ${ChipWrapper} {
            filter: brightness(0.5);
            transition: filter 0.7s;
         }

         transform: scale(0.7);
         transition: transform 0.7s;
         ${orientation.portrait`
            transform:scale(var(--betting-container-scale, 0.42));
         `}

         ${orientation.mobileLandscape`
           transform: scale(0.4);
         `}
      `}
   ${({ isCollapsedActivePlayingArea }) =>
      isCollapsedActivePlayingArea &&
      css`
         opacity: 1;
         ${orientation.portrait`transform: scale(var(--betting-container-scale, 0.42));`};
         ${orientation.mobileLandscape`transform: translate(-38%,18%) scale(0.28);`};
      `}
   ${({ isPortrait }) =>
      isPortrait &&
      css`
         ${portraitStyles};
      `}

   ${orientation.portrait`
      ${portraitStyles};
   `}

  ${orientation.mobileLandscape`
      --betting-map-cell-small-fz: 15px;
      --betting-map-cell-normal-fz: 24px;

      --betting-map-width: 566px;
      --betting-map-height: 215px;

      --chip-size: 30px;
   `}
`;
