import { FC } from 'react';

import { useAutoplay } from '#/modules/Autoplay/hooks';
import { translation } from '#/translates';

import * as S from '../../Autoplay.styles';

export const CancelAutoplayButton: FC<{ onCancelSuccessful: () => void }> = ({
   onCancelSuccessful,
}) => {
   const { autoplay } = useAutoplay();

   const handleOnClick = async () => {
      await autoplay.cancelAutoplayFromUI();
      onCancelSuccessful();
   };

   return (
      <S.AutoplayButton
         disabled={autoplay.isLoading()}
         data-testid="cancel-autoplay-button"
         onClick={handleOnClick}
         inProgress
      >
         {translation('sidebars.autoplay.stop')}
      </S.AutoplayButton>
   );
};
