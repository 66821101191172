import {
   IModalNotificationProps,
   ModalNotificationView,
} from '@/common/ui/ModalNotification/ModalNotificationView';

import * as S from './Overlay.styles';

interface IOverlayProps extends IModalNotificationProps {
   withBackdrop?: boolean;
}

export const Overlay = ({
   type,
   title,
   message = null,
   withBackdrop = true,
   onClose,
   actionButton,
}: IOverlayProps) => (
   <S.Overlay withBackdrop={withBackdrop}>
      <ModalNotificationView
         actionButton={actionButton}
         type={type}
         onClose={onClose}
         title={title}
         message={message}
      />
   </S.Overlay>
);
