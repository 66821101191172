import { deepCopy } from '@/common/utils';

import { TCallBet } from '#/state/features/bets';

import { mergeBetChips } from '../mergeBetChips';

/**
 * Sums two CallBet objects.
 *
 * @param savedBet - The saved CallBet to which the newBet will be added.
 * @param newBet - The new CallBet to add to the savedBet.
 * @returns A new CallBet object representing the sum of savedBet and newBet.
 */
export const sumAmountTwoCallBets = (savedBet: TCallBet, newBet: TCallBet): TCallBet => {
   // Create a clone of the savedBet to avoid modifying the original object.
   const summedBet = deepCopy(savedBet);

   /**
    * Update the amount in the summedBet by iterating through the extractedCommand.
    *
    * @param summedBet - The CallBet to be updated.
    * @param newBet - The new CallBet from which amounts are extracted.
    */
   const updateAmount = (summedBet: TCallBet, newBet: TCallBet) => {
      // Update the total amount
      summedBet.amount += newBet.amount;
      newBet.extractedCommand.forEach((bet, i) => {
         const { amount, chips } = bet;

         // chips and the individual bet amounts.
         summedBet.extractedCommand[i].amount += amount;
         summedBet.extractedCommand[i].chips = mergeBetChips(
            summedBet.extractedCommand[i].chips,
            chips,
         );
      });
   };

   updateAmount(summedBet, newBet);

   return summedBet;
};
