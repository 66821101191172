// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const RenderTrack = styled.div`
   opacity: 0;
   pointer-events: none;

   ${orientation.portrait`
      width: 100%;
      height: 0;
   `}

   ${({ $whenRender }) =>
      $whenRender &&
      css`
         position: absolute;
         bottom: 0;
         opacity: 1;
         pointer-events: unset;

         ${orientation.portrait`
            height: 100%;
         `}
      `}
`;
