import { TClientAuth } from '@/common/services/api/authAPI/types';

import { IAuthState } from '../types';

export const authApiAdapter = (data: TClientAuth): IAuthState => {
   const { UserId, UserName, Age, Token, TotalBalance, Currency, Country } = data.userInfo;
   const { chatToken } = data.chatInfo ?? {};
   const { sessionId, sessionSecret } = data.sessionInfo ?? {};

   return {
      user: {
         age: Age ?? 0,
         id: UserId,
         token: Token,
         country: Country ?? '',
         username: UserName ?? '',
         currency: Currency ?? '',
         totalBalance: TotalBalance ?? 0,
         chatToken,
      },
      session: {
         id: sessionId,
         secret: sessionSecret,
      },
   };
};
