import { FC, MouseEvent, ReactNode } from 'react';

import { TNullable } from '../../types';

import { EControl } from './constants';
import { icons } from './assets/icons';
import * as S from './Control.styles';
import { GeneralControlProps } from './types';

export type TControlProps = GeneralControlProps & {
  variant: EControl;
  isActive?: boolean;
  isHasInitialBorder?: boolean;
  children?: TNullable<ReactNode>;
  playTapSound: () => void;
};

export const Control: FC<TControlProps> = ({
  color,
  size = '70px',
  variant,
  disabled = false,
  isActive = false,
  isHasInitialBorder = true,
  playTapSound,
  dataTestId,
  onClick,
  children = null,
  ...otherProps
}) => {
  const Icon = icons[variant];

  const handleOnClick = (event: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick(event);
    playTapSound();
  };

  return (
    <S.ControlWrapper
      color={color}
      isHasInitialBorder={isHasInitialBorder}
      size={size}
      onClick={handleOnClick}
      disabled={disabled}
      isActive={isActive}
      data-control={true}
      data-testid={dataTestId}
      {...otherProps}
    >
      {children}
      <S.SvgWrapper>
        <Icon />
      </S.SvgWrapper>
    </S.ControlWrapper>
  );
};
