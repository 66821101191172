// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '../../../../ui/theme/mixins';
import { isMobileDevice } from '../../../../utils/checkDevice';

export const TableWrapper = styled.div`
  overflow-x: auto;
  max-width: 100%;

  ::-webkit-scrollbar {
    height: 5px;
  }
`;

export const Close = styled.div`
  position: absolute;
  right: -5px;
  top: 8px;
  display: ${isMobileDevice() ? 'block' : 'none'};

  ${orientation.portrait`
      top: 0;
      right: 0;
   `}

  ${orientation.mobileLandscape`
      right: 0;
   `}
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 3px;
`;

export const Thead = styled.thead``;

export const Tr = styled.tr`
  white-space: nowrap;
`;

export const Th = styled.th`
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.yellow};
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.55);
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  ${orientation.mobileLandscape`
    max-width: 10ch;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Tbody = styled.tbody`
  &:before {
    display: block;
    line-height: 5px;
    content: '.';
    color: rgba(0, 0, 0, 0);
  }
`;

export const Td = styled.td`
  padding: 5px 16px;
  color: ${({ theme }) => theme.colors?.white};
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: 19.5px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
`;
