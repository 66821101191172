export const enum Rank {
   ACE = 'ACE',
   KING = 'KING',
   QUEEN = 'QUEEN',
   JACK = 'JACK',
   TEN = 'TEN',
   NINE = 'NINE',
   EIGHT = 'EIGHT',
   SEVEN = 'SEVEN',
   SIX = 'SIX',
   FIVE = 'FIVE',
   FOUR = 'FOUR',
   THREE = 'THREE',
   TWO = 'TWO',
}

export const enum Suit {
   CLUBS = 'CLUBS',
   SPADES = 'SPADES',
   HEARTS = 'HEARTS',
   DIAMONDS = 'DIAMONDS',
}

export interface ICard {
   backColor?: string;
   disabled?: boolean;
   isFaceDown?: boolean;
   rank: Rank;
   size?: { width: string };
   style?: object;
   suit: Suit;
}
