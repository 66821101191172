export const enum ETableSocketEvents {
   PlayersUpdated = 'playersUpdated',
   WinNumber = 'winNumber',
   LastBets = 'lastBets',
   StartRound = 'startRound',
   RoundResult = 'roundResult',
   BettingEndTime = 'bettingEndTime',
   LastBetsStartTime = 'lastBetsStartTime',
   NewStatisticCalculated = 'newStatisticCalculated',
   CancelRound = 'cancelRound',
   CancelBet = 'cancelBet',
   CancelAutoplay = 'autoplay-interrupted',
   UpdateAutoplay = 'autoplay-update',
   RoundWinners = 'roundWinners',
   DealerLogin = 'dealerLogin',
   SessionClosed = 'sessionClosed',
   MaintenancePending = 'maintenancePending',
   MaintenanceStarted = 'maintenanceStarted',
   MaintenanceEnded = 'maintenanceEnded',
   NotifyInactivePlayer = 'notifyInactivePlayer',
   ConfigPlayerApp = 'configPlayerApp',
   BetsState = 'betsState',
   RoundState = 'roundState',
   DealerRating = 'dealerRating',
   DealerFeedbackRequest = 'dealerFeedbackRequest',
   DealerRatingUpdate = 'dealerRatingUpdate',
   DealerFeedbackRejected = 'dealerFeedbackRejected',
   DealerFeedbackLeft = 'dealerFeedbackLeft',
   UserGeneralSettingsUpdated = 'userGeneralSettingsUpdated',
   CasinoGameTypeSettingsUpdate = 'casinoGameTypeSettingsUpdated',
   UserGeneralSettings = 'userGeneralSettings',
   GameTableDeactivated = 'gameTableDeactivated',
}
