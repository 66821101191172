import { useDispatch } from 'react-redux';

import { dealerRatingEventAction } from '#/services/api/middleware/dealerRatingMiddleware/dealerRatingEventMiddleware';

import { TDealerRatingEvent } from './schemes';

export const useDealerRatingEvent = () => {
   const dispatch = useDispatch();

   return (data: TDealerRatingEvent) => {
      dispatch(dealerRatingEventAction(data));
   };
};
