import { IPlayTapSound } from '@/common/entities';

import { withTapSound } from '#/hocs/withTapSound';

import { useStatisticsActions, useStatisticsRangeSelector } from '../../../state';

import * as S from './LineStatistics.styles';

const ranges: number[] = [100, 200, 500, 1000];

const LineStatistics = ({ playTapSound }: IPlayTapSound) => {
   const statisticRange = useStatisticsRangeSelector();
   const { handleUpdateStatisticsRange } = useStatisticsActions();

   const handleRangeChange = (range: number) => (): void => {
      playTapSound();
      handleUpdateStatisticsRange(range);
   };

   return (
      <S.Line>
         {ranges.map((range) => (
            <S.Dot
               data-testid={`line-statistic-${range}`}
               key={range}
               onClick={handleRangeChange(range)}
               isSelected={range === statisticRange}
            >
               <S.Range isSelected={range === statisticRange}>{range}</S.Range>
            </S.Dot>
         ))}
      </S.Line>
   );
};

export const LineStatisticsWithTapSound = withTapSound(LineStatistics);
