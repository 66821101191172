export const getCountDownMessage = ({
   tick,
   messages,
   isLastBetsState,
}: {
   tick: number;
   messages: { noMoreBets: string; lastBets: string; placeBets: string };
   isLastBetsState: boolean;
}): string => {
   const isNoMoreBets = tick <= 0;

   if (isNoMoreBets) {
      return messages.noMoreBets;
   }

   if (isLastBetsState) {
      return messages.lastBets;
   }

   return messages.placeBets;
};
