import { AudioController, SoundVolumeAndRangeController } from '@/common/modules/GameSounds';

import { ROUND_RESULT_NUMBERS_COUNT } from '#/constants';
import { ESoundAnnouncementType, TWinnerNumbersRange } from '#/modules/Announcements/types';
import { AnnouncementId } from '#/modules/Announcements/helpers/getRandomAnnouncementId';
import { EAnnouncementType } from '#/modules/Announcements/state/announcementsState';

const ANNOUNCERS = [EAnnouncementType.Male, EAnnouncementType.Female];
const LANGUAGES = ['en', 'tr'];

export const generateRoundResultAnnouncementTypes = () => {
   // For announcing winning number we need to generate sequence
   // of announcement types for each number result
   // from 0 to 36
   return new Array(ROUND_RESULT_NUMBERS_COUNT)
      .fill(0)
      .map((_, i) => `round-result-${i}` as TWinnerNumbersRange);
};

export const createAnnouncementSound = (
   announcementSoundSrc: string,
   basedVolume: SoundVolumeAndRangeController,
): AudioController => {
   return new AudioController({
      soundUrl: announcementSoundSrc,
      basedVolume,
      options: {
         preload: true,
         autoplay: false,
         format: 'mp3',
      },
   });
};

export interface IGenerateAnnouncementSoundsPool {
   basedVolume: SoundVolumeAndRangeController;
   count: AnnouncementId;
   soundURL: string;
   types: Array<ESoundAnnouncementType | TWinnerNumbersRange>;
}
/*
 * {
 *   male: {
 *       [type]: {
 *           1: { en: Sound, tr: Sound },
 *           2: { en: Sound, tr: Sound }
 *       };
 *   }
 * }
 * */
export const generateAnnouncementSoundsPool = ({
   types,
   count,
   soundURL,
   basedVolume,
}: IGenerateAnnouncementSoundsPool) => {
   return ANNOUNCERS.reduce((acc, announcer) => {
      if (!acc[announcer]) {
         acc[announcer] = {};
      }

      types.forEach((type) => {
         acc[announcer][type] = [...Array(count)].reduce((innerAcc, _, version) => {
            const versionIndex = version + 1;
            innerAcc[versionIndex] = LANGUAGES.reduce((languageAcc, language) => {
               const announcementUrl = `${soundURL}/${language}/${announcer}/${versionIndex}/${type}.mp3`;
               languageAcc[language] = createAnnouncementSound(announcementUrl, basedVolume);
               return languageAcc;
            }, {});
            return innerAcc;
         }, {});
      });

      return acc;
   }, {});
};
