/* eslint-disable no-console */
import * as Sentry from '@sentry/react';

import { showNotification } from '@/common/ui/Notification';

import { Logger, Message, OptionalParams } from './types';

export class ConsoleLogger implements Logger {
   private transformToJson(value: unknown): string {
      try {
         const jsonString = JSON.stringify(value);
         return jsonString;
      } catch (error) {
         console.error('Error transforming value to JSON', error);
         return 'Error transforming value to JSON';
      }
   }

   private captureException(message: unknown): void {
      const logMessage = this.transformToJson(message);
      Sentry.captureException(new Error(logMessage));
   }

   log(message: Message, ...optionalParams: OptionalParams): void {
      console.log(message, ...optionalParams);
   }

   error(message: Message, ...optionalParams: OptionalParams): void {
      this.captureException(message);
      console.error(message, ...optionalParams);
   }

   errorValidation(messages: { message: string; path: string[] }[]): void {
      const printMsg = (message) => `${message.path.join(': ')} (${message.message})`;
      const [firstMessage] = messages;
      this.captureException(firstMessage);

      showNotification.error(printMsg(firstMessage), {
         style: { fontSize: '10px' },
      });
   }
}

export const logger = new ConsoleLogger();
