import { css } from 'styled-components';

export const bettingMapGridAreas = (): string => {
   const gridAreasList = Array.from({ length: 50 }).reduce(
      (total, _, index) => `${total}:nth-child(${index}) { grid-area: f_${index - 1} }`,
      '',
   );
   return css`
      ${gridAreasList}
   `;
};
