// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation, isHoverSupports, applyZIndex } from '../../../../ui/theme/mixins';

export const SidebarCloseButton = styled.div`
   --sidebar-close-icon-wrapper-size: 27px;
   --sidebar-close-icon-size: calc(var(--sidebar-close-icon-wrapper-size) - 8px);
   --sidebar-close-icon-line-width: 3px;

   ${applyZIndex('biggest')}
   position: absolute;
   right: 19px;
   top: 62px;
   width: var(--sidebar-close-icon-wrapper-size);
   height: var(--sidebar-close-icon-wrapper-size);
   border-radius: 50%;

   ${orientation.mobileLandscape`
      top: 20px;
   `}

   ${orientation.portrait`
      right: 4px;
      top: 35px;
   `}

   &:before,
   &:after {
      position: absolute;
      content: '';
      top: calc((var(--sidebar-close-icon-wrapper-size) - var(--sidebar-close-icon-size)) / 2);
      left: calc(50% - var(--sidebar-close-icon-line-width) / 2);
      width: var(--sidebar-close-icon-line-width);
      height: var(--sidebar-close-icon-size);
      background-color: ${({ theme }) => theme.colors.smokeGrey};
      border-radius: 5px;
   }

   &:before {
      transform: rotateZ(45deg);
   }

   &:after {
      transform: rotateZ(-45deg);
   }

   ${isHoverSupports`
      &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.bg.activePrimary};
      }
   `}

   &:active {
      background: ${({ theme }) => theme.bg.activePrimary};
   }
`;
