import { useState } from 'react';

import { useWindowEvent } from '@/common/hooks';
import { TNullable } from '@/common/types';

import { EDevice } from '#/layout/constants';

export const useDeviceDetector = () => {
   const [device, setDevice] = useState<TNullable<EDevice>>(null);

   const handleDevice = () => {
      if ('maxTouchPoints' in navigator) {
         const UA: string = navigator.userAgent;

         const hasTouch = navigator.maxTouchPoints > 0;
         const isMobileDevice =
            /\b(BlackBerry|webOS|iPhone|Android|Windows Phone|iPad)\b/i.test(UA) && hasTouch;

         const deviceType = isMobileDevice ? EDevice.Mobile : EDevice.Desktop;

         if (device !== deviceType) {
            setDevice(deviceType);
         }
      }
   };

   useWindowEvent('resize', handleDevice);
   handleDevice();

   return device;
};
