import { FC, memo, ReactNode } from 'react';

import * as S from './HorizontalCountDownContainer.styles';

interface IHorizontalCountDownContainerProps {
   children: ReactNode;
}

export const HorizontalCountDownContainer: FC<IHorizontalCountDownContainerProps> = memo(
   ({ children }) => <S.HorizontalCountDownContainer>{children}</S.HorizontalCountDownContainer>,
);
