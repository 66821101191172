import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { EStreamingQualities } from '@/common/modules/LiveStreamPlayer/constants';

import { TRootState } from '#/state/types';

export interface IVideoState {
   quality: number;
}

const initialState: IVideoState = {
   quality: EStreamingQualities.Medium, // start form medium video quality
};

export const videoQualitySlice = createSlice({
   name: 'videoQuality',
   initialState,
   reducers: {
      setVideoQuality: (state, action: PayloadAction<number>): void => {
         state.quality = action.payload;
      },
   },
});

export const useVideoQualityActions = () => {
   const dispatch = useDispatch();
   const { setVideoQuality } = videoQualitySlice.actions;

   const handleSetVideoQuality = (quality: number): void => {
      dispatch(setVideoQuality(quality));
   };

   return {
      handleSetVideoQuality,
   };
};

export const useVideoQualitySelector = () => useSelector((state: TRootState) => state.videoQuality);

export const useVideoQuality = () => {
   const { quality } = useVideoQualitySelector() ?? {};
   const { handleSetVideoQuality } = useVideoQualityActions();

   return {
      quality,
      handleSetVideoQuality,
   };
};
