import { IPlayTapSound } from '@/common/entities';
import { useChat } from '@/common/modules/Chat';

import { Control } from '../../Control';
import { EControl } from '../../constants';

export const ChatControlView = ({ playTapSound }: IPlayTapSound) => {
  const { isChatEnabled, handleToggleChat } = useChat();

  return (
    <Control
      dataTestId="chat-control"
      isActive={isChatEnabled}
      onClick={handleToggleChat}
      variant={EControl.Chat}
      playTapSound={playTapSound}
    />
  );
};
