// @ts-nocheck
import styled from 'styled-components/macro';

export const StatisticByColorWrapper = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   gap: 7px;
   margin-bottom: 15px;
`;
