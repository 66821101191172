// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const DesktopWrapper = styled.div`
   margin: 5px 0 20px 5px;
`;

export const DesktopLabel = styled.div`
   display: flex;
   align-items: center;
   gap: 0 32px;
   margin-bottom: 10px;
   font-size: 20px;
   font-weight: bold;

   ${orientation.portrait`
      margin-bottom: 20px;
      gap: 25px;
      font-size: 19px;
   `}
`;

export const DesktopLabelIcon = styled.div`
   --menu-icon-size: 50px;

   width: var(--menu-icon-size);
   height: var(--menu-icon-size);
   transform: translateX(-5px);

   ${orientation.portrait`
      --menu-icon-size: 40px;
   `}
`;

export const DesktopLabelContent = styled.div`
   margin-top: -5px;
   font-weight: 600;
`;
