import { FC, ReactNode } from 'react';

import { CloseModal } from '../Modal/components/CloseModal';

import * as S from './ModalAction.styles';

export interface IModalActionProps {
  actionButton: {
    title: string;
    onClick: () => void;
    disabled?: boolean;
    dataId: string;
  };
  content?: ReactNode;
  feedbackScreen?: ReactNode;
  isFeedbackScreenShown?: boolean;
  message?: ReactNode;
  onClose?: () => void;
  title?: string;
}

export const ModalAction: FC<IModalActionProps> = ({
  onClose,
  actionButton,
  content = '',
  title = '',
  message = '',
  feedbackScreen = '',
  isFeedbackScreenShown = false,
}) => (
  <S.ModalActionsWrapper isFeedbackScreenShown={isFeedbackScreenShown}>
    <S.ActionIcon>
      <CloseModal onClose={onClose} />
    </S.ActionIcon>
    {isFeedbackScreenShown ? (
      feedbackScreen
    ) : (
      <S.ModalContent>
        <S.Title>{title}</S.Title>
        {message && <S.Message>{message}</S.Message>}

        {content && <S.Content>{content}</S.Content>}
        <S.Button onClick={actionButton.onClick} disabled={actionButton.disabled}>
          {actionButton.title}
        </S.Button>
      </S.ModalContent>
    )}
  </S.ModalActionsWrapper>
);
