import { LocalStorage } from '@/common/utils/LocalStorage';

interface IChatPosition {
   x: number;
   y: number;
}

class ChatPosition {
   private chatPositionStorage: LocalStorage;

   constructor() {
      this.chatPositionStorage = new LocalStorage('chatPosition');
   }

   public saveChatPosition({ x, y }): void {
      this.chatPositionStorage.set({ x, y });
   }

   public getChatPosition(): IChatPosition {
      if (!this.chatPositionStorage.get()) {
         return { x: 0, y: 0 };
      }

      return this.chatPositionStorage.get();
   }
}

export const chatPosition = new ChatPosition();
