import { roulettePocketsList } from '#/core/roulette';

import { IStatisticByPocket, PocketType } from '../../types';

import { isCorrectType } from './type-guards';

export const getPocketTypes = (statisticsByPockets: IStatisticByPocket[]) => {
   const NUMBER_OF_ROULETTE_CELLS = roulettePocketsList.length - 1;

   return statisticsByPockets.reduce<Record<string, PocketType>>(
      (types, pocketStatistic, cellId) => {
         const cellNumberIsCorrect = cellId <= NUMBER_OF_ROULETTE_CELLS;

         if (cellNumberIsCorrect && isCorrectType(pocketStatistic.type)) {
            types[cellId] = pocketStatistic.type;
         }

         return types;
      },
      {},
   );
};
