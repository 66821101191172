import { useEffect, useState } from 'react';

import { useAutoplay } from '#/modules/Autoplay/hooks';

export const useTooltip = (inputValue, sidebarType) => {
   const [tooltip, setShowTooltip] = useState({ show: false, message: '' });
   const { autoplay } = useAutoplay();

   const tooltipMessage = (value) => {
      const [emptyLossLimit, lessThanBets] = [
         'sidebars.autoplay.emptyLossLimit',
         'sidebars.autoplay.lessThanBets',
      ];

      const isEmptyLossLimit = value === '' ? emptyLossLimit : '';
      const isLessThanBets = Number(value) < autoplay.getBets() ? lessThanBets : '';

      return isEmptyLossLimit || isLessThanBets;
   };

   useEffect(() => {
      const message = tooltipMessage(inputValue);
      setShowTooltip({
         show: Boolean(message),
         message,
      });
   }, [inputValue]);

   useEffect(() => {
      setShowTooltip({
         show: false,
         message: '',
      });
   }, [sidebarType]);

   return tooltip;
};
