import { useDispatch } from 'react-redux';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { iterateObject } from 'helpers/iterateObject';

import { addNewBets, chipZIndexes, getBetIdx, removeBet, undoBet } from './helpers';
import { TBetType, IChipsZIndexes, TDoubleBet, TExcludeBetFromBetList, TTotalBets } from './types';

export interface IBetsState {
   betsHistory: TBetType[];
   totalBets: TTotalBets;
   zIndexes: IChipsZIndexes;
}

export const BETS_ACTIONS = {
   addBets: 'addBets',
   undo: 'undo',
   removeBetById: 'removeBetById',
   addBetsFromOutside: 'addBetsFromOutside',
   addDoubleBet: 'addDoubleBet',
   resetBets: 'resetBets',
   resetBetOnCancelRound: 'resetBetOnCancelRound',
} as const;

const initialState: IBetsState = {
   totalBets: {},
   betsHistory: [],
   zIndexes: {},
};

export const betsSlice = createSlice({
   name: 'bets',
   initialState,
   reducers: {
      [BETS_ACTIONS.removeBetById]: (state, action: PayloadAction<number>) => {
         const [totalBets, betsHistory] = removeBet({
            totalBets: state.totalBets,
            betsHistory: state.betsHistory,
            betId: action.payload,
         });

         state.totalBets = totalBets;
         state.betsHistory = betsHistory;
         state.zIndexes = chipZIndexes.removeZIndex(state.zIndexes, action.payload);
      },

      [BETS_ACTIONS.addBets]: (
         state,
         action: PayloadAction<TExcludeBetFromBetList<TDoubleBet>[]>,
      ): void => {
         const [totalBets, betsHistory] = addNewBets({
            totalBets: state.totalBets,
            betsHistory: state.betsHistory,
            newBets: action.payload,
         });

         state.zIndexes = chipZIndexes.updateZIndexes({
            zIndexes: state.zIndexes,
            updateZIndexes: getBetIdx(action.payload),
         });

         state.totalBets = totalBets;
         state.betsHistory = betsHistory;
      },

      [BETS_ACTIONS.addBetsFromOutside]: (state, action) => {
         const { totalBets, betsHistory, zIndexes } = action.payload as IBetsState;
         state.betsHistory.push(...betsHistory);
         state.zIndexes = chipZIndexes.mergeZIndexes(state.zIndexes, zIndexes);

         iterateObject(totalBets, (key, value) => {
            state.totalBets[key] = state.totalBets[key] ? state.totalBets[key] + value : value;
         });
      },

      [BETS_ACTIONS.addDoubleBet]: (state, action): void => {
         const [totalBets, betsHistory] = action.payload;

         state.totalBets = totalBets;
         state.betsHistory = betsHistory;
      },

      [BETS_ACTIONS.resetBets]: (state): void => {
         state.totalBets = {};
         state.zIndexes = {};
         state.betsHistory = [];
      },

      [BETS_ACTIONS.resetBetOnCancelRound]: (state): void => {
         state.totalBets = {};
         state.zIndexes = {};
         state.betsHistory = [];
      },

      [BETS_ACTIONS.undo]: (state): void => {
         const [commands, betsHistory] = undoBet({
            totalBets: state.totalBets,
            betsHistory: state.betsHistory,
         });

         state.totalBets = commands;
         state.betsHistory = betsHistory;
         state.zIndexes = chipZIndexes.getPreviousZIndexes(state.zIndexes);
      },
   },
});

export const useBetsActions = () => {
   const dispatch = useDispatch();

   const { addBetsFromOutside } = betsSlice.actions;

   const handleAddBetOutside = (bets) => {
      dispatch(addBetsFromOutside(bets));
   };

   return {
      handleAddBetOutside,
   };
};
