import { z } from 'zod';

const sessionInfo = z.object({
   sessionId: z.string(),
   sessionSecret: z.string(),
});

const userInfo = z.object({
   Age: z.number().nullable(),
   Country: z.string().nullable(),
   Currency: z.string().nullable(),
   Sex: z.string().nullable(),
   Token: z.string(),
   TotalBalance: z.number().nullable(),
   UserId: z.string(),
   UserName: z.string().nullable(),
});

const chatInfo = z.object({
   chatToken: z.string(),
});

export const clientAuthSchema = z.object({
   sessionInfo,
   userInfo,
   chatInfo,
});
