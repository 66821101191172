export const enum EStreamingQualities {
   Low = 0,
   Medium = 1,
   High = 2,
}

export const reverseStreamingQualities = {
   0: 'LOW',
   1: 'MEDIUM',
   2: 'HIGH',
};

export enum EStreamingUpdateMode {
   Auto = 'auto',
   Manual = 'manual',
}
