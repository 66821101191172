import {
   EStatisticsTab,
   useStatisticsActions,
   useStatisticsTypeSelector,
} from '#/modules/Statistics/state';

export const useStatisticsTabs = () => {
   const { handleSetStatisticType } = useStatisticsActions();
   const statisticType = useStatisticsTypeSelector();
   const isStatisticTab = statisticType === EStatisticsTab.STATISTICS;

   return { statisticType, setStatisticType: handleSetStatisticType, isStatisticTab };
};
