// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const WrapperBottom = styled.div`
   mask-image: linear-gradient(to top, black 125px, transparent 100%);
   mask-mode: alpha;

   ${orientation.mobileLandscape`
        mask-image: linear-gradient(to top, black 196px, transparent 100%);
   `}
`;

export const WrapperTop = styled.div`
   mask-image: linear-gradient(to bottom, black 125px, transparent 100%);
   mask-mode: alpha;
   position: absolute;
   top: 45px;
   width: calc(100% - 35px);

   ${orientation.mobileLandscape`
        mask-image: linear-gradient(to bottom, black 196px, transparent 100%);
   `};
`;
