import { TWinNumberEvent } from '@/common/services/sockets/useWinNumber/types';
import { EGameState, useGameState } from '@/common/state/gameState';
import { winHistoryDTO } from '@/common/utils';

import { useWinHistory } from '#/modules/WinHistory';
import { useWinNumber } from '#/modules/WinNumbers';
import { playWinSound } from '#/core/sounds';

import { fireWinNumberEvent } from './helpers';

type UseWinNumberOutput = (winNumberEvent: TWinNumberEvent) => void;

export const useWinNumberEvent = (): UseWinNumberOutput => {
   const { handleChangeGameState } = useGameState();
   const { handleAddWinNumberToWinHistory } = useWinHistory();
   const { handleSetWinNumber } = useWinNumber();

   return ({ winNumber, winResults, timestampMs }) => {
      // TODO (tech): hack with volume should be handled by single source of truth
      playWinSound();

      fireWinNumberEvent({ timestampMs, winNumber, handleSetWinNumber });
      handleAddWinNumberToWinHistory(winHistoryDTO({ winNumber, winResults }));

      handleChangeGameState(EGameState.WIN_NUMBER);
   };
};
