import { TBetType } from '../../types';
import { isDoubleBetType, isSpecialBetType, isRangeStraightBetType } from '../../type-guards';

export const getBetIdx = (commands: TBetType[]) => {
   const result: number[] = [];

   for (const command of commands) {
      const isRangeStraightBet = isRangeStraightBetType(command);
      const isSpecialType = isSpecialBetType(command);
      const isDoubleBet = isDoubleBetType(command);

      if (isRangeStraightBet || isSpecialType) {
         result.push(...getBetIdx(command.extractedCommand));
      } else if (isDoubleBet) {
         result.push(...getBetIdx(command.partialDoubled));
      } else {
         result.push(command.id);
      }
   }

   return result;
};
