import { TDoubleBet, ETypeBet } from '#/state/features/bets';

export class DoubleCommand {
   execute = (): TDoubleBet => ({
      type: ETypeBet.DOUBLE,
      partialDoubled: [],
      partialTotalBets: {},
      amount: 0,
   });
}
