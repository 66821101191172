import { useDispatch } from 'react-redux';

import { dealerFeedbackRequestEventAction } from '#/services/api/middleware/dealerRatingMiddleware/dealerFeedbackRequestEventMiddleware';

import { TDealerFeedbackRequestEvent } from './schemes';

export const useDealerFeedbackRequestEvent = () => {
   const dispatch = useDispatch();

   return (data: TDealerFeedbackRequestEvent) => {
      dispatch(dealerFeedbackRequestEventAction(data));
   };
};
