import { FC } from 'react';

import { CloseIcon } from '../assets';
import { ENotificationActionColors } from '../constants';
import { INotification } from '../state';
import * as S from '../Notifications.styles';

import { NotificationAction } from './NotificationAction';
import { NotificationIcon } from './NotificationIcon';

interface INotificationTemplateProps extends INotification {
   handleCloseNotification: () => void;
}

export const NotificationTemplate: FC<INotificationTemplateProps> = ({
   title,
   action,
   content,
   type,
   handleCloseNotification,
}) => {
   return (
      <S.Wrapper borderColor={ENotificationActionColors[type]}>
         <div>
            <NotificationIcon type={type} />
         </div>
         <S.NotificationContentWrapper>
            <S.Title>{title}</S.Title>
            <S.Content data-testid={`${type}-notification-content`}>{content}</S.Content>
            <NotificationAction type={type} action={action} />
            <S.CloseIconWrapper onClick={handleCloseNotification}>
               <CloseIcon />
            </S.CloseIconWrapper>
         </S.NotificationContentWrapper>
      </S.Wrapper>
   );
};
