import { LIMIT_TOOLTIP_CLASSNAME } from '#/modules/LimitTooltip/constants';
import { WarningTooltip } from '#/modules/Tooltips/TooltipPortal';
import { ELimitTooltip, useLimitTooltipSelector } from '#/modules/LimitTooltip/state/index';

export const LimitTooltip = () => {
   const activeTooltip = useLimitTooltipSelector();
   const isActiveMaxLimitTooltip = activeTooltip === ELimitTooltip.Max;

   const tooltipMessage = isActiveMaxLimitTooltip
      ? 'tooltip.limit.maxLimit'
      : 'tooltip.limit.minLimit';

   return (
      activeTooltip && (
         <WarningTooltip
            shouldShowNotification={activeTooltip}
            message={tooltipMessage}
            selector={LIMIT_TOOLTIP_CLASSNAME}
         />
      )
   );
};
