import { memo } from 'react';

import { DolbyPlayer } from '@/common/modules/LiveStreamPlayer/Dolby/components';
import { JanusPlayer } from '@/common/modules/LiveStreamPlayer/Janus/components';
import { useGameState } from '@/common/state/gameState/gameState';

import { useSmallVideoMode } from '#/modules/LiveStream/state';
import { useGameConfigStore } from '#/services/api/gameConfigApiSlice/state';
import { useVideoQuality } from '#/modules/LiveStream/state/videoQuality';
import { useVideo } from '#/modules/LiveStream/state/video';

import { useVideoLoading, VideoLoadingScreen } from './components/VideoLoadingScreen';
import { LiveStreamSmallMode } from './components';
import * as S from './LiveStream.styles';

const checkDolbyPlayerFeature = (): boolean => import.meta.env.VITE_STREAMING_PLAYER === 'DOLBY';

export const LiveStream = memo(() => {
   const { mode, volume, mute } = useVideo();
   const { quality } = useVideoQuality();
   const { gameConfig } = useGameConfigStore();
   const { isSmallVideo } = useSmallVideoMode();
   const { handleSetVideoQuality } = useVideoQuality();
   const { handleShowVideoLoader, handleHideVideoLoader } = useVideoLoading();
   const { isSessionClosedDueToInactivity } = useGameState();

   const handlePauseLiveStream = () => {
      if (!isSessionClosedDueToInactivity) {
         handleShowVideoLoader();
      }
   };

   return (
      <>
         <S.VideoWrapper data-testid="Video" id="video-container">
            <LiveStreamSmallMode>
               {checkDolbyPlayerFeature() ? (
                  <DolbyPlayer
                     muted={mute}
                     volume={volume}
                     quality={quality}
                     qualityMode={mode}
                     streamId={gameConfig?.streamId}
                     onPause={handlePauseLiveStream}
                     onPlaying={handleHideVideoLoader}
                     onQualityChange={handleSetVideoQuality}
                  />
               ) : (
                  <JanusPlayer
                     mode={mode}
                     muted={mute}
                     volume={volume}
                     quality={quality}
                     serverURL={gameConfig?.esUrl}
                     streamId={gameConfig.streamId}
                     onPause={handlePauseLiveStream}
                     onSuspend={handleCheckLowPowerMode}
                     onPlaying={(event) => {
                        handleShowVideoPlayer(event);
                        handleHideVideoLoader();
                     }}
                     onQualityChange={handleSetVideoQuality}
                  />
               )}
               {isSmallVideo && <VideoLoadingScreen />}
            </LiveStreamSmallMode>
         </S.VideoWrapper>
      </>
   );
});

LiveStream.displayName = 'LiveStream';
