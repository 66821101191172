import { useDispatch } from 'react-redux';

import { Autoplay } from '#/core/autoplay';
import { useUserSelector } from '#/services';
import { useTotalBetsAmountSelector } from '#/modules/Balance';

import { useAutoplayActions, useAutoplaySelectors } from '../state';

export const useAutoplay = () => {
   const dispatch = useDispatch();

   return {
      autoplay: new Autoplay({
         autoplayModel: useAutoplayHookConstructor(),
         autoplayActions: { ...useAutoplayActions(dispatch) },
      }),
   };
};

const useAutoplayHookConstructor = () => {
   const {
      useAutoplayId,
      useAutoplayRounds,
      useSingleWinLimit,
      useAutoplayStartState,
      useLossLimit,
      useSelectedAutoplayRound,
      useAutoplayRoundCount,
      useUserLossProgress,
      useIsLoading,
   } = useAutoplaySelectors();
   const { id: userId } = useUserSelector();

   return {
      autoplayRounds: useAutoplayRounds(),
      autoplayId: useAutoplayId(),
      bets: useTotalBetsAmountSelector(),
      isAutoplayStarted: useAutoplayStartState(),
      lossLimit: useLossLimit(),
      singleWinLimit: useSingleWinLimit(),
      selectedRound: useSelectedAutoplayRound(),
      userId,
      roundCounter: useAutoplayRoundCount(),
      userLossProgress: useUserLossProgress(),
      isLoading: useIsLoading(),
   };
};

export const createAutoplay = (dispatch, state) => {
   const {
      autoplayId,
      isAutoplayStarted,
      autoplayRounds,
      lossLimit,
      selectedRound,
      singleWinLimit,
      roundCounter,
      userLossProgress,
      isLoading,
   } = state;
   return {
      autoplay: new Autoplay({
         autoplayModel: {
            autoplayId,
            isAutoplayStarted,
            autoplayRounds,
            lossLimit,
            selectedRound,
            singleWinLimit,
            roundCounter,
            userLossProgress,
            isLoading,
         },

         autoplayActions: useAutoplayActions(dispatch),
      }),
   };
};
