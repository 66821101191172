import { useEffect } from 'react';

import { EControl } from '@/common/ui';
import { icons } from '@/common/ui/Control/assets/icons/';
import { useGameState } from '@/common/state/gameState';
import { IPlayTapSound } from '@/common/entities';

import { useCollapsedActivePlayingAreaState } from '#/modules/Track/state';
import { withTapSound } from '#/hocs/withTapSound';

import * as S from './CollapseBettingAreaControl.styles';

const CollapseBettingAreaControl = ({ playTapSound }: IPlayTapSound) => {
   const { isBettingTimeState, isWaitingForConnectionGameStatus } = useGameState();
   const {
      isCollapsedActivePlayingArea,
      handleToggleCollapsedActivePlayingArea,
      handleSetCollapsedActivePlayingArea,
   } = useCollapsedActivePlayingAreaState();
   const isHideCollapseButton = isWaitingForConnectionGameStatus || !isBettingTimeState;

   const collapsedIcon = isCollapsedActivePlayingArea ? EControl.Collapse : EControl.CollapseExpand;
   const Icon = icons[collapsedIcon];

   useEffect(() => {
      if (!isBettingTimeState) {
         const defaultState = false;
         handleSetCollapsedActivePlayingArea(defaultState);
      }
   }, [isBettingTimeState]);

   return !isHideCollapseButton ? (
      <S.Icon>
         <Icon
            onClick={() => {
               playTapSound();
               handleToggleCollapsedActivePlayingArea();
            }}
         />
      </S.Icon>
   ) : (
      ''
   );
};

export const CollapseBettingAreaControlWithTapSound = withTapSound(CollapseBettingAreaControl);
