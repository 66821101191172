import { useMobileOrientation } from 'react-device-detect';

import { isMobileDevice } from '../../utils/checkDevice';

import { DeviceOrientation } from './types';

interface ReactDetectOrientation {
   isLandscape: boolean;
   isPortrait: boolean;
   orientation: 'landscape' | 'portrait';
}

export const useDeviceOrientation = (): DeviceOrientation => {
   const { isPortrait }: ReactDetectOrientation = useMobileOrientation();

   // change PORTRAIT or LANDSCAPE mode for only mobile devices. For desktop device use only LANDSCAPE mode
   if (isMobileDevice()) {
      return isPortrait ? DeviceOrientation.PORTRAIT : DeviceOrientation.LANDSCAPE;
   }

   return DeviceOrientation.LANDSCAPE;
};
