import { streetConfig } from 'core/config';

export const generateStreetGrid = (): string =>
   streetConfig.getArrayOfBetIds().reduce((acc, id, index) => {
      // for numbers 0,1,2 (first on list) we place the cell above and to the left
      if (index === 0) {
         return `${acc}
               .cell_${id} {
                  position: absolute;
                  top: calc((var(--bm-big-cell-height) * 2) - var(--half-cell-width));
                  width: var(--cell-size);
                  height: var(--cell-size);
                  left: calc(var(--bm-small-cell-width) - var(--half-cell-width));
                  background: transparent;
                  box-shadow: none;
               }`;
      }
      // for numbers 0,2,3 (first on list) we place the cell above and to the left
      if (index === 1) {
         return `${acc}
               .cell_${id} {
                  position: absolute;
                  top: calc(var(--bm-big-cell-height) - var(--half-cell-width));
                  width: var(--cell-size);
                  height: var(--cell-size);
                  left: calc(var(--bm-small-cell-width) - var(--half-cell-width));
                  background: transparent;
                  box-shadow: none;
               }`;
      }
      return (
         // we substract 2 from index because first two indexes we custom place
         `${acc}
            .cell_${id} {
               position: absolute;
               top: calc((var(--bm-big-cell-height) * 3) - var(--half-cell-width));
               width: var(--cell-size);
               height: var(--cell-size);
               left: calc(var(--bm-small-cell-width) + (var(--bm-big-cell-width) * ${
                  index - 2
               }) + ((var(--bm-big-cell-width) - var(--cell-size)) / 2));
               background: transparent;
               box-shadow: none;
            }`
      );
   }, '');

export const generateStreetGridPortrait = (): string =>
   streetConfig.getArrayOfBetIds().reduce((acc, id, index) => {
      // for numbers 0,2,3 (first on list) we place the cell above and to the left
      if (index === 0) {
         return `${acc}
            .cell_${id} {
               position: absolute;
               top: calc(var(--bm-small-cell-height) - var(--half-cell-width));
               width: var(--cell-size);
               height: var(--cell-size);
               right: calc((var(--bm-big-cell-width) * 2) - var(--half-cell-width));
               left: unset;
               background: transparent;
               box-shadow: none;
            }`;
      }
      // for numbers 0,1,2 (second on list) we place the cell above and to the left
      if (index === 1) {
         return `${acc}
            .cell_${id} {
               position: absolute;
               top: calc(var(--bm-small-cell-height) - var(--half-cell-width));
               width: var(--cell-size);
               height: var(--cell-size);
               right: calc(var(--bm-big-cell-width) - var(--half-cell-width));
               left: unset;
               background: transparent;
               box-shadow: none;
            }`;
      }

      return (
         // we substract 2 from index because first two indexes we custom place
         `${acc}
           .cell_${id} {
              position: absolute;
              top: calc(var(--bm-small-cell-height) + (var(--bm-big-cell-height) * ${
                 index - 2
              }) + ((var(--bm-big-cell-height) - var(--cell-size)) / 2));
              width: var(--cell-size);
              height: var(--cell-size);
              left: calc((var(--bm-small-cell-width)) * 2 - var(--half-cell-width));

              background: transparent;
              box-shadow: none;
           }`
      );
   }, '');
