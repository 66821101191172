// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { orientation, isHoverSupports } from '../../../../ui/theme/mixins';
import * as SidebarCloseButtonStyles from '../../components/SidebarCloseButton/SidebarCloseButton.styles';

export const SidebarContentHeader = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   height: var(--sidebar-header-height);

   &:empty {
      --sidebar-header-height: 0;
      ${SidebarCloseButtonStyles.SidebarCloseButton} {
         background-color: red;
      }
   }
`;

export const SidebarContentWrapper = styled.div`
   --sidebar-width: 100%;
   --sidebar-header-height: 150px;

   --sidebar-padding-left: 75px;
   --sidebar-padding-right: 50px;
   --sidebar-background-color: rgba(0, 0, 0, 0.9);

   --sidebar-title-fz: 20px;
   --sidebar-title-icon-size: 50px;

   --scrollbar-margin-right: 25px;

   width: var(--sidebar-width);
   height: 100%;
   padding-left: var(--sidebar-padding-left);
   background: var(--sidebar-background-color);

   > .scrollbar {
      margin-right: var(--scrollbar-margin-right);
      width: calc(100% - var(--scrollbar-margin-right));
      /* height: 930px; */
      height: 100%;

      /* ::-webkit-scrollbar-track-piece:start {
         margin-top: 115px;
      } */

      ${orientation.mobileLandscape`
        --scrollbar-margin-right: 30px;
      `}

      ${orientation.portrait`
        --scrollbar-margin-right: 15px;
      `}
   }

   ${orientation.mobileLandscape`
      --sidebar-header-height: 70px;
      --sidebar-padding-left: 40px;

      left: -70px;
   `}

   ${orientation.portrait`
      --sidebar-header-height: 100px;
      --sidebar-padding-left: 20px;

      width: 100vw;
   `}

   + ${SidebarContentHeader}:empty {
      --sidebar-header-height: 0;
   }
`;

export const SidebarContentHeaderTitle = styled.div`
   font-size: var(--sidebar-title-fz);
   color: ${({ theme }) => theme.colors?.main};
   font-family: 'Montserrat', sans-serif;
   font-weight: 700;
   margin-left: 20px;
   margin-right: auto;

   ${orientation.portrait`
      margin-left: 5px;
      --sidebar-title-fz: 16px;
   `}

   ${orientation.mobileLandscape`
      --sidebar-title-fz: 14px;
      margin-left: 0;
   `}
`;

export const SidebarContentHeaderIcon = styled.div`
   width: var(--sidebar-title-icon-size);
   height: var(--sidebar-title-icon-size);
   color: ${({ theme }) => theme.colors?.main};
`;

export const SidebarContentHeaderCloseButton = styled.div`
   --sidebar-close-icon-wrapper-size: 27px;
   --sidebar-close-icon-size: calc(var(--sidebar-close-icon-wrapper-size) - 8px);
   --sidebar-close-icon-line-width: 3px;

   position: absolute;
   right: 19px;
   top: 75px;
   cursor: pointer;
   width: var(--sidebar-close-icon-wrapper-size);
   height: var(--sidebar-close-icon-wrapper-size);
   cursor: pointer;
   border-radius: 50%;

   &:before,
   &:after {
      position: absolute;
      content: '';
      top: calc((var(--sidebar-close-icon-wrapper-size) - var(--sidebar-close-icon-size)) / 2);
      left: calc(50% - var(--sidebar-close-icon-line-width) / 2);
      width: var(--sidebar-close-icon-line-width);
      height: var(--sidebar-close-icon-size);
      background-color: ${({ theme }) => theme.colors.smokeGrey};
      border-radius: 5px;
   }

   &:before {
      transform: rotateZ(45deg);
   }

   &:after {
      transform: rotateZ(-45deg);
   }

   ${isHoverSupports`
      &:hover {
        background: ${({ theme }) => theme.bg.activePrimary};
      }
   `}

   &:active {
      background: ${({ theme }) => theme.bg.activePrimary};
   }
   ${orientation.mobileLandscape`
      top: 20px;
   `}

   ${orientation.portrait`
      right: 4px;
      top: 10px;
   `}
`;

export const SidebarContentBody = styled.div`
   width: 100%;
   overflow: hidden;
   height: 100%;
   padding-right: var(--sidebar-padding-right);
   scroll-behavior: smooth;

   ${orientation.portrait`
     --sidebar-padding-right: 15px;
   `}

   ${orientation.mobileLandscape`
      --sidebar-padding-right: 25px;
   `}

   ${({ isScrollableBody }) =>
      isScrollableBody &&
      css`
         overflow-y: auto;
         height: calc(100% - var(--sidebar-header-height));
      `}
`;
