import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { ITrackComponentState } from '#/state/features/bets';
import { TRaceTrackTypeBets } from '#/state/features/bets/types';

import { IChipPosition, IRaceTrackChip } from '../../types';
import { RACETRACK_CELL_CLASSNAME } from '../../constants';

interface ICalibrateChips {
   chipSize: number;
   raceTrackContainerRef: RefObject<SVGSVGElement>;
   trackStore: () => ITrackComponentState;
}
export const useCalibrateChips = ({
   chipSize,
   trackStore,
   raceTrackContainerRef,
}: ICalibrateChips): IRaceTrackChip[] => {
   const [calibratedChips, setCalibratedChips] = useState<IRaceTrackChip[]>([]);
   const cachedChipsCoords = useRef<Record<string, IChipPosition>>({});
   const { useRaceTrackBetsSelector, useBetsHistorySelector } = trackStore();
   const bets = useRaceTrackBetsSelector();
   const betsHistory = useBetsHistorySelector();
   const svgContainer = raceTrackContainerRef.current;

   const calibrateChip = useCallback(
      ({
         cellId,
         betValue,
         type,
      }: {
         cellId: string;
         betValue: number;
         type: TRaceTrackTypeBets;
      }) => {
         const calibratedChip: IRaceTrackChip = {
            cellId,
            betValue,
            x: null,
            y: null,
            size: null,
            type,
         };

         if (!svgContainer) {
            return null;
         }

         const cell = svgContainer.querySelector(
            `.${RACETRACK_CELL_CLASSNAME}_${cellId} > path:last-child`,
         );

         if (!cell) {
            return null;
         }

         const {
            x = null,
            y = null,
            width = 0,
            height = 0,
         } = (cell as SVGSVGElement)?.getBBox() ?? {};

         if (x && y && chipSize) {
            const calculateOffsetX = Math.floor(x - (chipSize - width) / 2);
            const calculateOffsetY = Math.floor(y - (chipSize - height) / 2);

            cachedChipsCoords.current[cellId] = {
               x: calculateOffsetX,
               y: calculateOffsetY,
            };

            calibratedChip.x = calculateOffsetX;
            calibratedChip.y = calculateOffsetY;
         }

         return calibratedChip;
      },
      [cachedChipsCoords, svgContainer, chipSize],
   );

   const getCalibratedChips = (): IRaceTrackChip[] => {
      const calibratedChips: IRaceTrackChip[] = [];

      for (const cellId in bets) {
         const { amount, type } = bets[cellId];
         const calibratedChip = calibrateChip({ cellId, betValue: amount, type });
         if (calibratedChip) {
            // todo added cache for saved coordinates
            calibratedChips.push(calibratedChip);
         }
      }

      return calibratedChips;
   };

   useEffect(() => {
      setCalibratedChips(getCalibratedChips());
   }, [chipSize, betsHistory]);

   return calibratedChips;
};
