import { useSidebarSelector } from '@/common/modules/Sidebar';

import { THandleShowMaxBetTooltip, positioningTooltip } from '#/modules/LimitTooltip';

import { useTooltip } from '../../hooks';

export const LossLimitTooltipWrapper = ({ children, inputValue }) => {
   const sidebarType = useSidebarSelector();
   const tooltip = useTooltip(inputValue, sidebarType);

   const handleShowTooltip: THandleShowMaxBetTooltip = (element) => {
      positioningTooltip(element.target as HTMLElement, '.loss-limit-notification');
   };

   return children({ handleShowTooltip, tooltip });
};
