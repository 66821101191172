import { useAutoplay } from '#/modules/Autoplay/hooks';

import { UpdateAutoplayEvent } from './schemes';

type UpdateAutoplayEventOutput = (socket: UpdateAutoplayEvent) => void;

export const useUpdatedAutoplayEvent = (): UpdateAutoplayEventOutput => {
   const { autoplay } = useAutoplay();

   return ({ roundsLeft, lossLimit, lossLimitLeft }) => {
      autoplay.handleSetUserLossProgress(lossLimit, lossLimitLeft);
      autoplay.handleSetAutoplayLeftRounds(roundsLeft);
   };
};
