import { TCommonBet, ETypeBet, TRangeStraightBet } from '#/state/features/bets';

import { Command } from './Command';

export type RangeCommandType = Pick<TRangeStraightBet, 'amount' | 'title'> & {
   numbers: TCommonBet['numbers'];
};

export class RangeStraightCommand {
   private readonly type: ETypeBet = ETypeBet.STRAIGHT;
   private readonly numbers: RangeCommandType['numbers'];
   private readonly amount: RangeCommandType['amount'];
   private readonly title: RangeCommandType['title'] = '';
   private readonly extractedCommand: TRangeStraightBet['extractedCommand'];

   constructor({ numbers, amount, title }: RangeCommandType) {
      this.numbers = numbers;
      this.amount = amount;
      this.extractedCommand = this.extractCommand();

      if (title) {
         this.title = title;
      }
   }

   execute = (): TRangeStraightBet => ({
      title: this.title,
      amount: this.amount * this.numbers.length,
      type: this.type,
      extractedCommand: this.extractedCommand,
   });

   extractCommand = () => {
      return this.numbers.map((number) => {
         const newCommand = new Command({
            id: number,
            type: this.type,
            numbers: [number],
            amount: this.amount,
         });

         return newCommand.execute();
      });
   };
}
