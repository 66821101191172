import { pipe } from '@/common/utils';
import { isGameLoadedSuccessful } from '@/common/state/gameState/utils';

import { Bets } from '#/core/bets';
import { doubleTotalBets, getTotalBetsAmount } from '#/core/bets/utils';
import { sumBetsById } from '#/services/api/betsApiSlice/helpers';
import { extractDoubleBet } from '#/services/api/betsApiSlice/helpers/extractDoubleBet';
import { CallBetType } from '#/modules/Track/BettingMap/commands/CallBetCommand';
import { RangeCommandType } from '#/modules/Track/BettingMap/commands/RangeStraightCommand';
import { TBetType, TCommonBet, extractRangeAndSpecialBets } from '#/state/features/bets';
import { Store } from '#/state/types';
import { isCallBetType } from '#/state/features/bets/type-guards';

import { AbstractBetsCreator } from './AbstractBetsCreator';

export class BetsGeneratorCreator extends AbstractBetsCreator {
   private bets: Bets;

   constructor({ initBets }: { initBets: (store: Store) => Bets }) {
      super({});

      import('#/state').then(({ store }) => {
         store.subscribe(() => {
            const gameStatus = store.getState().gameState.gameStatus;

            if (isGameLoadedSuccessful(gameStatus)) {
               this.bets = initBets(store);
            }
         });
      });
   }

   private saveBets = (bets: TBetType[]): void => {
      this.bets.addBets(bets);
   };

   getBets = () => {
      const getTotalBets = pipe(extractDoubleBet, extractRangeAndSpecialBets, sumBetsById);

      return {
         totalBets: this.bets.getTotalBets(),
         zIndexes: this.bets.getZIndexes(),
         betsHistory: getTotalBets(this.bets.getBetsHistory()),
      };
   };

   getCallBets = () => {
      const betList = this.bets.getBetsHistory();
      return betList.filter(isCallBetType);
   };

   hasPlacedBets = () => {
      return this.getBets().betsHistory.length > 0;
   };

   makeCommonBet = (command: TCommonBet): void => {
      const bet = this.createCommonBet(command);

      this.saveBets([bet]);
   };

   makeRangeStraightBets = ({ numbers, amount }: RangeCommandType): void => {
      const bet = this.createRangeStraightBet({ numbers, amount });

      this.saveBets([bet]);
   };

   makeCallBet = (options: { type: CallBetType; amount: TCommonBet['amount'] }) => {
      const bet = this.createCallBet(options);

      this.saveBets([bet as TBetType]);
   };

   removeLastBet = (): void => {
      if (this.bets.getBetsHistory().length === 0) {
         return;
      }

      this.bets.undoLastBet();
   };

   resetBets = (): void => {
      this.bets.resetBets();
   };

   removeBetById = (id: number): void => {
      const commandAmount = this.bets.getTotalBets()?.[id] ?? null;

      if (!commandAmount) {
         return;
      }

      this.bets.removeBetById(id);
   };

   doubleBets = (): void => {
      const bets = this.bets.getBetsHistory();
      const totalBets = this.bets.getTotalBets();
      const bet = this.createdDoubleBet();

      const doubleCommand = {
         ...bet,
         partialTotalBets: totalBets,
         partialDoubled: sumBetsById(extractDoubleBet(bets)),
         amount: getTotalBetsAmount(totalBets),
      };

      this.bets.doubleBets([doubleTotalBets(totalBets), [...bets, doubleCommand]]);
   };

   moveBet = ({ bet, from }: { bet: TCommonBet; from: number }) => {
      this.bets.removeBetById(from);
      this.bets.addBets([bet]);
   };
}
