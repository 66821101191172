import { FetchArgs } from '@reduxjs/toolkit/query/react';

import { responseCreator } from '@/common/services/api/utils';
import { showNotification } from '@/common/ui/Notification';
import { addQueryParamsToUrl, logger } from '@/common/utils';

interface IAdditionalFetchParams {
   config: {
      maxRetries?: number;
      domain: string;
   };
   extraOptions?: { retry?: boolean };
   headers: { 'Content-Type': string; token: string };
}

const INITIAL_RETRIES_COUNTER = 0;
const MAX_RETRIES = 3;

export const baseQuery = async (
   args: FetchArgs,
   { headers, config, extraOptions = {} }: IAdditionalFetchParams,
   retries = INITIAL_RETRIES_COUNTER,
) => {
   const showErrorNotification = (msg: string) => showNotification.error(msg);

   const { maxRetries = MAX_RETRIES, domain } = config;
   const { retry = false } = extraOptions;

   try {
      const { url: path, body, method, params = null } = args;

      const url = addQueryParamsToUrl({
         url: `${domain}${path}`,
         queryParams: params ?? {},
      });

      const response = await fetch(url, {
         method,
         body: JSON.stringify(body),
         headers,
      });

      const isServerErrorCode = response.status >= 500 && response.status <= 599;
      const isSuccessfulResponse = response.ok;
      const data = await response.json();

      if (isServerErrorCode) {
         const errorResponse = responseCreator.createErrorResponse(data);
         const errorMessage = errorResponse?.error?.error;
         logger.error(errorMessage);
         showErrorNotification(errorMessage);
         return errorResponse;
      }

      if (isSuccessfulResponse) {
         return responseCreator.createSuccessResponse(data);
      }

      const errorMessage = data?.error?.message;
      logger.error(errorMessage);
      showErrorNotification(errorMessage || 'Something went wrong!');
      return responseCreator.createExceptionResponse(data);
   } catch (error) {
      // Retry logic
      const increasedRetryCounter = retries + 1;
      const isMaxRetriesReached = retries <= maxRetries;

      if (retry && isMaxRetriesReached) {
         return baseQuery(args, { headers, config, extraOptions }, increasedRetryCounter);
      }

      // Return error response if retries exceeded or retry is disabled
      const errorResponse = responseCreator.createErrorResponse(error);
      const errorMessage = errorResponse?.error?.error;
      logger.error(errorMessage);
      showErrorNotification(errorMessage);
      return errorResponse;
   }
};
