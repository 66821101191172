const formatCurrencySettings = {
   useGrouping: true,
   minimumFractionDigits: 0,
};

interface IOptions {
   isCompact: boolean;
}

export const formatCurrency = (
   number: number,
   { isCompact }: IOptions = { isCompact: false },
): string =>
   new Intl.NumberFormat('en-US', {
      ...formatCurrencySettings,
      ...(isCompact && { notation: 'compact' }),
   }).format(number);
