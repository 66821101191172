import { FC } from 'react';

import { EGameTypesName } from '@/common/constants';

import { LoadingTitle, CircleLoader } from './components';
import * as S from './LoadingScreen.styles';

interface ILoadingScreenProps {
   percents: number;
   type?: EGameTypesName;
}

export const LoadingScreen: FC<ILoadingScreenProps> = ({
   type = EGameTypesName.SingleZeroRoulette,
   percents,
}) => {
   return (
      <S.LoadingScreen data-testid="loading-screen">
         <S.LoadingScreenContent>
            <S.LoadingTitle>
               <LoadingTitle type={type} />
               <S.CircleLoader>
                  <CircleLoader percents={percents} />
               </S.CircleLoader>
            </S.LoadingTitle>
         </S.LoadingScreenContent>
      </S.LoadingScreen>
   );
};
