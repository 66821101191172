import { Suspense, lazy, memo } from 'react';

import { autoKeys } from '@/common/utils';
import { useSidebar } from '@/common/modules/Sidebar/state';
import { Sidebar, SidebarContent } from '@/common/modules/Sidebar';
import { ERouletteSidebar } from '@/common/entities/Sidebar';
import * as Icons from '@/common/ui/Icons';

import { useTranslation } from '#/translates';

import { History } from '../History';
import { Statistics } from '../Statistics';
import { Autoplay } from '../Autoplay';

import * as S from './Sidebars.styles';

const Favourite = lazy(() => import('#/modules/Favourite'));
const Settings = lazy(() => import('#/modules/Settings'));
const Help = lazy(() => import('#/modules/Help'));
const MenuContainer = lazy(() => import('#/modules/Menu'));

const sidebarsConfig = [
   {
      type: ERouletteSidebar.Statistics,
      title: 'sidebars.statistics.title',
      icon: <Icons.Statistics />,
      content: <Statistics />,
      isCloseOnClickOutside: true,
   },

   {
      type: ERouletteSidebar.Favourite,
      title: 'sidebars.favourite.title',
      icon: <Icons.Favourite />,
      content: (
         <Suspense fallback={null}>
            <Favourite />
         </Suspense>
      ),
      isCloseOnClickOutside: true,
   },
   {
      type: ERouletteSidebar.Autoplay,
      title: 'sidebars.autoplay.title',
      icon: <Icons.Autoplay />,
      content: <Autoplay />,
      isCloseOnClickOutside: false,
   },
   {
      type: ERouletteSidebar.Settings,
      title: 'sidebars.settings.title',
      icon: <Icons.Settings />,
      content: (
         <Suspense fallback={null}>
            <Settings />
         </Suspense>
      ),
      isCloseOnClickOutside: true,
   },
   {
      type: ERouletteSidebar.Help,
      content: (
         <Suspense fallback={null}>
            <Help />
         </Suspense>
      ),
      isCloseOnClickOutside: true,
      isScrollableBody: false,
   },
   {
      type: ERouletteSidebar.History,
      title: 'sidebars.history.title',
      icon: <Icons.History />,
      content: <History />,
      isCloseOnClickOutside: true,
   },
];

export const Sidebars = memo(() => {
   const { sidebarType, handleCloseSidebar } = useSidebar();
   const { t } = useTranslation();

   return (
      <S.SidebarsWrapper>
         {autoKeys(
            sidebarsConfig.map(
               ({ title, type, content, icon, isCloseOnClickOutside, isScrollableBody }) => (
                  <Sidebar
                     isOpen={sidebarType === type}
                     handleCloseOnClickOutside={() => {
                        if (!isCloseOnClickOutside) {
                           return null;
                        }

                        handleCloseSidebar();
                     }}
                  >
                     <SidebarContent
                        isScrollableBody={isScrollableBody}
                        handleCloseSidebar={handleCloseSidebar}
                        title={title ? t(title) : null}
                        icon={icon ?? null}
                     >
                        {content}
                     </SidebarContent>
                  </Sidebar>
               ),
            ),
         )}
         <Suspense fallback={null}>
            <MenuContainer />
         </Suspense>
      </S.SidebarsWrapper>
   );
});

Sidebars.displayName = 'Sidebars';
