import { FC } from 'react';

import { EBetControl } from '@/common/ui';
import { IPlayChipSound } from '@/common/entities';

import { betsCreator } from '#/core/betsCreator';
import { LimitTooltipWrapper } from '#/modules/LimitTooltip';
import { useTranslation } from '#/translates';
import { withChipSound } from '#/hocs';

import { BetControl } from '../BetControl';

export const DoubleControl: FC<{ isVisible: boolean } & IPlayChipSound> = ({
   isVisible,
   playChipSound,
}) => {
   const isDisabled = !betsCreator?.hasPlacedBets?.() || !isVisible;
   const { t } = useTranslation();

   return (
      <LimitTooltipWrapper>
         {({ handleShowMaxBetTooltip }) => (
            <BetControl
               withPulseAnimation
               dataTestId="double-control"
               title={t('betControls.double')}
               color="#29D23A"
               variant={EBetControl.Double}
               onClick={(event) => {
                  handleShowMaxBetTooltip(event);
                  betsCreator.doubleBets();
               }}
               disabled={isDisabled}
               playChipSound={playChipSound}
            />
         )}
      </LimitTooltipWrapper>
   );
};

export const DoubleBetsControlWithChipSound = withChipSound(DoubleControl);
