import { RTKQueryApi, SuccessResponse } from '../types';

import { TStatistics } from './types';

export const statisticsAPI = (apiSlice: RTKQueryApi) =>
   apiSlice.injectEndpoints({
      endpoints: (build) => ({
         getStatisticsByPhysicalTableId: build.query<
            SuccessResponse<TStatistics>,
            { physicalTableId: string }
         >({
            query: ({ physicalTableId }) => ({
               url: `/rounds/statistic/${physicalTableId}/all`,
               method: 'GET',
            }),
         }),
         getStatisticsByGameTableId: build.query<
            SuccessResponse<TStatistics>,
            { gameTableId: string }
         >({
            query: ({ gameTableId }) => ({
               url: `/rounds/statistic/game-table/${gameTableId}/all`,
               method: 'GET',
            }),
         }),
      }),
   });
