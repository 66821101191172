import { useEffect } from 'react';

import { TNullable, TSetIntervalType } from '@/common/types';
import { timeSync } from '@/common/helpers';

import { countDownByDate } from './helpers';

export const useCountDown = ({ cb, endTime, isLocalDelta = true }): void => {
   useEffect(() => {
      let interval: TNullable<TSetIntervalType> = null;
      if (isLocalDelta) {
         const delta = 0;

         interval = countDownByDate({ cb, endTime, delta });
      } else if (endTime) {
         timeSync.getServerTimeDelta().then((delta) => {
            interval = countDownByDate({ cb, endTime, delta });
         });
      }

      return () => {
         if (interval) {
            clearInterval(interval);
         }
      };
   }, [endTime]); // start new count down when endTime changes
};
