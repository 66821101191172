import { FC, ReactNode } from 'react';

import { usePowerSavingMode } from './hooks/usePowerSavingMode';
import { usePowerSavingModal } from './hooks/usePowerSavingModal';
import { PowerSavingModeModal } from './components/PowerSavingModeModal/PowerSavingModeModal';
import { TestVideo } from './components/TestVideo/TestVideo';
import * as S from './PowerSavingModeDetector.styles';

export const PowerSavingModeDetector: FC<{ children: ReactNode }> = ({ children }) => {
   const { isCheckedPowerSavingMode, handleSetCheckedPowerSavingMode } = usePowerSavingMode();
   const { isOpenModal, handleOpenModal, handleCloseModal } = usePowerSavingModal();

   if (isCheckedPowerSavingMode) {
      return <>{children}</>;
   }

   return (
      <>
         <PowerSavingModeModal isShow={isOpenModal} onClose={handleCloseModal} />
         <S.PowerSavingMode isOpenModal={isOpenModal}>
            <TestVideo
               onSuccessPlay={handleSetCheckedPowerSavingMode}
               onErrorPlay={handleOpenModal}
            />
            {children}
         </S.PowerSavingMode>
      </>
   );
};
