import { RefObject, useEffect, useState } from 'react';

import { useEvent } from '@/common/hooks';
import { TNullable } from '@/common/types';

import { ITrackComponentState } from '#/state/features/bets';

import { getActiveElementsIndexes, getNodeCellByCellId } from '../../helpers';
import { RACETRACK_CELL_CLASSNAME, RACETRACK_SVG_CLASSNAME } from '../../constants';

export const useHoverRacetrack = ({
   raceTrackContainerRef,
   trackStore,
}: {
   raceTrackContainerRef: RefObject<SVGSVGElement>;
   trackStore: () => ITrackComponentState;
}): void => {
   const { useRaceTrackSelector } = trackStore();
   const { rangeLevel } = useRaceTrackSelector();
   const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
   const [currentCellIndex, setCurrentCellIndex] = useState<TNullable<number>>(null);

   const handleMouseOver = useEvent((event): void => {
      const eventTarget = event.target;

      if (eventTarget.classList.contains(RACETRACK_SVG_CLASSNAME)) {
         setActiveIndexes([]);
      }

      const findHoverIndex = (cellClassList: DOMTokenList) => {
         const classList = Array.from(cellClassList).find((cls) =>
            cls.startsWith(`${RACETRACK_CELL_CLASSNAME}_`),
         );

         return classList ? Number(classList.replace(`${RACETRACK_CELL_CLASSNAME}_`, '')) : null;
      };

      const parentClassList: DOMTokenList = eventTarget.parentElement.classList;
      const hoverCellIndex = findHoverIndex(parentClassList);

      if (hoverCellIndex !== currentCellIndex) {
         const indexes: number[] = getActiveElementsIndexes({
            classList: parentClassList,
            rangeLevel,
         });
         setActiveIndexes(indexes);
         setCurrentCellIndex(hoverCellIndex);
      }
   });

   const handleMouseLeave = useEvent((): void => {
      setActiveIndexes([]);
   });

   useEffect(() => {
      const raceTrackContainer = raceTrackContainerRef.current;

      if (raceTrackContainer) {
         raceTrackContainer.addEventListener('mouseover', handleMouseOver);
         raceTrackContainer.addEventListener('mouseleave', handleMouseLeave);

         return () => {
            raceTrackContainer.removeEventListener('mouseover', handleMouseOver);
            raceTrackContainer.removeEventListener('mouseleave', handleMouseLeave);
         };
      }
   }, [raceTrackContainerRef, rangeLevel]);

   useEffect(() => {
      const svgContainer = raceTrackContainerRef.current;

      if (svgContainer) {
         const ACTIVE_CLASSNAME = 'active';

         const nodes = activeIndexes.map((index: number) =>
            getNodeCellByCellId({ container: svgContainer, cellId: String(index) }),
         );
         nodes.forEach((node) => node?.classList.add(ACTIVE_CLASSNAME));

         return (): void => {
            nodes.forEach((node) => node?.classList.remove(ACTIVE_CLASSNAME));
         };
      }
   }, [activeIndexes, raceTrackContainerRef]);
};
