import { FC } from 'react';

import * as S from './WinNumbersView.styles';

interface IWinNumbersViewProps {
   animationDuration: number;
   currentColor: string;
   leftColors: string;
   nextNumber: number;
   previousNumber: number;
   rightColors: string;
   winNumber: number;
}
export const WinNumbersView: FC<IWinNumbersViewProps> = ({
   winNumber,
   leftColors,
   rightColors,
   nextNumber,
   currentColor,
   previousNumber,
   animationDuration,
}) => (
   <S.WinNumbersWrapper animationDuration={animationDuration}>
      <svg viewBox="0 0 291 123" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            fill="url(#a)"
            fillOpacity={0.8}
            d="M232.3 120.6a136.8 136.8 0 0 0-55-25.9l20-82.9a229.8 229.8 0 0 1 90.9 42.8l-56 66Z"
         />
         <path
            fill="url(#b)"
            fillOpacity={0.8}
            d="M58 119.5a137 137 0 0 1 55.5-24.8L95 11.4c-33.4 6.9-64.8 21-91.7 41.2l54.6 67Z"
         />
         <g filter="url(#c)">
            <path
               fill="url(#d)"
               fillOpacity={0.8}
               d="M110.2 101.6 90 5.7c28.9-5.8 58.5-7.2 87.8-4.1l2 .2c7.2 1 14.6 2.4 21.8 4l-22.2 95.5c-4-1-8.2-1.7-12.3-2.3-19-2-38.2-1-56.9 2.6Z"
            />
            <path
               stroke="url(#e)"
               strokeOpacity={0.55}
               strokeWidth={4}
               d="M167.3 97c-18.6-2-37.2-1.2-55.5 2.3l-19.4-92c28-5.4 56.7-6.7 85.2-3.7l1.9.2a253 253 0 0 1 19.7 3.5L177.9 99c-3.4-.8-7-1.4-10.6-2Z"
            />
         </g>
         <defs>
            <linearGradient
               id="a"
               x1={230.82}
               x2={299.575}
               y1={10.895}
               y2={58.11}
               gradientUnits="userSpaceOnUse"
            >
               <stop offset={0.172} stopColor={rightColors} />
               <stop offset={0.807} stopColor={rightColors} stopOpacity={0} />
            </linearGradient>
            <linearGradient
               id="b"
               x1={61.52}
               x2={-8.171}
               y1={9.885}
               y2={55.813}
               gradientUnits="userSpaceOnUse"
            >
               <stop offset={0.172} stopColor={leftColors} />
               <stop offset={0.807} stopColor={leftColors} stopOpacity={0} />
            </linearGradient>
            <linearGradient
               id="d"
               x1={145.78}
               x2={145.78}
               y1={0}
               y2={101.625}
               gradientUnits="userSpaceOnUse"
            >
               <stop offset={0.057} stopColor={currentColor} stopOpacity={0.6} />
               <stop offset={1} stopColor={currentColor} stopOpacity={0.8} />
            </linearGradient>
            <linearGradient
               id="e"
               x1={145.78}
               x2={145.78}
               y1={0}
               y2={101.625}
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor={currentColor} />
               <stop offset={1} stopColor={currentColor} stopOpacity={0} />
            </linearGradient>
            <filter
               id="c"
               width={119.561}
               height={109.626}
               x={86}
               y={-4}
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity={0} result="BackgroundImageFix" />
               <feGaussianBlur in="BackgroundImageFix" stdDeviation={0} />
               <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_9095_600696"
               />
               <feBlend
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_9095_600696"
                  result="shape"
               />
            </filter>
         </defs>
      </svg>

      <S.Circle animationDuration={animationDuration}>
         <S.Digit>{previousNumber}</S.Digit>
         <S.Digit data-test-id="win-number">{winNumber}</S.Digit>
         <S.Digit>{nextNumber}</S.Digit>
      </S.Circle>
   </S.WinNumbersWrapper>
);
