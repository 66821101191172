import { TNullable } from '@/common/types';
import { logger } from '@/common/utils';

import { errors } from '#/constants/errors';

const TOOLTIP_TOP_GAP = 5;

export const positioningTooltip = (target: HTMLElement, selector: string): void => {
   try {
      const {
         width: targetWidth,
         top: targetTop,
         left: targetLeft,
      }: DOMRect = target.getBoundingClientRect();

      setTimeout(() => {
         const tooltipNode: TNullable<HTMLElement> = document.querySelector(selector);

         if (tooltipNode) {
            const tooltipTopPosition = targetTop - tooltipNode.clientHeight - TOOLTIP_TOP_GAP;
            const tooltipLeftPosition = targetLeft - tooltipNode.clientWidth / 2 + targetWidth / 2;

            tooltipNode.style.top = `${Math.max(0, tooltipTopPosition)}px`;
            tooltipNode.style.left = `${Math.max(0, tooltipLeftPosition)}px`;

            const whenTooltipClimbsOverRightEdge =
               tooltipNode.getBoundingClientRect().right > window.innerWidth;

            if (whenTooltipClimbsOverRightEdge) {
               tooltipNode.style.left = 'unset';
               tooltipNode.style.right = '0';
            }
         }
      }, 0);
   } catch (_) {
      logger.log(errors.limits.errorLimitTooltip);
   }
};
