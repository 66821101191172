import { FC, ReactNode, createContext, useContext } from 'react';

import { DeviceOrientation } from '@/common/hooks';
import { TNullable } from '@/common/types';

type BetConstructorContextValue = { orientation: DeviceOrientation };

interface IBetConstructorOrientationProviderProps {
   children: ReactNode;
   value: BetConstructorContextValue;
}

const BetConstructorContext = createContext<TNullable<BetConstructorContextValue>>(null);

export const BetConstructorProvider: FC<IBetConstructorOrientationProviderProps> = ({
   value,
   children,
}) => <BetConstructorContext.Provider value={value}>{children}</BetConstructorContext.Provider>;

export const useBetConstructorOrientationContext = () => {
   const context = useContext(BetConstructorContext);

   return context?.orientation;
};
