// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const AppVersionWrapper = styled.div`
   margin: 60px 0 0 15px;
   font-size: 18px;
   color: ${({ theme }) => theme.colors?.white};

   ${orientation.portrait`
      position: absolute;
      left: 35px;
      bottom: 50px;
      z-index: 1;
      font-size: 20px;
      margin: 25px 0 0 0;
   `}

   ${orientation.mobileLandscape`
      position: absolute;
      right: 60px;
      top: 20px;
      z-index: 1;
      font-size: 20px;
      margin: 0;
   `}
`;
