import { FC, MouseEvent, memo } from 'react';

import { IPlayTapSound } from '@/common/entities';

import { ITrackComponentState } from '#/state/features/bets';
import { withTapSound } from '#/hocs/withTapSound';

import { ERangeLevelLimitEnum } from '../../constants';

import { MinusIcon, PlusIcon } from './components';
import * as S from './RaceTrackRangeControls.styles';

const RaceTrackRangeControls: FC<
   {
      trackStore: () => ITrackComponentState;
   } & IPlayTapSound
> = memo(({ trackStore, playTapSound }) => {
   const { useRaceTrackSelector, handleIncreaseRangeLevel, handleDecreaseRangeLevel } =
      trackStore();

   const { rangeLevel } = useRaceTrackSelector();

   const handleIncreaseRangeLevelClick = (): void => {
      if (rangeLevel < ERangeLevelLimitEnum.Max) {
         playTapSound();
         handleIncreaseRangeLevel?.();
      }
   };

   const handleDecreaseRangeLevelClick = (): void => {
      if (rangeLevel > ERangeLevelLimitEnum.Min) {
         playTapSound();
         handleDecreaseRangeLevel?.();
      }
   };

   return (
      <S.RaceTrackRangeControlsWrapper
         className="racetrack__controls"
         // we add onClick and stop propagation because we don't need that tooltip, which use as wrapper for our track is open
         onClick={(event: MouseEvent<HTMLElement>) => event.stopPropagation()}
      >
         <S.RaceTrackRangeButton
            data-testid="racetrack-increase-range-control"
            onClick={handleIncreaseRangeLevelClick}
         >
            <PlusIcon />
         </S.RaceTrackRangeButton>

         <S.RaceTrackRange>{rangeLevel}</S.RaceTrackRange>

         <S.RaceTrackRangeButton
            data-testid="racetrack-decrease-range-control"
            onClick={handleDecreaseRangeLevelClick}
         >
            <MinusIcon />
         </S.RaceTrackRangeButton>
      </S.RaceTrackRangeControlsWrapper>
   );
});
export const RaceTrackRangeControlsWithTapSound = withTapSound(RaceTrackRangeControls);

RaceTrackRangeControls.displayName = 'RaceTrackRangeControls';
