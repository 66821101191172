import { useDocumentEvent } from '@/common/hooks';
import { isMobileDevice } from '@/common/utils/checkDevice';

import { generalVolume } from '#/core/sounds';

export const useToggleMuteOnMobile = (): void => {
   const handleToggleMute = (): void => {
      if (isMobileDevice() && generalVolume.isGeneralSoundSet()) {
         // eslint-disable-next-line sonarjs/no-all-duplicated-branches
         if (document.hidden) {
            generalVolume.toggleMute();
         } else {
            generalVolume.toggleMute();
         }
      }
   };

   useDocumentEvent('visibilitychange', handleToggleMute);
};
