import React, { FC } from 'react';

import * as S from './SwipeUpView.styles';

interface ISwipeUpView {
   animationDuration?: number;
   subtitle?: string;
   title?: string;
}
export const SwipeUpView: FC<ISwipeUpView> = ({ title, subtitle, animationDuration = 3 }) => (
   <S.SwipeUpViewSvg
      viewBox="0 0 228 216"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      animationDuration={animationDuration}
   >
      <defs>
         <filter id="first-arrow-filter" x="-150%" y="-150%" width="400%" height="400%">
            <feGaussianBlur
               id="first-arrow-filter-drop-shadow-0-blur"
               in="SourceAlpha"
               stdDeviation="1,1"
            />
            <feOffset id="first-arrow-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
            <feFlood id="first-arrow-filter-drop-shadow-0-flood" floodColor="rgba(0,0,0,0.5)" />
            <feComposite id="first-arrow-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
            <feMerge id="first-arrow-filter-drop-shadow-0-merge">
               <feMergeNode id="first-arrow-filter-drop-shadow-0-merge-node-1" />
               <feMergeNode id="first-arrow-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
            </feMerge>
         </filter>
         <filter id="second-arrow-filter" x="-150%" y="-150%" width="400%" height="400%">
            <feGaussianBlur
               id="second-arrow-filter-drop-shadow-0-blur"
               in="SourceAlpha"
               stdDeviation="1,1"
            />
            <feOffset id="second-arrow-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
            <feFlood id="second-arrow-filter-drop-shadow-0-flood" floodColor="rgba(0,0,0,0.5)" />
            <feComposite id="second-arrow-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
            <feMerge id="second-arrow-filter-drop-shadow-0-merge">
               <feMergeNode id="second-arrow-filter-drop-shadow-0-merge-node-1" />
               <feMergeNode
                  id="second-arrow-filter-drop-shadow-0-merge-node-2"
                  in="SourceGraphic"
               />
            </feMerge>
         </filter>
         <filter id="third-arrow-filter" x="-150%" y="-150%" width="400%" height="400%">
            <feGaussianBlur
               id="third-arrow-filter-drop-shadow-0-blur"
               in="SourceAlpha"
               stdDeviation="1,1"
            />
            <feOffset id="third-arrow-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
            <feFlood id="third-arrow-filter-drop-shadow-0-flood" floodColor="rgba(0,0,0,0.5)" />
            <feComposite id="third-arrow-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
            <feMerge id="third-arrow-filter-drop-shadow-0-merge">
               <feMergeNode id="third-arrow-filter-drop-shadow-0-merge-node-1" />
               <feMergeNode id="third-arrow-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
            </feMerge>
         </filter>
         <filter id="subtitle-filter" x="-150%" width="400%" y="-150%" height="400%">
            <feGaussianBlur
               id="subtitle-filter-drop-shadow-0-blur"
               in="SourceAlpha"
               stdDeviation="1,1"
            />
            <feOffset id="subtitle-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
            <feFlood id="subtitle-filter-drop-shadow-0-flood" floodColor="rgba(0,0,0,0.5)" />
            <feComposite id="subtitle-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
            <feMerge id="subtitle-filter-drop-shadow-0-merge">
               <feMergeNode id="subtitle-filter-drop-shadow-0-merge-node-1" />
               <feMergeNode id="subtitle-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
            </feMerge>
         </filter>
         <filter id="title-filter" x="-150%" width="400%" y="-150%" height="400%">
            <feGaussianBlur
               id="title-filter-drop-shadow-0-blur"
               in="SourceAlpha"
               stdDeviation="1,1"
            />
            <feOffset id="title-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
            <feFlood id="title-filter-drop-shadow-0-flood" floodColor="rgba(0,0,0,0.5)" />
            <feComposite id="title-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
            <feMerge id="title-filter-drop-shadow-0-merge">
               <feMergeNode id="title-filter-drop-shadow-0-merge-node-1" />
               <feMergeNode id="title-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
            </feMerge>
         </filter>
      </defs>
      <g id="first-arrow_to" transform="translate(114.000015,45.834173)">
         <path
            id="first-arrow"
            d="M102.863,41.6374c-.977-.9763-.977-2.5592,0-3.5355l9.369-9.3697c.977-.9763,2.559-.9763,3.536,0l9.369,9.3697c.977.9763.977,2.5592,0,3.5355-.976.9763-2.559.9763-3.535,0L114,34.0355l-7.602,7.6019c-.976.9763-2.559.9763-3.535,0v0Z"
            transform="translate(-114,-35.1848)"
            opacity="0.08"
            filter="url(#first-arrow-filter)"
         />
      </g>
      <g id="second-arrow_to" transform="translate(114.000015,70.623995)">
         <path
            id="second-arrow"
            d="M98.8445,62.6465c-.9763-.9763-.9763-2.5593,0-3.5356L112.232,45.7232c.977-.9763,2.559-.9763,3.536,0l13.387,13.3877c.977.9763.977,2.5593,0,3.5356-.976.9763-2.559.9763-3.535,0L114,51.0265l-11.62,11.62c-.976.9763-2.5592.9763-3.5355,0v0Z"
            transform="translate(-114.000015,-54.184849)"
            opacity="0"
            filter="url(#second-arrow-filter)"
         />
      </g>
      <g id="third-arrow_to" transform="translate(113.999986,118.881283)">
         <path
            id="third-arrow"
            d="M95.7142,80.185c-.9763.9763-.9763,2.5592,0,3.5355s2.5593.9763,3.5356,0L111.482,71.4883v39.4467c0,1.381,1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5v-39.4825l12.268,12.268c.976.9763,2.559.9763,3.536,0c.976-.9763.976-2.5592,0-3.5355L115.768,63.6671c-.977-.9763-2.56-.9763-3.536,0L95.7142,80.185Z"
            transform="translate(-113.999985,-88.184937)"
            opacity="0"
            filter="url(#third-arrow-filter)"
         />
      </g>

      <text
         id="title"
         dx="0"
         dy="0"
         transform="translate(41 158)"
         opacity="0"
         filter="url(#title-filter)"
      >
         <tspan y="0" fontWeight="700" strokeWidth="0">
            {title}
         </tspan>
      </text>
      <text
         xmlns="http://www.w3.org/2000/svg"
         id="subtitle"
         dx="0"
         dy="0"
         transform="translate(27.88636 189.126074)"
         opacity="0"
         filter="url(#subtitle-filter)"
      >
         <tspan y="0" fontWeight="500" strokeWidth="0">
            {subtitle}
         </tspan>
      </text>
   </S.SwipeUpViewSvg>
);
