import { BaseSyntheticEvent, FC } from 'react';
import { Message } from 'stream-chat';
import { ChatAutoComplete, useMessageInputContext } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

import * as S from './ChatMessageInput.styles';

export const ThreadInput: FC<{ placeholder: string }> = ({ placeholder }) => {
   const { handleSubmit, setText } = useMessageInputContext();

   const handleSendMessage = (
      event: BaseSyntheticEvent<object>,
      customMessageData?: Partial<Message<DefaultStreamChatGenerics>> | undefined,
   ): void => {
      handleSubmit(event, customMessageData);
      setText('');
   };

   return (
      <S.MessageInputWrapper className="no-cursor">
         <ChatAutoComplete rows={1} placeholder={placeholder} />
         <S.ChatMessageIcon />
         <S.SendButton onClick={handleSendMessage} />
      </S.MessageInputWrapper>
   );
};
