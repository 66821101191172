// @ts-nocheck
import styled from 'styled-components/macro';

export const Scrollbar = styled.div`
  overflow: hidden auto;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
  scrollbar-color: ${({ theme }) => `${theme.scrollbar.thumbColor} ${theme.scrollbar.trackColor}`};
`;
