import { findLast, formatCurrency } from '@/common/utils';
import { TChip } from '@/common/ui/Chip/entities';

const enum EValueLength {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
}

class ChipStyler {
  private getScaleByValueLength = (formattedValue: string): number => {
    const scaleByValueLength = {
      [EValueLength.ONE]: 1,
      [EValueLength.TWO]: 0.9,
      [EValueLength.THREE]: 0.7,
      [EValueLength.FOUR]: 0.75,
      [EValueLength.FIVE]: 0.5,
      [EValueLength.SIX]: 0.45,
      DEFAULT: 0.35,
    } as const;

    return scaleByValueLength[formattedValue.length] ?? scaleByValueLength.DEFAULT;
  };

  private getFontSizeByValueLength = (formattedValue: string): number => {
    const fonSizes = {
      [EValueLength.ONE]: 26,
      [EValueLength.TWO]: 26,
      [EValueLength.THREE]: 26,
      [EValueLength.FOUR]: 23,
      [EValueLength.FIVE]: 28,
      [EValueLength.SIX]: 25,
      DEFAULT: 25,
    } as const;

    return fonSizes[formattedValue.length] ?? fonSizes.DEFAULT;
  };

  private getFormattedChipValue = (value: number): string => {
    return formatCurrency(value, { isCompact: true });
  };

  private getChipColor = ({
    chipTrayConfig,
    chipValue,
  }: {
    chipTrayConfig: TChip[];
    chipValue: number;
  }): string => {
    const DEFAULT_COLOR = '#FAA200';
    const chipByChipValue = chipTrayConfig.find(({ value }) => value === chipValue);

    if (chipByChipValue) {
      return chipByChipValue.primaryColor;
    }

    const previousChip = findLast(chipTrayConfig, (chip) => chip.value < chipValue);
    return previousChip?.primaryColor ?? DEFAULT_COLOR;
  };

  public getChipParams = ({
    chipTrayConfig,
    chipValue,
  }: {
    chipTrayConfig: TChip[];
    chipValue: number;
  }) => {
    const formattedValue = this.getFormattedChipValue(chipValue);

    return {
      value: formattedValue,
      scale: this.getScaleByValueLength(formattedValue),
      fontSize: this.getFontSizeByValueLength(formattedValue),
      color: this.getChipColor({ chipTrayConfig, chipValue }),
    };
  };
}

export const chipStyler = new ChipStyler();
