// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '../theme/mixins';

export const GameInfoViewContainer = styled.div`
  --game-info-fz: 14px;
  --game-info-gap: 10px 1px;

  width: max-content;
  height: var(--game-info-fz);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--game-info-gap);
  font-size: var(--game-info-fz);
  font-weight: 400;
  color: rgba(196, 196, 196, 1);
  white-space: nowrap;

  ${orientation.portrait`
    --game-info-fz: 10px;
    --game-info-gap: 1px;
    justify-content: flex-start;
  `}

  ${orientation.mobileLandscape`
      --game-info-fz: 10px;
      --game-info-gap: 7px 1px;
   `}
`;

export const PlayersCountContainer = styled.div`
  flex-basis: 100%;
`;

export const StyledText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: inherit;
`;

export const DealerName = styled(StyledText)`
  color: ${({ theme }) => theme.colors?.main};
  font-weight: 500;
  margin-left: 2px;
`;

export const VerticalSeparator = styled.span`
  position: relative;
  width: ${({ width }) => width};
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    border-radius: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background-color: rgb(196, 196, 196);
  }

  ${orientation.mobileLandscape`
    width: ${({ width }) => `calc(${width} - 5px)`};
  `};

  ${orientation.portrait`
    width: ${({ width }) => `calc(${width} - 5px)`};
  `};
`;
