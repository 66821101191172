import { ElementRef, useEffect, useRef } from 'react';

import { TNullable } from '@/common/types';

import { TrackContainerId } from '#/modules/Track';

import { RACETRACK_CONTAINER_DEFAULT_ID, RACETRACK_SVG_CLASSNAME } from '../../constants';

export const useRaceTrackContainer = (
   trackContainerId: TrackContainerId = RACETRACK_CONTAINER_DEFAULT_ID,
) => {
   const raceTrackContainerRef = useRef<TNullable<ElementRef<'svg'>>>(null);

   useEffect(() => {
      const wrapperSelector = `#${trackContainerId}`;
      const svgSelector = `.${RACETRACK_SVG_CLASSNAME}`;

      raceTrackContainerRef.current = document.querySelector(`${wrapperSelector} ${svgSelector}`);
   }, [trackContainerId]);

   return raceTrackContainerRef;
};
