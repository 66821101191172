// @ts-nocheck
import styled from 'styled-components/macro';

import { scrollBarClassName } from '@/common/ui/Scrollbar';
import { orientation } from '@/common/ui/theme/mixins';

export const HistoryWrapper = styled.div`
   height: 100%;

   ${orientation.portrait`
      width: 100%;
   `}

   .${scrollBarClassName} {
      overflow-y: hidden;
   }
`;
