import React from 'react';

export const MinusIcon = () => (
   <svg width="27" height="5" viewBox="0 0 27 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M0.996094 2.87043C0.996094 1.71337 1.86837 0.775391 2.94438 0.775391H24.3755C25.4515 0.775391 26.3238 1.71337 26.3238 2.87043C26.3238 4.0275 25.4515 4.96548 24.3755 4.96548H2.94438C1.86837 4.96548 0.996094 4.0275 0.996094 2.87043Z"
         fill="#D2D2D2"
      />
   </svg>
);
