import { FC } from 'react';

import { Chip, Portal } from '@/common/ui';
import { getZIndex } from '@/common/ui/theme/mixins';

import { useHighlightChipByMinLimit } from '#/modules/Track/hooks';
import { useGameConfigStore } from '#/services/api/gameConfigApiSlice/state';
import { ETypeBet } from '#/state/features/bets';

import { IDragChip } from '../../hooks/useDragAndDrop/types';

export const DragChip: FC<IDragChip> = ({ chipValue, betType, chipId }) => {
   const isLessMinLimit = useHighlightChipByMinLimit();

   const isHighlightChip = isLessMinLimit({
      type: betType as ETypeBet,
      betValue: chipValue,
   });
   const { gameConfig } = useGameConfigStore();
   const chips = gameConfig.chipTray;
   return (
      <Portal>
         <Chip
            chips={chips}
            chipNumber={chipValue}
            id={chipId}
            highlightShadow={isHighlightChip}
            style={{
               zIndex: getZIndex('biggest'),
               position: 'absolute',
               top: '0',
               left: '0',
               opacity: `${chipValue ? '1' : '0'}`,
               pointerEvents: 'none',
            }}
         />
      </Portal>
   );
};
