import { useEffect, useState } from 'react';

import { LocalStorage } from '@/common/utils/LocalStorage';

export const useSwipeUp = () => {
   const [isSwipeUpOpen, setSwipeUpOpen] = useState(true);
   const swipeUpData = new LocalStorage('notShowSwipeUp');

   useEffect((): void => {
      if (swipeUpData.get()) {
         setSwipeUpOpen(false);
      }
   }, []);

   const handleOpenSwipeUp = (): void => {
      setSwipeUpOpen(true);
   };

   const handleCloseSwipeUp = (): void => {
      setSwipeUpOpen(false);
      swipeUpData.set(true);
   };

   return {
      isSwipeUpOpen,
      handleOpenSwipeUp,
      handleCloseSwipeUp,
   };
};
