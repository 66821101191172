import { TNullable } from '@/common/types';

export const enum ECancelReasons {
   ManualCancel = 'ManualCancel',
   SpinCounterToLow = 'SpinCounterToLow',
   BallHasBeenLaunchedInTheSameDirectionAsTheWheelRotor = 'BallHasBeenLaunchedInTheSameDirectionAsTheWheelRotor',
   ReemSensorError = 'ReemSensorError',
   BallOrRotorError = 'BallOrRotorError',
   RotorError = 'RotorError',
}

export enum INCIDENT_STATUS {
   RESOLVED = 'RESOLVED',
   OPENED = 'OPENED',
   IN_PROGRESS = 'IN_PROGRESS',
   CLOSED = 'CLOSED',
}

export type TIncidentHistory = {
   comment: TNullable<string>;
   created: string;
   createdBy: string;
   status: string;
};

export enum INCIDENT_STATUS_TITLE {
   OPENED = 'NEW',
   IN_PROGRESS = 'IN PROGRESS',
   RESOLVED = 'RESOLVED',
   CLOSED = 'CLOSE',
}

export enum MANUAL_INCIDENT_TYPES {
   CHAT = 'CHAT',
   TECH = 'TECH',
   SOS = 'SOS',
   CHANGE = 'CHANGE',
   MISTAKE = 'MISTAKE',
   WHEEL = 'WHEEL',
   ASSISTANCE = 'ASSISTANCE',
}

export enum MANUAL_INCIDENT_SUB_TYPES {
   TECH_SCREEN = 'TECH_SCREEN',
   TECH_OTHER = 'TECH_OTHER',
   CHAT_RUDE = 'CHAT_RUDE',
   CHAT_NAME = 'CHAT_NAME',
   CHAT_GP = 'CHAT_GP',
   MISTAKE_INVALID_SPIN = 'MISTAKE_INVALID_SPIN',
   MISTAKE_OTHER = 'MISTAKE_OTHER',
   MISTAKE_DROPPED_BALL = 'MISTAKE_DROPPED_BALL',
   MISTAKE_DID_NOT_SPIN = 'MISTAKE_DID_NOT_SPIN',
   MISTAKE_WRONG_DIRECTION = 'MISTAKE_WRONG_DIRECTION',
   MISTAKE_SPUN_OUT = 'MISTAKE_SPUN_OUT',
   MISTAKE_EARLY_SPIN = 'MISTAKE_EARLY_SPIN',
   MISTAKE_SAME_DIRECTION = 'MISTAKE_SAME_DIRECTION',
   ASSISTANCE_SM_CALL = 'ASSISTANCE_SM_CALL',
}

export type Incident = {
   type: MANUAL_INCIDENT_TYPES;
   created: string;
   subType: TNullable<MANUAL_INCIDENT_SUB_TYPES>;
   incidentId: string;
   incidentType: TNullable<string>;
   physicalTable: {
      id: string;
      title: string;
   };
   status: INCIDENT_STATUS;
   history: TIncidentHistory[];
   roundId: string;
   dealer: {
      name: string;
   };
};

export type IncidentId = Incident['incidentId'];

export type StudioId = string;

export interface IColoredIncident extends Incident {
   color: string;
   statusTitle: INCIDENT_STATUS_TITLE;
}

export const CANCEL_ROUND_INCIDENTS = [
   MANUAL_INCIDENT_TYPES.SOS,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_OTHER,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_INVALID_SPIN,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_DROPPED_BALL,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_DID_NOT_SPIN,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_WRONG_DIRECTION,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_SPUN_OUT,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_EARLY_SPIN,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_SAME_DIRECTION,
];

export const TYPES = {
   [MANUAL_INCIDENT_TYPES.ASSISTANCE]: [MANUAL_INCIDENT_SUB_TYPES.ASSISTANCE_SM_CALL],
   [MANUAL_INCIDENT_TYPES.SOS]: [],
   [MANUAL_INCIDENT_TYPES.CHANGE]: [],
   [MANUAL_INCIDENT_TYPES.TECH]: [
      MANUAL_INCIDENT_SUB_TYPES.TECH_SCREEN,
      MANUAL_INCIDENT_SUB_TYPES.TECH_OTHER,
   ],
   [MANUAL_INCIDENT_TYPES.CHAT]: [
      MANUAL_INCIDENT_SUB_TYPES.CHAT_RUDE,
      MANUAL_INCIDENT_SUB_TYPES.CHAT_NAME,
      MANUAL_INCIDENT_SUB_TYPES.CHAT_GP,
   ],
   [MANUAL_INCIDENT_TYPES.MISTAKE]: [
      MANUAL_INCIDENT_SUB_TYPES.MISTAKE_INVALID_SPIN,
      MANUAL_INCIDENT_SUB_TYPES.MISTAKE_OTHER,
      MANUAL_INCIDENT_SUB_TYPES.MISTAKE_DROPPED_BALL,
      MANUAL_INCIDENT_SUB_TYPES.MISTAKE_DID_NOT_SPIN,
      MANUAL_INCIDENT_SUB_TYPES.MISTAKE_WRONG_DIRECTION,
      MANUAL_INCIDENT_SUB_TYPES.MISTAKE_SPUN_OUT,
      MANUAL_INCIDENT_SUB_TYPES.MISTAKE_EARLY_SPIN,
      MANUAL_INCIDENT_SUB_TYPES.MISTAKE_SAME_DIRECTION,
   ],
};

export const LOCK_STREAM_DECK_BUTTONS_INCIDENTS = [
   ECancelReasons.BallHasBeenLaunchedInTheSameDirectionAsTheWheelRotor,
   ECancelReasons.BallOrRotorError,
   ECancelReasons.ManualCancel,
   ECancelReasons.ReemSensorError,
   ECancelReasons.RotorError,
   ECancelReasons.SpinCounterToLow,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_INVALID_SPIN,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_OTHER,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_DROPPED_BALL,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_DID_NOT_SPIN,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_WRONG_DIRECTION,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_SPUN_OUT,
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_EARLY_SPIN,
   MANUAL_INCIDENT_TYPES.SOS,
];
