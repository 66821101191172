import { FC, ReactNode } from 'react';

import { TNullable } from '../../../../types';
import { Scrollbar } from '../../../../ui/Scrollbar';
import { SidebarCloseButton } from '../SidebarCloseButton/SidebarCloseButton';

import * as S from './SidebarContent.styles';

export interface ISidebarContent {
   children: ReactNode;
   handleCloseSidebar: () => void;
   icon?: ReactNode;
   isScrollableBody?: boolean;
   title: TNullable<string>;
}

export const SidebarContent: FC<ISidebarContent> = ({
   title,
   icon,
   handleCloseSidebar,
   children,
   isScrollableBody = true,
}) => (
   <S.SidebarContentWrapper>
      <SidebarCloseButton onClick={handleCloseSidebar} />
      <S.SidebarContentHeader>
         {icon && <S.SidebarContentHeaderIcon>{icon}</S.SidebarContentHeaderIcon>}
         {title && <S.SidebarContentHeaderTitle>{title}</S.SidebarContentHeaderTitle>}
      </S.SidebarContentHeader>
      <Scrollbar>
         <S.SidebarContentBody className="scrollbar-body" isScrollableBody={isScrollableBody}>
            {children}
         </S.SidebarContentBody>
      </Scrollbar>
   </S.SidebarContentWrapper>
);
