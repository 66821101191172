// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const StatisticsWheel = styled.svg`
   --wheel-size: 340px;
   touch-action: none;

   width: var(--wheel-size);
   height: var(--wheel-size);

   ${orientation.portrait`
   	 --wheel-size: 186px;
   `}

   ${orientation.mobileLandscape`
   	 --wheel-size: 160px;
   `}
`;
