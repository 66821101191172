// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';
import { isMobileDevice } from '@/common/utils/checkDevice';

export const StatisticsWrapper = styled.div`
   --statistics-control-fz: 18px;
   --statistics-control-padding: 8px 24px;
   --statistics-control-gap: 10px;

   display: flex;
   flex-direction: column;
   align-items: center;

   ${orientation.portrait`
   	--statistics-control-fz: 16px;
      --statistics-control-padding: 5px;
  `}

   ${orientation.mobileLandscape`
      --statistics-control-fz: 12px;
      --statistics-control-padding: 5px 14px;
   `}
`;

export const DoubleStatisticsWrapper = styled.div`
   display: flex;
   gap: 30px;
   width: 100%;
   justify-content: space-between;
`;

export const StatisticsWheelContainer = styled.div`
   text-align: center;
   margin: 40px auto;
`;

export const InnerStatistics = styled.div`
   display: ${isMobileDevice() ? 'flex' : 'block'};
   gap: 50px;

   ${orientation.portrait`
   	width: 100%;
      flex-direction: column;
      gap: 20px;
   `}

   ${orientation.mobileLandscape`
     width: 100%;
     display: grid;
     grid-template-columns: 1fr 1fr;
     gap: 45px;
   `}
`;

export const EmptyStatistics = styled.div`
   width: 433px;
`;
