import { createListenerMiddleware } from '@reduxjs/toolkit';

import { balanceSlice } from '#/modules/Balance';

import { updateLossLimit } from './effects/updateLossLimit';

export const updateAutoplayLossLimitMiddleware = createListenerMiddleware();

updateAutoplayLossLimitMiddleware.startListening({
   actionCreator: balanceSlice.actions.updateBalanceFromUI,
   effect: updateLossLimit,
});
