// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const FavouriteWrapper = styled.div`
   --favourite-tab-height: 40px;
   --favourite-tab-fz: 16px;

   height: 100%;
   color: ${({ theme }) => theme.colors.white};

   ${orientation.mobileLandscape`
     --favourite-tab-height: 54.5px;
     --favourite-tab-fz: 24px;

   `}

   ${orientation.portrait`
     --favourite-tab-height: 30px;
     --favourite-tab-fz: 13px;
   `};
`;
