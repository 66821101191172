import styled from 'styled-components/macro';

export const CommentTextArea = styled.textarea`
   width: 100%;
   background-color: ${({ theme }) => theme.colors.black[40]};
   color: ${({ theme }) => theme.colors.white};
   font-size: 12px;
   border-color: transparent;
   resize: none;
   padding: 10px;
   line-height: 18px;

   &:focus-visible {
      outline: 0;
   }
`;
