import { TGameTableId } from '../../../entities';
import { RTKQueryApi, SuccessResponse } from '../types';

import {
   TGameConfig,
   TGameType,
   TWebrtcStream,
   TRouletteGameConfig,
   TBlackjackGameConfig,
} from './types';

export const gameConfigAPI = (apiSlice: RTKQueryApi) =>
   apiSlice.injectEndpoints({
      endpoints: (build) => ({
         getGameTableConfig: build.query<
            SuccessResponse<TBlackjackGameConfig | TRouletteGameConfig>,
            { gameTableId: TGameTableId; currency: string }
         >({
            query: ({ gameTableId, currency }) => ({
               url: `/config/game-table/${gameTableId}`,
               method: 'GET',
               params: {
                  currency,
               },
            }),
         }),
         getGameTableConfigByBO: build.query<
            SuccessResponse<
               TGameConfig & {
                  round_record: { record_enabled: boolean; record_storage_url: string };
               }
            >,
            { gameTableId: string }
         >({
            query: ({ gameTableId }) => ({
               url: `/bo/config/game-table/${gameTableId}`,
               method: 'GET',
            }),
         }),
         getGameTypes: build.query<SuccessResponse<TGameType[]>, void>({
            query: () => ({
               url: '/game-types',
               method: 'GET',
            }),
         }),
         getWebrtcStreams: build.query<SuccessResponse<TWebrtcStream[]>, void>({
            query: () => ({
               url: '/webrtc-streams',
               method: 'GET',
            }),
         }),
      }),
   });
