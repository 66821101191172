// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

import { SendIcon, ChatIcon } from './assets';

export const MessageInputWrapper = styled.div`
   --input-height: 44px;
   --input-font-size: 16px;
   --input-padding-left: 72px;

   position: relative;
   width: 100%;
   height: var(--input-height);
   display: flex;
   justify-content: space-between;
   align-items: center;

   ${orientation.mobileLandscape`
      --input-height: 35px;
      --input-font-size: 14px;
      --input-padding-left: 65px;

   `}

   & > :first-child {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      overflow: hidden;

      textarea {
         resize: none;
         width: inherit;
         min-height: var(--input-height) !important;
         padding-left: var(--input-padding-left);
         line-height: 40px;
         will-change: transform;
         background: rgba(36, 36, 36, 0.8);
         background-repeat: no-repeat;
         padding-right: 46px;
         background-size: 40px;
         color: ${({ theme }) => theme.colors?.white};
         border: none;
         font-size: var(--input-font-size);

         &:focus-visible {
            outline: unset;
         }

         &::placeholder {
            color: rgba(159, 159, 159, 1);
         }

         ${orientation.portrait`
            &::placeholder {
               font-size: 14px;
            }
         `}

         ${orientation.mobileLandscape`
            line-height: 35px;
            background-size: 35px;
            padding-top: 0;
         `}
      }
   }
`;

export const ChatMessageIcon = styled(ChatIcon)`
   --icon-size: 40px;

   position: absolute;
   left: 16px;
   width: var(--icon-size);
   height: var(--icon-size);

   ${orientation.mobileLandscape`
      --icon-size: 35px;
   `}
`;

export const SendButton = styled(SendIcon)`
   --input-send-button-size: 40px;

   position: absolute;
   right: 0;
   width: var(--input-send-button-size);
   height: var(--input-send-button-size);
   margin-right: 10px;
   cursor: pointer;
   color: ${({ theme }) => theme.colors.smokeGrey};

   & > path {
      transition: filter 0.5s;
   }

   &:hover,
   &:active {
      & > path {
         filter: ${({ theme }) => `drop-shadow(0px 0px 4px ${theme.colors?.main})`};
      }
   }

   &:active {
      & > path {
         color: ${({ theme }) => theme.colors?.main};
      }
   }

   ${orientation.portrait`
      height: 40px;
   `}
`;
