// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { isHoverSupports, orientation } from '@/common/ui/theme/mixins';

import { ArrowIcon } from '../assets';

const SlideControl = styled(ArrowIcon)`
   width: 15px;
   height: 100%;
`;

export const PreviousSlideControl = styled(SlideControl)`
   margin-right: 5px;
`;

export const NextSlideControl = styled(SlideControl)`
   margin-left: 5px;
   transform: rotate(180deg);
`;

export const ChipTrayWrapper = styled.div`
   display: flex;
   gap: 14px;
   align-items: center;
   height: ${({ isVertical }) => (isVertical ? 'auto' : '95px')};
   width: auto;
   max-width: 100%;
   margin: 0 auto;
   cursor: pointer;

   > .swiper {
      height: 100%;
   }

   ${({ isVertical }) =>
      isVertical &&
      css`
         height: auto;
         flex-direction: column;

         & .swiper-wrapper {
            margin: 3px 0;
            width: 90px;
            height: 450px;
         }

         ${PreviousSlideControl} {
            margin-right: 0;
            transform: rotate(90deg);
         }

         ${NextSlideControl} {
            margin-left: 0;
            transform: rotate(-90deg);
         }

         ${orientation.portrait`
            gap: 2px;

            & .swiper-wrapper {
               width: 80px;
               height: 335px;
            }

            & .swiper-vertical {
               padding-top: 3px;
               padding-bottom: 3px;
            }
         `}
      `}

   & .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      filter: none;
   }

   & .swiper-horizontal {
      padding: 4px;
   }

   ${orientation.mobileLandscape`
      & .swiper-wrapper {
         width: 380px;
         height: 95px;
      }

      ${PreviousSlideControl},
      ${NextSlideControl} {
         width: 8px;
      }
   `}
`;

export const ChipWrapper = styled.div`
   --bg-alpha-value: 0.4;

   display: flex;
   transition: all 0.2s;
   position: relative;
   overflow: hidden;
   box-shadow:
      0 1px 3px 0 #000,
      inset 0 0 1px 0 #000;
   border-radius: 50%;
   will-change: transform;

   > svg {
      filter: brightness(60%);
   }

   ${isHoverSupports`
      &:hover {
         --bg-alpha-value: 0;

         transform: scale(1.15);

         ${orientation.mobileLandscape`
            transform: scale(1.2);
         `}
      }
   `}

   &:active {
      transform: scale(1.15);

      ${orientation.mobileLandscape`
         transform: scale(1.2);
      `}
   }

   ${({ isSelectedChip }) =>
      isSelectedChip &&
      css`
         > svg {
            filter: none;
         }
         transform: scale(1.15);
      `};
`;
