import { useCurrency } from 'hooks/useCurrency';

import { AmountInfo } from '@/common/ui';

import { useTranslation } from '#/translates';

import { useBalanceSelector } from '.';

export const Balance = () => {
   const { t } = useTranslation();
   const balance = useBalanceSelector();
   const formatCurrency = useCurrency();

   return (
      <AmountInfo
         amount={formatCurrency(balance)}
         title={t('balance')}
         titleDataTestId="balance-title"
         amountDataTestId="balance-value"
      />
   );
};
