import { useChannelActionContext, useChannelStateContext } from 'stream-chat-react';

import * as S from './CloseThread.styles';

const CloseThreadIcon = () => (
   <svg width="15" height="35" viewBox="-1 -2 18 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M15.1426 1L1.00044 15.1421L15.1426 29.2843"
         stroke="#D2D2D2"
         strokeWidth="3"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
   </svg>
);

// Due to the lack of control over some GetStream features,
// we need to use this hack and wrap the thread with this component to be able to close it
export const CloseThread = ({ children }) => {
   const { closeThread } = useChannelActionContext();
   const channelState = useChannelStateContext();

   const isThreadOpen = channelState.thread;

   return (
      <>
         {isThreadOpen ? (
            <S.CloseThreadIconWrapper onClick={closeThread}>
               <CloseThreadIcon />
            </S.CloseThreadIconWrapper>
         ) : null}
         {children}
      </>
   );
};
