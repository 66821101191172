import { contractValidator } from '@/common/services/api';
import { userBetsByRoundIdSchema } from '@/common/services/api/betsAPI/schemes';
import { roundStateScheme } from '@/common/services/api/roundsAPI/schemes';

import { ETableSocketEvents } from '../constants';

import { useBettingEndTimeEvent } from './useBettingEndTimeEvent';
import { lastBetsStartTimeSchema, useLastBetsStartTime } from './useLastBetsStartTime';
import { useCancelAutoplay } from './useCancelAutoplay';
import { useCancelBetEvent } from './useCancelBetEvent';
import { useCancelRoundEvent } from './useCancelRoundEvent';
import { useDealerLoginEvent } from './useDealerLoginEvent';
import { useMaintenanceEnd } from './useMaintenanceEnd';
import { useMaintenancePending } from './useMaintenancePending';
import { useMaintenanceStart } from './useMaintenanceStart';
import { usePlayersCountEvent } from './usePlayersCountEvent';
import { useRoundResultEvent } from './useRoundResultEvent';
import { useRoundStartEvent } from './useRoundStartEvent';
import { useRoundWinnersEvent } from './useRoundWinners';
import { useSessionClosedEvent } from './useSessionClosedEvent';
import { useStatisticsUpdates } from './useStatisticsUpdates';
import { useUpdatedAutoplayEvent } from './useUpdatedAutoplayEvent';
import { useWinNumberEvent } from './useWinNumberEvent';
import { useNotifyInactivePlayerEvent } from './useNotifyInactivePlayer';
import { useRoundStateEvent } from './useRoundStateEvent';
import { useBetStateEvent } from './useBetStateEvent';
import { useDealerRatingEvent } from './useDealerRatingEvent';
import { useDealerFeedbackRequestEvent } from './useDealerFeedbackRequestEvent';
import { useUserGeneralSettingsUpdateEvent } from './useUserGeneralSettingsUpdateEvent';
import { useDealerRatingUpdateEvent } from './useDealerRatingUpdateEvent';
import { useCasinoGameTypeSettingsUpdateEvent } from './useUserCasinoGameTypeSettingsUpdate';
import { useGameTableDeactivatedEvent } from './useGameTableDeactivatedEvent';
import { dealerRatingScheme } from './useDealerRatingEvent/schemes';
import { dealerRatingUpdateEventSchema } from './useDealerRatingUpdateEvent/schemes';
import { dealerFeedbackRequestEventSchema } from './useDealerFeedbackRequestEvent/schemes';
import { userGeneralSettingsUpdateEventSchema } from './useUserGeneralSettingsUpdateEvent/schemes';
import { casinoGameTypeSettingsUpdateEventSchema } from './useUserCasinoGameTypeSettingsUpdate/schemes';

export const useSocketEvents = () => {
   const handleCancelRoundEvent = useCancelRoundEvent();
   const handleRoundWinners = useRoundWinnersEvent();
   const handleBettingEndEvent = useBettingEndTimeEvent();
   const handleLastBetsStartTime = useLastBetsStartTime();
   const handleWinNumberEvent = useWinNumberEvent();
   const handleRoundStartEvent = useRoundStartEvent();
   const handleRoundResultEvent = useRoundResultEvent();
   const handlePlayersCountEvent = usePlayersCountEvent();
   const handleStatisticsUpdates = useStatisticsUpdates();
   const handleCancelBetEvent = useCancelBetEvent();
   const handleCancelAutoplay = useCancelAutoplay();
   const handleAutoplayUserLoss = useUpdatedAutoplayEvent();
   const handleDealerLoginEvent = useDealerLoginEvent();
   const handleSessionClosedEvent = useSessionClosedEvent();
   const handleMaintenancePending = useMaintenancePending();
   const handleMaintenanceStarted = useMaintenanceStart();
   const handleMaintenanceEnded = useMaintenanceEnd();
   const handleNotifyInactivePlayerEvent = useNotifyInactivePlayerEvent();
   const handleRoundStateEvent = useRoundStateEvent();
   const handleBetStateEvent = useBetStateEvent();
   const handleDealerRatingEvent = useDealerRatingEvent();
   const handleDealerRatingUpdateEvent = useDealerRatingUpdateEvent();
   const handleDealerFeedbackRequestEvent = useDealerFeedbackRequestEvent();
   const handleUserGeneralSettingsUpdateEvent = useUserGeneralSettingsUpdateEvent();
   const handleUserCasinoGameTypeSettingsUpdate = useCasinoGameTypeSettingsUpdateEvent();
   const handleGameTableDeactivatedEvent = useGameTableDeactivatedEvent();

   return {
      [ETableSocketEvents.GameTableDeactivated]: handleGameTableDeactivatedEvent,
      [ETableSocketEvents.BettingEndTime]: handleBettingEndEvent,
      [ETableSocketEvents.WinNumber]: handleWinNumberEvent,
      [ETableSocketEvents.StartRound]: handleRoundStartEvent,
      [ETableSocketEvents.RoundResult]: handleRoundResultEvent,
      [ETableSocketEvents.CancelRound]: handleCancelRoundEvent,
      [ETableSocketEvents.PlayersUpdated]: handlePlayersCountEvent,
      [ETableSocketEvents.CancelBet]: handleCancelBetEvent,
      [ETableSocketEvents.NewStatisticCalculated]: handleStatisticsUpdates,
      [ETableSocketEvents.CancelAutoplay]: handleCancelAutoplay,
      [ETableSocketEvents.RoundWinners]: handleRoundWinners,
      [ETableSocketEvents.UpdateAutoplay]: handleAutoplayUserLoss,
      [ETableSocketEvents.DealerLogin]: handleDealerLoginEvent,
      [ETableSocketEvents.SessionClosed]: handleSessionClosedEvent,
      [ETableSocketEvents.MaintenancePending]: handleMaintenancePending,
      [ETableSocketEvents.MaintenanceStarted]: handleMaintenanceStarted,
      [ETableSocketEvents.MaintenanceEnded]: handleMaintenanceEnded,
      [ETableSocketEvents.NotifyInactivePlayer]: handleNotifyInactivePlayerEvent,
      [ETableSocketEvents.LastBetsStartTime]: {
         handleEvent: handleLastBetsStartTime,
         handleValidate: (eventPayload: unknown) =>
            contractValidator({
               data: eventPayload,
               schema: lastBetsStartTimeSchema,
               path: ETableSocketEvents.LastBetsStartTime,
            }),
      },
      [ETableSocketEvents.BetsState]: {
         handleEvent: handleBetStateEvent,
         handleValidate: (eventPayload: unknown) =>
            contractValidator({
               data: eventPayload,
               schema: userBetsByRoundIdSchema,
               path: ETableSocketEvents.BetsState,
            }),
      },
      [ETableSocketEvents.RoundState]: {
         handleEvent: handleRoundStateEvent,
         handleValidate: (eventPayload: unknown) =>
            contractValidator({
               data: eventPayload,
               schema: roundStateScheme,
               path: ETableSocketEvents.RoundState,
            }),
      },
      [ETableSocketEvents.DealerRating]: {
         handleEvent: handleDealerRatingEvent,
         handleValidate: (eventPayload: unknown) =>
            contractValidator({
               data: eventPayload,
               schema: dealerRatingScheme,
               path: ETableSocketEvents.DealerRating,
            }),
      },
      [ETableSocketEvents.DealerFeedbackRequest]: {
         handleEvent: handleDealerFeedbackRequestEvent,
         handleValidate: (eventPayload: unknown) =>
            contractValidator({
               data: eventPayload,
               schema: dealerFeedbackRequestEventSchema,
               path: ETableSocketEvents.DealerFeedbackRequest,
            }),
      },
      [ETableSocketEvents.DealerRatingUpdate]: {
         handleEvent: handleDealerRatingUpdateEvent,
         handleValidate: (eventPayload: unknown) =>
            contractValidator({
               data: eventPayload,
               schema: dealerRatingUpdateEventSchema,
               path: ETableSocketEvents.DealerRatingUpdate,
            }),
      },
      [ETableSocketEvents.CasinoGameTypeSettingsUpdate]: {
         handleEvent: handleUserCasinoGameTypeSettingsUpdate,
         handleValidate: (eventPayload: unknown) =>
            contractValidator({
               data: eventPayload,
               schema: casinoGameTypeSettingsUpdateEventSchema,
               path: ETableSocketEvents.CasinoGameTypeSettingsUpdate,
            }),
      },
      [ETableSocketEvents.UserGeneralSettingsUpdated]: {
         handleEvent: handleUserGeneralSettingsUpdateEvent,
         handleValidate: (eventPayload: unknown) =>
            contractValidator({
               data: eventPayload,
               schema: userGeneralSettingsUpdateEventSchema,
               path: ETableSocketEvents.UserGeneralSettingsUpdated,
            }),
      },
      [ETableSocketEvents.UserGeneralSettings]: {
         handleEvent: handleUserGeneralSettingsUpdateEvent,
         handleValidate: (eventPayload: unknown) =>
            contractValidator({
               data: eventPayload,
               schema: userGeneralSettingsUpdateEventSchema,
               path: ETableSocketEvents.UserGeneralSettings,
            }),
      },
   };
};
