// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex, orientation } from '@/common/ui/theme/mixins';

export const PortalTooltip = styled.div`
   ${applyZIndex('biggest')};

   pointer-events: none;
   position: absolute;
   max-width: 18ch;
   width: 100%;
   font-size: calc(var(--scale) * 16px);
   padding: 5px;
   border-radius: 18px;
   background-color: rgba(45, 45, 45, 0.8);
   box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
   text-align: center;
   font-weight: 500;
   color: ${({ theme }) => theme.colors?.main};

   ${orientation.portrait`
      font-size: 12px;
   `}
`;
