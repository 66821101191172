import { FC } from 'react';

import { useSelectedChipSelector } from '@/common/modules/ChipTray';
import { IPlayChipSound } from '@/common/entities';

import { mapConfigCommonBets } from '#/core';
import { betsCreator } from '#/core/betsCreator';
import { useHoverSpecialBet } from '#/modules/Favourite/components/SpecialBets/hooks/useHoverSpecialBet';
import { getSpecialBets } from '#/modules/Favourite/components/StraightUpBets';
import { withChipSound } from '#/hocs';

import { StatisticsBar } from '../../StatisticsBar';

import { IDoubleStatistics } from './types';
import * as S from './DoubleStatistics.styles';

export const DoubleStatistics: FC<IDoubleStatistics & IPlayChipSound> = ({
   leftCell,
   rightCell,
   playChipSound,
}) => {
   const { specialBetsObject } = getSpecialBets();
   const hoverChildElementsRef = useHoverSpecialBet();
   const { value: chipValue } = useSelectedChipSelector();

   const handleDoubleStatisticsClick = (cellNumber: number) => (): void => {
      const { id, type, numbers } = mapConfigCommonBets[cellNumber];
      betsCreator.makeCommonBet({ id, type, numbers, amount: chipValue });
      playChipSound();
   };

   return (
      <>
         <S.StatisticByColorWrapper ref={hoverChildElementsRef}>
            <StatisticsBar
               dataTestId={leftCell.dataTestId}
               label={leftCell.label}
               color="#82828233"
               direction="left"
               percentage={leftCell.percentage}
               onClick={handleDoubleStatisticsClick(leftCell.betId)}
               highlightCells={specialBetsObject[leftCell.betId].numbers}
            />
            <StatisticsBar
               dataTestId={rightCell.dataTestId}
               label={rightCell.label}
               color="#F3F3F333"
               direction="right"
               percentage={rightCell.percentage}
               onClick={handleDoubleStatisticsClick(rightCell.betId)}
               highlightCells={specialBetsObject[rightCell.betId].numbers}
            />
         </S.StatisticByColorWrapper>
      </>
   );
};

export const DoubleStatisticsWithChipSound = withChipSound(DoubleStatistics);
