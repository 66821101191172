// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const Line = styled.div`
   display: flex;
   align-items: center;
   height: 4px;
   width: 100%;
   background: #5a5a5a;
   justify-content: space-between;
   margin-top: 24px;
   margin-bottom: 34px;

   ${orientation.mobileLandscape`
      margin-top: 20px;
      margin-bottom: 0;
   `}
`;

export const Dot = styled.div`
   width: 15px;
   height: 15px;
   border-radius: 50%;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   background: ${({ isSelected, theme }) => (isSelected ? theme.colors?.main : '#5a5a5a')};
   display: flex;
   justify-content: center;
   position: relative;
`;

export const Range = styled.div`
   transition: all 0.3s ease-in-out;
   color: ${({ isSelected, theme }) => (isSelected ? theme.colors?.white : '#a2a2a2')};
   position: absolute;
   padding: 14px;
   top: -40px;
   padding-bottom: 44px;
   cursor: pointer;

   ${orientation.mobileLandscape`
      font-size: 12px;
      padding: 0;
      top: -20px;
   `}
`;
