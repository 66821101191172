import { TSeatBetsConfig, ESeatBetsConfig } from '@/common/blackjack/types';

export const enum EBJBetType {
   Ante = 'ante',
   TopThreeBet = 'top3',
   BetBehind = 'betBehind',
   TwentyOnePlusThreeBet = '21p3',
   PerfectPairsBet = 'perfectPairs',
   Insurance = 'insurance',
   DoubleAll = 'doubleAll',
   Split = 'split',
}

export const enum EBJSeatStatus {
   Waiting = 'WAITING',
   Decision = 'DECISION',
   Played = 'PLAYED',
}

export const enum EHandResult {
   Bust = 'BUST',
   Lose = 'LOSE',
   Draw = 'DRAW',
   Win = 'WIN',
   Bj = 'BJ',
}

export const enum EdDoubleBetTypes {
   DoubleZero = 'double0',
   DoubleOne = 'double1',
}

export const defaultBjSeatConfig: TSeatBetsConfig = {
   [ESeatBetsConfig.bjTop3]: true,
   [ESeatBetsConfig.bj21Plus3]: true,
   [ESeatBetsConfig.bjPerfectPairs]: true,
   [ESeatBetsConfig.bjBetBehind]: true,
   [ESeatBetsConfig.bjMaxSeatsPerUser]: 3,
};
