import { FC, SyntheticEvent } from 'react';

import * as S from './TestVideo.styles';

const MOCK_VIDEO_SRC = 'https://www.w3schools.com/html/mov_bbb.mp4';

export const TestVideo: FC<{
   onSuccessPlay: () => void;
   onErrorPlay: () => void;
}> = ({ onSuccessPlay, onErrorPlay }) => (
   <S.TestVideo
      autoPlay
      muted
      playsInline
      src={MOCK_VIDEO_SRC}
      onPlay={onSuccessPlay}
      onSuspend={(event: SyntheticEvent<HTMLVideoElement, Event>) => {
         event.currentTarget.play().then(onSuccessPlay).catch(onErrorPlay);
      }}
   />
);
