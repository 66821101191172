import { forwardRef, VideoHTMLAttributes, SyntheticEvent } from 'react';

import { LIVE_STREAM_ID } from './constants';

interface ILiveStreamElementProps extends VideoHTMLAttributes<HTMLVideoElement> {
   onPause?: () => void;
   onPlaying?: (event: unknown) => void;
   onSuspend?: (event: SyntheticEvent<HTMLVideoElement, Event>) => void;
}
export const LiveStreamElement = forwardRef<HTMLVideoElement, ILiveStreamElementProps>(
   ({ onPause, onPlaying }, ref) => (
      <video
         muted // we always mute video element and handle audio via audio element
         autoPlay
         id={LIVE_STREAM_ID}
         playsInline
         ref={ref}
         onAbort={onPause}
         onSuspend={onPause}
         onPlaying={onPlaying}
      />
   ),
);
