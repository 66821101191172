import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

export interface IChatState {
   isChatEnabled: boolean;
}

const initialState: IChatState = {
   isChatEnabled: false,
};

export const chatSlice = createSlice({
   name: 'chat',
   initialState,
   reducers: {
      toggleChat: (state): void => {
         state.isChatEnabled = !state.isChatEnabled;
      },
      closeChat: (state): void => {
         state.isChatEnabled = false;
      },
   },
});

export const useChatActions = () => {
   const dispatch = useDispatch();
   const { toggleChat, closeChat } = chatSlice.actions;

   const handleToggleChat = (): void => {
      dispatch(toggleChat());
   };

   const handleCloseChat = (): void => {
      dispatch(closeChat());
   };

   return {
      handleCloseChat,
      handleToggleChat,
   };
};

// @ts-ignore
export const useChatEnabledSelector = (): boolean => useSelector(({ chat }) => chat?.isChatEnabled);

export const useChat = () => {
   const isChatEnabled = useChatEnabledSelector();
   const { handleCloseChat, handleToggleChat } = useChatActions();

   return {
      isChatEnabled,
      handleCloseChat,
      handleToggleChat,
   };
};
