import { useEffect } from 'react';

import { useEvent } from '@/common/hooks';

export const useDelayCallback = ({
   callback,
   delay,
}: {
   callback: (...args: unknown[]) => unknown;
   delay: number;
}): void => {
   const memoizedCallback = useEvent(callback);

   useEffect(() => {
      setTimeout(memoizedCallback, delay);
   }, []);
};
