import { z } from 'zod';

const roundsAmount = z.number();

const colorOdds = z.object({
   reds: z.number(),
   blacks: z.number(),
   zeros: z.number(),
});

const columnOdds = z.object({
   first: z.number(),
   second: z.number(),
   third: z.number(),
});

const oddEvenOdds = z.object({
   odd: z.number(),
   even: z.number(),
});

const specialBetsOdds = z.object({
   voisins: z.number(),
   tiers: z.number(),
   orphelins: z.number(),
   zeros: z.number(),
});
const halvesOdds = z.object({
   first: z.number(),
   second: z.number(),
});

const thirdsOdds = z.object({
   first: z.number(),
   second: z.number(),
   third: z.number(),
});

const numberOdds = z
   .object({
      number: z.string(),
      odds: z.number(),
   })
   .array()
   .nonempty();

const hotOrColdNumber = z.object({
   number: z.string(),
   odds: z.number(),
});

const frequentNumbers = z.object({
   hot: z.tuple([hotOrColdNumber, hotOrColdNumber, hotOrColdNumber, hotOrColdNumber]),
   cold: z.tuple([hotOrColdNumber, hotOrColdNumber, hotOrColdNumber, hotOrColdNumber]),
});

const simpleStatistic = z.object({
   roundsAmount,
   statistics: z.object({
      colorOdds,
      columnOdds,
      oddEvenOdds,
      specialBetsOdds,
      halvesOdds,
      thirdsOdds,
      numberOdds,
      frequentNumbers,
   }),
});

export const getStatisticsSchema = z.tuple([
   simpleStatistic,
   simpleStatistic,
   simpleStatistic,
   simpleStatistic,
]);
