import { z } from 'zod';

import { ETimerType } from '@/common/modules/CountDown/constants';

export const gameConfigSchema = z.object({
   game_table_id: z.string(),
   game_type: z.object({
      name: z.string(),
      title: z.string().nullable(),
      uuid: z.string(),
   }),
   title: z.string(),
   stream_id: z.string(),
   esUrl: z.string(),
   wssUrl: z.string(),
   tssUrl: z.string(),
   chat: z.object({
      channel_id: z.string(),
   }),
   logo: z
      .object({
         height: z.number(),
         path: z.string(),
         width: z.number(),
      })
      .nullable(),
   colors: z
      .object({
         redBaseSectorBackgroundColor: z.string().optional(),
         blackBaseSectorBackgroundColor: z.string().optional(),
         blackBackgroundColor: z.string().optional(),
         redBackgroundColor: z.string().optional(),
         zeroBackgroundColor: z.string().optional(),
         hotShadowColor: z.string().optional(),
         coldShadowColor: z.string().optional(),
         blackNumberColor: z.string().optional(),
         redNumberColor: z.string().optional(),
         zeroNumberColor: z.string().optional(),
         bordersColor: z.string().optional(),
         sideAreaTextColor: z.string().optional(),
         transparency: z.number().optional(),
      })
      .nullable(),
   chip_tray: z.object({
      chips: z
         .object({
            uuid: z.string(),
            value: z.number(),
            primary_color: z.string(),
            secondary_color: z.string(),
            is_default: z.boolean(),
         })
         .array()
         .nonempty(),
   }),
   currency: z
      .object({
         name: z.string(),
         short_name: z.string(),
         symbol: z.string(),
      })
      .nullable(),
   is_game_table_active: z.boolean(),
   lobby_redirect_url: z.string().nullable(),
   is_physical_table_under_maintenance: z.boolean(),
});

export const rouletteAdditionalConfigFields = z.object({
   autoplay_rounds: z.number().array(),
   timer: z.object({
      type: z.union([z.literal(ETimerType.CIRCULAR), z.literal(ETimerType.HORIZONTAL)]).nullable(),
      betting_time: z.number().nullable(),
   }),
   bet_type_limits: z
      .object({
         bet_type: z.string(),
         min: z.number(),
         max: z.number(),
      })
      .array()
      .superRefine((val, ctx) => {
         const expectedBetTypes = [
            'street',
            'corner',
            'column',
            'dozen',
            'line',
            'parity',
            'highLow',
            'color',
            'straight',
            'split',
         ];

         const betTypeFromServer = new Set(val.map(({ bet_type }) => bet_type));
         const isBetTypeLimitsValid = expectedBetTypes.every((betType) =>
            betTypeFromServer.has(betType),
         );

         if (!isBetTypeLimitsValid) {
            ctx.addIssue({
               code: 'invalid_type',
               expected: 'array',
               received: 'array',
               message: 'not all types of bets are in config',
               path: ['bet_type_limits'],
            });
         }
      }),
});

export const blackJackAdditionalConfigFields = z.object({
   bet_type_limits: z
      .object({
         bet_type: z.string(),
         min: z.number(),
         max: z.number(),
      })
      .array(),
   mode_accesses: z
      .object({
         bj_21_plus_3: z.boolean().optional(),
         bj_bet_behind: z.boolean().optional(),
         bj_perfect_pairs: z.boolean().optional(),
         bj_top_3: z.boolean().optional(),
         bj_max_seats_per_user: z.number(),
      })
      .optional(),
   timer: z.object({
      type: z.union([z.literal(ETimerType.CIRCULAR), z.literal(ETimerType.HORIZONTAL)]).nullable(),
      betting_time: z.number().nullable(),
      decision_time: z.number().nullable(),
      insurance_time: z.number().nullable(),
   }),
});

export const rouletteGameConfigSchema = gameConfigSchema.merge(rouletteAdditionalConfigFields);
export const blackjackGameConfigSchema = gameConfigSchema.merge(blackJackAdditionalConfigFields);
