import { FC } from 'react';

import * as S from './ProgressBar.styles';

export const ProgressBar: FC<{ progress: number }> = ({ progress }) => {
   return (
      <S.ProgressBarContainer data-testid="coverage-value" fillWidth={progress}>
         <S.ProgressBarPercentValue>{`${progress}%`}</S.ProgressBarPercentValue>
      </S.ProgressBarContainer>
   );
};
