import { FC } from 'react';

import { TNullable } from '@/common/types';
import { ETimerType } from '@/common/modules/CountDown/constants';

import { CountDownContainer } from '#/modules/SwitchCountDown/components/CountDownContainer';

import { useGetCountDown } from './hooks';

interface ICountDown {
   timerType: ETimerType;
}
export const SwitchCountDown: FC<ICountDown> = ({ timerType }): TNullable<JSX.Element> => {
   const { type, TimerComponent } = useGetCountDown();

   if (type !== timerType) {
      return null;
   }

   return <CountDownContainer>{({ tick }) => <TimerComponent tick={tick} />}</CountDownContainer>;
};
