import { useGameState } from '@/common/state/gameState';

import { GameInfo, PlayersList } from '#/modules';
import { ChatControl } from '#/modules/Chat/components/ChatControl';
import { FavouriteControl, MenuControl } from '#/modules/Control/components';
import { DealerRatingStarsView } from '#/modules/DealerRatingViews/DealerRatingStarsView';

import * as S from './LeftColumn.styles';

export const LeftColumn = () => {
   const { isRoundResultState } = useGameState();

   return (
      <S.LeftColumnContainer isRoundResult={isRoundResultState}>
         <PlayersList />
         <S.GameInfoContainer>
            <GameInfo />
            <DealerRatingStarsView />
         </S.GameInfoContainer>
         <S.LeftControlsContainer>
            <ChatControl />
            <FavouriteControl />
            <MenuControl />
         </S.LeftControlsContainer>
      </S.LeftColumnContainer>
   );
};
