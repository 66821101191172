const _getQueryParamsFromUrl = (url: URL) =>
   [...url.searchParams.entries()].reduce(
      (params, [key, value]) => ({
         ...params,
         [key]: value,
      }),
      {},
   );

interface IGetValuesFromQueryParams {
   error?: string;
   key: string;
   params?: Record<string, string>;
}

export const getValueFromQueryParams = ({
   key,
   params = _getQueryParamsFromUrl(new URL(window.location.href)),
}: IGetValuesFromQueryParams): string => params[key];
