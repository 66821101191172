import { FC, ReactNode } from 'react';

import { DeviceOrientation, useDeviceOrientation } from '@/common/hooks';
import { getBettingMapConfig } from '@/common/roulette/utils/getBettingMapConfig';
import { rgbaToSvgFeColorMatrix } from '@/common/utils';
import { TGameTableColors } from '@/common/services/api/gameTablesAPI/hooks/useGetGameTableById/types';

import { useBetConstructorOrientationContext } from '#/modules/Favourite/components/BetConstructor/context';

import { SvgLandscape, SvgPortrait } from './components';
import { HotAndColdFilters } from './components/SvgFilters/components';
import { SVG_BLUR_FILTER_ID } from './constants';

export interface IRaceTrackSvgProps {
   children?: ReactNode;
   colorsConfiguration: TGameTableColors;
   isDisabled?: boolean;
   showSmallModeRacetrack?: boolean;
   trackContainerId: string;
}
export const RaceTrackSvg: FC<IRaceTrackSvgProps> = ({
   children,
   showSmallModeRacetrack,
   trackContainerId,
   isDisabled = false,
   colorsConfiguration,
}) => {
   const deviceOrientation: DeviceOrientation = useDeviceOrientation();
   const betConstructorOrientation = useBetConstructorOrientationContext();

   const whenRenderPortraitSvg: boolean = [deviceOrientation, betConstructorOrientation].includes(
      DeviceOrientation.PORTRAIT,
   );

   const bettingMapConfig = getBettingMapConfig({
      isDisabled,
      config: colorsConfiguration ?? {},
   });
   const { hotShadowColor, coldShadowColor } = bettingMapConfig;

   const RaceTrackSvgComponent =
      whenRenderPortraitSvg || showSmallModeRacetrack ? SvgPortrait : SvgLandscape;

   return (
      <RaceTrackSvgComponent bettingMapConfig={bettingMapConfig}>
         {children}
         <filter id={`${trackContainerId}_${SVG_BLUR_FILTER_ID}`}>
            <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
         </filter>
         <defs>
            <HotAndColdFilters
               coldColorOption={rgbaToSvgFeColorMatrix(coldShadowColor)}
               hotColorOption={rgbaToSvgFeColorMatrix(hotShadowColor)}
               trackContainerId={trackContainerId}
            />
         </defs>
      </RaceTrackSvgComponent>
   );
};
