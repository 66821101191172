import { Suspense, lazy } from 'react';

import { isDesktopDevice } from '@/common/utils/checkDevice';
import { isPortraitMode, useDeviceOrientation } from '@/common/hooks';
import { getZIndex } from '@/common/ui/theme/mixins';

import { Sidebars, Chat } from '#/modules';
import { DealerRatingModalView } from '#/modules/DealerRatingViews/DealerRatingModalView';

import PortraitGrid from './PortraitGrid';
import LandscapeMobile from './LandscapeMobile';

const LandscapeDesktop = lazy(() => import('./LandscapeDesktop'));

const DesktopGrid = () => (
   <Suspense fallback={null}>
      <LandscapeDesktop />
   </Suspense>
);

const MobileGrid = () => {
   const deviceOrientation = useDeviceOrientation();
   const isPortrait = isPortraitMode(deviceOrientation);
   const MobileGrid = isPortrait ? PortraitGrid : LandscapeMobile;

   return (
      <>
         <MobileGrid />
         <Sidebars />
         <div
            style={{
               zIndex: getZIndex('chat'),
               position: 'absolute',
               top: '0',
               left: isPortrait ? '0' : 'unset',
               width: '100%',
            }}
         >
            <Chat />
         </div>
         <DealerRatingModalView />
      </>
   );
};

export const Grid = () => (isDesktopDevice() ? <DesktopGrid /> : <MobileGrid />);
