import { useRef } from 'react';

import { getCellsIdFromDataAttrs } from '#/modules/Track/BettingMap/helpers';
import { BettingMapRefType } from '#/modules/Track/BettingMap/hooks/useBettingMapRef/types';

const HIGHLIGHT_CLASSNAME = 'highlight';

type HighlightCells = (event: DragEvent) => void;
type RemoveHighlightCells = (event: DragEvent) => void;

export const useHighlightCells = (
   node: BettingMapRefType,
): {
   highlightCells: HighlightCells;
   removeHighlightCells: RemoveHighlightCells;
} => {
   const highlightCellIndexes = useRef<string[]>([]);
   const getCellNode = (container: HTMLElement, selector: string) => {
      return container.querySelector(selector);
   };
   const highlightCells: HighlightCells = (event) => {
      if (!node) {
         return;
      }

      const target = event.target as HTMLElement;

      highlightCellIndexes.current = getCellsIdFromDataAttrs(target, 'highlightCells');
      highlightCellIndexes.current.forEach((id) => {
         const cellNode = getCellNode(node, `.cell_${id}`);

         if (cellNode) {
            cellNode.classList.add(HIGHLIGHT_CLASSNAME);
         }
      });
   };

   const removeHighlightCells = (event) => {
      if (!node) {
         return;
      }

      const whenHasRelatedTarget = event.relatedTarget;
      const whenHasTarget = event.target;

      if (whenHasRelatedTarget && whenHasTarget) {
         const getDataHighlightAttributes = (target: HTMLElement) => {
            return target.dataset?.highlightCells?.split(',') ?? [];
         };

         const eventTarget = event.target;
         const relatedTarget = event.relatedTarget;

         const newIdx = getDataHighlightAttributes(relatedTarget);
         const oldIdx = getDataHighlightAttributes(eventTarget);

         const diff = oldIdx.filter((id) => !newIdx.includes(id));

         diff.forEach((id) => {
            const cellNode = getCellNode(node, `.cell_${id}`);
            if (cellNode) {
               cellNode.classList.remove(HIGHLIGHT_CLASSNAME);
            }
         });
      }
   };

   return {
      highlightCells,
      removeHighlightCells,
   };
};
