import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { ETypeBet } from '#/state/features/bets';
import { TRootState } from '#/state/types';

import { TLimits } from './types';

interface ITableCapacityState {
   betLimitsByType: TLimits;
}

const min = 10;

export const mockBetLimitTypes = {
   [ETypeBet.STRAIGHT]: {
      min,
      max: 200,
   },
   [ETypeBet.SPLIT]: {
      min,
      max: 400,
   },
   [ETypeBet.STREET]: {
      min,
      max: 600,
   },
   [ETypeBet.CORNER]: {
      min,
      max: 800,
   },
   [ETypeBet.LINE]: {
      min,
      max: 1000,
   },
   [ETypeBet.DOZEN]: {
      min,
      max: 200,
   },
   [ETypeBet.COLUMN]: {
      min,
      max: 200,
   },
   [ETypeBet.RED]: {
      min,
      max: 200,
   },
   [ETypeBet.BLACK]: {
      min,
      max: 200,
   },
   [ETypeBet.EVEN]: {
      min,
      max: 200,
   },
   [ETypeBet.ODD]: {
      min,
      max: 200,
   },
   [ETypeBet.LOW]: {
      min,
      max: 200,
   },
   [ETypeBet.HIGH]: {
      min,
      max: 200,
   },
};

const initialState: ITableCapacityState = {
   betLimitsByType: mockBetLimitTypes,
};

export const tableCapacitySlice = createSlice({
   name: 'tableCapacity',
   initialState,
   reducers: {
      setBetLimits: (state, action: PayloadAction<ITableCapacityState['betLimitsByType']>) => {
         state.betLimitsByType = action.payload;
      },
   },
});

export const useTableCapacityActions = () => {
   const dispatch = useDispatch();
   const { setBetLimits } = tableCapacitySlice.actions;

   const handleSetBetLimits = (limits: ITableCapacityState['betLimitsByType']): void => {
      dispatch(setBetLimits(limits));
   };

   return {
      handleSetBetLimits,
   };
};

const useTableCapacitySelector = (): ITableCapacityState =>
   useSelector(({ tableCapacity }: TRootState) => tableCapacity);

export const useTableCapacity = () => {
   const { betLimitsByType } = useTableCapacitySelector() ?? {};

   return {
      betLimitsByType,
   };
};
