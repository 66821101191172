import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '@/common/types';

import { TRootState } from '#/state/types';

interface ITooltipState {
   openTooltipId: TNullable<string>;
}

const initialState: ITooltipState = {
   openTooltipId: null,
};

export const tooltipPortalSlice = createSlice({
   name: 'tooltip-portal',
   initialState,
   reducers: {
      updateTooltip: (state, action: PayloadAction<TNullable<string>>): void => {
         state.openTooltipId = action.payload;
      },
   },
});

export const useTooltipPortalActions = () => {
   const dispatch = useDispatch();
   const { updateTooltip } = tooltipPortalSlice.actions;

   const handleUpdateTooltip = (openTooltipId: TNullable<string>): void => {
      dispatch(updateTooltip(openTooltipId));
   };

   return { handleUpdateTooltip };
};

export const useTooltipPortalSelector = (): TNullable<string> =>
   useSelector(
      createSelector(
         (state: TRootState) => state.tooltipPortal.openTooltipId,
         (showTooltip) => showTooltip,
      ),
   );
