import './animations.css';

import { createGlobalStyle } from 'styled-components';

import { aspectRationMixin, orientation } from '@/common/ui/theme/mixins';
import { isTabletDevice } from '@/common/utils/checkDevice';
import { normalizer } from '@/common/ui/theme/normalizer';

export const GlobalStyles = createGlobalStyle`
  ${normalizer};

  * {
    -webkit-tap-highlight-color: transparent;
    touch-action: pan-x pan-y;

    &:not(input) {
      user-select: none;
    }
  }

  html {
    height: -webkit-fill-available;
  }

  body {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    font-family: 'Montserrat', 'Roboto', sans-serif;
    background-color: #000;

    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;

    ${orientation.portrait`
      background-color: ${({ theme }) => theme.colors.black[50]};
    `}

    ${orientation.mobileLandscape`
      background-color: ${({ theme }) => theme.colors.black[50]};
    `}
  }

  // this property for ios devices because User Agent Style Sheet
  // on these devices set property overflow-y and overflow-x to value "hidden"
   svg:not(:root) {
      overflow-y: visible;
      overflow-x: visible;
   }

  #root {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  body, #root {
    ${orientation.portrait`
      height: auto;
      overflow: hidden;
    `}
  }

  .main__wrapper {
    width: inherit;
    height: inherit;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .scrollbars > div {
    overflow-x: auto !important;
  }

  .no-cursor {
    cursor: auto;
  }

  .hidden {
    opacity: 0;
  }

  .main-container {
    position: relative;
    overflow: hidden;

    ${aspectRationMixin({ width: 16, height: 9 })}
    width: ${isTabletDevice() ? '100%' : 'auto'};

    &.overflow-visible {
      overflow: visible;
    }
  }
`;
