import { EGameState, useGameState } from '@/common/state/gameState';

import { useTrackState } from '#/modules/Track/state';

import { ISwitchControl } from '../types';

export const useCollapseSwitchTrackType = (): ISwitchControl => {
   const { gameState } = useGameState();
   const { isCollapsedTrack, handleToggleTrackType, handleCollapseTrack } = useTrackState();

   const handleRollup = (): void => {
      handleCollapseTrack(!isCollapsedTrack);
      if (isCollapsedTrack) {
         handleToggleTrackType();
      }
   };

   const handlers: Record<string, () => void> = {
      [EGameState.NO_MORE_BETS]: handleRollup,
      DEFAULT: handleToggleTrackType,
   };

   const handleOnClick = handlers[gameState] ?? handlers.DEFAULT;

   return {
      handleOnClick,
   };
};
