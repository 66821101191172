import { logger } from '@/common/utils';

import { ETrackingEvents } from './constants';
import { eventsToTrackController } from './EventsToTrackController';

const checkRoundReceivedAllEvents = (events) => Object.values(events).every((value) => value);

export const eventsTracker = (event: ETrackingEvents, eventsToTrack) => {
   // when round canceled we can't continue tracking
   // hence we reset state and waiting for the new round start event
   const isRoundCanceled = event === ETrackingEvents.CANCEL_ROUND;
   if (isRoundCanceled) {
      eventsToTrackController.reset();
   }

   // when round started we reset state (clear previous events)
   // and start events tracking from scratch
   const startEventsTracking = event === ETrackingEvents.ROUND_START;
   if (startEventsTracking) {
      eventsToTrackController.reset();
   }

   const isTrackingInProgress = startEventsTracking || eventsToTrack[ETrackingEvents.ROUND_START];
   if (isTrackingInProgress) {
      // mark when event received
      eventsToTrack[event] = true;
      // when round is over we start events validation
      // in error case send logs to sentry
      const isRoundClosed = event === ETrackingEvents.WIN_NUMBER;
      if (isRoundClosed) {
         const isRoundReceivedAllEvents = checkRoundReceivedAllEvents(eventsToTrack);

         if (!isRoundReceivedAllEvents) {
            logger.error(`Not all events were received ${JSON.stringify(eventsToTrack)}`);
         }
      }
   }
};
