import { TUserBetsByRoundId } from '@/common/services/api/betsAPI/types';

import { calculateTotalBets } from '#/state/features/bets';
import { IBetsState } from '#/state/features/bets/bets';
import { TCommonBet } from '#/state/features/bets/types';

import { reverseBetAdapter } from './reverseAdapter';

export const betsReverseAdapter = (bets: TUserBetsByRoundId[]): IBetsState => {
   const transformedBets: TCommonBet[] = [];

   const betAdapterMap = {
      straight: reverseBetAdapter.straightBetAdapter,
      column: reverseBetAdapter.columnBetAdapter,
      corner: reverseBetAdapter.cornerBetAdapter,
      split: reverseBetAdapter.splitBetAdapter,
      dozen: reverseBetAdapter.dozenBetAdapter,
      highLow: reverseBetAdapter.highLowBetAdapter,
      parity: reverseBetAdapter.parityBetAdapter,
      color: reverseBetAdapter.colorBetAdapter,
      line: reverseBetAdapter.lineBetAdapter,
      street: reverseBetAdapter.streetBetAdapter,
   };

   for (const bet of bets) {
      const adapter = betAdapterMap[bet.type];

      if (!adapter) {
         continue;
      }

      const transformedBet = adapter(bet);

      if (transformedBet) {
         transformedBets.push(transformedBet);
      }
   }

   return {
      betsHistory: transformedBets,
      totalBets: calculateTotalBets(transformedBets),
      zIndexes: {},
   };
};
