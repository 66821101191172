// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex, orientation } from '@/common/ui/theme/mixins';

export const WinnerListChildrenContainer = styled.div`
   --winner-list-height: 245px;
   --winner-list-width: 230px;
   --winner-list-height-padding: 15px 18px 5px 18px;

   ${applyZIndex('winNumbers')}

   position: absolute;
   min-width: var(--winner-list-width);
   height: var(--winner-list-height);
   margin-top: 70px;
   padding: var(--winner-list-height-padding);
   background: rgba(0, 0, 0, 0.9);

   ${orientation.mobileLandscape`
      --winner-list-width: auto;
      --winner-list-height: 28px;

      min-width: 125vw;
      left: -120px;
      top: -10px;
      margin-top: unset;
      padding: 7px 0;
   `}

   ${orientation.portrait`
      --winner-list-width: auto;
      --winner-list-height: 28px;

      min-width: 100vh;
      left: -5px;
      margin-top: -15px;
      padding: 5px;
   `}
`;
