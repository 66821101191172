export const enum EPlaceTrack {
   MainTrack = 'MAIN_TRACK',
   BetGeneratorTrack = 'BET_GENERATOR_TRACK',
}

export const enum ETypeBet {
   STRAIGHT = 'STRAIGHT',
   ODD = 'ODD',
   EVEN = 'EVEN',
   RED = 'RED',
   BLACK = 'BLACK',
   LOW = 'LOW',
   HIGH = 'HIGH',
   LINE = 'LINE',
   DOZEN = 'DOZEN',
   SPLIT = 'SPLIT',
   STREET = 'STREET',
   CORNER = 'CORNER',
   COLUMN = 'COLUMN',
   DOUBLE = 'DOUBLE',
   COMPLETE = 'COMPLETE',
   LUCKY = 'LUCKY',
   FINAL = 'FINAL',
   JEU_ZERO = 'JEU_ZERO',
   TIERS = 'TIERS',
   VOISIN = 'VOISIN',
   ORPHELINS = 'ORPHELINS',
}
