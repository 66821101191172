import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { theme } from '@/common/ui/theme';

const Theme = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

ThemeProvider.displayName = 'Theme.Provider';

export { Theme as ThemeProvider };
