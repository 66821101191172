export const createAnimation = ({
   strokeDasharray,
   duration,
}: {
   strokeDasharray: number;
   duration: number;
}): [Keyframe[], KeyframeAnimationOptions] => {
   const keyframes: Keyframe[] = [
      { strokeDashoffset: strokeDasharray },
      { strokeDashoffset: 0, opacity: 1 },
      { opacity: 0 },
   ];
   const options: KeyframeAnimationOptions = {
      duration,
      iterations: 1,
      fill: 'forwards',
   };

   return [keyframes, options];
};
