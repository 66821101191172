// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex } from '@/common/ui/theme/mixins';

export const Mask = styled.div`
   & + div {
      ${applyZIndex('noPointerEvents')}
   }
   &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transition:
         visibility 1s,
         opacity 1s linear;
      ${applyZIndex('noPointerEvents')}
   }
`;
