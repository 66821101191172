import React from 'react';

export const PlusIcon = () => (
   <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M15.3321 2.30718C15.3321 1.18721 14.4174 0.279297 13.2891 0.279297C12.1608 0.279297 11.2462 1.18721 11.2462 2.30718V11.4326H2.05274C0.924437 11.4326 0.00976562 12.3405 0.00976562 13.4605C0.00976562 14.5805 0.924437 15.4884 2.05274 15.4884H11.2462V24.6139C11.2462 25.7338 12.1608 26.6417 13.2891 26.6417C14.4174 26.6417 15.3321 25.7338 15.3321 24.6139V15.4884H24.5255C25.6538 15.4884 26.5685 14.5805 26.5685 13.4605C26.5685 12.3405 25.6538 11.4326 24.5255 11.4326H15.3321V2.30718Z"
         fill="#D2D2D2"
      />
   </svg>
);
