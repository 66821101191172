// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const HorizontalCountDownContainer = styled.div`
   --timer-width: 400px;
   --timer-height: 30px;
   --timer-border-width: 2px;
   --timer-border-radius: 20px;
   --timer-value-fz: 14px;
   --timer-value-size: var(--timer-height);
   --timer-message-fz: 20px;
   --timer-transition-duration: 0.5s;
   --timer-transition-timing-function: linear;

   ${orientation.mobileLandscape`
      --timer-width: 300px;
      --timer-height: 20px;
      --timer-message-fz: 13px;
      --timer-value-fz: 11px;

      border-radius: 40px;
      margin-bottom: 5px;
      margin-top: 0;
   `}

   ${orientation.portrait`
      --timer-width: 40vh;
      --timer-height: 21px;
      --timer-message-fz: 13px;
      --timer-value-fz: 11px;
   `}

   position: relative;
   width: var(--timer-width);
   height: var(--timer-height);
   border-radius: var(--timer-border-radius);
   background-color: #1a1a1a;
   color: ${({ theme }) => theme.colors.white};
`;
