import { EModalNotificationType } from '@/common/ui/ModalNotification/constants';

import { useTranslation } from '#/translates';

import { Overlay } from '../Overlay';
import { useLobbyRedirect } from '../../hooks';

export const LostConnectionOverlay = () => {
   const { t } = useTranslation();
   const handleLobbyRedirect = useLobbyRedirect();

   return (
      <Overlay
         type={EModalNotificationType.Warning}
         title={t('lostConnectionOverlay.title')}
         actionButton={{
            title: t('lostConnectionOverlay.action'),
            onClick: handleLobbyRedirect,
         }}
      />
   );
};
