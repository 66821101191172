// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { isHoverSupports } from '../theme/mixins';

export const Arrow = styled.span`
  position: relative;
  display: flex;
  color: ${({ theme }) => theme.colors?.white};
  background-color: transparent;
  align-items: center;
  justify-content: center;
  width: var(--arrow-size);
  height: var(--arrow-size);
  will-change: transform;

  ${isHoverSupports`
      &:hover {
          cursor: pointer;
          ${({ disabled }) =>
            disabled &&
            css`
              cursor: auto;
            `}
       }
   `}

  &::before,
   &::after {
    content: '';
    position: absolute;
    background-color: ${({ arrowColor }) => arrowColor};
    border-radius: 5px;
  }

  &::before {
    width: 100%;
    height: var(--arrow-width);
    left: 0;
    bottom: 0;
  }

  &::after {
    width: var(--arrow-width);
    height: 100%;
    right: 0;
    bottom: 0;
  }

  &.top {
    transform: rotate(-135deg);
  }
  &.right {
    transform: rotate(-45deg);
  }
  &.bottom {
    transform: rotate(45deg);
  }
  &.left {
    transform: rotate(135deg);
  }
`;
