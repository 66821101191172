// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex } from '@/common/ui/theme/mixins';

export const SwipeUpContainer = styled.div`
   ${applyZIndex('swipeUp')}

   position: fixed;
   left: 0;
   top: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100vw;
   height: 100vh;
   background-color: rgb(0, 0, 0, 85%);
`;

export const SwipeUpClose = styled.span`
   position: absolute;
   right: 30px;
   top: 30px;
   width: 18px;
   height: 18px;

   &:before,
   &:after {
      --width: 3px;

      position: absolute;
      content: '';
      top: 0;
      left: calc(50% - var(--width) / 2);
      width: var(--width);
      height: 100%;
      border-radius: 10px;

      background-color: rgb(196, 196, 196, 100%);
   }

   &:before {
      transform: rotateZ(45deg);
   }

   &:after {
      transform: rotateZ(-45deg);
   }
`;
