import { hexToRgba } from '@/common/utils';
import { TGameTableColors } from '@/common/services/api/gameTablesAPI/hooks/useGetGameTableById/types';

import { IBettingMapConfig, defaultBettingMapColorsConfig } from '../../constants';

export const getBettingMapConfig = ({
   config,
   isDisabled,
   defaultConfig = defaultBettingMapColorsConfig,
}: {
   config: TGameTableColors;
   defaultConfig?: IBettingMapConfig;
   isDisabled: boolean;
}): IBettingMapConfig => {
   const { transparency } = config;
   const transparencyOption = isDisabled ? 0.6 : transparency || 1;
   const colorsList = Object.keys(defaultConfig).filter(
      (configItem) => configItem !== 'transparency',
   );
   return colorsList.reduce((total, color) => {
      const hexColor = config?.[color] ?? defaultConfig[color];
      const rgbaColor = hexToRgba(hexColor, transparencyOption);
      total[color] = rgbaColor;
      return total;
   }, {} as IBettingMapConfig);
};
