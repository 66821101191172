import { Bet, TableCapacity, TableCapacityProgressBar, WinHistory } from '#/modules';
import { Balance } from '#/modules/Balance/Balance';
import { WinHistoryWithTableCapacityGroup } from '#/layout/componentsGroup';

import * as S from './BottomColumn.styles';

export const BottomColumn = () => (
   <S.BottomRow>
      <Balance />
      <Bet />
      <WinHistoryWithTableCapacityGroup>
         <WinHistory />
         <TableCapacityProgressBar />
      </WinHistoryWithTableCapacityGroup>
      <TableCapacity />
   </S.BottomRow>
);
