import { useRef } from 'react';

import { TSetTimeout, TNullable } from '@/common/types';

import { LIMIT_TOOLTIP_CLASSNAME, SHOW_TOOLTIP_IN_SEC } from '../../constants';
import { positioningTooltip } from '../../helpers';
import { useLimitTooltipActions } from '../../state';
import { THandleShowMaxBetTooltip } from '../../types';

export const LimitTooltipWrapper = ({ children }) => {
   const timeoutRef = useRef<TNullable<TSetTimeout>>(null);
   const { handleHideTooltip } = useLimitTooltipActions();

   const handleShowMaxBetTooltip: THandleShowMaxBetTooltip = (element) => {
      if (element.target) {
         positioningTooltip(element.target as HTMLElement, `.${LIMIT_TOOLTIP_CLASSNAME}`);
      }
      // reset timeout on the new click to prevent hiding tooltip while usr is clicking over it
      if (timeoutRef.current) {
         clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
         handleHideTooltip();
      }, SHOW_TOOLTIP_IN_SEC);
   };

   return <>{children({ handleShowMaxBetTooltip })}</>;
};
