import { FC } from 'react';

import { useTranslation } from '#/translates';

import { useWinnerListTotalAmount } from '../../hooks';
import { IWinnerList } from '../types';

import * as S from './WinnerListTitle.styles';

interface IWinnerListTitleProps {
   winnerList: IWinnerList['winnerList'];
}

export const WinnerListTitle: FC<IWinnerListTitleProps> = ({ winnerList }) => {
   const { t } = useTranslation();
   const winnerListTotalAmount = useWinnerListTotalAmount(winnerList);

   return (
      <S.WinnerListTitle>
         {t('playersList.title')} {winnerListTotalAmount}
      </S.WinnerListTitle>
   );
};
