// @ts-nocheck
import styled, { keyframes } from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

const arcAnimation = keyframes`
   0% {
      transform: translate(0, 15%) rotate(-40deg);
   }
   25% {
      transform: translate(0, 15%) rotate(0deg);
   }
   100% {
      transform: translate(0, 15%) rotate(0deg);
   }
`;

export const WinNumbersWrapper = styled.div`
   position: relative;
   width: 290px;

   svg {
      width: 100%;
      height: 100%;
   }
`;

export const Circle = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   aspect-ratio: 1/ 1;
   border-radius: 50%;
   transform: translate(0, 15%) rotate(0deg);
   animation-name: ${arcAnimation};
   animation-timing-function: linear;
   animation-duration: ${({ animationDuration }) => `${animationDuration}ms`};
   animation-fill-mode: forwards;
   animation-iteration-count: 1;
   pointer-events: none;
   touch-action: none;
`;

export const Digit = styled.span`
   --digit-fz: 60px;

   width: 25%;
   height: 25%;
   display: grid;
   place-items: center;
   position: absolute;
   color: rgb(255, 255, 255);
   font-size: var(--digit-fz);
   font-weight: 600;
   will-change: transform;

   &:nth-child(1) {
      transform: translate(35%, -15%) rotate(-25deg);
   }

   &:nth-child(2) {
      transform: translate(150%, -40%);
   }

   &:nth-child(3) {
      transform: translate(265%, -15%) rotate(25deg);
   }

   ${orientation.portrait`
      --digit-fz: 35px;
   `}

   ${orientation.mobileLandscape`
      --digit-fz: 35px;
   `}
`;
