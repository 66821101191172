import { UnknownAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { deepEqual } from '@/common/utils';

import { BETS_ACTIONS } from '#/state/features/bets';
import { TRootState } from '#/state/types';

import { cancelAutoplayOnUpdateBets } from './effect/cancelAutoplayOnUpdateBets';

export const cancelAutoplayMiddleware = createListenerMiddleware();

// todo: add one method to betCreator (for example "makeBet") through which we will make each bet (after that we can remove this middleware)
const areBetsUpdated = (
   action: UnknownAction,
   currentState: TRootState,
   previousState: TRootState,
): boolean => {
   const actions = [BETS_ACTIONS.addBets, BETS_ACTIONS.addDoubleBet];
   const partialBetsActionType = 'betConfirmed/setSendBetsStatusPartial';

   let areBetsChanged = false;

   if (action.type && actions.some((item) => action.type.includes(`bets/${item}`))) {
      areBetsChanged = !deepEqual(currentState.bets.totalBets, previousState.rebet.totalBets);
   }

   if (action.type === partialBetsActionType) {
      return true;
   }

   return areBetsChanged;
};

cancelAutoplayMiddleware.startListening({
   effect: cancelAutoplayOnUpdateBets,
   predicate: areBetsUpdated,
});
