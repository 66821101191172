// @ts-nocheck
import styled from 'styled-components';

import { applyZIndex, orientation } from '@/common/ui/theme/mixins';

import { WinSumWrapper } from '#/modules/WinSum/WinSum.styles';
import { WinNumbersWrapper } from '#/modules/WinNumbers/components/WinNumbersView/WinNumbersView.styles';

export const WinResult = styled.div`
   ${applyZIndex('winNumbers')};

   position: absolute;
   width: 30%;
   height: 240px;
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: 120px 130px;
   gap: 5px;
   justify-items: center;
   align-items: center;
   transform: translateY(-560px);
   pointer-events: none;

   ${orientation.portrait`
      top: 5%;
      left: 0%;
      width: 100%;
      height: 20vh;
      grid-template-rows: 50% 50%;
      align-items: flex-start;
      transform: translateY(40%);

      ${WinNumbersWrapper} {
         width: 70%;
         margin: 0 auto;
      }

      ${WinSumWrapper} {
         width: 100%;
         height: auto;
         transform: scale(1.4);
      }
   `}

   ${orientation.mobileLandscape`
      bottom: 0;
      width: 50vw;
      height: 45vh;
      grid-template-rows: 50% 50%;
      transform: translateY(-42%);

      @media screen and (max-height: 300px) {
         transform: translateY(-100%);
      }

      ${WinNumbersWrapper} {
         width: 60%;
         min-height: 80px;
         margin: 0 auto;
      }

      ${WinSumWrapper} {
         width: 100%;
         height: 100%;
      }
   `}
`;

export const WinResultChild = styled.div`
   display: grid;
   place-items: center;
   width: 100%;
   height: 100%;
`;
