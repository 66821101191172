import { DateFormatter } from '../DateFormatter';

const _calculateEndTime = ({
   startTime,
   bettingTimeDuration,
}: {
   startTime: number;
   bettingTimeDuration: number;
}): number => startTime + bettingTimeDuration;

export const getEndTime = ({
   startBettingTime,
   bettingTime,
}: {
   startBettingTime: number | string;
   bettingTime: number;
}) =>
   _calculateEndTime({
      startTime: DateFormatter.toUnixTime(startBettingTime),
      bettingTimeDuration: bettingTime,
   });
