import { FC } from 'react';

import { ErrorBoundary } from '@/common/dev/ErrorBoundary';
import { autoKeys } from '@/common/utils';

import { winNumberDTO } from '#/modules/WinNumbers';

import { getWinNumberColor } from '../../utils';

import * as S from './WinHistoryView.styles';

interface IWinHistoryProps {
   winHistory: string[];
}

export const WinHistoryView: FC<IWinHistoryProps> = ({ winHistory }) => (
   <ErrorBoundary>
      <S.WinHistoryContainer data-testid="win-numbers-container">
         <S.ListWrapper>
            {autoKeys(
               winHistory?.map((winNumber, index) => {
                  const isLastWinNumber = index === 0;

                  return isLastWinNumber ? (
                     <S.LastWinNumber
                        data-testid={`win-history-${winNumber}`}
                        color={getWinNumberColor(winNumber)}
                     >
                        {winNumberDTO(winNumber)}
                     </S.LastWinNumber>
                  ) : (
                     <S.WinNumber
                        data-testid={`win-history-${winNumber}`}
                        color={getWinNumberColor(winNumber)}
                     >
                        {winNumberDTO(winNumber)}
                     </S.WinNumber>
                  );
               }),
            )}
         </S.ListWrapper>
      </S.WinHistoryContainer>
   </ErrorBoundary>
);
