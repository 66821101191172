import { hasOwn } from '@/common/utils/hasOwn';
import { logger } from '@/common/utils/logger';

import { structuredCloneFallback } from './fallback';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const deepCopy = <T>(obj: T): T => {
   if (typeof obj !== 'object') {
      throw new Error('We can copy only object');
   }

   if (typeof window !== 'undefined' && 'structuredClone' in window) {
      try {
         return window.structuredClone(obj);
      } catch (error) {
         logger.error('window.structuredClone failed. Falling back to JSON methods.');
      }
   }

   // Fallback
   try {
      return structuredCloneFallback(obj) as T;
   } catch (error) {
      const copy: Record<string, unknown> = {};

      for (const key in obj) {
         if (hasOwn(obj, key)) {
            if (typeof obj[key] === 'object') {
               copy[key] = deepCopy(obj[key]);
            } else {
               copy[key] = obj[key];
            }
         }
      }

      return copy as T;
   }
};
