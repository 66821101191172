import { z } from 'zod';

export const roundDetailBetsScheme = z.object({
   uuid: z.string().uuid(),
   type: z.string(),
   net_cash: z.number(),
   bet: z.number(),
   params: z.record(z.string(), z.string()),
});

export const roundDetailScheme = z.object({
   title: z.string(),
   win_number: z.string(),
   round_created: z.coerce.date(),
   round_number: z.string(),
   physical_table_id: z.string(),
   uuid: z.string().uuid(),
   bets: roundDetailBetsScheme.array(),
});
