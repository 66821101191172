import { useState } from 'react';
import { Swiper } from 'swiper';

import { TNullable } from '../../../../types';

export const useSwiperControls = () => {
   const [swiper, setSwiper] = useState<TNullable<Swiper>>(null);

   const handlePreviousSlide = (): void => {
      if (swiper) {
         swiper.slidePrev();
      }
   };

   const handleNextSlide = (): void => {
      if (swiper) {
         swiper.slideNext();
      }
   };

   return { setSwiper, handleNextSlide, handlePreviousSlide, swiper };
};
