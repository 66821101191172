import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  memo,
  useRef,
  useState,
} from 'react';

import { TSetTimeout, TNullable } from '../../types';
import { isMobileDevice } from '../../utils/checkDevice';

import * as S from './Tooltip.styles';

interface ITooltipProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
  position: 'bottom-left' | 'bottom-right' | 'center' | 'left' | 'right';
  showDelay?: number;
  suppressTooltip?: boolean;
  text: string;
}
export const _Tooltip: FC<ITooltipProps> = ({
  text,
  children,
  position,
  style = {},
  showDelay = 200,
  suppressTooltip = false,
}) => {
  const [isShow, setIsShow] = useState(false);
  const timeout: MutableRefObject<TNullable<TSetTimeout>> = useRef(null);

  const handleShow = (): void => {
    timeout.current = setTimeout(() => setIsShow(true), showDelay);
  };

  const handleHide = (): void => {
    if (timeout.current) {
      clearInterval(timeout.current);
    }
    setIsShow(false);
  };

  const whenRenderTooltip: boolean = isShow && text.length > 0;

  if (isMobileDevice()) {
    return <>{children}</>;
  }

  return (
    <S.Container style={style}>
      <S.Children onMouseEnter={handleShow} onMouseLeave={handleHide}>
        {children}
      </S.Children>
      {!suppressTooltip && whenRenderTooltip && (
        <S.TooltipContainer position={position}>
          <S.TooltipContent>{text}</S.TooltipContent>
        </S.TooltipContainer>
      )}
    </S.Container>
  );
};

export const Tooltip = memo(_Tooltip);
