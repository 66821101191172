import { TChip } from '../../../../ui/Chip/entities';
import { IChipTrayProps } from '../../types';

export const useSortChipsByChipTrayDirection = ({
   chips,
   selectedChip,
   isVerticalSlider,
}: {
   chips: IChipTrayProps['chips'];
   selectedChip: TChip;
   isVerticalSlider: boolean;
}) => {
   // for the vertical chiptray we should sort chips from the min value (the bottom) to the max value (the top)
   const chipsList = isVerticalSlider ? Array.from(chips).sort((a, b) => b.value - a.value) : chips;
   const selectedChipIndex = chipsList.findIndex(({ id }) => id === selectedChip.id);

   return {
      selectedChipIndex,
      chipsList,
   };
};
