import { FC, ReactNode } from 'react';

import { useCountDown } from '@/common/modules/CountDown/hooks/useCountDown';
import { ErrorBoundary } from '@/common/dev/ErrorBoundary';
import {
   useTimerTickSelector,
   useTimerEndTimeSelector,
   useTimerActions,
} from '@/common/modules/CountDown/state';

interface ITimerContainerProps {
   children: ({ tick }: { tick: number }) => ReactNode;
}

export const CountDownContainer: FC<ITimerContainerProps> = ({ children }) => {
   const tick = useTimerTickSelector() as number;
   const endTime = useTimerEndTimeSelector();
   const { handleSetTick, handleSetStartTime, handleSetEndTime } = useTimerActions();

   const handleTimerTick = ({ tick, isCountDownFinished, delta }): void => {
      handleSetStartTime(delta);

      if (!isCountDownFinished) {
         handleSetTick(tick);
      } else {
         handleSetEndTime(null);
      }
   };

   useCountDown({
      endTime,
      cb: handleTimerTick,
      isLocalDelta: true,
   });

   return <ErrorBoundary>{children({ tick })}</ErrorBoundary>;
};
