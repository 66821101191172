// @ts-nocheck
import styled from 'styled-components/macro';

export const G = styled.g`
  text-rendering: geometricPrecision;
  fill: ${({ theme }) => theme.colors?.white};
  transform-origin: center;
`;

export const Text = styled.text`
  text-rendering: inherit;
`;

export const TSpan = styled.tspan`
  text-rendering: inherit;

  @supports (alignment-baseline: central) {
    alignment-baseline: central;
  }

  @supports (dominant-baseline: central) {
    dominant-baseline: central;
  }

  @supports (text-anchor: middle) {
    text-anchor: middle;
  }
`;
