import * as S from './DealerRateButton.styles';

export const DealerRateButton = ({
   buttonTitle,
   handleShowModal,
   isBlinking,
   isLeavingFeedbackButtonShown,
}: {
   buttonTitle: string;
   handleShowModal: () => void;
   isBlinking: boolean;
   isLeavingFeedbackButtonShown: boolean;
}) => {
   if (!isLeavingFeedbackButtonShown) {
      return;
   }

   return (
      <S.DealerRateButtonContainer
         isLeavingFeedbackTooltipShown={isLeavingFeedbackButtonShown}
         isBlinking={isBlinking}
         onClick={handleShowModal}
      >
         {buttonTitle}
      </S.DealerRateButtonContainer>
   );
};
