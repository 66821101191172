// eslint-disable-next-line import/no-named-as-default
import io, { Socket } from 'socket.io-client';

import { logger } from '@/common/utils';
import { TNullable } from '@/common/types';

export interface IParams {
   baseUrl: string;
   queryParams?: Record<string, string | string[]>;
}

type TSocketConfig = {
   reconnectionAttempts?: number;
};

export class SocketConnection {
   private socket: TNullable<Socket> = null;
   private static isConnectionCreated: TNullable<boolean> = null;

   public createConnection = ({
      baseUrl,
      events,
      queryParams,
      additionalAction,
      additionalConfig,
   }: {
      baseUrl: string;
      events: Record<string, () => void>;
      queryParams: Record<string, string | string[]>;
      additionalAction: Record<string, () => void>;
      additionalConfig: TSocketConfig;
   }): TNullable<Socket> => {
      const socketURL = SocketConnection.generateUrl({
         baseUrl,
         queryParams,
      });

      const socket = this.createSocketConnection(socketURL, additionalConfig);

      this.connect(events, additionalAction);

      return socket as Socket;
   };

   createSocketConnection = (connectionURL: string, config: TSocketConfig): Socket => {
      if (!SocketConnection.isConnectionCreated) {
         this.socket = io(connectionURL, {
            transports: ['websocket'],
            reconnectionAttempts: config?.reconnectionAttempts,
         });
         SocketConnection.isConnectionCreated = true;
      }
      return this.socket as Socket;
   };

   handleConnectionErrorEvents(handleConnectError?: () => void): void {
      if (this.socket) {
         this.socket.io.on('error', (error) => {
            handleConnectError?.();
            logger.log(`socket table connection error: ${error}`);
         });
         this.socket.on('connect_error', (error) => {
            handleConnectError?.();
            logger.log(`Cannot reconnect to websocket server: ${error}`);
         });
      }
   }

   public connect = (
      events:
         | Record<
              string,
              { handleEvent: (socket: unknown) => void; handleValidate: (socket: unknown) => void }
           >
         | Record<string, (socket: unknown) => void>,
      additionalAction: {
         reconnectHandlers?: {
            handleConnectError: () => void;
            handleSocketReconnect: (socket: unknown) => void;
         };
         trackEvents?: {
            handleTrackEvents: (socket: unknown) => void;
         };
      },
   ): void => {
      Object.entries(events).forEach(([event, handler]) =>
         this.socket?.on(
            event,
            (eventPayload: unknown, callback: ({ success }: { success: boolean }) => void) => {
               // when validation will be added to all apps the check below will be removed
               if (
                  typeof handler === 'object' &&
                  'handleEvent' in handler &&
                  'handleValidate' in handler
               ) {
                  handler.handleEvent(eventPayload);
                  handler.handleValidate(eventPayload);
               } else {
                  handler(eventPayload);
               }
               callback({ success: true });
            },
         ),
      );
      this.handleConnectionErrorEvents(additionalAction?.reconnectHandlers?.handleConnectError);
      additionalAction?.reconnectHandlers?.handleSocketReconnect(this.socket);
      additionalAction?.trackEvents?.handleTrackEvents(this.socket);
   };

   static generateUrl = ({ baseUrl, queryParams }: IParams): string => {
      const url: URL = new URL(baseUrl);

      if (queryParams && Object.keys(queryParams).length) {
         Object.entries(queryParams).forEach(([key, value]) => {
            if (Array.isArray(value)) {
               url.searchParams.append(key, value.join(','));
            } else {
               url.searchParams.append(key, value);
            }
         });
      }

      return url.href;
   };

   public emit = (event: string, payload: unknown) => {
      this.socket?.emit(event, payload);
   };

   public disconnect = (): void => {
      if (this.socket) {
         SocketConnection.isConnectionCreated = null;
         this.socket.disconnect();
      }
   };
}
