import { ErrorBoundary } from '@/common/dev/ErrorBoundary';

import { ProgressBar } from '#/components/ProgressBar';
import { useBetsState } from '#/state/features/bets/selectors';

import { tableCapacityCalculator } from './utils/TableCapacityCalculator';
import * as S from './TableCapacity.styles';

const useTableFullnessPercentage = (): number => {
   const { useBetsHistorySelector } = useBetsState();
   const bets = useBetsHistorySelector();

   return tableCapacityCalculator.calculateCapacity(bets);
};

export const TableCapacityProgressBar = () => {
   const tableFullness = useTableFullnessPercentage();

   return (
      <ErrorBoundary>
         <S.TableCapacityWrapper>
            <ProgressBar progress={tableFullness} />
         </S.TableCapacityWrapper>
      </ErrorBoundary>
   );
};
