import { useEffect, useState } from 'react';

import { useGameState } from '@/common/state/gameState';

export const useLoading = () => {
   const [isLoading, setIsLoading] = useState(false);
   const { isSuccessGameStatus, isWaitingForConnectionGameStatus, isSessionClosedDueToInactivity } =
      useGameState();

   useEffect(() => {
      if (isWaitingForConnectionGameStatus || isSessionClosedDueToInactivity) {
         return;
      }
      setIsLoading(isSuccessGameStatus);
   }, [isSuccessGameStatus]);

   return isLoading;
};
