// @ts-nocheck
import styled from 'styled-components';

export const CenterColumnContainer = styled.div`
   position: relative;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-bottom: 10px;
`;
export const NextRoundNotification = styled.div`
   position: fixed;
   top: 32%;
   left: 0;
`;

export const BetConfirmed = styled.div`
   position: absolute;
   top: 33%;
   left: 0;
   width: 100%;
`;
