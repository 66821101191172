import { Tooltip, EControl } from '@/common/ui';
import { useSidebar } from '@/common/modules/Sidebar';
import { ERouletteSidebar } from '@/common/entities/Sidebar';

import { useTranslation } from '#/translates';

import { Control } from '../ControlWithTapSound';

import { AutoplayRoundCounter } from './components/AutoplayCounter/AutoplayRoundCounter';

export const AutoplayControl = () => {
   const { sidebarType, handleSetSidebarType } = useSidebar();
   const { t } = useTranslation();

   return (
      <Tooltip position="left" text={t('sidebars.autoplay.title')}>
         <Control
            variant={EControl.Play}
            isActive={sidebarType === ERouletteSidebar.Autoplay}
            onClick={() => handleSetSidebarType(ERouletteSidebar.Autoplay)}
            dataTestId="autoplay-control"
         >
            <AutoplayRoundCounter />
         </Control>
      </Tooltip>
   );
};
