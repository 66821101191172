import { z } from 'zod';

export const updateAutoplaySchema = z.object({
   autoplayId: z.string(),
   lossLimit: z.number(),
   lossLimitLeft: z.number(),
   rounds: z.number().optional(),
   roundsLeft: z.number(),
});

export type UpdateAutoplayEvent = z.infer<typeof updateAutoplaySchema>;
