import { BetConfig } from 'core/config/BetConfig';

import { rangeNumbers } from '@/common/utils';

import { ETypeBet } from '#/state/features/bets';

class HighBetConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.HIGH;
   readonly config = [
      {
         id: 45,
         value: '19 to 36',
         numbers: rangeNumbers({ start: 19, end: 36 }),
      },
   ];
}

export const highConfig = new HighBetConfig();
