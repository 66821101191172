import { useState, useCallback } from 'react';

export const useShowLimitsTable = () => {
  const [isShowLimitsTable, setIsShowLimitsTable] = useState(false);

  const handleShowLimitsTable = useCallback(() => setIsShowLimitsTable(true), []);
  const handleHideLimitsTable = useCallback(() => setIsShowLimitsTable(false), []);

  return {
    isShowLimitsTable,
    handleShowLimitsTable,
    handleHideLimitsTable,
  };
};
