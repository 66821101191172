import { extractDoubleBet } from 'services/api/betsApiSlice/helpers/extractDoubleBet';
import { sumBetsById } from 'services/api/betsApiSlice/helpers/sumBetsById';

import { pipe } from '@/common/utils';

import { TBetType } from '#/state/features/bets/types';

import { TCallBet, TCommonBet, TRaceTrackTypeBets } from '../../types';
import { isRangeStraightBetType, isSpecialBetType, isCallBetType } from '../../type-guards';

export const calculateTotalBets = (commands: (TCallBet | TCommonBet)[]) => {
   return commands
      .reduce((total: TCommonBet[], bet) => {
         isCallBetType(bet) ? total.push(...bet.extractedCommand) : total.push(bet);
         return total;
      }, [])
      .reduce((total, { id, amount }) => {
         total[id] = total[id] ? (total[id] += amount) : amount;
         return total;
      }, {});
};

export const calculateRaceTrackTotalBets = (commands: TCommonBet[]) => {
   return commands.reduce((total, { id, amount, type }) => {
      total[id] = {
         amount: total[id] ? (total[id].amount += amount) : amount,
         type,
      };
      return total;
   }, {});
};

export const extractRangeAndSpecialBets = (betsHistory: TBetType[]) => {
   return betsHistory.reduce((total: TBetType[], command) => {
      const isRangeBet = isRangeStraightBetType(command);
      const isSpecialBet = isSpecialBetType(command);

      if (isRangeBet || isSpecialBet) {
         total.push(...command.extractedCommand);
      } else {
         total.push(command);
      }
      return total;
   }, []);
};

export const getRaceTrackTotalBets = (
   betsHistory: TBetType[],
): Record<
   number,
   {
      amount: number;
      type: TRaceTrackTypeBets;
   }
> => {
   const getTotalBets = pipe(
      extractDoubleBet,
      extractRangeAndSpecialBets,
      sumBetsById,
      calculateRaceTrackTotalBets,
   );
   return getTotalBets(betsHistory);
};
