import { useGameState } from '@/common/state/gameState';

import { MaintenanceStartedEvent } from './schemes';

type MaintenanceStartEventOutput = (socket: MaintenanceStartedEvent) => void;

export const useMaintenanceStart = (): MaintenanceStartEventOutput => {
   const { handleSetMaintenanceMode } = useGameState();

   return () => {
      handleSetMaintenanceMode();
   };
};
