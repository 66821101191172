import { RTKQueryApi, SuccessResponse } from '../types';

import { IAutoplayConfig, IAutoplayDTO } from './types';

export const autoplayApi = (apiSlice: RTKQueryApi) =>
   apiSlice.injectEndpoints({
      endpoints: (build) => ({
         startAutoplay: build.mutation<SuccessResponse<IAutoplayDTO>, IAutoplayConfig>({
            query: (config) => {
               return {
                  url: '/autoplay',
                  method: 'POST',
                  body: config,
               };
            },
         }),
         cancelAutoplay: build.mutation<SuccessResponse<IAutoplayDTO>, { autoplayId: string }>({
            query: ({ autoplayId }) => ({
               url: '/autoplay/cancel',
               method: 'POST',
               body: {
                  autoplayId,
               },
            }),
         }),
      }),
   });
