import { MouseEvent, useRef } from 'react';

import { TNullable } from '@/common/types';

import { getCellsIdFromDataAttrs } from '#/modules/Track/BettingMap/helpers';
import { BettingMapRefType } from '#/modules/Track/BettingMap/hooks/useBettingMapRef/types';

const HIGHLIGHT_CLASSNAME = 'highlight';

type Event = MouseEvent<HTMLElement>;
export const useHoverBet = (node: BettingMapRefType): Record<string, (event: Event) => void> => {
   const cellsListRef = useRef<TNullable<Element>[]>([]);

   const onMouseOver = (event: Event): void => {
      if (node) {
         const eventTarget = event.target as HTMLElement;

         const highlightCellsIdx = getCellsIdFromDataAttrs(eventTarget, 'highlightCells');
         const getCell = (id: string) => node.querySelector(`.cell_${id}`);
         const addClassList = (elem: Element) => elem.classList.add(HIGHLIGHT_CLASSNAME);

         cellsListRef.current = highlightCellsIdx.map(getCell);
         cellsListRef.current.forEach(addClassList);
      }
   };

   const onMouseOut = (): void => {
      const removeClasslist = (elem: HTMLElement): void => {
         elem.classList?.remove(HIGHLIGHT_CLASSNAME);
      };

      cellsListRef.current.forEach(removeClasslist);
      cellsListRef.current.length = 0;
   };

   return {
      onMouseOver,
      onMouseOut,
   };
};
