import { BetConfig } from 'core/config/BetConfig';
import { rouletteBlackColorNumbers } from 'core/roulette/colors';

import { ETypeBet } from '#/state/features/bets';

class BlackBetConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.BLACK;
   readonly config = [
      {
         id: 43,
         value: 'bettingMap.black',
         numbers: rouletteBlackColorNumbers,
      },
   ];
}

export const blackConfig = new BlackBetConfig();
