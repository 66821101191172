// @ts-nocheck
import { FC, useEffect, useRef } from 'react';

import { dolbyController } from '@/common/modules/LiveStreamPlayer/Dolby/DolbyController';
import { useConnectVideoElement } from '@/common/modules/LiveStreamPlayer/hooks';
import { LiveStreamElement } from '@/common/modules/LiveStreamPlayer/components';
import { EStreamingUpdateMode } from '@/common/modules/LiveStreamPlayer/constants';

import { useStreamVisibility } from '../hooks';

const useConnectLiveStreamToVideo = ({
   streamId,
   videoElement,
   audioElement,
   qualityMode,
   onQualityChange,
}) => {
   useEffect(() => {
      if (videoElement && streamId) {
         dolbyController.initializeConnection({
            videoElement,
            audioElement,
            streamName: streamId, // "plmx-szrol-ge-00-01-hooligan-main" / streamId
            streamAccountId: import.meta.env.VITE_ACCOUNT_ID,
         });

         dolbyController.connectToVideo();
         dolbyController.connectToAudio();

         dolbyController.subscribeToLiveStreamQualityEvents(() => {
            // determine the current quality setting based on the resolution width of the video element.
            const quality = dolbyController.qualitySizes[videoElement.videoWidth];
            // we ignore quality change during video runtime if quality mode is manual
            // to prevent unexpected quality changes
            if (quality && qualityMode !== EStreamingUpdateMode.Manual) {
               onQualityChange(quality);
            }
         });

         dolbyController.useAutoLiveStreamReconnect();
      }
   }, [videoElement, streamId]);
};

interface IDolbyProps {
   muted?: boolean;
   onPause: () => void;
   onPlaying: (event) => void;
   onQualityChange: (event) => void;
   quality: number;
   qualityMode: EStreamingUpdateMode;
   streamId: string;
}
export const DolbyPlayer: FC<IDolbyProps> = ({
   muted,
   streamId,
   onPause,
   onPlaying,
   quality,
   qualityMode,
   onQualityChange,
}) => {
   const audioElement = useRef(null);
   const { videoElement, onVideoElementChange } = useConnectVideoElement();

   useConnectLiveStreamToVideo({
      streamId,
      videoElement,
      onQualityChange,
      qualityMode,
      audioElement: audioElement.current,
   });

   // handle stream when close app or change tab
   useStreamVisibility(dolbyController);

   useEffect(() => {
      // update video quality mode and set it to manual mode
      if (dolbyController) {
         dolbyController.setQuality(quality);
      }
   }, [quality]);

   useEffect(() => {
      // update video quality mode to auto
      if (dolbyController && qualityMode === EStreamingUpdateMode.Auto) {
         dolbyController.setAutoQuality();
      }
   }, [qualityMode]);

   return (
      <>
         <LiveStreamElement ref={onVideoElementChange} onPause={onPause} onPlaying={onPlaying} />
         <audio ref={audioElement} muted={muted} id="audio" />
      </>
   );
};

DolbyPlayer.displayName = 'DolbyPlayer';
