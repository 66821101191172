// @ts-nocheck
// reference to documentation react-datepicker -> https://reactdatepicker.com/
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components/macro';

import { theme } from '@/common/ui/theme';
import { isHoverSupports } from '@/common/ui/theme/mixins';

export const DatePickerInputButton = styled.div`
   --switch-month-arrow-border-width: 2px;
   --switch-month-arrow-size: 7px;
   --switch-month-arrow-side-indent: 30px;

   --container-border-color: #a1a1a1;

   --month-name-color: ${theme.colors?.white};
   --month-container-bg: #000000;

   --day-number-color: ${theme.colors?.white};
   --hover-day-number-color: ${theme.colors?.main};

   --day-name-color: ${theme.colors?.white};
   --day-name-bg: #323741;

   --outside-month-day-color: #656a78;

   --selected-day-number-color: rgba(255, 185, 79, 0.5);

   .react-datepicker-wrapper {
      width: 100%;
   }

   /* reset default styles */
   .react-datepicker {
      background-color: unset;
      border-radius: unset;
   }

   .react-datepicker__month {
      margin: 0;
      padding-top: 8px;
   }

   .react-datepicker__header {
      border-bottom: unset;
      background: unset;
      padding: 0;
   }

   /* add custom styles */
   /* main wrapper */
   .react-datepicker {
      border: 2px solid var(--container-border-color);
      margin-top: 10px;
   }

   /* navigation */
   .react-datepicker__navigation {
      top: 25px;
   }

   .react-datepicker__navigation-icon::before {
      border-width: var(--switch-month-arrow-border-width) var(--switch-month-arrow-border-width) 0
         0;
      width: var(--switch-month-arrow-size);
      height: var(--switch-month-arrow-size);
   }

   .react-datepicker__navigation--previous {
      left: var(--switch-month-arrow-side-indent);
   }

   .react-datepicker__navigation--next {
      right: var(--switch-month-arrow-side-indent);
   }

   /* month container */
   .react-datepicker__month-container {
      background-color: var(--month-container-bg);
      padding: 25px 15px 0 15px;
      width: 100%;
   }

   /* children container */
   .react-datepicker__children-container {
      width: unset;
      margin: 0;
      padding: 0;
   }

   /* month title */
   .react-datepicker__current-month {
      color: var(--month-name-color);
      font-size: 20px;
   }

   /* day names container */
   .react-datepicker__day-names {
      padding-top: 20px;
   }

   .react-datepicker__day-name,
   .react-datepicker__day {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 4px;
      aspect-ratio: 1/1;
      width: calc((100% / 7) - (4px * 2));
      font-size: 20px;
      font-weight: 400;
   }

   /* day name */
   .react-datepicker__day-name {
      background-color: var(--day-name-bg);
      color: var(--day-name-color);
      font-size: 16px;
   }

   /* day number */
   .react-datepicker__day {
      border-radius: 0;
      border: 2px solid transparent;
      color: var(--day-number-color);

      &--keyboard-selected {
         background-color: unset;
      }

      ${isHoverSupports`
        &:hover {
           border-color: var(--hover-day-number-color);
           background-color: transparent;
           border-radius: 0;
        }
      `}

      &.react-datepicker__day--selected {
         background-color: var(--selected-day-number-color);
         border-color: transparent;
      }

      &.react-datepicker__day--outside-month {
         color: var(--outside-month-day-color);
      }

      &.react-datepicker__day--in-selecting-range {
         border-color: transparent;
         background-color: var(--selected-day-number-color);
         color: var(--day-number-color);
      }

      &.react-datepicker__day--in-range,
      &.react-datepicker__day--in-range.react-datepicker__day--outside-month {
         background-color: var(--selected-day-number-color);
         color: var(--day-number-color);

         ${isHoverSupports`
            &:hover {
               border-color: var(--hover-day-number-color);
            }
         `}
      }

      /* prevent outline */
      &.react-datepicker__day,
      &.react-datepicker__day--in-range,
      &.react-datepicker__day--keyboard-selected,
      &.react-datepicker__day--in-selecting-range {
         &:focus-visible {
            outline: none;
         }
      }
   }
`;
