import { FC } from 'react';

import { useAutoplay } from '#/modules/Autoplay/hooks';
import { translation } from '#/translates';

import * as S from '../../Autoplay.styles';

export const StartAutoplayButton: FC<{ onStartSuccessful: () => void }> = ({
   onStartSuccessful,
}) => {
   const { autoplay } = useAutoplay();

   const handleOnClick = async () => {
      await autoplay.startAutoplay();
      onStartSuccessful();
   };

   return (
      <S.AutoplayButton
         data-testid="start-autoplay-button"
         disabled={autoplay.isAutoplayDisabled() || autoplay.isLoading()}
         onClick={handleOnClick}
      >
         {translation('sidebars.autoplay.start')}
      </S.AutoplayButton>
   );
};
