import { BaseQueryApi, FetchArgs, createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../baseQuery';

const clientAPI = async (
   args: FetchArgs,
   { getState }: BaseQueryApi,
   extraOptions: { retry?: boolean },
) => {
   const config = {
      domain: import.meta.env.VITE_EXTERNAL_API_URL,
   };

   const headers = {
      'Content-Type': 'application/json',
      token: `Basic ${import.meta.env.VITE_BASIC_TOKEN}`,
   };

   const { session, user } = getState()?.auth ?? {};

   if (session?.id && session?.secret) {
      const credentials = `${session.id}:${session.secret}`;
      headers['Authorization'] = `Basic ${btoa(credentials)}`;
   }

   if (user?.token) {
      headers['AUTH-TOKEN'] = user.token;
   }

   return baseQuery(args, { headers, config, extraOptions });
};

export const clientApiSlice = createApi({
   baseQuery: clientAPI,
   reducerPath: 'clientAPI',
   endpoints: () => ({}),
});
