// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const TableCapacityWrapper = styled.div`
   --progress-width: 128px;
   --progress-height: 15px;

   display: flex;
   flex-shrink: 0;
   width: var(--progress-width);
   height: var(--progress-height);

   ${orientation.portrait`
        --progress-width: 62px;
        --progress-height: 8px;
    `}

   ${orientation.mobileLandscape`
        --progress-width: 62px;
        --progress-height: 8px;
    `}
`;
