import { ElementRef, forwardRef } from 'react';

import { IIHorizontalCountDownProps } from '../../HorizontalCountDown';

import * as S from './HorizontalCountDownProgressBar.styles';

export const HorizontalCountDownProgressBar = forwardRef<
   ElementRef<'div'>,
   Pick<IIHorizontalCountDownProps, 'tick'>
>(({ tick }, ref) => {
   return (
      <S.HorizontalCountDownProgressBarContainer tick={tick}>
         <S.HorizontalCountDownProgressBar
            tick={tick}
            ref={ref}
            data-testid="horizontal-timer-progress"
         />
      </S.HorizontalCountDownProgressBarContainer>
   );
});
