import { EModalNotificationType } from '@/common/ui/ModalNotification/constants';

import { useTranslation } from '#/translates';
import { Overlay } from '#/modules/LayoutOverlay/components/Overlay';
import { useLobbyRedirect } from '#/modules/LayoutOverlay/hooks';

import * as S from './KickInactiveGameTablePlayer.styles';

export const KickInactiveGameTablePlayer = () => {
   const { t } = useTranslation();
   const handleLobbyRedirect = useLobbyRedirect();

   return (
      <>
         <S.OverlayBlur></S.OverlayBlur>
         <Overlay
            withBackdrop={false}
            type={EModalNotificationType.Warning}
            title={t('sessionClosedInactivePlayer.title')}
            actionButton={{
               onClick: handleLobbyRedirect,
               title: t('sessionClosedInactivePlayer.action'),
            }}
         />
      </>
   );
};
