import { useEffect, useRef, useState } from 'react';

import { TNullable } from '@/common/types';

import { CloseModal } from '#/components/Modal';
import { translation } from '#/translates';

import * as S from './LimitInput.styles';

interface ILimitInput {
   currencySign: string;
   handleChange: (e) => void;
   handleClose: (value) => void;
   label: string;
   limit: string;
   placeholder?: string;
   testId: string;
}

export const LimitInput = (props: ILimitInput) => {
   const currencyRef = useRef<TNullable<HTMLSpanElement>>(null);
   const [isFocused, setIsFocused] = useState(false);

   const [valueLeftPadding, setValueLeftPadding] = useState(0);

   const calculatePaddingLeftDependOnCurrency = () => {
      const currencyIconElement = currencyRef.current;
      let pseudoElementStyles;
      if (currencyIconElement) {
         pseudoElementStyles = window.getComputedStyle(currencyIconElement, '::before');
         return parseFloat(pseudoElementStyles.width);
      }
      return pseudoElementStyles;
   };

   useEffect(() => {
      setValueLeftPadding(calculatePaddingLeftDependOnCurrency());
   }, []);

   const {
      label,
      limit,
      currencySign,
      placeholder = '',
      testId,
      handleChange,
      handleClose,
   } = props;

   return (
      <>
         <S.LimitHeader>
            <S.LimitLabel>{translation(label)} :</S.LimitLabel>
         </S.LimitHeader>
         <S.LimitControlsWrapper>
            <S.LimitInput
               isFocused={isFocused}
               type="text"
               value={limit}
               placeholder={translation(placeholder)}
               onChange={(e) => {
                  handleChange(e);
                  setIsFocused(true);
               }}
               valueLeftPadding={valueLeftPadding}
               data-testid={`${testId}-input`}
            />
            {<S.CurrencyIcon ref={currencyRef} currency={currencySign} />}

            {Boolean(limit) && (
               <S.CloseIconWrapper data-testid="autoplay-clear-loss-limit-control">
                  <CloseModal onClose={handleClose} />
               </S.CloseIconWrapper>
            )}
         </S.LimitControlsWrapper>
      </>
   );
};
