// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const BottomColumnContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 5px;
`;

export const TopRow = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;

   ${orientation.portrait`
      gap: 5px;
   `}
`;

export const BottomRow = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;

   & > :nth-child(2) {
      width: 100px;
   }
`;
