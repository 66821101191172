import { betsCreator } from '#/core/betsCreator';
import { useAutoplay } from '#/modules/Autoplay/hooks';

import { useRebetState } from '../state';

export const useRebetButtonVisibility = (): boolean => {
   const { useAvailableRebet } = useRebetState();
   const whenHasBetsFromPreviousRound = useAvailableRebet();
   const { autoplay } = useAutoplay();
   const isAutoplayStopped = !autoplay.isAutoplayStarted();
   const areBetsNotPlaced = !betsCreator?.hasPlacedBets?.();
   const isRebetButtonShown = areBetsNotPlaced && whenHasBetsFromPreviousRound && isAutoplayStopped;

   return isRebetButtonShown;
};
