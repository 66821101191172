import { useMobileLandscapeScale } from '#/hooks/useScale';
import { Notifications, SwipeUp } from '#/modules';
import { Announcements } from '#/modules/Announcements/Announcements';
import { useGameType } from '#/services/api/gameConfigApiSlice/state/gameConfig';

import { useLoadingGrid } from '../hooks/useLoadingGrid';

import { BottomColumn, CenterColumn, LeftColumn, RightColumn } from './components';
import * as S from './LandscapeGrid.styles';

export const LandscapeMobile = () => {
   const { isAutoRoulette } = useGameType();
   const isLayoutGridLoaded = useLoadingGrid();
   useMobileLandscapeScale({ isLayoutGridLoaded });

   if (!isLayoutGridLoaded) {
      return <S.GridContainer className="video-container loading" />;
   }

   return (
      <>
         <SwipeUp />
         <S.GridContainer className="video-container">
            {isAutoRoulette ? <Announcements /> : null}
            <Notifications />
            <S.LeftColumn>
               <LeftColumn />
            </S.LeftColumn>
            <S.CenterColumn>
               <CenterColumn />
            </S.CenterColumn>
            <S.RightColumn>
               <RightColumn />
            </S.RightColumn>
            <S.BottomColumn>
               <BottomColumn />
            </S.BottomColumn>
         </S.GridContainer>
      </>
   );
};
