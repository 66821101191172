// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const MessageContainer = styled.span`
   --chat-fz: 16px;
   --str-chat__font-family: 'Montserrat', sans-serif;

   display: block;
   font-size: 16px;
   will-change: transform;
   background-color: rgba(36, 36, 36, 0.8);
   width: max-content;
   padding: 5px 10px;

   &:last-of-type {
      margin-bottom: 0;
      width: fit-content;
   }

   ${orientation.portrait`
      --chat-fz: 14px;
      padding-left: 15px;
   `}

   ${orientation.mobileLandscape`
      --chat-fz: 14px;
   `}
`;

export const Username = styled.div`
   display: inline-block;
   color: ${({ color }) => color};
   font-weight: 600;
   margin-right: 10px;
   margin-bottom: 2px;
`;

export const UsernameWrapper = styled.div`
   display: flex;
   align-items: center;
   color: #fff;

   --reply-icon-opacity: 0;

   .reply-icon {
      opacity: var(--reply-icon-opacity);
      cursor: pointer;
      transition: opacity 0.2s ease-in-out;
   }

   &:hover .reply-icon {
      --reply-icon-opacity: 1;
   }

   ${orientation.portrait`
      --reply-icon-opacity: 1;
   `}

   ${orientation.mobileLandscape`
      --reply-icon-opacity: 1;
   `}
`;

export const Message = styled.div`
   color: ${({ color }) => color};
   font-family: 'Montserrat', sans-serif;
   word-wrap: break-word;
   max-width: 480px;
`;
