// @ts-nocheck

import { nanoid } from '@reduxjs/toolkit';
import {
   DetailedHTMLProps,
   FC,
   HTMLAttributes,
   MutableRefObject,
   ReactElement,
   ReactNode,
   cloneElement,
   memo,
   useEffect,
   useRef,
} from 'react';
import { TSetTimeout } from 'types';

import { TNullable } from '@/common/types';
import { Portal } from '@/common/ui/Portal';

import { SHOW_TOOLTIP_DURATION } from '#/constants/animations';

import { useTooltipPortalActions, useTooltipPortalSelector } from './state';
import * as S from './TooltipPortal.styles';

const TOOLTIP_TOP_GAP = 5;

interface ITooltipPortalProps
   extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
   children: ReactElement<ReactNode>;
   showDelay?: number;
   suppressTooltip: boolean;
   text: string;
}

const _TooltipPortal: FC<ITooltipPortalProps> = ({
   text,
   children,
   showDelay = SHOW_TOOLTIP_DURATION,
   suppressTooltip = false,
}) => {
   const openTooltipId = useTooltipPortalSelector();

   const { handleUpdateTooltip } = useTooltipPortalActions();
   const tooltipID = useRef(nanoid());
   const timeoutRef: MutableRefObject<TNullable<TSetTimeout>> = useRef(null);
   const tooltipRef: MutableRefObject<TNullable<HTMLElement>> = useRef(null);
   const childrenRef: MutableRefObject<TNullable<HTMLDivElement>> = useRef(null);

   useEffect(() => {
      const timeout = timeoutRef.current;

      return () => {
         if (timeout) {
            clearTimeout(timeout);
         }
      };
   }, [openTooltipId]);

   const positioningTooltip = (): void => {
      if (tooltipRef.current && childrenRef.current) {
         const tooltipRect: DOMRect = tooltipRef.current?.getBoundingClientRect();
         const childrenRect: DOMRect = childrenRef.current?.getBoundingClientRect();

         const tooltipTopPosition = childrenRect.top - tooltipRect.height - TOOLTIP_TOP_GAP;
         const tooltipLeftPosition =
            childrenRect.left - (tooltipRect.width - childrenRect.width) / 2;

         tooltipRef.current.style.top = `${Math.max(0, tooltipTopPosition)}px`;
         tooltipRef.current.style.left = `${Math.max(0, tooltipLeftPosition)}px`;

         // checks if the tooltip doesn't clip over the right edge
         const whenTooltipClimbsOverRightEdge =
            tooltipRef.current.getBoundingClientRect().right > window.innerWidth;

         if (whenTooltipClimbsOverRightEdge) {
            tooltipRef.current.style.left = 'unset';
            tooltipRef.current.style.right = '0px';
         }
      }
   };

   const handleOpenTooltip = (): void => {
      const whenCurrentTooltipIsOpen = tooltipID.current === openTooltipId;
      const whenTimeoutNoFinish = timeoutRef.current;
      const startHideTimeout = () => {
         timeoutRef.current = setTimeout(() => {
            handleUpdateTooltip(null);
         }, showDelay);
      };

      if (whenCurrentTooltipIsOpen && whenTimeoutNoFinish) {
         if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
         }
         startHideTimeout();
         return;
      }

      handleUpdateTooltip(tooltipID.current);
      startHideTimeout();
   };

   const whenRenderTooltip = tooltipID.current === openTooltipId;

   return (
      <>
         {whenRenderTooltip && (
            <Portal onMount={positioningTooltip}>
               <S.PortalTooltip ref={tooltipRef} data-testid="tooltip">
                  <div>{text}</div>
               </S.PortalTooltip>
            </Portal>
         )}

         {cloneElement(children, {
            ref: childrenRef,
            onPointerUp: (...args: unknown[]): void => {
               if (children.props.handleAction) {
                  suppressTooltip ? children.props.handleAction(args) : handleOpenTooltip();
               }
            },
         })}
      </>
   );
};

export const TooltipPortal = memo(_TooltipPortal);
