import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface IControlProps {
   children: ReactNode;
   variant?: string;
}

export const Control = styled.button<IControlProps>`
   width: ${({ size }) => size};
   height: ${({ size }) => size};
   background: none;
   outline: none;
   cursor: pointer;
`;
