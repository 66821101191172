import { ECellColor, rouletteColorGetter } from 'core/roulette';

const getColor = rouletteColorGetter({
   [ECellColor.Green]: '#23b04e',
   [ECellColor.Red]: '#f00000',
   [ECellColor.Black]: '#282828',
});

export const getWinNumberColors = ([current, previousNumber, nextNumber]): [
   string,
   string,
   string,
] => {
   const [currentColor, leftColorsStart, rightColorsStart] = [
      current,
      previousNumber,
      nextNumber,
   ].map((index: number) => getColor(String(index)));

   return [currentColor, leftColorsStart, rightColorsStart];
};
