import { Suspense, lazy } from 'react';

import * as S from './History.styles';

const HistoryContainer = lazy(() => import('./components/HistoryContainer'));

export const History = () => (
   <S.HistoryWrapper>
      <Suspense fallback={null}>
         <HistoryContainer />
      </Suspense>
   </S.HistoryWrapper>
);
