import { FC } from 'react';

import { BetControl, EBetControl } from '@/common/ui';
import { IPlayChipSound } from '@/common/entities';

import { betsCreator } from '#/core/betsCreator';
import { useTranslation } from '#/translates';
import { withChipSound } from '#/hocs/withChipSound';

const RebetControl: FC<{ isVisible: boolean } & IPlayChipSound> = ({
   isVisible,
   playChipSound,
}) => {
   const { t } = useTranslation();

   return (
      <BetControl
         withPulseAnimation
         dataTestId="rebet-control"
         title={t('betControls.rebet')}
         color="#29D23A"
         variant={EBetControl.Rebet}
         onClick={betsCreator.rebetBets}
         disabled={!isVisible}
         playChipSound={playChipSound}
      />
   );
};

export const RebetControlWithChipSound = withChipSound(RebetControl);
