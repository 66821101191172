import { roulettePocketsList } from 'core/roulette';

import { TBetType } from '#/state/features/bets';
import {
   isCallBetType,
   isDoubleBetType,
   isRangeStraightBetType,
   isSpecialBetType,
} from '#/state/features/bets/type-guards';

class TableCapacityCalculator {
   // TODO: FIX
   // eslint-disable-next-line no-useless-constructor
   constructor(private rouletteTableSize: number) {}

   calculateCapacity = (bets: TBetType[]) => {
      const numbersSet = new Set<number>();

      bets?.forEach((bet) =>
         this.processBet({ numbersSet, bet, rouletteTableSize: this.rouletteTableSize }),
      );

      const filteredNumbersCount = numbersSet.size;
      return this.calculatePercentage(filteredNumbersCount, this.rouletteTableSize);
   };

   private processBet = ({
      numbersSet,
      bet,
      rouletteTableSize,
   }: {
      numbersSet: Set<number>;
      bet: TBetType;
      rouletteTableSize: number;
   }) => {
      if (isSpecialBetType(bet) || isCallBetType(bet) || isRangeStraightBetType(bet)) {
         bet.extractedCommand.forEach((betItem) => {
            betItem.numbers.forEach((number) => numbersSet.add(number));
         });
      } else if (isDoubleBetType(bet)) {
         this.processDoubleBet(numbersSet, bet.partialTotalBets, rouletteTableSize);
      } else {
         bet.numbers.forEach((number) => numbersSet.add(number));
      }
   };

   private processDoubleBet = (
      numbersSet: Set<number>,
      partialTotalBets: Record<string, number>,
      rouletteTableSize: number,
   ) => {
      Object.keys(partialTotalBets)
         .map(Number)
         .filter((num) => num < rouletteTableSize)
         .forEach((num) => numbersSet.add(num));
   };

   private calculatePercentage = (count: number, total: number) => {
      return Math.round((count * 100) / total);
   };
}

const rouletteTableSize = roulettePocketsList.length;
export const tableCapacityCalculator = new TableCapacityCalculator(rouletteTableSize);
