import React from 'react';

export const SectionBorders = () => (
   <svg style={{ pointerEvents: 'none' }}>
      <path
         stroke="#fff"
         d="M169.9 169.4 184.3 0m-14.4 169.4L212.7 5m-42.8 164.5 70-154.9m-70 155 95.2-141m-95.2 141 117.6-123M169.9 169.4l136.6-101M170 169.3 321.6 93M170 169.4l162.5-49.8M170 169.4l168.6-21.6M170 169.4l169.8 7.2M170 169.4l166 35.8m-166-35.8 157.7 63.4M170 169.4l144.7 89.1m-144.7-89 127.6 112.2M169.9 169.4l106.7 132.3M170 169.4l82.9 148.4m-83-148.4 56.7 160.3M170 169.4 198.6 337m-28.7-167.6v170m0-170L141 337m29-167.6-56.7 160.3M170 169.4 87 317.8m83-148.4L63 301.7m106.8-132.3L42.2 281.7M170 169.4 25 258.5m144.8-89L12 232.7m158-63.3L3.7 205.2m166.2-35.8L0 176.6m169.9-7.2L1.2 147.8M170 169.4 7.3 119.6M170 169.4 18 93m151.8 76.5L33.2 68.4m136.7 101L52.2 46.7M170 169.4 74.7 28.6m95.2 140.8-70-154.9m70 155L127 4.8m43 164.6L155.4 0"
      />
      <path
         fill="#fff"
         d="M339.5 170c0 93.6-75.9 169.5-169.5 169.5v1c94.2 0 170.5-76.3 170.5-170.5h-1ZM170 .5C263.6.5 339.5 76.4 339.5 170h1C340.5 75.8 264.2-.5 170-.5v1ZM.5 170C.5 76.4 76.4.5 170 .5v-1A170.5 170.5 0 0 0-.5 170h1ZM170 339.5A169.5 169.5 0 0 1 .5 170h-1c0 94.2 76.3 170.5 170.5 170.5v-1ZM311.5 170c0 78.1-63.4 141.5-141.5 141.5v1c78.7 0 142.5-63.8 142.5-142.5h-1ZM170 28.5c78.1 0 141.5 63.4 141.5 141.5h1c0-78.7-63.8-142.5-142.5-142.5v1ZM28.5 170C28.5 91.9 91.9 28.5 170 28.5v-1A142.5 142.5 0 0 0 27.5 170h1ZM170 311.5A141.5 141.5 0 0 1 28.5 170h-1c0 78.7 63.8 142.5 142.5 142.5v-1Z"
      />
   </svg>
);
