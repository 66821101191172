// @ts-nocheck
import styled from 'styled-components/macro';

export const Card = styled.div`
   display: flex;
   align-items: ${({ isBottom }) => (isBottom ? 'bottom' : 'top')};
   justify-content: space-between;
   flex-direction: column;
   flex-grow: 1;
   padding: 0;
   background: ${({ theme }) => theme.colors?.white};
   width: ${({ size }) => size};
   border-radius: 3px;
   box-sizing: border-box;
   box-shadow: 0 5px 34px 0 ${({ theme }) => theme.colors.lightBlack};
`;
