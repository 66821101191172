import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { getTotalBetsAmount } from '#/core/bets/utils';
import { TRootState, Store } from '#/state/types';

import { useDigitalTableActions } from '../actions';
import { EPlaceTrack } from '../constants';
import { getRaceTrackTotalBets } from '../helpers';
import { TBetType, TTotalBets } from '../types';

export const betsState = (store: Store) => {
   const getBetsStore = () => store?.getState().bets;

   return {
      zIndexes: () => getBetsStore().zIndexes,
      totalBets: () => getBetsStore().totalBets,
      betsHistory: () => getBetsStore().betsHistory,
   };
};

const useTotalBetsAmountSelector = (): number => {
   return useSelector(createSelector(({ bets }: TRootState) => bets.totalBets, getTotalBetsAmount));
};

const useBetsHistorySelector = () => {
   return useSelector(({ bets }: TRootState) => bets?.betsHistory);
};

const useBetByCellIdSelector = (id: number): number => {
   return useSelector(
      createSelector(
         ({ bets }: TRootState) => bets.totalBets,
         (bets) => bets[id],
      ),
   );
};

const useEmptyBetsSelector = (): boolean => {
   return useSelector(
      createSelector(
         ({ bets }: TRootState) => bets?.betsHistory,
         (commands: TBetType[]) => commands.length === 0,
      ),
   );
};

const useTotalBetsSelector = (): TTotalBets => {
   return useSelector(
      createSelector(
         ({ bets }: TRootState) => bets.totalBets,
         (totalBets) => totalBets,
      ),
   );
};

const useRaceTrackBetsSelector = () => {
   return useSelector(
      createSelector(
         ({ bets }: TRootState) => bets?.betsHistory,
         (commands: TBetType[]) => getRaceTrackTotalBets(commands),
      ),
   );
};

const useZIndex = (id: number): number => {
   return useSelector(
      createSelector(
         ({ bets }: TRootState) => bets.zIndexes,
         (zIndexes) => zIndexes[id],
      ),
   );
};

const useDigitalTableSelectors = () => {
   return {
      useZIndex,
      useTotalBetsSelector,
      useBetByCellIdSelector,
      useBetsHistorySelector,
      useRaceTrackBetsSelector,
      useEmptyBetsSelector,
      useTotalBetsAmountSelector,
   };
};

export const useBetsState = () => {
   return {
      placeTrack: EPlaceTrack.MainTrack,
      ...useDigitalTableActions(),
      ...useDigitalTableSelectors(),
   };
};
