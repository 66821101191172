export const enum EGameTypes {
   SingleZeroRoulette = 'szrol',
   AutoRoulette = 'slngsht',
   Smcp = 'smcp',
   Roda = 'roda',
   BlackJack = 'bjl',
}

export const enum EGameTypesName {
   SingleZeroRoulette = 'Roulette',
   AutoRoulette = 'AutoRoulette',
   BlackJack = 'BlackJack',
}
