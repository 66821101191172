import { pipe } from '@/common/utils';

import { TLimits } from '#/modules/TableCapacity';
import { TBetType } from '#/state/features/bets';

import {
   extractCallBet,
   extractDoubleBet,
   extractSpecialBet,
   sortBetsByMinLimit,
   sumBetsById,
} from '../../../helpers';

export const sumSendingBets = ({ bets, limits }: { bets: TBetType[]; limits: TLimits }) => {
   const processingBets = pipe(extractDoubleBet, extractSpecialBet, extractCallBet, sumBetsById);

   return sortBetsByMinLimit({
      bets: processingBets(bets),
      limits,
   });
};
