import { TNullable } from '@/common/types';
import { VolumeTransformer } from '@/common/modules/RangeSlider/helpers';

import { DolbyController } from '../../LiveStreamPlayer/Dolby/DolbyController';

import { SoundVolumeAndRangeController } from './SoundVolumeAndRangeController';

export class GeneralSoundControl {
   public generalSound: SoundVolumeAndRangeController;
   private dependentSounds: SoundVolumeAndRangeController[];
   private dolbyController: DolbyController;
   private isGeneralVolumeSet: boolean;
   private dealerSound: SoundVolumeAndRangeController;

   constructor({ dependentSounds, generalSound, dolbyController }) {
      this.generalSound = generalSound;
      this.dependentSounds = dependentSounds;
      this.dealerSound = dependentSounds[1];
      this.dolbyController = dolbyController;
      this.isGeneralVolumeSet = false;
   }

   calculateDependantSound = ({
      sound,
      newMaxVolumeValue,
      oldMaxVolumeValue,
   }: {
      sound: SoundVolumeAndRangeController;
      newMaxVolumeValue: number;
      oldMaxVolumeValue: number;
   }): number => {
      const isUnmuteAction = oldMaxVolumeValue === 0;
      return isUnmuteAction
         ? VolumeTransformer.toVolume(sound.currentVolumeRange) * newMaxVolumeValue
         : Number(((sound.currentVolume * newMaxVolumeValue) / oldMaxVolumeValue).toFixed(5));
   };

   setDefaultSoundValues = (generalVolume: number): void => {
      this.dependentSounds.map((sound) => {
         sound.currentVolume = generalVolume * sound.defaultSoundVolumeValue;
         sound.previousVolume = generalVolume * sound.defaultSoundVolumeValue;
         sound.previousRange = VolumeTransformer.toRange(sound.defaultSoundVolumeValue);
         sound.currentRange = VolumeTransformer.toRange(sound.defaultSoundVolumeValue);
      });

      this.generalSound.currentVolume = generalVolume;
      this.generalSound.previousVolume = generalVolume;

      this.setGeneralVolume();
   };

   switchVolume = (): void => {
      const isMuted = this.isMuted();
      const audioStream: TNullable<HTMLAudioElement> = document.querySelector('#audio');

      if (audioStream) {
         audioStream.muted = isMuted;
         audioStream.play().catch(() => null);
      }
   };

   setPreviousVolume = (): void => {
      this.dependentSounds.map((sound) => {
         sound.previousVolume = sound.currentVolume;
      });
   };

   setNewVolume = ({ newMaxVolumeValue, oldMaxVolumeValue }): void => {
      this.dependentSounds.map((sound) => {
         sound.currentVolume = this.calculateDependantSound({
            sound,
            newMaxVolumeValue,
            oldMaxVolumeValue,
         });
      });
   };

   handleSliderRangeChanges = (range: number): void => {
      if (!this.isGeneralSoundSet()) {
         this.setDefaultSoundValues(VolumeTransformer.toVolume(range));
      }

      const isUnmuteAction = range !== 0;

      const newMaxGeneralSoundValue = VolumeTransformer.toVolume(range);
      const oldMaxGeneralSoundValue = this.generalSound.currentVolume;

      this.setPreviousVolume();

      this.generalSound.previousVolume = this.generalSound.currentVolume;
      this.generalSound.currentVolume = newMaxGeneralSoundValue;

      this.setNewVolume({
         newMaxVolumeValue: newMaxGeneralSoundValue,
         oldMaxVolumeValue: oldMaxGeneralSoundValue,
      });

      if (isUnmuteAction) {
         // when user unmute the game sounds we set the previous volume
         this.generalSound.previousVolume = newMaxGeneralSoundValue;
      }
      // debugger;
      this.dolbyController.setVolume(this.dealerSound.currentSoundVolume);
      this.switchVolume();
   };

   toggleMute = (): void => {
      const DEFAULT_SOUND_VALUE = 0.5;

      if (!this.isGeneralSoundSet()) {
         this.setDefaultSoundValues(DEFAULT_SOUND_VALUE);
      } else {
         this.handleSliderRangeChanges(
            this.isMuted() ? VolumeTransformer.toRange(this.generalSound.previousVolume) : 0,
         );
      }

      this.switchVolume();
   };

   setGeneralVolume = (): void => {
      this.isGeneralVolumeSet = true;
   };

   isGeneralSoundSet = (): boolean => {
      return this.isGeneralVolumeSet;
   };

   isMuted = (): boolean => {
      return this.generalSound.currentVolume === 0;
   };
}
