// @ts-nocheck
import styled from 'styled-components';

export const LeftColumn = styled.div`
   grid-area: left-column;
`;

export const TopColumn = styled.div`
   grid-area: top-column;
`;

export const CenterColumn = styled.div`
   grid-area: center-column;
`;

export const RightColumn = styled.div`
   grid-area: right-column;
`;

export const BottomColumn = styled.div`
   grid-area: bottom-column;
`;

export const GridContainer = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: grid;
   grid-template-areas:
      'top-column top-column top-column'
      'left-column   center-column right-column'
      'bottom-column bottom-column bottom-column';
   grid-template-columns: 15vw 1fr 15vw;
   grid-template-rows: auto 1fr auto;
   grid-gap: 10px 2px;

   padding: 5px;

   & .control {
      scale: 0.8;
   }

   &.loading {
      backdrop-filter: blur(10px);
   }
`;
