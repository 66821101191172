import { reverseObject } from './reverseObject';

const zeroTypes = {
   0: 'Zero',
};

export const winNumberDTO = (winNumber: number): number | string =>
   zeroTypes[winNumber] || winNumber;

export const winNumberReverseDTO = (winNumber: string): string =>
   reverseObject(zeroTypes)[winNumber] || winNumber;
