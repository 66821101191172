import { useEffect } from 'react';

import { useEvent } from './useEvent';

export const useDocumentEvent = <Type extends keyof DocumentEventMap>(
   type: Type,
   cb: (event: DocumentEventMap[Type]) => void,
   options?: AddEventListenerOptions,
): void => {
   const eventCb = useEvent(cb);

   useEffect(() => {
      document.addEventListener(type, eventCb, options);

      return () => document.removeEventListener(type, eventCb, options);
   }, [eventCb]);
};
