import { formatCurrency } from '#/helpers';

export const formatAmount = ({
   amount,
   currencySign,
}: {
   amount: number;
   currencySign: string;
}) => {
   const formatOptions = {
      isCompact: true,
   };

   return `${currencySign} ${formatCurrency(amount, formatOptions)}`;
};
