// @ts-nocheck
import styled from 'styled-components/macro';

import { SideControls } from '../../LandscapeGrid.styles';

export const LeftControlsContainer = styled(SideControls)`
   margin-right: auto;
   margin-top: auto;
   transform: translateX(-25px);
`;

export const LeftColumnContainer = styled.div`
   position: relative;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: ${({ isRoundResult }) => (isRoundResult ? 'space-between' : 'flex-end')};
`;

export const GameInfoContainer = styled.div`
   margin-top: 15px;
   margin-bottom: auto;
   display: flex;
   align-items: center;
   position: relative;
   left: -25px;
`;
