import {
   TColorBet,
   TColumnBet,
   TCornerBet,
   TDozenBet,
   THighLowBet,
   TLineBet,
   TParityBet,
   TSplitBet,
   TStraightBet,
   TStreetBet,
} from '@/common/services/api/betsAPI/types';
import { rangeNumbers } from '@/common/utils';

import {
   callBetsConfig,
   cornerConfig,
   dozenConfig,
   highConfig,
   lineConfig,
   lowConfig,
   splitConfig,
   straightConfig,
   streetConfig,
} from '#/core/config';
import { blackConfig } from '#/core/config/black';
import { columnConfig } from '#/core/config/column';
import { evenConfig } from '#/core/config/even';
import { oddConfig } from '#/core/config/odd';
import { redConfig } from '#/core/config/red';
import { CallBetCommand, Command } from '#/modules/Track/BettingMap/commands';
import { CallBetType } from '#/modules/Track/BettingMap/commands/CallBetCommand';
import { TCallBet, ETypeBet } from '#/state/features/bets';

import { transformZeroReverse } from '../adapters/helpers/transformZero';

class ReverseBetAdapter {
   straightBetAdapter = (bet: TStraightBet) => {
      const betNumber = transformZeroReverse(bet.params.number);
      const configByNumber = straightConfig.mapBetIdToBet?.[betNumber] ?? null;

      if (configByNumber) {
         const { id, numbers, type } = configByNumber;

         return new Command({
            id,
            numbers,
            amount: bet.amount,
            type,
         }).execute();
      }
   };

   splitBetAdapter = (bet: TSplitBet) => {
      const splitNumbers = bet.params.split.split('-').map(transformZeroReverse).map(Number);
      const configByNumber = splitConfig.config
         .flat()
         .find((bet) => bet.numbers.every((num) => splitNumbers.includes(num)));

      if (configByNumber) {
         const { id, numbers } = configByNumber;

         return new Command({
            id,
            numbers,
            amount: bet.amount,
            type: ETypeBet.SPLIT,
         }).execute();
      }
   };

   streetBetAdapter = (bet: TStreetBet) => {
      const [firstNumber, lastNumber] = bet.params.street
         .split('-')
         .map(transformZeroReverse)
         .map(Number);
      const numbers = rangeNumbers({ start: firstNumber, end: lastNumber });
      const configByNumber = streetConfig.config.find((bet) =>
         bet.numbers.every((num) => numbers.includes(num)),
      );

      if (configByNumber) {
         const { id, numbers } = configByNumber;

         return new Command({
            id,
            numbers,
            amount: bet.amount,
            type: ETypeBet.STREET,
         }).execute();
      }
   };

   parityBetAdapter = (bet: TParityBet) => {
      const isEvenBet = bet.params.parity === 'even';

      if (isEvenBet) {
         const { id, numbers } = evenConfig.config[0];

         return new Command({
            id,
            numbers,
            amount: bet.amount,
            type: ETypeBet.EVEN,
         }).execute();
      }

      const { id, numbers } = oddConfig.config[0];

      return new Command({
         id,
         numbers,
         amount: bet.amount,
         type: ETypeBet.ODD,
      }).execute();
   };

   lineBetAdapter = (bet: TLineBet) => {
      const [firstLineNumber] = bet.params.line.split('-').map(Number);
      const configByNumber = lineConfig.config.find((bet) => bet.numbers[0] === firstLineNumber);

      if (configByNumber) {
         const { id, numbers } = configByNumber;

         return new Command({
            id,
            numbers,
            amount: bet.amount,
            type: ETypeBet.LINE,
         }).execute();
      }
   };

   highLowBetAdapter = (bet: THighLowBet) => {
      const isHighBet = bet.params.highLow === 'high';

      if (isHighBet) {
         const { id, numbers } = highConfig.config[0];

         return new Command({
            id,
            numbers,
            amount: bet.amount,
            type: ETypeBet.HIGH,
         }).execute();
      }

      const { id, numbers } = lowConfig.config[0];

      return new Command({
         id,
         numbers,
         amount: bet.amount,
         type: ETypeBet.LOW,
      }).execute();
   };

   dozenBetAdapter = (bet: TDozenBet) => {
      const configByNumber = dozenConfig.mapDozenNumberToBet?.[bet.params.dozen] ?? null;

      if (configByNumber) {
         const { id, numbers, type } = configByNumber;

         return new Command({
            id,
            numbers,
            amount: bet.amount,
            type,
         }).execute();
      }
   };

   cornerBetAdapter = (bet: TCornerBet) => {
      const cornerNumbers = bet.params.corner.split('-').map(transformZeroReverse).map(Number);
      const configByNumber = cornerConfig.config.find((bet) =>
         bet.numbers.every((num) => cornerNumbers.includes(num)),
      );

      if (configByNumber) {
         const { id, numbers } = configByNumber;

         return new Command({
            id,
            numbers,
            amount: bet.amount,
            type: ETypeBet.CORNER,
         }).execute();
      }
   };

   columnBetAdapter = (bet: TColumnBet) => {
      const configByNumber = columnConfig.mapColumnNumberToBet?.[bet.params.column] ?? null;

      if (configByNumber) {
         const { id, numbers, type } = configByNumber;

         return new Command({
            id,
            numbers,
            amount: bet.amount,
            type,
         }).execute();
      }
   };

   colorBetAdapter = (bet: TColorBet) => {
      const isRedBet = bet.params.color === 'red';

      if (isRedBet) {
         const { id, numbers } = redConfig.config[0];

         return new Command({
            id,
            numbers,
            amount: bet.amount,
            type: ETypeBet.RED,
         }).execute();
      }

      const { id, numbers } = blackConfig.config[0];

      return new Command({
         id,
         numbers,
         amount: bet.amount,
         type: ETypeBet.BLACK,
      }).execute();
   };

   callBetAdapter = (bet: TCallBet) => {
      const getCallBetAmount = ({ amount, type }: { amount: number; type: CallBetType }) => {
         const countOfInternalBetsInCallBet = callBetsConfig[type].length;
         return amount / countOfInternalBetsInCallBet;
      };

      const callBetsMap = {
         jeuZero: ETypeBet.JEU_ZERO,
         orphelins: ETypeBet.ORPHELINS,
         tiers: ETypeBet.TIERS,
         voisin: ETypeBet.VOISIN,
      } as const;

      const type = callBetsMap[bet.type];

      return new CallBetCommand({
         type,
         amount: getCallBetAmount({ amount: bet.amount, type }),
      }).execute();
   };
}

export const reverseBetAdapter = new ReverseBetAdapter();
