// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { applyZIndex } from '@/common/ui/theme/mixins';

export const ChipWrapper = styled.div`
   z-index: ${({ zIndex }) => zIndex || 1};
   position: absolute;
   width: var(--chip-size);
   height: var(--chip-size);
   will-change: transform;

   &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50px;
      box-shadow: ${({ theme }) => `0.5px 0.5px 10px 10px ${theme.colors?.redHue['10']}`};
      opacity: 0;
   }

   svg {
      width: 100%;
      height: 100%;
      pointer-events: none;
   }
`;

export const ChipPlace = styled.div`
   ${applyZIndex('biggest')};
   position: absolute;
   width: var(--cell-size);
   height: var(--cell-size);
   background-color: rgba(0, 0, 0, 0);
   ${({ $fullSize }) =>
      $fullSize &&
      css`
         --cell-size: 100%;
      `}
`;
