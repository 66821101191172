import { en } from '../en';

export const tr = {
   translation: {
      ...en.translation,
      track: {
         raceTrack: 'Racetrack',
         bettingMap: 'Bahis haritası',
      },
      menu: {
         lobby: 'Lobi',
         history: 'Geçmiş',
         help: 'Nasıl oynanır?',
         settings: 'Ayarları',
      },
      gameId: 'ID',
      balance: 'Bakiye',
      bet: 'Bahis',
      sidebars: {
         menu: {
            title: 'Menü',
         },
         settings: {
            title: 'Ayarlar',
            volume: 'Ses Düzeyı',
            gameSounds: 'Oyun Sesleri',
            dealerSounds: 'Stüdyo Sesleri',
            dealerRate: 'Rate Game Presenter',
            videoQuality: 'Video Kalitesi',
            currentQuality: 'Şu anki kalite',
            lowQuality: 'Düşük',
            mediumQuality: 'Orta',
            highQuality: 'Yüksek',
            autoQuality: 'Otomatik',
         },
         help: {
            title: 'Nasıl oynanır?',
            search: {
               placeholder: 'Enter a text',
            },
            singleZeroRoulette: {
               gameOverview: {
                  title: 'Oyuna Genel Bakış',
                  content: [
                     {
                        text: 'Canlı Online Tek Sıfırlı Rulet, klasik casino oyununun, oyunculara sürükleyici ve etkileşimli bir deneyim sunan, heyecan verici sanal adaptasyonudur. Gerçek zamanlı olarak profesyonel krupiyeler tarafından yönetilen bu oyun oyunculara, evlerinin konforundan rulet heyecanınıyaşamaimkanısağlar. Oyun, tek sıfırlı ruletin standart kuralları ile oynanır. Gerçekçilik artar ve heyecan verici bir oyun deneyimi sunulur.',
                     },
                  ],
               },
               objective: {
                  title: 'Amaç',
                  content: [
                     {
                        text: 'Canlı Online Tek Sıfırlı Rulet oyununda amaç, rulet tekerinin dönüşünün sonucunu doğru tahmin etmektir. Oyuncular tekil sayılar,sayıgrupları, renkler veya tek/çift gibi çeşitli mevcut bahis seçeneklerine bahis koyarlar ve tahminleri ile sonuç aynı olursa kazanırlar.',
                     },
                  ],
               },
               gamePlayFeatures: {
                  title: 'Oyun Özellikleri',
                  content: [
                     {
                        text: 'Canlı Krupiyeler: Deneyimli ve samimi krupiyeler, oyunu oynatırken gerçekçi ve etkileşimli bir atmosfer yaratırlar. Tekeri döndürüp sonuçları duyururlar ve çekici bir oyun deneyimi sağlarlar.',
                     },
                     {
                        text: 'Gerçek Zamanlıyayın: Oyun, yüksek kalitede video ve ses ile gerçek zamanlı olarak yayınlanarakoyuncuların, oyunu her yönüyle net bir şekilde görmeleri sağlanır. Oyuncular, canlı sohbet özelliğiüzerinden krupiye ve diğer oyuncular ile etkileşime girebilirler.',
                     },
                     {
                        text: "Tek Sıfırlı Teker:Canlı Online Tek Sıfırlı Rulet, 0'dan 36'ya sayıları içeren Avrupa tarzı rulet tekeri kullanır. Bu tekerde bir adet yeşilsıfırlı cep vardır ve böylece oyunculara çift sıfırlı rulete oranla daha yüksek şans verir.",
                     },
                     {
                        text: 'Bahis Seçenekleri:Oyuncuların iç bahisler (tekil sayılar veya küçük gruplara koyulan bahisler) ve dışbahisler (renkler veya tek/çift gibi büyük gruplara koyulan bahisler) dahil çeşitli bahis seçenekleri mevcuttur. Oyun arayüzü, bahis koymak için sezgisel bir platform sunar.',
                     },
                     {
                        text: 'Bahis Limitleri: Her Canlı Online Tek Sıfırlı Rulet masasında önceden belirlenmiş minimum ve maksimum bahis sınırlarıvardır. Böylece oyuncular tercih ettikleri bahis aralığındaki bir masayıseçebilirler.',
                     },
                     {
                        text: 'İstatistik ve Geçmiş: Oyun, geçmişdönüşlerindetaylı istatistiklerini ve kapsamlıgeçmişini sunar. Böylece oyuncular önceki sonuçlara bakarak koyacakları bahisleri seçebilirler.',
                     },
                  ],
               },
               howToPlay: {
                  title: 'Oynanış',
                  content: [
                     {
                        text: 'Masaya gelen oyuncular, arayüzü kullanarak istedikleri fiş değerini seçerler ve onları sanal rulet masasındaki ilgili bahis alanlarına yerleştirirler.',
                     },
                     {
                        text: 'Bahis süresi sona erdiğinde, krupiye tekeri döndürür ve topu atar. Oyuncular, canlı video yayını ile tekerin dönüşünü görürler.',
                     },
                     {
                        text: 'Top, numaralı ceplerden birine gelip durduğunda, krupiye kazanan sayıyı duyurur. ',
                     },
                     {
                        text: 'Kazanan bahisler otomatik olarak sonuçlandırılır ve karşılık gelen ödemeler oyuncuların hesaplarına yatırılır. Ardından oyuncular, sonraki oyun turu için tekrar bahis koyabilirler.',
                     },
                  ],
               },
               tipsAndStrategies: {
                  title: 'İpuçları ve Stratejiler ',
                  content: [
                     {
                        text: 'Doğru bahis kararları vermek için çeşitli bahis türleri ve bunların ödeme oranları hakkında bilgilenin.',
                     },
                     {
                        text: 'Bütçenizi doğru yönetin ve sorumlu bir şekilde oynadığınızdan emin olmak için bahislerinize limit koyun.',
                     },
                     {
                        text: 'Bahis stratejinizi geliştirmeye yardımcı olabilmesi için oyun istatistikleri ve geçmişini kullanarak olası örüntülerini veya trendleri görmeye çalışın.',
                     },
                     {
                        text: 'Genel oyun deneyiminizi daha iyi hale getirmek için krupiye ve diğer oyuncular ile etkileşime geçin.',
                     },
                  ],
               },
               gameRound: {
                  title: 'Oyun Turu',
                  content: [
                     {
                        title: 'Bahis Süresi',
                        text: 'Bahisler, bahis süresi içinde masaya koyulmalıdır. Bahis koymak için fiş değerinin seçilmesi ve ana bahis alanı veya parkur üzerinde bir ya da daha fazla bahis bölgesine yerleştirilmesi gerekir. Masanın minimum bahis limitinden düşük olan bahisler geçersizdir ve kabul edilmez. Masanın maksimum bahis limitinden yüksek olan bahisler otomatik olarak maksimum limite gelecek şekilde değiştirilir. Oyun turu devam ederken masaya geldiyseniz, bahis koymak için lütfen sonraki turun başlamasını bekleyin.',
                     },
                     {
                        title: 'Dönüş',
                        text: 'Bahis süresi sona erdikten sonra Oyun Sunucusu, Rulet topunu dönen tekerin üzerindeki top yoluna bırakır. Top, Rulet tekeri içinde döner. "BAHİSLER KAPANDI" yazısı göründükten sonra bahisler alınmaz. Koyulan bahisler değiştirilemez.',
                     },
                     {
                        title: 'Oyun Sonucu',
                        text: ' Top, bir noktada tekerin üzerindeki numaralı ceplerden birinin içine düşer. Kazanan sayı Oyun Sunucusu tarafından duyurulur ve arayüzde gösterilir.  Oyuncu o sayıyı kapsayan bir bahis koyduysa, kazanır. Kazanan sayı, sadece spin geçerliyse geçerlidir.   Spinin geçerli olması için:   Rulet Topunun Oyun Sunucusu tarafından tekerin dönüş yönünün aksinde döndürülmesi ve bir cebin içinde durmadan önce tekerin etrafında en az üç tur dönmesi gereklidir. ',
                     },
                  ],
               },
               rules: {
                  title: 'Kurallar',
                  content: [
                     {
                        title: 'Limitler',
                        text: 'Bahis Limitleri oyun sırasında sürekli olarak mevcut olmalı ve masada izin verilen minimum ve maksimum bahis düzeylerini göstermelidir. Masadaki Min ve Max sembolleri, Düz bahisler için limitleri gösterir. Tüm limitler Limitler ve Ödemeler panelinde gösterilir.Rulet oyunlarında, şu bahislerin kendi limitleri vardır: Düz, Ayrılmış, Sokak, Köşe, Sepet, Çizgi, Sütun Düzine, Kırmızı, Siyah, Çift, Tek, Düşük 1-18, Yüksek 19-36. Minimum ve Maksimum limitleri idare tarafından belirlenir ve sunucudan gönderilir.',
                     },
                     {
                        title: 'Ödemeler',
                        text: 'Oyuncu, masadaki Limitler üzerine tıklayarak açılan pencereden Masa Ödemelerini görebilir. Ödemeler her zaman aynıdır. Her bahis için ödemeler şöyledir:',
                     },
                  ],
               },
               betsPayout: {
                  titles: ['Bahis', 'Ödeme'],
                  list: [
                     { bet: 'Düz', payout: '35:1' },
                     { bet: 'Ayrılmış', payout: '17:1' },
                     { bet: 'Cadde', payout: '11:1' },
                     { bet: 'Köşe/Sepet', payout: '8:1' },
                     { bet: 'Çizgi', payout: '5:1' },
                     { bet: 'Sütun/Düzine', payout: '2:1' },
                     { bet: 'Kırmızı/Siyah', payout: '1:1' },
                     { bet: 'Tek/Çift', payout: '1:1' },
                     { bet: '1-18/19-36', payout: '1:1' },
                  ],
               },
               placingBets: {
                  title: 'Bahis Koyma',
                  content: [
                     {
                        text: 'Oyuna katılabilmek için oyuncunun en az minimum bahis tutarı kadar bakiyesi olmalıdır.',
                     },
                     {
                        text: 'Fiş tablası, mevcut fiş değerlerini gösterir ve bahis koymak istediğiniz fişlerin değerini seçmenize imkan tanır.',
                     },
                     {
                        text: 'Binlik değerdeki fişler K ile ifade edilir. Örneğin, 1000 değerindeki fişin üzerinde 1k yazar.',
                     },
                     {
                        text: 'Fiş değeri seçildikten sonra, oyun masasındaki bahis alanında tıklayarak/dokunarak bahis koyulabilir.',
                     },
                     { text: 'Bir bahis alanında birden fazla fiş yerleştirilebilir.' },
                     {
                        text: 'Toplam Bahis, mevcut oyun turu için bahislerin toplam tutarını gösterir.',
                     },
                     {
                        text: 'Maksimum bahis limitine erişildiğinde, o bahis için fazladan para kabul edilmez.',
                     },
                  ],
               },
               betTypes: {
                  title: 'Bahis türleri',
                  content: [
                     {
                        text: 'Rulet oyununda çeşitli bahis türleri mevcuttur. Her bahis türünün kendi ödeme oranı vardır. Bahisler bir veya birden çok sayıyı kapsayabilir.',
                     },
                  ],
               },
               insideBets: {
                  title: 'İç Bahisler',
                  content: [
                     {
                        text: 'Bahisler, İç Bahisler denen bahis panelindeki numaralı bahis yerlerine veya numaralı bahis yerlerinin aralarına koyulur.',
                     },
                     { text: 'Düz - Bahis, tek bir sayı üzerine koyulur.' },
                     {
                        text: 'Ayrılmış - Bahis, dikey veya yatay olarak iki sayı arasındaki çizgi üzerine koyulur.',
                     },
                     {
                        text: 'Sokak - Bahis, herhangi bir sayı satırının sonuna koyulur. Sokak Bahsi üç sayıyı kapsar. 0/1/2 ve 0/2/3 kesişimlerine koyulan bahisler de sokak bahsi olarak alınır.',
                     },
                     {
                        text: 'Köşe - Bahis, dört sayının kesiştiği (merkezi kesişim) köşeye koyulur. Dört sayının her biri kapsanır. 0/1/2/3 kesişimine koyulan sepet bahsi de köşe bahsi olarak alınır.',
                     },
                     {
                        text: 'Çizgi - Bahis, iki satırın sonundaki kesişimlerine koyulur. Çizgi bahsi, iki satırdaki tüm sayılar olmak üzere toplam altı sayıyı kapsar.',
                     },
                  ],
               },
               outsideBets: {
                  title: 'Dış Bahisler',
                  content: [
                     {
                        text: 'Sayılı panelin sağındaki veya altındaki bahis yerlerine koyulan bahislere dış bahisler denir. Sıfır, bu alanların hiçbirine dahil değildir.',
                     },
                     {
                        text: 'Sütun - Sütunun altındaki "2\'ye 1" yazılı kutu, o sütundaki 12 sayının tamamını kapsar.',
                     },
                     {
                        text: 'Düzine - "1\'inci 12", "2\'nci 12", "3\'üncü 12" yazılı kutu, kutunun yanındaki 12 sayıyı kapsar.',
                        list: [
                           '1-18/19-36 - 18 sayıdan oluşan ilk veya ikinci grubu kapsar.',
                           'Çift/Tek - 18 tek veya 18 çift sayıyı kapsar.',
                           'Kırmızı/Siyah - 18 kırmızı veya 18 siyah sayıyı kapsar.',
                        ],
                     },
                     { text: 'Parkur Bahisleri' },
                     {
                        text: 'Bu bahisler, sayıları Rulet tekerindeki sırasıyla gösteren Rulet parkuru kullanılarak yapılır.',
                     },
                  ],
               },
               racetrackBets: {
                  title: 'Parkur Bahisleri',
                  content: [
                     {
                        text: 'Tek sıfırlı Rulet tekeri, masadaki ilgili bölgelere karşılık gelen 4 bölüme ayrılmıştır. Bu bölgelerin geleneksel adları:',
                     },
                  ],
               },
               voisinsDuZero: {
                  title: 'Voisins du Zero',
                  content: [
                     {
                        text: 'Rulet tekerinde sıfırın olduğu tarafta 22 ve 25 dahil olmak üzere, bunların arasında kalan 17 sayıyı kapsar.',
                     },
                     {
                        text: '9 fiş şu şekilde yerleştirilir:',
                        list: [
                           '2 fiş 0/2/3 sokağına',
                           '1 fiş 4/7 arasına',
                           '1 fiş 12/15 arasına',
                           '1 fiş 18/21 arasına',
                           '1 fiş 19/22 arasına',
                           '2 fiş 25/26/28/29 köşesine',
                           '1 fiş 32/35 arasına',
                        ],
                     },
                  ],
               },
               jeuZero: {
                  title: 'Jeu Zero',
                  content: [
                     {
                        text: 'Sıfırı ve rulet tekerinde sıfırın yakınındaki 6 sayıyı (12, 35, 3, 26, 0, 32 ve 15) kapsar',
                     },
                     {
                        text: '4 fiş şu şekilde yerleştirilir:',
                        list: [
                           '1 fiş 0/3 arasına',
                           '1 fiş 12/15 arasına',
                           '1 fiş 26 üzerine (düz)',
                           '1 fiş 32/35 arasına',
                        ],
                     },
                  ],
               },
               tiers: {
                  title: 'Tiers du Cylindre',
                  content: [
                     {
                        text: ' Rulet tekerinde sıfırın karşı tarafında 27 ve 33 dahil olmak üzere, bunların arasında kalan 12 sayıyı kapsar.',
                     },
                     {
                        text: '6 fiş şu şekilde yerleştirilir:',
                        list: [
                           '1 fiş 5/8 arasına',
                           '1 fiş 10/11 arasına',
                           '1 fiş 13/16 arasına',
                           '1 fiş 23/24 arasına',
                           '1 fiş 27/30 arasına',
                           '1 fiş 33/36 arasına',
                        ],
                     },
                  ],
               },
               orphelins: {
                  title: 'Orphelins',
                  content: [
                     {
                        text: 'Yukarıdaki Voisins du Zero ve Tiers bahislerine dahil olmayan, Rulet tekerinde iki ayrı bölümde bulunan 8 sayıyı kapsar.',
                     },
                     {
                        text: '5 fiş şu şekilde yerleştirilir:',
                        list: [
                           '1 fiş 1 üzerine (düz)',
                           '1 fiş 6/9 arasına',
                           '1 fiş 14/17 arasına',
                           '1 fiş 17/20 arasına',
                           '1 fiş 31/34 arasına',
                        ],
                     },
                  ],
               },
               neighbourBets: {
                  title: 'Komşu Bahisleri',
                  content: [
                     {
                        text: '1 tekil sayıyı ve sayının tekerdeki konumuna bağlı olarak soldan ve sağdan onun 0 ila 9 komşusunu kapsar:',
                        list: [
                           'Oyuncu 1 fiş (1 sayı + 0 sol/0 sağ komşusu) ila 19 fiş (1 sayı + 9 sol/9 sağ komşusu) yerleştirir.',
                           'Oyuncu, 0 ila 9 olarak komşu sayısını seçebilir.',
                        ],
                     },
                     {
                        text: 'Neighbour’s Bets can be placed with click/tap on "-" or "+" button to increase or decrease chosen neighbour bets to the right and left.',
                     },
                  ],
               },
               myBets: {
                  title: 'Bahislerim',
                  content: [
                     {
                        text: 'Bahislerim özelliği, mevcut bahsi kaydederek sonraki turlarda hızlıca bahis koymayı sağlar. Ayrıca özel bahis seçkilerinizi oluşturup sonraki oyunlar için kaydedebilirsiniz.',
                     },
                     {
                        text: 'Düz bahisler. Bu bloktaki bahis seçeneklerinin herhangi birine tıklayarak, bahis panelindeki ilgili tüm düz bahis konumlarına bahis koyabilirsiniz.',
                     },
                     {
                        text: 'Örneğin, Düz Bahisler panelindeki "Kırmızı" üzerine tıklamak, bahis panelindeki tüm kırmızı sayılara fiş yerleştirir.',
                     },
                     {
                        text: 'Özel bahislerin üç bahis seçeneği vardır:',
                        list: [
                           'Son bahis, sayıların son basamağına yapılır. Örneğin Son Dört 4, 14, 24 ve 34 sayılarına bahis koymak anlamına gelir. Son 1-6 için 4 fiş gerekirken, Son 6-9 için sadece 3 fiş gerekir.',
                           'Tam bahisler - Tüm iç bahisleri alır ve belirli bir sayıya koyar.',
                           'Şansımı Göreyim - Sunucu tarafından rastgele olarak seçilen 3, 5 veya 7 sayıya bahis koyar. ',
                        ],
                     },
                     {
                        text: 'Bahis Üreteci kendiniz özel bahis seçkisi oluşturabilmeniz için Bahis Panelini veya Parkuru açar.',
                     },
                  ],
               },
               bettingFromStatistics: {
                  title: 'İstatistik panelinden bahis koyma',
                  content: [
                     {
                        text: 'Doğrudan İstatistik, Son Sonuçlar ve Tablo panellerinden bahis koyabilirsiniz.',
                     },
                     {
                        text: 'İlgili bahis türü üzerine geldiğinizde Bahis Paneli veya Parkur üzerinde vurgulanacaktır. İstatistikler penceresinden sadece Bahis süresi içinde bahis koyabilirsiniz.',
                     },
                  ],
               },
               rebet: {
                  title: 'Bahsi tekrarla',
                  content: [
                     {
                        text: 'Bahsi tekrarla butonuna tıklamak/dokunmak, önceki oyun turundaki bahisleri tekrar koyar.',
                     },
                     { text: 'Buton, sadece oyuncu daha önce oynadıysa mevcuttur.' },
                  ],
               },
               double: {
                  title: 'İkiye katla',
                  content: [
                     {
                        text: 'İkiye katla butonuna tıklamak/dokunmak, oyuncunun tüm bahislerini ikiye katlamasını sağlar.',
                     },
                     {
                        text: 'Oyuncu, bahsi tekrarlamak için bakiyesi yeterli olduğu sürece veya bahis limitine ulaşana kadar istediği kadar kez İkiye katla butonuna basabilir.',
                     },
                  ],
               },
               undo: {
                  title: 'Geri al',
                  content: [
                     {
                        text: 'Geri al butonuna tıklamak/dokunmak en son fiş koyma, Bahsi tekrarla veya İkiye katla eylemini geri alır.',
                     },
                     {
                        text: 'İlk bahis koyulana kadar Geri al butonu devre dışıdır. Bahis koyulduktan sonra Geri al butonu etkinleşir.',
                     },
                     { text: 'Geri al butonuna basılı tutarak tüm bahisler kaldırılabilir' },
                  ],
               },
               dragAndDrop: {
                  title: 'Fişleri Sürükleyip Bırakma',
                  content: [
                     {
                        text: 'Fişleri Sürükleyip Bırakma özelliği oyuncunun, halihazırda bahis alanında bulunan fişleri kolayca hareket ettirmesini sağlar. Fişleri hareket ettirme özelliği Parkur bölümünde mevcut değildir.',
                     },
                     {
                        text: 'Fişi seçmek için oyuncunun fişin üzerine tıklaması ve baslı tutarken onu farklı bir yere taşıması gereklidir.',
                     },
                  ],
               },
               autoplay: {
                  title: 'Otomatik oyun',
                  content: [
                     {
                        text: 'Otomatik oyun özelliği online rulet oynamayı daha kolay ve verimli bir hale getirmek için tasarlanmıştır. Oyuncuların önceden belirlenmiş bahis stratejileri ayarlayarak oyunun, onların yerine bahis koymasını sağlar.',
                     },
                     {
                        text: 'Oynadığınız casinoda mevcutsa, bu özelliği kullanarak belirli bir sayıda tur boyunca otomatik olarak bahis koyabilirsiniz.',
                     },
                     {
                        text: 'Otomatik Oyun Panelinden Otomatik Oyun Turu sayısını seçebilir, otomatik oyunun durması için limit şartlarını belirleyebilirsiniz.',
                     },
                     { text: 'Otomatik Oyun üzerindeki sayaç, kaç turunuz kaldığını gösterir.' },
                     {
                        text: 'Mevcut koşulları görebilir ve değiştirebilirsiniz.  Sonraki turdan itibaren yeni şartlar geçerli olur.',
                     },
                     {
                        text: 'Otomatik oyun ayarlanan şartlara ulaşıldığı için durursa bir bildirim alırsınız.',
                     },
                  ],
               },
               statistics: {
                  title: 'İstatistikler',
                  content: [
                     {
                        text: 'Oyuncu, İstatistik simgesine tıklayarak son 100, 200, 500 veya 1000 tur için İstatistikleri daire, çubuk veya son sonuçlar düzeni gibi farklı şekillerde görebilir.',
                     },
                  ],
               },
               returnToPlayer: {
                  title: 'Oyuncuya Dönüş Oranı',
                  content: [{ text: "Teorik olarak oyuncuya dönüş oranı   (ODO) %97,30'dur." }],
               },
               cancelledGames: {
                  title: 'İptal Edilen Oyunlar',
                  content: [
                     {
                        text: 'Bazı durumlarda teknik sorunlar nedeniyle oyun oturumu iptal edilebilir.',
                     },
                     {
                        text: 'Bu durumda masadaki tüm oyunculara bildirim gider ve tüm bahisler hesaplarına geri yatırılır.',
                     },
                     { text: 'İptal edilen tur Otomatik Oyunu durdurmaz' },
                     {
                        text: 'O turdaki bahisler iade edilir, ancak Otomatik Oyun koşulları sonraki turda geçerliliğini korur.',
                     },
                     { text: 'İptal edilen oyun, Otomatik Oyun turlarından düşülmez.' },
                  ],
               },
               gameHistory: {
                  title: 'Oyun Geçmişi',
                  content: [
                     {
                        text: 'Oyun Geçmişi, tamamladığınız oyun turları geçmişini tarih, oyun turu, koyulan bahis ve tur sonuçlarının listeleri şeklinde gösterir.',
                     },
                     { text: 'En son oyun turu sonucu listenin en üstünde yer alır.' },
                     {
                        text: 'Oyun Geçmişi listesindeki belirli bir oyun turu üzerine dokunmak/tıklamak, o tur için detaylı geçmişi açar.',
                     },
                  ],
               },
               chat: {
                  title: 'Sohbet',
                  content: [
                     { text: 'Oyun Sunucusuna ve diğer oyunculara mesaj gönderilebilir.' },
                     {
                        text: 'Sohbet özelliği, Oyun Sunucusuna veya diğer oyunculara karşı uygunsuz veya kaba mesajlar gönderen oyuncular için devre dışı bırakılacaktır.',
                     },
                     {
                        text: 'Oyuncular, sohbet penceresini kendileri için kullanışlı olacak bir yere taşıyabilirler.',
                     },
                  ],
               },
               roundId: {
                  title: 'Tur Kimliği',
                  content: [
                     { text: 'Her oyun turunun kendine ait eşsiz bir Tur Kimliği vardır.' },
                     {
                        text: 'Belirli bir oyun ile ilgili müşteri hizmetlerine eriştiğinizde, bu Tur Kimliğini belirtin.',
                     },
                  ],
               },
               gameSettings: {
                  title: 'Oyun Ayarları',
                  content: [
                     {
                        text: 'Oyun Ayarları, oyuncu tarafından değiştirilebilen oyun ayarlarının listesini gösterir.',
                     },
                     {
                        text: 'Uygulanan oyun ayarları oyuncunun profiline kaydedilir ve oyuncu tekrar giriş yaptığında otomatik olarak yüklenir.',
                     },
                  ],
               },
               errorHandling: {
                  title: 'Arıza ve Bağlantı Kesintisi Politikası',
                  content: [
                     {
                        text: 'Oyunda, hizmette veya oyun prosedüründe herhangi bir hata olması durumunda oyun turu duraklatılır.',
                     },
                     {
                        text: 'Durumun incelendiği oyunculara sohbet mesajı veya açılır mesaj ile bildirilir.',
                     },
                     {
                        text: 'Hata anında giderilebiliyorsa, oyun turu normal şekilde devam eder.',
                     },
                     {
                        text: 'Anında bir çözüm bulunamazsa, oyun turu iptal edilir ve koyulan bahisler oyun turuna katılan oyunculara iade edilir.',
                     },
                     {
                        text: 'Oyun sunucusu ile bağlantının kesilmesi halinde, ekranda yeniden bağlanıldığında dair bir mesaj belirir. Bahisler kabul edildikten sonra bağlantı kesilirse, tüm bahisler geçerliliğini korur.',
                     },
                     {
                        text: 'Oyun turu tamamlandıysa, oyuncu döndüğünde sonuç Oyun Geçmişi bölümünde gösterilir.',
                     },
                  ],
               },
            },
            autoRoulette: {
               gameOverview: {
                  title: 'Game Overview',
                  content: [
                     {
                        text: "Single Zero Auto Roulette is played via Automatic roulette wheel. The objective of the game is to predict the number of the wheel's pocket where the spun ball will eventually land. The roulette wheel consists of 37 pockets numbered 0-36 where 0 represents single zero.",
                     },
                  ],
               },
               gameRound: {
                  title: 'Game Round',
                  content: [],
               },
               bettingTime: {
                  title: 'Betting Time',
                  content: [
                     {
                        text: 'Bets should be placed during the betting time on the table. To place a bet, chip value must be selected and placed on one or more bet spots on the betting grid or racetrack. Any placed bets that are below the minimum bet limit for the table are not valid and will not be accepted. Any placed bets that are above the maximum bet limit for the table are automatically adjusted to match the maximum limit. If you have joined the game round in progress, please wait for the next round to place bets.',
                     },
                  ],
               },
               spinning: {
                  title: 'Spinning',
                  content: [
                     {
                        text: 'After betting time has ended, the Roulette ball will be automatically released into the ball track of the spinning wheel. Ball is spun within the Roulette wheel. After the status "NO MORE BETS" announced bets are not accepted any more. Placed bets can not be modified.',
                     },
                  ],
               },
               gameOutcome: {
                  title: 'Game Outcome',
                  content: [
                     {
                        text: 'The ball will eventually land in one of the numbered pockets within the wheel and winning number will be announced and appear on UI in subtitles. The player will win if has placed a bet that covers that particular number. A winning number is valid only when the spin was valid. Valid Spin is when: The Roulette ball is spun by the Auto Roulette in the direction opposite to the rotation of the wheel and completes at least three revolutions around the track of the wheel before dropping to constitute valid spin.',
                     },
                  ],
               },
               rules: {
                  title: 'Rules',
                  content: [],
               },
               limits: {
                  title: 'Limits',
                  content: [
                     {
                        text: 'The Bet Limits should be always displayed during the game play and show minimum and maximum allowed bet limits at the table.',
                     },
                     {
                        text: 'The Min and Max sign on the table indicate the limits for Strait Up bets. All the limits are displayed on the Limits and Payouts panel in Menu.',
                     },
                     {
                        text: 'In Roulette games the following bets have their own limits: Straight Up, Split, Street, Corner, Basket, Line, Column, Dozen, Red, Black, Even, Odd, Low 1-18, High 19-36',
                     },
                  ],
               },
               placingBets: {
                  title: 'Placing Bets',
                  content: [
                     {
                        text: 'Player must have sufficient balance to place a minimum bet to participate in the game.',
                     },
                     {
                        text: 'Chip tray displays available chip values and allows you to select the value of chips you want to bet.',
                     },
                     {
                        text: 'Chips of thousand denomination are displayed as number of thousands and character K, for example, chip of value 2000 will be displayed as 2k.',
                     },
                     {
                        text: 'Once the chip size is selected, a bet can be placed by clicking/tapping the betting area on the game table.',
                     },

                     {
                        text: 'More than one chip can be placed on a single betting area. Total Bet will show the total bet amount on bets for the current game round. Once the maximum bet limit is reached, no additional funds will be accepted for that bet.',
                     },
                  ],
               },
               betTypes: {
                  title: 'Bet types',
                  content: [
                     {
                        text: 'There are multiple ways to bet in Roulette game. Each bet type has its own payout rate. Bets can cover a single number or multiple numbers.',
                     },
                  ],
               },
               insideBets: {
                  title: 'Inside Bets',
                  content: [
                     {
                        text: 'Bets placed on the number bet spots or between the numbered bet spots within the betting grid are called Inside Bets.',
                        list: [
                           'Straight Up - bet placed on a single number.',
                           'Split - bet placed on the line between any two numbers, either on the vertical or horizontal.',
                           'Street - bet placed at the end of any row of numbers.A Street Bet covers three numbers.Bets placed at the junctions 0/1/2 and 0/2/3 are also accepted as street bets.',
                           'Corner - bet placed at corner (central intersection) where four numbers meet. All four numbers are covered. Basket bet placed at the junction of 0/1/2/3 is also accepted as corner bet.',
                           'Line - bet at the end of two rows on the intersection between the two rows. A line bet covers all the numbers in both rows, a total of six numbers.',
                        ],
                     },
                  ],
               },
               outsideBets: {
                  title: 'Outside Bets',
                  content: [
                     {
                        text: 'Bets that are made on the bet spots below and to the right of the numbered board are called outside bets. The zero is not covered by any of these bet spots.',
                        list: [
                           'Column - box marked "2 to 1" at the end of the column covers all 12 numbers in that column.',
                           'Dozen - box marked "1st12", "2n12", "3rd12" covers the 12 numbers alongside the box.',
                           '1-18/19-36 - covers the first or second bet set of 18 numbers.',
                           'Even/Odd - covers the 18 even or 18 odd numbers.',
                           'Red/Black - covers 18 red or 18 black numbers.',
                        ],
                     },
                  ],
               },
               racetrackAndNeighbourBets: {
                  title: 'Racetrack and Neighbour Bets',
                  content: [
                     {
                        text: 'These bets are placed using Roulette racetrack, which represents the numbers as they appear on the Roulette wheel, rather than the Roulette table. Provides unique betting options: racetrack bets and neighbour bets. Racetrack bets includes: Voisins du Zero, Zero, Tiers, Orphelins. A single-zero Roulette wheel is split into 4 sectors that corresponds to areas on the table. The traditional names of these sectors are:',
                     },
                     { text: 'Voisins du Zero' },
                     {
                        text: 'Covers a total of 17 numbers that include 22,25 and the numbers that lie between them on the side of the Roulette wheel that contains zero.',
                     },
                     {
                        text: '9 chips are placed as follows:',
                        list: [
                           '2 chips on the 0/2/3 street',
                           '1 chip on the 4/7 split',
                           '1 chip on the 12/15 split',
                           '1 chip on the 18/21 split',
                           '1 chip on the 19/22 split',
                           '2 chips on the 25/26/28/29 corner',
                           '1 chip on the 32/35 split',
                        ],
                     },
                     {
                        text: 'Jeu Zero',
                     },
                     {
                        text: ' Covers zero and the 6 numbers in close proximity to zero on the Roulette wheel 12, 35, 3, 26, 0, 32 and 15.',
                     },
                     {
                        text: '4 chips are placed as follows:',
                        list: [
                           '1 chip on the 0/3 split',
                           '1 chip on the 12/15 split',
                           '1 chip on 26 (straight up)',
                           '1 chip on the 32/35 split',
                        ],
                     },
                     {
                        text: 'Tiers du Cylindre',
                     },
                     {
                        text: 'Covers a total of 12 numbers that include 27, 33 and the numbers that lie between them on the side of the Roulette wheel opposite to zero.',
                     },
                     {
                        text: '6 chips are placed as follows:',
                        list: [
                           '1 chip on the 5/8 split',
                           '1 chip on the 10/11 split',
                           '1 chip on the 13/16 split',
                           '1 chip on the 23/24 split',
                           '1 chip on the 27/30 split',
                           '1 chip on the 33/36 split',
                        ],
                     },
                     { text: 'Orphelins' },
                     {
                        text: 'Covers a total of 8 numbers on the two segments of the Roulette wheel not covered by Voisins du Zero and Tiers bet above.',
                     },
                     {
                        text: '5 chips are placed as follows:',
                        list: [
                           '1 chip on 1 (straight up)',
                           '1 chip on the 6/9 split',
                           '1 chip on the 14/17 split',
                           '1 chip on the 17/20 split',
                           '1 chip on the 31/34 split',
                        ],
                     },
                     {
                        text: 'Neighbour Bets Covers 1 straight up number and from 0 to 9 of its neighbours from left and right, based on the number location on the wheel. Player places from 1 chip (1 number + 0 left/0 right neighbours) to 19 chips (1 number + 9 left/9 right neighbours). Player can select number or neighbours from 0 to 9. Neighbour Bets can be placed with click/tap on "-" or "+" button to increase or decrease chosen neighbour bets to the right and left.',
                     },
                  ],
               },
               myBets: {
                  title: 'My Bets',
                  content: [
                     {
                        text: ' My Bet feature provides an option to save current bet and for fast betting in future rounds. Also you can create custom sets of bets and save them for later play. Straight-Up bets. By press on any of bet options in this block you will place bets on all relevant straight-up positions on the betting map. For example, pressing “Red” on Straight-Up Bets panel will put chips on all red numbers on the betting map.',
                     },
                     {
                        text: 'Special bets have three betting options:',
                        list: [
                           'The Final Bet is made on the last digit of a number. For instance Final Four means that you bet on the numbers 4, 14, 24 and 34. The bet on Final 1–6 requires 4 chips, while Final 6–9 gets along with just 3 chips. Complete bets - take all inside bets and puts them on a particular number.',
                           'Try My Luck - puts chips on 3, 5 or 7 numbers randomly selected by server.',
                           'Bet Generator opens Betting map or Race track for manual creation of a set of custom bets.',
                        ],
                     },
                  ],
               },
               bettingFromStatisticsPanels: {
                  title: 'Betting from Statistics panels',
                  content: [
                     {
                        text: ' You can place bets straight from Statistics, Last Results and Chart panels. Hower over relevant bet type will highlight it on the Betting Map or Racetrack. It is possible to bet from Statistics during Betting time only.',
                     },
                  ],
               },
               rebet: {
                  title: 'Rebet',
                  content: [
                     {
                        text: ' Clicking/Tapping on Rebet button will place bets on the table from the previous game round. On the first entry Rebet button is disabled. If the player has already participated in game, on next game round Rebet button is enabled.',
                     },
                  ],
               },
               double: {
                  title: 'Double',
                  content: [
                     {
                        text: "Clicking/Tapping on Double button will double all player's bets. Player can press Double as many times as he wants until his balance is sufficient to double the bet or he reaches bet/table limit.",
                     },
                  ],
               },
               undo: {
                  title: 'Undo',
                  content: [
                     {
                        text: ' Clicking/Tapping on Undo button will revoke last placed chip, Rebet and Double action. On the first entry Undo button is disabled. As soon as bet is placed Undo button is enabled. The button remains enabled until there are no more actions to undo. All bets can be cleared by holding the Undo button.',
                     },
                  ],
               },
               dragAndDrop: {
                  title: 'Drag and Drop Chips',
                  content: [
                     {
                        text: 'Drag and Drop Chips functionality allows the player to freely move chips that are already placed on the betting grid. Moving chips functionality is not supported on Racetrack. To select the chip, player needs to click & hold on the chip and move it to a different bet spot while it is being held. With Moving Chips functionality player can combine different chips or remove them from the betting grid. Undo function will revoke the last move done.',
                     },
                  ],
               },
               autoPlay: {
                  title: 'Autoplay',
                  content: [
                     {
                        text: 'This feature should be enabled by your service provider. You can use this feature to place bets automatically during predefined number of rounds. After you place bet on the table, you can open Autoplay Panel where you can set a number of the Autoplay Rounds and limit conditions to stop autoplay. Autoplay always starts from the next round. Counter on the Autoplay will notify you how many rounds you have left. If you press the Autoplay button while the feature is active, Autoplay will stop and panel will open. You can see current conditions and change them. New conditions are applied from the next round. If Autoplay will stop reaching set conditions, you will be notified.',
                     },
                  ],
               },
               announcements: {
                  title: 'Announcements',
                  content: [
                     {
                        text: 'Prerecorded voice announcements will declare every game state of the game round as well as subtitles will be shown. You can disable game state announcements in the game menu',
                     },
                  ],
               },
               returnToPlayer: {
                  title: 'Return to Player',
                  content: [
                     { text: 'The theoretical percentage return to player (RTP) is 97.30%.' },
                  ],
               },
               cancelledGames: {
                  title: 'Cancelled Games',
                  content: [
                     {
                        text: 'In some cases of technical issues game session can be interrupted. All the players who are currently at the table are notified of the cancellation and all bets are returned to their accounts. Cancelled game rounds are marked with X. The spin counts to be valid if the ball makes three full revolutions inside the wheel. If the ball did not make 3 revolutions it is counted as a Auto-roulette mistake, the game will be stopped, round will be cancelled. Cancelled round does not stop Autoplay. Round bets will be returned but Autoplay conditions will apply to continue from the next round. Cancelled game is not deducted from the Autoplay rounds.',
                     },
                  ],
               },
               gameHistory: {
                  title: 'Game History',
                  content: [
                     {
                        text: ' Game History must show your completed game rounds history as a list of dates, game rounds, placed bets and round outcomes. Most recent game round result is positioned at the top of the list. Clicking/Tapping on a particular game round entry in Game History list detailed history for this game round.',
                     },
                  ],
               },
               chat: {
                  title: 'Chat',
                  content: [
                     { text: 'It is possible to send messages to other players.' },
                     {
                        text: 'Customer Support team use chat to inform players. Chat should be available during all game phases.',
                     },
                     {
                        text: 'Chat functionality will be disabled for players who use inappropriate and offensivelanguage or abuse the other players.',
                     },
                     { text: 'Player can move chat to place it in a more convenient way for him.' },
                  ],
               },
               liveSupport: {
                  title: 'Live Support',
                  content: [
                     {
                        text: 'If assistance is needed, Live support chat allows you to contact our Customer Support team.',
                     },
                  ],
               },
               gameNumber: {
                  title: 'Game Number',
                  content: [
                     { text: 'Every game round has its own unique Game Number.' },
                     { text: 'This number reflects the time when the game round began.' },
                     {
                        text: 'Refer to this Game Number, when contacting customer support regarding particular game round. history for this game round.',
                     },
                  ],
               },
               gameSettings: {
                  title: 'Game Settings',
                  content: [
                     {
                        text: 'Game Settings displays a list of game settings, that can be changed by the player.',
                     },
                     {
                        text: "Once applied, game settings are stored to player's profile and launched automatically once player logs in again.",
                     },
                     {
                        text: 'Player can change nickname, video, announcements and sound settings.',
                     },
                  ],
               },
               errorHandlingAndDisconnectionPolicy: {
                  title: 'Error Handling and Disconnection Policy',
                  content: [
                     {
                        text: 'In case of game error, service error or Auto-roulette error, the game round will be paused.',
                     },
                     {
                        text: 'Players will be notified via chat message or a pop-up message, that the issue is being investigated.',
                     },
                     {
                        text: 'If the error can be resolved immediately, the game round will continue as normal.',
                     },
                     {
                        text: 'If the immediate resolution is not possible, the game round will be cancelled, and initial bets will be returned to all players who participated in the game round.',
                     },
                     {
                        text: 'In case of disconnection from the game server, a reconnecting message will be displayed on the screen. If the disconnection occurred after bets were accepted, then all bets remain valid.',
                     },
                     {
                        text: "On player's return, if the game round is completed, game round outcome will be displayed in game History.",
                     },
                  ],
               },
            },
         },
         history: {
            title: 'Geçmiş',
            roundBetsTable: {
               betType: 'Bahis Türü',
               bet: 'Bahis',
               netCash: 'net nakit',
            },
         },
         autoplay: {
            title: 'Otomatik Oyun',
            autoplayRounds: 'Otomatik oyun turları',
            lossLimit: 'Kayıp Limiti',
            singleWinLimit: 'Tek Kazanma Sınırı',
            noLimit: 'limit yok',
            stop: 'Durmak',
            start: 'Başlangıç',
            placeBet: 'Otomatik oynatmayı başlatmak için bir bahis koyun',
            emptyLossLimit: 'This field is mandatory',
            lessThanBets: 'Value can’t be lower than bet',
         },
         favourite: {
            title: 'Bahislerim',
            specialBets: 'Özel bahisler',
            finalBets: 'Son Bahisler',
            completeBets: 'Bahisleri Tamamla',
            tryMyLuck: 'Şansımı dene',
            savedBets: 'Kayıtlı bahisler',
            addLastBet: 'Son bahsi ekle',
            specialLineBets: 'Düz bahisler',
            bets: 'Bahis',
            betConstructor: {
               title: 'Bahis Oluşturucu',
               placeBets: 'Bahislerinizi koyun',
            },
         },
         statistics: {
            title: 'İstatistikler',
            lastResults: 'Son sonuçlar',
         },
      },
      chat: {
         title: 'Sohbet',
         placeholder: 'Sohbet için tıklayın',
         errorLoading: 'Something went wrong. Please reopen the chat',
      },
      youWin: 'Kazandınız',
      unmute: 'Seşı açmak ıçın basın',
      waitForNextRound: 'Sonraki oyunu bekleyin',
      pleaseWaitForNextRound: 'Sonraki oyunu bekleyin',
      bettingMap: {
         even: 'Çift',
         odd: 'Tek',
         red: 'Kırmızı',
         black: 'Siyah',
         column: {
            first: '1. sütun',
            second: '2. sütun',
            third: '3. sütun',
         },
         dozen: {
            first: '1st Düzine',
            second: '2nd Düzine',
            third: '3rd Düzine',
         },
      },
      notifications: {
         betConfirmed: {
            error: {
               title: 'Dikkat',
               content: 'Üzgünüz, bahisleriniz kabul edilmedi.',
            },
            success: {
               sendSomeBets: 'bahislerinizden bazıları kabul edilmedi',
               sendAllBets: 'Bahis Onaylandı',
            },
         },
         cancelRound: {
            title: 'Caution',
            content: 'Üzgünüz, bu tur iptal edildi. Tüm bahisler bakiyenize iade edilecektir',
         },
         cancelAutoplay: {
            title: 'Bilgi',
            content: 'Otomatik oynatma durduruldu',
         },
         maintenancePending: {
            title: 'Caution',
            content: 'Sorry the table will be closed from next round',
         },
      },
      timer: {
         lastBets: 'Son bahisler',
         placeBets: 'Bahislerinizi koyun',
         noMoreBets: 'Bahisler kapandı',
      },
      playersList: {
         title: 'Toplam Galibiyet',
      },
      video: {
         toggleVideoMode: 'Video modu',
         pleaseWait: 'Lütfen Bekleyin',
      },
      connection: {
         waitForConnection: 'Lütfen yeniden bağlanmayı bekleyin',
      },
      sound: {
         unmute: 'Sesi aç',
      },
      gameHistory: {
         chooseDate: 'Tarih seçin',
         emptyGameHistoryList: 'Bu dönem için sonuç yok.',
         datePicker: {
            cancel: 'İptal etmek',
            save: 'Kaydetmek',
         },
         navigation: {
            next: 'Sonraki',
            previous: 'Öncesi',
         },
      },
      tableCapacity: {
         limits: {
            bets: 'Bahisler',
            pays: 'Öder',
            minLimit: 'Minimum sınır',
            maxLimit: 'Maksimum sınır',
         },
      },
      errorOverlay: {
         title: 'Ups...',
         message: 'Bir şeyler ters gitti ama biz bunu zaten düzeltiyoruz',
         action: 'Anladım',
         sessionClosed: 'Oturum sunucu tarafında kapatıldı ve artık geçerli değil.',
      },
      sessionErrorOverlay: {
         title: 'Bu cihazda oyuna yeniden katılmak için sayfayı yenileyin',
         action: 'Anladım',
      },
      sessionClosedInactivePlayer: {
         title: 'You have been disconnected due to inactivity',
         action: 'Anladım',
      },
      notifyInactivePlayerOverlay: {
         title: 'Soon you will be disconnected due to inactivity',
         action: 'Anladım',
      },
      maintenanceOverlay: {
         title: 'Üzgünüz bu masa şu anda aktif değil. Lütfen başka bir masa deneyin. ',
         action: 'Anladım',
      },
      inactiveGameTableOverlay: {
         title: "We're sorry but the table is currently closed.",
         action: 'Got it',
      },
      lostConnectionOverlay: {
         title: 'Connection lost. Please, check your internet',
         action: 'Got It',
      },
      tooltip: {
         limit: {
            maxLimit: 'Bahisleriniz bu bahis pozisyonu için maksimum limite ayarlandı',
            minLimit: 'Bahsiniz limitin altında',
         },
      },
      announcements: {
         announcementsSettings: "Dealer's voice",
         male: 'Erkek',
         female: 'Kadın',
         subtitlesLabel: 'Altyazılar',
         bettingTimeEnd: {
            1: 'Ladies and gentlemen, the betting time is closed. Thank you, and good luck!',
            2: 'Thank you, no more bets! Good luck.',
            3: "Thank you for your bets. Let's play. Good luck!",
         },
         bettingTimeStart: {
            1: 'Betting time is open, please place your bets!',
            2: 'You are more than welcome to place your bets!',
            3: 'Dear ladies and gentlemen, betting time is available!',
         },
         winnersCongratulations: {
            1: 'Congratulations to our lucky winner! Well done!',
            2: 'Great job! A round of applause for our winner!',
            3: "Congratulations! You've hit the right number on our Auto Roulette table. Well done!",
         },
         lastBets: {
            1: 'Ladies and gentlemen, it’s the final countdown before the round begins, place your bets!',
            2: 'Dear Players, last bets please, hurry up!',
            3: 'The final countdown has begun, players. Make your bets now before the ball drops.',
         },
      },
      betControls: {
         double: 'Bahis katla',
         rebet: 'Bahis tekrarla',
      },
      dealerRating: {
         buttonTitle: 'Krupiyeyi Derecelendirin',
         modalTitle: 'Kurpiyemizi beğendiniz mi?',
         feedbackCommentTitle: 'Geri bildiriminizi bırakın',
         sendButtonTitle: 'Göndermek',
         appreciationTitle: 'Geri bildiriminiz için teşekkür ederiz',
      },
   },
};
