export const rgbaToSvgFeColorMatrix = (rgba: string = ''): string => {
   if (!rgba) return '';
   // Parse the RGBA color components
   // @ts-ignore
   const [r, g, b, a] = rgba.match(/\d+(\.\d+)?/g).map(Number);

   // Convert the color to SVG feColorMatrix format
   const matrix = [
      0,
      0,
      0,
      0,
      (r / 255) * a,
      //
      0,
      0,
      0,
      0,
      (g / 255) * a,
      //
      0,
      0,
      0,
      0,
      (b / 255) * a,
      //
      0,
      0,
      0,
      a,
      0,
   ];

   // Format the matrix as a string
   const matrixString = matrix.join(' ');
   return matrixString;
};
