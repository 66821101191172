import * as Sentry from '@sentry/react';

const DEFAULT_ENV = 'development';

interface ISentry {
   dns: string;
}
export const setupSentry = ({ dns = '' }: ISentry) => {
   const ignoreLocalDevelopment = import.meta.env.PROD;

   if (ignoreLocalDevelopment) {
      Sentry.init({
         dsn: dns,
         environment: import.meta.env.VITE_ENV || DEFAULT_ENV,
         replaysSessionSampleRate: 0.1,
         replaysOnErrorSampleRate: 1.0,
         integrations: [new Sentry.BrowserTracing()],
      });

      Sentry.setTag('App version', import.meta.env.VITE_APP_VERSION);
   }
};
