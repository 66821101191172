import { frontViewAvif, frontViewJpg, frontViewWebP } from './assets';
import * as S from './AmbientVideo.styles';

export const AmbientVideo = () => {
   return (
      <picture>
         <source type="image/avif" srcSet={frontViewAvif} />
         <source type="image/webp" srcSet={frontViewWebP} />
         <S.Image src={frontViewJpg} alt="Description of the image" />
      </picture>
   );
};
