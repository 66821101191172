import styled from 'styled-components/macro';

export const MobileBettingPanelLeftControl = styled.div`
  grid-area: leftControl;
`;

export const MobileBettingPanelRightControl = styled.div`
  grid-area: rightControl;
`;

export const MobileBettingPanelGridChipTray = styled.div`
  grid-area: chipTray;
`;
