import { LocalStorage } from '@/common/utils/LocalStorage';

import { ISavedBet } from '../types';

import { Storage } from './types';

class LastBetsStorage {
   private storage;

   constructor(storage: Storage) {
      this.storage = storage;
   }

   public get() {
      return this.storage.get();
   }

   public set(lastBets: ISavedBet[]): void {
      this.storage.set(lastBets);
   }
}

const storage: Storage = new LocalStorage('lastBets');
export const lastBetsStorage = new LastBetsStorage(storage);
