// @ts-nocheck
import styled from 'styled-components/macro';

export const Icon = styled.span`
  grid-area: icon;
  position: relative;
  display: inline-block;

  svg {
    width: var(--icon-size);
    height: var(--icon-size);
    margin-right: 12px;
  }
`;
