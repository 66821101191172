import { EModalNotificationType } from '@/common/ui/ModalNotification/constants';

import { useTranslation } from '#/translates';

import { Overlay } from '../Overlay';
import { useLobbyRedirect } from '../../hooks';

export const ErrorOverlay = () => {
   const { t } = useTranslation();
   const handleLobbyRedirect = useLobbyRedirect();

   return (
      <Overlay
         actionButton={{
            title: t('errorOverlay.action'),
            onClick: handleLobbyRedirect,
         }}
         type={EModalNotificationType.Error}
         title={t('errorOverlay.title')}
         message={t('errorOverlay.message')}
      />
   );
};
