// @ts-nocheck
import { CSSProperties } from 'react';

import { getZIndex } from '@/common/ui/theme/mixins';

export const FadeBoxStyles: CSSProperties = {
   zIndex: getZIndex('betConfirmedNotification'),
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
};
