import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TRootState } from '#/state/types';

export enum ETrackType {
   Betting = 'betting',
   Racetrack = 'racetrack',
}

const initialState = {
   isCollapsedActivePlayingArea: false,
   isCollapsedTrack: false,
   trackType: ETrackType.Betting,
};

export const switchTypeTrack = {
   [ETrackType.Racetrack]: ETrackType.Betting,
   [ETrackType.Betting]: ETrackType.Racetrack,
};

export const trackTypeSlice = createSlice({
   name: 'trackType',
   initialState,
   reducers: {
      toggleCollapsedActivePlayingArea: (state): void => {
         state.isCollapsedActivePlayingArea = !state.isCollapsedActivePlayingArea;
      },
      setCollapsedActivePlayingArea: (state, action: PayloadAction<boolean>): void => {
         state.isCollapsedActivePlayingArea = action.payload;
      },
      toggleTrackType: (state): void => {
         state.trackType = switchTypeTrack[state.trackType];
      },
      collapseTrack: (state, action: PayloadAction<boolean>): void => {
         state.isCollapsedTrack = action.payload;
      },
   },
});

const useTrackTypeActions = () => {
   const dispatch = useDispatch();
   const {
      toggleTrackType,
      collapseTrack,
      setCollapsedActivePlayingArea,
      toggleCollapsedActivePlayingArea,
   } = trackTypeSlice.actions;

   const handleToggleCollapsedActivePlayingArea = (): void => {
      dispatch(toggleCollapsedActivePlayingArea());
   };
   const handleSetCollapsedActivePlayingArea = (isCollapsed: boolean): void => {
      dispatch(setCollapsedActivePlayingArea(isCollapsed));
   };

   const handleToggleTrackType = (): void => {
      dispatch(toggleTrackType());
   };
   const handleCollapseTrack = (isCollapsed: boolean): void => {
      dispatch(collapseTrack(isCollapsed));
   };

   return {
      handleCollapseTrack,
      handleToggleCollapsedActivePlayingArea,
      handleSetCollapsedActivePlayingArea,
      handleToggleTrackType,
   };
};

const useCollapsedActivePlayingAreaSelector = () =>
   useSelector((state: TRootState) => state.trackType?.isCollapsedActivePlayingArea);

const useTrackCollapseSelector = () =>
   useSelector((state: TRootState) => state.trackType?.isCollapsedTrack);

export const useTrackTypeSelector = () =>
   useSelector((state: TRootState) => state.trackType?.trackType);

export const useCollapsedActivePlayingAreaState = () => {
   const isCollapsedActivePlayingArea = useCollapsedActivePlayingAreaSelector();

   const { handleSetCollapsedActivePlayingArea, handleToggleCollapsedActivePlayingArea } =
      useTrackTypeActions();

   return {
      isCollapsedActivePlayingArea,
      handleToggleCollapsedActivePlayingArea,
      handleSetCollapsedActivePlayingArea,
   };
};

export const useTrackState = () => {
   const trackType = useTrackTypeSelector();
   const isCollapsedTrack = useTrackCollapseSelector();
   const activeTrackIsRaceTrack: boolean = trackType === ETrackType.Racetrack;
   const isBettingMapActive: boolean = trackType === ETrackType.Betting;

   const { handleCollapseTrack, handleToggleTrackType } = useTrackTypeActions();

   return {
      trackType,
      isCollapsedTrack,
      activeTrackIsRaceTrack,
      isBettingMapActive,
      handleCollapseTrack,
      handleToggleTrackType,
   };
};
