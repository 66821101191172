// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { isHoverSupports, orientation } from '@/common/ui/theme/mixins';

export const Wrapper = styled.div`
   width: 100%;
   margin-top: 15px;
`;

export const StatisticTypeWrapper = styled.div`
   background: rgba(130, 130, 130, 0.2);
   display: flex;
   justify-content: space-around;
   padding: 6px 8px;
   align-items: center;
   will-change: transform;
   margin-top: 42px;

   ${orientation.mobileLandscape`
      margin-top: 0;
      gap: 3px;
   `}
`;

export const StatisticValue = styled.div`
   position: absolute;
   width: 100%;
   left: 0;
   transform: translateY(-55px);

   ${orientation.mobileLandscape`
      transform: translateY(-45px);
   `}
`;

export const StatisticLabel = styled.div`
   pointer-events: none;

   ${orientation.mobileLandscape`
      height: 100%;
      display: grid;
      place-items: center;
      font-size: 10px;
   `}
`;

export const StatisticType = styled.div`
   --bg-color: ${({ isSmallVideo }) => (isSmallVideo ? '#655652' : '#363636')};

   padding: 8px 26px;
   cursor: pointer;
   text-transform: capitalize;
   transition: 0.3s;
   color: #fff;
   height: 33px;
   text-align: center;
   position: relative;

   font-size: 16px;

   &:not(:last-child):before {
      content: ' ';
      display: ${({ $isActive }) => ($isActive ? 'none' : 'block')};
      position: absolute;
      top: 50%;
      right: -0.5px;
      height: 22px;
      transform: translateY(-50%);
      width: 1px;
      background: var(--bg-color);
   }

   ${orientation.portrait`
      padding: 8px 10px;
   `}

   ${orientation.mobileLandscape`
      width: 25%;
      height: 22px;
      padding: 0;
      font-size: 12px;
   `}

   ${({ $isActive }) =>
      !$isActive &&
      css`
         ${isHoverSupports`
            &:hover {
               background: var(--bg-color);
            }
         `}
      `}

   ${({ $isActive }) =>
      $isActive &&
      css`
         background: var(--bg-color);
      `}
`;
