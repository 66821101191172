import { FC } from 'react';

import { EGameTypesName } from '@/common/constants';

import { AutoRouletteTitle } from './components';
import * as S from './LoadingTitle.styles';

const titleLibrary = {
   [EGameTypesName.BlackJack]: (
      <g>
         <path
            d="M100.111 138.996C100.111 148.983 93.3468 154.468 81.9534 154.468H56.8516V99.2358H80.2624C91.6559 99.2358 98.513 104.362 98.513 114.255C98.513 120.194 95.2185 124.507 91.2127 125.951C96.373 127.659 100.116 132.785 100.116 138.996H100.111ZM78.6589 121.095C82.8455 121.095 85.067 119.475 85.067 116.058C85.067 112.64 82.8396 111.108 78.6589 111.108H70.4724V121.095H78.6589ZM70.4666 142.596H80.0817C84.0874 142.596 86.3148 140.439 86.3148 137.287C86.3148 133.958 84.0874 131.89 80.0817 131.89H70.4666V142.596Z"
            fill="#161616"
         />
         <path
            d="M111.913 99.2369H126.245V141.96H150.723V154.463H111.913V99.231V99.2369Z"
            fill="#161616"
         />
         <path
            d="M196.856 99.2358L217.684 154.468H203.177L199.258 143.762H179.41L175.585 154.468H161.434L182.261 99.2358H196.862H196.856ZM183.766 131.89H194.984L189.375 116.329L183.766 131.89Z"
            fill="#161616"
         />
         <path
            d="M250.869 98.3413C256.653 98.3413 261.283 99.779 264.758 102.118V116.153C260.839 113.095 256.565 111.657 252.029 111.657C242.95 111.657 237.16 117.326 237.16 126.859C237.16 136.392 242.945 141.972 252.029 141.972C256.571 141.972 260.839 140.623 264.758 137.565V151.6C261.288 153.939 256.659 155.377 250.869 155.377C234.222 155.377 222.828 144.04 222.828 126.865C222.828 109.689 234.222 98.3531 250.869 98.3531V98.3413Z"
            fill="#161616"
         />
         <path
            d="M292.164 122.716L309.523 99.2417H326.17L306.053 125.685L326.974 154.468H310.059L292.164 129.733V154.468H277.832V99.2358H292.164V122.71V122.716Z"
            fill="#161616"
         />
         <path
            d="M336.489 138.549C339.959 140.888 342.985 142.326 346.991 142.326C352.419 142.326 354.023 139.356 354.023 132.433V99.2427H368.355V135.043C368.355 149.255 360.88 155.371 348.95 155.371C343.789 155.371 338.892 153.662 336.489 152.13V138.549Z"
            fill="#161616"
         />
         <path
            d="M411.789 99.2358L432.616 154.468H418.109L414.191 143.762H394.343L390.518 154.468H376.366L397.194 99.2358H411.794H411.789ZM398.704 131.89H409.923L404.313 116.329L398.704 131.89Z"
            fill="#161616"
         />
         <path
            d="M466.231 98.3413C472.015 98.3413 476.645 99.779 480.12 102.118V116.153C476.202 113.095 471.928 111.657 467.391 111.657C458.313 111.657 452.523 117.326 452.523 126.859C452.523 136.392 458.307 141.972 467.391 141.972C471.934 141.972 476.202 140.623 480.12 137.565V151.6C476.651 153.939 472.021 155.377 466.231 155.377C449.584 155.377 438.19 144.04 438.19 126.865C438.19 109.689 449.584 98.3531 466.231 98.3531V98.3413Z"
            fill="#161616"
         />
         <path
            d="M505.925 122.716L523.283 99.2417H539.93L519.814 125.685L540.735 154.468H523.82L505.925 129.733V154.468H491.593V99.2358H505.925V122.71V122.716Z"
            fill="#161616"
         />
         <path
            d="M96.0286 134.873C96.0286 144.86 89.2648 150.345 77.8713 150.345H52.7695V95.1128H76.1804C87.5739 95.1128 94.4309 100.239 94.4309 110.132C94.4309 116.071 91.1365 120.384 87.1307 121.828C92.291 123.536 96.0344 128.662 96.0344 134.873H96.0286ZM74.5769 116.972C78.7634 116.972 80.985 115.352 80.985 111.935C80.985 108.517 78.7576 106.985 74.5769 106.985H66.3904V116.972H74.5769ZM66.3846 138.473H75.9996C80.0054 138.473 82.2328 136.316 82.2328 133.164C82.2328 129.835 80.0054 127.767 75.9996 127.767H66.3846V138.473Z"
            fill="#E7D981"
         />
         <path
            d="M107.831 95.1119H122.163V137.835H146.641V150.338H107.831V95.106V95.1119Z"
            fill="#E7D981"
         />
         <path
            d="M192.773 95.1128L213.601 150.345H199.094L195.175 139.639H175.327L171.502 150.345H157.351L178.178 95.1128H192.779H192.773ZM179.683 127.767H190.901L185.292 112.206L179.683 127.767Z"
            fill="#E7D981"
         />
         <path
            d="M246.787 94.2163C252.571 94.2163 257.201 95.654 260.676 97.9931V112.028C256.757 108.97 252.483 107.532 247.947 107.532C238.868 107.532 233.078 113.201 233.078 122.734C233.078 132.267 238.863 137.847 247.947 137.847C252.489 137.847 256.757 136.498 260.676 133.44V147.475C257.206 149.814 252.577 151.252 246.787 151.252C230.14 151.252 218.746 139.915 218.746 122.74C218.746 105.564 230.14 94.2281 246.787 94.2281V94.2163Z"
            fill="#E7D981"
         />
         <path
            d="M288.082 118.593L305.441 95.1187H322.088L301.971 121.562L322.892 150.345H305.977L288.082 125.61V150.345H273.75V95.1128H288.082V118.587V118.593Z"
            fill="#E7D981"
         />
         <path
            d="M332.407 134.424C335.877 136.763 338.903 138.201 342.909 138.201C348.337 138.201 349.941 135.231 349.941 128.308V95.1177H364.273V130.918C364.273 145.13 356.798 151.246 344.868 151.246C339.707 151.246 334.81 149.537 332.407 148.005V134.424Z"
            fill="#E7D981"
         />
         <path
            d="M407.707 95.1128L428.534 150.345H414.027L410.109 139.639H390.261L386.436 150.345H372.284L393.112 95.1128H407.712H407.707ZM394.622 127.767H405.841L400.231 112.206L394.622 127.767Z"
            fill="#E7D981"
         />
         <path
            d="M462.159 94.2163C467.943 94.2163 472.573 95.654 476.048 97.9931V112.028C472.129 108.97 467.855 107.532 463.319 107.532C454.24 107.532 448.45 113.201 448.45 122.734C448.45 132.267 454.235 137.847 463.319 137.847C467.861 137.847 472.129 136.498 476.048 133.44V147.475C472.578 149.814 467.949 151.252 462.159 151.252C445.512 151.252 434.118 139.915 434.118 122.74C434.118 105.564 445.512 94.2281 462.159 94.2281V94.2163Z"
            fill="#E7D981"
         />
         <path
            d="M501.842 118.593L519.2 95.1187H535.847L515.731 121.562L536.652 150.345H519.737L501.842 125.61V150.345H487.51V95.1128H501.842V118.587V118.593Z"
            fill="#E7D981"
         />
      </g>
   ),
   [EGameTypesName.SingleZeroRoulette]: (
      <g>
         <g opacity="0.8">
            <path
               d="M90.0943 136.638H81.7693V150.977H66.5078V97.0195H91.1735C105.898 97.0195 115.145 104.65 115.145 116.983C115.145 124.923 111.289 130.78 104.586 134.018L116.224 150.977H99.8835L90.0943 136.638ZM90.2518 109.043H81.7751V124.847H90.2518C96.5699 124.847 99.7319 121.919 99.7319 116.983C99.7319 112.048 96.5699 109.043 90.2518 109.043Z"
               fill="#161616"
            />
            <path
               d="M196.629 126.924V97.0195H211.89V126.463C211.89 135.71 215.747 139.414 222.141 139.414C228.534 139.414 232.315 135.716 232.315 126.463V97.0195H247.349V126.924C247.349 143.031 237.945 152.051 221.989 152.051C206.033 152.051 196.629 143.031 196.629 126.924Z"
               fill="#161616"
            />
            <path
               d="M258.508 97.0254H273.769V138.878H299.514V150.977H258.508V97.0195V97.0254Z"
               fill="#161616"
            />
            <path
               d="M353.844 139.181V150.977H310.527V97.0195H352.846V108.816H325.637V117.911H349.609V129.316H325.637V139.181H353.85H353.844Z"
               fill="#161616"
            />
            <path
               d="M380.476 109.119H363.902V97.0195H412.23V109.119H395.738V150.971H380.476V109.119Z"
               fill="#161616"
            />
            <path
               d="M440.191 109.119H423.617V97.0195H471.945V109.119H455.453V150.971H440.191V109.119Z"
               fill="#161616"
            />
            <path
               d="M526.801 139.181V150.977H483.484V97.0195H525.803V108.816H498.594V117.911H522.566V129.316H498.594V139.181H526.807H526.801Z"
               fill="#161616"
            />
         </g>
         <path
            d="M86.5943 131.977H78.2693V146.317H63.0078V92.3594H87.6735C102.398 92.3594 111.645 99.9901 111.645 112.323C111.645 120.263 107.789 126.12 101.086 129.358L112.724 146.317H96.3835L86.5943 131.977ZM86.7518 104.383H78.2751V120.187H86.7518C93.0699 120.187 96.2319 117.258 96.2319 112.323C96.2319 107.387 93.0699 104.383 86.7518 104.383Z"
            fill="#E7D981"
         />
         <path
            d="M193.129 122.264V92.3594H208.39V121.803C208.39 131.05 212.247 134.754 218.641 134.754C225.034 134.754 228.815 131.056 228.815 121.803V92.3594H243.849V122.264C243.849 138.371 234.445 147.391 218.489 147.391C202.533 147.391 193.129 138.371 193.129 122.264Z"
            fill="#E7D981"
         />
         <path
            d="M126.125 126.121C126.125 110.188 139.137 98.5098 156.804 98.5098C174.471 98.5098 187.483 110.194 187.483 126.121C187.483 142.048 174.471 153.732 156.804 153.732C139.137 153.732 126.125 142.048 126.125 126.121ZM171.702 126.121C171.702 116.94 165.154 110.952 156.798 110.952C148.442 110.952 141.894 116.946 141.894 126.121C141.894 135.296 148.442 141.29 156.798 141.29C165.154 141.29 171.702 135.296 171.702 126.121Z"
            fill="#161616"
         />
         <path
            d="M126.125 119.998C126.125 104.065 137.835 92.3867 153.736 92.3867C169.636 92.3867 181.347 104.071 181.347 119.998C181.347 135.925 169.636 147.609 153.736 147.609C137.835 147.609 126.125 135.925 126.125 119.998ZM167.144 119.998C167.144 110.817 161.251 104.829 153.731 104.829C146.21 104.829 140.317 110.823 140.317 119.998C140.317 129.173 146.21 135.167 153.731 135.167C161.251 135.167 167.144 129.173 167.144 119.998Z"
            fill="#E7D981"
         />
         <path
            d="M255.008 92.3594H270.269V134.212H296.014V146.311H255.008V92.3594Z"
            fill="#E7D981"
         />
         <path
            d="M350.344 134.521V146.317H307.027V92.3594H349.346V104.156H322.137V113.251H346.109V124.656H322.137V134.521H350.35H350.344Z"
            fill="#E7D981"
         />
         <path
            d="M376.976 104.459H360.402V92.3594H408.73V104.459H392.238V146.311H376.976V104.459Z"
            fill="#E7D981"
         />
         <path
            d="M436.691 104.459H420.117V92.3594H468.445V104.459H451.953V146.311H436.691V104.459Z"
            fill="#E7D981"
         />
         <path
            d="M523.301 134.521V146.317H479.984V92.3594H522.303V104.156H495.094V113.251H519.066V124.656H495.094V134.521H523.307H523.301Z"
            fill="#E7D981"
         />
      </g>
   ),
};

interface ILoadingTitleProps {
   type?: EGameTypesName;
}

export const LoadingTitle: FC<ILoadingTitleProps> = ({
   type = EGameTypesName.SingleZeroRoulette,
}) => {
   const isAutoRoulette = type === EGameTypesName.AutoRoulette;

   if (isAutoRoulette) {
      return <AutoRouletteTitle />;
   }

   return (
      <S.LoadingTitle>
         <svg width="100%" height="100%" viewBox="0 0 587 259" fill="none">
            <path
               d="M586.307 66.5273H0V172.121H586.307V66.5273Z"
               fill="url(#paint0_linear_20275_327248)"
            />
            <path
               d="M581.64 71.1945V167.454H4.66712V71.1945H581.64ZM586.307 66.5273H0V172.121H586.307V66.5273Z"
               fill="url(#paint1_linear_20275_327248)"
            />

            {titleLibrary[type] || titleLibrary[EGameTypesName.SingleZeroRoulette]}
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.6">
               <rect
                  x="205.938"
                  y="169.771"
                  width="183.184"
                  height="88.6753"
                  fill="url(#pattern0_20275_327248)"
               />
            </g>
            <path
               d="M223.766 234.536V187.695H233.415V226.193H257.293V234.536H223.766Z"
               fill="#E7D981"
            />
            <path
               d="M232.833 188.276V226.774H256.717V233.95H224.356V188.276H232.839M234 187.109H223.184V235.117H257.878V225.607H233.994V187.109H234Z"
               fill="#1A1A1A"
            />
            <path d="M272.497 187.695H262.848V234.536H272.497V187.695Z" fill="#E7D981" />
            <path
               d="M271.915 188.276V233.95H263.432V188.276H271.915ZM273.082 187.109H262.266V235.117H273.082V187.109Z"
               fill="#1A1A1A"
            />
            <path
               d="M297.043 234.536L276.566 187.695H287.044L301.868 222.045L316.814 187.695H326.569L306.162 234.536H297.043Z"
               fill="#E7D981"
            />
            <path
               d="M325.684 188.276L305.785 233.95H297.43L277.467 188.276H286.667L301.87 223.513L317.207 188.276H325.69M327.463 187.109H316.437L316.134 187.809L301.876 220.578L287.74 187.815L287.437 187.109H275.688L276.399 188.743L296.363 234.416L296.666 235.117H306.549L306.852 234.416L326.752 188.743L327.463 187.109Z"
               fill="#1A1A1A"
            />
            <path
               d="M329.926 234.536V187.695H364.439V195.974H339.575V206.679H361.633V214.829H339.575V226.257H365.349V234.536H329.926Z"
               fill="#E7D981"
            />
            <path
               d="M363.855 188.282V195.394H338.997V207.271H361.049V214.254H338.997V226.85H364.771V233.961H330.514V188.288H363.855M365.022 187.115H329.348V235.122H365.938V225.677H340.164V215.415H362.216V206.099H340.164V196.554H365.022V187.109V187.115Z"
               fill="#1A1A1A"
            />
            <path
               d="M147.772 33.5603V47.2813H136.352V3.17188H155.674C165.225 3.17188 171.692 8.55918 171.692 18.4008C171.692 28.2423 165.228 33.5574 155.674 33.5574H147.772V33.5603ZM154.452 12.8719H147.772V23.9354H154.452C158.617 23.9354 160.486 21.7083 160.486 18.4037C160.486 15.0991 158.617 12.8719 154.452 12.8719Z"
               fill="#E7D981"
            />
            <path d="M188.069 47.2818H176.793V0.589844H188.069V47.2818Z" fill="#E7D981" />
            <path
               d="M211.383 27.8257C211.383 25.3125 209.084 23.5158 204.489 23.5158C201.401 23.5158 198.238 24.3073 195.653 25.8152V16.7651C198.311 15.3294 202.62 14.252 207.218 14.252C216.772 14.252 222.231 18.7784 222.231 27.3259V47.298H211.386V45.6457C210.308 46.7231 207.221 47.8728 203.772 47.8728C197.381 47.8728 191.922 44.2822 191.922 37.4564C191.922 31.2054 197.381 27.1844 204.347 27.1844C207.076 27.1844 210.092 28.0452 211.386 28.9811V27.8257H211.383ZM211.383 36.3731C210.664 35.0068 208.87 34.146 206.857 34.146C204.63 34.146 202.403 35.079 202.403 37.2339C202.403 39.3889 204.63 40.3941 206.857 40.3941C208.867 40.3941 210.664 39.5333 211.383 38.2392V36.3702V36.3731Z"
               fill="#E7D981"
            />
            <path
               d="M232.288 58.7191L237.028 46.5059L224.961 14.9707H236.67L242.848 33.3598L248.881 14.9707H260.088L243.423 58.7191H232.288Z"
               fill="#E7D981"
            />
            <path
               d="M287.913 37.7111L276.565 21.9074V47.1945H265.289V3.08789H275.99L288.057 21.0465L300.196 3.08789H310.825V47.1974H299.477V21.9102L288.202 37.714H287.913V37.7111Z"
               fill="#E7D981"
            />
            <path
               d="M334.887 27.4233C334.887 24.9102 332.588 23.1135 327.993 23.1135C324.905 23.1135 321.742 23.905 319.157 25.4128V16.3627C321.815 14.9271 326.124 13.8496 330.722 13.8496C340.276 13.8496 345.735 18.3761 345.735 26.9236V46.8956H334.89V45.2433C333.812 46.3208 330.725 47.4704 327.276 47.4704C320.885 47.4704 315.426 43.8799 315.426 37.054C315.426 30.803 320.885 26.782 327.851 26.782C330.58 26.782 333.596 27.6428 334.89 28.5788V27.4233H334.887ZM334.887 35.9708C334.168 34.6045 332.374 33.7437 330.361 33.7437C328.134 33.7437 325.907 34.6767 325.907 36.8316C325.907 38.9865 328.134 39.9918 330.361 39.9918C332.371 39.9918 334.168 39.131 334.887 37.8368V35.9679V35.9708Z"
               fill="#E7D981"
            />
            <path
               d="M366.079 47.2001H354.873V23.6375H349.27V14.8734H354.873V5.96484H366.079V14.8734H372.399V23.6375H366.079V47.2001Z"
               fill="#E7D981"
            />
            <path
               d="M398.786 25.5877C397.419 24.8684 395.912 24.5824 394.118 24.5824C391.031 24.5824 388.945 25.9488 388.373 28.2452V47.2091H377.098V14.8824H388.373V17.7566C389.953 15.6017 392.469 14.166 395.701 14.166C397.136 14.166 398.286 14.452 398.788 14.7409V25.5877H398.786Z"
               fill="#E7D981"
            />
            <path
               d="M408.985 12.1409C405.537 12.1409 402.594 9.76937 402.594 6.10658C402.594 2.44379 405.54 0 408.985 0C412.431 0 415.377 2.37157 415.377 6.10658C415.377 9.84159 412.431 12.1409 408.985 12.1409ZM414.589 47.1974H403.313V14.8707H414.589V47.1974Z"
               fill="#E7D981"
            />
            <path
               d="M441.523 30.6651L452.871 47.1881H440.66L435.129 38.7821L429.598 47.1881H417.961L429.237 30.7373L418.464 14.8613H430.603L435.631 22.6202L440.732 14.8613H452.369L441.523 30.6651Z"
               fill="#E7D981"
            />
            <defs>
               <pattern
                  id="pattern0_20275_327248"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
               >
                  <use transform="scale(0.00318471 0.00657895)" />
               </pattern>
               <linearGradient
                  id="paint0_linear_20275_327248"
                  x1="0"
                  y1="119.324"
                  x2="586.307"
                  y2="119.324"
                  gradientUnits="userSpaceOnUse"
               >
                  <stop offset="0.04" stopColor="#010101" />
                  <stop offset="0.5" stopColor="#383836" />
                  <stop offset="0.63" stopColor="#333331" />
                  <stop offset="0.77" stopColor="#272726" />
                  <stop offset="0.91" stopColor="#121212" />
                  <stop offset="1" stopColor="#010101" />
               </linearGradient>
               <linearGradient
                  id="paint1_linear_20275_327248"
                  x1="0"
                  y1="119.324"
                  x2="586.307"
                  y2="119.324"
                  gradientUnits="userSpaceOnUse"
               >
                  <stop offset="0.04" stopColor="#5D5D5B" />
                  <stop offset="0.5" stopColor="#E3E2E0" />
                  <stop offset="0.58" stopColor="#DEDDDC" />
                  <stop offset="0.67" stopColor="#D2D1D0" />
                  <stop offset="0.76" stopColor="#BDBDBC" />
                  <stop offset="0.85" stopColor="#A0A0A0" />
                  <stop offset="0.94" stopColor="#7B7C7D" />
                  <stop offset="1" stopColor="#5F6062" />
               </linearGradient>
            </defs>
         </svg>
      </S.LoadingTitle>
   );
};
