import { FC, memo } from 'react';

import { DeviceOrientation, useDeviceOrientation } from '@/common/hooks';
import { TNullable } from '@/common/types';
import { isMobileDevice } from '@/common/utils/checkDevice';

import { LandscapeGradient, PortraitGradient } from './components';

export const Gradient = memo((): TNullable<JSX.Element> => {
   const deviceOrientation: DeviceOrientation = useDeviceOrientation();

   if (!isMobileDevice()) {
      return null;
   }

   const components: Readonly<Record<string, FC>> = {
      [DeviceOrientation.PORTRAIT]: PortraitGradient,
      [DeviceOrientation.LANDSCAPE]: LandscapeGradient,
   };

   const GradientComponent = components[deviceOrientation];

   return <GradientComponent />;
});

Gradient.displayName = 'Gradient';
