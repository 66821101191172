import { useCallback, useEffect, useState } from 'react';

import { useWindowEvent } from '@/common/hooks';

import * as S from './PortraitGradient.styles';

const GRADIENT_HEIGHT = 35;

export const PortraitGradient = () => {
   const [topPosition, setTopPosition] = useState(0);

   const handleChangeGradientTopPosition = useCallback(() => {
      const screenWidth = window.innerWidth;
      setTopPosition(screenWidth - GRADIENT_HEIGHT);
   }, []);

   useWindowEvent('resize', handleChangeGradientTopPosition);
   useEffect(handleChangeGradientTopPosition, [handleChangeGradientTopPosition]);

   return <S.Gradient top={topPosition} height={GRADIENT_HEIGHT} />;
};
