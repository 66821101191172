import { pipe } from '@/common/utils';

import { dozenConfig } from '#/core/config';
import { TCommonBet } from '#/state/features/bets';

import { transformZero } from './helpers/transformZero';
import { shorteningNumbersBySeparator, splitNumbersBySeparator, transformBetType } from './helpers';

interface ISendParams {
   params: {
      key: string;
      value: (bet: TCommonBet) => number | string;
   };
   sendType: string;
}

const adapterByBetType = (options: ISendParams) => (bet: TCommonBet) => {
   const { params, sendType } = options;
   const { chips } = bet;

   return {
      chips,
      type: sendType,
      params: {
         [params.key]: params.value(bet),
      },
   };
};

export const straightAdapter = adapterByBetType({
   sendType: 'straight',
   params: {
      key: 'number',
      value: (bet) => transformZero(String(bet.numbers[0])),
   },
});

export const redOrBlackAdapter = adapterByBetType({
   sendType: 'color',
   params: {
      key: 'color',
      value: transformBetType,
   },
});

export const evenOrOddAdapter = adapterByBetType({
   sendType: 'parity',
   params: {
      key: 'parity',
      value: transformBetType,
   },
});

export const highOrLowAdapter = adapterByBetType({
   sendType: 'highLow',
   params: {
      key: 'highLow',
      value: transformBetType,
   },
});

export const cornerAdapter = adapterByBetType({
   sendType: 'corner',
   params: {
      key: 'corner',
      value: pipe(splitNumbersBySeparator, transformZero),
   },
});

export const dozenAdapter = adapterByBetType({
   sendType: 'dozen',
   params: {
      key: 'dozen',
      value: (bet) => dozenConfig.mapDozenIdToNumber[bet.id],
   },
});

export const columnAdapter = adapterByBetType({
   sendType: 'column',
   params: {
      key: 'column',
      value: (bet) => bet.numbers[0],
   },
});

export const lineAdapter = adapterByBetType({
   sendType: 'line',
   params: {
      key: 'line',
      value: pipe(shorteningNumbersBySeparator, transformZero),
   },
});

export const splitAdapter = adapterByBetType({
   sendType: 'split',
   params: {
      key: 'split',
      value: pipe(shorteningNumbersBySeparator, transformZero),
   },
});

export const streetAdapter = adapterByBetType({
   sendType: 'street',
   params: {
      key: 'street',
      value: pipe(shorteningNumbersBySeparator, transformZero),
   },
});
