import { rouletteRedColorNumbers, rouletteZeroNumbers } from 'core/roulette/colors';

export const getCellBgClass = (id: number): string => {
   const isMergeSectors = id > 48;

   if (isMergeSectors) {
      return '';
   }

   if (rouletteZeroNumbers.includes(id)) {
      return 'zero';
   }

   if (id === 42) {
      return 'base_sector_red';
   }

   if (id === 43) {
      return 'base_sector_black';
   }

   if (rouletteRedColorNumbers.includes(id)) {
      return 'red';
   }

   return 'black';
};
