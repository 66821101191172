import { z } from 'zod';

export const roundStateScheme = z.object({
   uuid: z.string(),
   round_number: z.string(),
   start_time: z.string(),

   betting_end_time: z.string().nullable(),
   physical_table_id: z.string(),
   physicalTable: z.object({
      bettingTimeDuration: z.number(),
   }),
   playersCount: z.number(),
   dealer: z
      .object({
         name: z.string(),
         uuid: z.string(),
      })
      .nullable(),
   winResults: z
      .object({
         result: z.string(),
         round_number: z.string(),
      })
      .array(),
   is_under_maintenance: z.boolean(),
});

const roundSchema = z.object({
   uuid: z.string(),
   number: z.number(),
   duration: z.number(),
   startTime: z.string(),
   endTime: z.string(),
});

const gameTypeSchema = z.object({
   name: z.string(),
   title: z.string(),
});

const dealerSchema = z.object({
   uuid: z.string(),
   name: z.string(),
});

export const roundByPhysicalTableIdSchema = z.object({
   round: roundSchema,
   physicalTableId: z.string(),
   gameTableTitle: z.string(),
   gameType: gameTypeSchema,
   dealer: dealerSchema,
   totalRoundBet: z.number(),
   totalRoundPayout: z.number(),
   winNumber: z.string(),
   currency: z.string(),
   status: z.string(),
   result: z.number(),
});

export const roundByPhysicalTableIdAndStudioId = z.object({
   uuid: z.string(),
   round_number: z.string(),
   start_time: z.string(),
   physical_table_id: z.string(),
   physical_table_title: z.string(),
   physical_table_uuid: z.string(),
   playersCount: z.number(),
   status: z.string(),
});
