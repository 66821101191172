import { mapConfigCommonBets } from 'core';

const specialBets = [
   { id: 42, name: 'bettingMap.red' },
   { id: 43, name: 'bettingMap.black' },
   { id: 41, name: 'bettingMap.even' },
   { id: 44, name: 'bettingMap.odd' },
   { id: 40, name: '1-18' },
   { id: 45, name: '19-36' },
   { id: 46, name: 'bettingMap.column.first' },
   { id: 37, name: 'bettingMap.dozen.first' },
   { id: 47, name: 'bettingMap.column.second' },
   { id: 38, name: 'bettingMap.dozen.second' },
   { id: 48, name: 'bettingMap.column.third' },
   { id: 39, name: 'bettingMap.dozen.third' },
].map((conf) => ({
   ...mapConfigCommonBets[conf.id],
   ...conf,
}));

const specialBetsObject = specialBets.reduce((acc, { id, name }) => {
   acc[id] = {
      ...mapConfigCommonBets[id],
      value: name,
   };

   return acc;
}, {});

const specialBetsToHighlight = (id: number): string =>
   [...specialBetsObject[String(id)].numbers].join(',');

export const getSpecialBets = () => ({
   specialBets,
   specialBetsObject,
   specialBetsToHighlight,
});
