// @ts-nocheck
import styled, { css } from 'styled-components/macro';

export const LiveStreamSmallMode = styled.div`
   ${({ isSmallLiveStreamMode }) =>
      isSmallLiveStreamMode &&
      css`
                position: absolute;
                top: 35px;
                left: 50%;

                width: 860px!important;
                height: 485px;

                transform-origin: top;
                transform: translate(-50%, calc(var(--scale) * 15px)) scale(var(--scale));

                border-radius: 20px;
                background: ${({ theme }) => theme.colors.black[50]};
                overflow: hidden;

                video {
                    min-width: 100%;
                }
            }
            `}
`;
