// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const VideoWrapper = styled.div`
   ${orientation.portrait`
      width: 100%;
      aspect-ratio: 1/1;
   `}
`;
