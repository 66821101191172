import { getValueFromQueryParams } from '@/common/utils';

import { TLaunchToken } from '../entities';

export interface IUrlParams {
   gameId: string;
   gameTableId: string;
   launchToken: TLaunchToken;
   lobbyRedirectUrl: string;
}

// Prod url example:
// https://stage.playmatrix.com/api/v1/games/launch?gameId=plmx-szrol
// &gameTableId=2ce292f8-98bf-11ed-a05d-d2f1ffbb9224&clientId=1978 -> gameTableId
// &language=tr
// &currencyCode=TRY
// ...
// &token=5003924_1999_uDLdbGcIl0ChSApcFwT -> launchToken
const urlParams: IUrlParams = {
   lobbyRedirectUrl: 'lobbyUrl',
   launchToken: 'token',
   gameTableId: 'gameTableId',
   gameId: 'gameId',
};

export const enum EParamsGameTypes {
   SingleZeroRoulette = 'plmx-szrol',
   AutoRoulette = 'plmx-slngsht',
   BlackJack = 'plmx-bjl',
}

export const gameSettings = {
   launchToken: getValueFromQueryParams({ key: urlParams.launchToken }),
   gameTableId: getValueFromQueryParams({ key: urlParams.gameTableId }),
   lobbyRedirectUrl: getValueFromQueryParams({ key: urlParams.lobbyRedirectUrl }),
   gameId: getValueFromQueryParams({ key: urlParams.gameId }),
};
