import { useEffect, useState } from 'react';

export const useFakePercentLoader = () => {
   const [percents, setPercents] = useState<number>(0);

   useEffect(() => {
      const interval = setInterval(() => {
         setPercents((percents) => {
            const isFullyLoaded = percents >= 100;
            return isFullyLoaded ? 0 : percents + 2;
         });
      }, 100);
      return () => clearInterval(interval);
   }, []);

   return percents;
};
