import { FC, ReactNode } from 'react';

import * as S from './RenderTrack.styles';

interface IRenderTrack {
   children?: ReactNode;
   whenRender: boolean;
}
export const RenderTrack: FC<IRenderTrack> = ({ children, whenRender }) => (
   <S.RenderTrack $whenRender={whenRender}>{children}</S.RenderTrack>
);
