// @ts-nocheck
import styled from 'styled-components/macro';

import { aspectRationMixin, orientation } from '@/common/ui/theme/mixins';

export const MobileVideoContainer = styled.div`
   ${orientation.mobileLandscape`
      ${aspectRationMixin({ width: 16, height: 9 })}
      height: 100vh;

      video {
         width: 100%;
         height: 100%;
      }
   `}

   ${orientation.portrait`
      width: 100vw;
      height: 100vh;
      position: relative;

      video {
         ${aspectRationMixin({ width: 1, height: 1 })}
         width: 100%;
         height: 90%;
         object-fit: cover;
         object-position: 50% 0;
      }
   `}
`;
