import { FC } from 'react';

import { ISectionProps } from '../../Section';

import * as S from './FillPlace.styles';

type IFillPlaceProps = Pick<ISectionProps, 'fillColor' | 'path' | 'scale'>;

export const FillPlace: FC<IFillPlaceProps> = ({ path, scale, fillColor }) => (
   <S.FillPlace d={path} scale={scale} fill={fillColor} />
);
