import { memo } from 'react';

import { ErrorBoundary } from '@/common/dev/ErrorBoundary';
import { TableLimitsView } from '@/common/ui';

import { FADE_DURATION } from '#/constants/animations';
import { useTranslation } from '#/translates';

import { useStraightBetLimits } from './hooks/useStraightBetLimits';
import { useLimitsTableRows } from './hooks/useLimitsTableRows';
import { useGameTitle } from './hooks/useGameTitle';

export const TableCapacity = memo(() => {
   const { t } = useTranslation();
   const straightLimits = useStraightBetLimits();

   return (
      <ErrorBoundary>
         <TableLimitsView
            showAnimationDuration={FADE_DURATION}
            limitsRange={straightLimits ?? ''}
            gameTitle={useGameTitle()}
            tableData={{
               titles: [
                  'tableCapacity.limits.bets',
                  'tableCapacity.limits.pays',
                  'tableCapacity.limits.minLimit',
                  'tableCapacity.limits.maxLimit',
               ].map(t),
               rows: useLimitsTableRows(),
            }}
         />
      </ErrorBoundary>
   );
});

TableCapacity.displayName = 'TableCapacity';
