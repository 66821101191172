import { useEffect } from 'react';

const MODAL_CLOSE_TIMEOUT = 1500;

export const useCloseModalAfterFeedbackLeft = ({
   isFeedbackLeft,
   handleCloseModal,
}: {
   isFeedbackLeft: boolean;
   handleCloseModal: () => void;
}) => {
   useEffect(() => {
      const modalTimeout = setTimeout(() => {
         if (!isFeedbackLeft) {
            return;
         }

         handleCloseModal();
      }, MODAL_CLOSE_TIMEOUT);

      return () => clearTimeout(modalTimeout);
   }, [isFeedbackLeft]);
};
