import { FC, useEffect, useState } from 'react';
import { StyledComponentInterpolation } from 'styled-components/macro';

import { TNullable } from '../../types';

import { sideBarAnimationClassNames } from './constants';
import * as S from './FadeInOut.styles';

interface IFadeInOut {
  animation?: StyledComponentInterpolation;
  children: React.ReactNode;
  className?: string;
  id: string;
  show: boolean;
  styles?: StyledComponentInterpolation;
}

export const FadeInOut: FC<IFadeInOut> = ({
  id = 'FadeInOut',
  show,
  children,
  styles,
  animation,
  className,
}): TNullable<JSX.Element> => {
  const [shouldRender, setShouldRender] = useState<boolean>(show);

  useEffect(() => {
    if (show) setShouldRender(true);
  }, [show]);

  function handleAnimationEnd() {
    const clearClassList = () => {
      const element = document.getElementById(id);
      if (element) {
        const removedClass = show
          ? sideBarAnimationClassNames.openSideBar
          : sideBarAnimationClassNames.closeSideBar;
        element.classList.remove(removedClass);
      }
    };
    setTimeout(clearClassList, 0);
    if (!show) {
      setShouldRender(false);
    }
  }

  if (!shouldRender) {
    return null;
  }

  return (
    <S.FadeInOutContainer
      id={id}
      styles={styles}
      animation={animation}
      onAnimationEnd={handleAnimationEnd}
      className={className}
    >
      {children}
    </S.FadeInOutContainer>
  );
};
