import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { IBetsState } from '#/state/features/bets';
import { TRootState, Store } from '#/state/types';

export const rebetState = (store: Store) => {
   const getRebetState = () => store?.getState().rebet;

   return {
      zIndexes: () => getRebetState().zIndexes,
      totalBets: () => getRebetState().totalBets,
      betsHistory: () => getRebetState().betsHistory,
   };
};

const useAvailableRebet = (): boolean =>
   useSelector(
      createSelector(
         (state: TRootState) => state.rebet?.betsHistory,
         (betsHistory) => betsHistory?.length > 0,
      ),
   );

const useRebet = (): IBetsState =>
   useSelector(
      createSelector(
         (state: TRootState) => state.rebet,
         (rebet) => rebet,
      ),
   );

export const useRebetSelectors = () => {
   return {
      useAvailableRebet,
      useRebet,
   };
};
