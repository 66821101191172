import { lineConfig } from 'core/config/line';

export const generateLineGrid = (): string =>
   lineConfig.getArrayOfBetIds().reduce((acc, id, index) => {
      return `${acc}
            .cell_${id} {
               position: absolute;
               top: calc((var(--bm-big-cell-height) * ${3}) - var(--half-cell-width));
               width: var(--cell-size);
               height: var(--cell-size);
               left: calc(var(--bm-small-cell-width) + (((var(--bm-big-cell-width) * ${
                  index + 1
               }))) - var(--half-cell-width));
               background: transparent;
               box-shadow: none;
            }`;
   }, '');

export const generateLineGridPortrait = (): string =>
   lineConfig.getArrayOfBetIds().reduce((acc, id, index) => {
      return `${acc}
         .cell_${id} {
            position: absolute;
            top: calc(var(--bm-small-cell-height) + (var(--bm-big-cell-height) * ${index}) + var(--bm-big-cell-height) - var(--half-cell-width));
            width: var(--cell-size);
            height: var(--cell-size);
            left: calc((var(--bm-small-cell-width)) * 2 - var(--half-cell-width));
            background: transparent;
            box-shadow: none;
         }`;
   }, '');
