// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { applyZIndex, orientation } from '../../ui/theme/mixins';

export const SidebarWrapper = styled.div`
   --transition-duration: 0.5s;
   --transition-timing-func: ease;

   ${applyZIndex('biggest')}

   display: flex;
   position: fixed;

   bottom: 0;
   transition: all var(--transition-duration) var(--transition-timing-func);
   max-width: 100%;

   ${({ direction }) => {
      // eslint-disable-next-line sonarjs/no-small-switch
      switch (direction) {
         case 'bottom':
            return css`
               bottom: 0;
               left: 0;
               transform: translateY(100%);

               &.sidebar-enter {
                  transform: translateY(100%);
               }

               &.sidebar-enter-active {
                  transform: translateY(0);
               }

               &.sidebar-enter-done {
                  transform: translateY(0);
               }

               &.sidebar-exit-done {
                  transform: translateY(100%);
               }
            `;
         default:
            return css`
               top: -1px;
               left: 0;
               transform: translateX(-100%);

               &.sidebar-enter {
                  transform: translateX(-100%);
               }

               &.sidebar-enter-active {
                  transform: translateX(0);

                  ${orientation.desktopLandscape`
                    transform: translateX(0);
                  `}
               }

               &.sidebar-enter-done {
                  transform: translateX(0);

                  ${orientation.desktopLandscape`
                    transform: translateX(0);
                  `}
               }

               &.sidebar-exit-done {
                  transform: translateX(-100%);
               }
            `;
      }
   }}
`;
