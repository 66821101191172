import { TLimits } from '#/modules/TableCapacity';
import { TCommonBet } from '#/state/features/bets';

export const sortBetsByMinLimit = ({ bets, limits }: { bets: TCommonBet[]; limits: TLimits }) => {
   return bets.reduce<{ validBets: TCommonBet[]; invalidBets: TCommonBet[] }>(
      (total, bet) => {
         const betAmountIsGreaterMinLimit = limits[bet.type] && bet.amount >= limits[bet.type].min;

         betAmountIsGreaterMinLimit ? total.validBets.push(bet) : total.invalidBets.push(bet);

         return total;
      },
      { validBets: [], invalidBets: [] },
   );
};
