import { useEffect, useRef } from 'react';

import { TSetTimeout, TNullable } from '@/common/types';

import { FADE_DURATION } from '#/constants/animations';

import { useBetConfirmed } from '../state';
import { SHOWING_NOTIFICATION_DURATION } from '../constants';

export const useShowBetConfirmedNotification = () => {
   const { isShowNotification, handleResetState, handleHideNotification } = useBetConfirmed();
   const timeout = useRef<TNullable<TSetTimeout>>(null);

   useEffect(() => {
      if (isShowNotification) {
         timeout.current = setTimeout(handleHideNotification, SHOWING_NOTIFICATION_DURATION);
      } else {
         timeout.current = setTimeout(handleResetState, FADE_DURATION);
      }

      return () => {
         if (timeout.current) {
            clearTimeout(timeout.current);
         }
      };
   }, [isShowNotification]);
};
