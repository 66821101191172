import { FC } from 'react';

import * as S from './SidebarCloseButton.styles';

export interface ISidebarCloseButtonProps {
   onClick: () => void;
}

export const SidebarCloseButton: FC<ISidebarCloseButtonProps> = ({ onClick }) => (
   <S.SidebarCloseButton data-testid="sidebar-close-button" onClick={onClick} />
);
