import { InnerStatistics, LastResults, StatisticsButtons } from './components';
import { useStatisticsTabs } from './hooks';
import * as S from './Statistics.styles';

export const Statistics = () => {
   const { isStatisticTab, statisticType, setStatisticType } = useStatisticsTabs();

   return (
      <S.StatisticsWrapper>
         <StatisticsButtons statisticType={statisticType} setStatisticType={setStatisticType} />
         {isStatisticTab ? <InnerStatistics /> : <LastResults />}
      </S.StatisticsWrapper>
   );
};
