// @ts-nocheck
import styled, { keyframes } from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

import {
   ACTIVE_CLASSNAME_MODIFIER,
   ANIMATION_DURATION_SEC,
   HIDE_ANIMATION_DURATION_SEC,
} from './constants';

const hideAnimation = keyframes`
    from {
        opacity: 1;
    }
    to {
         opacity: 0;
    }
`;

const horizontalLineAnimation = keyframes`
    from {
       transform: scaleX(0);
    }
    to {
       transform: scaleX(1);
    }
`;

const verticalLineFillAnimation = keyframes`
     from {
         transform: scaleY(0);
      }
      to {
         transform: scaleY(1);
      }
`;

const Line = styled.span`
   position: absolute;
   display: block;
`;

export const Top = styled(Line)`
   top: var(--border-shift);
   left: var(--border-shift);
   transform-origin: top left;
`;

export const Bottom = styled(Line)`
   bottom: var(--border-shift);
   right: var(--border-shift);
   transform-origin: top right;
`;

export const Right = styled(Line)`
   top: var(--border-shift);
   right: var(--border-shift);
   transform-origin: top left;
`;

export const Left = styled(Line)`
   bottom: var(--border-shift);
   left: var(--border-shift);
   transform-origin: bottom left;
`;

export const WinningNumber = styled.div`
   --animation-duration: ${ANIMATION_DURATION_SEC}s;
   --hide-animation-duration: ${HIDE_ANIMATION_DURATION_SEC}s;
   --one-line-animation-duration: calc(var(--animation-duration) / 4);

   --border-width: 2px;
   --border-shift: calc(0px - (var(--border-width) / 2));

   ${orientation.mobileLandscape`
      --border-width: 2px;
   `}

   ${orientation.portrait`
      --border-width: 2px;
   `}

   width: 100%;
   height: 100%;
   position: relative;
   cursor: pointer;

   ${Top},
   ${Bottom} {
      width: 100%;
      height: var(--border-width);
      transform: scaleX(0);
   }

   ${Right},
   ${Left} {
      width: var(--border-width);
      height: 100%;
      transform: scaleY(0);
   }

   &.${ACTIVE_CLASSNAME_MODIFIER} {
      ${Top},
      ${Bottom},
      ${Right},
      ${Left} {
         box-shadow: ${({ theme }) => `0 0 3px 1px ${theme.colors?.main}`};
         background-color: ${({ theme }) => theme.colors?.main};
      }

      ${Top},
      ${Bottom} {
         animation-name: ${horizontalLineAnimation}, ${hideAnimation};
         animation-timing-function: linear, linear;
         animation-fill-mode: forwards, forwards;
         animation-duration: var(--one-line-animation-duration), var(--hide-animation-duration);
      }

      ${Top} {
         animation-delay: 0s, var(--animation-duration);
      }

      ${Bottom} {
         animation-delay: calc(var(--animation-duration) / 2), var(--animation-duration);
      }

      ${Right},
      ${Left} {
         animation-name: ${verticalLineFillAnimation}, ${hideAnimation};
         animation-timing-function: linear, linear;
         animation-fill-mode: forwards, forwards;
         animation-duration: var(--one-line-animation-duration), var(--animation-duration);
      }

      ${Right} {
         animation-delay: calc(var(--animation-duration) / 4), var(--animation-duration);
      }

      ${Left} {
         animation-delay: calc(var(--animation-duration) * 3 / 4), var(--animation-duration);
      }
   }
`;
