import { SuccessIcon } from './assets';
import * as S from './AppreciationScreen.styles';

interface IAppreciationScreenProps {
   appreciationTitle: string;
}

export const AppreciationScreen = ({ appreciationTitle }: IAppreciationScreenProps) => {
   return (
      <S.AppreciationScreenWrapper>
         <SuccessIcon />
         <S.AppreciationMessage>{appreciationTitle}</S.AppreciationMessage>
      </S.AppreciationScreenWrapper>
   );
};
