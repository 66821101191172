import { useCurrency } from 'hooks/useCurrency';

import { AmountInfo } from '@/common/ui';

import { useTotalBetsAmountSelector } from '#/modules/Balance';
import { useTranslation } from '#/translates';

export const Bet = () => {
   const { t } = useTranslation();
   const formatCurrency = useCurrency();
   const betsAmount: number = useTotalBetsAmountSelector();

   return (
      <AmountInfo
         amount={formatCurrency(betsAmount)}
         title={t('bet')}
         titleDataTestId="bet-amount-title"
         amountDataTestId="bet-amount-value"
      />
   );
};
