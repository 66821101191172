import { IBetsState } from '#/state/features/bets';

type BetsModel = {
   zIndexes: () => IBetsState['zIndexes'];
   totalBets: () => IBetsState['totalBets'];
   betsHistory: () => IBetsState['betsHistory'];
};

type BetsActions = {
   handleUndoBets: () => void;
   handleResetBets: () => void;
   handleResetBetsOnCancelRound?: () => void;
   handleAddBets: (bets: IBetsState['betsHistory']) => void;
   handleAddDoubleBet: (betsData: [IBetsState['totalBets'], IBetsState['betsHistory']]) => void;
   handleRemoveBetById: (betId: number) => void;
   handleAddCommandsFromOutside?: ({ betsHistory, totalBets, zIndexes }: IBetsState) => void;
};

export class Bets {
   private readonly betsModel: BetsModel;
   private readonly betsActions: BetsActions;

   constructor({ betsModel, betsActions }: { betsModel: BetsModel; betsActions: BetsActions }) {
      this.betsModel = betsModel;
      this.betsActions = betsActions;
   }

   getZIndexes = () => {
      return this.betsModel.zIndexes();
   };

   getBetsHistory = () => {
      return this.betsModel.betsHistory();
   };

   getTotalBets = () => {
      return this.betsModel.totalBets();
   };

   addBets = (bets: IBetsState['betsHistory']): void => {
      this.betsActions.handleAddBets(bets);
   };

   doubleBets = (betsData: [IBetsState['totalBets'], IBetsState['betsHistory']]): void => {
      this.betsActions.handleAddDoubleBet(betsData);
   };

   undoLastBet = (): void => {
      this.betsActions.handleUndoBets();
   };

   resetBets = (): void => {
      this.betsActions.handleResetBets();
   };

   resetBetsOnCancelRound = (): void => {
      this.betsActions?.handleResetBetsOnCancelRound?.();
   };

   removeBetById = (id: number): void => {
      this.betsActions.handleRemoveBetById(id);
   };

   addBetsFromOutside = (data: IBetsState) => {
      this.betsActions.handleAddCommandsFromOutside?.(data);
   };
}
