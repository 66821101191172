import { useDispatch } from 'react-redux';

import { Store } from '#/state/types';

import { IBetsState, betsSlice } from '../bets';
import { TDoubleBet, TExcludeBetFromBetList } from '../types';

const createActions = (dispatch: Store['dispatch']) => {
   const {
      undo,
      addBets,
      addDoubleBet,
      removeBetById,
      addBetsFromOutside,
      resetBets,
      resetBetOnCancelRound,
   } = betsSlice.actions;

   const handleResetBets = (): void => {
      dispatch(resetBets());
   };

   const handleResetBetsOnCancelRound = (): void => {
      dispatch(resetBetOnCancelRound());
   };

   const handleUndoBets = (): void => {
      dispatch(undo());
   };

   const handleAddBets = (newBets: TExcludeBetFromBetList<TDoubleBet>[]): void => {
      dispatch(addBets(newBets));
   };

   const handleAddDoubleBet = (
      betsData: [IBetsState['totalBets'], IBetsState['betsHistory']],
   ): void => {
      dispatch(addDoubleBet(betsData));
   };

   const handleRemoveBetById = (betId: number): void => {
      dispatch(removeBetById(betId));
   };

   const handleAddCommandsFromOutside = (newBets: IBetsState): void => {
      dispatch(addBetsFromOutside(newBets));
   };

   return {
      handleResetBets,
      handleUndoBets,
      handleAddBets,
      handleAddDoubleBet,
      handleRemoveBetById,
      handleAddCommandsFromOutside,
      handleResetBetsOnCancelRound,
   };
};

export const betsActions = (store: Store) => createActions(store.dispatch);

export const useDigitalTableActions = () => createActions(useDispatch());
