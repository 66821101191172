import { useEffect } from 'react';
import { Swiper } from 'swiper';

import { TNullable } from '../../../../types';

export const useSlideToSelectedChip = ({
   swiper,
   selectedChipIndex,
}: {
   swiper: TNullable<Swiper>;
   selectedChipIndex: number;
}) => {
   useEffect(() => {
      if (swiper) {
         swiper.slideTo(selectedChipIndex);
      }
   }, [swiper, selectedChipIndex]);
};
