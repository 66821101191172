import styled from 'styled-components/macro';

import { applyZIndex } from '../../../ui/theme/mixins';

export const SelectedChipContainer = styled.div`
   --selected-chip-size: 100px;

   ${applyZIndex('mobileChipTraySelected')};
   position: relative;
   width: var(--selected-chip-size);
   height: var(--selected-chip-size);
   border: ${({ theme }) => `3px solid ${theme.colors.main}`};
   border-radius: 50%;

   & > svg {
      /* "will-change" added special for ios devices (for prevent little jumping via transition)  */
      will-change: transform;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 2px;
   }

   &.horizontal {
      --selected-chip-size: 48px;
   }

   &.vertical {
      --selected-chip-size: 13vw;
   }
`;

export const SlideUpWrapper = styled.div`
   ${applyZIndex('mobileChipTray')};

   position: fixed;
   width: 100vw;
   height: 190px;
   transition:
      transform 500ms,
      opacity 500ms;
   background: linear-gradient(270deg, rgba(3, 3, 3, 0.8) 32.61%, rgba(3, 3, 3, 0.8) 62.17%);
   opacity: ${({ isShow }) => (isShow ? '1' : '0')};

   &.vertical {
      top: 0;
      left: 100%;
      display: flex;
      align-items: center;
      width: 40vw;
      height: 100%;
      transform: ${({ isShow }) => (isShow ? 'translateX(-100%)' : 'translateX(0)')};

      /* todo: move to component green line */
      &:before {
         content: '';
         position: absolute;
         width: 1px;
         height: 100%;
         left: 0;
         background: linear-gradient(
            89deg,
            rgba(41, 210, 58, 0) 15.03%,
            #29d23a 50%,
            rgba(41, 210, 58, 0) 84.97%
         );
      }
   }

   &.horizontal {
      left: -10vw;
      transform: ${({ isShow }) => (isShow ? 'translateY(-50%)' : 'translateY(50%)')};
      width: 120vw;

      &:before {
         content: '';
         position: absolute;
         width: calc(100% + 10vw);
         height: 1px;
         left: 0;
         background: linear-gradient(
            90deg,
            rgba(41, 210, 58, 0) 15.03%,
            #29d23a 50%,
            rgba(41, 210, 58, 0) 84.97%
         );
      }
   }
`;

export const ChipTray = styled.div`
   ${applyZIndex('mobileChipTray')};

   position: absolute;
   transition:
      transform 500ms,
      opacity 500ms;

   &.vertical {
      right: 0;
      top: 0;
      width: 40vw;
      transform: ${({ isShow }) => (isShow ? 'translate(-10%, -45%)' : 'translate(110%, -45%)')};
   }

   &.horizontal {
      left: ${({ width }) => `calc(50% - ${width} / 2)`};
      transform: ${({ isShow }) => (isShow ? 'translateY(-100%)' : 'translateY(100%)')};
   }
`;
