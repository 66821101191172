import { z } from 'zod';

import { INCIDENT_STATUS, MANUAL_INCIDENT_TYPES } from '@/common/entities';

export const incidentSchema = z.object({
   created: z.string(),
   dealer: z.object({
      name: z.string(),
      uuid: z.string(),
   }),
   history: z
      .object({
         comment: z.string().nullable(),
         created: z.string(),
         created_by: z.string(),
         status: z.string(),
      })
      .array(),
   physical_table: z.object({
      id: z.string(),
      title: z.string(),
      uuid: z.string(),
   }),
   round: z.object({
      number: z.string(),
      uuid: z.string(),
   }),
   status: z.nativeEnum(INCIDENT_STATUS),
   sub_type: z.string().nullable(),
   type: z.nativeEnum(MANUAL_INCIDENT_TYPES),
   updated: z.string(),
   uuid: z.string(),
});

export type TIncidentData = z.infer<typeof incidentSchema>;
