import { memo } from 'react';

import { ACTIVE_CLASSNAME } from './constants';
import * as S from './WinningNumber.styles';

export const WinningNumber = memo(() => {
   return (
      <S.WinningNumber className={ACTIVE_CLASSNAME}>
         <S.Top />
         <S.Right />
         <S.Bottom />
         <S.Left />
      </S.WinningNumber>
   );
});

WinningNumber.displayName = 'WinningNumber';
