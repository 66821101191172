// @ts-nocheck
import styled from 'styled-components/macro';

import { getBorderColorByTimerTick } from '../../../../utils';
import { orientation } from '../../../../../../ui/theme/mixins';

export const HorizontalCountDownTick = styled.span`
   z-index: 1;
   position: absolute;
   left: 0;
   top: 0;
   /* we need add min and max properties for correct rendering in ios devices */
   max-width: var(--timer-height);
   max-height: var(--timer-height);
   min-width: var(--timer-height);
   min-height: var(--timer-height);
   aspect-ratio: 1/1;
   display: grid;
   place-items: center;
   background-color: ${({ theme }) => theme.colors.black['50']};
   border-radius: 50%;
   font-size: var(--timer-value-fz);
   border: ${({ tick }) => `var(--timer-border-width) solid ${getBorderColorByTimerTick(tick)}`};
   transition: border-color var(--timer-transition-duration) var(--timer-transition-timing-function);
   line-height: 18px;
   font-weight: 700;

   ${orientation.mobileLandscape`
      line-height: 100%;
   `}

   ${orientation.mobileLandscape`
      line-height: 100%;
   `}
`;
