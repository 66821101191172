// @ts-nocheck
import { ElementRef } from 'react';

import { TNullable } from '@/common/types';
import { logger } from '@/common/utils';
import 'modules/LiveStreamPlayer/Janus/janus';

const Janus = window?.Janus;
const JANUS_SETTINGS = {
   streamingPlugin: 'janus.plugin.streaming',
   opaqueId: `streaming-${Janus.randomString(12)}`,
};
const JANUS_COMMANDS = {
   WATCH: 'watch',
};

export class JanusStreamController {
   private streamId: string;
   public streamingPlugin;
   private videoStream: TNullable<MediaStreamTrack> = null;
   private audioStream: TNullable<MediaStreamTrack>;
   private janus;
   public video: ElementRef<'video'>;
   public audio: ElementRef<'video'>;
   public streamMetadata;
   public serverURL: string;

   private _startVideoStreaming = ({ jsep }): void => {
      if (jsep) {
         this.streamingPlugin.createAnswer({
            jsep,
            tracks: [{ type: 'data' }],
            customizeSdp: (jsep: { sdp: string }) => {
               jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1;stereo=1');
            },
            success: (jsep: { sdp: string }) => {
               this.streamingPlugin.send({ message: { request: 'start' }, jsep });
            },
         });
      }
   };

   private _attachStreamingPlugin({ onConnectWebRTCStream }): void {
      this.janus.attach({
         opaqueId: JANUS_SETTINGS.opaqueId,
         plugin: JANUS_SETTINGS.streamingPlugin,
         success: (streamingPlugin): void => {
            this.streamingPlugin = streamingPlugin;

            this.watchStream();
         },
         onmessage: (_, jsep): void => {
            this._startVideoStreaming({
               jsep,
            });
         },
         onremotetrack: (stream: MediaStreamTrack): void => {
            // In this callback, we receive media streams for audio and video from the Janus plugin,
            // and then we should connect these streams to HTML media elements
            if (stream.kind === 'video') {
               this.videoStream = stream;
            }

            if (stream.kind === 'audio') {
               this.audioStream = stream;
            }
         },
         webrtcState: () => {
            onConnectWebRTCStream?.();
         },
         error: (error) => {
            this.janus?.destroy();
            logger.error(`Janus plugin connection failed: ${error}`);
         },
      });
   }

   public initializeConnection = ({
      video,
      audio,
      streamId,
      serverURL,
      onError,
      onConnectWebRTCStream,
   }): void => {
      this.video = video;
      this.audio = audio;
      this.streamId = streamId;
      this.serverURL = serverURL;

      Janus.init({
         dependencies: Janus.useDefaultDependencies(),
         callback: () => {
            this.janus = new Janus({
               server: this.serverURL,
               success: (): void => {
                  // when janus init correctly then we can attach streaming plugin
                  this._attachStreamingPlugin({
                     onConnectWebRTCStream,
                  });
               },
               error: (error): void => {
                  onError(error);
                  logger.error(`Janus connection failed: ${error}`);
               },
            });
         },
      });
   };

   public reconnect = (): void => {
      if (this.streamingPlugin) {
         this.destroyConnection();
         // @ts-ignore
         this.initializeConnection({
            serverURL: this.serverURL,
            video: this.video,
            audio: this.audio,
            streamId: this.streamId,
            onConnectWebRTCStream: () => {
               this.connectStreamToVideo();
               this.connectStreamToAudio();
            },
         });
      }
   };

   public connectStreamToVideo = (): void => {
      // If a video stream exists, we will connect it to the HTML video element
      if (this.videoStream) {
         this.video.srcObject = new MediaStream([this.videoStream]);
      }
   };

   public connectStreamToAudio = (): void => {
      // If an audio stream exists, we will connect it to the HTML audio element
      if (this.audioStream) {
         this.audio.srcObject = new MediaStream([this.audioStream]);
      }
   };

   public watchStream = (): void => {
      this.streamingPlugin.send({
         message: {
            request: JANUS_COMMANDS.WATCH,
            id: this.streamId,
         },
      });
   };

   public setQuality = (quality: number): void => {
      this.streamingPlugin?.send({
         message: { request: 'configure', substream: quality },
      });
   };

   public setToMinimalQuality = (): void => {
      const MINIMAL_QUALITY = 0;

      this.setQuality(MINIMAL_QUALITY);
   };

   public hideVideo = (): void => {
      this.streamingPlugin.send({
         message: {
            request: 'configure',
            video: false,
         },
      });
   };

   public showVideo = (): void => {
      this.streamingPlugin.send({
         message: {
            request: 'configure',
            video: true,
         },
      });
   };

   destroyConnection = (): void => {
      this.streamingPlugin.detach();
   };
}

export const janusStreamController = new JanusStreamController();
