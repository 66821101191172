import { IBetsState } from '#/state/features/bets';

type RebetModel = {
   zIndexes: () => IBetsState['zIndexes'];
   totalBets: () => IBetsState['totalBets'];
   betsHistory: () => IBetsState['betsHistory'];
};
type RebetActions = {
   handleSavePreviousRoundBets: (bets: IBetsState) => void;
};

export class Rebet {
   private readonly rebetModel: RebetModel;
   private readonly rebetActions: RebetActions;

   constructor({
      rebetModel,
      rebetActions,
   }: {
      rebetModel: RebetModel;
      rebetActions: RebetActions;
   }) {
      this.rebetModel = rebetModel;
      this.rebetActions = rebetActions;
   }

   getRebetBets = (): IBetsState => {
      return {
         zIndexes: this.rebetModel.zIndexes(),
         totalBets: this.rebetModel.totalBets(),
         betsHistory: this.rebetModel.betsHistory(),
      };
   };

   resetRebet = (): void => {
      const { betsHistory, totalBets } = this.getRebetBets();
      const ifHasRebetBets = betsHistory.length > 0 && Object.values(totalBets).length > 0;

      if (ifHasRebetBets) {
         this.rebetActions.handleSavePreviousRoundBets({
            betsHistory: [],
            totalBets: {},
            zIndexes: {},
         });
      }
   };
}
