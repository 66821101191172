import { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

const SoundButton = styled.div`
   width: ${({ width }: { width: string }) => width ?? '100'}%;
`;

interface ITapSoundButton {
   children: ReactNode;
   playTapSound: () => void;
   width: string;
}

export const TapSoundButton: FC<ITapSoundButton> = ({ width, children, playTapSound }) => (
   <SoundButton width={width} onClick={playTapSound}>
      {children}
   </SoundButton>
);
