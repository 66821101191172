import { iterateObject } from 'helpers';

import { TCommonBet } from '#/state/features/bets';

type BetChipsType = TCommonBet['chips'];

export const mergeBetChips = (betChips1: BetChipsType, betChips2: BetChipsType) => {
   const mergedObject: BetChipsType = {};

   iterateObject(betChips1, (key, value) => {
      mergedObject[key] = value;
   });

   iterateObject(betChips2, (key, value) => {
      mergedObject[key] = mergedObject[key] ? (mergedObject[key] += value) : value;
   });

   return mergedObject;
};
