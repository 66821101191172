import { FC, memo, useEffect, useRef } from 'react';

import * as S from './RangeSlider.styles';

const useSliderProgressBar = ({ sliderRef, rangeLevel }): void => {
   useEffect((): void => {
      sliderRef.current.style.backgroundSize = `${rangeLevel}% 100%`;
      sliderRef.current.value = rangeLevel;
   }, [rangeLevel]);
};

interface IRangeSlider {
   onChange: (range: number) => void;
   playTapSound: () => void;
   previousVolume: number;
   rangeLevel?: number;
   testId?: string;
}

export const RangeSlider: FC<IRangeSlider> = memo(
   ({ rangeLevel, onChange, testId, playTapSound }) => {
      const sliderRef = useRef({ value: rangeLevel });

      useSliderProgressBar({ sliderRef, rangeLevel });

      const handleChangeVolumeLevel = ({ target }): void => {
         const volumeLevel = Number(target.value);
         onChange(volumeLevel);
      };

      return (
         <S.RangeSliderWrapper>
            <S.Input
               type="range"
               data-testid={`${testId}-slider`}
               ref={sliderRef}
               onClick={playTapSound}
               defaultValue={rangeLevel}
               onChange={handleChangeVolumeLevel}
            />
         </S.RangeSliderWrapper>
      );
   },
);

RangeSlider.displayName = 'RangeSlider';
