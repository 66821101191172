import { useRoundState } from 'services/api/roundStateApiSlice';

import { TDealerLoginEvent } from '@/common/services/sockets/useDealerLoginEvent';
import { useDealerRateState } from '@/common/modules/DealerRating/state';

export const useDealerLoginEvent = (): ((socket: TDealerLoginEvent) => void) => {
   const { handleSetDealerInfo } = useRoundState();
   const { handleSetLeavingFeedbackAvailable } = useDealerRateState();

   return ({ dealerName }) => {
      handleSetDealerInfo({ name: dealerName });
      handleSetLeavingFeedbackAvailable(false);
   };
};
