/* eslint-disable */
export const colorTimerBySeconds = {
   0: 'rgb(228, 29, 29)',
   1: 'rgb(228, 29, 29)',
   2: 'rgb(255, 122, 0)',
   3: 'rgb(255, 122, 0)',
   4: 'rgb(250, 162, 0)',
   5: 'rgb(250, 162, 0)',
   DEFAULT: 'rgb(41, 210, 58)',
} as const;

export const borderColorsBySeconds = {
   0: 'rgb(228, 29, 29)',
   1: 'rgb(228, 29, 29)',
   2: 'rgb(230, 110, 0)',
   3: 'rgb(230, 110, 0)',
   4: 'rgb(204, 145, 35)',
   5: 'rgb(204, 145, 35)',
   DEFAULT: 'rgb(36, 152, 48)',
} as const;

export const enum ETimerType {
   CIRCULAR = 'circular',
   HORIZONTAL = 'horizontal',
}
