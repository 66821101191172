import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { dealerRateSlice } from '@/common/modules/DealerRating/state';

import { TDealerRatingEvent } from '#/services/sockets/hooks/event-hooks/useSocketEvents/useDealerRatingEvent/schemes';

export const dealerRatingEventMiddleware = createListenerMiddleware();
export const dealerRatingEventAction = createAction<TDealerRatingEvent>('dealerRating');

dealerRatingEventMiddleware.startListening({
   actionCreator: dealerRatingEventAction,
   effect: ({ payload }, listenerApi) => {
      const { dispatch } = listenerApi;
      const { setDealerRate, setDealerId, setIsFeedbackEnabledOnCasinoGameTypeLevel } =
         dealerRateSlice.actions;
      const { dealerId, dealerRating, isFeedbackEnabledOnCasinoGameTypeLevel } = payload;

      dispatch(setDealerRate(dealerRating));
      dispatch(setDealerId(dealerId));
      dispatch(setIsFeedbackEnabledOnCasinoGameTypeLevel(isFeedbackEnabledOnCasinoGameTypeLevel));
   },
});
