import { FC, ReactNode } from 'react';

import * as S from './WinHistoryWithTableCapacityGroup.styles';

interface IWinHistoryWithTableCapacityGroupProps {
   children: ReactNode;
}
export const WinHistoryWithTableCapacityGroup: FC<IWinHistoryWithTableCapacityGroupProps> = ({
   children,
}) => <S.WinHistoryWithTableCapacityContainer>{children}</S.WinHistoryWithTableCapacityContainer>;
