import { ModalFeedbackForm } from '@/common/modules/DealerRating/components/ModalFeedbackForm';

import { rouletteEventEmitController } from '#/layout/hooks/SocketConnectionController';
import { useTranslation } from '#/translates';

export const DealerRatingModalView = () => {
   const { t } = useTranslation();

   return (
      <ModalFeedbackForm
         modalTitle={t('dealerRating.modalTitle')}
         feedbackCommentTitle={t('dealerRating.feedbackCommentTitle')}
         sendButtonTitle={t('dealerRating.sendButtonTitle')}
         handleSendFeedbackAction={rouletteEventEmitController.sendDealerFeedback}
         appreciationTitle={t('dealerRating.appreciationTitle')}
      />
   );
};
