import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IBetsState } from '#/state/features/bets';

import { useRebetSelectors } from './selectors';

const initialState: IBetsState = {
   totalBets: {},
   betsHistory: [],
   zIndexes: {},
};

export const rebetSlice = createSlice({
   name: 'rebet',
   initialState,
   reducers: {
      savePreviousRoundBets: (state, action: PayloadAction<IBetsState>): void => {
         const { totalBets, betsHistory, zIndexes } = action.payload;

         state.betsHistory = betsHistory;
         state.totalBets = totalBets;
         state.zIndexes = zIndexes;
      },
   },
});

export const useRebetState = () => {
   return { ...useRebetSelectors() };
};
