import { ETableSocketEvents } from '#/services/sockets/hooks/event-hooks/constants';
import { useAnnouncementsState } from '#/modules/Announcements/state';
import { ETrackingEvents } from '#/state/features/trackingEventsSlice/trackingEvents';

export const useConnectAnnouncementToSockets = () => {
   const { handleAddAnnouncementToPool } = useAnnouncementsState();

   const handleAddAnnouncementsEvents = (event: ETrackingEvents) => {
      const announcementsEvents = [
         ETableSocketEvents.StartRound,
         ETableSocketEvents.LastBetsStartTime,
         ETableSocketEvents.BettingEndTime,
         ETableSocketEvents.WinNumber,
      ];

      if (announcementsEvents.includes(event)) {
         handleAddAnnouncementToPool(event);
      }
   };

   return handleAddAnnouncementsEvents;
};
