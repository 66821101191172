// @ts-nocheck
import styled from 'styled-components/macro';

export const Wrapper = styled.div``;

const Gradient = styled.div`
   position: absolute;
   top: 0;
   height: 100%;
   padding: 15px;
   filter: drop-shadow(0px 0px 20px ${({ theme }) => theme.colors.black[50]});
`;

export const RightLine = styled(Gradient)`
   && {
      right: -4px;
      background: linear-gradient(
         90deg,
         rgba(0, 0, 0, 0) 0%,
         ${({ theme }) => theme.colors.black[50]} 100%
      );
   }
`;

export const LeftLine = styled(Gradient)`
   && {
      left: -4px;
      background: linear-gradient(
         -90deg,
         rgba(0, 0, 0, 0) 0%,
         ${({ theme }) => theme.colors.black[50]} 100%
      );
   }
`;
