import { memo } from 'react';

import { Tooltip, EControl } from '@/common/ui';
import { useSidebar } from '@/common/modules/Sidebar';
import { ERouletteSidebar } from '@/common/entities/Sidebar';

import { useTranslation } from '#/translates';

import { Control } from '../ControlWithTapSound';

export const FavouriteControl = memo(() => {
   const { t } = useTranslation();
   const { sidebarType, handleSetSidebarType } = useSidebar();

   return (
      <Tooltip position="right" text={t('sidebars.favourite.title')}>
         <Control
            isActive={sidebarType === ERouletteSidebar.Favourite}
            variant={EControl.Favourite}
            dataTestId="favourite-control"
            onClick={() => handleSetSidebarType(ERouletteSidebar.Favourite)}
         />
      </Tooltip>
   );
});

FavouriteControl.displayName = 'FavouriteControl';
