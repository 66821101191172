// @ts-nocheck
import styled, { keyframes } from 'styled-components';

export const SeatSVG = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ size }) => size.width};
  height: ${({ size }) => size.height};
`;

export const SVGAnimatedPlace = styled.g`
  opacity: 0;
  transition: all 0.6s ease;

  &:hover {
    opacity: 1;
  }
`;

export const SeatSVGElementsContainer = styled.g`
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'normal' : 'pointer')};
`;

export const opacityAnimation = keyframes`
    from {
      opacity: 0.2;
    }

    to {
      opacity: 1;
    }
`;
