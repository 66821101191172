// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const LimitControlsWrapper = styled.div`
   display: flex;
   flex-wrap: wrap;
   position: relative;
`;

export const LimitHeader = styled.div`
   display: flex;
   margin-top: 30px;
   justify-content: space-between;
   margin-bottom: 15px;
`;

export const LimitLabel = styled.div`
   color: ${({ theme }) => theme.colors?.white};
   font-size: var(--autoplay-label-fz);

   ${orientation.portrait`
      --autoplay-label-fz: 16px;
      font-weight: 600;
   `}

   ${orientation.mobileLandscape`
      --autoplay-label-fz: 16px;
      font-weight: 600;
   `}
`;

export const LimitInput = styled.input`
   --chat-message-input-height: 45px;
   --chat-message-input-fz: 18px;
   --chat-message-input-pl: 30px;

   will-change: transform;
   height: var(--chat-message-input-height);
   width: 100%;
   padding-right: 9px;
   background: transparent;
   padding-left: ${({ valueLeftPadding }) =>
      `calc(var(--chat-message-input-pl) + ${valueLeftPadding}px)`};
   color: ${({ theme }) => theme.colors?.white};
   border: 2px solid ${({ theme }) => theme.colors.silverHue[40]};
   font-size: var(--chat-message-input-fz);
   font-weight: 500;

   &:focus-visible {
      outline: unset;
   }
   &::-webkit-inner-spin-button {
      display: none;
   }
`;

export const CurrencyIcon = styled.span`
   &::before {
      content: ${({ currency }) => `"${currency}"`};
      position: absolute;
      top: 14px;
      left: 16px;
   }
`;

export const CloseIconWrapper = styled.div`
   position: absolute;
   top: 4px;
   right: 0;
   display: flex;
   align-items: center;
`;
