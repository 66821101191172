import { FC } from 'react';

import { TNullable } from '@/common/types';

import * as S from './BetConfirmedNotificationView.styles';

type Props = {
   title: string;
   value?: TNullable<string>;
};

export const BetConfirmedNotificationView: FC<Props> = ({ title, value = null }) => (
   <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.Value>{value}</S.Value>
   </S.Wrapper>
);
