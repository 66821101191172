import { TNullable } from '@/common/types';

import { useTranslation } from '#/translates';

import { SwipeUpView } from '../SwipeUpView';

import { useSwipeUp } from './hooks';
import { useSwipeDirection } from './hooks/useSwipeDirection';
import * as S from './Android.styles';

export const Android = (): TNullable<JSX.Element> => {
   const { t } = useTranslation();

   const { isSwipeUpOpen, handleCloseSwipeUp } = useSwipeUp();

   const toFullScreen = (): void => {
      const documentIsNotInFullscreenMode = !document.fullscreenElement;

      if (documentIsNotInFullscreenMode) {
         document.documentElement.requestFullscreen().catch(() => {
            handleCloseSwipeUp();
         });
      }
   };

   const touchEvents = useSwipeDirection({
      onTopSwipeCallback: (): void => {
         toFullScreen();
         handleCloseSwipeUp();
      },
   });

   if (!isSwipeUpOpen) {
      return null;
   }

   return (
      <S.SwipeUpContainer {...touchEvents}>
         <SwipeUpView title={t('swipe.title')} subtitle={t('swipe.subtitle')} />
         <S.SwipeUpClose onClick={handleCloseSwipeUp} />
      </S.SwipeUpContainer>
   );
};
