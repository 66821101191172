const raceTrackNumbers: number[] = [
   0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33, 1, 20, 14,
   31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26,
];

export const getNearElements = ({ currentNumber, rangeLevel }): number[] => {
   const nearElements: number[] = [currentNumber];
   const raceTrackNumbersCount: number = raceTrackNumbers.length;
   const currentNumberIndex: number = raceTrackNumbers.indexOf(currentNumber);

   const insideElementStartId = 48;
   const isInsideElement = currentNumber > insideElementStartId;

   if (rangeLevel === 0 || isInsideElement) {
      return nearElements;
   }

   for (let i = 1; i <= rangeLevel; i++) {
      if (currentNumberIndex - i < 0) {
         nearElements.push(
            raceTrackNumbers[raceTrackNumbersCount - Math.abs(currentNumberIndex - i)],
         );
      } else {
         const prevNumber: number = raceTrackNumbers[currentNumberIndex - i];
         nearElements.push(prevNumber);
      }

      if (currentNumberIndex + i === raceTrackNumbersCount) {
         nearElements.push(raceTrackNumbers[0]);
      } else if (currentNumberIndex + i > raceTrackNumbersCount) {
         nearElements.push(raceTrackNumbers[rangeLevel - i + 1]);
      } else {
         const nextNumber: number = raceTrackNumbers[currentNumberIndex + i];
         nearElements.push(nextNumber);
      }
   }

   return nearElements;
};

interface IActiveElementsIndexes {
   classList: DOMTokenList;
   rangeLevel: number;
}
export const getActiveElementsIndexes = ({
   classList,
   rangeLevel,
}: IActiveElementsIndexes): number[] => {
   const elementClassName = classList[0];
   const currentNumber = parseInt(elementClassName.replace(/\D/g, ''), 10);

   return getNearElements({ currentNumber, rangeLevel });
};
