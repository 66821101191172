import { TValidError, TValidException } from './types';

const isString = (value: unknown): value is string => {
   return typeof value === 'string';
};

const isObject = (value: unknown): value is object => {
   return typeof value === 'object';
};

export const isValidNetworkError = (response: unknown): response is { message: string } => {
   return (
      isObject(response) && response !== null && 'message' in response && isString(response.message)
   );
};

// now we are getting exceptions and errors in same data structure
const isValidExceptionOrError = (response: unknown): response is TValidError | TValidException => {
   return (
      isObject(response) &&
      'error' in response &&
      isObject(response.error) &&
      'message' in response.error &&
      'details' in response.error &&
      isObject(response.error.details) &&
      'message' in response.error.details &&
      isString(response.error.details.message)
   );
};

export const isValidError = (response: unknown): response is TValidError => {
   return isValidExceptionOrError(response);
};

export const isValidException = (response: unknown): response is TValidException => {
   return isValidExceptionOrError(response);
};

export const getErrorMessage = (error): string => {
   return error.exception ?? error.error;
};
