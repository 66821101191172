// @ts-nocheck
import styled from 'styled-components/macro';

import { aspectRationMixin } from '@/common/ui/theme/mixins';

export const DesktopVideoContainer = styled.div`
   height: 100vh;
   margin: 0 auto;
   ${aspectRationMixin({ width: 16, height: 9 })}

   video {
      max-width: 100vw;
      width: 100%;
      object-fit: cover;
      ${aspectRationMixin({ width: 16, height: 9 })}
   }
`;
