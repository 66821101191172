import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '../assets';
import { ENotificationIcons } from '../constants';

const iconsMap = {
   [ENotificationIcons.Info]: <InfoIcon />,
   [ENotificationIcons.Error]: <ErrorIcon />,
   [ENotificationIcons.Success]: <SuccessIcon />,
   [ENotificationIcons.Warning]: <WarningIcon />,
};

export const NotificationIcon = ({ type }: { type: ENotificationIcons }) => iconsMap[type];
