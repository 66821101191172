import { formatCurrency } from 'helpers';
import { useEffect } from 'react';

import { useAutoplay } from '#/modules/Autoplay/hooks';

export const useSetEmptyValue = (inputValue, setInput) => {
   const { autoplay } = useAutoplay();

   const lossLimit = autoplay.getLossLimit();

   useEffect(() => {
      if (!inputValue.length && lossLimit) {
         setInput(formatCurrency(lossLimit));
      }
   }, [lossLimit]);
};
