// @ts-nocheck
/* eslint no-nested-ternary: 0 */
import styled, { css } from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

import { rotatedText, smallText } from './helpers';

export const Typography = styled.div`
   ${({ uppercase }) =>
      uppercase &&
      css`
         text-transform: uppercase;
      `};

   ${({ lowercase }) =>
      lowercase &&
      css`
         text-transform: lowercase;
      `};
`;

export const Title = styled(Typography)`
   --betting-map-number-color: ${({ numberColor }) => {
      const colorAdapter = numberColor === 'green' ? 'zero' : numberColor;
      return css`var(--${colorAdapter}-betting-map-number-color)`;
   }};
   color: var(--betting-map-number-color, ${({ color, theme }) => color ?? theme.colors?.white});
   position: absolute;
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: 500;
   text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
   pointer-events: none;

   ${({ $cellId }) => css`
      font-size: ${smallText($cellId)
         ? 'var(--betting-map-cell-small-fz)'
         : 'var(--betting-map-cell-normal-fz)'};
      writing-mode: ${rotatedText($cellId) ? 'vertical-lr' : 'normal'};
      transform: ${rotatedText($cellId) ? 'rotate(-180deg)' : 'none'};
   `}

   ${orientation.portrait`
      ${({ $cellId }) => css`
         height: auto;
         white-space: nowrap;
         writing-mode: unset;
         transform: ${smallText($cellId) && !rotatedText($cellId)
            ? 'rotate(90deg)'
            : rotatedText($cellId)
              ? 'rotate(0)'
              : 'none'};
      `}
   `}

  ${({ $isPortrait, $cellId }) =>
      $isPortrait &&
      css`
         height: auto;
         white-space: nowrap;
         font-size: ${smallText($cellId) ? '16px' : '26px'};
         writing-mode: unset;
         transform: ${smallText($cellId) && !rotatedText($cellId)
            ? 'rotate(90deg)'
            : rotatedText($cellId)
              ? 'rotate(0)'
              : 'none'};
      `}
`;
