// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { isHoverSupports, orientation } from '@/common/ui/theme/mixins';

export const ButtonWrapper = styled.div`
   --statistics-control-gap: 15px;

   display: flex;
   margin-bottom: 35px;
   justify-content: space-between;
   width: 100%;
   gap: var(--statistics-control-gap);

   ${orientation.mobileLandscape`
      --statistics-control-gap: 50px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      > div {
         width: 100%;
         max-width: 100%;
      }
   `}

   ${orientation.portrait`
      gap: 10px;
   `}

   ${orientation.mobileLandscape`
      gap: 45px;
      margin-bottom: 20px;
   `}
`;

export const Button = styled.div`
   border: 2px solid ${({ theme }) => theme.colors.grey};
   color: ${({ theme }) => theme.colors.mediumGrey};
   min-width: 50%;
   display: flex;
   align-content: center;
   justify-content: center;
   font-size: var(--statistics-control-fz);
   cursor: pointer;
   transition: 0.2s;
   padding: var(--statistics-control-padding);
   align-items: center;
   font-weight: 600;

   ${orientation.mobileLandscape`
      --statistics-control-fz: 16px;
      --statistics-control-padding: 5px;
      height: 35px;
   `}

   ${isHoverSupports`
      &:hover {
         border-color: ${({ theme }) => theme.colors?.main};
         color: ${({ theme }) => theme.colors?.white};
         background: ${({ theme }) => theme.bg.activePrimary};
      }
   `}

   ${({ isSelected }) =>
      isSelected &&
      css`
         border-color: ${({ theme }) => theme.colors?.main};
         color: ${({ theme }) => theme.colors?.white};
         box-shadow: ${({ theme }) => `0 0 10px ${theme.colors?.main}CC`};
         background: ${({ theme }) => theme.bg.activePrimary};
      `};
`;
