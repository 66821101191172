// @ts-nocheck
import styled from 'styled-components';

import { getBackgroundColorByTimerTick } from '../../../../utils';

export const CircularCountDownProgressBar = styled.circle`
   fill: transparent;
   stroke-width: 10;
   stroke-linecap: round;
   transform: rotate(-90deg) translate3d(0, 0px, 0px) !important;
   transform-origin: center;
   stroke: ${({ tick }) => getBackgroundColorByTimerTick(tick)};
   transition: stroke 1s linear;
`;

export const CircularCountDownProgressBarContainer = styled.svg`
   position: absolute;
   overflow: visible;
   left: 0;
   top: 0;
   width: var(--circle-timer-size);
   height: var(--circle-timer-size);
`;
