// @ts-nocheck
import styled from 'styled-components/macro';

import { isHoverSupports } from '@/common/ui/theme/mixins';
import { isMobileDevice } from '@/common/utils/checkDevice';

export const StraightUpBet = styled.div`
   display: flex;
   background: linear-gradient(0deg, #141414, #141414), rgba(66, 66, 66, 0.7);
   height: 42px;
   align-items: center;
   justify-content: center;
   max-width: ${isMobileDevice() ? '206px' : '212px'};
   cursor: pointer;
   min-width: calc(50% - 10px);
   font-weight: 500;

   ${isHoverSupports`
     &:hover {
         border: 1px solid ${({ theme }) => theme.colors?.main};
     }
   `}

   &:active {
      background: ${({ theme }) => theme.bg.activePrimary};
      border: 1px solid ${({ theme }) => theme.colors?.main};
   }
`;

export const StraightUpBetWrapper = styled.div`
   margin-top: 20px;
   display: flex;
   flex-wrap: wrap;
   gap: 10px;
   margin-bottom: 20px;
`;
