// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '../../../theme/mixins';
import { StyledText } from '../../GameInfoView.styles';

export const Time = styled(StyledText)`
  min-width: 58px;
  text-align: left;

  ${orientation.mobileLandscape`
       min-width: 42px;
   `};

  ${orientation.portrait`
      min-width: 42px;
   `};
`;
