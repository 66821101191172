import { IPlayTapSound } from '@/common/entities';

import { withTapSound } from '#/hocs/withTapSound';
import { EStatisticsTab } from '#/modules/Statistics/state';
import { useTranslation } from '#/translates';

import * as S from './StatisticsButtons.styles';

const StatisticsButtons = ({
   statisticType,
   setStatisticType,
   playTapSound,
}: {
   statisticType: EStatisticsTab;
   setStatisticType: (statistics: EStatisticsTab) => void;
} & IPlayTapSound) => {
   const { t } = useTranslation();

   return (
      <S.ButtonWrapper>
         <S.Button
            isSelected={statisticType === EStatisticsTab.STATISTICS}
            onClick={() => {
               playTapSound();
               setStatisticType(EStatisticsTab.STATISTICS);
            }}
         >
            {t('sidebars.statistics.title')}
         </S.Button>
         <S.Button
            isSelected={statisticType === EStatisticsTab.LAST_RESULT}
            onClick={() => {
               playTapSound();
               setStatisticType(EStatisticsTab.LAST_RESULT);
            }}
         >
            {t('sidebars.statistics.lastResults')}
         </S.Button>
      </S.ButtonWrapper>
   );
};

export const StatisticsButtonsWithTapSound = withTapSound(StatisticsButtons);
