import { callBetsConfig } from 'core/config';

import { TCallBet, TCommonBet, ETypeBet } from '#/state/features/bets';

export type CallBetType = ETypeBet.JEU_ZERO | ETypeBet.ORPHELINS | ETypeBet.TIERS | ETypeBet.VOISIN;

export interface ICallBetCommand {
   amount: TCommonBet['amount'];
   type: CallBetType;
}

export class CallBetCommand {
   type: CallBetType;
   amount: TCommonBet['amount'];
   extractedCommand: TCommonBet[];
   numberOfChips = 1;

   idx = {
      [ETypeBet.ORPHELINS]: 157,
      [ETypeBet.JEU_ZERO]: 158,
      [ETypeBet.VOISIN]: 159,
      [ETypeBet.TIERS]: 160,
   };

   constructor({ type, amount }: ICallBetCommand) {
      this.type = type;
      this.amount = amount;
      this.extractedCommand = this.extractCommand();
   }

   execute = (): TCallBet => ({
      id: this.idx[this.type],
      amount: this.amount * callBetsConfig[this.type].length,
      type: this.type,
      extractedCommand: this.extractedCommand,
   });

   private isVoisin() {
      return this.type === ETypeBet.VOISIN;
   }

   private sumVoisinSameBets = (bets: TCommonBet[]): TCommonBet[] => {
      const uniqueBets = new Map<number, TCommonBet>();

      for (const bet of bets) {
         const { id, amount } = bet;
         const savedBet = uniqueBets.get(id);

         if (savedBet) {
            const updatedAmount = savedBet.amount + amount;
            const updatedBet = {
               ...savedBet,
               amount: updatedAmount,
               chips: {
                  [updatedAmount]: 1,
               },
            };
            uniqueBets.set(id, updatedBet);
         } else {
            uniqueBets.set(id, bet);
         }
      }

      return Array.from(uniqueBets.values());
   };

   extractCommand = () => {
      const bets = callBetsConfig[this.type].map(({ id, type, numbers }) => ({
         id,
         type,
         numbers,
         amount: this.amount,
         chips: {
            [this.amount]: this.numberOfChips,
         },
      }));

      return this.isVoisin() ? this.sumVoisinSameBets(bets) : bets;
   };
}
