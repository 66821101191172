import { TBetType, TDoubleBet, TExcludeBetFromBetList, TTotalBets } from '../../types';
import { isRangeStraightBetType, isSpecialBetType, isCallBetType } from '../../type-guards';

interface IParams {
   betsHistory: TBetType[];
   newBets: TExcludeBetFromBetList<TDoubleBet>[];
   totalBets: TTotalBets;
}

export const addNewBets = ({
   totalBets,
   betsHistory,
   newBets,
}: IParams): [TTotalBets, TBetType[]] => {
   const copyTotalBets = { ...totalBets };
   const copyBetsHistory = [...betsHistory];

   newBets?.forEach((command) => {
      const isRangeStraightBet = isRangeStraightBetType(command);
      const isSpecialType = isSpecialBetType(command);
      const isSideBet = isCallBetType(command);

      if (isRangeStraightBet || isSpecialType || isSideBet) {
         copyBetsHistory.push(command);

         command.extractedCommand.forEach(({ id, amount }) => {
            copyTotalBets[id] = copyTotalBets[id] ? (copyTotalBets[id] += amount) : amount;
         });
      } else {
         const { id, amount } = command;

         copyBetsHistory.push(command);
         copyTotalBets[id] = copyTotalBets[id] ? (copyTotalBets[id] += amount) : amount;
      }
   });

   return [copyTotalBets, copyBetsHistory];
};
