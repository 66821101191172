import { iterateObject } from 'helpers';

import { TTotalBets } from '#/state/features/bets';

export const partialDoubleTotalBets = (
   totalBets: TTotalBets,
   updatedTotalBetsValues: TTotalBets,
): TTotalBets => {
   const resultTotalBets = { ...totalBets };

   iterateObject(resultTotalBets, (key) => {
      if (updatedTotalBetsValues[key]) {
         resultTotalBets[key] += updatedTotalBetsValues[key];
      }
   });

   return resultTotalBets;
};
