import { BettingPanelGroup } from '#/layout/componentsGroup';
import { useCheckBettingTime } from '#/modules/ChipTray/hooks';
import {
   AutoplayControl,
   StatisticsControl,
   SwitchTrackControl,
   CollapseBettingAreaControl,
} from '#/modules/Control/components';

import * as S from './RightColumn.styles';

export const RightColumn = () => {
   const isBettingTime = useCheckBettingTime();

   return (
      <S.RightColumnContainer>
         <S.CollapseTrackControl>
            <CollapseBettingAreaControl />
         </S.CollapseTrackControl>
         <S.BottomContainer>
            <S.BettingPanelGroupContainer isDisabled={!isBettingTime} isVisible={isBettingTime}>
               <BettingPanelGroup />
            </S.BettingPanelGroupContainer>
            <S.RightControlsContainer>
               <AutoplayControl />
               <StatisticsControl />
               <SwitchTrackControl />
            </S.RightControlsContainer>
         </S.BottomContainer>
      </S.RightColumnContainer>
   );
};
