import { blackConfig } from '../black';
import { columnConfig } from '../column';
import { cornerConfig } from '../corners';
import { dozenConfig } from '../dozen';
import { evenConfig } from '../even';
import { highConfig } from '../high';
import { lineConfig } from '../line';
import { lowConfig } from '../low';
import { oddConfig } from '../odd';
import { redConfig } from '../red';
import { splitConfig } from '../split';
import { straightConfig } from '../straight';
import { streetConfig } from '../street';

export const mapConfigCommonBets = [
   ...straightConfig.getConfig(),
   ...dozenConfig.getConfig(),
   ...lowConfig.getConfig(),
   ...evenConfig.getConfig(),
   ...redConfig.getConfig(),
   ...blackConfig.getConfig(),
   ...oddConfig.getConfig(),
   ...highConfig.getConfig(),
   ...columnConfig.getConfig(),
];

export const mapConfigSpecialBets = [
   ...cornerConfig.getConfig(),
   ...streetConfig.getConfig(),
   ...lineConfig.getConfig(),
   ...splitConfig.getConfig(),
];
