import { EModalNotificationType } from '@/common/ui/ModalNotification/constants';

import { useTranslation } from '#/translates';

import { useLobbyRedirect } from '../../hooks';
import { Overlay } from '../Overlay';

export const InactiveGameTableOverlay = () => {
   const { t } = useTranslation();
   const handleLobbyRedirect = useLobbyRedirect();

   return (
      <Overlay
         type={EModalNotificationType.Warning}
         title={t('inactiveGameTableOverlay.title')}
         actionButton={{
            title: t('inactiveGameTableOverlay.action'),
            onClick: handleLobbyRedirect,
         }}
      />
   );
};
