import { TNullable } from '@/common/types';
import { logger } from '@/common/utils';

import { betsCreatorByPlaceTrack } from '#/core/betsCreator';
import { THandleShowMaxBetTooltip, useLimitTooltipActions } from '#/modules/LimitTooltip';
import { useTableCapacity } from '#/modules/TableCapacity/state';
import { Command } from '#/modules/Track/BettingMap/commands';
import { ETypeBet, ITrackComponentState } from '#/state/features/bets';

import { IDropChip } from './types';

export const useDropChip = ({
   trackStore,
   handleShowMaxBetTooltip,
}: {
   trackStore: () => ITrackComponentState;
   handleShowMaxBetTooltip: TNullable<THandleShowMaxBetTooltip>;
}) => {
   const { placeTrack } = trackStore();
   const betsCreator = betsCreatorByPlaceTrack(placeTrack);
   const { handleSetMaxLimitTooltip } = useLimitTooltipActions();
   const { betLimitsByType } = useTableCapacity();

   const _checkPossibilityMakeBet = ({
      firstCommandAmount = 0,
      secondCommandAmount = 0,
      maxLimit,
   }: {
      firstCommandAmount: number;
      secondCommandAmount: number;
      maxLimit: number;
   }): boolean => {
      const sumCommandsAmount = firstCommandAmount + secondCommandAmount;

      return sumCommandsAmount <= maxLimit;
   };

   const _showMaxLimitTooltip = (event) => {
      handleShowMaxBetTooltip?.(event);
      handleSetMaxLimitTooltip();
   };

   return ({ draggableCommand, droppableCommand, event }: IDropChip): void => {
      try {
         const { betId: droppableBetId, type, highlightCells: numbers } = droppableCommand;
         const { amount, betId: draggableBetId } = draggableCommand;
         const betLimits = betLimitsByType[type];

         if (!betLimits.max) {
            logger.error({ betType: type, betId: droppableBetId, betLimitsByType });
         }

         const { max: maxLimitForDroppableCommand } = betLimits;

         const makeBet = () => {
            const newCommand = new Command({
               id: droppableBetId,
               type: type as ETypeBet,
               numbers,
               amount,
            });

            betsCreator.moveBet({
               from: draggableBetId,
               bet: newCommand.execute(),
            });
         };

         const ifCanMakeBet = _checkPossibilityMakeBet({
            firstCommandAmount: draggableCommand.amount,
            secondCommandAmount: droppableCommand.amount || 0,
            maxLimit: maxLimitForDroppableCommand,
         });

         ifCanMakeBet ? makeBet() : _showMaxLimitTooltip(event);
      } catch (error) {
         logger.error(error);
      }
   };
};
