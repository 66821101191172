import { isGameLoadedSuccessful } from '@/common/state/gameState/utils';
import { logger, pipe } from '@/common/utils';

import { Balance } from '#/core/balance';
import { BetLimits } from '#/core/betLimits';
import { Bets } from '#/core/bets';
import { Rebet } from '#/core/rebet';
import { sumBetsById } from '#/services/api/betsApiSlice/helpers';
import { extractDoubleBet } from '#/services/api/betsApiSlice/helpers/extractDoubleBet';
import { extractSpecialBet } from '#/services/api/betsApiSlice/helpers/extractSpecialBet';
import {
   TBetType,
   TCallBet,
   IChipsZIndexes,
   TCommonBet,
   TTotalBets,
   calculateTotalBets,
   TDoubleBet,
} from '#/state/features/bets';
import {
   isDoubleBetType,
   isCallBetType,
   isSpecialBetType,
} from '#/state/features/bets/type-guards';
import { Store } from '#/state/types';
import { CallBetType } from '#/modules/Track/BettingMap/commands/CallBetCommand';
import { RangeCommandType } from '#/modules/Track/BettingMap/commands/RangeStraightCommand';
import { SpecialCommandOptions } from '#/modules/Track/BettingMap/commands/special/types';

import { partialDoubleTotalBets } from '../bets';

import { AbstractBetsCreator } from './AbstractBetsCreator';

// todo: rename extractedCommand to extractedBets
export class BetsCreator extends AbstractBetsCreator {
   private balance: Balance;
   private bets: Bets;
   private limits: BetLimits;
   private rebet: Rebet;

   constructor({
      initBets,
      initBalance,
      initBetLimits,
      initRebet,
   }: {
      initBets: (store: Store) => Bets;
      initBalance: (store: Store) => Balance;
      initBetLimits: (store: Store) => BetLimits;
      initRebet: (store: Store) => Rebet;
   }) {
      super({});

      import('state').then(({ store }) => {
         store.subscribe(() => {
            const gameStatus = store.getState().gameState.gameStatus;

            if (isGameLoadedSuccessful(gameStatus)) {
               this.bets = initBets(store);
               this.balance = initBalance(store);
               this.rebet = initRebet(store);
               this.limits = initBetLimits(store);
            }
         });
      });
   }

   private showNotEnoughBalanceLog = () => {
      if (import.meta.env.DEV) {
         logger.log('Not enough balance');
      }
   };

   private saveValidatedBets = (bets: TBetType[]): void => {
      const totalAmountBets = this.calculateTotalAmountBets(bets);

      this.bets?.addBets(bets);
      this.balance?.decreaseBalance(totalAmountBets);
   };

   private validateBets = (bets: TBetType[]) => {
      const validatedBetsByLimits = this.limits?.validateBetsByLimits({
         bets,
         totalBets: this.bets?.getTotalBets(),
      });

      const validatedBetsByPlayerBalance =
         this.balance.validateBetsByPlayerBalance(validatedBetsByLimits);

      if (!validatedBetsByPlayerBalance) {
         this.limits.hideLimitTooltip();
         this.showNotEnoughBalanceLog();
         return;
      }

      this.limits.handleBetLimitTooltips(validatedBetsByLimits);
      return this.limits.filterNonNullBets(validatedBetsByLimits);
   };

   public getBets = () => {
      return {
         totalBets: this.bets?.getTotalBets(),
         zIndexes: this.bets?.getZIndexes(),
         betsHistory: this.bets?.getBetsHistory(),
      };
   };

   public getCallBets = () => {
      const betList = this.bets?.getBetsHistory();
      return betList.filter(isCallBetType);
   };

   public hasPlacedBets = () => {
      const betsHistory = this.getBets()?.betsHistory ?? [];

      return betsHistory.length > 0;
   };

   public makeCommonBet = (options: Omit<TCommonBet, 'chips'>): void => {
      this.rebet?.resetRebet();
      const bet = this.createCommonBet(options);
      const validBets = this.validateBets([bet]);

      if (!validBets) {
         return;
      }

      this.saveValidatedBets(validBets);
   };

   public makeRangeStraightBets = ({ numbers, amount, title = '' }: RangeCommandType): void => {
      this.rebet?.resetRebet();
      const bet = this.createRangeStraightBet({ numbers, amount, title });
      const validBets = this.validateBets(bet.extractedCommand);

      if (!validBets) {
         return;
      }

      this.saveValidatedBets(validBets);
   };

   public makeCallBet = (options: { type: CallBetType; amount: TCommonBet['amount'] }): void => {
      this.rebet?.resetRebet();
      const callBet = this.createCallBet(options);
      const totalBets = this.bets?.getTotalBets();
      const validExtractedBetsByLimits = this.limits?.validateCallBetByLimits({
         callBet,
         totalBets,
      });
      const validExtractedBetsByPlayerBalance = this.balance.validateBetsByPlayerBalance(
         validExtractedBetsByLimits,
      );

      if (!validExtractedBetsByPlayerBalance) {
         this.limits.hideLimitTooltip();
         this.showNotEnoughBalanceLog();
         return;
      }

      this.limits.handleBetLimitTooltips(validExtractedBetsByLimits);
      const extractedBets = this.limits.filterNonNullBets(validExtractedBetsByLimits);
      const validCallBet = {
         ...callBet,
         amount: this.calculateTotalAmountBets(extractedBets),
         extractedCommand: extractedBets,
      } satisfies TCallBet;
      this.saveValidatedBets([validCallBet]);
   };

   public makeSpecialBet = (betOptions: SpecialCommandOptions): void => {
      this.rebet?.resetRebet();
      const bet = this.createSpecialBet(betOptions);
      const validBets = this.validateBets([bet]);

      if (!validBets) {
         return;
      }

      this.saveValidatedBets(validBets);
   };

   public doubleBets = (): void => {
      this.rebet?.resetRebet();
      const bets = this.bets?.getBetsHistory();
      const totalBets = this.bets?.getTotalBets();
      const transformBets: (TCallBet | TCommonBet)[] = pipe(
         extractDoubleBet,
         extractSpecialBet,
         sumBetsById,
      )(bets);
      const totalAmountDoubleBet = this.calculateTotalAmountBets(transformBets);

      if (!this.balance.canPlaceBets(totalAmountDoubleBet)) {
         this.showNotEnoughBalanceLog();
         return;
      }

      const validBets = this.limits?.validateDoubleBets({
         bets: transformBets,
         totalBets,
      });

      if (validBets.updatedBets.length) {
         const totalAmountBets = this.calculateTotalAmountBets(validBets.updatedBets);
         const doubleBet = {
            ...this.createdDoubleBet(),
            partialDoubled: [...validBets.updatedBets],
            partialTotalBets: validBets.updatedTotalBets,
            amount: totalAmountBets,
         } satisfies TDoubleBet;
         const updatedTotalBets = partialDoubleTotalBets(totalBets, validBets.updatedTotalBets);
         this.bets?.doubleBets([updatedTotalBets, [...bets, doubleBet]]);
         this.balance?.decreaseBalance(totalAmountBets);
      }
   };

   public removeLastBet = (): void => {
      const betsHistory = this.bets?.getBetsHistory() ?? [];
      const lastBet = betsHistory[betsHistory.length - 1];

      const LAST_BET_DEFAULT_VALUE = 0;

      if (!lastBet) {
         return;
      }

      let lastBetAmount = LAST_BET_DEFAULT_VALUE;
      const isSpecialBet = isSpecialBetType(lastBet);
      const isDoubleBet = isDoubleBetType(lastBet);

      if (isSpecialBet) {
         lastBetAmount = lastBet.extractedCommand
            ? this.calculateTotalAmountBets(lastBet.extractedCommand)
            : LAST_BET_DEFAULT_VALUE;
      } else if (isDoubleBet) {
         lastBetAmount = lastBet.amount ? lastBet.amount : LAST_BET_DEFAULT_VALUE;
      } else {
         lastBetAmount = lastBet.amount ? lastBet.amount : LAST_BET_DEFAULT_VALUE;
      }

      this.bets?.undoLastBet();
      this.balance?.increaseBalance(lastBetAmount);
   };

   public resetBets = (): void => {
      this.bets?.resetBets();
      this.balance?.resetBalance();
   };

   public resetBetsOnCancelRound = (): void => {
      this.bets?.resetBetsOnCancelRound();
      this.balance?.resetBalance();
   };

   public removeBetById = (id: number): void => {
      const commandAmount = this.bets?.getTotalBets()?.[id] ?? null;

      if (!commandAmount) {
         return;
      }

      this.bets?.removeBetById(id);
      this.balance?.increaseBalance(commandAmount);
   };

   public saveLastBets = (bets: (TCallBet | TCommonBet)[]): void => {
      this.rebet?.resetRebet();
      const validBets = this.validateBets(bets);

      if (!validBets) {
         return;
      }

      this.bets?.addBetsFromOutside({
         betsHistory: validBets,
         totalBets: calculateTotalBets(validBets),
         zIndexes: validBets.reduce((total, bet) => {
            total[bet.id] = total[bet.id] ? (total[bet.id] += 1) : 1;
            return total;
         }, {}),
      });
      const totalAmountValidatedBets = this.calculateTotalAmountBets(validBets);
      this.balance?.decreaseBalance(totalAmountValidatedBets);
   };

   public saveBetsFromOutside = (bets: {
      betsHistory: TBetType[];
      totalBets: TTotalBets;
      zIndexes: IChipsZIndexes;
   }) => {
      this.rebet?.resetRebet();
      const validBets = this.validateBets(bets.betsHistory);

      if (!validBets) {
         return false;
      }

      this.bets?.addBetsFromOutside({
         ...bets,
         betsHistory: validBets,
         totalBets: calculateTotalBets(validBets),
      });
      const totalAmountValidatedBets = this.calculateTotalAmountBets(validBets);
      this.balance?.decreaseBalance(totalAmountValidatedBets);

      return true;
   };

   public rebetBets = (): void => {
      const rebetBets = this.rebet?.getRebetBets();
      const currentBalance = this.balance?.getBalance();
      const rebetBetsTotalAmount = this.calculateTotalAmountBets(rebetBets.betsHistory);
      const isBalancePositive = this.balance?.isBalancePositive(
         currentBalance - rebetBetsTotalAmount,
      );

      if (isBalancePositive) {
         this.bets?.addBetsFromOutside(rebetBets);
         this.balance?.decreaseBalance(rebetBetsTotalAmount);
         this.rebet?.resetRebet();
      } else {
         this.limits?.showMaxLimitTooltip();
      }
   };

   public rebetAutoplayBets = (): boolean => {
      const rebetBets = this.rebet?.getRebetBets();
      const rebetBetsTotalAmount = this.calculateTotalAmountBets(rebetBets.betsHistory);
      const currentBalance = this.balance?.getBalance();
      const updatedBalance = currentBalance - rebetBetsTotalAmount;
      const isBalancePositive = this.balance?.isBalancePositive(
         currentBalance - rebetBetsTotalAmount,
      );

      if (isBalancePositive) {
         this.limits?.validateBetsByMinLimit({
            bets: rebetBets.betsHistory,
            totalBets: rebetBets.totalBets,
         });
         this.bets?.addBetsFromOutside(rebetBets);
         this.balance?.updateBalanceByAutoplay(updatedBalance);
      } else {
         this.limits?.showMaxLimitTooltip();
      }

      return isBalancePositive;
   };

   public moveBet = ({ bet, from }: { bet: TCommonBet; from: number }): void => {
      this.bets?.removeBetById(from);
      this.bets?.addBets([bet]);
   };
}
