import { TNullable } from '@/common/types';

import { betsCreator } from '#/core/betsCreator';
import { betConfirmedSlice } from '#/modules/BetConfirmedNotification';
import { TCommonBet } from '#/state/features/bets/types';
import { TRootState, Store } from '#/state/types';

import { IBetsSendDataAdapter } from '../types';
import { replaceChipValueToChipId } from '../helpers/replaceChipValueToChipId';
import { transformBetByAdapter } from '../helpers';

import { sumSendingBets } from './utils/sumSendingBets';

export const sendBetsApiAdapter = (
   state: TRootState,
   dispatch: Store['dispatch'],
): TNullable<IBetsSendDataAdapter> => {
   const { auth, bets, round, tableCapacity, gameConfig } = state;
   const { id: roundId, number: roundNumber } = round.round;
   const { betsHistory } = bets;
   const { betLimitsByType: limits } = tableCapacity;

   const { validBets, invalidBets } = sumSendingBets({
      bets: betsHistory,
      limits,
   });

   const betsWithChipsWithId = replaceChipValueToChipId({
      bets: validBets,
      chipConfig: gameConfig?.gameConfig?.chipTray,
   });

   const areBetsEmpty = !validBets.length && !invalidBets.length;

   if (areBetsEmpty) {
      return null;
   }

   const noValidBets = !validBets.length;
   const hasValidAndInvalidBets = validBets.length && invalidBets.length;
   const totalAmountValidBets = (bets: TCommonBet[]) =>
      bets.reduce((total, bet) => total + bet.amount, 0);

   if (noValidBets) {
      dispatch(betConfirmedSlice.actions.setSendBetsStatusPartial());
      dispatch(betConfirmedSlice.actions.showNotification());
      betsCreator.resetBets();
   } else if (hasValidAndInvalidBets) {
      invalidBets.forEach(({ id }) => betsCreator.removeBetById(id));
      dispatch(betConfirmedSlice.actions.setSendBetsStatusPartial());
      dispatch(betConfirmedSlice.actions.updateTotalAmountBets(totalAmountValidBets(validBets)));
   } else {
      dispatch(betConfirmedSlice.actions.updateTotalAmountBets(totalAmountValidBets(validBets)));
      dispatch(betConfirmedSlice.actions.setSendBetsStatusAll());
   }

   return {
      user_id: auth.user?.id ?? null,
      currency: auth.user?.currency ?? null,
      round_id: roundId ?? null,
      round_number: roundNumber ?? null,
      game_table_id: gameConfig.gameConfig.gameTableId,
      placed_time: new Date().toISOString(),
      bets: transformBetByAdapter(betsWithChipsWithId),
   };
};
