// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const WinHistoryContainer = styled.div`
   display: flex;
   align-items: flex-start;
   overflow: hidden;
   height: 100%;
   width: 970px;

   ${orientation.portrait`
      width: 100%;
      justify-content: flex-start;
   `}
`;

export const ListWrapper = styled.div`
   --win-history-item-fz: 22px;
   --win-history-item-gap: 10px;
   --win-number-size: 38px;

   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-start;
   font-size: var(--win-history-item-fz);
   gap: var(--win-history-item-gap);
   width: 100%;
   flex-direction: row;

   ${orientation.mobileLandscape`
      --win-number-size: 24px;
      --win-history-item-fz: 16px;
      --win-history-item-gap: 6px;
   `}

   ${orientation.portrait`
      --win-history-item-fz: 16px;
      --win-history-item-gap: 4px;
      --win-number-size: 24px;
      width: 100%;
      overflow: hidden;
      justify-content: flex-start;
   `}
`;

export const WinNumber = styled.div`
   width: var(--win-number-size);
   height: var(--win-number-size);
   font-weight: 500;
   color: ${({ color }) => color};
   display: grid;
   place-items: center;
`;

export const LastWinNumber = styled(WinNumber)`
   --last-win-number-size: 36px;
   width: var(--last-win-number-size);
   height: var(--last-win-number-size);
   border: ${({ theme }) => `1px solid ${theme.colors?.main}`};
   border-radius: 2px;

   ${orientation.mobileLandscape`
      --last-win-number-size: 24px;
   `}

   ${orientation.portrait`
      --last-win-number-size: 24px;
   `}
`;
