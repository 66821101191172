import { useTrackState } from '#/modules/Track/state';

import { ISwitchControl } from '../types';

export const useSwitchTrackType = (): ISwitchControl => {
   const { handleToggleTrackType } = useTrackState();

   return {
      handleOnClick: handleToggleTrackType,
   };
};
