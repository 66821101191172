import { FC, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface IPortal {
  children: ReactNode;
  onMount?: () => void;
}
export const Portal: FC<IPortal> = ({ children, onMount }) => {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    const rootElement = document.getElementById('root');
    rootElement?.appendChild(container);

    onMount?.();

    return () => {
      if (rootElement) {
        rootElement.removeChild(container);
      }
    };
  }, [container, onMount]);

  return ReactDOM.createPortal(children, container);
};
