import { FC } from 'react';

import * as S from './PlayersCount.styles';

interface IPlayerCountProps {
  dataTestId: string;
  iconColor?: string;
  playersCount: number;
}
export const PlayersCount: FC<IPlayerCountProps> = ({
  playersCount,
  dataTestId,
  iconColor = '#DDDDDD',
}) => (
  <S.PlayersCountWrapper data-testid={dataTestId}>
    <S.UserIcon
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.91456 10.2574C11.5103 10.2574 13.6145 8.15315 13.6145 5.55741C13.6145 2.96168 11.5103 0.857422 8.91456 0.857422C6.31883 0.857422 4.21457 2.96168 4.21457 5.55741C4.21457 8.15315 6.31883 10.2574 8.91456 10.2574ZM8.91455 21.0003C13.3644 21.0003 16.9717 18.896 16.9717 16.3003C16.9717 13.7046 13.3644 11.6003 8.91455 11.6003C4.46472 11.6003 0.857422 13.7046 0.857422 16.3003C0.857422 18.896 4.46472 21.0003 8.91455 21.0003Z"
        fill={iconColor}
      />
    </S.UserIcon>
    <S.UserCount>{playersCount}</S.UserCount>
  </S.PlayersCountWrapper>
);
