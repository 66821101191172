import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

interface IUserGeneralSettingState {
   isDealerFeedbackEnabled: boolean;
}

const initialState: IUserGeneralSettingState = {
   isDealerFeedbackEnabled: false,
};

export const userGeneralSettingsSlice = createSlice({
   name: 'userGeneralSettings',
   initialState,
   reducers: {
      setIsDealerRatingEnabled: (
         state,
         action: PayloadAction<IUserGeneralSettingState['isDealerFeedbackEnabled']>,
      ) => {
         state.isDealerFeedbackEnabled = action.payload;
      },
   },
   selectors: {
      getDealerRatingEnabled: (state) => state.isDealerFeedbackEnabled,
   },
});

export const useDealerRatingUserSettingsSelector = () =>
   useSelector(userGeneralSettingsSlice.selectors.getDealerRatingEnabled);

export const useUserGeneralSettingsState = () => {
   const isDealerRatingEnabled = useDealerRatingUserSettingsSelector();

   return {
      isDealerRatingEnabled,
   };
};
