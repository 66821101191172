import { TLaunchToken, TGameTableId } from '../../../entities/Session';
import { RTKQueryApi, SuccessResponse } from '../types';

import { TClientAuth } from './types';

export const authAPI = (apiSlice: RTKQueryApi) =>
   apiSlice.injectEndpoints({
      endpoints: (build) => ({
         clientAuth: build.mutation<
            SuccessResponse<TClientAuth>,
            { launchToken: TLaunchToken; gameTableId: TGameTableId }
         >({
            query: ({ launchToken, gameTableId }) => ({
               url: '/authenticate',
               method: 'POST',
               body: {
                  launchToken,
                  gameTableId,
                  requestScope: 'country, age, sex',
               },
            }),
         }),
      }),
   });
