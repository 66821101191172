import {
   BaseQueryFn,
   FetchArgs,
   FetchBaseQueryError,
   createApi,
   fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { TRootState } from '#/state/types';

const baseQuery = fetchBaseQuery({
   baseUrl: import.meta.env.VITE_EXTERNAL_API_URL,
   prepareHeaders: (headers: Headers, { getState }) => {
      headers.set('Content-Type', 'application/json');

      const { session, user } = (getState() as TRootState).auth ?? {};

      if (session?.id && session?.secret) {
         const credentials = `${session.id}:${session.secret}`;
         headers.set('Authorization', `Basic ${btoa(credentials)}`);
      }

      if (user?.token) {
         headers.set('AUTH-TOKEN', user.token);
      }

      headers.set('token', `Basic ${import.meta.env.VITE_BASIC_TOKEN}`);

      return headers;
   },
});

const baseQueryWithCheckUser: BaseQueryFn<
   FetchArgs | string,
   unknown,
   FetchBaseQueryError
> = async (args, api, extraOptions) => baseQuery(args, api, extraOptions);

export const apiSlice = createApi({
   baseQuery: baseQueryWithCheckUser,
   endpoints: () => ({}),
});
