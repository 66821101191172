import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

export interface IBalanceState {
   balance: number;
   initialBalance: number;
   showInsufficientBalanceNotification: boolean;
}

const initialState: IBalanceState = {
   balance: 0,
   initialBalance: 0,
   showInsufficientBalanceNotification: true,
};

export const balanceSlice = createSlice({
   name: 'balance',
   initialState,
   reducers: {
      updateBalanceAutoplayFromUI: (
         state,
         action: PayloadAction<IBalanceState['balance']>,
      ): void => {
         state.balance = action.payload;
      },
      updateBalanceFromUI: (state, action: PayloadAction<IBalanceState['balance']>): void => {
         state.balance = action.payload;
      },
      updateBalanceFromServer: (state, action: PayloadAction<IBalanceState['balance']>): void => {
         state.balance = action.payload;
      },
      updateInitialBalance: (
         state,
         action: PayloadAction<IBalanceState['initialBalance']>,
      ): void => {
         state.initialBalance = action.payload;
      },

      setSetShowInsufficientBalanceNotification: (
         state,
         action: PayloadAction<IBalanceState['showInsufficientBalanceNotification']>,
      ): void => {
         state.showInsufficientBalanceNotification = action.payload;
      },
   },
});

export const useBalanceActions = () => {
   const dispatch = useDispatch();

   const { updateInitialBalance } = balanceSlice.actions;

   const handleUpdateBalance = (balance: IBalanceState['initialBalance']) => {
      dispatch(updateInitialBalance(balance));
   };

   return {
      handleUpdateBalance,
   };
};
