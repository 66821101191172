import { FC } from 'react';

import { Chip } from '@/common/ui';

import { useGameConfigStore } from '#/services/api/gameConfigApiSlice/state';
import { ETypeBet } from '#/state/features/bets';

import { useHighlightChipByMinLimit } from '../../../hooks/useHighlightChipByMinLimit';
import { LAST_SIMPLE_BET_CELL_ID } from '../constants';

import * as S from './Bet.styles';

interface IBet {
   cells: number[];
   getBetsAmountByCellId: (id: number) => number;
   getZIndex: (id: number) => number;
   id: number;
   type: ETypeBet;
}

export const Bet: FC<IBet> = ({ id, cells, getBetsAmountByCellId, getZIndex, type }) => {
   const zIndex = getZIndex(id);
   const amount = getBetsAmountByCellId(id);
   const isHighlightChip = useHighlightChipByMinLimit();
   const isSimpleBet = id <= LAST_SIMPLE_BET_CELL_ID;
   const whenHasBet = amount > 0;
   const { gameConfig } = useGameConfigStore();
   const chips = gameConfig.chipTray;
   return (
      <>
         <S.ChipPlace
            draggable={whenHasBet}
            data-testid={`chip-place_${id}`}
            data-bet-id={id}
            data-highlight-cells={cells}
            data-chip-place={true}
            data-amount={amount}
            data-type={type}
            $fullSize={isSimpleBet}
         />
         {whenHasBet && (
            <S.ChipWrapper
               draggable
               data-testid={`chip_${id}`}
               data-highlight-cells={cells}
               data-chip-place={false}
               data-amount={amount}
               data-type={type}
               data-command-id={id}
               zIndex={zIndex}
            >
               <Chip
                  chips={chips}
                  chipNumber={amount}
                  highlightShadow={isHighlightChip({ betValue: amount, type })}
               />
            </S.ChipWrapper>
         )}
      </>
   );
};
