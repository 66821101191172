import { useLayoutEffect } from 'react';

import { DeviceOrientation, useDeviceOrientation } from '@/common/hooks';

import { IUseWindowResize, useWindowResize } from './useWindowResize';

export const usePortraitMode = (): void => {
   const deviceOrientation: DeviceOrientation = useDeviceOrientation();
   const { height: currentHeight }: IUseWindowResize = useWindowResize();

   useLayoutEffect(() => {
      const setMaxHeightToMainContainerComponent = (
         mainContainer: HTMLDivElement,
         height: number,
      ): void => {
         mainContainer.style.height = `${height}px`;
      };

      const removeMaxHeightFromMainContainer = (mainContainer: HTMLDivElement): void => {
         mainContainer.removeAttribute('style');
      };

      const removeScaling = (): void => {
         document.documentElement.removeAttribute('style');
      };

      const mainContainer = document.querySelector('.main-container')?.firstChild as HTMLDivElement;

      if (currentHeight) {
         setMaxHeightToMainContainerComponent(mainContainer, currentHeight);
         // remove scaling because we don't use it on this portrait mode
         removeScaling();
      }

      return (): void => {
         removeMaxHeightFromMainContainer(mainContainer);
      };
   }, [currentHeight, deviceOrientation]);
};
