import { TCommonBet } from '#/state/features/bets';

export class Command {
   id: TCommonBet['id'];
   type: TCommonBet['type'];
   numbers: TCommonBet['numbers'];
   amount: TCommonBet['amount'];
   numberOfChips = 1;

   constructor({ id, type, numbers, amount }: Omit<TCommonBet, 'chips'>) {
      this.id = id;
      this.type = type;
      this.numbers = numbers;
      this.amount = amount;
   }

   execute = (): TCommonBet => ({
      id: this.id,
      amount: this.amount,
      numbers: this.numbers,
      type: this.type,
      chips: {
         [this.amount]: this.numberOfChips,
      },
   });
}
