import { cornerConfig } from 'core/config/corners';

const cornerIdx = cornerConfig.getIds();
const ID_CORNER_0_1_2_3 = cornerIdx[0];

const createRowsFromConfigIdx = (config) => {
   const rows: {
      first: number[];
      second: number[];
   } = {
      first: [],
      second: [],
   };

   for (const [index, id] of config.entries()) {
      if (index === 0) {
         continue;
      } else if (index % 2 === 0) {
         rows.first.push(id);
      } else {
         rows.second.push(id);
      }
   }

   return rows;
};

export const generateCornerGrid = (): string => {
   const rows = createRowsFromConfigIdx(cornerIdx);
   let styles = `.cell_${ID_CORNER_0_1_2_3} {
      position: absolute;
      width: var(--cell-size);
      height: var(--cell-size);
      top: calc((var(--bm-big-cell-height) * 3) - var(--half-cell-width));
      left: calc(var(--bm-small-cell-width) - var(--half-cell-width));
      background: transparent;
      box-shadow: none;
   }`;

   const addStylesForRow = ({ config, rowNumber }) => {
      config.forEach((id, index) => {
         styles += `
           .cell_${id} {
            position: absolute;
            width: var(--cell-size);
            height: var(--cell-size);
            top: calc((var(--bm-big-cell-height) * ${rowNumber}) - var(--half-cell-width));
            left: calc(((var(--bm-big-cell-width) * ${
               index + 1
            }) + var(--bm-small-cell-width)) - var(--half-cell-width));

            background: transparent;
            box-shadow: none;
         }`;
      });
   };

   addStylesForRow({ config: rows.first, rowNumber: 1 });
   addStylesForRow({ config: rows.second, rowNumber: 2 });

   return styles;
};

export const generateCornerGridPortrait = (): string => {
   const rows = createRowsFromConfigIdx(cornerIdx);
   let styles = `
      .cell_${ID_CORNER_0_1_2_3} {
         position: absolute;
         width: var(--cell-size);
         height: var(--cell-size);
         top: calc(var(--bm-small-cell-height) - var(--half-cell-width));
         left: calc((var(--bm-small-cell-width) * 2) - var(--half-cell-width));

         background: transparent;
         box-shadow: none;
      }
   `;

   const addStylesForColumn = ({ config, columnNumber }) => {
      config.forEach((id, index) => {
         styles += `
           .cell_${id} {
            position: absolute;
            width: var(--cell-size);
            height: var(--cell-size);
            top: calc(var(--bm-small-cell-height) + (var(--bm-big-cell-height) * ${
               index + 1
            }) - var(--half-cell-width));
            left: calc((var(--bm-small-cell-width) * 2) + (var(--bm-big-cell-width) * ${columnNumber}) - var(--half-cell-width));
            background: transparent;
            box-shadow: none;
         }`;
      });
   };

   addStylesForColumn({ config: rows.first, columnNumber: 2 });
   addStylesForColumn({ config: rows.second, columnNumber: 1 });

   return styles;
};
