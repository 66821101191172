import { useDispatch } from 'react-redux';

import { casinoGameTypeSettingsEventAction } from '#/services/api/middleware/casinoGameTypeSettingsMiddleware';

import { TCasinoGameTypeSettingsUpdateEvent } from './schemes';

export const useCasinoGameTypeSettingsUpdateEvent = () => {
   const dispatch = useDispatch();

   return (data: TCasinoGameTypeSettingsUpdateEvent) => {
      dispatch(casinoGameTypeSettingsEventAction(data));
   };
};
