// @ts-nocheck
import styled from 'styled-components/macro';

import { SideControls } from '../../LandscapeGrid.styles';

export const RightColumnContainer = styled.div`
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
`;
export const CollapseTrackControl = styled.div`
   top: 50px;
   right: -26px;
   position: absolute;
`;

export const RightControlsContainer = styled(SideControls)`
   margin-left: auto;
   transform: translateX(25px);
`;
