// @ts-nocheck
import styled from 'styled-components/macro';

export const TopColumnContainer = styled.div`
   position: relative;
`;

export const GameInfoContainer = styled.div`
   display: flex;
   align-items: center;
`;
