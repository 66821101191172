// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '../../../theme/mixins';

export const PlayersCountWrapper = styled.div`
  --player-count-fz: 16px;
  --player-count-icon-size: 18px;

  display: flex;
  align-items: center;
  gap: 10px;

  ${orientation.mobileLandscape`
      --player-count-fz: 12px;
      --player-count-icon-size: 14px;

      gap: 5px;
   `}

  ${orientation.portrait`
      --player-count-fz: 12px;
      --player-count-icon-size: 14px;

      gap: 5px;
   `}
`;

export const UserIcon = styled.svg`
  height: var(--player-count-icon-size);
  width: var(--player-count-icon-size);
`;

export const UserCount = styled.div`
  font-size: var(--player-count-fz);
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme }) => theme.colors?.white};
  line-height: 16px;

  ${orientation.mobileLandscape`
      line-height: 14px;
   `};
`;
