type HtmlSvgOrRectElement = HTMLElement | SVGRectElement | SVGSVGElement;

export const addStyleProperties =
   <T extends HtmlSvgOrRectElement>(styles: Record<string, string>) =>
   (node: T): HtmlSvgOrRectElement => {
      for (const [name, value] of Object.entries(styles)) {
         node.style[name] = value;
      }

      return node;
   };
