export const errors = {
   auth: {
      missedLaunchToken: 'Launch token did not found',
      request: 'Failed authenticate request',
      requestValidation: 'Auth request validation error',
      authorizedUser: 'User is not authorized',
   },
   bets: {
      missedGameTableId: 'gameTableId did not found on query params',
      errorSendBets: 'Failed send bets request',
      emptyBets: 'You did not bid',
      limits: 'Limits for current bet not found',
   },
   tableId: {
      missedTableId: 'Table ID not found',
   },
   config: {
      notValidUrl: 'Cannot get config from URL',
      request: 'Failed game config request',
      requestValidation: 'Config request validation error',
   },
   maintenance: 'Maintenance error',
   inactiveGameTable: 'Inactive game table error',
   roundState: {
      request: 'Failed round state request',
      requestValidation: 'Round state request validation error',
   },
   userBets: {
      request: 'Failed user bets request',
      requestValidation: 'User bets request validation error',
   },
   limits: {
      errorLimitTooltip: 'Limit tooltip not found',
   },
   statistics: {
      request: 'Failed statistics request',
   },
};
