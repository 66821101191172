import { APIResponse, ErrorResponse, ExceptionResponse } from '../../types';

interface IDataResponse<T> {
   data: APIResponse<T>;
}

interface IErrorResponse {
   error: ErrorResponse | ExceptionResponse;
}

export type IApiResponse<T> = IDataResponse<T> | IErrorResponse;

export const unwrapResponse = <T>(response: IApiResponse<T>) => {
   if ('data' in response) {
      return response.data;
   }

   return response.error;
};
