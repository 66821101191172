import { useEffect, useRef } from 'react';

import { TSetTimeout } from '@/common/types';

import { useAnnouncementsState } from '#/modules/Announcements/state';
import { useWinNumber } from '#/modules/WinNumbers';
import { announcementsSounds } from '#/core/sounds';
import { ETableSocketEvents } from '#/services/sockets/hooks/event-hooks/constants';
import { useGameLanguageSelector } from '#/services/api/gameConfigApiSlice/state/gameConfig';
import { ESoundAnnouncementType } from '#/modules/Announcements/types';
import { getRandomAnnouncementId } from '#/modules/Announcements/helpers';
import { MAX_ANNOUNCEMENT_ID } from '#/modules/Announcements/helpers/getRandomAnnouncementId';

import {
   generateAnnouncementSoundsPool,
   generateRoundResultAnnouncementTypes,
   IGenerateAnnouncementSoundsPool,
} from './createAnnouncementSounds';

const socketEventToAnnouncementType = (socketEvent: string) => {
   const eventsMap = {
      [ETableSocketEvents.StartRound]: () => ESoundAnnouncementType.BettingTimeStart,
      [ETableSocketEvents.LastBetsStartTime]: () => ESoundAnnouncementType.LastBets,
      [ETableSocketEvents.BettingEndTime]: () => ESoundAnnouncementType.BettingTimeEnd,
      [ETableSocketEvents.WinNumber]: (winNumber: string) => `round-result-${winNumber}`,
   };
   return eventsMap[socketEvent];
};

// TODO: create singleton player
let announcementSoundPlayer;
const ANNOUNCEMENT_SHIFT_DELAY = 5000;
export const useAnnouncements = () => {
   const announcementsPool = useRef<IGenerateAnnouncementSoundsPool | null>(null);
   const language = useGameLanguageSelector();
   const { winNumber } = useWinNumber();
   const {
      announcementType: announcer,
      currentAnnouncement,
      handleShiftAnnouncementFromPool,
   } = useAnnouncementsState();

   // We moved announcement sounds pool inside hook to avoid
   // Uploading announcements in scope of szrol
   useEffect(() => {
      if (!announcementsPool.current) {
         announcementsPool.current = generateAnnouncementSoundsPool({
            basedVolume: announcementsSounds,
            types: [
               ESoundAnnouncementType.BettingTimeStart,
               ESoundAnnouncementType.BettingTimeEnd,
               ESoundAnnouncementType.LastBets,
               ...generateRoundResultAnnouncementTypes(),
            ],
            count: MAX_ANNOUNCEMENT_ID, // at the moment we support 1 announcement version
            soundURL: `https://${import.meta.env.VITE_STATIC}/sounds/autorol/roulette`,
         }) as IGenerateAnnouncementSoundsPool;
      }
   }, []);

   useEffect(() => {
      if (announcementsPool.current && currentAnnouncement && announcer) {
         const announcementId = getRandomAnnouncementId();
         const type = socketEventToAnnouncementType(currentAnnouncement);
         announcementSoundPlayer =
            announcementsPool.current[announcer][type(winNumber)]?.[announcementId][language];
         announcementSoundPlayer?.play();

         // when sound ends (or 5 seconds delay ends) we should remove it form queue
         // if announcement sound did not load properly we will never start playing
         // and never trigger end event, so announcement call stack will stuck
         // need to force handleShiftAnnouncementFromPool after delay so player should continue working
         const timeout: TSetTimeout = setTimeout(() => {
            handleShiftAnnouncementFromPool();
         }, ANNOUNCEMENT_SHIFT_DELAY);
         announcementSoundPlayer?.audio?.on('end', () => handleShiftAnnouncementFromPool(timeout));
      }
   }, [currentAnnouncement]);

   // This approach serves as a workaround due to the design flaw.
   // Specifically, if the user selects 'Off' instead of 'Male' or 'Female', we should mute the announcements
   announcementSoundPlayer?.audio?.mute(!announcer);

   // subtitle is not currently supported
   return null;
};
