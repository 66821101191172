import { ElementRef, useCallback, useState } from 'react';

import { TNullable } from '@/common/types';

export const useConnectVideoElement = () => {
   const [videoElement, setVideoElement] = useState<TNullable<ElementRef<'video'>>>(null);

   const onVideoElementChange = useCallback((videoElement: ElementRef<'video'>) => {
      // connect video stream to video element
      setVideoElement(videoElement);
   }, []);

   return {
      videoElement,
      onVideoElementChange,
   };
};
