import { useLayoutEffect, useState } from 'react';

import { useWindowEvent } from '@/common/hooks';
import { TNullable } from '@/common/types';

export interface IUseWindowResize {
   height: TNullable<number>;
   width: TNullable<number>;
}

export const useWindowResize = (): IUseWindowResize => {
   const [sizes, setSizes] = useState<IUseWindowResize>({
      width: null,
      height: null,
   });

   const handleResize = (): void => {
      setSizes({
         width: window.innerWidth,
         height: window.innerHeight,
      });
   };

   useWindowEvent('resize', handleResize);
   useLayoutEffect(handleResize, []);

   return sizes;
};
