import { FC } from 'react';

import * as S from './Time.styles';

interface ITimeProps {
  dataTestId: string;
  getTimeValue: () => string;
}

export const Time: FC<ITimeProps> = ({ getTimeValue, dataTestId }) => {
  const timeValue = getTimeValue();

  return <S.Time data-testid={dataTestId}>{timeValue}</S.Time>;
};
