import { Box } from '@/common/ui';
import { TapSoundButton } from '@/common/modules/GameSounds';
import { useSidebar } from '@/common/modules/Sidebar';

import { translation } from '#/translates';
import { useUserCurrencySign } from '#/services/api/authApiSlice/authSlice';

import { useAutoplay } from './hooks';
import {
   AutoplayRound,
   CancelAutoplayButton,
   LossLimit,
   SingleWinLimit,
   StartAutoplayButton,
} from './components';
import * as S from './Autoplay.styles';

export const Autoplay = () => {
   const { handleCloseSidebar } = useSidebar();
   const { autoplay } = useAutoplay();

   const currencySign = useUserCurrencySign();

   return (
      <S.AutoplayWrapper>
         <AutoplayRound />

         <Box mt="15px">
            <LossLimit currencySign={currencySign} />
         </Box>

         <Box mt="15px">
            <SingleWinLimit currencySign={currencySign} />
         </Box>

         <S.ButtonsWrapper>
            <TapSoundButton width="50">
               {autoplay.isAutoplayStarted() ? (
                  <CancelAutoplayButton onCancelSuccessful={handleCloseSidebar} />
               ) : (
                  <StartAutoplayButton onStartSuccessful={handleCloseSidebar} />
               )}
            </TapSoundButton>
         </S.ButtonsWrapper>
         {autoplay.isMessageShown() && (
            <S.Message>{translation('sidebars.autoplay.placeBet')}</S.Message>
         )}
      </S.AutoplayWrapper>
   );
};
