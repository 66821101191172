// @ts-nocheck
import styled from 'styled-components/macro';

export const BottomRow = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   gap: 10px;

   :nth-child(n):not(:last-child) {
      margin-right: 15px;
   }
`;
