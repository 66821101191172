// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const WinnerListTitle = styled.div`
   --title-fz: 16px;
   --title-padding: 0 0 10px 0;

   ${orientation.mobileLandscape`
      --title-fz: 14px;
      --title-padding: 0;
   `}

   ${orientation.portrait`
      --title-fz: 14px;
      --title-padding: 0;
   `}

   font-size: var(--title-fz);
   font-weight: 700;
   padding: var(--title-padding);
   color: ${({ theme }) => theme.colors?.main};
   white-space: nowrap;
`;
