import { isIOS } from 'react-device-detect';

import { Components } from './components';

export const SwipeUp = () => {
   if (isIOS) {
      return null;
   }

   return <Components.Android />;
};
