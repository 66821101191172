import { z } from 'zod';

export const roundWinnersSchema = z
   .object({
      username: z.string(),
      amount: z.number(),
   })
   .array();

export type TRoundWinners = z.infer<typeof roundWinnersSchema>;
