import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '@/common/types';

import { Store } from '#/state/types';

export const enum ELimitTooltip {
   Min = 'MIN',
   Max = 'MAX',
}

export interface ILimitTooltipActions {
   handleHideTooltip: () => void;
   handleSetMaxLimitTooltip: () => void;
   handleSetMinLimitTooltip: () => void;
}

interface ILimitTooltipState {
   activeTooltip: TNullable<ELimitTooltip>;
}

const initialState: ILimitTooltipState = {
   activeTooltip: null,
};

export const limitTooltipSlice = createSlice({
   name: 'limitTooltip',
   initialState,
   reducers: {
      setMaxTooltip: (state) => {
         state.activeTooltip = ELimitTooltip.Max;
      },

      setMinTooltip: (state) => {
         state.activeTooltip = ELimitTooltip.Min;
      },

      hideTooltip: (state) => {
         state.activeTooltip = null;
      },
   },
   selectors: {
      getActiveTooltip: (state) => state.activeTooltip,
   },
});

const createActions = (dispatch: Store['dispatch']) => {
   const { hideTooltip, setMaxTooltip, setMinTooltip } = limitTooltipSlice.actions;

   const handleSetMaxLimitTooltip = (): void => {
      dispatch(setMaxTooltip());
   };

   const handleSetMinLimitTooltip = (): void => {
      dispatch(setMinTooltip());
   };

   const handleHideTooltip = (): void => {
      dispatch(hideTooltip());
   };

   return { handleSetMaxLimitTooltip, handleSetMinLimitTooltip, handleHideTooltip };
};

export const useLimitTooltipActions = (): ILimitTooltipActions => {
   return createActions(useDispatch());
};
export const limitTooltipActions = (store: Store): ILimitTooltipActions => {
   return createActions(store.dispatch);
};

export const useLimitTooltipSelector = () => {
   return useSelector(limitTooltipSlice.selectors.getActiveTooltip);
};
