// @ts-nocheck
import { ComponentProps, FC, useRef } from 'react';
import { Transition } from 'react-transition-group';

type TransitionSteps = 'entered' | 'entering' | 'exited' | 'exiting';

const defaultStyle = (duration: number = 500) => ({
  transitionTimingFunction: 'linear',
  transitionProperty: 'opacity, filter',
  transitionDuration: `${duration}ms`,
  filter: 'blur(1px)',
  opacity: 0,
});

const transitionStyles = {
  entering: { opacity: 1, filter: 'blur(1px)' },
  entered: { opacity: 1, filter: 'none' },
  exiting: { opacity: 0, filter: 'blur(1px)' },
  exited: { opacity: 0, filter: 'blur(1px)' },
};

export type FadeProps = ComponentProps<'div'> & {
  duration?: number;
  isVisible: boolean;
};

export const FadeBox: FC<FadeProps> = ({
  style,
  children,
  isVisible,
  duration = 500,
  ...othersProps
}) => {
  const nodeRef = useRef(null);
  return (
    <Transition mountOnEnter unmountOnExit nodeRef={nodeRef} in={isVisible} timeout={duration}>
      {(state: TransitionSteps) => {
        return (
          <div
            ref={nodeRef}
            style={{
              ...defaultStyle(duration),
              ...transitionStyles[state],
              ...style,
            }}
            {...othersProps}
          >
            {children}
          </div>
        );
      }}
    </Transition>
  );
};
