import { RatingStars } from '../RatingStars';

import * as S from './RatingStarsBlinking.styles';

export const RatingStarsBlinking = ({
   filledStars,
   handleShowModal,
   isBlinking,
}: {
   filledStars: number;
   handleShowModal: () => void;
   isBlinking: boolean;
}) => {
   return (
      <S.StarsAnimationWrapper isBlinking={isBlinking}>
         <RatingStars filledStars={filledStars} handleShowModal={handleShowModal} />
      </S.StarsAnimationWrapper>
   );
};
