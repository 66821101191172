import { dolbyController } from '@/common/modules/LiveStreamPlayer/Dolby/DolbyController';
import { ESessionClosedReason, useGameStateActions } from '@/common/state/gameState/gameState';

export const useSessionClosedEvent = () => {
   const { handleSetSessionClosed, handleUnsetNotifyInactivePlayer } = useGameStateActions();

   return (event: { reason: ESessionClosedReason }) => {
      handleSetSessionClosed(event.reason);
      handleUnsetNotifyInactivePlayer();
      dolbyController.pause();
      dolbyController.destroyConnection();
   };
};
