import { straightConfig } from 'core/config';

import { TCommonBet, ETypeBet } from '#/state/features/bets';

import { AbstractSpecialCommand } from './AbstractSpecialCommand';
import { ISpecialCommand, LuckyCommandParams } from './types';

export class LuckyCommand extends AbstractSpecialCommand implements ISpecialCommand {
   luckyRange: number[];

   constructor({ cell, amount, luckyRange }: LuckyCommandParams) {
      super({ type: ETypeBet.LUCKY, amount, cell });

      if (luckyRange) {
         this.luckyRange = luckyRange;
      }

      this.extractedCommand = this.extract();
   }

   extract = (): TCommonBet[] => {
      return straightConfig
         .getConfig()
         .filter(({ id }) => this.luckyRange.includes(id))
         .map(({ id, type, numbers }) => ({
            id,
            amount: this.amount,
            type,
            numbers,
            chips: {
               [this.amount]: this.numberOfChips,
            },
         }));
   };
}
