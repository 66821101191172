// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const Wrapper = styled.div`
   --wrapper-width: 570px;
   display: grid;
   place-items: center;
   width: var(--wrapper-width);
   padding: 12px 0;
   background: linear-gradient(
      270deg,
      rgba(3, 3, 3, 0.1) 3.33%,
      rgba(3, 3, 3, 0.85) 32.61%,
      rgba(3, 3, 3, 0.85) 62.17%,
      rgba(3, 3, 3, 0.1) 94.93%
   );
   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
   will-change: filter;
   text-transform: uppercase;

   ${orientation.mobileLandscape`
      --wrapper-width: 70vw;

      padding: 10px 15px;
      transform: translateY(-15px);
   `}

   ${orientation.portrait`
     --wrapper-width: 95vw;

      padding: 10px 15px;
   `}
`;

export const Title = styled.div`
   margin-bottom: 5px;
   font-size: 20px;
   color: ${({ theme }) => theme.colors?.white};

   ${orientation.mobileLandscape`
     font-size: 16px;
  `}

   ${orientation.portrait`
     font-size: 16px;
  `}
`;

export const Value = styled.div`
   font-size: 32px;
   font-weight: 500;
   line-height: 100%;
   color: ${({ theme }) => theme.colors?.main};

   ${orientation.portrait`
      font-size: 28px;
   `}

   ${orientation.mobileLandscape`
      font-size: 28px;
   `}
`;
