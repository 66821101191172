import { useDispatch } from 'react-redux';

import { EGameState, useGameStateActions } from '@/common/state/gameState';
import { useTimerActions } from '@/common/modules/CountDown/state';
import { TCancelRoundEvent } from '@/common/services/sockets/useCancelRoundEvent';

import { betsCreator } from '#/core/betsCreator';
import { ENotificationIcons, useNotificationsActions } from '#/modules/Notifications';
import { savePreviousRoundBetsAction } from '#/modules/Rebet/middleware';
import { useTranslation } from '#/translates';

export const useCancelRoundEvent = (): ((socket: TCancelRoundEvent) => void) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const { handleResetTimerState } = useTimerActions();
   const { handleAddNotification } = useNotificationsActions();
   const { handleChangeGameState } = useGameStateActions();

   const handelSaveBetsFromPreviousRound = () => {
      dispatch(savePreviousRoundBetsAction());
   };

   return () => {
      handleChangeGameState(EGameState.ROUND_CANCELLED);
      handelSaveBetsFromPreviousRound();
      handleAddNotification({
         title: t('notifications.cancelRound.title'),
         action: '',
         content: t('notifications.cancelRound.content'),
         type: ENotificationIcons.Warning,
      });
      betsCreator.resetBetsOnCancelRound();
      handleResetTimerState();
   };
};
