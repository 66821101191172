export const addQueryParamsToUrl = ({
   url,
   queryParams = {},
}: {
   url: string;
   queryParams?: Record<string, string>;
}): string => {
   const searchParams = new URLSearchParams();

   Object.keys(queryParams).forEach((key) => {
      searchParams.append(key, queryParams[key]);
   });

   const queryString = searchParams.toString();

   if (queryString) {
      return url.includes('?') ? `${url}&${queryString}` : `${url}?${queryString}`;
   }

   return url;
};
