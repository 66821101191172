import { TRoundState as RoundStateResponse } from '@/common/services/api/roundsAPI/types';

import { IRoundState } from '../types';

export const roundStateApiAdapter = (roundStateResponse: RoundStateResponse): IRoundState => {
   const {
      uuid,
      winResults,
      start_time,
      playersCount,
      round_number,
      physicalTable,
      physical_table_id,
      betting_end_time,
      dealer,
   } = roundStateResponse;

   return {
      uuid,
      winResults,
      playersCount,
      roundNumber: round_number,
      bettingEndTime: betting_end_time,
      physicalTableId: physical_table_id,
      bettingTime: physicalTable.bettingTimeDuration,
      startBettingTime: start_time,
      dealerName: dealer?.name ?? null,
   };
};
