import { useEffect } from 'react';
import { useMessageListContext } from 'stream-chat-react';

// for more info: https://getstream.io/chat/docs/sdk/react/components/contexts/message_list_context/#scrolltobottom
// We need to use this component because the GetSteam API allows us to call this hook only within the context of the MessageList header
export const ScrollToBottom = () => {
   const { scrollToBottom } = useMessageListContext();

   useEffect(() => {
      if (scrollToBottom) {
         scrollToBottom();
      }
   }, [scrollToBottom]);

   return <></>;
};
