import { useDispatch } from 'react-redux';

import { dealerRatingUpdateEventAction } from '#/services/api/middleware/dealerRatingMiddleware/dealerRatingUpdateEventMiddleware';

import { TDealerRatingUpdateEvent } from './schemes';

export const useDealerRatingUpdateEvent = () => {
   const dispatch = useDispatch();

   return (data: TDealerRatingUpdateEvent) => {
      dispatch(dealerRatingUpdateEventAction(data));
   };
};
