// @ts-nocheck
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const Element = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(var(--scale));
  overflow: hidden;

  ${({ standardSize }: { standardSize: { width: number; height: number } }) => css`
    width: ${standardSize.width}px;
    height: ${standardSize.height}px;
  `}
`;
