// @ts-nocheck
import styled from 'styled-components/macro';

import * as RangeStyles from '@/common/modules/RangeSlider/RangeSlider.styles';

export const LossLimitProgressBar = styled.div`
   width: 100%;
   height: 15px;
   position: relative;
   margin-top: 20px;

   ${RangeStyles.Input} {
      margin: 0;
      pointer-events: none;
   }
`;
