export const AutoRouletteTitle = () => {
   return (
      <svg
         width="434"
         height="232"
         viewBox="0 0 434 232"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <g filter="url(#filter0_d_24155_407728)">
            <path
               d="M427.272 48.3262H2V169.142H427.272V48.3262Z"
               fill="url(#paint0_linear_24155_407728)"
            />
         </g>
         <path
            d="M423.886 53.6661V163.802H5.38525V53.6661H423.886ZM427.272 48.3262H2V169.142H427.272V48.3262Z"
            fill="url(#paint1_linear_24155_407728)"
         />
         <g opacity="0.8">
            <path
               d="M69.5287 142.542H63.6275V152.707H52.8093V114.459H70.2937C80.7314 114.459 87.286 119.868 87.286 128.61C87.286 134.239 84.5525 138.39 79.801 140.686L88.0511 152.707H76.4679L69.5287 142.542ZM69.6403 122.982H63.6316V134.185H69.6403C74.1189 134.185 76.3603 132.109 76.3603 128.61C76.3603 125.112 74.1189 122.982 69.6403 122.982Z"
               fill="#161616"
            />
            <path
               d="M145.045 135.657V114.459H155.863V135.33C155.863 141.885 158.597 144.511 163.129 144.511C167.662 144.511 170.341 141.889 170.341 135.33V114.459H180.998V135.657C180.998 147.075 174.332 153.468 163.022 153.468C151.711 153.468 145.045 147.075 145.045 135.657Z"
               fill="#161616"
            />
            <path
               d="M188.909 114.458H199.727V144.125H217.977V152.702H188.909V114.454V114.458Z"
               fill="#161616"
            />
            <path
               d="M256.489 144.345V152.707H225.784V114.459H255.782V122.821H236.495V129.268H253.487V137.352H236.495V144.345H256.494H256.489Z"
               fill="#161616"
            />
            <path
               d="M275.367 123.036H263.619V114.459H297.876V123.036H286.185V152.703H275.367V123.036Z"
               fill="#161616"
            />
            <path
               d="M317.698 123.036H305.949V114.459H340.207V123.036H328.516V152.703H317.698V123.036Z"
               fill="#161616"
            />
            <path
               d="M379.091 144.345V152.707H348.386V114.459H378.384V122.821H359.097V129.268H376.089V137.352H359.097V144.345H379.096H379.091Z"
               fill="#161616"
            />
         </g>
         <path
            d="M67.0463 139.234H61.1451V149.399H50.3269V111.15H67.8113C78.249 111.15 84.8036 116.559 84.8036 125.302C84.8036 130.93 82.0701 135.082 77.3186 137.377L85.5687 149.399H73.9855L67.0463 139.234ZM67.1579 119.673H61.1492V130.876H67.1579C71.6366 130.876 73.8779 128.8 73.8779 125.302C73.8779 121.803 71.6366 119.673 67.1579 119.673Z"
            fill="#E7D981"
         />
         <path
            d="M142.562 132.348V111.15H153.38V132.022C153.38 138.576 156.114 141.202 160.646 141.202C165.179 141.202 167.858 138.58 167.858 132.022V111.15H178.515V132.348C178.515 143.766 171.849 150.159 160.539 150.159C149.228 150.159 142.562 143.766 142.562 132.348Z"
            fill="#E7D981"
         />
         <path
            d="M95.0689 135.093C95.0689 123.799 104.292 115.521 116.816 115.521C129.339 115.521 138.563 123.803 138.563 135.093C138.563 146.383 129.339 154.665 116.816 154.665C104.292 154.665 95.0689 146.383 95.0689 135.093ZM127.376 135.093C127.376 128.585 122.735 124.34 116.811 124.34C110.888 124.34 106.247 128.589 106.247 135.093C106.247 141.596 110.888 145.845 116.811 145.845C122.735 145.845 127.376 141.596 127.376 135.093Z"
            fill="#161616"
         />
         <path
            d="M95.0689 130.744C95.0689 119.45 103.37 111.172 114.641 111.172C125.912 111.172 134.213 119.454 134.213 130.744C134.213 142.034 125.912 150.316 114.641 150.316C103.37 150.316 95.0689 142.034 95.0689 130.744ZM124.145 130.744C124.145 124.236 119.968 119.991 114.637 119.991C109.306 119.991 105.129 124.24 105.129 130.744C105.129 137.248 109.306 141.497 114.637 141.497C119.968 141.497 124.145 137.248 124.145 130.744Z"
            fill="#E7D981"
         />
         <path d="M186.426 111.15H197.245V140.818H215.494V149.394H186.426V111.15Z" fill="#E7D981" />
         <path
            d="M254.006 141.037V149.399H223.301V111.15H253.299V119.512H234.012V125.959H251.004V134.044H234.012V141.037H254.011H254.006Z"
            fill="#E7D981"
         />
         <path
            d="M272.885 119.727H261.136V111.15H295.394V119.727H283.703V149.394H272.885V119.727Z"
            fill="#E7D981"
         />
         <path
            d="M315.215 119.727H303.467V111.15H337.724V119.727H326.033V149.394H315.215V119.727Z"
            fill="#E7D981"
         />
         <path
            d="M376.608 141.037V149.399H345.903V111.15H375.901V119.512H356.614V125.959H373.606V134.044H356.614V141.037H376.613H376.608Z"
            fill="#E7D981"
         />
         <g opacity="0.8">
            <path
               d="M281.655 108.773C278.678 108.773 275.931 108.295 273.415 107.338C270.899 106.381 268.701 105.034 266.823 103.298C264.98 101.526 263.545 99.4701 262.517 97.1312C261.49 94.7922 260.976 92.2228 260.976 89.4231C260.976 86.6234 261.49 84.054 262.517 81.7151C263.545 79.3761 264.98 77.3383 266.823 75.6018C268.701 73.8298 270.899 72.4654 273.415 71.5085C275.931 70.5517 278.678 70.0732 281.655 70.0732C284.667 70.0732 287.413 70.5517 289.894 71.5085C292.41 72.4654 294.59 73.8298 296.433 75.6018C298.276 77.3383 299.711 79.3761 300.739 81.7151C301.802 84.054 302.333 86.6234 302.333 89.4231C302.333 92.2228 301.802 94.8099 300.739 97.1843C299.711 99.5233 298.276 101.561 296.433 103.298C294.59 105.034 292.41 106.381 289.894 107.338C287.413 108.295 284.667 108.773 281.655 108.773ZM281.655 100.055C283.072 100.055 284.383 99.8068 285.588 99.3107C286.829 98.8145 287.892 98.1057 288.778 97.1843C289.699 96.2274 290.408 95.0934 290.904 93.7821C291.436 92.4709 291.702 91.0179 291.702 89.4231C291.702 87.7929 291.436 86.3399 290.904 85.0641C290.408 83.7528 289.699 82.6365 288.778 81.7151C287.892 80.7582 286.829 80.0317 285.588 79.5355C284.383 79.0394 283.072 78.7913 281.655 78.7913C280.237 78.7913 278.908 79.0394 277.668 79.5355C276.463 80.0317 275.399 80.7582 274.478 81.7151C273.592 82.6365 272.883 83.7528 272.352 85.0641C271.856 86.3399 271.607 87.7929 271.607 89.4231C271.607 91.0179 271.856 92.4709 272.352 93.7821C272.883 95.0934 273.592 96.2274 274.478 97.1843C275.399 98.1057 276.463 98.8145 277.668 99.3107C278.908 99.8068 280.237 100.055 281.655 100.055Z"
               fill="#161616"
            />
            <path
               d="M231.825 108.028V79.1624H220.396V70.8164H253.727V79.1624H242.351V108.028H231.825Z"
               fill="#161616"
            />
            <path
               d="M195.659 108.772C190.166 108.772 185.878 107.266 182.795 104.253C179.711 101.241 178.17 96.9706 178.17 91.4421V70.8164H188.695V91.1231C188.695 94.3127 189.315 96.5985 190.556 97.9806C191.832 99.3628 193.568 100.054 195.765 100.054C197.963 100.054 199.681 99.3628 200.922 97.9806C202.162 96.5985 202.782 94.3127 202.782 91.1231V70.8164H213.148V91.4421C213.148 96.9706 211.607 101.241 208.523 104.253C205.44 107.266 201.152 108.772 195.659 108.772Z"
               fill="#161616"
            />
            <path
               d="M127.649 108.028L144.075 70.8164H154.441L170.92 108.028H159.97L147.105 75.9728H151.251L138.387 108.028H127.649ZM136.633 100.798L139.344 93.0369H157.524L160.235 100.798H136.633Z"
               fill="#161616"
            />
         </g>
         <path
            d="M281.655 103.94C278.678 103.94 275.931 103.462 273.415 102.505C270.899 101.548 268.701 100.202 266.823 98.4651C264.98 96.6931 263.545 94.6376 262.517 92.2986C261.49 89.9596 260.976 87.3903 260.976 84.5906C260.976 81.7909 261.49 79.2215 262.517 76.8825C263.545 74.5435 264.98 72.5058 266.823 70.7693C268.701 68.9973 270.899 67.6329 273.415 66.676C275.931 65.7192 278.678 65.2407 281.655 65.2407C284.667 65.2407 287.413 65.7192 289.894 66.676C292.41 67.6329 294.59 68.9973 296.433 70.7693C298.276 72.5058 299.711 74.5435 300.739 76.8825C301.802 79.2215 302.333 81.7909 302.333 84.5906C302.333 87.3903 301.802 89.9774 300.739 92.3518C299.711 94.6908 298.276 96.7285 296.433 98.4651C294.59 100.202 292.41 101.548 289.894 102.505C287.413 103.462 284.667 103.94 281.655 103.94ZM281.655 95.2224C283.072 95.2224 284.383 94.9743 285.588 94.4781C286.829 93.982 287.892 93.2732 288.778 92.3518C289.699 91.3949 290.408 90.2609 290.904 88.9496C291.436 87.6384 291.702 86.1854 291.702 84.5906C291.702 82.9604 291.436 81.5074 290.904 80.2315C290.408 78.9203 289.699 77.804 288.778 76.8825C287.892 75.9257 286.829 75.1992 285.588 74.703C284.383 74.2069 283.072 73.9588 281.655 73.9588C280.237 73.9588 278.908 74.2069 277.668 74.703C276.463 75.1992 275.399 75.9257 274.478 76.8825C273.592 77.804 272.883 78.9203 272.352 80.2315C271.856 81.5074 271.607 82.9604 271.607 84.5906C271.607 86.1854 271.856 87.6384 272.352 88.9496C272.883 90.2609 273.592 91.3949 274.478 92.3518C275.399 93.2732 276.463 93.982 277.668 94.4781C278.908 94.9743 280.237 95.2224 281.655 95.2224Z"
            fill="#E7D981"
         />
         <path
            d="M231.825 103.195V74.3298H220.396V65.9839H253.727V74.3298H242.351V103.195H231.825Z"
            fill="#E7D981"
         />
         <path
            d="M195.659 103.939C190.166 103.939 185.878 102.433 182.795 99.4209C179.711 96.4085 178.17 92.1381 178.17 86.6096V65.9839H188.695V86.2906C188.695 89.4801 189.315 91.766 190.556 93.1481C191.832 94.5302 193.568 95.2213 195.765 95.2213C197.963 95.2213 199.681 94.5302 200.922 93.1481C202.162 91.766 202.782 89.4801 202.782 86.2906V65.9839H213.148V86.6096C213.148 92.1381 211.607 96.4085 208.523 99.4209C205.44 102.433 201.152 103.939 195.659 103.939Z"
            fill="#E7D981"
         />
         <path
            d="M127.649 103.195L144.075 65.9839H154.441L170.92 103.195H159.97L147.105 71.1403H151.251L138.387 103.195H127.649ZM136.633 95.9655L139.344 88.2043H157.524L160.235 95.9655H136.633Z"
            fill="#E7D981"
         />
         <g opacity="0.6">
            <rect
               x="151.812"
               y="169.142"
               width="129.851"
               height="62.8579"
               fill="url(#pattern0_24155_407728)"
            />
         </g>
         <path d="M164.45 215.05V181.847H171.289V209.136H188.216V215.05H164.45Z" fill="#E7D981" />
         <path
            d="M170.876 182.261V209.55H187.806V214.637H164.867V182.261H170.88M171.703 181.434H164.036V215.464H188.629V208.723H171.699V181.434H171.703Z"
            fill="#1A1A1A"
         />
         <path d="M198.992 181.847H192.152V215.05H198.992V181.847Z" fill="#E7D981" />
         <path
            d="M198.579 182.261V214.637H192.566V182.261H198.579ZM199.406 181.434H191.739V215.464H199.406V181.434Z"
            fill="#1A1A1A"
         />
         <path
            d="M216.394 215.05L201.879 181.847H209.306L219.814 206.196L230.409 181.847H237.323L222.858 215.05H216.394Z"
            fill="#E7D981"
         />
         <path
            d="M236.695 182.261L222.589 214.637H216.667L202.516 182.261H209.037L219.814 207.239L230.686 182.261H236.699M237.956 181.434H230.14L229.925 181.93L219.818 205.159L209.798 181.934L209.583 181.434H201.255L201.759 182.592L215.91 214.968L216.126 215.464H223.131L223.346 214.968L237.452 182.592L237.956 181.434Z"
            fill="#1A1A1A"
         />
         <path
            d="M239.701 215.05V181.847H264.166V187.715H246.541V195.303H262.177V201.08H246.541V209.182H264.811V215.05H239.701Z"
            fill="#E7D981"
         />
         <path
            d="M263.752 182.26V187.301H246.132V195.721H261.763V200.671H246.132V209.599H264.402V214.64H240.119V182.265H263.752M264.58 181.433H239.292V215.463H265.229V208.768H246.959V201.494H262.59V194.89H246.959V188.124H264.58V181.429V181.433Z"
            fill="#1A1A1A"
         />
         <path
            d="M111.581 23.7803V33.5065H103.485V2.23926H117.182C123.953 2.23926 128.537 6.05808 128.537 13.0343C128.537 20.0106 123.955 23.7782 117.182 23.7782H111.581V23.7803ZM116.316 9.11518H111.581V16.9576H116.316C119.268 16.9576 120.593 15.3789 120.593 13.0364C120.593 10.6939 119.268 9.11518 116.316 9.11518Z"
            fill="#E7D981"
         />
         <path d="M140.145 33.5036H132.152V0.405762H140.145V33.5036Z" fill="#E7D981" />
         <path
            d="M156.672 19.7048C156.672 17.9234 155.042 16.6497 151.785 16.6497C149.596 16.6497 147.354 17.2108 145.522 18.2796V11.8644C147.405 10.8468 150.46 10.083 153.719 10.083C160.492 10.083 164.362 13.2916 164.362 19.3506V33.5078H156.674V32.3366C155.91 33.1003 153.721 33.9153 151.277 33.9153C146.746 33.9153 142.877 31.3701 142.877 26.5316C142.877 22.1005 146.746 19.2502 151.684 19.2502C153.619 19.2502 155.757 19.8604 156.674 20.5238V19.7048H156.672ZM156.672 25.7637C156.162 24.7952 154.89 24.185 153.463 24.185C151.885 24.185 150.306 24.8464 150.306 26.3739C150.306 27.9014 151.885 28.614 153.463 28.614C154.888 28.614 156.162 28.0038 156.672 27.0865V25.7617V25.7637Z"
            fill="#E7D981"
         />
         <path
            d="M171.49 41.6016L174.85 32.9442L166.296 10.5903H174.596L178.975 23.6255L183.252 10.5903H191.196L179.383 41.6016H171.49Z"
            fill="#E7D981"
         />
         <path
            d="M210.921 26.7352L202.877 15.5327V33.4576H194.884V2.19238H202.47L211.023 14.9225L219.628 2.19238H227.163V33.4596H219.119V15.5347L211.126 26.7373H210.921V26.7352Z"
            fill="#E7D981"
         />
         <path
            d="M244.218 19.4358C244.218 17.6543 242.589 16.3807 239.331 16.3807C237.143 16.3807 234.901 16.9417 233.068 18.0106V11.5954C234.952 10.5777 238.007 9.81396 241.266 9.81396C248.039 9.81396 251.908 13.0226 251.908 19.0815V33.2388H244.22V32.0675C243.457 32.8313 241.268 33.6463 238.824 33.6463C234.293 33.6463 230.423 31.1011 230.423 26.2625C230.423 21.8315 234.293 18.9812 239.231 18.9812C241.166 18.9812 243.303 19.5914 244.22 20.2548V19.4358H244.218ZM244.218 25.4947C243.709 24.5261 242.437 23.916 241.01 23.916C239.432 23.916 237.853 24.5773 237.853 26.1049C237.853 27.6324 239.432 28.345 241.01 28.345C242.435 28.345 243.709 27.7348 244.218 26.8174V25.4926V25.4947Z"
            fill="#E7D981"
         />
         <path
            d="M266.33 33.4559H258.386V16.7534H254.414V10.5409H258.386V4.22607H266.33V10.5409H270.809V16.7534H266.33V33.4559Z"
            fill="#E7D981"
         />
         <path
            d="M289.514 18.1295C288.546 17.6196 287.477 17.4169 286.206 17.4169C284.017 17.4169 282.539 18.3855 282.133 20.0133V33.456H274.141V10.541H282.133V12.5784C283.253 11.0509 285.037 10.0332 287.328 10.0332C288.345 10.0332 289.16 10.2359 289.516 10.4407V18.1295H289.514Z"
            fill="#E7D981"
         />
         <path
            d="M296.743 8.60616C294.298 8.60616 292.212 6.92507 292.212 4.32868C292.212 1.73229 294.301 0 296.743 0C299.185 0 301.274 1.6811 301.274 4.32868C301.274 6.97626 299.185 8.60616 296.743 8.60616ZM300.715 33.4561H292.722V10.5412H300.715V33.4561Z"
            fill="#E7D981"
         />
         <path
            d="M319.809 21.7431L327.853 33.4555H319.196L315.276 27.4969L311.355 33.4555H303.106L311.099 21.7943L303.463 10.5405H312.068L315.632 16.0404L319.248 10.5405H327.496L319.809 21.7431Z"
            fill="#E7D981"
         />
         <defs>
            <filter
               id="filter0_d_24155_407728"
               x="0"
               y="46.3262"
               width="433.272"
               height="128.816"
               filterUnits="userSpaceOnUse"
               color-interpolation-filters="sRGB"
            >
               <feFlood flood-opacity="0" result="BackgroundImageFix" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="2" dy="2" />
               <feGaussianBlur stdDeviation="2" />
               <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
               <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_24155_407728"
               />
               <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_24155_407728"
                  result="shape"
               />
            </filter>
            <pattern
               id="pattern0_24155_407728"
               patternContentUnits="objectBoundingBox"
               width="1"
               height="1"
            >
               <use transform="scale(0.00318471 0.00657895)" />
            </pattern>
            <linearGradient
               id="paint0_linear_24155_407728"
               x1="2"
               y1="108.734"
               x2="427.272"
               y2="108.734"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.04" stop-color="#010101" />
               <stop offset="0.5" stop-color="#383836" />
               <stop offset="0.63" stop-color="#333331" />
               <stop offset="0.77" stop-color="#272726" />
               <stop offset="0.91" stop-color="#121212" />
               <stop offset="1" stop-color="#010101" />
            </linearGradient>
            <linearGradient
               id="paint1_linear_24155_407728"
               x1="2"
               y1="108.734"
               x2="427.272"
               y2="108.734"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.04" stop-color="#5D5D5B" />
               <stop offset="0.5" stop-color="#E3E2E0" />
               <stop offset="0.58" stop-color="#DEDDDC" />
               <stop offset="0.67" stop-color="#D2D1D0" />
               <stop offset="0.76" stop-color="#BDBDBC" />
               <stop offset="0.85" stop-color="#A0A0A0" />
               <stop offset="0.94" stop-color="#7B7C7D" />
               <stop offset="1" stop-color="#5F6062" />
            </linearGradient>
         </defs>
      </svg>
   );
};
