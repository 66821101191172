import { Tooltip, EControl } from '@/common/ui';

import { useTrackState } from '#/modules/Track/state';
import { useTranslation } from '#/translates';

import { Control } from '../ControlWithTapSound';

import { useSwitchStrategy } from './hooks/useSwitchStrategy';

export const SwitchTrackControl = () => {
   const { t } = useTranslation();
   const { isBettingMapActive } = useTrackState();
   const useSwitchTrack = useSwitchStrategy();
   const { handleOnClick } = useSwitchTrack();

   return isBettingMapActive ? (
      <Tooltip position="left" text={t('track.bettingMap')}>
         <Control
            dataTestId="track-control"
            variant={EControl.BettingMap}
            onClick={handleOnClick}
         />
      </Tooltip>
   ) : (
      <Tooltip position="left" text={t('track.raceTrack')}>
         <Control dataTestId="track-control" variant={EControl.Racetrack} onClick={handleOnClick} />
      </Tooltip>
   );
};
