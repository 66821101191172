import { createSelector } from '@reduxjs/toolkit';
import { getTotalBetsAmount } from 'core/bets/utils';
import { useSelector } from 'react-redux';

import { IRaceTrackState } from '#/modules/Track/RaceTrack/state';
import { ETrackType } from '#/modules/Track/state';
import {
   TBetType,
   ITrackComponentState,
   TTotalBets,
   getRaceTrackTotalBets,
} from '#/state/features/bets';
import { EPlaceTrack } from '#/state/features/bets/constants';
import { TRootState, Store } from '#/state/types';

import { useBetGeneratorActions } from '../actions';

export const betGeneratorState = (store: Store) => {
   const getBetGeneratorStore = () => store?.getState().betConstructor;

   return {
      zIndexes: () => getBetGeneratorStore().zIndexes,
      totalBets: () => getBetGeneratorStore().totalBets,
      betsHistory: () => getBetGeneratorStore().betsHistory,
   };
};

const useTrackTypeSelector = (): ETrackType => {
   return useSelector(({ betConstructor }: TRootState) => betConstructor.trackType);
};

const useRaceTrackSelector = (): IRaceTrackState =>
   useSelector(
      createSelector(
         ({ betConstructor }: TRootState) => betConstructor.rangeLevel,
         (rangeLevel: number) => ({ rangeLevel }),
      ),
   );

const useTotalBetsAmountSelector = (): number => {
   return useSelector(
      createSelector((state: TRootState) => state.betConstructor.totalBets, getTotalBetsAmount),
   );
};

const useBetsHistorySelector = () => {
   return useSelector(({ betConstructor }: TRootState) => betConstructor.betsHistory);
};

const useBetByCellIdSelector = (id: number): number => {
   return useSelector(
      createSelector(
         ({ betConstructor }: TRootState) => betConstructor.totalBets,
         (bets) => bets[id],
      ),
   );
};

const useEmptyBetsSelector = (): boolean => {
   return useSelector(
      createSelector(
         ({ betConstructor }: TRootState) => betConstructor.betsHistory,
         (commands: TBetType[]) => commands.length === 0,
      ),
   );
};

const useTotalBetsSelector = (): TTotalBets => {
   return useSelector(
      createSelector(
         ({ betConstructor }: TRootState) => betConstructor.totalBets,
         (totalBets) => totalBets,
      ),
   );
};

const useRaceTrackBetsSelector = () => {
   return useSelector(
      createSelector(
         ({ betConstructor }: TRootState) => betConstructor.betsHistory,
         (commands: TBetType[]) => getRaceTrackTotalBets(commands),
      ),
   );
};

const useZIndex = (id: number): number => {
   return useSelector(
      createSelector(
         ({ betConstructor }: TRootState) => betConstructor.zIndexes,
         (zIndexes) => zIndexes[id],
      ),
   );
};

const useBetConstructorSelectors = () => {
   return {
      useZIndex,
      useBetByCellIdSelector,
      useBetsHistorySelector,
      useRaceTrackBetsSelector,
      useEmptyBetsSelector,
      useTotalBetsAmountSelector,
      useTrackTypeSelector,
      useRaceTrackSelector,
      useTotalBetsSelector,
   };
};

export const useBetConstructorState = (): ITrackComponentState => {
   const betConstructorSelectors = useBetConstructorSelectors();
   const betConstructorActions = useBetGeneratorActions();

   return {
      placeTrack: EPlaceTrack.BetGeneratorTrack,
      ...betConstructorActions,
      ...betConstructorSelectors,
   };
};
