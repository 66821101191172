import { UnknownAction, Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '@/common/types';

import { ITimerPosition } from '../types';
import { ETimerType } from '../constants';

export interface ITimerState {
   duration: TNullable<number>;
   endTime: TNullable<number>;
   position: ITimerPosition;
   startTime: TNullable<number>;
   tick: TNullable<number>;
   type: ETimerType;
}

const initialState: ITimerState = {
   tick: null,
   type: ETimerType.HORIZONTAL,
   startTime: null,
   endTime: null,
   duration: null,
   position: {
      x: 0,
      y: 0,
   },
};

const switchTypeTimer: Record<ETimerType, ETimerType> = {
   [ETimerType.HORIZONTAL]: ETimerType.CIRCULAR,
   [ETimerType.CIRCULAR]: ETimerType.HORIZONTAL,
};

export const timerSlice = createSlice({
   name: 'timer',
   initialState,
   reducers: {
      setTick: (state, action: PayloadAction<ITimerState['tick']>) => {
         state.tick = action.payload;
      },
      toggleTimerType: (state) => {
         state.type = switchTypeTimer[state.type];
      },
      savePosition: (state, action: PayloadAction<ITimerPosition>) => {
         state.position = action.payload;
      },
      setEndTime: (state, action: PayloadAction<ITimerState['endTime']>) => {
         state.endTime = action.payload;
      },
      setStartTime: (state, action: PayloadAction<ITimerState['startTime']>) => {
         state.startTime = action.payload;
      },
      updateDuration: (state, action: PayloadAction<ITimerState['duration']>) => {
         state.duration = action.payload;
      },
      setTimerType: (state, action: PayloadAction<ITimerState['type']>) => {
         state.type = action.payload;
      },
      setPartialCountDownState: (state, action: PayloadAction<Partial<ITimerState>>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      resetTimerState: (state) => {
         state.tick = null;
         state.endTime = null;
      },
   },
   selectors: {
      getTimerDuration: (state) => state.duration,
      getTimerTick: (state) => state.tick,
      getTimerEndTime: (state) => state.endTime,
   },
});

export const useTimerActions = () => {
   const dispatch: Dispatch<UnknownAction> = useDispatch();

   const {
      resetTimerState,
      setStartTime,
      setEndTime,
      savePosition,
      updateDuration,
      toggleTimerType,
      setTick,
      setTimerType,
   } = timerSlice.actions;

   const handleToggleTimerType = () => {
      dispatch(toggleTimerType());
   };

   const handleSavePosition = (position: ITimerPosition): void => {
      dispatch(savePosition(position));
   };

   const handleSetEndTime = (endTime: ITimerState['endTime']): void => {
      dispatch(setEndTime(endTime));
   };

   const handleSetStartTime = (startTime: ITimerState['startTime']): void => {
      dispatch(setStartTime(startTime));
   };

   const handleUpdateDuration = (duration: ITimerState['duration']): void => {
      dispatch(updateDuration(duration));
   };

   const handleSetTick = (tick: ITimerState['tick']): void => {
      dispatch(setTick(tick));
   };

   const handleResetTimerState = () => {
      dispatch(resetTimerState());
   };

   const handleSetTimerType = (type: ITimerState['type']) => {
      dispatch(setTimerType(type));
   };

   return {
      handleSetEndTime,
      handleSetStartTime,
      handleSavePosition,
      handleUpdateDuration,
      handleToggleTimerType,
      handleSetTick,
      handleResetTimerState,
      handleSetTimerType,
   };
};

export const useTimerTickSelector = () => useSelector(timerSlice?.selectors.getTimerTick);
export const useTimerEndTimeSelector = () => useSelector(timerSlice?.selectors.getTimerEndTime);
export const useTimerDurationSelector = () => useSelector(timerSlice?.selectors.getTimerDuration);

const useTimerSelector = (): ITimerState =>
   useSelector(({ timer }: { timer: ITimerState }) => timer);

export const useTimerState = () => {
   const { type, endTime, position, startTime, duration } = useTimerSelector() ?? {};
   const {
      handleSetEndTime,
      handleSavePosition,
      handleUpdateDuration,
      handleToggleTimerType,
      handleSetStartTime,
      handleSetTimerType,
   } = useTimerActions();

   return {
      type,
      endTime,
      position,
      startTime,
      duration,
      handleSetStartTime,
      handleSetEndTime,
      handleSavePosition,
      handleUpdateDuration,
      handleToggleTimerType,
      handleSetTimerType,
   };
};
