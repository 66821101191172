import { useAutoplay } from '#/modules/Autoplay/hooks';

import { CancelAutoplayEvent } from './schemes';

type CancelAutoplayEventOutput = (socket: CancelAutoplayEvent) => void;

export const useCancelAutoplay = (): CancelAutoplayEventOutput => {
   const { autoplay } = useAutoplay();

   return autoplay.cancelAutoplayFromServer;
};
