import { FC, ReactNode, ElementRef, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { TNullable } from '../../types';
import { useOnClickOutside } from '../../hooks';

import * as S from './Sidebar.styles';

export interface ISidebar {
   children: ReactNode;
   direction?: 'bottom' | 'left';
   handleCloseOnClickOutside?: TNullable<() => void>;
   isOpen: boolean;
   transitionDuration?: number;
}

export const Sidebar: FC<ISidebar> = ({
   isOpen,
   children,
   handleCloseOnClickOutside,
   transitionDuration = 500,
   direction = 'left',
}) => {
   const sidebarRef = useRef<ElementRef<'div'>>(null);

   useOnClickOutside({
      ref: sidebarRef,
      cb: handleCloseOnClickOutside,
      attached: Boolean(handleCloseOnClickOutside),
   });

   return (
      <CSSTransition
         in={isOpen}
         classNames="sidebar"
         mountOnEnter
         unmountOnExit
         timeout={transitionDuration}
      >
         <S.SidebarWrapper
            data-testid="sidebar"
            ref={sidebarRef}
            direction={direction}
            style={{
               '--transition-duration': `${transitionDuration}ms`,
            }}
         >
            <>{children}</>
         </S.SidebarWrapper>
      </CSSTransition>
   );
};
