import styled, { css } from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const DealerRatingWrapper = styled.div`
   --left: -30px;
   --bottom: -1px;

   position: relative;
   left: var(--left);
   bottom: var(--bottom);

   ${orientation.portrait`
      --left: -20px;
   `};

   ${orientation.mobileLandscape`
      --left: -20px;
      --bottom: 0px;
   `};
`;

export const RatingStarsWrapper = styled.div`
   display: flex;

   ${({ isActionDisabled }) =>
      isActionDisabled &&
      css`
         pointer-events: none;
      `};
`;
