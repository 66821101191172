import { FC } from 'react';

import * as S from './CircularCountDownTick.styles';

interface ICircularCountDownTickProps {
   isLastBetsState: boolean;
   tick: number;
}

export const CircularCountDownTick: FC<ICircularCountDownTickProps> = ({
   tick,
   isLastBetsState,
}) => {
   const isShowPulseAnimation = tick >= 0 && isLastBetsState;

   return (
      <S.CircularCountDownTick tick={tick} isShowPulseAnimation={isShowPulseAnimation}>
         {tick}
      </S.CircularCountDownTick>
   );
};
