import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '@/common/types';

import { TRootState } from '#/state/types';

export const enum ESendBetsStatus {
   All = 'ALL',
   Partial = 'PARTIAL',
}

interface IBetConfirmed {
   isShowNotification: boolean;
   sendBetsStatus: TNullable<ESendBetsStatus>;
   totalAmountBets: TNullable<number>;
}

const initialState: IBetConfirmed = {
   isShowNotification: false,
   totalAmountBets: null,
   sendBetsStatus: null,
};

export const betConfirmedSlice = createSlice({
   name: 'betConfirmed',
   initialState,
   reducers: {
      showNotification: (state) => {
         state.isShowNotification = true;
      },

      hideNotification: (state) => {
         state.isShowNotification = false;
      },

      updateTotalAmountBets: (state, action: PayloadAction<IBetConfirmed['totalAmountBets']>) => {
         state.totalAmountBets = action.payload;
      },

      setSendBetsStatusPartial: (state) => {
         state.sendBetsStatus = ESendBetsStatus.Partial;
      },

      setSendBetsStatusAll: (state) => {
         state.sendBetsStatus = ESendBetsStatus.All;
      },

      resetState: (state) => {
         state.isShowNotification = false;
         state.totalAmountBets = null;
         state.sendBetsStatus = null;
      },
   },
});

const useBetConfirmedActions = () => {
   const dispatch = useDispatch();

   const {
      showNotification,
      hideNotification,
      updateTotalAmountBets,
      setSendBetsStatusPartial,
      setSendBetsStatusAll,
      resetState,
   } = betConfirmedSlice.actions;

   const handleShowNotification = () => {
      dispatch(showNotification());
   };

   const handleHideNotification = () => {
      dispatch(hideNotification());
   };

   const handleSetSendBetsStatusAll = () => {
      dispatch(setSendBetsStatusAll());
   };

   const handleSetSendBetsStatusPartial = () => {
      dispatch(setSendBetsStatusPartial());
   };

   const handleUpdateTotalAmountBets = (totalAmountBets: IBetConfirmed['totalAmountBets']) => {
      dispatch(updateTotalAmountBets(totalAmountBets));
   };

   const handleResetState = () => {
      dispatch(resetState());
   };

   return {
      handleShowNotification,
      handleHideNotification,
      handleUpdateTotalAmountBets,
      handleSetSendBetsStatusAll,
      handleSetSendBetsStatusPartial,
      handleResetState,
   };
};

const useBetConfirmedSelector = () => useSelector(({ betConfirmed }: TRootState) => betConfirmed);

export const useBetConfirmed = () => {
   const { sendBetsStatus, totalAmountBets, isShowNotification } = useBetConfirmedSelector() ?? {};
   return {
      isSendAllBets: sendBetsStatus === ESendBetsStatus.All,
      totalAmountBets,
      isShowNotification,
      ...useBetConfirmedActions(),
   };
};
