import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '../../types';

export enum EGameState {
   MAKE_BET = 'MAKE_BET',
   NO_MORE_BETS = 'NO_MORE_BETS',
   LAST_BETS = 'LAST_BETS',
   WIN_NUMBER = 'WIN_NUMBER',
   ROUND_RESULT = 'ROUND_RESULT',
   WAIT_NEXT_ROUND = 'WAIT_NEXT_ROUND',
   ROUND_CANCELLED = 'CANCELLED',
   IN_PROGRESS = 'IN_PROGRESS',
   ROUND_WINNERS = 'ROUND_WINNERS',
}

export const enum EGameStatus {
   INITIAL = 'INITIAL',
   LOADING = 'LOADING',
   SUCCESS = 'SUCCESS',
   ERROR = 'ERROR',
   WAIT_FOR_CONNECTION = 'WAIT_FOR_CONNECTION',
   MAINTENANCE = 'MAINTENANCE',
   INACTIVE_TABLE = 'INACTIVE_TABLE',
   SESSION_CLOSED = 'SESSION_CLOSED',
   LOST_CONNECTION = 'LOST_CONNECTION',
   MAINTENANCE_IS_PENDING = 'PENDING',
   MAINTENANCE_IN_PROGRESS = 'STARTED',
   MAINTENANCE_IS_ENDED = 'ENDED',
}

export const enum ESessionClosedReason {
   KickInactiveGameTablePlayer = 'KickInactiveGameTablePlayer',
   LoginFromAnotherPlace = 'LoginFromAnotherPlace',
}

interface IGameState {
   gameState: EGameState;
   gameStatus: EGameStatus;
   isNotifyInactivePlayer: boolean;
   sessionClosedReason: TNullable<ESessionClosedReason>;
}

const initialState: IGameState = {
   gameStatus: EGameStatus.INITIAL,
   gameState: EGameState.WAIT_NEXT_ROUND,
   sessionClosedReason: null,
   isNotifyInactivePlayer: false,
};

export const gameStateSlice = createSlice({
   name: 'gameState',
   initialState,
   reducers: {
      changeGameState: (state, action) => {
         state.gameState = action.payload;
      },

      setGameStatusAsError: (state) => {
         state.gameStatus = EGameStatus.ERROR;
      },

      setGameStatusAsLoading: (state) => {
         state.gameStatus = EGameStatus.LOADING;
      },

      setGameStatusAsSuccess: (state) => {
         state.gameStatus = EGameStatus.SUCCESS;
      },

      setNotifyInactivePlayer: (
         state,
         action: PayloadAction<IGameState['isNotifyInactivePlayer']>,
      ) => {
         state.isNotifyInactivePlayer = action.payload;
      },

      setGameStatusAsSessionClosed: (
         state,
         action: PayloadAction<IGameState['sessionClosedReason']>,
      ) => {
         state.gameStatus = EGameStatus.SESSION_CLOSED;
         state.sessionClosedReason = action.payload;
      },

      setWaitingForConnection: (state) => {
         state.gameStatus = EGameStatus.WAIT_FOR_CONNECTION;
      },

      setMaintenanceMode: (state) => {
         state.gameStatus = EGameStatus.MAINTENANCE;
      },

      setInactiveGameTableMode: (state) => {
         state.gameStatus = EGameStatus.INACTIVE_TABLE;
      },

      setLostConnectionMode: (state) => {
         state.gameStatus = EGameStatus.LOST_CONNECTION;
      },

      setMaintenanceIsPending: (state) => {
         state.gameStatus = EGameStatus.MAINTENANCE_IS_PENDING;
      },

      setMaintenanceInProgress: (state) => {
         state.gameStatus = EGameStatus.MAINTENANCE_IN_PROGRESS;
      },

      setMaintenanceEnded: (state) => {
         state.gameStatus = EGameStatus.MAINTENANCE_IS_ENDED;
      },
   },
   selectors: {
      getGameState: (state) => state.gameState,
      getGameStatus: (state) => state.gameStatus,
      getSessionClosedReason: (state) => state.sessionClosedReason,
      getIsNotifyInactivePlayer: (state) => state.isNotifyInactivePlayer,
   },
});

export const useGameStateActions = () => {
   const dispatch = useDispatch();
   const {
      changeGameState,
      setMaintenanceMode,
      setGameStatusAsSessionClosed,
      setWaitingForConnection,
      setGameStatusAsSuccess,
      setGameStatusAsError,
      setNotifyInactivePlayer,
      setLostConnectionMode,
      setMaintenanceIsPending,
      setMaintenanceInProgress,
      setMaintenanceEnded,
      setInactiveGameTableMode,
   } = gameStateSlice.actions;

   const handleChangeGameState = (digitalTableState: string): void => {
      dispatch(changeGameState(digitalTableState));
   };

   const handleSetSessionClosed = (reason: ESessionClosedReason): void => {
      dispatch(setGameStatusAsSessionClosed(reason));
   };

   const handleSetGameStatusAsSuccess = (): void => {
      dispatch(setGameStatusAsSuccess());
   };

   const handleSetInactiveGameTableMode = (): void => {
      dispatch(setInactiveGameTableMode());
   };

   const handleSetWaitingForConnection = (): void => {
      dispatch(setWaitingForConnection());
   };

   const handleSetGameStatusAsError = (): void => {
      dispatch(setGameStatusAsError());
   };

   const handleSetMaintenanceMode = (): void => {
      dispatch(setMaintenanceMode());
   };

   const handleSetNotifyInactivePlayer = () => {
      dispatch(setNotifyInactivePlayer(true));
   };

   const handleUnsetNotifyInactivePlayer = () => {
      dispatch(setNotifyInactivePlayer(false));
   };

   const handleSetLostConnectionMode = () => {
      dispatch(setLostConnectionMode());
   };
   const handleSetMaintenanceIsPending = () => {
      dispatch(setMaintenanceIsPending());
   };
   const handleSetMaintenanceInProgress = () => {
      dispatch(setMaintenanceInProgress());
   };
   const handleSetMaintenanceEnded = () => {
      dispatch(setMaintenanceEnded());
   };

   return {
      handleChangeGameState,
      handleSetMaintenanceMode,
      handleSetSessionClosed,
      handleSetWaitingForConnection,
      handleSetGameStatusAsSuccess,
      handleSetGameStatusAsError,
      handleSetNotifyInactivePlayer,
      handleUnsetNotifyInactivePlayer,
      handleSetLostConnectionMode,
      handleSetMaintenanceIsPending,
      handleSetMaintenanceInProgress,
      handleSetMaintenanceEnded,
      handleSetInactiveGameTableMode,
   };
};

export const useSessionClosedReason = () =>
   useSelector(gameStateSlice.selectors.getSessionClosedReason);
export const useGameStateSelector = () => useSelector(gameStateSlice.selectors.getGameState);
export const useGameStatusSelector = () => useSelector(gameStateSlice.selectors.getGameStatus);
export const useNotifyInactivePlayer = () =>
   useSelector(gameStateSlice.selectors.getIsNotifyInactivePlayer);

export const useGameState = () => {
   const gameState = useGameStateSelector() ?? {};
   const gameStatus = useGameStatusSelector();
   const closeSessionReason = useSessionClosedReason();

   const {
      handleChangeGameState,
      handleSetMaintenanceMode,
      handleSetMaintenanceIsPending,
      handleSetMaintenanceInProgress,
      handleSetMaintenanceEnded,
   } = useGameStateActions();

   const startRound = () => handleChangeGameState(EGameState.MAKE_BET);
   const toggleToWaitForTheNextRound = () => handleChangeGameState(EGameState.WAIT_NEXT_ROUND);

   const isBettingTimeState = gameState === EGameState.MAKE_BET;
   const isNoMoreBetsState = gameState === EGameState.NO_MORE_BETS;
   const isLastBetsState = gameState === EGameState.LAST_BETS;
   const isRoundResultState = gameState === EGameState.ROUND_RESULT;
   const isWaitForTheNextRoundState = gameState === EGameState.WAIT_NEXT_ROUND;
   const isRoundCancelledState = gameState === EGameState.ROUND_CANCELLED;
   const isWinNumberState = gameState === EGameState.WIN_NUMBER;
   const isRoundWinnersState = gameState === EGameState.ROUND_WINNERS;

   const isLoadingGameStatus = gameStatus === EGameStatus.LOADING;
   const isErrorGameStatus = gameStatus === EGameStatus.ERROR;
   const isSuccessGameStatus = gameStatus === EGameStatus.SUCCESS;
   const isSessionClosedStatus = gameStatus === EGameStatus.SESSION_CLOSED;

   const isWaitingForConnectionGameStatus = gameStatus === EGameStatus.WAIT_FOR_CONNECTION;
   const isMaintenanceMode = gameStatus === EGameStatus.MAINTENANCE;
   const isInactiveGameTableMode = gameStatus === EGameStatus.INACTIVE_TABLE;
   const isConnectionLostMode = gameStatus === EGameStatus.LOST_CONNECTION;
   const isSessionClosedDueToInactivity =
      closeSessionReason === ESessionClosedReason.KickInactiveGameTablePlayer;
   const isLoginFromAnotherPlace =
      closeSessionReason === ESessionClosedReason.LoginFromAnotherPlace;
   const isGameInProgress = gameState === EGameState.IN_PROGRESS;

   const isMaintenanceStarted =
      gameStatus === EGameStatus.MAINTENANCE_IS_PENDING ||
      gameStatus === EGameStatus.MAINTENANCE_IN_PROGRESS;
   const isMaintenancePending = gameStatus === EGameStatus.MAINTENANCE_IS_PENDING;
   const isMaintenanceInProgress = gameStatus === EGameStatus.MAINTENANCE_IN_PROGRESS;
   const isMaintenanceEnded = gameStatus === EGameStatus.MAINTENANCE_IS_ENDED;

   return {
      gameState,
      gameStatus,
      startRound,
      isErrorGameStatus,
      isMaintenanceMode,
      isNoMoreBetsState,
      isLastBetsState,
      isMaintenanceEnded,
      isBettingTimeState,
      isRoundResultState,
      isLoadingGameStatus,
      isSuccessGameStatus,
      isConnectionLostMode,
      handleChangeGameState,
      handleSetMaintenanceMode,
      isRoundCancelledState,
      isWinNumberState,
      isSessionClosedStatus,
      isWaitForTheNextRoundState,
      isRoundWinnersState,
      toggleToWaitForTheNextRound,
      isWaitingForConnectionGameStatus,
      isInactiveGameTableMode,
      isSessionClosedDueToInactivity,
      isLoginFromAnotherPlace,
      isGameInProgress,
      isMaintenanceStarted,
      isMaintenancePending,
      isMaintenanceInProgress,
      handleSetMaintenanceIsPending,
      handleSetMaintenanceInProgress,
      handleSetMaintenanceEnded,
   };
};
