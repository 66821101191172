import { useEffect, useRef, useState } from 'react';

import { TSetTimeout, TNullable } from '@/common/types';

interface ILongPress {
   callback: () => void;
   delay?: number;
}

interface ILongPressHandlers {
   onMouseDown: () => void;
   onMouseLeave: () => void;
   onMouseUp: () => void;
   onTouchEnd: () => void;
   onTouchStart: () => void;
}

export const useLongPress = ({ callback, delay = 1000 }: ILongPress): ILongPressHandlers => {
   const [startLongPress, setStartLongPress] = useState(false);
   const timer = useRef<TNullable<TSetTimeout>>(null);

   useEffect(() => {
      if (startLongPress) {
         timer.current = setTimeout(callback, delay);
      } else if (timer.current) {
         clearTimeout(timer.current);
      }

      return () => {
         if (timer.current) {
            clearTimeout(timer.current);
         }
      };
   }, [callback, delay, startLongPress]);

   return {
      onMouseDown: () => setStartLongPress(true),
      onMouseUp: () => setStartLongPress(false),
      onMouseLeave: () => setStartLongPress(false),
      onTouchStart: () => setStartLongPress(true),
      onTouchEnd: () => setStartLongPress(false),
   };
};
