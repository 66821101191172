import { safeJsonParse } from '@/common/utils/safeJsonParse';

import type { DataTransfer } from './types';

const DATA_TRANSFER_NAME = 'draggableCommand';

type SetDataTransfer = (params: { event: DragEvent; data: DataTransfer }) => void;
type GetDataTransfer = (event: DragEvent) => DataTransfer;

export const useDataTransfer = (): {
   setDataTransfer: SetDataTransfer;
   getDataTransfer: GetDataTransfer;
} => {
   const setDataTransfer: SetDataTransfer = ({
      event,
      data,
   }: {
      event: DragEvent;
      data: DataTransfer;
   }): void => {
      const { dataTransfer } = event;

      if (!dataTransfer) {
         return;
      }

      dataTransfer.dropEffect = 'move';
      dataTransfer.effectAllowed = 'move';
      dataTransfer.setData(DATA_TRANSFER_NAME, JSON.stringify(data));
   };

   const getDataTransfer: GetDataTransfer = (event) => {
      const { dataTransfer } = event;

      if (!dataTransfer) {
         return;
      }

      return safeJsonParse(dataTransfer.getData(DATA_TRANSFER_NAME));
   };

   return {
      setDataTransfer,
      getDataTransfer,
   };
};
