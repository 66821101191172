import { FC } from 'react';

import { ErrorBoundary } from '../../dev/ErrorBoundary';

import * as S from './AmountInfo.styles';

interface IAmountInfoProps {
  amount: number | string;
  amountColor?: string;
  amountDataTestId: string;
  title: string;
  titleColor?: string;
  titleDataTestId: string;
}
export const AmountInfo: FC<IAmountInfoProps> = ({
  title,
  amount,
  amountColor,
  amountDataTestId,
  titleDataTestId,
  titleColor,
}) => (
  <ErrorBoundary>
    <S.AmountInfoContainer>
      <S.AmountInfoInnerContainer>
        <S.AmountInfoTitle data-testid={titleDataTestId} color={titleColor}>
          {title}:{' '}
        </S.AmountInfoTitle>
        <S.AmountInfoValue data-testid={amountDataTestId} color={amountColor}>
          {' '}
          {amount}
        </S.AmountInfoValue>
      </S.AmountInfoInnerContainer>
    </S.AmountInfoContainer>
  </ErrorBoundary>
);
