// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const CloseChat = styled.div`
   position: absolute;
   right: 5px;
   top: 5px;
   z-index: 1;

   ${orientation.portrait`
      > :first-child {
         margin: 0;
      }
   `}
`;
