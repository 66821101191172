import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '@/common/types';

interface IWinNumberState {
   winNumber: TNullable<string>;
}

const initialState: IWinNumberState = {
   winNumber: null,
};

export const winNumberSlice = createSlice({
   name: 'winNumber',
   initialState,
   reducers: {
      setWinNumber: (state, action: PayloadAction<IWinNumberState['winNumber']>): void => {
         state.winNumber = action.payload;
      },
   },
   selectors: {
      getWinNumber: (state) => state.winNumber,
   },
});

const useWinNumberActions = () => {
   const dispatch = useDispatch();
   const { setWinNumber } = winNumberSlice.actions;

   const handleSetWinNumber = (winNumber: IWinNumberState['winNumber']): void => {
      dispatch(setWinNumber(winNumber));
   };

   const handleResetWinNumber = (): void => {
      dispatch(setWinNumber(null));
   };

   return { handleSetWinNumber, handleResetWinNumber };
};

const useWinNumberSelector = () => useSelector(winNumberSlice.selectors.getWinNumber);

export const useWinNumber = () => {
   const winNumber = useWinNumberSelector();

   const { handleSetWinNumber, handleResetWinNumber } = useWinNumberActions();

   return {
      winNumber,
      handleSetWinNumber,
      handleResetWinNumber,
   };
};
