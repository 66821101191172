// @ts-nocheck
import styled from 'styled-components/macro';

export const SideControls = styled.div`
   display: flex;
   flex-direction: column;
   position: relative;
   margin-bottom: 50px;

   > :where(button) {
      margin-bottom: 10px;
   }
`;

export const LeftColumn = styled.div`
   grid-area: left-column;
`;

export const CenterColumn = styled.div`
   grid-area: center-column;
`;

export const RightColumn = styled.div`
   grid-area: right-column;
`;

export const BottomColumn = styled.div`
   --gap: 35px;

   position: relative;
   width: calc(100% + (var(--gap) * 2));
   left: calc(0px - var(--gap));
   grid-area: bottom-column;
`;

export const GridContainer = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) scale(var(--scale));
   height: 375px;
   display: grid;
   grid-template-areas:
      'left-column center-column right-column'
      'bottom-column bottom-column bottom-column';
   grid-template-columns: 45px 1fr 45px;
   grid-template-rows: 90% 10%;
   grid-gap: 5px 20px;
   aspect-ratio: 16 / 9;

   &.loading {
      top: 0%;
      left: 0%;
      height: 100%;
      transform: none;
      backdrop-filter: blur(10px);
   }
`;
