import { ElementRef, useEffect, useRef } from 'react';

import { TNullable } from '@/common/types';

import { BETTING_MAP_CLASSNAME } from '#/modules/Track/BettingMap/constants';
import { getCellsIdFromDataAttrs } from '#/modules/Track/BettingMap/helpers';

const HIGHLIGHT_CLASSNAME = 'highlight';

export const useHoverSpecialBet = () => {
   const ref = useRef<TNullable<ElementRef<'div'>>>(null);
   const cellsListRef = useRef<Array<TNullable<ElementRef<'div'>>>>([]);
   const bettingMapRef = useRef<TNullable<ElementRef<'div'>>>(null);

   useEffect(() => {
      bettingMapRef.current = document.querySelector(`.${BETTING_MAP_CLASSNAME}`);
   }, []);

   useEffect(() => {
      const node = ref.current;
      let cellsList: Array<TNullable<HTMLDivElement>> = cellsListRef.current;

      const handleMouseOver = (event: MouseEvent): void => {
         const highlightCellsIds = getCellsIdFromDataAttrs(
            event.target as HTMLElement,
            'highlightCells',
         );

         cellsList =
            highlightCellsIds.map((id: string) => {
               if (!bettingMapRef.current) {
                  return null;
               }

               const node = bettingMapRef.current.querySelector<HTMLDivElement>(`.cell_${id}`);
               if (node) {
                  node?.classList.add(HIGHLIGHT_CLASSNAME);
               }

               return node;
            }) || [];
      };

      const handleMouseLeave = (): void => {
         cellsList.forEach((elem) => {
            elem?.classList?.contains(HIGHLIGHT_CLASSNAME) &&
               elem?.classList?.remove(HIGHLIGHT_CLASSNAME);
         });
         cellsList.length = 0;
      };

      node?.addEventListener('mouseover', handleMouseOver);
      node?.addEventListener('mouseout', handleMouseLeave);

      return () => {
         node?.removeEventListener('mouseover', handleMouseOver);
         node?.removeEventListener('mouseout', handleMouseLeave);
      };
   }, []);

   return ref;
};
