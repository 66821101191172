import { useDispatch, useSelector } from 'react-redux';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TChip } from '@/common/ui/Chip/entities';

interface IChipTrayState {
   openedSliderFirstTime: boolean;
   selectedChip: TChip;
}

const initialState: IChipTrayState = {
   selectedChip: {
      id: '',
      isDefault: true,
      value: 0,
      primaryColor: '',
   },
   openedSliderFirstTime: false,
};

export const chipTraySlice = createSlice({
   name: 'chipTray',
   initialState,
   reducers: {
      selectChip: (state, action: PayloadAction<IChipTrayState['selectedChip']>) => {
         state.selectedChip = action.payload;
      },

      setOpenedSliderFirstTime: (
         state,
         action: PayloadAction<IChipTrayState['openedSliderFirstTime']>,
      ) => {
         state.openedSliderFirstTime = action.payload;
      },
   },
   selectors: {
      getSelectedChip: (state) => state.selectedChip,
      getOpenedSliderFirstTime: (state) => state.openedSliderFirstTime,
   },
});

export const useChipTrayActions = () => {
   const dispatch = useDispatch();
   const { selectChip, setOpenedSliderFirstTime } = chipTraySlice.actions;

   const handleSelectChip = (selectedChipId: IChipTrayState['selectedChip']) => {
      dispatch(selectChip(selectedChipId));
   };

   const handleSetOpenedSliderFirstTime = (value: boolean) => {
      dispatch(setOpenedSliderFirstTime(value));
   };

   return { handleSelectChip, handleSetOpenedSliderFirstTime };
};

export const useSelectedChipSelector = () => useSelector(chipTraySlice.selectors.getSelectedChip);
export const useOpenedSliderFirstTimeSelector = () =>
   useSelector(chipTraySlice.selectors.getOpenedSliderFirstTime);
