import styled from 'styled-components/macro';

export const AnnouncementsWrapper = styled.div`
   position: fixed;
   top: 30px;
   left: 50%;
   height: 100px;
   color: rgb(255, 255, 255);
   font-size: 24px;
   text-align: center;
   background: linear-gradient(
      270deg,
      rgba(3, 3, 3, 0) 3.33%,
      rgb(3, 3, 3) 32.61%,
      rgb(3, 3, 3) 62.17%,
      rgba(3, 3, 3, 0) 94.93%
   );
   transform: translate(-50%, 0);
   display: flex;
   align-items: center;
`;
