import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '@/common/types';

import { TRootState } from '#/state/types';

import { StatisticByRange } from './types';

export enum EStatisticsTab {
   STATISTICS = 'statistics',
   LAST_RESULT = 'lastResults',
}

const initialState: {
   statisticType: EStatisticsTab;
   statistics: TNullable<StatisticByRange>;
   statisticRange: number;
} = { statistics: null, statisticRange: 100, statisticType: EStatisticsTab.STATISTICS };

export const statisticsSlice = createSlice({
   name: 'statistics',
   initialState,
   reducers: {
      setStatisticType: (state, action) => {
         state.statisticType = action.payload;
      },
      updateStatistics: (state, action) => {
         state.statistics = action.payload;
      },
      updateStatisticsRange: (state, action) => {
         state.statisticRange = action.payload;
      },
   },
});

export const useStatisticsActions = () => {
   const dispatch = useDispatch();
   const { updateStatistics, updateStatisticsRange, setStatisticType } = statisticsSlice.actions;

   const handleUpdateStatistics = (statistics): void => {
      dispatch(updateStatistics(statistics));
   };

   const handleUpdateStatisticsFromSocket = (statistics): void => {
      dispatch(updateStatistics(statistics));
   };

   const handleUpdateStatisticsRange = (statistics): void => {
      dispatch(updateStatisticsRange(statistics));
   };

   const handleSetStatisticType = (statistics): void => {
      dispatch(setStatisticType(statistics));
   };

   return {
      handleUpdateStatistics,
      handleUpdateStatisticsRange,
      handleUpdateStatisticsFromSocket,
      handleSetStatisticType,
   };
};
export const useStatisticsTypeSelector = () =>
   useSelector(({ statistics }: TRootState) => statistics?.statisticType);
export const useStatisticsSelector = () =>
   useSelector(({ statistics }: TRootState) => statistics?.statistics);

export const useStatisticsRangeSelector = () =>
   useSelector(({ statistics }: TRootState) => statistics?.statisticRange);
