import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { TRootState, Store } from '#/state/types';

export const balanceState = (store: Store) => {
   const getBalanceState = () => store?.getState?.()?.balance;

   return {
      balance: () => getBalanceState().balance,
      initialBalance: () => getBalanceState().initialBalance,
   };
};

export const useBalanceSelector = () => useSelector((state: TRootState) => state.balance?.balance);

export const useTotalBetsAmountSelector = () =>
   useSelector(
      createSelector(
         (state: TRootState) => state.balance?.balance,
         (state: TRootState) => state.balance?.initialBalance,
         (balance, initialBalance) => initialBalance - balance,
      ),
   );

export const useShowInsufficientBalanceNotification = () =>
   useSelector(
      createSelector(
         (state: TRootState) => state.balance.showInsufficientBalanceNotification,
         (showInsufficientBalanceNotification) => showInsufficientBalanceNotification,
      ),
   );
