// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const Gradient = styled.div`
   display: none;

   ${orientation.portrait`
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: ${({ top }) => `${top}px`};
        background: linear-gradient(180deg,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 1) 100%);
        height: ${({ height }) => `${height}px`};
        filter: drop-shadow(0px 20px 10px #000000);
    `}
`;
