import { useTimerState } from '@/common/modules/CountDown/state';
import { ETimerType } from '@/common/modules/CountDown/constants';
import { CircularCountDownContainer } from '@/common/modules/CountDown/components/CircularCountDown/CircularCountDownContainer';

import { HorizontalCountDownContainer } from '../components/HorizontalCountDownContainer';

export const useGetCountDown = () => {
   const { type } = useTimerState();

   const countDowns = {
      [ETimerType.CIRCULAR]: CircularCountDownContainer,
      [ETimerType.HORIZONTAL]: HorizontalCountDownContainer,
   };

   return {
      type,
      TimerComponent: countDowns[type],
   };
};
