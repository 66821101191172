import { logger } from '../logger';
import { uuid } from '../uuid';

export class TimeSync {
   private serverTimeDelta: number = 0;
   private INITIAL_RETRIES_COUNTER: number = 0;
   private MAX_RETRIES: number = 3;

   calculateDelta = ({
      serverAcceptedRequestTime,
      clientSentRequestTime,
      serverSentResponseTime,
      clientReceiveResponseTime,
   }): number =>
      (serverAcceptedRequestTime -
         clientSentRequestTime +
         (serverSentResponseTime - clientReceiveResponseTime)) /
      2;

   private setServerTimeDelta = (value: number) => {
      this.serverTimeDelta = value;
   };

   fetchServerTimeDelta = async (url: string, retries = this.INITIAL_RETRIES_COUNTER) => {
      if (this.serverTimeDelta) {
         return;
      }

      try {
         const response = await fetch(url, {
            headers: {
               'content-type': 'application/json',
            },
            body: JSON.stringify({ id: uuid(), t0: Date.now() }),
            method: 'POST',
         });

         const {
            data: {
               t0: clientSentRequestTime,
               t1: serverAcceptedRequestTime,
               t2: serverSentResponseTime,
            },
         } = await response.json();

         const clientReceiveResponseTime = Date.now();
         const serverTimeDelta = this.calculateDelta({
            serverAcceptedRequestTime,
            clientSentRequestTime,
            serverSentResponseTime,
            clientReceiveResponseTime,
         });

         this.setServerTimeDelta(serverTimeDelta);
      } catch (error) {
         const increasedRetryCounter = retries + 1;
         const isMaxRetriesReached = retries >= this.MAX_RETRIES;

         if (!isMaxRetriesReached) {
            this.fetchServerTimeDelta(url, increasedRetryCounter);
            return;
         }

         logger.error('Something went wrong in TimeSync.fetchServerTimeDelta');
         throw new Error('Something went wrong in TimeSync.fetchServerTimeDelta');
      }
   };

   getServerTimeDelta = async () => {
      return this.serverTimeDelta;
   };
}
