import { Tooltip, MenuControlView } from '@/common/ui';
import { useSidebar } from '@/common/modules/Sidebar';
import { ERouletteSidebar } from '@/common/entities/Sidebar';

import { useTranslation } from '#/translates';
import { withTapSound } from '#/hocs/withTapSound';

export const MenuControl = () => {
   const { sidebarType, handleSetSidebarType } = useSidebar();
   const { t } = useTranslation();

   return (
      <Tooltip position="right" text={t('sidebars.menu.title')}>
         <Menu
            isActive={sidebarType === ERouletteSidebar.Menu}
            onClick={() => handleSetSidebarType(ERouletteSidebar.Menu)}
         />
      </Tooltip>
   );
};

const Menu = withTapSound(MenuControlView);
