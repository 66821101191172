// @ts-nocheck
import styled from 'styled-components/macro';

import * as ChipTrayStyles from '@/common/modules/ChipTray/chiptray/ChipTray.styles';

export const MobileChipTrayContainer = styled.div`
   position: relative;
   opacity: ${({ isShow }) => (isShow ? 1 : 0)};
   pointer-events: ${({ isShow }) => (isShow ? 'unset' : 'none')};

   &.horizontal {
      .swiper {
         width: 100%;
         height: ${({ height }) => height};
         gap: 0;
      }

      ${ChipTrayStyles.ChipTrayWrapper} {
         width: ${({ width }) => width};
      }
   }

   &.vertical {
      margin: 0;
      .swiper-wrapper {
         margin: 0;
      }
      .swiper {
         width: ${({ width }) => width};
         height: ${({ height }) => height} !important;
         display: flex;
         justify-content: center;
      }
   }
`;
