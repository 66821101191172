import { FC } from 'react';

import { EModalNotificationType } from '../../constants';

import { ErrorIcon, WarningIcon } from './assets';
import * as S from './Icon.styles';

interface IIconProps {
  type: EModalNotificationType;
}
export const Icon: FC<IIconProps> = ({ type }) => {
  const icon = {
    [EModalNotificationType.Error]: <ErrorIcon />,
    [EModalNotificationType.Warning]: <WarningIcon />,
  };

  return <S.Icon>{icon[type]}</S.Icon>;
};
