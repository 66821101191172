import tapSound from '@/common/assets/sounds/tap.sound.aac';
import chipSound from '@/common/assets/sounds/chip.sound.aac';
import winSound from '@/common/assets/sounds/win.sound.aac';
import {
   AudioController,
   GeneralSoundControl,
   SoundVolumeAndRangeController,
   playSound,
} from '@/common/modules/GameSounds';
import { dolbyController } from '@/common/modules/LiveStreamPlayer/Dolby/DolbyController';

export const gameSettingsSounds = new SoundVolumeAndRangeController({
   defaultSoundVolumeValue: 0.5,
}); // sound setting object for slider game
export const dealerSettingsSounds = new SoundVolumeAndRangeController({
   defaultSoundVolumeValue: 1,
}); // sound setting object for slider video
export const generalSettingSound = new SoundVolumeAndRangeController({
   defaultSoundVolumeValue: 1,
}); // sound setting object for general slider
export const announcementsSounds = new SoundVolumeAndRangeController({
   defaultSoundVolumeValue: 1,
}); // sound setting object for announcement slider
export const generalVolume = new GeneralSoundControl({
   // object for general slider which interacts with dependent objects
   dependentSounds: [gameSettingsSounds, dealerSettingsSounds, announcementsSounds],
   generalSound: generalSettingSound,
   dolbyController,
});

export const tap = new AudioController({
   // object for specific action which knows about action sound and its volume based on given sound setting object
   soundUrl: tapSound,
   basedVolume: gameSettingsSounds,
});

export const chip = new AudioController({
   soundUrl: chipSound,
   basedVolume: gameSettingsSounds,
});

export const win = new AudioController({
   soundUrl: winSound,
   basedVolume: gameSettingsSounds,
});

export const playTapSound = () => {
   playSound(tap);
};
export const playWinSound = () => playSound(win);
export const playChipSound = () => playSound(chip);
