import { Howl, HowlOptions } from 'howler';

import { SoundVolumeAndRangeController } from '.';

export class AudioController {
   public audio: Howl;
   private basedVolume: SoundVolumeAndRangeController;
   private readonly soundMaxValue: number = 0.8; // as sound of video is quiter then sound of game it was decided to decrease game sound to 80%

   constructor({
      soundUrl,
      basedVolume,
      options,
   }: {
      soundUrl: string;
      basedVolume: SoundVolumeAndRangeController;
      options?: Omit<HowlOptions, 'src'>;
   }) {
      this.audio = this.setSound(soundUrl, options);
      this.basedVolume = basedVolume;
   }

   private setSound = (soundUrl: string, options: Omit<HowlOptions, 'src'> = {}): Howl => {
      this.audio = new Howl({
         src: soundUrl,
         ...options,
      });
      return this.audio;
   };

   public play = (): void => {
      if (this.audio) {
         this.audio.volume(this.basedVolume.currentVolume * this.soundMaxValue);
         this.audio.play();
      }
   };
}
