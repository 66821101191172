// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex } from '@/common/ui/theme/mixins';

export const WarningNotificationWrapper = styled.div`
   ${applyZIndex('biggest')};

   background: rgba(0, 0, 0, 0.9);
   border: 1px solid rgba(255, 187, 61, 0.3);
   box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 7px;
   font-size: 14px;
   position: absolute;
   padding: 10px;
   width: 280px;
   top: -100%;
   left: -100%;
   color: #fff;
   display: flex;
   align-items: center;
   justify-content: space-between;
   pointer-events: none;
   scale: var(--scale);
   transform-origin: bottom;
   opacity: ${({ shouldShowNotification }) => (shouldShowNotification ? '1' : '0')};
`;

export const WarningNotificationContent = styled.div`
   width: 220px;
`;
