import { callBetsConfig } from 'core/config';

import {
   TGameTableColors,
   TLogo,
} from '@/common/services/api/gameTablesAPI/hooks/useGetGameTableById/types';
import { TNullable } from '@/common/types';
import { defaultBettingMapColorsConfig } from '@/common/roulette/constants';
import { reqExpValidation } from '@/common/utils';
import { TChip } from '@/common/ui/Chip/entities';
import { ETimerType } from '@/common/modules/CountDown';
import { TRouletteGameConfig } from '@/common/services/api/gameConfigAPI/types';

import { ETypeBet } from '#/state/features/bets';
import { IGameConfig } from '#/services/api/gameConfigApiSlice/state/gameConfig';

import { addCallBetsLimits } from '../utils/addCallBetsLimits';

type BetLimitItem = TRouletteGameConfig['bet_type_limits'][number];

const transformLimits = (limits: TRouletteGameConfig['bet_type_limits']) => {
   const TYPES = {
      STRAIGHT: 'straight',
      RED_BLACK: 'color',
      LOW_HIGH: 'highLow',
      EVEN_ODD: 'parity',
      SPLIT: 'split',
      STREET: 'street',
      COLUMN: 'column',
      DOZEN: 'dozen',
      LINE: 'line',
      CORNER: 'corner',
   };

   const transformBetType = (limit: BetLimitItem) => {
      const { bet_type, min, max } = limit;

      switch (bet_type) {
         case TYPES.STRAIGHT:
         case TYPES.SPLIT:
         case TYPES.STREET:
         case TYPES.COLUMN:
         case TYPES.DOZEN:
         case TYPES.LINE:
         case TYPES.CORNER:
            return {
               [limit.bet_type.toUpperCase()]: { min, max },
            };
         case TYPES.RED_BLACK:
            return {
               [ETypeBet.RED]: { min, max },
               [ETypeBet.BLACK]: { min, max },
            };

         case TYPES.LOW_HIGH:
            return {
               [ETypeBet.LOW]: { min, max },
               [ETypeBet.HIGH]: { min, max },
            };
         case TYPES.EVEN_ODD:
            return {
               [ETypeBet.EVEN]: { min, max },
               [ETypeBet.ODD]: { min, max },
            };
      }
   };

   const betLimits = Object.entries(limits).reduce((total, [_, limit]) => {
      // eslint-disable-next-line no-param-reassign
      total = { ...total, ...transformBetType(limit) };

      return total;
   }, {});

   const betLimitsWithCallBets = addCallBetsLimits(betLimits, callBetsConfig);

   return betLimitsWithCallBets;
};

const transformChipTray = (chipTray: TRouletteGameConfig['chip_tray']['chips']): TChip[] =>
   chipTray.map(({ uuid, value, primary_color, is_default }) => ({
      id: uuid,
      value,
      primaryColor: primary_color,
      isDefault: is_default,
   }));

const transformLogo = (logo: TNullable<TLogo>) => {
   const isWrongLogoPath = !logo?.path || !reqExpValidation.isSvgPath(logo?.path);

   if (isWrongLogoPath) {
      return null;
   }

   return logo;
};
export const transformColors = (colors: TNullable<TGameTableColors>) => {
   return { ...defaultBettingMapColorsConfig, ...colors };
};

export const gameConfigApiAdapter = (
   config: TRouletteGameConfig,
): Omit<IGameConfig['gameConfig'], 'gameTableId' | 'launchToken'> => ({
   title: config.title,
   logo: transformLogo(config.logo),
   colors: transformColors(config.colors),
   autoplayRounds: config.autoplay_rounds,
   betTypeLimits: transformLimits(config.bet_type_limits),
   wssUrl: config.wssUrl,
   tssUrl: config.tssUrl,
   lobbyRedirectUrl: config?.lobby_redirect_url ?? null,
   esUrl: config.esUrl,
   timer: {
      type: ETimerType.HORIZONTAL,
      betting_time: config.timer.betting_time,
   },
   gameType: {
      title: config.game_type.title ?? '',
      name: config.game_type.name,
      id: config.game_type.uuid,
   },
   channelId: config.chat.channel_id,
   streamId: config.stream_id,
   chipTray: transformChipTray(config.chip_tray.chips),
   isGameTableActive: config.is_game_table_active,
   isPhysicalTableUnderMaintenance: config.is_physical_table_under_maintenance,
});
