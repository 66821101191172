import { FC } from 'react';

import type { IFilter } from '../../types';

export const FilterTemplate: FC<IFilter> = ({ id, color }) => (
   <filter id={id} filterUnits="objectBoundingBox" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feColorMatrix
         in="SourceAlpha"
         type="matrix"
         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
         result="feColorMatrixResult"
      />
      <feMorphology radius="8" operator="erode" in="SourceAlpha" result="feMorphologyResult" />
      <feGaussianBlur stdDeviation="5" />
      <feComposite in2="feColorMatrixResult" operator="arithmetic" k2="-1" k3="1" />
      <feColorMatrix type="matrix" values={color} />
      <feBlend mode="normal" in2="shape" result="feMorphologyResult" />
   </filter>
);
