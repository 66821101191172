import { StatisticByRange } from '#/modules/Statistics/state/types';

import { config } from '../constants';

const _mergeArrayObjects = <T, U>({
   first,
   second,
   key,
}: {
   first: T[];
   second: U[];
   key: keyof T | keyof U;
}): Array<T & U> => {
   const result: Array<T & U> = [];

   const secondMap = new Map<string, U>();

   for (const secondItem of second) {
      const keyValue = String(secondItem[key as keyof U]);
      secondMap.set(keyValue, secondItem);
   }

   for (const firstItem of first) {
      const keyValue = String(firstItem[key as keyof T]);
      const secondItem = secondMap.get(keyValue);

      if (secondItem) {
         result.push({ ...firstItem, ...secondItem });
      }
   }

   return result;
};

export const prepareStatisticsWheelNumbers = (statistics: StatisticByRange[number]) => {
   return statistics?.statisticsByPockets?.length
      ? _mergeArrayObjects({
           first: config,
           key: 'number',
           second: statistics?.statisticsByPockets,
        })
      : [];
};
