import { useDispatch, useSelector } from 'react-redux';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { gameSettings, EParamsGameTypes } from '@/common/helpers';
import { defaultBettingMapColorsConfig } from '@/common/roulette/constants';
import { TNullable } from '@/common/types';
import { EGameTypes } from '@/common/constants';
import {
   TLogo,
   TGameTableColors,
} from '@/common/services/api/gameTablesAPI/hooks/useGetGameTableById/types';
import { TRouletteClientLanguage } from '@/common/entities/Language';
import { TChip } from '@/common/ui/Chip/entities';
import { TLaunchToken, TLobbyRedirectUrl } from '@/common/entities';
import { TGameType } from '@/common/entities/Game';
import { ETimerType } from '@/common/modules/CountDown';

import { TRootState } from '#/state/types';
import { TLimits } from '#/modules/TableCapacity';

const getInitialParamsGameType = (paramType: string): EGameTypes => {
   const options = {
      [EParamsGameTypes.SingleZeroRoulette]: EGameTypes.SingleZeroRoulette,
      [EParamsGameTypes.AutoRoulette]: EGameTypes.AutoRoulette,
      [EParamsGameTypes.BlackJack]: EGameTypes.BlackJack,
   };
   return options[paramType] || options[EParamsGameTypes.SingleZeroRoulette];
};

export interface IGameConfig {
   gameConfig: {
      title: string;
      wssUrl: string;
      tssUrl: string;
      esUrl: string;
      chipTray: TChip[];
      streamId: string;
      channelId: string;
      betTypeLimits: TLimits;
      autoplayRounds: number[];
      gameType: TGameType;
      timer: {
         type: ETimerType;
         betting_time: TNullable<number>;
      };
      logo: TNullable<TLogo>;
      lobbyRedirectUrl: TLobbyRedirectUrl;
      colors: TGameTableColors;
      isGameTableActive: boolean;
      isPhysicalTableUnderMaintenance: boolean;
      launchToken: TLaunchToken;
      gameTableId: string;
   };
   gameLanguage: TRouletteClientLanguage;
}

const initialState: IGameConfig = {
   gameLanguage: 'en',
   gameConfig: {
      launchToken: gameSettings.launchToken,
      gameTableId: gameSettings.gameTableId,
      title: '',
      wssUrl: '',
      tssUrl: '',
      logo: null,
      lobbyRedirectUrl: null,
      colors: defaultBettingMapColorsConfig,
      esUrl: '',
      chipTray: [],
      streamId: '',
      channelId: '',
      betTypeLimits: {},
      autoplayRounds: [],
      gameType: {
         id: '',
         name: getInitialParamsGameType(gameSettings.gameId),
         title: '',
      },
      timer: {
         type: ETimerType.HORIZONTAL,
         betting_time: null,
      },
      isGameTableActive: false,
      isPhysicalTableUnderMaintenance: false,
   },
};

export const gameConfigSlice = createSlice({
   name: 'gameConfig',
   initialState,
   reducers: {
      setGameConfig: (state, action: PayloadAction<IGameConfig['gameConfig']>) => {
         state.gameConfig = action.payload;
      },
      setGameLanguage: (state, action: PayloadAction<IGameConfig['gameLanguage']>) => {
         state.gameLanguage = action.payload;
      },
   },
   selectors: {
      getGameType: (state) => state.gameConfig.gameType,
   },
});

export const useGameConfigActions = () => {
   const dispatch = useDispatch();
   const { setGameLanguage, setGameConfig } = gameConfigSlice.actions;

   const handleSetGameConfig = (gameConfig: IGameConfig['gameConfig']) => {
      dispatch(setGameConfig(gameConfig));
   };

   const handleSetGameLanguage = (gameLanguage: IGameConfig['gameLanguage']) => {
      dispatch(setGameLanguage(gameLanguage));
   };

   return {
      handleSetGameLanguage,
      handleSetGameConfig,
   };
};

export const useGameLanguageSelector = () =>
   useSelector(({ gameConfig }: TRootState) => gameConfig.gameLanguage);

export const useGameStateSelector = (): IGameConfig =>
   useSelector(({ gameConfig }: TRootState) => gameConfig);

export const useGameType = () => {
   const { name: gameTypeName } = useSelector(gameConfigSlice.selectors.getGameType);

   return {
      isAutoRoulette: gameTypeName === EGameTypes.AutoRoulette,
      isSingleZeroRoulette: gameTypeName === EGameTypes.SingleZeroRoulette,
      isBlackJack: gameTypeName === EGameTypes.BlackJack,
   };
};

export const useGameConfigStore = () => {
   const gameConfig = useGameStateSelector();
   const { handleSetGameLanguage } = useGameConfigActions();

   return {
      handleSetGameLanguage,
      gameConfig: gameConfig?.gameConfig,
   };
};
