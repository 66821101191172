import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TRootState } from '#/state/types';

export interface IWinHistoryState {
   winHistory: string[];
}

const initialState: IWinHistoryState = {
   winHistory: [],
};

export const winHistorySlice = createSlice({
   name: 'winHistory',
   initialState,
   reducers: {
      addWinNumberToWinHistory: (state, action: PayloadAction<string[]>): void => {
         state.winHistory = action.payload;
      },
   },
});
export const useWinHistoryActions = () => {
   const dispatch = useDispatch();
   const { addWinNumberToWinHistory } = winHistorySlice.actions;

   const handleAddWinNumberToWinHistory = (winNumber: unknown): void => {
      // @ts-ignore
      dispatch(addWinNumberToWinHistory(winNumber));
   };

   return { handleAddWinNumberToWinHistory };
};

const useWinHistorySelector = (): IWinHistoryState =>
   useSelector((winHistory: TRootState) => winHistory.winHistory);

export const useWinHistory = () => {
   const { winHistory } = useWinHistorySelector() ?? {};
   const { handleAddWinNumberToWinHistory } = useWinHistoryActions();

   return { winHistory, handleAddWinNumberToWinHistory };
};
