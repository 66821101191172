import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { gameStateSlice } from '@/common/state/gameState';

import { errors } from '#/constants/errors';

import { getAuthData, getGameConfig, getStatistics } from './helpers';

export const userConnectionMiddleware = createListenerMiddleware();
export const userConnectionAction = createAction('auth');

userConnectionMiddleware.startListening({
   actionCreator: userConnectionAction,
   effect: async (_, listenerApi) => {
      const { dispatch, getState } = listenerApi;
      const {
         setGameStatusAsError,
         setGameStatusAsLoading,
         setGameStatusAsSuccess,
         setMaintenanceMode,
         setInactiveGameTableMode,
      } = gameStateSlice.actions;

      try {
         dispatch(setGameStatusAsLoading());

         await getAuthData(dispatch, getState);

         Promise.all([
            await getGameConfig(dispatch, getState),
            await getStatistics(dispatch, getState),
         ]);

         dispatch(setGameStatusAsSuccess());
      } catch (error) {
         const actionByErrorMessage = {
            [errors.maintenance]: setMaintenanceMode,
            [errors.inactiveGameTable]: setInactiveGameTableMode,
         };

         const action = actionByErrorMessage?.[error.message] ?? setGameStatusAsError;

         dispatch(action());
      }
   },
});
