// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const CircleLoader = styled.div`
   position: absolute;
   bottom: 0;
`;

export const LoadingTitle = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 190px 12px;
   position: relative;
   ${orientation.portrait`
     padding: 190px 12px;
   `}
   ${orientation.mobileLandscape`
      padding: 30px 12px 120px;
   `}
`;

export const LoadingScreenContent = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   scale: var(--scale, 0.66);
   transition: all 0.5s ease;
   width: 100%;
   height: 100%;
`;
export const LoadingScreen = styled.div`
   padding: 60px 0;
   box-sizing: border-box;
   width: 100%;
   height: 100%;
`;
