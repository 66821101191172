import { EModalNotificationType } from '@/common/ui/ModalNotification/constants';

import { useTranslation } from '#/translates';

import { Overlay } from '../../../Overlay';
import { useLobbyRedirect } from '../../../../hooks';

export const LoginFromAnotherPlace = () => {
   const { t } = useTranslation();
   const handleLobbyRedirect = useLobbyRedirect();

   return (
      <Overlay
         type={EModalNotificationType.Warning}
         title={t('sessionErrorOverlay.title')}
         actionButton={{
            title: t('sessionErrorOverlay.action'),
            onClick: handleLobbyRedirect,
         }}
      />
   );
};
