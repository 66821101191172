import { useDispatch, useSelector } from 'react-redux';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TNullable, TSetTimeout } from '@/common/types';

import { TRootState } from '#/state/types';

export enum EAnnouncementType {
   Male = 'male',
   Female = 'female',
}

interface IAnnouncementsInitialState {
   announcementType: TNullable<EAnnouncementType>;
   announcementsPool: string[];
   isShowAnnouncementSubtitles: boolean;
}

const announcementsInitialState: IAnnouncementsInitialState = {
   announcementsPool: [],
   // we temporarily removed subtitles from scope
   isShowAnnouncementSubtitles: false,
   announcementType: EAnnouncementType.Male,
};

export const announcementsSlice = createSlice({
   name: 'announcements',
   initialState: announcementsInitialState,
   reducers: {
      addAnnouncementToPool: (state, action: PayloadAction<string>): void => {
         const announcement = action.payload;
         if (!state.announcementsPool.includes(announcement)) {
            state.announcementsPool.push(announcement);
         }
      },
      shiftAnnouncementFromPool: (state): void => {
         state.announcementsPool.shift();
      },
      changeAnnouncementType: (
         state,
         action: PayloadAction<TNullable<EAnnouncementType>>,
      ): void => {
         state.announcementType = action.payload;
      },
      toggleShowAnnouncementSubtitles: (state, action: PayloadAction<boolean>): void => {
         state.isShowAnnouncementSubtitles = action.payload;
      },
   },
});

export const useAnnouncementsActions = () => {
   const dispatch = useDispatch();
   const {
      addAnnouncementToPool,
      changeAnnouncementType,
      shiftAnnouncementFromPool,
      toggleShowAnnouncementSubtitles,
   } = announcementsSlice.actions;
   const isShowAnnouncementSubtitles = useIsShowAnnouncementSubtitlesSelector();

   const handleAddAnnouncementToPool = (announcement: string): void => {
      dispatch(addAnnouncementToPool(announcement));
   };

   const handleShiftAnnouncementFromPool = (timeout?: TSetTimeout): void => {
      if (timeout) {
         clearTimeout(timeout);
      }
      dispatch(shiftAnnouncementFromPool());
   };

   const handleChangeAnnouncementType = (announcementType: TNullable<EAnnouncementType>): void => {
      dispatch(changeAnnouncementType(announcementType));
   };

   const handleToggleShowAnnouncementSubtitles = (): void => {
      dispatch(toggleShowAnnouncementSubtitles(!isShowAnnouncementSubtitles));
   };

   return {
      handleChangeAnnouncementType,
      handleAddAnnouncementToPool,
      handleShiftAnnouncementFromPool,
      handleToggleShowAnnouncementSubtitles,
   };
};

export const useAnnouncementsPoolSelector = () =>
   useSelector(({ announcements }: TRootState) => announcements.announcementsPool);

export const useIsShowAnnouncementSubtitlesSelector = () =>
   useSelector(({ announcements }: TRootState) => announcements.isShowAnnouncementSubtitles);

export const useAnnouncementTypeSelector = () =>
   useSelector(({ announcements }: TRootState) => announcements.announcementType);

export const useAnnouncementsState = () => {
   const {
      handleAddAnnouncementToPool,
      handleShiftAnnouncementFromPool,
      handleChangeAnnouncementType,
      handleToggleShowAnnouncementSubtitles,
   } = useAnnouncementsActions();

   const announcementType = useAnnouncementTypeSelector();
   const announcementsPool = useAnnouncementsPoolSelector();
   const isShowAnnouncementSubtitles = useIsShowAnnouncementSubtitlesSelector();

   return {
      announcementType,
      announcementsPool,
      isShowAnnouncementSubtitles,
      currentAnnouncement: announcementsPool[0],

      handleChangeAnnouncementType,
      handleAddAnnouncementToPool,
      handleShiftAnnouncementFromPool,
      handleToggleShowAnnouncementSubtitles,
   };
};
