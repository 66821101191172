import { FC } from 'react';

import { theme } from '@/common/ui/theme';

import * as S from './WinSumView.styles';

const centerText = (count: string): number => {
  const CHAR_WIDTH = 55;

  return count.length * 2 + CHAR_WIDTH;
};

interface IWinSumView {
  animationDuration: number;
  content: string;
  count: string;
}

export const WinSumView: FC<IWinSumView> = ({ count, content, animationDuration }) => (
  <S.WinSumViewWrapper
    viewBox="0 50 480 100"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    animationDuration={animationDuration}
  >
    <defs>
      <linearGradient
        xmlns="http://www.w3.org/2000/svg"
        id="background-fill"
        x1="438.451"
        y1="114.92"
        x2="0.451172"
        y2="114.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0416667" stopOpacity="0" />
        <stop offset="0.104167" stopOpacity="0.722222" />
        <stop offset="0.510417" />
        <stop offset="0.875" stopOpacity="0.634146" />
        <stop offset="0.9375" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        xmlns="http://www.w3.org/2000/svg"
        id="background-fill"
        x1="438.451"
        y1="114.92"
        x2="0.451172"
        y2="114.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0416667" stopOpacity="0" />
        <stop offset="0.104167" stopOpacity="0.722222" />
        <stop offset="0.510417" />
        <stop offset="0.875" stopOpacity="0.634146" />
        <stop offset="0.9375" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="bottom-line-stroke"
        x1="455"
        y1="108.899"
        x2="42.4513"
        y2="108.899"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop className="lighter-color" id="bottom-line-stroke-0" offset="0%" />
        <stop className="darker-color" id="bottom-line-stroke-1" offset="51.0417%" />
        <stop className="lighter-color" id="bottom-line-stroke-2" offset="100%" />
      </linearGradient>
      <linearGradient
        id="top-line-stroke"
        x1="455"
        y1="11.8987"
        x2="42.4513"
        y2="11.8987"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop className="lighter-color" id="top-line-stroke-0" offset="0%" />
        <stop className="darker-color" id="top-line-stroke-1" offset="51.0417%" />
        <stop className="lighter-color" id="top-line-stroke-2" offset="100%" />
      </linearGradient>
      <radialGradient
        id="e39ZVSR0YnF6-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(29.0934 0 0 0.51282 -0.247 0)"
      >
        <stop className="darker-color" id="e39ZVSR0YnF6-fill-0" offset="14.4835%" />
        <stop id="e39ZVSR0YnF6-fill-1" offset="100%" stopColor="rgba(255,255,255,0)" />
      </radialGradient>
      <radialGradient
        id="e39ZVSR0YnF7-fill"
        cx="0"
        cy="0"
        r="0.5"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
        gradientTransform="translate(0.5 0.5)"
      >
        <stop className="darker-color" id="e39ZVSR0YnF7-fill-0" offset="0%" />
        <stop className="lighter-color" id="e39ZVSR0YnF7-fill-1" offset="99%" opacity={0} />
      </radialGradient>
      <radialGradient
        id="e39ZVSR0YnF8-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(5.04121 4.61539 -4.58731 5.01054 -0.041 0)"
      >
        <stop className="darker-color" id="e39ZVSR0YnF8-fill-0" offset="0%" />
        <stop className="lighter-color" id="e39ZVSR0YnF8-fill-1" offset="100%" />
      </radialGradient>
      <radialGradient
        id="e39ZVSR0YnF10-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(29.0934 0 0 0.51282 -0.247 0)"
      >
        <stop className="darker-color" id="e39ZVSR0YnF10-fill-0" offset="14.4835%" />
        <stop id="e39ZVSR0YnF10-fill-1" offset="100%" stopColor="rgba(255,255,255,0)" />
      </radialGradient>
      <radialGradient
        id="e39ZVSR0YnF11-fill"
        cx="0"
        cy="0"
        r="0.5"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
        gradientTransform="translate(0.5 0.5)"
      >
        <stop className="darker-color" id="e39ZVSR0YnF11-fill-0" offset="0%" />
        <stop className="lighter-color" id="e39ZVSR0YnF11-fill-1" offset="99%" />
      </radialGradient>
      <radialGradient
        id="e39ZVSR0YnF12-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(5.04121 4.61539 -4.58731 5.01054 -0.041 0)"
      >
        <stop className="darker-color" id="e39ZVSR0YnF12-fill-0" offset="0%" />
        <stop className="lighter-color" id="e39ZVSR0YnF12-fill-1" offset="100%" />
      </radialGradient>
      <filter id="description-text-filter" x="-150%" width="400%" y="-150%" height="400%">
        <feGaussianBlur
          id="description-text-filter-drop-shadow-0-blur"
          in="SourceAlpha"
          stdDeviation="1,0"
        />
        <feOffset id="description-text-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
        <feFlood id="description-text-filter-drop-shadow-0-flood" floodColor={theme.colors?.main} />
        <feComposite id="description-text-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
        <feMerge id="description-text-filter-drop-shadow-0-merge">
          <feMergeNode id="description-text-filter-drop-shadow-0-merge-node-1" />
          <feMergeNode id="description-text-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
        </feMerge>
      </filter>
      <linearGradient
        id="description-text-fill"
        x1="0"
        y1="0.901427"
        x2="0"
        y2="0.189957"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
        gradientTransform="translate(0 0)"
      >
        <stop className="darker-color" id="description-text-fill-0" offset="0%" />
        <stop className="darker-color" id="description-text-fill-1" offset="100%" />
      </linearGradient>
    </defs>
    <rect
      id="background"
      width="100%"
      height="100%"
      rx="0"
      ry="0"
      transform="matrix(.785154 0 0 1 59.807157 51.769489)"
      opacity="0"
      fill="url(#background-fill)"
    />
    <line
      id="top-line"
      x1="26"
      y1="9.89746"
      x2="455"
      y2="9.89751"
      transform="matrix(.893043 0 0 1 25.723158 41.371989)"
      opacity="0"
      fill="none"
      stroke="url(#top-line-stroke)"
      strokeWidth="2"
    />
    <line
      id="bottom-line"
      x1="26"
      y1="106.897"
      x2="455"
      y2="106.898"
      transform="matrix(.893043 0 0 1 25.723158 41.371989)"
      opacity="0"
      fill="none"
      stroke="url(#bottom-line-stroke)"
      strokeWidth="2"
    />
    <g id="bottom-line-circle" transform="translate(419.807157,148.268989)">
      <g id="bottom-line-circle-scale" transform="scale(4,4)">
        <g id="bottom-line-circle-inner" transform="translate(-132,-109.127998)" opacity="0">
          <ellipse
            rx="45"
            ry="4.61538"
            transform="translate(132 109.128)"
            fill="url(#e39ZVSR0YnF6-fill)"
          />
          <ellipse
            rx="7.5"
            ry="7.69231"
            transform="translate(132.5 109.128)"
            fill="url(#e39ZVSR0YnF7-fill)"
          />
          <ellipse
            rx="7.5"
            ry="7.69231"
            transform="translate(132.5 109.128)"
            fill="url(#e39ZVSR0YnF8-fill)"
          />
        </g>
      </g>
    </g>

    <g id="top-line-circle" transform="translate(85.230887,51.769489)">
      <g id="top-line-circle-scale" transform="scale(4,4)">
        <g id="top-line-circle-inner" transform="translate(-132,-109.127998)" opacity="0">
          <ellipse
            rx="45"
            ry="4.61538"
            transform="translate(132 109.128)"
            fill="url(#e39ZVSR0YnF10-fill)"
          />
          <ellipse
            rx="7.5"
            ry="7.69231"
            transform="translate(132.5 109.128)"
            fill="url(#e39ZVSR0YnF11-fill)"
          />
          <ellipse
            rx="7.5"
            ry="7.69231"
            transform="translate(132.5 109.128)"
            fill="url(#e39ZVSR0YnF12-fill)"
          />
        </g>
      </g>
    </g>
    <text id="title-text" dx="0" dy="0" opacity="0" fill={theme.colors?.white} strokeWidth="0">
      <tspan y="0" fontWeight="600" strokeWidth="0">
        {content}
      </tspan>
    </text>
    <text
      id="description-text"
      x={centerText(count)}
      y="5"
      transform="matrix(1.1 0 0 1.1 172.894195 129.406916)"
      filter="url(#description-text-filter)"
      fill="url(#description-text-fill)"
      fillRule="evenodd"
      strokeLinecap="square"
      strokeLinejoin="bevel"
      textAnchor="middle"
    >
      {count}
    </text>
  </S.WinSumViewWrapper>
);
