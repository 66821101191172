// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { HorizontalCountDownContainer } from '../HorizontalCountDownContainer/HorizontalCountDownContainer.styles';
import * as HorizontalCountDownTickStyles from '../HorizontalCountDownTick/HorizontalCountDownTick.styles';

export const HorizontalCountDownPosition = styled.div`
   ${HorizontalCountDownContainer} {
      ${({ isVertical }) =>
         isVertical &&
         css`
            position: relative;
            transform-origin: left;
            transform: rotate(-90deg) translateX(0) translateY(calc(var(--timer-width) / 2));

            ${HorizontalCountDownTickStyles.HorizontalCountDownTick} {
               transform: rotate(90deg);
            }
         `}
   }
`;
