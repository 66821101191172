import { FC } from 'react';

import { autoKeys } from '@/common/utils';

import { FilterTemplate } from './components';
import { coldColor, hotColor } from './constants';

export interface IHotAndColdFiltersProps {
   coldColorOption?: string;
   hotColorOption?: string;
   trackContainerId: string;
}

export const HotAndColdFilters: FC<IHotAndColdFiltersProps> = ({
   coldColorOption,
   hotColorOption,
   trackContainerId,
}) => {
   const filters = [
      {
         filterId: 'filter__cold-number',
         color: coldColorOption || coldColor,
      },
      {
         filterId: 'filter__hot-number',
         color: hotColorOption || hotColor,
      },
   ] as const;

   return (
      <>
         {autoKeys(
            filters.map(({ filterId, color }) => (
               <FilterTemplate id={`${trackContainerId}_${filterId}`} color={color} />
            )),
         )}
      </>
   );
};
