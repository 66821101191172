import { getCurrentTime } from 'helpers/currentTime';

import { DateFormatter } from '@/common/utils';

export const calculateTimerDuration = async ({
   startTime,
   bettingTime = 15,
}: {
   startTime: string;
   bettingTime: number;
}): Promise<number> => {
   const currentTime = await getCurrentTime();
   const diff: number = currentTime - DateFormatter.toUnixTime(startTime);
   const currentDuration: number = Math.ceil(bettingTime - diff);

   return Math.max(0, currentDuration);
};
