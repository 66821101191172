import { useEvent } from '@/common/hooks';

interface IUseAnimation {
   end?: (event) => void;
   start?: (event) => void;
}

export interface IAnimationTriggers {
   onAnimationEnd: (event) => void;
   onAnimationStart: (event) => void;
}

export const useAnimationTriggers = ({
   start = () => null,
   end = () => null,
}: IUseAnimation): IAnimationTriggers => {
   const onAnimationStart = useEvent(start);
   const onAnimationEnd = useEvent(end);

   return {
      onAnimationStart,
      onAnimationEnd,
   };
};
