// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const CloseThreadIconWrapper = styled.div`
   display: flex;
   width: 0;
   margin-top: 25px;
   cursor: pointer;
   position: relative;
   left: -25px;
   height: 15px;
   z-index: 1;

   ${orientation.portrait`
      left: 5px;
      margin-top: 12px;
      width: 30px;
      position: absolute;
      height: 40px;
   `}
`;
