import 'swiper/css';
import { Swiper } from 'swiper/react';

import { ISliderProps } from './types';

export const Slider = ({
  children,
  onSwiper,
  slidesPerView,
  style = {},
  spaceBetween = 0,
  direction = 'horizontal',
}: ISliderProps) => (
  <Swiper
    speed={300}
    style={style}
    onSwiper={onSwiper}
    simulateTouch={false}
    spaceBetween={spaceBetween}
    slidesPerView={slidesPerView}
    direction={direction}
  >
    {children}
  </Swiper>
);
