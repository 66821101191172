class ReqExpValidation {
   isNumber(value: string): boolean {
      return /^(?:\d+)$/.test(value);
   }

   isNumberOrDot(value: string): boolean {
      return /^(?:\d+|\.)$/.test(value);
   }

   isSvgPath(value: string): boolean {
      return /\.svg$/.test(value);
   }
}

export const reqExpValidation = new ReqExpValidation();
