import { useDealerRateState } from '@/common/modules/DealerRating/state';
import { DealerRatingStars } from '@/common/modules/DealerRating/components';

import { useUserGeneralSettingsState } from '#/modules/Settings/state';
import { useTranslation } from '#/translates';

export const DealerRatingStarsView = () => {
   const { t } = useTranslation();
   const { isDealerRatingEnabled } = useUserGeneralSettingsState();
   const { isFeedbackEnabledOnCasinoGameTypeLevel } = useDealerRateState();

   if (!isFeedbackEnabledOnCasinoGameTypeLevel || !isDealerRatingEnabled) {
      return;
   }

   return <DealerRatingStars buttonTitle={t('dealerRating.buttonTitle')} />;
};
