import { useDispatch } from 'react-redux';

import { userGeneralSettingsUpdatedEventAction } from '#/services/api/middleware/userGeneralSettingsUpdatedMiddleware';

import { TUserGeneralSettingsUpdateEvent } from './schemes';

export const useUserGeneralSettingsUpdateEvent = () => {
   const dispatch = useDispatch();

   return (data: TUserGeneralSettingsUpdateEvent) => {
      dispatch(userGeneralSettingsUpdatedEventAction(data));
   };
};
