import { FC } from 'react';

import { ITimerProps } from '../../types';

import {
   HorizontalCountDownContainer,
   HorizontalCountDownProgressBar,
   HorizontalCountDownTick,
   HorizontalCountDownMessage,
} from './components';
import { useHorizontalCountDownAnimation } from './hooks/useHorizontalCountDownAnimation';

export interface IIHorizontalCountDownProps extends ITimerProps {
   message: string;
}
export const HorizontalCountDown: FC<IIHorizontalCountDownProps> = ({
   tick,
   message,
   duration,
   endTime,
}) => {
   const { timerProgressBarRef } = useHorizontalCountDownAnimation({ duration, endTime });

   return (
      <HorizontalCountDownContainer>
         <HorizontalCountDownProgressBar ref={timerProgressBarRef} tick={tick} />
         <HorizontalCountDownTick tick={tick} />
         <HorizontalCountDownMessage message={message} />
      </HorizontalCountDownContainer>
   );
};
