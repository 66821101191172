import { TCallBet, TCommonBet, TDoubleBet, TExcludeBetFromBetList } from '#/state/features/bets';
import { isRangeStraightBetType, isSpecialBetType } from '#/state/features/bets/type-guards';

export const extractSpecialBet = (bets: TExcludeBetFromBetList<TDoubleBet>[]) => {
   const extractedBets: (TCallBet | TCommonBet)[] = [];

   for (const bet of bets) {
      const isRangeStraightBet = isRangeStraightBetType(bet);
      const isSpecialBet = isSpecialBetType(bet);
      const whenExtract = isRangeStraightBet || isSpecialBet;

      if (whenExtract) {
         extractedBets.push(...bet.extractedCommand);
      } else {
         extractedBets.push(bet);
      }
   }

   return extractedBets;
};
