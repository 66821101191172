import { memo } from 'react';

import { useTheme } from '@/common/ui/theme/hooks';
import { uuid } from '@/common/utils';

export const BoxShadowSvgFilter = memo(() => {
  const theme = useTheme();
  const svgFilterId = `shadow-filter-${uuid()}`;

  return (
    <>
      <circle
        cx="30"
        cy="30"
        r="31"
        x={0}
        y={0}
        style={{
          fill: theme.colors?.redHue['10'] as string,
          filter: `url(#${svgFilterId})`,
        }}
      />

      <defs>
        <filter id={svgFilterId}>
          <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="4" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          <feMerge>
            <feMergeNode />
            <feMergeNode />
            <feMergeNode />
            <feMergeNode />
          </feMerge>
        </filter>
      </defs>
    </>
  );
});
