// @ts-nocheck
import styled from 'styled-components/macro';

import { isHoverSupports, orientation } from '@/common/ui/theme/mixins';

export const CloseIcon = styled.div`
   --wrapper-size: 27px;
   --icon-size: calc(var(--wrapper-size) - 8px);
   --icon-line-width: 3px;

   position: relative;

   width: var(--wrapper-size);
   height: var(--wrapper-size);

   margin: 5px 10px 5px 5px;

   cursor: pointer;
   border-radius: 50%;

   &:before,
   &:after {
      position: absolute;
      content: '';
      top: calc((var(--wrapper-size) - var(--icon-size)) / 2);
      left: calc(50% - var(--icon-line-width) / 2);
      width: var(--icon-line-width);
      height: var(--icon-size);
      background-color: ${({ theme }) => theme.colors.smokeGrey};
      border-radius: 5px;
   }

   &:before {
      transform: rotateZ(45deg);
   }

   &:after {
      transform: rotateZ(-45deg);
   }

   ${isHoverSupports`
      &:hover {
         background: ${({ theme }) => theme.bg.activePrimary};
      }
   `}

   &:active {
      background: ${({ theme }) => theme.bg.activePrimary};
   }

   ${orientation.mobileLandscape`
     --wrapper-size: 30px;
     --icon-line-width: 2px;
      margin: 0;
   `}
   ${orientation.portrait`
      --wrapper-size: 30px;
   `}
`;
