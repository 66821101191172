import { SyntheticEvent, useRef, ElementRef } from 'react';

import { useDealerRateState } from '../../state';

import * as S from './Comment.styles';

interface IComment {
   isShown: boolean;
   title: string;
}

export const Comment = ({ title, isShown }: IComment) => {
   const { feedbackComment, handleSetFeedbackComment } = useDealerRateState();
   const textareaRef = useRef<ElementRef<'textarea'>>(null);

   const handleChange = (event: SyntheticEvent<HTMLTextAreaElement, Event>) => {
      const { currentTarget } = event;
      handleSetFeedbackComment(currentTarget.value);
   };

   const handleFocus = () => {
      if (textareaRef.current) {
         textareaRef.current.focus();
      }
   };

   return (
      isShown && (
         <S.CommentTextArea
            ref={textareaRef}
            focused
            defaultValue={feedbackComment}
            onChange={handleChange}
            onClick={handleFocus}
            rows={2}
            maxLength="255"
            placeholder={title}
         />
      )
   );
};
