import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { handleReconnect } from './effect/handleReconnect';

export const reconnectMiddleware = createListenerMiddleware();
export const reconnectionEventAction = createAction('reconnect');

reconnectMiddleware.startListening({
   effect: handleReconnect,
   actionCreator: reconnectionEventAction,
});
