// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '../../../theme/mixins';

export const GameTitle = styled.span`
  margin-right: 15px;
  color: #a8a8a8;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${orientation.portrait`
       margin-right: 0;
   `}

  ${orientation.mobileLandscape`
       margin-right: 0;
   `}
`;
