import { FC, HTMLAttributes } from 'react';

import { TChip } from './entities';
import { Components } from './components';
import { chipStyler } from './helpers';
import * as S from './Chip.styles';

export interface IChip extends HTMLAttributes<SVGElement> {
  chipNumber: number;
  chips: TChip[];
  height?: number;
  highlightShadow?: boolean;
  onClick?: () => void;
  width?: number;
  x?: number;
  y?: number;
}

export const Chip: FC<IChip> = ({
  x = 0,
  y = 0,
  chips,
  chipNumber,
  width = 80,
  height = 80,
  className = '',
  highlightShadow = false,
  onClick = () => null,
  ...otherProps
}) => {
  const params = chipStyler.getChipParams({
    chipTrayConfig: chips,
    chipValue: chipNumber,
  });

  return (
    <ChipView
      x={x}
      y={y}
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      highlightShadow={highlightShadow}
      {...otherProps}
      {...params}
    />
  );
};

export interface IChipView extends HTMLAttributes<SVGElement> {
  color: string;
  fontSize: number;
  height?: number;
  highlightShadow?: boolean;
  onClick?: () => void;
  scale: number;
  value: string;
  width?: number;
  x?: number;
  y?: number;
}
export const ChipView: FC<IChipView> = ({
  color,
  fontSize,
  scale,
  value,
  x = 0,
  y = 0,
  width = 80,
  height = 80,
  className = '',
  highlightShadow = false,
  onClick = () => null,
  ...otherProps
}) => {
  return (
    <S.ChipWrapper
      x={x}
      y={y}
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 60 60"
      className={`${className} chip`}
      {...otherProps}
    >
      {highlightShadow && <Components.BoxShadowSvgFilter />}
      <Components.ChipValue color={color} fontSize={fontSize} scale={scale} value={value} />
    </S.ChipWrapper>
  );
};
