import { FC } from 'react';
import { useMessageContext } from 'stream-chat-react';

import { ReplyIcon, RepliesCountButton } from './components';
import * as S from './ChatMessage.styles';

interface IChatMessageProps {
   messageColor: string;
   usernameColor: string;
}
export const ChatMessage: FC<IChatMessageProps> = ({ usernameColor, messageColor }) => {
   const { message } = useMessageContext();

   return (
      <>
         <S.MessageContainer className="chat-message">
            <S.UsernameWrapper>
               <S.Username color={usernameColor}>{message?.user?.name}</S.Username>
               <ReplyIcon />
            </S.UsernameWrapper>

            <S.Message className="chat-message" color={messageColor}>
               {message?.text}
            </S.Message>
         </S.MessageContainer>

         <RepliesCountButton />
      </>
   );
};
