import { FC } from 'react';

import { isMobileDevice } from '@/common/utils/checkDevice';
import { autoKeys } from '@/common/utils';

import { useCollapsedActivePlayingAreaState } from '#/modules/Track/state';
import { BettingMap } from '#/modules';
import { ETrackType, ITrackProps } from '#/modules/Track';

import { RaceTrack } from '../../RaceTrack';

import { RenderTrack } from './components';

export const Track: FC<Omit<ITrackProps, 'trackContainerId'>> = (props) => {
   const isMobile: boolean = isMobileDevice();

   const tracks = [
      {
         Component: BettingMap,
         id: 'betting-map',
         whenRender: props.trackType === ETrackType.Betting,
      },
      {
         Component: RaceTrack,
         id: 'racetrack',
         whenRender: props.trackType === ETrackType.Racetrack,
      },
   ];
   const { handleSetCollapsedActivePlayingArea } = useCollapsedActivePlayingAreaState();

   const setDefaultCollapsedActivePlayingAreaState = () => {
      const defaultState = false;
      handleSetCollapsedActivePlayingArea(defaultState);
   };

   return (
      <>
         {autoKeys(
            tracks.map(({ Component, id, whenRender }) => (
               <RenderTrack whenRender={whenRender}>
                  <Component
                     {...props}
                     onClick={(e) => {
                        props?.onClick?.(e);

                        if (isMobile) {
                           setDefaultCollapsedActivePlayingAreaState();
                        }
                     }}
                     trackContainerId={id}
                  />
               </RenderTrack>
            )),
         )}
      </>
   );
};
