import { useCurrency } from 'hooks/useCurrency';

import { TRoundWinners } from '@/common/services/sockets/useRoundWinners/schemes';

export const useWinnerListTotalAmount = (winnerList: TRoundWinners) => {
   const formatCurrencyWithSign = useCurrency();
   const winnerListTotalAmount = winnerList.reduce((total, { amount }) => total + amount, 0);

   return formatCurrencyWithSign(winnerListTotalAmount);
};
