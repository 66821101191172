// @ts-nocheck
import styled from 'styled-components/macro';

export const Icon = styled.div`
   display: flex;
   box-sizing: border-box;
   width: 26px;
   height: 26px;
   position: relative;
   align-items: center;
   justify-content: center;
   cursor: pointer;
`;
