import { autoKeys } from '@/common/utils';

import { useDealerRateState } from '../../state';

import { RatingStarEmpty, RatingStarFull, RatingStarHalf } from './assests';
import * as S from './RatingStars.styles';

const AMOUNT_OF_STARS = 5;

export const RatingStars = ({
   filledStars,
   size,
   handleShowModal = () => {},
}: {
   filledStars: number;
   size?: string;
   handleShowModal?: () => void;
}) => {
   const { handleSetFeedbackStars, isLeavingFeedbackAvailable } = useDealerRateState();

   const handleChooseStar = (starValue: number) => {
      if (!isLeavingFeedbackAvailable) {
         return;
      }

      handleSetFeedbackStars(starValue);
      handleShowModal?.();
   };

   const roundedRate = (rate: number) => Math.ceil(rate);

   const isStarFractional = (rate: number, value): boolean => {
      const isFractional = rate % 1 !== 0;
      if (!isFractional) {
         return false;
      }

      const roundUp = roundedRate(rate);
      return roundUp === value;
   };

   const getStarComponent = (value: number) => {
      const isStarFilled = value <= roundedRate(filledStars);
      const isRateFractional = isStarFractional(filledStars, value);

      if (isStarFilled) {
         return isRateFractional ? RatingStarHalf : RatingStarFull;
      }
      return RatingStarEmpty;
   };

   return (
      <S.StarsWrapper size={size}>
         {autoKeys(
            [...Array(AMOUNT_OF_STARS)].map((_, index) => {
               const value = index + 1;
               const Component = getStarComponent(value);

               return <Component key={index} onClick={() => handleChooseStar(value)} />;
            }),
         )}
      </S.StarsWrapper>
   );
};
