import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '@/common/types';

import { TRootState } from '#/state/types';

interface IRoundState {
   dealer: {
      name: TNullable<string>;
   };
   physicalTableId: TNullable<string>;
   previousRound: {
      id: TNullable<string>;
      number: TNullable<string>;
   };
   round: {
      id: TNullable<string>;
      number: TNullable<string>;
   };
}

const initialState: IRoundState = {
   round: {
      id: null,
      number: null,
   },
   previousRound: {
      id: null,
      number: null,
   },
   dealer: {
      name: null,
   },
   physicalTableId: null,
};

export const roundSlice = createSlice({
   name: 'roundSlice',
   initialState,
   reducers: {
      setRoundPhysicalTableId: (
         state,
         action: PayloadAction<IRoundState['physicalTableId']>,
      ): void => {
         state.physicalTableId = action.payload;
      },
      setRoundInfo: (state, action: PayloadAction<IRoundState['round']>): void => {
         state.round = action.payload;
      },
      setDealerInfo: (state, action: PayloadAction<IRoundState['dealer']>): void => {
         state.dealer = action.payload;
      },
      setPreviousRoundNumber: (state, action: PayloadAction<IRoundState['round']>): void => {
         state.previousRound = action.payload;
      },
   },
});

const useRoundActions = () => {
   const dispatch = useDispatch();
   const { setRoundInfo, setDealerInfo, setRoundPhysicalTableId, setPreviousRoundNumber } =
      roundSlice.actions;

   const handleSetRoundInfo = (roundInfo: IRoundState['round']): void => {
      dispatch(setRoundInfo(roundInfo));
   };

   const handleSetDealerInfo = (dealerInfo: IRoundState['dealer']): void => {
      dispatch(setDealerInfo(dealerInfo));
   };
   const handleSetPreviousRoundInfo = (round: IRoundState['round']): void => {
      dispatch(setPreviousRoundNumber(round));
   };
   const handleSetRoundPhysicalTableId = (
      physicalTableId: IRoundState['physicalTableId'],
   ): void => {
      dispatch(setRoundPhysicalTableId(physicalTableId));
   };
   return {
      handleSetRoundInfo,
      handleSetDealerInfo,
      handleSetPreviousRoundInfo,
      handleSetRoundPhysicalTableId,
   };
};

const useRoundInfoSelector = (): IRoundState['round'] =>
   useSelector((state: TRootState) => state.round?.round);

const usePhysicalTableIdSelector = (): IRoundState['physicalTableId'] =>
   useSelector((state: TRootState) => state.round?.physicalTableId);

const useDealerNameSelector = (): IRoundState['dealer']['name'] =>
   useSelector((state: TRootState) => state.round?.dealer?.name);

const usePreviousRoundInfo = (): IRoundState['round'] =>
   useSelector((state: TRootState) => state.round?.previousRound);

export const useRoundState = () => {
   const roundInfo = useRoundInfoSelector();
   const dealerName = useDealerNameSelector();
   const physicalTableId = usePhysicalTableIdSelector();
   const previousRoundInfo = usePreviousRoundInfo();
   const {
      handleSetRoundInfo,
      handleSetDealerInfo,
      handleSetPreviousRoundInfo,
      handleSetRoundPhysicalTableId,
   } = useRoundActions();

   return {
      roundInfo,
      dealerName,
      physicalTableId,
      previousRoundInfo,
      handleSetRoundInfo,
      handleSetDealerInfo,
      handleSetPreviousRoundInfo,
      handleSetRoundPhysicalTableId,
   };
};
