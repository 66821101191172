import { iterateObject, multiple } from 'helpers';

import { TTotalBets } from '#/state/features/bets';

export const doubleTotalBets = (totalBets: TTotalBets) => {
   const doubledTotalBets: TTotalBets = {};

   iterateObject(totalBets, (key) => {
      doubledTotalBets[key] = multiple(totalBets[key], 2);
   });

   return doubledTotalBets;
};
