import { IAnimationTriggers, useAnimationTriggers } from 'hooks';
import { useEffect, useState } from 'react';

import { useGameState } from '@/common/state/gameState';

import { useWinnerList, useWinnerListActions } from '../../state';

export const usePlayersListVisibility = (): {
   showWinnerList: boolean;
   animationTriggers: IAnimationTriggers;
} => {
   const winnerList = useWinnerList();
   const { isRoundWinnersState } = useGameState();
   const [showWinnerList, setShowPlayersList] = useState(false);
   const { handleSaveWinnerList } = useWinnerListActions();

   useEffect(() => {
      const whenShowPlayersList = isRoundWinnersState && winnerList.length > 0;

      if (whenShowPlayersList) {
         setShowPlayersList(true);
      }
   }, [winnerList, isRoundWinnersState]);

   const animationTriggers: IAnimationTriggers = useAnimationTriggers({
      end: (): void => {
         setShowPlayersList(false);
         handleSaveWinnerList([]);
      },
   });

   return {
      showWinnerList,
      animationTriggers,
   };
};
