// @ts-nocheck
import { css } from 'styled-components';
import styled, { keyframes } from 'styled-components/macro';

const textAnimation = keyframes`
  0% {
    opacity: 0;
  }
  13.333333% {
    opacity: 0.6;
  }
  86.666667% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
  }
`;

const firstArrowTransformAnimation = keyframes`
  0% {
    transform: translate(114.000015px, 45.834173px);
  }
  13.333333% {
    transform: translate(114.000015px, 45.834173px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  23.333333% {
    transform: translate(114px, 35.1848px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  33.333333% {
    transform: translate(114px, 35.1848px);
  }
  46.666667% {
    transform: translate(114px, 35.1848px);
  }
  56.666667% {
    transform: translate(114.000015px, 45.834173px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  66.666667% {
    transform: translate(114px, 35.1848px);
  }
  100% {
    transform: translate(114px, 35.1848px);
  }
`;

const firstArrowOpacityAnimation = keyframes`
  0% {
    opacity: 0.08;
  }
  13.333333% {
    opacity: 0.08;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  46.666667% {
    opacity: 0;
  }
  56.666667% {
    opacity: 0;
  }
  73.333333% {
    opacity: 1;
  }
  86.666667% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const secondArrowTransformAnimation = keyframes`
  0% {
    transform: translate(114.000015px, 70.623995px);
  }
  6.666667% {
    transform: translate(114.000015px, 70.623995px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  16.666667% {
    transform: translate(114.000015px, 54.184849px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  26.666667% {
    transform: translate(114.000015px, 54.184849px);
  }
  43.333333% {
    transform: translate(114.000015px, 54.184849px);
  }
  50% {
    transform: translate(114.000015px, 70.623995px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  60% {
    transform: translate(114.000015px, 54.184849px);
  }
  100% {
    transform: translate(114.000015px, 54.184849px);
  }
`;

const secondArrowOpacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  6.666667% {
    opacity: 0;
  }
  26.666667% {
    opacity: 1;
  }
  36.666667% {
    opacity: 0;
  }
  43.333333% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  66.666667% {
    opacity: 1;
  }
  83.333333% {
    opacity: 1;
  }
  96.666667% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const thirdArrowTransformAnimation = keyframes`
  0% {
    transform: translate(113.999986px, 118.881283px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  10% {
    transform: translate(113.999985px, 88.184937px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  20% {
    transform: translate(113.999985px, 88.184937px);
  }
  40% {
    transform: translate(113.999985px, 88.184937px);
  }
  43.333333% {
    transform: translate(113.999986px, 118.881283px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  53.333333% {
    transform: translate(113.999985px, 88.184937px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  66.666667% {
    transform: translate(113.999985px, 88.184937px);
  }
  100% {
    transform: translate(113.999985px, 88.184937px);
  }
`;

const thirdArrowOpacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  23.333333% {
    opacity: 1;
  }
  36.666667% {
    opacity: 0;
  }
  43.333333% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  66.666667% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  93.333333% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

export const SwipeUpViewSvg = styled.svg`
   width: 228px;
   height: 216px;

   ${({ animationDuration }) =>
      animationDuration &&
      css`
         #first-arrow,
         #second-arrow,
         #third-arrow {
            fill: ${({ theme }) => theme.colors?.white};
            clip-rule: evenodd;
            fill-rule: evenodd;
         }
         #first-arrow_to {
            animation: ${firstArrowTransformAnimation} ${animationDuration}s linear infinite normal
               forwards;
         }

         #first-arrow {
            animation: ${firstArrowOpacityAnimation} ${animationDuration}s linear infinite normal
               forwards;
         }

         #second-arrow_to {
            animation: ${secondArrowTransformAnimation} ${animationDuration}s linear infinite normal
               forwards;
         }

         #second-arrow {
            animation: ${secondArrowOpacityAnimation} ${animationDuration}s linear infinite normal
               forwards;
         }

         #third-arrow_to {
            animation: ${thirdArrowTransformAnimation} ${animationDuration}s linear infinite normal
               forwards;
         }

         #third-arrow {
            animation: ${thirdArrowOpacityAnimation} ${animationDuration}s linear infinite normal
               forwards;
         }

         #title,
         #subtitle {
            fill: ${({ theme }) => theme.colors?.white};
            stroke-width: 0;
            text-transform: uppercase;
         }

         #title {
            font-size: 25px;
            font-weight: 700;
            animation: ${textAnimation} ${animationDuration}s linear infinite normal forwards;
         }

         #subtitle {
            font-size: 18px;
            font-weight: 500;
            animation: ${textAnimation} ${animationDuration}s linear infinite normal forwards;
         }
      `}
`;
