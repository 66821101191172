import { z } from 'zod';

export const gameHistoryScheme = z
   .object({
      net_cash: z.number(),
      physical_table_id: z.string(),
      round_created: z.coerce.date(),
      round_number: z.string(),
      title: z.string(),
      total_bet: z.number(),
      uuid: z.string().uuid(),
   })
   .array();
