import { TCommonBet } from '#/state/features/bets';

import { SpecialCommandOptions } from './types';

export abstract class AbstractSpecialCommand {
   protected type: SpecialCommandOptions['type'];
   protected cell: SpecialCommandOptions['cell'];
   protected amount: SpecialCommandOptions['amount'];
   protected extractedCommand: TCommonBet[];
   protected numberOfChips = 1;

   constructor({ type, cell, amount }: Omit<SpecialCommandOptions, 'luckyRange'>) {
      this.cell = cell;
      this.amount = amount;
      this.type = type;
   }

   execute = () => {
      return {
         type: this.type,
         numbers: [this.cell],
         extractedCommand: this.extractedCommand,
         amount: this.extractedCommand.reduce((total, command) => total + command.amount, 0),
      };
   };
}
