import { ElementRef, useRef } from 'react';

import { useOnClickOutside, DeviceOrientation, useDeviceOrientation } from '@/common/hooks';
import {
   useChipTrayActions,
   useSelectedChipSelector,
   MobileChipTray,
} from '@/common/modules/ChipTray';

import { useShowSlider } from '#/modules/ChipTray/components/MobileChipTrayWrapper/hooks';
import { useGameConfigStore } from '#/services/api/gameConfigApiSlice/state';
import { withChipSound } from '#/hocs/withChipSound';

import { IChipConfig } from '../../types';

import * as S from './MobileChipTrayWrapper.styles';

const config = {
   [DeviceOrientation.PORTRAIT]: {
      direction: 'vertical',
      width: '100px',
      height: '400px',
   },
   [DeviceOrientation.LANDSCAPE]: {
      direction: 'horizontal',
      width: '525px',
      height: 'auto',
   },
} as const;

const MobileChipTrayWrapper = ({
   chipSize,
   slidesPerView,
   spaceBetween,
   showChipTray,
   playChipSound,
}: IChipConfig) => {
   const orientation = useDeviceOrientation();
   const { width, height, direction } = config[orientation];

   const { gameConfig } = useGameConfigStore();
   const selectedChip = useSelectedChipSelector();
   const { handleSelectChip } = useChipTrayActions();

   const { handleCloseSlider, handleToggleSlider, showSlider } = useShowSlider();

   const chipTrayRef = useRef<ElementRef<'div'>>(null);

   useOnClickOutside({ ref: chipTrayRef, cb: handleCloseSlider, attached: true });

   const handleClickOnChip = () => {
      handleToggleSlider();
      playChipSound();
   };

   return (
      <S.MobileChipTrayContainer
         isShow={showChipTray}
         className={direction}
         width={width}
         height={height}
         ref={chipTrayRef}
      >
         <MobileChipTray
            config={{
               chips: gameConfig.chipTray,
               direction,
               chipSize,
               slidesPerView,
               spaceBetween,
               selectedChip,
               handleSelectChip,
            }}
            width={width}
            height={height}
            showSlider={showSlider}
            handleCloseSlider={handleCloseSlider}
            handleClickOnChip={handleClickOnChip}
            showChipTray={showChipTray}
         />
      </S.MobileChipTrayContainer>
   );
};

export const MobileChipTrayWrapperWithChipSound = withChipSound(MobileChipTrayWrapper);
