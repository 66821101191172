import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const WinHistoryWithTableCapacityContainer = styled.div`
   --container-padding: 0 24px;
   --container-width: 1160px;
   --container-height: 38px;
   --container-gap: 32px;

   display: flex;
   align-items: center;
   width: var(--container-width);
   height: var(--container-height);
   gap: var(--container-gap);
   border-radius: 2px;
   background: linear-gradient(
      90deg,
      rgba(15, 15, 15, 0) 0%,
      rgba(71, 71, 71, 0.8) 3%,
      rgba(71, 71, 71, 0.8) 96.5%,
      rgba(15, 15, 15, 0) 100%
   );
   padding: var(--container-padding);

   ${orientation.mobileLandscape`
      --container-padding: 0 10px;
      --container-width: 456px;
      --container-height: 24px;
      --container-gap: 10px;
   `}

   ${orientation.portrait`
      --container-padding: 0 5px;
      --container-width: 100%;
      --container-height: 24px;
      --container-gap: 6px;
   `}
`;
