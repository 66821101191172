// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

// the main purpose of this function is to style the chat when it has a thread + override some default styles from the getstream chat
const threadStylesOverride = () => css`
   // when our chat has a thread, we need to increase the width of the container
   &:has(.str-chat__thread) {
      --chat-width: 840px;
   }

   ${orientation.mobileLandscape`
       &:has(.str-chat__thread) {
         --chat-width: 100%;
      }
   `}

   ${orientation.portrait`
      &:has(.str-chat__thread) {
         .str-chat__main-panel {
            width: 0;
         }
         
         .str-chat__thread-container {
            width: 100vw;
         }
      }
   `}


   .str-chat__thread-list .chat-message {
      max-width: 380px;
   }

   .str-chat__thread-container {
      width: 360px;
      min-width: 360px;
      justify-content: space-between;
      background-color: transparent;

      & .chat-message {
         width: auto;
      }

      & .reply-icon {
         display: none;
      }
   }

   ${orientation.mobileLandscape`
      .str-chat__thread-container {
         width: 90%;
      }
   `}

   .str-chat__theme-dark,
   .str-chat__list {
      background: none;
   }

   .str-chat__message-simple-name {
      color: #ffbb3d;
      font-size: 14px;
   }

   .str-chat__message-replies-count-button {
      color: #fff !important;
      background: transparent;
      margin-left: 10px;
      font-family: Montserrat, sans-serif;
      cursor: pointer;
      font-size: 12px;
   }

   .str-chat__thread-start {
      display: none;
   }

   .str-chat__li {
      margin: 4px 0;
   }
`;

export const Chat = styled.div`
   --chat-width: 540px;
   --chat-height: 300px;

   background-color: rgba(3, 3, 3, 0.8);
   width: var(--chat-width);
   height: var(--chat-height);
   border-radius: 15px;
   overflow: hidden;

   ${threadStylesOverride()}

   ${orientation.portrait`
      --chat-width: 100%;
      --chat-height: 35vh;
   `}

   ${orientation.mobileLandscape`
      --chat-width: 100%;
      --chat-height: 45vh;
   `}
`;
