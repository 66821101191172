import { useBalanceActions } from '#/modules/Balance';

import { CancelBetEvent } from './schemes';

type CancelBetEventOutput = (socket: CancelBetEvent) => void;

export const useCancelBetEvent = (): CancelBetEventOutput => {
   const { handleUpdateBalanceFromServer } = useBalanceActions();

   return ({ totalBalance }) => {
      handleUpdateBalanceFromServer(Number(totalBalance));
   };
};
