import { StatisticByRange } from '#/modules/Statistics/state/types';

import { useStatisticsRangeSelector, useStatisticsSelector } from '../../../state';

export const useSelectStatisticsByRange = () => {
   const statisticsByRange = useStatisticsSelector();
   const statisticRange = useStatisticsRangeSelector();

   const statistics: StatisticByRange[number] | undefined = statisticsByRange?.[statisticRange];

   return {
      statistics,
      statisticRange,
   };
};
