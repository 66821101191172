import { FC, ReactNode } from 'react';

import { scrollBarClassName } from './constants';
import * as S from './Scrollbar.styles';

interface IScrollbar {
  children: ReactNode;
  style?: object;
}
export const Scrollbar: FC<IScrollbar> = ({ children, ...props }) => (
  <S.Scrollbar className={scrollBarClassName} {...props}>
    {children}
  </S.Scrollbar>
);
