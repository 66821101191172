import { useGameState } from '@/common/state/gameState';
import { useDocumentEvent } from '@/common/hooks';
import { isMobileDevice } from '@/common/utils/checkDevice';

import { DolbyController } from '../DolbyController';

export const useStreamVisibility = (liveStreamPlayer: DolbyController): void => {
   const { isSessionClosedDueToInactivity } = useGameState();

   const handleVideoVisibility = (): void => {
      if (isSessionClosedDueToInactivity) {
         // we need to return early if session was closed due to inactivity to prevent restarting the video unnecessarily after changing tabs
         return;
      }

      const isTabInvisible = document.hidden;

      if (isMobileDevice()) {
         if (isTabInvisible) {
            liveStreamPlayer?.pause();
         } else {
            liveStreamPlayer?.play();
         }
      }
   };

   useDocumentEvent('visibilitychange', handleVideoVisibility);
};
