import { FC } from 'react';

import { ITimerProps } from '../../types';

import { useCircularCountDownTimerAnimation } from './hooks/useCircularCountDownTimerAnimation';
import {
   CircularCountDownProgressBar,
   CircularCountDownTick,
   CircularCountDownContainer,
} from './components';

export const CircularCountDown: FC<
   ITimerProps & {
      isLastBetsState: boolean;
   }
> = ({ tick, endTime, duration, isLastBetsState }) => {
   const { timerProgressBarRef } = useCircularCountDownTimerAnimation({ tick, endTime, duration });

   return (
      <CircularCountDownContainer>
         <CircularCountDownProgressBar tick={tick} ref={timerProgressBarRef} />
         <CircularCountDownTick tick={tick} isLastBetsState={isLastBetsState} />
      </CircularCountDownContainer>
   );
};
