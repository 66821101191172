import { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { store } from '#/state';

interface IStoreProvider {
   children: ReactNode;
}
export const StoreProvider = ({ children }: IStoreProvider) => (
   <Provider store={store}>{children}</Provider>
);
