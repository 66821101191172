import { FC, ReactElement } from 'react';

import { IIHorizontalCountDownProps } from '../../HorizontalCountDown';

import * as S from './HorizontalCountDownPosition.styles';

interface IHorizontalCountDownPositionProps {
   children: ReactElement<IIHorizontalCountDownProps>;
   isVertical: boolean;
}

export const HorizontalCountDownPosition: FC<IHorizontalCountDownPositionProps> = ({
   children,
   isVertical,
}) => (
   <S.HorizontalCountDownPosition data-testid="horizontal-timer" isVertical={isVertical}>
      {children}
   </S.HorizontalCountDownPosition>
);
