// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex } from '../../../theme/mixins';

export const LimitsRange = styled.span`
  color: ${({ theme }) => theme.colors?.white};
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  &:hover {
    cursor: pointer;
  }
`;

export const MobileBackdrop = styled.div`
  ${applyZIndex('limits')};

  position: fixed;
  background: none;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: -10vw;
`;
