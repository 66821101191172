import { RTKQueryApi, SuccessResponse } from '../types';

import { IBetsSendDataAdapter, TUserBetsByRoundId } from './types';

type TGetUserBetsByRoundIdParams = {
   roundNumber: string;
};

export const betsAPI = (apiSlice: RTKQueryApi) =>
   apiSlice.injectEndpoints({
      endpoints: (build) => ({
         bets: build.mutation<unknown, { bets: IBetsSendDataAdapter }>({
            query: ({ bets }) => ({
               url: '/bets',
               method: 'POST',
               body: bets,
            }),
         }),
         getUserBetsByRoundId: build.query<
            SuccessResponse<TUserBetsByRoundId[]>,
            TGetUserBetsByRoundIdParams
         >({
            query: ({ roundNumber }) => ({
               url: `/bets/round-id/${roundNumber}`,
               method: 'GET',
            }),
         }),
      }),
   });
