import { Chip } from '@/common/ui/Chip';

import { IMobileChipTray } from '../types';
import { ChipTray } from '../chiptray';

import * as S from './MobileChipTray.styles';

export const MobileChipTray = ({
   config,
   width,
   height,
   handleClickOnChip,
   handleCloseSlider,
   showSlider,
   showChipTray,
}: IMobileChipTray) => {
   const {
      chips,
      direction,
      chipSize,
      slidesPerView,
      spaceBetween,
      selectedChip,
      handleSelectChip,
   } = config;

   return (
      <>
         <S.SlideUpWrapper
            data-testid="chipTray-backdrop-container"
            className={direction}
            onClick={handleCloseSlider}
            isShow={showSlider && showChipTray}
         />
         <S.ChipTray
            width={width}
            height={height}
            className={direction}
            isShow={showSlider}
            onClick={handleCloseSlider}
         >
            <ChipTray
               direction={direction}
               chips={chips}
               selectedChip={selectedChip}
               handleSelectChip={handleSelectChip}
               chipSize={chipSize}
               slidesPerView={slidesPerView}
               spaceBetween={spaceBetween}
            />
         </S.ChipTray>

         <S.SelectedChipContainer data-testid="selected-chip-container" className={direction}>
            <Chip chips={chips} chipNumber={selectedChip.value} onClick={handleClickOnChip} />
         </S.SelectedChipContainer>
      </>
   );
};
