import { callBetsConfig } from '#/core/config';
import { TLimits } from '#/modules/TableCapacity';

export const addCallBetsLimits = (limits: TLimits, callBets: typeof callBetsConfig) => {
   const limitsWithCallBets = { ...limits };

   for (const [callBetName, bets] of Object.entries(callBets)) {
      limitsWithCallBets[callBetName] = { min: 0, max: 0 };

      bets.forEach((bet) => {
         const { min, max } = limits[bet.type];
         limitsWithCallBets[callBetName].min += min;
         limitsWithCallBets[callBetName].max += max;
      });
   }

   return limitsWithCallBets;
};
