import { PocketType } from '../../types';

export const removeClassByPocketType = ({ node }: { node: Element }): void => {
   const classNames: Record<PocketType, string> = {
      cold: 'cold',
      hot: 'hot',
   };

   node.classList.remove(classNames.cold, classNames.hot);
};
