import { isValidLanguage } from '@/common/helpers';
import { TRouletteClientLanguage } from '@/common/entities/Language';
import { getValueFromQueryParams } from '@/common/utils';

import { getNestedProperty } from '..';

export const translationQuery = (key: string, translations): string => {
   const DEFAULT_LANG: TRouletteClientLanguage = 'en';
   const languageQueryParam = 'language';

   const languageFromUrl = getValueFromQueryParams({
      key: languageQueryParam,
      error: 'Parameter "language" not found in url',
   });

   const gameLanguage = isValidLanguage(languageFromUrl) ? languageFromUrl : DEFAULT_LANG;

   return getNestedProperty(translations[gameLanguage]?.translation, key) || key;
};
