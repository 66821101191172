import { TBetType } from '#/state/features/bets';

const capitalizeFirstLetter = (w: string) => w.charAt(0).toUpperCase() + w.slice(1);

const toCamelCase = (str: string) => {
   const strSplit = str.toLowerCase().split('_');
   const capitalized = strSplit.map((w, i) => (i > 0 ? capitalizeFirstLetter(w) : w));
   return capitalized.join('');
};

export const transformBetType = (bet: TBetType): string => toCamelCase(bet.type);
