// @ts-nocheck
import styled, { keyframes } from 'styled-components/macro';

import { orientation } from '../../../theme/mixins';
import { hexToRgba } from '../../../../utils/hexToRgba';

const pulseAnimation = (color: string) => keyframes`
  0% {
    opacity: 1;
    -webkit-box-shadow: 0 0 0 0px ${hexToRgba(color, 1)};
    box-shadow: 0 0 0 0px ${hexToRgba(color, 1)};
  }
  100% {
    -webkit-box-shadow: 0 0 0 8px ${hexToRgba(color, 0)};
    box-shadow: 0 0 0 8px ${hexToRgba(color, 0)};
    opacity: 0;
  }
`;

export const SvgWrapper = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 1/1;
`;

export const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const Circle = styled.circle`
  fill: var(--control-bg-color);
  fill-opacity: 0.4;
  stroke-width: 4;
  transition: all var(--hover-transition-duration) var(--hover-transition-timing-function);
`;

export const Title = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  color: var(--control-title-color);
  font-family: 'Montserrat', sans-serif;
  font-size: var(--control-title-fz);
  font-weight: 700;
  pointer-events: none;

  ${orientation.portrait`
    white-space: unset;
  `}
`;

export const BetControlWrapper = styled.button`
  --control-size: ${({ size }) => size};
  --control-gap: 4px;
  /* hover */
  --hover-transition-duration: 0.2s;
  --hover-transition-timing-function: ease-in-out;
  /* animation */
  --animation-timing-function: ease-in-out;
  --animation-duration: 1s;
  /* colors */
  --disabled-color: #999999;
  --control-bg-color: #030303;
  --control-icon-color: #ffffff;
  /* control title */
  --control-title-color: #ffffff;
  --control-title-fz: 12px;

  position: relative;
  width: var(--control-size);
  height: var(--control-size);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--control-gap);
  margin-top: ${({ title }) =>
    title ? 'calc(var(--control-gap) + var(--control-title-fz))' : '0'};
  border-radius: 50px;
  background: none;
  cursor: pointer;
  transition: all 0.5s ease;

  &:before {
    transition: all 0.5s ease;
    content: '';
    display: ${({ withPulseAnimation }) => (withPulseAnimation ? 'unset' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--control-size);
    border-radius: 50px;
    animation-name: ${({ withPulseAnimation, color }) =>
      withPulseAnimation ? pulseAnimation(color) : 'none'};
    animation-duration: var(--animation-duration);
    animation-timing-function: var(--animation-timing-function);
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  &:hover {
    @media (hover: hover) {
      &:before {
        animation: none;
      }

      ${Circle} {
        fill: ${({ color }) => color};
      }
    }
  }

  &:active {
    &:before {
      animation: none;
    }

    ${Circle} {
      fill: ${({ color }) => color};
    }
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;

    &:before {
      content: none;
    }

    ${Circle} {
      stroke: var(--disabled-color);
      fill: none;
    }

    path {
      fill: var(--disabled-color);
    }

    ${Title} {
      color: var(--disabled-color);
    }
  }

  ${Svg},
  ${Circle} {
    stroke: ${({ color }) => color};
  }

  path {
    stroke: none;
    fill: var(--control-icon-color);
  }

  ${orientation.mobileLandscape`
    --control-size: 44px;
  `}

  ${orientation.portrait`
    --control-size: 13vw;

    margin-top: 0;
  `}
`;
