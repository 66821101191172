import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { userGeneralSettingsSlice } from '#/modules/Settings/state';
import { TUserGeneralSettingsUpdateEvent } from '#/services/sockets/hooks/event-hooks/useSocketEvents/useUserGeneralSettingsUpdateEvent/schemes';

export const userGeneralSettingsUpdatedEventMiddleware = createListenerMiddleware();
export const userGeneralSettingsUpdatedEventAction = createAction<TUserGeneralSettingsUpdateEvent>(
   'userGeneralSettingsUpdated',
);

userGeneralSettingsUpdatedEventMiddleware.startListening({
   actionCreator: userGeneralSettingsUpdatedEventAction,
   effect: async ({ payload }, listenerApi) => {
      const { dispatch } = listenerApi;
      const { setIsDealerRatingEnabled } = userGeneralSettingsSlice.actions;
      const { is_feedback_enabled } = payload;

      dispatch(setIsDealerRatingEnabled(is_feedback_enabled));
   },
});
