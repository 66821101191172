import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { IAuthState } from '#/services';
import { TRootState } from '#/state/types';
import { formatCurrencySign } from '#/helpers/formatCurrencySign';

const initialState: IAuthState = {
   user: {
      id: '',
      age: 22,
      country: 'USA',
      currency: 'EUR',
      token: '',
      totalBalance: 0,
      username: '',
      chatToken: '',
   },
   session: {
      id: '',
      secret: '',
   },
};

export const authSlice = createSlice({
   name: 'authSlice',
   initialState,
   reducers: {
      updateUserAndSession: (state, action: PayloadAction<IAuthState>): void => {
         state.user = action.payload.user;
         state.session = action.payload.session;
      },
      updateUser: (state, action: PayloadAction<IAuthState['user']>): void => {
         state.user = action.payload;
      },
      updateSession: (state, action: PayloadAction<IAuthState['session']>): void => {
         state.session = action.payload;
      },
   },
});

export const useAuthActions = () => {
   const dispatch = useDispatch();
   const { updateUser, updateSession } = authSlice.actions;

   const handleUpdateAuthData = (authData: IAuthState): void => {
      dispatch(updateUser(authData.user));
      dispatch(updateSession(authData.session));
   };

   return { handleUpdateAuthData };
};

export const useUserSelector = () => useSelector(({ auth }: TRootState) => auth?.user);
export const useAuthSelector = () => useSelector(({ auth }: TRootState) => auth);

export const useUserCurrencySelector = () =>
   useSelector(({ auth }: TRootState) => auth?.user.currency);

export const useUserCurrencySign = () => {
   const userCurrency = useUserCurrencySelector();

   return formatCurrencySign(userCurrency);
};
