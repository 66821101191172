import { FC } from 'react';

import { useSelectedChipSelector } from '@/common/modules/ChipTray';
import { IPlayChipSound } from '@/common/entities';

import { mapConfigCommonBets } from '#/core';
import { betsCreator } from '#/core/betsCreator';
import { useHoverSpecialBet } from '#/modules/Favourite/components/SpecialBets/hooks/useHoverSpecialBet';
import { getSpecialBets } from '#/modules/Favourite/components/StraightUpBets';
import { withChipSound } from '#/hocs';

import { CenterStatisticsBar } from '../../CenterStatisticsBar';
import { StatisticsBar } from '../../StatisticsBar';

import { ITripleStatistics } from './types';
import * as S from './TripleStatistics.styles';

export const TripleStatistics: FC<ITripleStatistics & IPlayChipSound> = ({
   leftCell,
   middleCell,
   rightCell,
   playChipSound,
}) => {
   const { specialBetsObject } = getSpecialBets();
   const { value: chipValue } = useSelectedChipSelector();
   const hoverChildElementsRef = useHoverSpecialBet();

   const handleTripleStatisticsClick = (cellNumber: number) => (): void => {
      const { id, type, numbers } = mapConfigCommonBets[cellNumber];
      betsCreator.makeCommonBet({ id, type, numbers, amount: chipValue });
      playChipSound();
   };

   return (
      <S.StatisticByColorWrapper ref={hoverChildElementsRef}>
         <StatisticsBar
            dataTestId={leftCell.dataTestId}
            color="#82828233"
            direction="left"
            label={leftCell.label}
            percentage={leftCell.percentage}
            onClick={handleTripleStatisticsClick(leftCell.betId)}
            highlightCells={specialBetsObject[leftCell.betId].numbers}
         />

         <CenterStatisticsBar
            dataTestId={middleCell.dataTestId}
            color="#3D3D3D"
            label={middleCell.label}
            percentage={middleCell.percentage}
            onClick={handleTripleStatisticsClick(middleCell.betId)}
            highlightCells={specialBetsObject[middleCell.betId].numbers}
         />

         <StatisticsBar
            dataTestId={rightCell.dataTestId}
            direction="right"
            color="#82828233"
            label={rightCell.label}
            percentage={rightCell.percentage}
            onClick={handleTripleStatisticsClick(rightCell.betId)}
            highlightCells={specialBetsObject[rightCell.betId].numbers}
         />
      </S.StatisticByColorWrapper>
   );
};

export const TripleStatisticsWithChipSound = withChipSound(TripleStatistics);
