import { createAnimation } from '#/modules/Track/helpers';
import { TrackContainerId } from '#/modules/Track/types';

import { ANIMATED_ELEMENT_STROKE_DASHARRAY } from '../../constants';
import { createdAnimatedElements } from '../createdAnimatedElements';

interface IAnimateWinningNumber {
   animationDurationOnMs: number;
   strokeColor: string;
   trackContainerId: TrackContainerId;
   winningNumberCell: HTMLElement;
}

interface IAnimateWinningNumberOutput {
   animatedElement: HTMLElement;
   handleStartAnimation: () => void;
}

export const animateWinningNumber = ({
   winningNumberCell,
   strokeColor,
   animationDurationOnMs,
}: IAnimateWinningNumber): IAnimateWinningNumberOutput => {
   const animatedSvg = createdAnimatedElements({
      node: winningNumberCell,
      strokeColor,
   }) as HTMLElement;

   const handleStartAnimation = (): void => {
      const animation = createAnimation({
         strokeDasharray: ANIMATED_ELEMENT_STROKE_DASHARRAY,
         duration: animationDurationOnMs,
      });

      const handler: Animation = animatedSvg.animate(...animation);
      handler.addEventListener('finish', () => animatedSvg.remove());
   };

   return { animatedElement: animatedSvg, handleStartAnimation };
};
