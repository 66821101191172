import { addStyleProperties } from '#/modules/Track';

import {
   ANIMATED_ELEMENT_STROKE_DASHARRAY,
   ANIMATED_ELEMENT_STROKE_WIDTH_PX,
} from '../../constants';

export const createdAnimatedElements = ({
   node,
   strokeColor,
}: {
   node: HTMLElement;
   strokeColor: string;
}) => {
   const svg = node.cloneNode(false);

   const addStylesToNode = addStyleProperties({
      strokeWidth: `${ANIMATED_ELEMENT_STROKE_WIDTH_PX}px`,
      strokeLinecap: 'round',
      strokeDasharray: `${ANIMATED_ELEMENT_STROKE_DASHARRAY}`,
      strokeDashoffset: '0',
      stroke: strokeColor,
   });

   addStylesToNode(svg as HTMLElement);

   return svg;
};
