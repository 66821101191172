import { RefObject, useEffect, useState } from 'react';

import { TNullable } from '@/common/types';

import { ITrackComponentState } from '#/state/features/bets';

import { getNodeCellByCellId } from '../../helpers';

export const useChipSize = ({
   raceTrackContainerRef,
   trackStore,
}: {
   raceTrackContainerRef: RefObject<SVGSVGElement>;
   trackStore: () => ITrackComponentState;
}) => {
   const [size, setSize] = useState<TNullable<number>>(null);
   const { useTrackTypeSelector } = trackStore();
   const trackType = useTrackTypeSelector();

   useEffect(() => {
      const raceTrackContainer = raceTrackContainerRef.current;

      if (raceTrackContainer) {
         // find cell with number 20 because this cell have rectangular form
         const cellWithNumber20 = getNodeCellByCellId({
            container: raceTrackContainer,
            cellId: '20',
         });

         if (cellWithNumber20) {
            const { width, height }: DOMRect = (cellWithNumber20 as SVGSVGElement).getBBox();

            setSize(Math.min(width, height));
         }
      }
   }, [raceTrackContainerRef, trackType]);

   return size;
};
