import { useGameStateActions } from '@/common/state/gameState';
import { EModalNotificationType } from '@/common/ui/ModalNotification/constants';

import { useTranslation } from '#/translates';

import { Overlay } from '../Overlay';

export const NotifyInactivePlayerOverlay = () => {
   const { t } = useTranslation();
   const { handleUnsetNotifyInactivePlayer } = useGameStateActions();

   return (
      <Overlay
         withBackdrop={false}
         actionButton={{
            title: t('notifyInactivePlayerOverlay.action'),
            onClick: handleUnsetNotifyInactivePlayer,
         }}
         type={EModalNotificationType.Warning}
         title={t('notifyInactivePlayerOverlay.title')}
      />
   );
};
