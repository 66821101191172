import {
   DoubleStatistics,
   StatisticByColor,
   StatisticType,
   TripleStatistics,
} from '../../../StatisticsComponents';
import { useSelectStatisticsByRange } from '../../hooks';
import * as S from '../../../../Statistics.styles';

import { getStatisticsBySectionConfig } from './utils/getStatisticsBySectionConfig';

export const StatisticsBySections = () => {
   const { statistics } = useSelectStatisticsByRange();
   const { colorStatistics, doubleStatistics, callBetsStatistics, tripleStatistics } =
      getStatisticsBySectionConfig(statistics);

   return (
      <div>
         <StatisticByColor {...colorStatistics} />
         <S.DoubleStatisticsWrapper>
            <DoubleStatistics leftCell={doubleStatistics.even} rightCell={doubleStatistics.odd} />
            <DoubleStatistics leftCell={doubleStatistics.low} rightCell={doubleStatistics.high} />
         </S.DoubleStatisticsWrapper>
         <TripleStatistics
            leftCell={tripleStatistics.firstDozen}
            middleCell={tripleStatistics.secondDozen}
            rightCell={tripleStatistics.thirdDozen}
         />
         <TripleStatistics
            leftCell={tripleStatistics.firstColumn}
            middleCell={tripleStatistics.secondColumn}
            rightCell={tripleStatistics.thirdColumn}
         />
         <StatisticType statisticsTypes={callBetsStatistics} />
      </div>
   );
};
