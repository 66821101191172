import { Tooltip, EControl } from '@/common/ui';
import { useSidebar } from '@/common/modules/Sidebar';
import { ERouletteSidebar } from '@/common/entities/Sidebar';

import { useTranslation } from '#/translates';

import { Control } from '../ControlWithTapSound';

export const StatisticsControl = () => {
   const { sidebarType, handleSetSidebarType } = useSidebar();
   const { t } = useTranslation();

   return (
      <Tooltip position="left" text={t('sidebars.statistics.title')}>
         <Control
            isActive={sidebarType === ERouletteSidebar.Statistics}
            dataTestId="statistics-control"
            variant={EControl.Statistics}
            onClick={() => handleSetSidebarType(ERouletteSidebar.Statistics)}
         />
      </Tooltip>
   );
};
