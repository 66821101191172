import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isValidLanguage, useGameConfigStore } from 'services/api/gameConfigApiSlice/state';
import { userConnectionAction } from 'services/api/middleware/userConnectionMiddleware';

import { TNullable } from '@/common/types';
import { getValueFromQueryParams } from '@/common/utils';

export const useInitUserConnection = () => {
   const { handleSetGameLanguage } = useGameConfigStore();
   const dispatch = useDispatch();

   useEffect(() => {
      const languageQueryParam = 'language';
      const languageFromUrl: TNullable<string> = getValueFromQueryParams({
         key: languageQueryParam,
         error: 'Parameter "language" not found in url',
      });

      if (isValidLanguage(languageFromUrl)) {
         handleSetGameLanguage(languageFromUrl);
      }

      dispatch(userConnectionAction());
   }, []);
};
