import { formatCurrency } from 'helpers';
import React, { useState } from 'react';

import { ProgressBar } from '#/components/ProgressBar';
import { handleChangeInput } from '#/modules/Autoplay/helper';
import { useAutoplay } from '#/modules/Autoplay/hooks';
import { WarningTooltip as LossLimitTooltip } from '#/modules/Tooltips/TooltipPortal/components/WarningTooltip';

import { LimitInput } from '../LimitInput';

import { useSetEmptyValue } from './hooks';
import { LossLimitTooltipWrapper } from './components/LossLimitTooltipWrapper';
import * as S from './LossLimit.styles';

interface ILossLimit {
   currencySign: string;
}

export const LossLimit = ({ currencySign }: ILossLimit) => {
   const { autoplay } = useAutoplay();

   const lossLimit = autoplay.getLossLimit();
   const userLossProgress = autoplay.getUserLossProgress();
   const [inputValue, setInput] = useState(formatCurrency(lossLimit));

   const limit = inputValue.length < 1 ? inputValue : formatCurrency(lossLimit);

   const handleResetLimit = () => {
      setInput('');
      autoplay.handleLossLimit(0);
   };

   useSetEmptyValue(inputValue, setInput);

   return (
      <>
         <LossLimitTooltipWrapper inputValue={inputValue}>
            {({ handleShowTooltip, tooltip }) => (
               <>
                  <LimitInput
                     label={'sidebars.autoplay.lossLimit'}
                     currencySign={currencySign}
                     limit={limit}
                     testId="loss-limit"
                     handleChange={(e) => {
                        handleChangeInput({
                           input: e.target,
                           setInput,
                           handler: autoplay.handleLossLimit,
                        });
                        handleShowTooltip(e.target);
                     }}
                     handleClose={(e) => {
                        const $inputElement = e.target.parentElement.parentElement;
                        handleResetLimit();
                        handleShowTooltip($inputElement);
                     }}
                  />
                  <LossLimitTooltip
                     shouldShowNotification={tooltip.show}
                     message={tooltip.message}
                     selector="loss-limit"
                  />
               </>
            )}
         </LossLimitTooltipWrapper>

         <S.LossLimitProgressBar label={`${currencySign} ${lossLimit}`}>
            <ProgressBar progress={userLossProgress} />
         </S.LossLimitProgressBar>
      </>
   );
};
