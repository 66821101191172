import { IBetsState } from '#/state/features/bets';
import { Store } from '#/state/types';

import { rebetSlice } from '..';

const createActions = (dispatch: Store['dispatch']) => {
   const { savePreviousRoundBets } = rebetSlice.actions;

   const handleSavePreviousRoundBets = (previousRoundBets: IBetsState): void => {
      dispatch(savePreviousRoundBets(previousRoundBets));
   };

   return { handleSavePreviousRoundBets };
};

export const rebetActions = (store: Store) => createActions(store.dispatch);
