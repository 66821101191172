import { TNullable } from '@/common/types';

type RouletteMatrix = number[][];
type RouletteMatrixWithSingleZero = TNullable<number>[][];

export const roulettePockets: RouletteMatrix = [
   [0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
   [0, 2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
   [0, 1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],
];

export const roulettePocketsWithSingleZero: RouletteMatrixWithSingleZero = [
   [0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
   [null, 2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
   [null, 1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],
];

export const roulettePocketsList = Array.from({ length: 37 }, (_, index) => index);
