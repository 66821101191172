import { DateFormatter } from '../../utils';

import { colorTimerBySeconds, borderColorsBySeconds } from './constants';

export const getBorderColorByTimerTick = (tick: number): string => {
   return borderColorsBySeconds?.[tick] ?? borderColorsBySeconds.DEFAULT;
};

export const getBackgroundColorByTimerTick = (tick: number): string => {
   return colorTimerBySeconds?.[tick] ?? colorTimerBySeconds.DEFAULT;
};

export const calculateAnimationProgress = ({
   currentTimerTickInSeconds,
   duration,
}: {
   currentTimerTickInSeconds: number;
   duration: number;
}) => {
   const progressBarSize = 100;

   return (progressBarSize * currentTimerTickInSeconds) / duration;
};

export const currentTimerTick = (endTime: number) => {
   return endTime - DateFormatter.toUnixTime(Date.now());
};
