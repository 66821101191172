import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { TUserId } from '@/common/entities';

import { notificationsSlice } from '#/modules/Notifications';
import { TRootState } from '#/state/types';

export interface IAutoplayState {
   autoplayId: string;
   autoplayRounds: number[];
   bets: number;
   initialBalance?: string;
   isAutoplayStarted: boolean;
   isLoading: boolean;
   lossLimit: number;
   roundCounter: number;
   selectedRound: number;
   singleWinLimit: number;
   userId: TUserId;
   userLossProgress: number;
}

const initialState: IAutoplayState = {
   lossLimit: 0,
   singleWinLimit: 0,
   autoplayRounds: [],
   selectedRound: 0,
   isAutoplayStarted: false,
   isLoading: false,
   autoplayId: '',
   bets: 0,
   roundCounter: 0,
   userLossProgress: 0,
   userId: '',
};

export const autoplaySlice = createSlice({
   name: 'autoplay',
   initialState,
   reducers: {
      setAutoplayRounds: (state, action: PayloadAction<Array<number>>) => {
         state.autoplayRounds = action.payload;
         state.selectedRound = action.payload[0];
         state.roundCounter = action.payload[0];
      },

      setLossLimit: (state, action: PayloadAction<number>) => {
         state.lossLimit = action.payload;
      },

      setSingleWinLimit: (state, action: PayloadAction<number>) => {
         state.singleWinLimit = action.payload;
      },

      setSelectedRound: (
         state,
         action: PayloadAction<{
            selectedRound: IAutoplayState['selectedRound'];
            lossLimit: IAutoplayState['lossLimit'];
         }>,
      ) => {
         const { selectedRound, lossLimit } = action.payload;
         state.selectedRound = selectedRound;
         state.lossLimit = lossLimit;
         state.roundCounter = selectedRound;
      },

      setAutoplayStarted: (state, action: PayloadAction<boolean>) => {
         state.isAutoplayStarted = action.payload;
      },

      setAutoplayId: (state, action: PayloadAction<string>) => {
         state.autoplayId = action.payload;
      },

      setAutoplayLeftRounds: (state, action: PayloadAction<number>) => {
         state.roundCounter = action.payload;
      },

      resetAutoplay: (state) => {
         state.autoplayId = '';
         state.roundCounter = state.selectedRound;
         state.isAutoplayStarted = false;
         state.userLossProgress = 0;
      },

      setUserLoss: (state, action: PayloadAction<number>) => {
         state.userLossProgress = action.payload;
      },
      setIsLoading: (state, action: PayloadAction<boolean>) => {
         state.isLoading = action.payload;
      },
   },
});

export const useAutoplayActions = (dispatch) => {
   const {
      setAutoplayRounds,
      setLossLimit,
      setSingleWinLimit,
      setAutoplayStarted,
      setSelectedRound,
      setAutoplayId,
      setAutoplayLeftRounds,
      resetAutoplay,
      setUserLoss,
      setIsLoading,
   } = autoplaySlice.actions;

   const handleSetAutoplayRounds = (rounds: number[]) => {
      dispatch(setAutoplayRounds(rounds));
   };

   const handleSetLossLimit = (lossLimit: number) => {
      dispatch(setLossLimit(lossLimit));
   };

   const handleSetSingleWinLimit = (singleWinLimit: number) => {
      dispatch(setSingleWinLimit(singleWinLimit));
   };

   const handleSetAutoplayStarted = (isAutoplayStarted: boolean) => {
      dispatch(setAutoplayStarted(isAutoplayStarted));
   };

   const handleSetSelectedRound = (payload: {
      selectedRound: IAutoplayState['selectedRound'];
      lossLimit: IAutoplayState['lossLimit'];
   }) => {
      dispatch(setSelectedRound(payload));
   };

   const handleSetAutoplayId = (id: string) => {
      dispatch(setAutoplayId(id));
   };
   const handleSetAutoplayLeftRounds = (rounds: number) => {
      dispatch(setAutoplayLeftRounds(rounds));
   };
   const handleResetAutoplay = () => {
      dispatch(resetAutoplay());
   };

   const handleShowNotification = (message) => {
      dispatch(notificationsSlice.actions.addNotification(message));
   };

   const handleSetUserLossProgress = (amount: number) => {
      dispatch(setUserLoss(amount));
   };

   const handleCancelApi = async (cancelAutoplayEndpoint) => {
      const response = await dispatch(cancelAutoplayEndpoint());

      return response;
   };

   const handleStartApi = async (startAutoplayEndpoint) => {
      const response = await dispatch(startAutoplayEndpoint());
      return response;
   };

   const handleSetIsLoading = (isLoading: boolean) => {
      dispatch(setIsLoading(isLoading));
   };

   return {
      handleSetAutoplayRounds,
      handleSetLossLimit,
      handleSetSingleWinLimit,
      handleSetAutoplayStarted,
      handleSetSelectedRound,
      handleSetAutoplayId,
      handleShowNotification,
      handleCancelApi,
      handleSetAutoplayLeftRounds,
      handleResetAutoplay,
      handleSetUserLossProgress,
      handleStartApi,
      handleSetIsLoading,
   };
};

const useAutoplayRounds = () => {
   return useSelector((state: TRootState) => state.autoplay?.autoplayRounds);
};

const useSelectedAutoplayRound = () => {
   return useSelector((state: TRootState) => state.autoplay?.selectedRound);
};

const useLossLimit = () => {
   return useSelector((state: TRootState) => state.autoplay?.lossLimit);
};

const useSingleWinLimit = () => {
   return useSelector((state: TRootState) => state.autoplay?.singleWinLimit);
};

const useAutoplayStartState = () => {
   return useSelector((state: TRootState) => state.autoplay?.isAutoplayStarted);
};

const useAutoplayId = () => {
   return useSelector((state: TRootState) => state.autoplay?.autoplayId);
};

const useAutoplayRoundCount = () => {
   return useSelector((state: TRootState) => state.autoplay?.roundCounter);
};

const useUserLossProgress = () => {
   return useSelector((state: TRootState) => state.autoplay?.userLossProgress);
};

const useIsLoading = () => {
   return useSelector((state: TRootState) => state.autoplay?.isLoading);
};

export const useAutoplaySelectors = () => {
   return {
      useLossLimit,
      useAutoplayRounds,
      useSingleWinLimit,
      useSelectedAutoplayRound,
      useAutoplayStartState,
      useAutoplayId,
      useAutoplayRoundCount,
      useUserLossProgress,
      useIsLoading,
   };
};
