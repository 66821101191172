import styled from 'styled-components/macro';

import { applyZIndex } from '@/common/ui/theme/mixins';
import * as CircularCountDownStyles from '@/common/modules/CountDown/components/CircularCountDown/components/CircularCountDownContainer/CircularCountDownContainer.styles';
import { FadeBox } from '@/common/ui';

export const CenterColumnContainer = styled.div`
   position: relative;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-end;
   gap: 5px;
`;

export const Notifications = styled.div`
   position: absolute;
   top: 45%;
`;

export const CountDown = styled(FadeBox)`
   display: flex;
   min-height: var(--horizontal-timer-height);

   ${CircularCountDownStyles.CircularCountDownContainer} {
      margin-right: 20px;
   }
`;

export const BettingPanelGroupContainer = styled(FadeBox)`
   ${applyZIndex('bettingTimer')}

   position: absolute;
   bottom: -4px;
   width: 100%;
   display: grid;
   grid-template-areas: 'leftControl chipTray rightControl';
   grid-template-columns: 44px auto 44px;
   align-items: center;
   gap: 12px;
   justify-content: center;
   transition: opacity 500ms;
   pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
`;
