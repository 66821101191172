// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const Wrapper = styled.div`
   background: linear-gradient(
      270deg,
      rgba(3, 3, 3, 0) 3.33%,
      rgba(3, 3, 3, 0.8) 32.61%,
      rgba(3, 3, 3, 0.8) 62.17%,
      rgba(3, 3, 3, 0) 94.93%
   );
   color: ${({ theme }) => theme.colors?.main};
   font-size: 28px;
   font-weight: 500;
   padding: 12px 140px;
   text-transform: uppercase;

   position: absolute;
   top: 57%;

   ${orientation.mobileLandscape`
      font-size: 17px;
      padding: 15px 40px;
      position: unset;
   `}
`;

export const WrapperPortrait = styled.div`
   background: linear-gradient(
      270deg,
      rgba(3, 3, 3, 0) 3.33%,
      rgba(3, 3, 3, 0.8) 32.61%,
      rgba(3, 3, 3, 0.8) 62.17%,
      rgba(3, 3, 3, 0) 94.93%
   );
   color: ${({ theme }) => theme.colors?.main};
   transform: translateY(40px);
   text-align: center;
   width: 100vw;
   font-size: 14px;
   font-weight: 500;
   text-transform: uppercase;

   ${orientation.mobileLandscape`
      font-size: 16px;
      padding: 12px 40px;
   `}
`;
