import styled from 'styled-components/macro';

import { applyZIndex, orientation } from '@/common/ui/theme/mixins';

export const StarsWrapper = styled.div`
   display: flex;
   justify-content: center;

   --star-size: 16px;

   > svg {
      margin-right: 5px;
      width: ${({ size }) => size || `var(--star-size)`};
      height: ${({ size }) => size || `var(--star-size)`};

      &:hover {
         cursor: pointer;
      }

      ${orientation.portrait`
         --star-size: 12px;
      `};

      ${orientation.mobileLandscape`
         --star-size: 12px;
         ${applyZIndex('feedback')};
      `}
   }
`;
