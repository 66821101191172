// @ts-nocheck
import styled from 'styled-components/macro';

import { getBackgroundColorByTimerTick, getBorderColorByTimerTick } from '../../../../utils';

export const HorizontalCountDownProgressBar = styled.div`
   position: absolute;
   left: 0;
   width: ${({ tick }) => (tick ? '100%' : '0%')};
   height: 100%;
   border-radius: 0 var(--timer-border-radius) var(--timer-border-radius) 0;
`;

export const HorizontalCountDownProgressBarContainer = styled.div`
   position: absolute;
   width: 100%;
   height: 100%;
   overflow: hidden;
   border-radius: var(--timer-border-radius);
   transition: border-color var(--timer-transition-duration) var(--timer-transition-timing-function);
   padding-left: calc(var(--timer-height) - var(--timer-border-width));
   border: ${({ tick }) => `var(--timer-border-width) solid ${getBorderColorByTimerTick(tick)}`};

   ${HorizontalCountDownProgressBar} {
      background-color: ${({ tick }) => getBackgroundColorByTimerTick(tick)};
      transition: background-color var(--timer-transition-duration)
         var(--timer-transition-timing-function);
   }
`;
