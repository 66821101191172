import { FC, ReactNode } from 'react';

import { Icon } from './components';
import { EModalNotificationType } from './constants';
import * as S from './ModalNotification.styles';

export interface IModalNotificationProps {
  actionButton: {
    title: string;
    onClick: () => void;
  };
  message?: ReactNode;
  onClose?: () => void;
  title?: string;
  type: EModalNotificationType;
}

export const ModalNotificationView: FC<IModalNotificationProps> = ({
  onClose,
  actionButton,
  type = EModalNotificationType.Error,
  title = 'Ups...',
  message = '',
}) => (
  <S.ModalWrapper type={type}>
    <S.ModalContent>
      <Icon type={type} />
      <S.Title>{title}</S.Title>
      {message && <S.Message>{message}</S.Message>}
      {onClose && <S.ActionIcon onClick={onClose} />}
    </S.ModalContent>
    <S.Button onClick={actionButton.onClick} type={type}>
      {actionButton.title}
    </S.Button>
  </S.ModalWrapper>
);
