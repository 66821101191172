import { FC, MouseEvent } from 'react';

import * as S from './CloseButton.styles';

interface ICloseButtonProps {
  onClick: (event?: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
export const CloseButton: FC<ICloseButtonProps> = ({ onClick }) => (
  <S.CloseButton onClick={onClick} />
);
