import { useDispatch } from 'react-redux';

import { TDoubleBet, TExcludeBetFromBetList, IBetsState } from '#/state/features/bets';
import { Store } from '#/state/types';

import { betConstructorSlice } from '..';

const createActions = (dispatch: Store['dispatch']) => {
   const {
      undo,
      addBets,
      addDoubleBet,
      removeBetById,
      resetBets,
      toggleTrackType,
      increaseRangeLevel,
      decreaseRangeLevel,
   } = betConstructorSlice.actions;

   const handleToggleTrackType = (): void => {
      dispatch(toggleTrackType());
   };

   const handleIncreaseRangeLevel = (): void => {
      dispatch(increaseRangeLevel());
   };

   const handleDecreaseRangeLevel = (): void => {
      dispatch(decreaseRangeLevel());
   };

   const handleResetBets = (): void => {
      dispatch(resetBets());
   };

   const handleUndoBets = (): void => {
      dispatch(undo());
   };

   const handleAddBets = (newBets: TExcludeBetFromBetList<TDoubleBet>[]): void => {
      dispatch(addBets(newBets));
   };

   const handleAddDoubleBet = (
      betsData: [IBetsState['totalBets'], IBetsState['betsHistory']],
   ): void => {
      dispatch(addDoubleBet(betsData));
   };

   const handleRemoveBetById = (betId: number): void => {
      dispatch(removeBetById(betId));
   };

   return {
      handleResetBets,
      handleUndoBets,
      handleAddBets,
      handleAddDoubleBet,
      handleRemoveBetById,
      handleToggleTrackType,
      handleIncreaseRangeLevel,
      handleDecreaseRangeLevel,
   };
};

export const betGeneratorActions = (store: Store) => createActions(store.dispatch);
export const useBetGeneratorActions = () => createActions(useDispatch());
