import styled from 'styled-components/macro';

export const AppreciationScreenWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 15px;
`;

export const AppreciationMessage = styled.div`
   font-size: 14px;
   font-weight: 600;
   color: white;
   text-align: center;
`;
