// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation, applyZIndex } from '@/common/ui/theme/mixins';

import DesktopBg from './assets/DesktopBg.jpg';
import DesktopBgAvif from './assets/DesktopBg.avif';
import DesktopBgWebP from './assets/DesktopBg.webp';
import MobileBgLandscape from './assets/MobileBg.jpg';
import MobileBgLandscapeAvif from './assets/MobileBg.avif';
import MobileBgLandscapeWebP from './assets/MobileBg.webp';
import MobilePortrait from './assets/MobilePortrait.jpg';
import MobilePortraitAvif from './assets/MobilePortrait.avif';
import MobilePortraitWebP from './assets/MobilePortrait.webp';

export const RouletteLoadingScreen = styled.div`
   ${applyZIndex('loadingScreen')};
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   background: rgba(0, 0, 0, 0.8) no-repeat right center;
   background-image: image-set(
      url(${DesktopBgAvif}) type('image/avif') 1x,
      url(${DesktopBgWebP}) type('image/webp') 1x,
      url(${DesktopBg}) 1x,
      url(${MobileBgLandscapeAvif}) type('image/avif') 2x,
      url(${MobileBgLandscapeWebP}) type('image/webp') 2x,
      url(${MobileBgLandscape}) 2x
   );
   background-size: cover;
   backdrop-filter: blur(10px);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   ${orientation.portrait`
      background-image: image-set(
         url(${MobilePortraitAvif}) type('image/avif') 1x,
         url(${MobilePortraitWebP}) type('image/webp') 1x,
         url(${MobilePortrait}) 1x
   
      );
   `}
`;
