import { createAutoplay } from '#/modules/Autoplay/hooks';
import { TRootState } from '#/state/types';

export const cancelAutoplayOnUpdateBets = (action, listenerApi) => {
   const { dispatch } = listenerApi;
   const state = listenerApi.getOriginalState() as TRootState;
   const { autoplay } = createAutoplay(dispatch, state.autoplay);

   autoplay.cancelAutoplayFromUI();
};
