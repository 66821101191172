// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const SettingsWrapper = styled.div`
   color: ${({ theme }) => theme.colors.white};
`;

export const Settings = styled.div`
   --settings-label-fz: 16px;
   --settings-item-gap: 40px;

   display: flex;
   flex-direction: column;
   gap: var(--settings-item-gap);

   ${orientation.mobileLandscape`
     --settings-item-gap: 20px;
   `}

   ${orientation.portrait`
      --settings-item-gap: 20px;

      padding: 0;
   `};
`;

export const SettingsItemWrapper = styled.div`
   display: flex;
   flex-direction: ${({ row }) => (row ? 'row' : 'column')};
   justify-content: space-between;
   align-items: ${({ row }) => row && 'center'};
`;

export const SettingsItemLabel = styled.div`
   ${orientation.portrait`
      width: 60%;
      padding: 0;
      align-self: start;
   `};

   ${orientation.mobileLandscape`
      width: 60%;
      margin-bottom: 0;
      align-self: start;
   `}
`;

export const SettingsItemAction = styled.div`
   ${orientation.portrait`
      width: 40%;
      padding: 0;
   `};

   ${orientation.mobileLandscape`
      width: 40%;
      margin-bottom: 0;
   `}
`;
