import { TBetType, TDoubleBet, TTotalBets } from '../../types';
import {
   isDoubleBetType,
   isRangeStraightBetType,
   isSpecialBetType,
   isCallBetType,
} from '../../type-guards';

export const undoBet = ({
   totalBets,
   betsHistory,
}: {
   totalBets: TTotalBets;
   betsHistory: (TBetType | TDoubleBet)[];
}): [TTotalBets, TBetType[]] => {
   const copyTotalBets = { ...totalBets };
   const copyBetsHistory = [...betsHistory];
   const lastBet = copyBetsHistory.pop();

   if (!lastBet) {
      return [totalBets, betsHistory];
   }

   const isDoubleBet = isDoubleBetType(lastBet);
   const isRangeStraightBet = isRangeStraightBetType(lastBet);
   const specialBet = isSpecialBetType(lastBet);
   const isSideBet = isCallBetType(lastBet);

   if (isDoubleBet) {
      Object.entries(lastBet.partialTotalBets).forEach(
         ([id, amount]) => (copyTotalBets[id] = copyTotalBets[id] - amount),
      );

      return [copyTotalBets, copyBetsHistory];
   }

   if (specialBet || isRangeStraightBet || isSideBet) {
      lastBet.extractedCommand.forEach(({ id, amount }) => (copyTotalBets[id] -= amount));

      return [copyTotalBets, copyBetsHistory];
   }

   copyTotalBets[lastBet.id] -= lastBet.amount;

   return [copyTotalBets, copyBetsHistory];
};
