// @ts-nocheck
import styled from 'styled-components/macro';

export const AutoplayRoundsCounter = styled.span`
   position: absolute;
   top: -2px;
   right: -5px;
   background: red;
   border-radius: 50%;
   width: 30px;
   height: 30px;
   z-index: 2;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 15px;
   color: white;
   pointer-events: none;
`;
