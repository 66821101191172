import { useTranslationQuery, translationQuery } from '@/common/services/translates';

import { useGameLanguageSelector } from '#/services/api/gameConfigApiSlice/state/gameConfig';

import { translations } from './locales';

export const useTranslation = () => {
   return useTranslationQuery(translations, useGameLanguageSelector);
};

export const translation = (key: string): string => {
   return translationQuery(key, translations);
};
