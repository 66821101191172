import { FC } from 'react';
import { Chat, Window, Thread, Channel, MessageInput, MessageList } from 'stream-chat-react';

import { ScrollToBottom, CloseThread } from './components';
import { useConnectChat } from './hooks';
import { ThreadInput } from './components/ThreadInput';

import 'stream-chat-react/dist/css/v2/index.css';
import './styles.css';

interface IGetStreamChatProps {
   ChatLoadingError?: () => JSX.Element;
   ChatMessageInput?: () => JSX.Element;
   ChatMessages?: () => JSX.Element;
   ReplyThread?: () => JSX.Element;
   isAdmin?: boolean;
   isDarkMode?: boolean;
   isReadMode?: boolean;
   physicalTableUUID: string;
   userId: string;
   userName: string;
   userToken: string;
}
export const GetStreamChat: FC<IGetStreamChatProps> = ({
   userId,
   userName,
   userToken,
   physicalTableUUID,
   ChatMessageInput,
   ChatLoadingError,
   isAdmin = false,
   isReadMode = false,
   isDarkMode = false,
   ChatMessages,
}) => {
   const chat = useConnectChat({
      userId,
      userName,
      userToken,
      isAdmin,
      physicalTableUUID,
   });

   if (!chat) {
      return <></>;
   }

   return (
      <Chat
         client={chat.chat}
         theme={isDarkMode ? 'str-chat__theme-dark' : 'str-chat__theme-light'}
      >
         <Channel
            ThreadHeader={() => null}
            EmptyStateIndicator={() => null}
            channel={chat.channel}
            TypingIndicator={() => null}
            LoadingErrorIndicator={ChatLoadingError}
            LoadingIndicator={() => null}
            Input={ChatMessageInput}
            Message={ChatMessages}
         >
            <Window>
               <MessageList
                  head={<ScrollToBottom />}
                  noGroupByUser={true}
                  messageActions={isAdmin ? ['delete', 'flag', 'mute'] : ['reply']}
                  hideDeletedMessages
                  disableDateSeparator
               />
               {isReadMode ? null : <MessageInput disableMentions noFiles />}
            </Window>

            <CloseThread>
               <Thread
                  autoFocus
                  enableDateSeparator={false}
                  // @ts-ignore
                  Input={ThreadInput}
                  messageActions={[]}
               />
            </CloseThread>
         </Channel>
      </Chat>
   );
};
