// @ts-nocheck
import styled from 'styled-components/macro';

import { isHoverSupports } from '@/common/ui/theme/mixins';

export const Input = styled.input`
   --range-thumb-size: 20px;
   --range-thumb-border: ${({ theme }) => `2px solid ${theme.colors?.white}`};
   --range-thumb-box-shadow: 0 0 2px 0 #555;

   --range-track-width: 100%;
   --range-track-height: 7px;

   &[type='range'] {
      -webkit-appearance: none;
      width: var(--range-track-width);
      height: var(--range-track-height);
      border-radius: 18px;
      background-color: ${({ theme }) => theme.colors.black['40']};
      background-image: linear-gradient(
         ${({ theme }) => theme.colors?.main},
         ${({ theme }) => theme.colors?.main}
      );

      background-repeat: no-repeat;
   }

   &[type='range']::-webkit-slider-thumb,
   &[type='range']::-moz-range-thumb {
      height: var(--range-thumb-size);
      width: var(--range-thumb-size);
      border: var(--range-thumb-border);
      border-radius: 50%;
   }

   &[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: var(--range-thumb-size);
      width: var(--range-thumb-size);
      border: var(--range-thumb-border);
      border-radius: 50%;
      background: ${({ theme }) => theme.colors?.main};
      cursor: pointer;
      box-shadow: var(--range-thumb-box-shadow);
      transition: box-shadow 0.3s;
      transform: translateY(calc(0px - (var(--range-thumb-size) / 2)));
      margin-top: 0;

      ${isHoverSupports`
        &:hover {
           box-shadow: ${({ theme }) => `0 0 1px 5px ${theme.colors?.main}80`};
        }
      `}

      &:active {
         box-shadow: ${({ theme }) => `0 0 1px 8px ${theme.colors?.main}80`};
      }
   }

   &[type='range']::-moz-range-thumb {
      -webkit-appearance: none;
      height: var(--range-thumb-size);
      width: var(--range-thumb-size);
      border: var(--range-thumb-border);
      border-radius: 50%;
      background: ${({ theme }) => theme.colors?.main};
      cursor: pointer;
      box-shadow: var(--range-thumb-box-shadow);
      transition:
         background 0.3s ease-in-out,
         box-shadow 0.3s;

      ${isHoverSupports`
        &:hover {
           box-shadow: ${({ theme }) => `0 0 1px 5px ${theme.colors?.main}80`};
        }
      `}

      &:active {
         box-shadow: ${({ theme }) => `0 0 1px 8px ${theme.colors?.main}80`};
      }
   }

   &[type='range']::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background-color: transparent;
      height: 7px;
      border-radius: 5px;
      cursor: pointer;
      padding-top: 20px;
      padding-bottom: 20px;
   }
`;

export const RangeSliderWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
`;
