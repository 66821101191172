import { formatCurrencyWithSign } from '@/common/utils';
import { TNullable } from '@/common/types';

import { useUserCurrencySelector } from '#/services/api/authApiSlice/authSlice';

const EMPTY_BALANCE = 0;

export const useCurrency = () => {
   const userCurrency = useUserCurrencySelector();

   return (balance: TNullable<number>): string =>
      formatCurrencyWithSign(balance ?? EMPTY_BALANCE, userCurrency);
};
