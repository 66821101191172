import React from 'react';

export const SectionNumerics = () => (
   <svg style={{ pointerEvents: 'none' }}>
      <path d="M170 340a170 170 0 1 0 0-340 170 170 0 0 0 0 340Zm0-28a142 142 0 1 0 0-284 142 142 0 0 0 0 284Z" />
      <path
         fill="#23B04E"
         d="M169.9 20.8c-1.2 0-2.2-.2-3-.8a5.8 5.8 0 0 1-2.2-2.4 9.3 9.3 0 0 1-.8-3.9 9 9 0 0 1 .7-3.9c.5-1 1.1-2 2-2.5.9-.6 1.9-.9 3-.9s2.1.2 3 .8c.9.5 1.6 1.4 2.1 2.4a9 9 0 0 1 .9 3.9c0 1.5-.2 2.8-.7 3.9-.5 1-1.2 2-2 2.5-1 .6-2 .9-3 .9Zm0-2.3c1 0 1.7-.4 2.2-1.3.6-.8.9-2 .9-3.7a6 6 0 0 0-1-3.6 2.7 2.7 0 0 0-2.4-1.2c-1 0-1.7.4-2.3 1.2-.6.9-.8 2.1-.8 3.8 0 1.6.3 2.8 1 3.6.5.9 1.3 1.3 2.3 1.2Z"
      />
      <path
         fill="red"
         d="M192.3 14c1.2.4 2.1 1 2.7 1.8.6.8.8 1.7.7 2.8 0 .8-.4 1.5-.9 2-.5.7-1.1 1.2-2 1.4-.9.3-2 .4-3.1.3-1-.2-2-.4-2.8-.8-1-.4-1.7-1-2.2-1.5l1.3-1.9c.5.5 1 1 1.7 1.3a9 9 0 0 0 2.2.6c1 .1 1.7 0 2.2-.3.6-.3 1-.8 1-1.4 0-.7-.1-1.2-.6-1.6-.4-.5-1.1-.7-2.2-.9l-1.2-.1.2-1.8 3.6-3.4-6.3-.7.3-2.2 9.4 1.2-.2 1.7-3.8 3.6Zm14.2 8.7L206 25l-10-2.2.3-1.7 6.5-4a6 6 0 0 0 1.6-1.2c.3-.4.5-.8.6-1.3.1-.6 0-1.1-.3-1.6a3 3 0 0 0-1.8-.9 4 4 0 0 0-3.5.7l-1.5-1.7c.7-.6 1.5-1 2.5-1.2 1-.2 2-.2 3.2 0a6 6 0 0 1 3.3 1.9c.7.9 1 2 .7 3.1-.1.8-.4 1.4-.9 2-.5.6-1.2 1.2-2.3 1.9l-4.4 2.7 6.5 1.3Z"
      />
      <path
         fill="#fff"
         d="m220 14.1-4 13.4-2.6-.8 3.5-11.3-2.8-.9.6-2 5.4 1.6Zm5.3 7.9c1.9.7 3.1 1.5 3.7 2.6.7 1 .8 2.2.3 3.4-.2.8-.7 1.4-1.3 2-.6.5-1.4.8-2.3.9-1 0-2-.1-3-.5A10 10 0 0 1 220 29a7.3 7.3 0 0 1-1.8-1.9l1.7-1.5 1.4 1.6c.6.4 1.3.8 2 1 .9.4 1.6.4 2.3.2.6-.1 1-.5 1.2-1.2.3-.7.2-1.3-.2-1.8s-1.3-1-2.6-1.5l-3.2-1.1 3.3-7 7.7 2.8-.7 2-5.7-2-1.4 3 1.3.4Z"
      />
      <path
         fill="red"
         d="m246 25.4-6.4 12.4-2.4-1.1 5.4-10.6-2.6-1.3 1-2 5 2.6Zm7 3.6c1.7 1 2.7 2.2 3 3.8.3 1.6 0 3.3-1.1 5.3a8.8 8.8 0 0 1-2.8 3 6 6 0 0 1-3.3 1.1c-1.2 0-2.4-.4-3.6-1l-1.6-1.2-1.1-1.4 1.8-1.3c.3.8 1 1.4 2 2a4 4 0 0 0 3.3.4c1-.3 2-1.2 2.8-2.5a4 4 0 0 1-2 .1c-.6 0-1.3-.3-2-.7-.8-.5-1.4-1-2-1.7-.4-.7-.6-1.4-.7-2.2 0-.7.2-1.5.6-2.2.5-.8 1-1.4 1.8-1.8a5.9 5.9 0 0 1 4.9.3Zm-3.2 6a3 3 0 0 0 2.1.5c.7-.2 1.2-.5 1.6-1.1.3-.6.4-1.2.1-1.9a3 3 0 0 0-1.5-1.6 3 3 0 0 0-2-.4 2 2 0 0 0-1.5 1.1c-.4.6-.5 1.3-.2 1.9a3 3 0 0 0 1.4 1.6Z"
      />
      <path
         fill="#fff"
         d="m270.3 54.5-2-1.5-2 2.5-2-1.5 2-2.6-6-4.7 1-1.4 11-3 2.2 1.8-10.4 2.8 3.6 2.8 1.8-2.2 1.9 1.5-1.7 2.2 2 1.5-1.4 1.8Z"
      />
      <path
         fill="red"
         d="m283.8 69.8-1.7 1.4-6.8-7.7 1.3-1.2 7.6.7a6 6 0 0 0 2 0 3 3 0 0 0 1.3-.7c.4-.4.7-1 .6-1.5a3 3 0 0 0-.8-1.8 4 4 0 0 0-3.3-1.5l-.2-2.3c1 0 1.9.1 2.8.5.9.5 1.7 1.1 2.5 2 1 1.1 1.5 2.3 1.6 3.4 0 1.2-.4 2.2-1.3 3-.6.5-1.2.8-2 1-.6.2-1.6.3-2.9.2l-5.1-.5 4.4 5Zm12.9-1.8-10.9 9-1.6-2 9.2-7.5-1.9-2.3 1.7-1.4 3.5 4.3Z"
      />
      <path
         fill="#fff"
         d="m301.8 95.2-1.9 1.3-5.8-8.5 1.4-1 7.5 1.6a6 6 0 0 0 2 .1c.5 0 1-.2 1.3-.4.6-.4.9-.9.9-1.4a3 3 0 0 0-.7-2 4 4 0 0 0-3-1.8l.1-2.3c1 0 1.8.3 2.6.9 1 .5 1.7 1.2 2.3 2.2a5.7 5.7 0 0 1 1.2 3.6c0 1.2-.6 2-1.7 2.8a5 5 0 0 1-2 .8c-.7.1-1.7 0-3-.2l-5-1.1 3.8 5.4Z"
      />
      <path
         fill="red"
         d="m311.3 112.7-2 1-4.6-9.2 1.6-.8 7.2 2.6a6 6 0 0 0 2 .5c.5 0 1-.1 1.3-.3.6-.3 1-.7 1-1.3a3 3 0 0 0-.3-2 4 4 0 0 0-2.8-2.2l.4-2.3c1 .2 1.8.6 2.5 1.2.8.7 1.5 1.5 2 2.5.7 1.4.9 2.6.6 3.8-.2 1.1-.9 2-2 2.5-.7.3-1.4.5-2.1.5-.8 0-1.7-.2-2.9-.6l-4.8-1.8 3 6Zm7.2 2.8c.8 1.8 1 3.3.7 4.4-.3 1.2-1 2-2.3 2.6-.8.3-1.5.5-2.3.4-.8-.1-1.6-.5-2.3-1a7 7 0 0 1-1.7-2.6 10 10 0 0 1-.8-2.9c-.1-1-.1-1.8.1-2.6l2.3.2a6 6 0 0 0-.2 2c.1.9.3 1.6.6 2.3.4.8.8 1.4 1.4 1.8.6.3 1.1.3 1.8 0 .6-.2 1-.7 1-1.4.2-.7 0-1.6-.6-2.9l-1.3-3 7.2-2.5 3.3 7.5-2 1-2.4-5.6-3 1 .5 1.3Z"
      />
      <path
         fill="#fff"
         d="m327.9 130.9-13.5 4-.7-2.6 11.4-3.3-.9-2.8 2.1-.6 1.6 5.3Zm2.7 13.2-1.7.4-13.2-2.3-.6-2.7 12.7 2.3-1.3-5.4-2.3.6-.6-2.3 4.4-1.1 2.6 10.5Z"
      />
      <path
         fill="red"
         d="M327.2 157.9c0 1.3-.4 2.3-1 3-.7.8-1.6 1.2-2.6 1.3-.8.1-1.6 0-2.3-.3-.7-.4-1.3-1-1.8-1.7-.5-.8-.8-1.8-.9-3l.1-3c.2-.9.6-1.7 1-2.4l2.1.9c-.4.5-.6 1.2-.8 2-.2.7-.2 1.5-.1 2.2 0 1 .3 1.6.7 2.1.5.5 1 .7 1.7.6.6 0 1.1-.3 1.4-.9.3-.5.4-1.3.3-2.3v-1.3l1.7-.2 4 2.8-.6-6.3 2.2-.2 1 9.5-1.8.1-4.3-3Zm-4.3 17.1-.2-2.5-3.1.2-.2-2.5 3.2-.2-.4-7.7h1.8l9.4 6.3.1 2.8-9-6 .3 4.6 2.8-.2.1 2.4-2.8.2.2 2.5-2.2.1Z"
      />
      <path
         fill="#fff"
         d="M327 191.1c0 1-.3 1.8-.7 2.5a4 4 0 0 1-4 2 4.3 4.3 0 0 1-3.7-2.9c-.3-.8-.5-1.7-.4-2.6.2-2 1-3.4 2.2-4.3 1.3-1 3-1.4 5.3-1.2 1.6.2 2.9.6 4 1.2a6 6 0 0 1 2.2 2.6 8.2 8.2 0 0 1 .1 5.7c-.1.6-.4 1.2-.7 1.6l-1.9-1.1c.5-.7.8-1.5 1-2.6a4 4 0 0 0-1-3.3c-.8-.8-2-1.3-3.5-1.4.4.4.7 1 1 1.6.1.7.2 1.4.1 2.2Zm-6.7-1c-.1.8 0 1.5.4 2 .4.6 1 .9 1.7 1 .7 0 1.3-.2 1.8-.7a3 3 0 0 0 .8-2 3 3 0 0 0-.5-2c-.4-.6-1-1-1.6-1-.7 0-1.3.2-1.8.6-.5.5-.7 1.2-.8 2.1Z"
      />
      <path
         fill="red"
         d="m314.8 214.1-2.1-.5 2.3-10 1.7.4 3.9 6.6a6 6 0 0 0 1.2 1.5c.4.4.8.6 1.3.7.6.1 1.1 0 1.6-.3.4-.4.7-1 .9-1.8a4 4 0 0 0-.7-3.5l1.8-1.5c.6.7 1 1.5 1.2 2.5.1 1 .1 2-.1 3.2-.4 1.5-1 2.6-2 3.3-.8.7-1.9 1-3 .7-.8-.2-1.5-.5-2-1a8.4 8.4 0 0 1-1.9-2.3l-2.6-4.4-1.5 6.4Zm8.2 14.4-1.7-.5-10.4-8.6.8-2.6 10 8.2 1.4-5.3-2.3-.6.7-2.4 4.4 1.3-3 10.5Z"
      />
      <path
         fill="#fff"
         d="m318.4 239.3-12.8-5.5 1-2.4 10.8 4.7 1.2-2.7 2 .8-2.2 5.1Zm-10.2 5.8a4.7 4.7 0 0 1-2.5 2c-1 .4-1.9.3-2.9-.2-.7-.3-1.3-.8-1.7-1.5-.4-.6-.6-1.4-.6-2.4 0-.9.2-1.9.8-3 .4-.9 1-1.7 1.6-2.4.7-.7 1.4-1.2 2.1-1.6l1.3 1.9c-.6.3-1.1.7-1.7 1.2-.5.6-1 1.2-1.3 1.9-.4.8-.5 1.5-.4 2.2.1.6.5 1 1 1.4a2 2 0 0 0 1.7 0c.6-.3 1-1 1.5-1.9l.6-1.1 1.6.8 2 4.5 2.8-5.8 2 1-4.2 8.6-1.6-.8-2.1-4.8Z"
      />
      <path
         fill="red"
         d="M299 259.6c-.9 1-1.8 1.6-2.8 1.7-1 .2-1.9 0-2.8-.6a5 5 0 0 1-1.5-1.7c-.3-.8-.4-1.6-.3-2.5.2-.9.6-1.8 1.2-2.8a10 10 0 0 1 2-2.2c.7-.6 1.5-1 2.3-1.3l1 2a7.2 7.2 0 0 0-3.4 2.7c-.5.7-.7 1.4-.7 2 0 .7.3 1.2.9 1.6.5.4 1 .4 1.6.2.6-.2 1.2-.7 1.8-1.6l.7-1 1.5 1 1.4 4.7 3.5-5.3 1.8 1.2-5.3 8-1.4-1-1.5-5Zm-5.3 9.4c-.5.8-1.2 1.4-1.9 1.8a4 4 0 0 1-4.4-.4 4.3 4.3 0 0 1-1.7-4.4c.1-.8.5-1.6 1-2.4a5.7 5.7 0 0 1 4.2-2.5c1.6-.2 3.3.4 5 1.8 1.3.9 2.2 2 2.8 3 .6 1.2.8 2.3.6 3.5a8.2 8.2 0 0 1-2.8 5l-1.5.8-1-2c.8-.2 1.5-.8 2.1-1.7.8-1 1-2.1.9-3.2-.2-1.1-1-2.2-2.2-3l-.1 1.8c-.2.7-.5 1.3-1 2Zm-5.2-4.3a3 3 0 0 0-.7 2c0 .7.4 1.2 1 1.6.6.5 1.2.6 1.8.4a3 3 0 0 0 1.8-1.2 3 3 0 0 0 .6-2c0-.8-.3-1.3-.9-1.7-.5-.4-1.1-.6-1.8-.4-.7.1-1.3.6-1.8 1.3Z"
      />
      <path
         fill="#fff"
         d="m285.9 285.2-10.1-9.7 1.8-1.9 8.5 8.2 2-2.1 1.6 1.5-3.8 4Zm-7.3 7-9.7-10 1.9-1.8 8.2 8.5 2.1-2 1.5 1.5-4 3.8Z"
      />
      <path
         fill="red"
         d="M262.2 297.2c-1 .7-2.1 1-3.1.7-1-.1-1.8-.7-2.4-1.5-.5-.7-.8-1.4-.9-2.2 0-.8.2-1.6.6-2.4.4-.8 1.1-1.6 2.1-2.3.8-.6 1.7-1 2.6-1.4.9-.3 1.8-.4 2.6-.4l.3 2.3c-.7 0-1.4 0-2.1.3-.7.2-1.4.6-2 1-.8.6-1.2 1.2-1.4 1.8-.2.6-.1 1.2.3 1.7.3.5.8.8 1.5.8.6 0 1.3-.3 2.1-1l1-.7 1.1 1.4-.3 5 5.1-3.8 1.3 1.7-7.7 5.7-1-1.4.3-5.3Zm-13.6-1c.9-.6 1.9-1 3-1 1 0 2 .3 3 1 1 .6 2 1.5 2.8 2.8a9 9 0 0 1 1.5 3.6c.2 1.2 0 2.3-.4 3.2-.4 1-1 1.8-2 2.4-1 .6-1.9 1-3 1-1 0-2-.3-3-1a9 9 0 0 1-2.8-2.7 9.2 9.2 0 0 1-1.6-3.7c-.1-1.2 0-2.3.4-3.2.4-1 1.1-1.8 2-2.4Zm1.2 1.9c-.8.5-1.3 1.3-1.3 2.3 0 1 .4 2.2 1.3 3.6 1 1.4 1.9 2.2 2.8 2.6 1 .4 1.8.3 2.7-.3.8-.5 1.2-1.3 1.3-2.3 0-1-.5-2.2-1.4-3.6a6.5 6.5 0 0 0-2.7-2.6c-1-.3-1.9-.3-2.7.3Z"
      />
      <path
         fill="#fff"
         d="M231.1 314.5a4 4 0 0 1-2.1-.5c-.6-.4-1-.9-1.4-1.6a3 3 0 0 1-.3-2.3c.1-.8.5-1.5 1.2-2.2.6-.7 1.4-1.3 2.5-1.7 1-.5 2-.8 3-.9.8 0 1.6.1 2.3.5.7.4 1.3 1 1.6 1.7.4.7.5 1.4.4 2a4 4 0 0 1-1 2c.7 0 1.3.2 1.7.5.5.4.9.8 1.2 1.4.3.7.4 1.4.3 2.1-.2.8-.5 1.4-1 2-.7.7-1.4 1.2-2.3 1.7-1 .4-1.9.7-2.7.7-.9 0-1.6 0-2.3-.4-.6-.4-1.1-.9-1.4-1.6a3.2 3.2 0 0 1 .3-3.4Zm5.2 2.8c.7-.3 1.2-.8 1.5-1.3.3-.5.3-1 0-1.6-.2-.5-.7-.9-1.2-1a3 3 0 0 0-2 .4 3 3 0 0 0-1.6 1.3c-.2.5-.2 1 0 1.6.3.5.7.9 1.3 1 .6 0 1.3 0 2-.4Zm-4.4-9.3c-1 .4-1.5 1-1.8 1.5a2 2 0 0 0 0 1.9c.2.6.7 1 1.4 1.1.6.1 1.4 0 2.3-.4.9-.4 1.4-1 1.8-1.5a2 2 0 0 0 0-1.9 2 2 0 0 0-1.4-1.1c-.7-.2-1.5 0-2.3.4Z"
      />
      <path
         fill="red"
         d="m209.4 317.1-.7-2 9.8-3.2.6 1.7-3.8 6.7a6 6 0 0 0-.7 1.9v1.3c.3.7.6 1 1.2 1.3a3 3 0 0 0 2-.1 4 4 0 0 0 2.7-2.4l2.1.8a5 5 0 0 1-1.6 2.3c-.7.7-1.7 1.2-2.8 1.5a5 5 0 0 1-3.8 0c-1-.4-1.8-1.2-2.1-2.3-.3-.8-.3-1.5-.2-2.2 0-.8.4-1.7 1-2.7l2.6-4.6-6.3 2Zm-6.4 7.4c-1.3.1-2.3 0-3.2-.6a3.8 3.8 0 0 1-1.7-2.3 6 6 0 0 1 0-2.3c.3-.8.8-1.4 1.5-2 .7-.6 1.6-1 2.8-1.4l2.9-.3c1 0 1.8.2 2.6.5l-.6 2.3c-.6-.3-1.3-.5-2-.5-.8 0-1.6 0-2.3.2-.9.2-1.5.6-2 1a2 2 0 0 0-.3 1.8c.1.6.5 1 1.1 1.3.6.2 1.4.2 2.3 0l1.3-.4.4 1.7-2 4.5 6-1.6.6 2-9.2 2.5-.4-1.7 2.2-4.7Z"
      />
      <path
         fill="#fff"
         d="m188.5 332.6-1.8-14 2.6-.3 1.5 11.8 3-.4.2 2.2-5.5.7Zm-10-13.6a5 5 0 0 1 3 .5c1 .5 1.7 1.3 2.3 2.3.6 1.1 1 2.4 1.1 3.9a9 9 0 0 1-.4 3.9 5.5 5.5 0 0 1-4.9 3.7 5.4 5.4 0 0 1-5.3-2.9 9 9 0 0 1-1-3.8c-.2-1.5 0-2.8.4-4a5.5 5.5 0 0 1 4.8-3.7Zm.2 2.2c-1 0-1.8.6-2.3 1.4-.5.9-.7 2.2-.5 3.8a6 6 0 0 0 1.1 3.6c.7.8 1.5 1.1 2.5 1 1 0 1.7-.5 2.2-1.4.5-.8.7-2.1.6-3.7a6.5 6.5 0 0 0-1.2-3.6 2.7 2.7 0 0 0-2.4-1Z"
      />
      <path
         fill="red"
         d="M157.1 327.5c-2-.1-3.4-.6-4.3-1.4a3.8 3.8 0 0 1-1.2-3.2c0-.8.3-1.6.7-2.2.5-.7 1.1-1.2 2-1.6a7 7 0 0 1 3-.4c1 0 2 .3 3 .6a7 7 0 0 1 2.2 1.4l-1.2 2a6 6 0 0 0-1.8-1.2c-.7-.3-1.4-.5-2.2-.5-1 0-1.7 0-2.2.4-.6.4-.9.9-.9 1.5 0 .8.2 1.3.8 1.7.5.4 1.5.7 2.8.7l3.4.2-1.2 7.6-8.2-.5.2-2.2 6 .4.5-3.2H157Z"
      />
      <path
         fill="#fff"
         d="m132.3 318 .4-2.1 10.1 2-.3 1.7-6.5 4a6 6 0 0 0-1.5 1.4 3 3 0 0 0-.6 1.2c-.2.6 0 1.2.3 1.6.4.4 1 .7 1.8.9a4 4 0 0 0 3.5-.8l1.5 1.7a6 6 0 0 1-2.5 1.3 8 8 0 0 1-3.1 0 5.7 5.7 0 0 1-3.4-1.8c-.7-.9-1-2-.8-3.1.2-.8.5-1.5 1-2 .4-.6 1.1-1.3 2.2-2l4.3-2.7-6.4-1.3Zm-12.3-4.5 2.4.6.7-3.1 2.5.6-.8 3 7.5 2-.5 1.7-8.9 7-2.6-.6 8.4-6.8-4.5-1.1-.7 2.7-2.3-.6.6-2.7-2.4-.6.6-2.1Z"
      />
      <path
         fill="red"
         d="m106.7 320.2 5.2-13 2.4 1-4.3 11 2.7 1-.8 2.1-5.2-2Zm-6.5-8.9c-.8-.4-1.5-.9-2-1.5a4 4 0 0 1-.6-4.4 4.3 4.3 0 0 1 4-2.6 6 6 0 0 1 2.5.6 6 6 0 0 1 3.4 3.4c.4 1.6.2 3.4-.7 5.4a8.7 8.7 0 0 1-2.4 3.3 6 6 0 0 1-3.3 1.3c-1.2.2-2.4 0-3.6-.6l-1.8-1c-.5-.5-1-.9-1.2-1.4l1.7-1.4c.4.7 1.1 1.3 2.1 1.8a4 4 0 0 0 3.4.1c1-.4 1.9-1.4 2.5-2.8-.6.3-1.2.4-1.9.3a5 5 0 0 1-2-.5Zm3.2-6a3 3 0 0 0-2-.2c-.7.1-1.2.6-1.5 1.2-.3.7-.3 1.3 0 2 .3.5.8 1 1.6 1.3a3 3 0 0 0 2.2.3c.6-.2 1-.6 1.4-1.3a2 2 0 0 0 0-1.8c-.3-.7-.9-1.1-1.7-1.5Z"
      />
      <path
         fill="#fff"
         d="M85.6 302.2c-1-.9-1.6-1.8-1.8-2.7-.2-1 0-2 .5-2.9.5-.6 1-1.2 1.8-1.5.7-.3 1.5-.5 2.4-.4a10 10 0 0 1 5.1 3L95 300l-2 1.2-1-1.9-1.8-1.5a4 4 0 0 0-2.1-.7c-.7 0-1.2.4-1.5 1-.4.5-.4 1-.2 1.6.2.6.8 1.2 1.6 1.7l1.1.7-1 1.5-4.6 1.5 5.4 3.4-1.2 1.8-8-5 .8-1.5 5-1.6ZM75.5 296c-1-1-1.5-1.9-1.6-2.8-.2-1 0-2 .7-2.8.4-.7 1-1.2 1.8-1.5.7-.3 1.5-.4 2.4-.2 1 .1 1.9.5 2.8 1.2a10 10 0 0 1 2.2 2c.6.8 1 1.6 1.2 2.4l-2 1c-.2-.7-.5-1.3-1-2l-1.6-1.5c-.8-.5-1.5-.8-2.1-.8-.7 0-1.2.3-1.6.9-.3.5-.4 1-.2 1.6a4 4 0 0 0 1.5 1.8l1.1.7-1 1.5-4.8 1.3 5.2 3.6-1.2 1.8-7.8-5.5 1-1.4 5-1.4Z"
      />
      <path fill="red" d="m55 287 9.4-10.4 2 1.8-8 8.7 2.1 2-1.4 1.6-4.2-3.7Z" />
      <path
         fill="#fff"
         d="m47.6 260.1 1.7-1.4 6.6 8-1.3 1-7.6-.8a6 6 0 0 0-2 0 3 3 0 0 0-1.3.6c-.5.4-.7 1-.7 1.5s.3 1.2.9 1.8a4 4 0 0 0 3.2 1.6v2.3a6.5 6.5 0 0 1-5.1-2.6 5.7 5.7 0 0 1-1.5-3.5c0-1.1.4-2.1 1.3-3l2-1h3l5 .6-4.2-5Zm-1.6-5.8a7 7 0 0 1 1.2 3c0 1-.2 2-.7 3.1-.6 1-1.5 2-2.7 3a9 9 0 0 1-3.5 1.7c-1.2.2-2.3.2-3.2-.2a5 5 0 0 1-2.5-1.8c-.7-1-1-2-1.2-3 0-1 .2-2 .7-3.1a9 9 0 0 1 2.7-3 9.2 9.2 0 0 1 3.5-1.7c1.2-.3 2.3-.2 3.2.2a5 5 0 0 1 2.5 1.8Zm-1.8 1.4a2.7 2.7 0 0 0-2.4-1.1 6 6 0 0 0-3.5 1.5 7 7 0 0 0-2.4 3c-.3 1-.1 1.8.5 2.6s1.4 1.2 2.4 1.1c1 0 2.1-.5 3.4-1.5 1.4-1 2.2-2 2.5-3 .3-1 .1-1.8-.5-2.6Z"
      />
      <path
         fill="red"
         d="m25.9 247 12.2-6.9 1.3 2.3-10.3 5.8 1.4 2.6-1.9 1L26 247Zm3.5-18 1.1 2.3 2.9-1.4 1.1 2.2-2.8 1.5 3.5 6.8-1.6.8-11.2-2-1.3-2.5 10.7 1.9-2.1-4.1-2.5 1.3-1.1-2.2 2.5-1.3-1.1-2.2 2-1Z"
      />
      <path
         fill="#fff"
         d="M20.6 217.3c-.3-1.3-.2-2.4.3-3.3a4 4 0 0 1 2.2-1.8c.8-.3 1.6-.3 2.3-.1.8.1 1.5.6 2.1 1.2.7.7 1.2 1.6 1.6 2.7.3 1 .5 2 .5 2.9 0 1 0 1.8-.3 2.6l-2.3-.4.4-2c0-.8-.2-1.6-.4-2.3-.3-.9-.7-1.5-1.3-1.9-.5-.4-1-.4-1.7-.2-.6.2-1 .6-1.2 1.2-.2.6-.1 1.3.2 2.3l.4 1.2-1.7.6-4.6-1.8 2.1 6-2 .8-3.2-9 1.7-.6 5 1.9Zm-9.3-8.3 13.4-4 .8 2.4-11.3 3.4.8 2.8-2 .6-1.7-5.3Z"
      />
      <path
         fill="red"
         d="M8 190.8c-.3-1.9 0-3.4 1.1-4.7 1-1.2 2.6-2 4.8-2.3 1.6-.3 3-.2 4.2.2a6 6 0 0 1 2.9 2c.7.9 1.1 2 1.4 3.4v2c0 .7-.1 1.2-.4 1.8l-2-.7c.3-.7.4-1.7.2-2.7a4 4 0 0 0-1.7-3 5 5 0 0 0-3.7-.5c.5.3 1 .8 1.3 1.4.3.6.6 1.3.7 2 .1 1 0 1.8-.1 2.6-.3.8-.7 1.4-1.3 2-.5.4-1.3.7-2.1.9-.9.1-1.7 0-2.5-.3-.7-.4-1.3-.9-1.8-1.6a6 6 0 0 1-1-2.5Zm6.7-1.2a3 3 0 0 0-1-2c-.5-.4-1.1-.6-1.8-.5a3 3 0 0 0-1.6 1 3 3 0 0 0-.3 2.3 3 3 0 0 0 1 1.8c.5.5 1 .6 1.8.5.7-.1 1.2-.4 1.6-1a3 3 0 0 0 .3-2.1Z"
      />
      <path
         fill="#fff"
         d="M18.3 163.4h2.2l.2 10.2H19l-5.4-5.4A6 6 0 0 0 12 167a3 3 0 0 0-1.4-.3c-.6 0-1.1.2-1.4.7-.4.4-.5 1-.5 1.9a4 4 0 0 0 1.5 3.3l-1.4 1.8a5.3 5.3 0 0 1-1.7-2.2c-.4-.9-.7-2-.7-3 0-1.6.3-2.8 1-3.7.7-1 1.7-1.4 3-1.5.7 0 1.4.2 2 .5.8.3 1.5.9 2.4 1.8l3.6 3.7v-6.6Zm.4-11.1H21l-.3 10.4H19l-5.1-5.8a6 6 0 0 0-1.5-1.3 3 3 0 0 0-1.4-.4 2 2 0 0 0-1.5.6 3 3 0 0 0-.6 2 4 4 0 0 0 1.4 3.3l-1.5 1.8a5.3 5.3 0 0 1-1.6-2.3c-.4-1-.6-2-.5-3.1 0-1.5.4-2.7 1.2-3.6.7-1 1.7-1.3 3-1.3.7 0 1.4.2 2 .5a8 8 0 0 1 2.3 2l3.4 3.8.2-6.6Z"
      />
      <path
         fill="red"
         d="m9.4 140 13.8 2.2-.4 2.6-11.7-1.9-.5 3-2.1-.4.9-5.5Zm8.6-10.5a4 4 0 0 1 1.6-1.4 3.6 3.6 0 0 1 4.1 1 4 4 0 0 1 1 2.2 8.4 8.4 0 0 1-1.2 6c-.5.7-1.1 1.2-1.8 1.6-.7.3-1.5.4-2.4.2-.7-.1-1.3-.5-1.8-1a4 4 0 0 1-1-1.9c-.4.5-.9.9-1.4 1-.6.3-1.2.3-1.8.1-.8-.1-1.4-.5-1.9-1-.5-.6-.8-1.2-1-2-.1-1 0-1.8.1-2.8.2-1 .6-2 1-2.6.5-.7 1.1-1.2 1.8-1.6.6-.3 1.3-.3 2.1-.2a3.2 3.2 0 0 1 2.5 2.4Zm-5.5 2.4c-.1.8-.1 1.4.1 2 .3.5.7.8 1.3 1 .6 0 1.1 0 1.6-.4.4-.4.7-1 .9-1.8.2-.8.1-1.5-.1-2-.3-.6-.7-1-1.3-1-.6-.2-1.1 0-1.6.4-.4.4-.7 1-.9 1.8Zm10.1 2c.2-.9.2-1.7 0-2.3a2 2 0 0 0-1.5-1.1 2 2 0 0 0-1.8.4c-.5.5-.9 1.2-1 2.2-.3.9-.2 1.7 0 2.3.3.6.8 1 1.5 1.1a2 2 0 0 0 1.8-.4c.5-.5.8-1.2 1-2.1Z"
      />
      <path
         fill="#fff"
         d="m29.2 114 2 .7-3.5 9.7-1.6-.6-3-7A6 6 0 0 0 22 115a3 3 0 0 0-1.2-.8c-.6-.2-1.1-.2-1.6.1-.5.3-.9.9-1.2 1.7a4 4 0 0 0 .3 3.5l-2 1.3c-.4-.8-.7-1.7-.8-2.7 0-1 .1-2 .5-3.1a5.7 5.7 0 0 1 2.2-3c1-.7 2.1-.8 3.3-.3.7.2 1.3.6 1.8 1.1.5.6 1 1.4 1.5 2.5l2.1 4.7 2.3-6.2ZM21 104c.7-1.9 1.8-3 3.3-3.5 1.5-.6 3.3-.4 5.4.5 1.4.6 2.6 1.4 3.4 2.3a6 6 0 0 1 1.4 3.2 7 7 0 0 1-.6 3.7l-1 1.8-1.2 1.2-1.5-1.6c.7-.5 1.3-1.2 1.7-2.2a4 4 0 0 0 0-3.4c-.4-1-1.4-1.8-2.8-2.4.2.5.4 1.1.4 1.8s-.2 1.4-.5 2.2c-.4.8-.9 1.5-1.5 2-.6.6-1.3 1-2 1-.8.2-1.6.1-2.3-.2a4.5 4.5 0 0 1-2.7-3.9c-.1-.8 0-1.7.4-2.6Zm6.3 2.3a3 3 0 0 0 .1-2.1c-.2-.7-.6-1.2-1.3-1.4a2 2 0 0 0-1.8 0 3 3 0 0 0-1.4 1.8 3 3 0 0 0-.2 2c.2.7.6 1.1 1.3 1.4.7.3 1.3.3 1.9 0a3 3 0 0 0 1.4-1.7Z"
      />
      <path
         fill="red"
         d="m33.6 79.7 1.5.8 7.8 11-1.4 2.4L34 83.3l-2.8 4.8 2 1.2-1.2 2-4-2.3 5.6-9.3Z"
      />
      <path
         fill="#fff"
         d="m56.5 70.2 1.7 1.3-6.4 8.1-1.3-1-.7-7.7a6 6 0 0 0-.4-2 3 3 0 0 0-.9-1c-.5-.5-1-.6-1.6-.5a3 3 0 0 0-1.6 1.2 4 4 0 0 0-.9 3.5l-2.2.6c-.2-1-.2-1.9 0-2.8.3-1 .8-2 1.5-2.9 1-1.1 2-1.9 3-2.1 1.2-.3 2.3 0 3.2.7.6.5 1 1 1.4 1.7.3.7.6 1.7.7 2.9l.4 5.1 4.1-5.1Zm2.3-10.8a4 4 0 0 1 2.1-.4c.7.1 1.3.4 1.9.9.6.6 1 1.2 1.2 2 .2.8.1 1.6-.2 2.5a9.2 9.2 0 0 1-4 4.6c-.8.4-1.6.5-2.4.5a4 4 0 0 1-2.2-1c-.5-.5-.9-1-1-1.7a4 4 0 0 1 0-2.2c-.6.2-1.2.3-1.7.2a3 3 0 0 1-1.6-.8c-.6-.5-1-1.2-1.2-1.9-.1-.7 0-1.4.2-2.2a8 8 0 0 1 1.5-2.4c.6-.8 1.4-1.4 2.1-1.7.8-.4 1.5-.6 2.3-.5.7 0 1.4.3 2 .8a3.2 3.2 0 0 1 1 3.3Zm-6-.6c-.5.7-.7 1.3-.8 1.8 0 .6.2 1.1.6 1.5.5.4 1 .6 1.6.5a3 3 0 0 0 1.6-1.2c.6-.6.9-1.2 1-1.8 0-.6-.2-1-.7-1.5-.4-.4-1-.5-1.5-.4-.6.2-1.2.5-1.7 1.1Zm7.8 6.9c.7-.8 1-1.5 1-2.2a2 2 0 0 0-.6-1.7 2 2 0 0 0-1.8-.4c-.7.1-1.3.6-2 1.3-.6.7-1 1.4-1 2 0 .8.2 1.3.7 1.8a2 2 0 0 0 1.8.5c.7-.2 1.3-.6 2-1.3Z"
      />
      <path
         fill="red"
         d="M64.8 45.1 74 55.7l-2 1.7-7.7-9-2.3 2-1.4-1.6 4.2-3.7Zm17.1 1.3 1.4 1.7-8 6.4-1.2-1.3 1-7.6a6 6 0 0 0 0-2 3 3 0 0 0-.5-1.3c-.4-.5-.9-.7-1.4-.7-.6 0-1.2.2-1.9.8a4 4 0 0 0-1.6 3.2h-2.3c0-1 .2-1.8.6-2.7.5-1 1.2-1.7 2-2.4 1.3-1 2.4-1.5 3.6-1.5 1.2 0 2.1.5 2.9 1.5.5.6.8 1.2 1 2v2.9l-.8 5.1 5.2-4.1Z"
      />
      <path
         fill="#fff"
         d="M90 34.5c1.2-.6 2.2-.7 3.2-.4 1 .2 1.7.8 2.3 1.8a4 4 0 0 1-.2 4.5c-.5.8-1.3 1.5-2.4 2-.8.6-1.7 1-2.7 1.2-.9.2-1.8.3-2.6.2v-2.3h2c.8-.3 1.5-.5 2.2-1 .7-.4 1.3-1 1.5-1.5a2 2 0 0 0 0-1.8c-.4-.5-.9-.8-1.5-.9a5 5 0 0 0-2.3.7l-1 .6-1-1.5.8-4.9-5.4 3.3-1.2-1.9 8.2-4.9 1 1.5-1 5.3Zm8-5.1a6.9 6.9 0 0 1 4.3-1c1.2.2 2.1 1 2.8 2 .4.8.5 1.6.5 2.4 0 .8-.4 1.5-.9 2.3a7 7 0 0 1-2.4 2c-.9.4-1.8.8-2.8 1H97v-2.3a6 6 0 0 0 2.1 0c.8-.1 1.5-.4 2.2-.7.8-.5 1.3-1 1.6-1.5a2 2 0 0 0 0-1.8c-.3-.6-.9-1-1.5-1a7 7 0 0 0-2.9.8l-3 1.6-2.9-7 7.3-3.9 1 2-5.3 2.8 1.2 3 1.2-.7Z"
      />
      <path
         fill="red"
         d="M118.5 20.7c1.2-.3 2.3-.2 3.2.2.9.4 1.5 1.2 1.9 2.2.3.7.3 1.5.2 2.3-.2.7-.6 1.5-1.3 2.1a6 6 0 0 1-2.6 1.6c-1 .4-1.9.6-2.9.6s-1.8 0-2.6-.3l.4-2.3c.6.3 1.3.4 2 .4a6 6 0 0 0 2.3-.5c.8-.3 1.5-.7 1.8-1.3.4-.5.4-1 .2-1.7-.2-.6-.6-1-1.2-1.2a5 5 0 0 0-2.3.3l-1.3.4-.6-1.6 1.7-4.7-6 2.2-.7-2 9-3.3.5 1.6-1.8 5Z"
      />
      <path
         fill="#fff"
         d="m143.7 21.2.5 2.1-10 2.2-.4-1.7 4.3-6.3a6 6 0 0 0 .9-1.8v-1.4c-.1-.6-.5-1-1-1.3a3 3 0 0 0-2-.1 4 4 0 0 0-2.9 2.1l-2-1c.3-.8 1-1.5 1.7-2.1a9 9 0 0 1 3-1.3c1.4-.3 2.7-.2 3.7.3s1.7 1.4 2 2.6c.2.7.2 1.4 0 2.2a9 9 0 0 1-1.3 2.6l-3 4.3 6.5-1.4Zm6.3-7.7c.9-.2 1.7-.1 2.5.1a4 4 0 0 1 3 3.3 4.3 4.3 0 0 1-2 4.4 6 6 0 0 1-2.4 1c-1.9.3-3.4 0-4.7-1-1.2-1-2-2.7-2.4-5-.3-1.5-.2-2.8.2-4 .3-1.2 1-2.2 2-3a8.1 8.1 0 0 1 5.4-1.6l1.7.5-.6 2c-.8-.3-1.7-.3-2.8-.1a4 4 0 0 0-2.9 1.7 5 5 0 0 0-.5 3.7c.3-.5.8-1 1.4-1.3.6-.3 1.3-.6 2-.7Zm.6 6.8a3 3 0 0 0 1.9-1c.4-.5.6-1.1.4-1.8 0-.8-.4-1.3-1-1.6a3 3 0 0 0-2.1-.3 3 3 0 0 0-2 1c-.4.5-.5 1.1-.4 1.8.1.7.4 1.2 1 1.6.6.3 1.3.4 2.2.3Z"
      />
   </svg>
);
