// @ts-nocheck
import styled, { keyframes, css } from 'styled-components';

import { getBackgroundColorByTimerTick } from '../../../../utils';

const pulseAnimation = (color: string) => keyframes`
   0% {
      box-shadow: 0 0 0 0 ${color};
      opacity: 1;
   }

   100% {
      box-shadow: 0 0 0 10px ${color};
      opacity: 0;
   }
`;

export const CircularCountDownTick = styled.div`
   position: absolute;
   top: 0;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: calc(var(--circle-timer-size) / 2);
   color: ${({ theme }) => theme.colors?.white};
   font-weight: bold;
   border-radius: 50%;

   &:before,
   &:after {
      box-sizing: border-box;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors?.black['50']};
   }

   &:before {
      z-index: -1;
      transform: scale(0.8);
   }

   &:after {
      z-index: -2;
   }

   ${({ tick, isShowPulseAnimation }) =>
      tick &&
      isShowPulseAnimation &&
      css`
         &:after {
            border: 15px solid ${getBackgroundColorByTimerTick(tick)};
            animation: ${pulseAnimation(getBackgroundColorByTimerTick(tick))} 1s ease infinite;
         }
      `}
`;
