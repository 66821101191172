// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const Progress = styled.div`
   position: absolute;
   left: 50%;
   top: ${({ isCentered }) => (isCentered ? '50%' : '35%')};
   transform: translate(-50%, -50%);
   transform-origin: 0 0;
   scale: var(--scale);
   text-align: center;

   ${orientation.mobileLandscape`
      top: 20%;
      scale: 0.8;
   `}

   ${orientation.portrait`
      top: 25%;
   `}
`;

export const ProgressMessage = styled.div`
   font-weight: 700;
   font-size: 20px;
   line-height: 23px;
   color: #fff;

   ${orientation.mobileLandscape`
      font-size: 14px;
      line-height: 14px;
   `}
`;
