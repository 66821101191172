// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '../theme/mixins';

export const AmountInfoContainer = styled.span`
  --amount-info-fz: 20px;

  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--amount-info-fz);
  width: 250px;

  ${orientation.mobileLandscape`
      --amount-info-fz: 12px;
      width: auto;
      flex-direction: column;
      align-items: flex-start;
   `}

  ${orientation.portrait`
      --amount-info-fz: 12px;
      width: 100px;
      gap: 0;
   `}
`;

export const AmountInfoInnerContainer = styled.span`
  display: flex;
  align-items: flex-start;

  ${orientation.desktopLandscape`
      flex-direction: row;
   `}

  ${orientation.mobileLandscape`
      flex-direction: column;
   `}

   ${orientation.portrait`
     flex-direction: column;
  `}
`;

export const AmountInfoTitle = styled.span`
  margin-right: 15px;
  color: ${({ theme, color }) => color ?? theme.colors.silverHue['40']};
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${orientation.portrait`
      margin-right: 0;
  `}
`;

export const AmountInfoValue = styled.span`
  color: ${({ theme, color }) => color ?? theme.colors?.white};
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
`;
