// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const ProgressBarContainer = styled.div`
   position: relative;
   overflow: hidden;
   width: 100%;
   height: 100%;
   background: rgba(36, 37, 40, 0.7);
   box-shadow:
      inset -2px -2px 4px rgba(255, 255, 255, 0.08),
      inset 2px 2px 4px rgba(0, 0, 0, 0.6);
   border-radius: 25px;

   &::before {
      content: '';
      position: absolute;
      left: ${({ fillWidth }) => `-${100 - fillWidth}%`};
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors?.main};
      border-radius: 25px;
      transition: all 0.1s linear;
   }
`;

export const ProgressBarPercentValue = styled.span`
   --progress-fz: 15px;

   position: absolute;
   top: 1px;
   right: 6px;
   bottom: 0;

   display: flex;
   align-items: center;

   color: ${({ theme }) => theme.colors?.white};
   font-size: var(--progress-fz);

   ${orientation.portrait`
      --progress-fz: 8px;
   `}

   ${orientation.mobileLandscape`
      --progress-fz: 8px;
   `}
`;
