import { ReactNode } from 'react';

import { FadeBox } from '../FadeBox';

import { TableLimitsViewComponents } from './components';
import { useShowLimitsTable } from './hooks/useShowLimitsTable';
import * as S from './TableLimitsView.styles';

export interface ITableLimitsViewProps {
  gameTitle: string;
  limitsRange: string;
  showAnimationDuration: number;
  tableData: {
    rows: Array<ReactNode[]>;
    titles: string[];
  };
}
export const TableLimitsView = ({
  showAnimationDuration,
  gameTitle,
  limitsRange,
  tableData,
}: ITableLimitsViewProps) => {
  const { isShowLimitsTable, handleHideLimitsTable, handleShowLimitsTable } = useShowLimitsTable();

  return (
    <S.TableLimitsViewWrapper>
      <TableLimitsViewComponents.GameTitle>{gameTitle}</TableLimitsViewComponents.GameTitle>
      <TableLimitsViewComponents.LimitsRange
        isShowLimitsTable={isShowLimitsTable}
        onClick={isShowLimitsTable ? handleHideLimitsTable : handleShowLimitsTable}
        handleShowLimitsTable={handleShowLimitsTable}
        handleHideLimitsTable={handleHideLimitsTable}
      >
        {limitsRange}
      </TableLimitsViewComponents.LimitsRange>
      <FadeBox
        duration={showAnimationDuration}
        isVisible={isShowLimitsTable}
        className="limit-container"
      >
        <TableLimitsViewComponents.LimitsTable
          titles={tableData.titles}
          rows={tableData.rows}
          handleHideLimitsTable={handleHideLimitsTable}
        />
      </FadeBox>
    </S.TableLimitsViewWrapper>
  );
};
