import { formatCurrency } from 'helpers';
import { useState } from 'react';

import { handleChangeInput } from '#/modules/Autoplay/helper';
import { useAutoplay } from '#/modules/Autoplay/hooks';

import { LimitInput } from '../LimitInput';

export const SingleWinLimit = ({ currencySign }) => {
   const { autoplay } = useAutoplay();
   const singleWinLimit = autoplay.getSingleWinLimit();
   const [inputValue, setInput] = useState(singleWinLimit ? formatCurrency(singleWinLimit) : '');

   const handleResetLimit = () => {
      setInput('');
      autoplay.handleSingleWinLimit(0);
   };

   return (
      <LimitInput
         label={'sidebars.autoplay.singleWinLimit'}
         currencySign={currencySign}
         placeholder={singleWinLimit ? '' : 'sidebars.autoplay.noLimit'}
         limit={inputValue !== '' ? formatCurrency(singleWinLimit) : ''}
         testId="singleWin-limit"
         handleChange={(e) =>
            handleChangeInput({
               input: e.target,
               setInput,
               handler: autoplay.handleSingleWinLimit,
            })
         }
         handleClose={handleResetLimit}
      />
   );
};
