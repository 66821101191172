import { getNestedProperty } from '../../utils';

export const useTranslationQuery = (translations, useGameLanguageSelector) => {
   const gameLanguage = useGameLanguageSelector();

   const t = (key: string): string =>
      getNestedProperty(translations[gameLanguage]?.translation, key) || key;

   return {
      t,
   };
};
