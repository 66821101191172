import { useEffect, useState } from 'react';

import { useChipTrayActions, useOpenedSliderFirstTimeSelector } from '@/common/modules/ChipTray';

export const useShowSlider = () => {
   const [showSlider, setShowSlider] = useState(false);

   const openedSliderFirstTime = useOpenedSliderFirstTimeSelector();
   const { handleSetOpenedSliderFirstTime } = useChipTrayActions();

   const handleCloseSlider = (event: Event): void => {
      // todo: add navigation module to Slider component and remove this check
      const isClickedToChipTrayControl = (event.target as Element).role === 'chiptray-control';

      if (!isClickedToChipTrayControl) {
         setShowSlider(false);
      }
   };

   const handleToggleSlider = (): void => {
      setShowSlider((prev) => !prev);
   };

   useEffect(() => {
      if (!openedSliderFirstTime) {
         setShowSlider(true);
         handleSetOpenedSliderFirstTime(true);
      }
   }, [openedSliderFirstTime]);

   return {
      showSlider,
      handleCloseSlider,
      handleToggleSlider,
   };
};
