import { useSessionClosedReason, ESessionClosedReason } from '@/common/state/gameState/gameState';

import { KickInactiveGameTablePlayer, LoginFromAnotherPlace } from './components';

export const SessionClosedOverlay = () => {
   const sessionClosedReason = useSessionClosedReason();

   if (!sessionClosedReason) {
      return null;
   }

   const sessionClosedModals = {
      [ESessionClosedReason.KickInactiveGameTablePlayer]: <KickInactiveGameTablePlayer />,
      [ESessionClosedReason.LoginFromAnotherPlace]: <LoginFromAnotherPlace />,
   };

   return sessionClosedModals[sessionClosedReason];
};
