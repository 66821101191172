import { hasOwn } from '@/common/utils';

import { THotAndColdTypeCellObject, ICellStatistics } from './types';

const isValidNumber = (value: unknown): boolean => {
   const FIRST_CELL_NUMBER = 0;
   const LAST_CELL_NUMBER = 36;

   const parsedValue = parseInt(String(value));

   const parsedValueIsNumber = !isNaN(parsedValue);
   const parsedValueInRange = parsedValue >= FIRST_CELL_NUMBER && parsedValue <= LAST_CELL_NUMBER;

   return parsedValueIsNumber && parsedValueInRange;
};

export const getCellTypesFromStatistic = (
   statisticsByPockets: ICellStatistics[] = [],
): THotAndColdTypeCellObject =>
   statisticsByPockets.reduce((colors, pocketStatistic) => {
      const hasType = hasOwn(pocketStatistic, 'type');
      const hasValidNumber = isValidNumber(pocketStatistic.number);

      if (hasType && hasValidNumber) {
         colors[pocketStatistic.number] = pocketStatistic.type;
      }

      return colors;
   }, {});
