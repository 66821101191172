import { useCallback } from 'react';

import { useTableCapacity } from '#/modules/TableCapacity/state';
import { ETypeBet } from '#/state/features/bets';

export const useHighlightChipByMinLimit = () => {
   const { betLimitsByType } = useTableCapacity();

   return useCallback(
      ({ betValue, type }: { betValue: number; type: ETypeBet }) => {
         if (!betLimitsByType[type]) {
            return false;
         }
         return betLimitsByType[type].min > betValue;
      },
      [betLimitsByType],
   );
};
