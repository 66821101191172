import { iterateObject } from 'helpers';

import { reqExpValidation, hasOwn } from '@/common/utils';

import { IChipsZIndexes } from '../../types';

class ChipZIndexes {
   public getMaxValue = (list: number[]): number => {
      const DEFAULT_ORDER = 0;

      if (list.length === 0) {
         return DEFAULT_ORDER;
      }

      return Math.max(...list);
   };

   getNumericalOrderValues = (zIndexes: IChipsZIndexes) => {
      return Object.values(zIndexes).filter(reqExpValidation.isNumber) as number[];
   };

   public updateZIndexes = ({
      zIndexes,
      updateZIndexes,
   }: {
      zIndexes: IChipsZIndexes;
      updateZIndexes: number[];
   }) => {
      const newOrders = { ...zIndexes };
      const previousOrders = { ...zIndexes };

      updateZIndexes.forEach((id) => {
         const numericalOrderValues = this.getNumericalOrderValues(newOrders);
         const MAX = this.getMaxValue(numericalOrderValues);

         newOrders[id] = MAX + 1;
      });

      newOrders.prev = previousOrders;

      return newOrders;
   };

   public getPreviousZIndexes = (zIndexes: IChipsZIndexes) => {
      return zIndexes?.prev ?? {};
   };

   public removeZIndex = (zIndexes: IChipsZIndexes, chipId: number) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [chipId]: _, ...rest } = zIndexes;

      return rest;
   };

   public mergeZIndexes = (oldOrders: IChipsZIndexes, newOrders: IChipsZIndexes) => {
      const resultOrders = { ...oldOrders };

      iterateObject(newOrders, (key) => {
         if (hasOwn(resultOrders, key)) {
            resultOrders[key] += 1;
         } else {
            const numericalOrderValues = this.getNumericalOrderValues(resultOrders);
            const MAX = this.getMaxValue(numericalOrderValues);
            resultOrders[key] = MAX + 1;
         }
      });

      resultOrders.prev = oldOrders;

      return resultOrders;
   };
}

export const chipZIndexes = new ChipZIndexes();
