export const findLast = <T>(
   array: T[],
   predicate: (value: T, index: number, array: T[]) => boolean,
): T | undefined => {
   if ('findLast' in Array.prototype && typeof Array.prototype.findLast === 'function') {
      return Array.prototype.findLast.call(array, predicate);
   }
   // Fallback
   for (let i = array.length - 1; i >= 0; i--) {
      if (predicate(array[i], i, array)) {
         return array[i];
      }
   }

   return undefined;
};
