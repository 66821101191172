import styled from 'styled-components/macro';

import { applyZIndex, orientation } from '@/common/ui/theme/mixins';

export const ModalFeedbackForm = styled.div`
   ${applyZIndex('feedback')};
   top: 8px;
   left: -30px;
   position: absolute;
`;

export const Backdrop = styled.div`
   --feedback-backdrop-bg: rgba(0, 0, 0, 0.25);
   display: none;

   ${orientation.mobileLandscape`
      display: unset;
      position: fixed;
      width: 115vw;
      height: 115vw;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--feedback-backdrop-bg);
   `}

   ${orientation.portrait`
      display: unset;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--feedback-backdrop-bg);
   `}
`;
