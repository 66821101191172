import { timerSlice } from '@/common/modules/CountDown';
import { gameSettings, timeSync } from '@/common/helpers';
import { TListenerApiDispatchType } from '@/common/types';
import { RTKQueryApi } from '@/common/services/api/types';
import { chipTraySlice, findDefaultChip } from '@/common/modules/ChipTray';
import { clientApiSlice } from '@/common/services/clientAPI/clientApiSlice';
import { rouletteGameConfigSchema } from '@/common/services/api/gameConfigAPI/schemes';
import { TRouletteGameConfig } from '@/common/services/api/gameConfigAPI/types';
import { contractValidator, gameConfigAPI, unwrapResponse } from '@/common/services/api';

import { TRootState } from '#/state/types';
import { errors } from '#/constants/errors';
import { gameConfigApiAdapter } from '#/services/api/gameConfigApiSlice/adapter';
import { createAutoplay } from '#/modules/Autoplay/hooks';
import { gameHistorySlice } from '#/modules/History';
import { tableCapacitySlice } from '#/modules/TableCapacity';
import { gameConfigSlice } from '#/services/api/gameConfigApiSlice/state';

import { getStraightBetMinValue } from './getStraightBetMinValue';

export const getGameConfig = async (
   dispatch: TListenerApiDispatchType,
   getState: () => unknown,
) => {
   const { autoplay: autoplayState, auth: authState, gameConfig } = getState() as TRootState;
   const gameConfigResponse = await dispatch(
      gameConfigAPI(clientApiSlice as RTKQueryApi).endpoints.getGameTableConfig.initiate({
         currency: authState.user.currency,
         gameTableId: gameConfig.gameConfig.gameTableId,
      }),
   );

   // @ts-ignore
   const { data, isError, isException } = unwrapResponse<TRouletteGameConfig>(gameConfigResponse);

   const isGameTableInactive = !data?.is_game_table_active;
   const isPhysicalTableUnderMaintenance = data?.is_physical_table_under_maintenance;

   if (isPhysicalTableUnderMaintenance) {
      throw new Error(errors.maintenance);
   }

   if (isGameTableInactive) {
      throw new Error(errors.inactiveGameTable);
   }

   if (isError || isException) {
      throw new Error(errors.config.request);
   }

   const { isValid } = contractValidator({
      schema: rouletteGameConfigSchema,
      data,
      path: 'game config API',
   });

   if (!isValid) {
      throw new Error(errors.config.requestValidation);
   }

   const transformGameConfigResponseByAdapter = gameConfigApiAdapter(data);

   const { autoplayRounds, betTypeLimits, chipTray, tssUrl, timer, gameType } =
      transformGameConfigResponseByAdapter;
   const { autoplay } = createAutoplay(dispatch, autoplayState);

   autoplay.handleAutoplayRounds(autoplayRounds);

   await timeSync.fetchServerTimeDelta(tssUrl);

   dispatch(gameHistorySlice.actions.setGameType(gameType));
   dispatch(gameHistorySlice.actions.setGameTypesList([gameType]));

   dispatch(tableCapacitySlice.actions.setBetLimits(betTypeLimits));
   dispatch(
      gameConfigSlice.actions.setGameConfig({
         ...transformGameConfigResponseByAdapter,
         gameTableId: gameSettings.gameTableId,
         launchToken: gameSettings.launchToken,
      }),
   );
   dispatch(timerSlice.actions.updateDuration(timer.betting_time));
   dispatch(timerSlice.actions.setTimerType(timer.type));

   const defaultChip = findDefaultChip(chipTray);
   const minLimitStraightBet = getStraightBetMinValue({
      chips: chipTray,
      betTypeLimits,
   });
   const defaultSelectedChip = defaultChip ?? minLimitStraightBet;

   if (defaultSelectedChip) {
      dispatch(chipTraySlice.actions.selectChip(defaultSelectedChip));
   }
};
