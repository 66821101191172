import { logger } from '../logger';

export const safeJsonParse = (jsonString: string) => {
   try {
      return JSON.parse(jsonString);
   } catch (error) {
      logger.error(`Error parsing JSON: ${error}`);
      return null;
   }
};
