import React from 'react';

import { TNullable } from '@/common/types';

import { useAutoplay } from '#/modules/Autoplay/hooks';

import * as S from './AutoplayRoundCounter.styles';

export const AutoplayRoundCounter = (): TNullable<JSX.Element> => {
   const { autoplay } = useAutoplay();

   const rounds = autoplay.getRound();
   const isAutoplayStarted = autoplay.isAutoplayStarted();
   const ifLastAutoplayRound = 0;

   if (!isAutoplayStarted || rounds === ifLastAutoplayRound) {
      return null;
   }

   return (
      <S.AutoplayRoundsCounter data-testid={`autoplay-round-${rounds}`}>
         {rounds}
      </S.AutoplayRoundsCounter>
   );
};
