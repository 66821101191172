import React from 'react';

import * as S from './LandscapeGradient.styles';

export const LandscapeGradient = () => (
   <>
      <S.RightLine />
      <S.LeftLine />
   </>
);
