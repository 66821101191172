import { FC } from 'react';

import { IIHorizontalCountDownProps } from '../../HorizontalCountDown';

import * as S from './HorizontalCountDownTick.styles';

export const HorizontalCountDownTick: FC<Pick<IIHorizontalCountDownProps, 'tick'>> = ({ tick }) => (
   <S.HorizontalCountDownTick data-testid="horizontal-timer-value" tick={tick}>
      {tick}
   </S.HorizontalCountDownTick>
);
