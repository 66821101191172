import { generateCornerGrid, generateCornerGridPortrait } from './corner';
import { generateLineGrid, generateLineGridPortrait } from './line';
import { generateSplitGrid, generateSplitGridPortrait } from './split';
import { generateStreetGrid, generateStreetGridPortrait } from './street';

export const generateGridLandscape = () =>
   [generateCornerGrid, generateStreetGrid, generateLineGrid, generateSplitGrid].map(
      (generateConfig) => generateConfig(),
   );

export const generateGridPortrait = () =>
   [
      generateCornerGridPortrait,
      generateStreetGridPortrait,
      generateLineGridPortrait,
      generateSplitGridPortrait,
   ].map((generateConfig) => generateConfig());
