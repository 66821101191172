import { ETrackingEvents } from './constants';

class EventsToTrackController {
   private static instance;
   private readonly eventsToTrack;

   private constructor() {
      this.eventsToTrack = {
         [ETrackingEvents.ROUND_START]: false,
         [ETrackingEvents.BETTING_END]: false,
         [ETrackingEvents.WIN_NUMBER]: false,
      };
   }

   public static getInstance(): EventsToTrackController {
      if (!EventsToTrackController.instance) {
         EventsToTrackController.instance = new EventsToTrackController();
      }
      return EventsToTrackController.instance;
   }

   public get() {
      return this.eventsToTrack;
   }

   public reset() {
      Object.keys(this.eventsToTrack).forEach((event) => {
         this.eventsToTrack[event] = false;
      });
   }
}

export const eventsToTrackController = EventsToTrackController.getInstance();
