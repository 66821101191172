import { TNullable } from '@/common/types';

import { RACETRACK_CELL_CLASSNAME } from '../../constants';

export const getNodeCellByCellId = ({
   container,
   cellId,
}: {
   container: HTMLElement | SVGSVGElement;
   cellId: string;
}): TNullable<HTMLElement | SVGSVGElement> => {
   return container.querySelector(`.${RACETRACK_CELL_CLASSNAME}_${cellId}`);
};
