import { ZodError, ZodSchema } from 'zod';

import { logger } from '@/common/utils';

const isZodError = (error: Error) => error instanceof ZodError;

export const contractValidator = <Z extends ZodSchema>({
   schema,
   data,
   path,
   handleError,
}: {
   schema: Z;
   data: unknown;
   path?: number | string;
   handleError?: (error: ZodError) => void;
}) => {
   try {
      schema.parse(data, { path: path ? [path] : [] });
      return { isValid: true };
   } catch (error) {
      if (isZodError(error as Error)) {
         const parsedError = JSON.parse(error as string);
         if (handleError) {
            handleError(parsedError);
         } else {
            logger.errorValidation(parsedError);
         }
      }
   }

   return { isValid: false };
};
