import { ITrackComponentState } from '#/state/features/bets';
import { useBetsState } from '#/state/features/bets/selectors';

import { useRaceTrackActions, useRaceTrackSelector } from '../../RaceTrack/state';
import { useTrackState, useTrackTypeSelector } from '../../state';

export const useTrackComponentState = (): ITrackComponentState => {
   const betsState = useBetsState();
   const raceTrackActions = useRaceTrackActions();
   const { handleToggleTrackType } = useTrackState();

   return {
      ...betsState,
      ...raceTrackActions,
      useRaceTrackSelector,
      useTrackTypeSelector,
      handleToggleTrackType,
   };
};
