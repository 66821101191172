import { useState } from 'react';

export const usePowerSavingMode = () => {
   const [isCheckedPowerSavingMode, setIsCheckedPowerSavingMode] = useState(false);

   const handleSetCheckedPowerSavingMode = () => setIsCheckedPowerSavingMode(true);

   return {
      isCheckedPowerSavingMode,
      handleSetCheckedPowerSavingMode,
   };
};
