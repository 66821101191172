import { logger } from '@/common/utils';

import { PocketType } from '../../types';

export const addClassByPocketType = ({ node, type }: { node: Element; type: PocketType }): void => {
   const classNames = {
      cold: 'cold',
      hot: 'hot',
   };

   try {
      node.classList.add(classNames?.[type] ?? '');
   } catch (error) {
      logger.log(`addClassByPocketType ${error}`);
   }
};
