import { useEffect, useRef, useState } from 'react';

import { TNullable } from '@/common/types';
import { theme } from '@/common/ui/theme';

import { ETypeBet } from '#/state/features/bets';

import { IDragChip } from './types';

export const useDragChip = (
   chipId: string,
): {
   showDragChip: (event: DragEvent) => void;
   hideDragChip: () => void;
   moveDragChip: (event: DragEvent) => void;
   highlightChip: () => void;
   removeHighlightChip: () => void;
   movingChip: IDragChip;
} => {
   const [movingChip, setMovingChip] = useState<IDragChip>({
      chipValue: 0,
      betType: null,
      chipId,
   });
   const cellChipRef = useRef<TNullable<HTMLElement>>(null);
   const dragChipRef = useRef<TNullable<HTMLElement>>(null);
   const dragChip = dragChipRef?.current;
   const cellChip = cellChipRef?.current;

   useEffect(() => {
      const dragChip = document.getElementById(chipId);

      if (dragChip) {
         dragChipRef.current = dragChip;
      }
   }, []);

   const showDragChip = (event: DragEvent) => {
      const eventTarget = event.target as HTMLElement;
      const isChipPlace = eventTarget.dataset.chipPlace;
      const target = isChipPlace ? (eventTarget.nextElementSibling as HTMLElement) : eventTarget;
      const { amount, type = '' } = eventTarget.dataset;

      setMovingChip({
         betType: type as ETypeBet,
         chipValue: Number(amount),
         chipId,
      });
      if (dragChip && target) {
         const targetWidth = target?.getBoundingClientRect()?.width;

         const dragChipStyles = {
            top: `-${targetWidth}px`,
            left: `-${targetWidth}px`,
            width: `${targetWidth}px`,
            height: `${targetWidth}px`,
         };

         Object.entries(dragChipStyles).forEach(([cssPropName, cssPropValue]) => {
            dragChip.style.setProperty(cssPropName, cssPropValue);
         });

         cellChipRef.current = target;
         cellChipRef.current.style.setProperty('opacity', '0');
      }
   };

   const hideDragChip = () => {
      if (cellChip) {
         cellChip.style.removeProperty('opacity');
      }

      setMovingChip({
         betType: null,
         chipValue: 0,
         chipId,
      });
   };

   const moveDragChip = (event: DragEvent) => {
      const setTranslateValuesForChip = (chipNode: HTMLElement, { x, y }) => {
         if (!chipNode) {
            return;
         }

         const chipSize = parseFloat(getComputedStyle(chipNode).width);

         const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

         const calibratedX = () => {
            const value = x + chipSize / 2;

            if (value > windowWidth) {
               return windowWidth;
            }

            if (value < chipSize) {
               return chipSize;
            }

            return value;
         };

         const calibratedY = () => {
            const value = y + chipSize / 2;

            if (value > windowHeight) {
               return windowHeight;
            }

            if (value < chipSize) {
               return chipSize;
            }

            return value;
         };

         chipNode.style.setProperty(
            'transform',
            `translate(${calibratedX()}px, ${calibratedY()}px)`,
         );
      };

      if (dragChip) {
         setTranslateValuesForChip(dragChip, {
            x: event.clientX,
            y: event.clientY,
         });
      }
   };

   const highlightChip = () => {
      if (dragChip) {
         dragChip.style.setProperty('border-radius', '50px');
         dragChip.style.setProperty(
            'box-shadow',
            `0.5px 0.5px calc(var(--scale, 0.5) * 10px) calc(var(--scale, 0.5) * 10px) ${theme.colors?.redHue['10']}`,
         );
      }
   };

   const removeHighlightChip = () => {
      if (dragChip) {
         dragChip.style.removeProperty('box-shadow');
         dragChip.style.removeProperty('border-radius');
      }
   };

   return {
      movingChip,
      showDragChip,
      hideDragChip,
      moveDragChip,
      highlightChip,
      removeHighlightChip,
   };
};
