import { roulettePocketsList } from 'core/roulette';
import { MutableRefObject, RefObject, useEffect, useRef } from 'react';

import { useSelectStatisticsByRange } from '#/modules/Statistics/components/InnerStatistics';

import { getNodeCellByCellId } from '../../helpers';

import { PocketType } from './types';
import { addClassByPocketType, getPocketTypes, removeClassByPocketType } from './helpers';

export const useHotAndCold = ({
   raceTrackContainerRef,
   isDisabled,
}: {
   raceTrackContainerRef: RefObject<SVGSVGElement>;
   isDisabled?: boolean;
}): void => {
   const { statistics } = useSelectStatisticsByRange();
   const hotAndColdPocketsList: MutableRefObject<Record<string, PocketType>> = useRef({});

   const handleRemoveClassByPocketType = () => {
      if (!raceTrackContainerRef.current) {
         return;
      }

      for (const [cellId] of roulettePocketsList.entries()) {
         const node = getNodeCellByCellId({
            container: raceTrackContainerRef.current,
            cellId: String(cellId),
         });

         if (node) {
            removeClassByPocketType({ node });
         }
      }
   };

   const handleAddClassByPocketType = () => {
      if (!raceTrackContainerRef.current) {
         return;
      }

      if (Object.keys(hotAndColdPocketsList.current).length) {
         for (const [cellId, type] of Object.entries(hotAndColdPocketsList.current)) {
            const node = getNodeCellByCellId({
               container: raceTrackContainerRef.current,
               cellId,
            });

            node && addClassByPocketType({ node, type });
         }
      }
   };

   useEffect(() => {
      if (!statistics) {
         return;
      }

      handleRemoveClassByPocketType();
      const statisticsByPockets = statistics.statisticsByPockets;
      if (statisticsByPockets && !isDisabled) {
         hotAndColdPocketsList.current = getPocketTypes(statisticsByPockets);
         handleAddClassByPocketType();
      }
   }, [statistics, isDisabled]);
};
