import { ETimerType } from '@/common/modules/CountDown';
import { DesktopBettingPanelView, MobileBettingPanelView } from '@/common/ui';
import { isMobileDevice } from '@/common/utils/checkDevice';

import { SwitchCountDown } from '#/modules/SwitchCountDown';
import { UndoControl } from '#/modules/Control/components/UndoControl';
import { ChipTrayWrapper, MobileChipTrayWrapper } from '#/modules/ChipTray';

import { DoubleControlSwitcher } from './components/DoubleControlSwitcher/DoubleControlSwitcher';

export const BettingPanelGroup = () => {
   const isMobile = isMobileDevice();

   if (isMobile) {
      return (
         <MobileBettingPanelView
            LeftControl={<UndoControl />}
            RightControl={<DoubleControlSwitcher />}
            ChipTray={<MobileChipTrayWrapper showChipTray chipSize={50} />}
         />
      );
   }

   return (
      <DesktopBettingPanelView
         ChipTray={<ChipTrayWrapper showChipTray />}
         Timer={<SwitchCountDown timerType={ETimerType.HORIZONTAL} />}
         LeftControl={<UndoControl />}
         RightControl={<DoubleControlSwitcher />}
      />
   );
};
