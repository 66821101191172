import { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { Box } from '@/common/ui';

interface IFlex {
   children?: ReactNode;
}
export const Flex = styled(Box)<IFlex>`
   display: flex;
   gap: ${({ gap }) => gap};
   width: ${({ width }) => width};
   height: ${({ height }) => height};
`;
