// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex, orientation } from '@/common/ui/theme/mixins';

export const Wrapper = styled.div`
   box-shadow:
      0 4px 8px rgba(0, 0, 0, 0.3),
      0 12px 24px rgba(0, 0, 0, 0.2);
   border-radius: 4px;
   background: rgba(3, 3, 3, 0.8);
   color: ${({ theme }) => theme.colors?.white};
   padding: 20px 50px 20px 15px;
   display: flex;
   gap: 12px;
   border-left: ${({ borderColor }) => `8px solid ${borderColor}`};
   margin: 10px;
   position: relative;

   ${orientation.portrait`
      font-size: 12px;
      padding: 10px 30px 10px 5px;
      max-width: calc(100% - 20px);
   `}

   ${orientation.mobileLandscape`
      font-size: 14px;
      padding: 16px 50px 10px 5px;
   `}
`;

export const NotificationContentWrapper = styled.div`
   display: flex;
   gap: 12px;
   flex-direction: column;
`;

export const NotificationsContainer = styled.div`
   ${applyZIndex('biggest')}

   position: absolute;
   right: 65px;
   top: 80px;

   ${orientation.portrait`
   	  left: 0;
        right: 0px;
        top: 40px;
   `}

   ${orientation.mobileLandscape`
   	  top: 15px;
        right: -35px;
   `}
`;

export const CloseIconWrapper = styled.div`
   position: absolute;
   right: 15px;
   cursor: pointer;
   top: 15px;

   ${orientation.mobileLandscape`
   	top: 10px;
   `}
`;

export const Title = styled.div``;

export const Content = styled.div`
   ${orientation.portrait`
      word-break: break-word;
   `}
`;

export const Action = styled.div`
   color: #31c440;
   cursor: pointer;
`;
