// @ts-nocheck
import styled from 'styled-components/macro';

import { isHoverSupports, orientation } from '@/common/ui/theme/mixins';

export const ResultNumber = styled.div`
   color: ${({ color }) => color};
   font-size: 20px;
   font-weight: 500;
   border: 1px solid rgba(220, 220, 220, 0.6);
   width: 48px;
   height: 48px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;

   ${orientation.mobileLandscape`
     font-size: 16px;
     width: 32px;
     height: 32px;
   `}

   ${isHoverSupports`
     &:hover {
        outline: ${({ theme }) => theme.colors?.main};
        background-color: rgba(210, 210, 210, 0.5);
     }
   `}

   &.highlight {
      ${isHoverSupports`
         outline: ${({ theme }) => theme.colors?.main};
         background-color: rgba(210, 210, 210, 0.5);
      `}
   }
`;

export const ResultNumberCancelRound = styled(ResultNumber)`
   position: relative;
   pointer-events: none;

   &:before,
   &:after {
      content: '';
      position: absolute;
      width: 40%;
      height: 1.5px;
      top: calc(50% - (1.5px / 2));
      left: calc(50% - (40% / 2));
      border-radius: 5px;
      background-color: ${({ color }) => color};
   }

   &:before {
      rotate: 45deg;
   }

   &:after {
      rotate: -45deg;
   }
`;

export const LastResultsWrapper = styled.div`
   position: relative;
   display: grid;
   grid-template-columns: repeat(8, 1fr);
   grid-gap: 10px;
   width: 100%;
   margin-bottom: 10px;

   ${orientation.portrait`
      grid-gap: 4px;
      grid-template-columns: repeat(6, 1fr);
      min-width: unset;

      ${ResultNumber} {
         width: 100%;
         height: 45px;
      }
   `}

   ${orientation.mobileLandscape`
      grid-template-columns: repeat(14, 1fr);
      gap: 7px;
   `}
`;
