import { TCommonBet, TCallBet } from '#/state/features/bets/types';
import { isCallBetType } from '#/state/features/bets/type-guards';

export const extractCallBet = (bets: (TCallBet | TCommonBet)[]) => {
   const extractedBets: TCommonBet[] = [];

   for (const bet of bets) {
      const isCallBet = isCallBetType(bet);

      if (isCallBet) {
         extractedBets.push(...bet.extractedCommand);
      } else {
         extractedBets.push(bet);
      }
   }

   return extractedBets;
};
