import { FC } from 'react';

import { useTimerDurationSelector, useTimerEndTimeSelector } from '../../state';

import { CircularCountDown } from './CircularCountDown';

export interface ICircularCountDownContainerProps {
   isLastBetsState: boolean;
   tick: number;
}

export const CircularCountDownContainer: FC<ICircularCountDownContainerProps> = ({
   tick,
   isLastBetsState,
}) => {
   const duration = useTimerDurationSelector();
   const endTime = useTimerEndTimeSelector();

   return (
      <CircularCountDown
         tick={tick}
         duration={duration}
         endTime={endTime}
         isLastBetsState={isLastBetsState}
      />
   );
};
