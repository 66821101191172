export const uuid = (): string => {
   if ('randomUUID' in crypto) {
      return crypto.randomUUID();
   }
   const uuidTemplate = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

   return uuidTemplate.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
   });
};
