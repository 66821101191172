import { FC } from 'react';

import { useTrackCollapse } from '../../hooks';
import { ITrackProps } from '../../types';
import { Track } from '../Track';

import * as S from './CollapsedTrack.styles';

export const CollapsedTrack: FC<Omit<ITrackProps, 'trackContainerId'>> = ({
   isDisabled,
   ...otherProps
}) => (
   <S.CollapsedTrackWrapper isDisabled={isDisabled} isCollapsedTrack={useTrackCollapse()}>
      <Track isDisabled={isDisabled} {...otherProps} />
   </S.CollapsedTrackWrapper>
);
