import { isMobileDevice } from '@/common/utils/checkDevice';

import { useUserCurrencySign } from '#/services/api/authApiSlice/authSlice';

import { WinnerListContainer, HorizontalWinnerList, VerticalWinnerList } from './components';
import { usePlayersListVisibility } from './hooks';
import { useWinnerList } from './state';

// TODO: rename to winner list
export const PlayersList = () => {
   const winnerList = useWinnerList();
   const { showWinnerList, animationTriggers } = usePlayersListVisibility();

   const WinnerListComponent = isMobileDevice() ? HorizontalWinnerList : VerticalWinnerList;

   return (
      <WinnerListContainer isShowWinnerList={winnerList && showWinnerList}>
         <WinnerListComponent
            animationTriggers={animationTriggers}
            currencySign={useUserCurrencySign()}
            winnerList={winnerList}
         />
      </WinnerListContainer>
   );
};
