import { useDelayCallback as useAutoHideNotification } from 'hooks';
import { FC } from 'react';

import { ENotificationIcons } from '../constants';
import { useNotifications } from '../state';

import { NotificationTemplate } from './NotificationTemplate';

interface INotification {
   action: string;
   content: string;
   handleCloseNotification: () => void;
   title: string;
   type: ENotificationIcons;
}
export const Notification: FC<INotification> = (props) => {
   const { handleRemoveNotification } = useNotifications();

   const NOTIFICATION_DURATION = 3000;
   useAutoHideNotification({
      callback: handleRemoveNotification,
      delay: NOTIFICATION_DURATION,
   });

   return <NotificationTemplate {...props} handleCloseNotification={handleRemoveNotification} />;
};
