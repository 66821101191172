import { formatCurrencyWithSign } from '@/common/utils';

import { useUserCurrencySelector } from '#/services/api/authApiSlice/authSlice';
import { useTranslation } from '#/translates';

import { BetConfirmedNotificationView } from '../BetConfirmedNotificationView/BetConfirmedNotificationView';
import { useBetConfirmed } from '../../state';

export const SendAllBetsNotification = () => {
   const { t } = useTranslation();
   const userCurrency = useUserCurrencySelector();
   const { totalAmountBets } = useBetConfirmed();
   const formattedTotalAmount = totalAmountBets
      ? formatCurrencyWithSign(totalAmountBets, userCurrency)
      : null;

   return (
      <BetConfirmedNotificationView
         title={t('notifications.betConfirmed.success.sendAllBets')}
         value={formattedTotalAmount}
      />
   );
};
