import { ElementRef, forwardRef } from 'react';

import { ITimerProps } from '../../../../types';

import * as S from './CircularCountDownProgressBar.styles';

export const CircularCountDownProgressBar = forwardRef<
   ElementRef<'div'>,
   Pick<ITimerProps, 'tick'>
>(({ tick }, ref) => {
   return (
      <S.CircularCountDownProgressBarContainer viewBox="0 0 100 100">
         <S.CircularCountDownProgressBar
            tick={tick}
            cx="50"
            cy="50"
            r="45"
            ref={ref}
            strokeColor="green"
         />
      </S.CircularCountDownProgressBarContainer>
   );
});
