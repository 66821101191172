import { NextRoundNotification } from '#/modules';
import { BetConfirmedNotification } from '#/modules/BetConfirmedNotification/BetConfirmedNotification';
import { TrackComponent } from '#/modules/Track/TrackComponent';

import { WinResult } from '../../../WinResult/WinResult';

import * as S from './CenterColumn.styles';

export const CenterColumn = () => {
   return (
      <S.CenterColumnContainer id="betting-container">
         <WinResult />
         <S.BetConfirmed>
            <BetConfirmedNotification />
         </S.BetConfirmed>
         <TrackComponent />
         <S.NextRoundNotification>
            <NextRoundNotification />
         </S.NextRoundNotification>
      </S.CenterColumnContainer>
   );
};
