// @ts-nocheck
import styled from 'styled-components/macro';

export const SliderWrapper = styled.div`
   display: flex;
   margin-top: 20px;
`;

export const Icon = styled.div`
   display: flex;
   width: auto;
   height: 100%;
   margin-right: 15px;
   border: 1px solid transparent;
   cursor: pointer;
`;

export const Volume = styled.span`
   min-width: 40px;
   margin-left: 30px;
   text-align: right;
   font-size: 16px;
`;
