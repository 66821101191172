import { ETimerType } from '@/common/modules/CountDown';
import { FadeBox } from '@/common/ui';

import { SwitchCountDown } from '#/modules/SwitchCountDown';
import { ChatControl } from '#/modules/Chat/components/ChatControl';
import { FavouriteControl, MenuControl } from '#/modules/Control/components';
import { FADE_DURATION } from '#/constants';
import { useCheckBettingTime } from '#/modules/ChipTray/hooks';

import * as S from './LeftColumn.styles';

const Controls = () => (
   <S.LeftControlsContainer>
      <ChatControl />
      <FavouriteControl />
      <MenuControl />
   </S.LeftControlsContainer>
);

export const LeftColumn = () => {
   const isBettingTime = useCheckBettingTime();

   return (
      <S.LeftColumnContainer>
         <FadeBox duration={FADE_DURATION} isVisible={isBettingTime}>
            <SwitchCountDown timerType={ETimerType.HORIZONTAL} />
         </FadeBox>
         <Controls />
      </S.LeftColumnContainer>
   );
};
