import { useEffect, useLayoutEffect, useRef } from 'react';

const useLatest = (value) => {
   const valueRef = useRef(value);

   useEffect(() => {
      valueRef.current = value;
   }, [value]);

   return valueRef;
};

export const useOnClickOutside = ({ ref, cb, attached }): void => {
   const latestHandler = useLatest(cb);

   const handleClick = (event: MouseEvent) => {
      if (!ref.current) return;

      const whenClickedToSidebar = ref.current.contains(event.target);
      const whenClickedToControl = (event.target as HTMLElement)?.dataset?.testid?.includes(
         'control',
      );

      if (!whenClickedToSidebar && !whenClickedToControl) {
         latestHandler.current(event);
      }
   };

   useLayoutEffect(() => {
      if (!attached) return;

      document.addEventListener('click', handleClick, { capture: true });

      return (): void => {
         document.removeEventListener('click', handleClick, { capture: true });
      };
   }, [ref, latestHandler, attached]);
};
