import { FC, ReactNode } from 'react';
import Draggable from 'react-draggable';

import { getVariableCSSValue } from '@/common/modules/CountDown/components/DraggableTimer';

import { chatPosition } from './helpers';

interface IDraggableChatProps {
   children: ReactNode;
}
export const DraggableChat: FC<IDraggableChatProps> = ({ children }) => (
   // @ts-ignore
   <Draggable
      bounds=".video-container"
      handle=".message-container"
      onStop={(_, event) => {
         chatPosition.saveChatPosition({ x: event.x, y: event.y });
      }}
      scale={Number(getVariableCSSValue('--scale'))}
      defaultPosition={chatPosition.getChatPosition()}
   >
      {children}
   </Draggable>
);
