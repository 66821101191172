import { z } from 'zod';

export const roundStartSchema = z.object({
   end_time: z.union([z.number(), z.null()]),
   round_number: z.string(),
   start_time: z.string(),
   uuid: z.string().uuid(),
});

export type StartRoundEvent = z.infer<typeof roundStartSchema>;
