import { FC } from 'react';
import { ErrorBoundary } from '@sentry/react';

import { useSelectedChipSelector, useChipTrayActions, ChipTray } from '@/common/modules/ChipTray';
import { FadeBox } from '@/common/ui/FadeBox';

import { FADE_DURATION } from '#/constants';
import { useGameConfigStore } from '#/services/api/gameConfigApiSlice/state';

import { IChipConfig } from '../../types';

export const ChipTrayWrapper: FC<IChipConfig> = ({
   direction,
   chipSize,
   slidesPerView,
   spaceBetween,
   showChipTray,
}) => {
   const {
      gameConfig: { chipTray },
   } = useGameConfigStore();
   const { handleSelectChip } = useChipTrayActions();
   const selectedChip = useSelectedChipSelector();
   return (
      <ErrorBoundary>
         <FadeBox duration={FADE_DURATION} isVisible={showChipTray}>
            <ChipTray
               direction={direction}
               chips={chipTray}
               selectedChip={selectedChip}
               handleSelectChip={handleSelectChip}
               chipSize={chipSize}
               slidesPerView={slidesPerView}
               spaceBetween={spaceBetween}
            />
         </FadeBox>
      </ErrorBoundary>
   );
};
