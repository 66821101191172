// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex } from '@/common/ui/theme/mixins';

export const LowPowerModeOverlay = styled.div`
   ${applyZIndex('layoutOverlay')};

   position: fixed;
   left: 0;
   top: 0;
   width: 100vw;
   height: 100vh;
   background-color: none;
   scale: var(--scale);
`;
