import '@/common/theme/scrollbar.css';
import { setupSentry } from '@/common/tracing/setupSentry';

import { Layout } from '#/layout';
import { GlobalStyles } from '#/theme';
import '#/translates';

export const App = () => {
   setupSentry({
      dns: import.meta.env.VITE_SENTRY_ROULETTE_DNS,
   });

   return (
      <>
         <GlobalStyles />
         <Layout />
      </>
   );
};
