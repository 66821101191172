import { useDispatch } from 'react-redux';

import { EGameState, useGameStateActions } from '@/common/state/gameState';
import { useTimerState } from '@/common/modules/CountDown';

import { betsCreator } from '#/core/betsCreator';
import { sendBetsAction } from '#/services/api/betsApiSlice/middleware';

import { BettingEndEvent } from './schemes';

type UseRoundResultOutput = (socket: BettingEndEvent) => void;

// todo: we always have  old state (maybe better in future move events to store)
export const useBettingEndTimeEvent = (): UseRoundResultOutput => {
   const { handleChangeGameState } = useGameStateActions();

   const { handleSetEndTime } = useTimerState();
   const dispatch = useDispatch();

   return () => {
      handleChangeGameState(EGameState.NO_MORE_BETS);
      if (betsCreator?.hasPlacedBets?.()) {
         dispatch(sendBetsAction());
      }
      handleSetEndTime(null);
   };
};
