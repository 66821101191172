import { PowerSavingModeDetector } from '@/common/modules/PowerSavingModeDetector/PowerSavingModeDetector';
import { isMobileDevice } from '@/common/utils/checkDevice';

import { AmbientVideo, RouletteLoadingScreen, VideoLoadingScreen, LiveStream } from '#/modules';
import { LimitTooltip } from '#/modules/LimitTooltip/components/LimitTooltip/LimitTooltip';
import { LayoutOverlay } from '#/modules/LayoutOverlay/LayoutOverlay';
import { useSmallVideoMode } from '#/modules/LiveStream/state';
import { ConnectionLoadingScreen } from '#/modules/ConnectionLoadingScreen/ConnectionLoadingScreen';

import {
   useContextMenu,
   useInitSocketConnection,
   useInitUserConnection,
   useCheckOfflineGame,
   useToggleMuteOnMobile,
} from './hooks';
import { Grid } from './Grid';
import { MainContainer } from './containers/MainContainer';
import { Gradient } from './components/Gradient';

export const Layout = () => {
   useInitUserConnection();
   useInitSocketConnection();
   useCheckOfflineGame();
   useContextMenu();

   const { isSmallVideo } = useSmallVideoMode();

   useToggleMuteOnMobile();

   return (
      <LayoutOverlay>
         {!isSmallVideo && <VideoLoadingScreen />}
         <ConnectionLoadingScreen />
         <AmbientVideo />
         <RouletteLoadingScreen />
         <div className={`main-container ${isMobileDevice() && 'overflow-visible'}`}>
            <MainContainer>
               <PowerSavingModeDetector>
                  <LiveStream />
               </PowerSavingModeDetector>
               <Gradient />
               <Grid />
               <LimitTooltip />
            </MainContainer>
         </div>
      </LayoutOverlay>
   );
};
