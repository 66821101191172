import { FC, memo } from 'react';

import * as S from './HorizontalCountDownMessage.styles';

interface IHorizontalCountDownMessageProps {
   message: string;
}

export const HorizontalCountDownMessage: FC<IHorizontalCountDownMessageProps> = memo(
   ({ message }) => (
      <S.HorizontalCountDownMessage data-testid="horizontal-timer">
         {message}
      </S.HorizontalCountDownMessage>
   ),
);

HorizontalCountDownMessage.displayName = 'TimerTitle';
