import { useDispatch } from 'react-redux';

import { Store } from '#/state/types';

import { IBalanceState, balanceSlice } from '..';

const createActions = (dispatch: Store['dispatch']) => {
   const {
      updateBalanceFromUI,
      updateBalanceFromServer,
      updateInitialBalance,
      setSetShowInsufficientBalanceNotification,
      updateBalanceAutoplayFromUI,
   } = balanceSlice.actions;

   const handleUpdateBalanceAutoplayFromUI = (balance: IBalanceState['balance']): void => {
      dispatch(updateBalanceAutoplayFromUI(balance));
   };

   const handleUpdateBalanceFromUI = (balance: IBalanceState['balance']): void => {
      dispatch(updateBalanceFromUI(balance));
   };

   const handleUpdateBalanceFromServer = (balance: IBalanceState['balance']): void => {
      dispatch(updateBalanceFromServer(balance));
   };

   const handleUpdateInitialBalance = (balance: IBalanceState['initialBalance']): void => {
      dispatch(updateInitialBalance(balance));
   };

   const handleSetInsufficientBalanceNotification = (value: boolean): void => {
      dispatch(setSetShowInsufficientBalanceNotification(value));
   };

   return {
      handleUpdateBalanceFromUI,
      handleUpdateInitialBalance,
      handleSetInsufficientBalanceNotification,
      handleUpdateBalanceFromServer,
      handleUpdateBalanceAutoplayFromUI,
   };
};

export const balanceActions = (store: Store) => {
   return createActions(store.dispatch);
};
export const useBalanceActions = () => {
   return createActions(useDispatch());
};
