import { useState } from 'react';

export const usePowerSavingModal = () => {
   const [isOpenModal, setIsOpenModal] = useState(false);

   const handleOpenModal = () => setIsOpenModal(true);
   const handleCloseModal = () => setIsOpenModal(false);

   return {
      isOpenModal,
      handleOpenModal,
      handleCloseModal,
   };
};
