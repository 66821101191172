import { MouseEvent, ReactNode, forwardRef, memo } from 'react';
import { useGameStateSelector } from 'services/api/gameConfigApiSlice/state';

import { isPortraitMode } from '@/common/hooks';

import { useBetConstructorOrientationContext } from '#/modules/Favourite/components/BetConstructor/context';

import { BettingMapRefType } from '../../hooks/useBettingMapRef/types';

import * as S from './BettingMapContainer.styles';

interface IBettingMapContainer {
   children: ReactNode;
   className?: string;
   dataTestId?: string;
   isCollapsedActivePlayingArea: boolean;
   isDisabled: boolean;
   onClick?: (event: MouseEvent) => void;
}

const _BettingMapContainer = forwardRef<BettingMapRefType, IBettingMapContainer>(
   (
      {
         onClick,
         children,
         className,
         isDisabled,
         dataTestId,
         isCollapsedActivePlayingArea,
         ...otherProps
      },
      ref,
   ) => {
      const betConstructorOrientation = useBetConstructorOrientationContext();
      const isPortrait = isPortraitMode(betConstructorOrientation);
      const { gameConfig } = useGameStateSelector() ?? {};
      const { colors } = gameConfig ?? {};

      return (
         <S.BettingMapContainer
            colorsConfiguration={colors}
            ref={ref}
            onClick={onClick}
            className={className}
            isCollapsedActivePlayingArea={isCollapsedActivePlayingArea}
            isDisabled={isDisabled}
            isPortrait={isPortrait}
            data-testid={dataTestId}
            {...otherProps}
         >
            {children}
         </S.BettingMapContainer>
      );
   },
);

export const BettingMapContainer = memo(_BettingMapContainer);
