import { WinSum, WinNumbers } from '#/modules';

import * as S from './WinResult.styles';

export const WinResult = () => {
   return (
      <S.WinResult>
         <S.WinResultChild>
            <WinNumbers />
         </S.WinResultChild>
         <S.WinResultChild>
            <WinSum />
         </S.WinResultChild>
      </S.WinResult>
   );
};
