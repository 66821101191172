import { Box } from '@/common/ui';
import { autoKeys } from '@/common/utils';
import { IPlayTapSound } from '@/common/entities';

import { useAutoplay } from '#/modules/Autoplay/hooks';
import { translation } from '#/translates';
import { withTapSound } from '#/hocs/withTapSound';

import * as S from './Round.styles';

const AutoplayRound = ({ playTapSound }: IPlayTapSound) => {
   const { autoplay } = useAutoplay();
   const autoplayRounds = autoplay.getAutoplayRounds();
   const selectedRound = autoplay.getSelectedRound();

   const handleAutoPlayRoundClick = (round: number) => (): void => {
      playTapSound();
      autoplay.handleSelectedRound({ selectedRound: round, lossLimit: autoplay.getBets() * round });
   };

   return (
      <div>
         <Box mb="15px">
            <S.RoundControlLabel>
               {translation('sidebars.autoplay.autoplayRounds')}
            </S.RoundControlLabel>
         </Box>
         <S.RoundControlsWrapper data-testid="autoplay-rounds">
            {autoKeys(
               autoplayRounds.map((round: number) => (
                  <S.Round
                     data-testid={`autoplay-round-${round}`}
                     data-selected={selectedRound === round}
                     isSelected={selectedRound === round}
                     onClick={handleAutoPlayRoundClick(round)}
                  >
                     {round}
                  </S.Round>
               )),
            )}
         </S.RoundControlsWrapper>
      </div>
   );
};

export const AutoplayRoundWithTapSound = withTapSound(AutoplayRound);
