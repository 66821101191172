// @ts-nocheck
import styled from 'styled-components';

export const LeftColumnContainer = styled.div`
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-end;
   padding-bottom: 5px;
`;

export const LeftControlsContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 20px;
`;
