class RenderCounter {
   // eslint-disable-next-line no-use-before-define
   private static instance: RenderCounter | null = null;
   private renderCount: number = 0;

   static getInstance(): RenderCounter {
      if (RenderCounter.instance === null) {
         RenderCounter.instance = new RenderCounter();
      }
      return RenderCounter.instance;
   }

   incrementRenderCount(): void {
      this.renderCount++;
   }

   getRenderCount(): number {
      return this.renderCount;
   }
}

export const renderCount = RenderCounter.getInstance();
