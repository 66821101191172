import { BetConfig, IConfigWithTypeBet } from 'core/config/BetConfig';

import { rangeNumbers } from '@/common/utils';

import { ETypeBet } from '#/state/features/bets';

class ColumnBetsConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.COLUMN;
   protected readonly config = [
      {
         id: 46,
         value: '2 to 1',
         numbers: rangeNumbers({ start: 1, end: 34, step: 3 }),
      },
      {
         id: 47,
         value: '2 to 1',
         numbers: rangeNumbers({ start: 2, end: 35, step: 3 }),
      },
      {
         id: 48,
         value: '2 to 1',
         numbers: rangeNumbers({ start: 3, end: 36, step: 3 }),
      },
   ];

   readonly mapColumnNumberToBet: Record<string, IConfigWithTypeBet> = this.config.reduce(
      (config, bet) => {
         const columnFirstNumber = bet.numbers[0];
         config[columnFirstNumber] = { ...bet, type: this.type };
         return config;
      },
      {},
   );
}

export const columnConfig = new ColumnBetsConfig();
