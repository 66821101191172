import { FC } from 'react';

import { isMobileDevice } from '@/common/utils/checkDevice';

import { colors } from '../../../../constants';
import { ISectionProps } from '../../Section';

import * as S from './HoverPlace.styles';

type IHoverPlaceProps = Pick<ISectionProps, 'number' | 'path'>;

const enum EHoverScale {
   Inside = 1.2,
   Outside = 1.5,
}

export const HoverPlace: FC<IHoverPlaceProps> = ({ path, number }) => {
   return (
      <>
         <S.HoverPlace
            d={path}
            scale={EHoverScale.Inside}
            fill={colors.hover}
            opacity={0}
            data-highlight-cells={[number]}
         />
         {isMobileDevice() && <S.HoverPlace d={path} scale={EHoverScale.Outside} opacity={0} />}
      </>
   );
};
