import { FC, ReactNode } from 'react';

import { useSmallVideoMode } from '#/modules/LiveStream/state';

import * as S from './LiveStreamSmallMode.styles';

export const LiveStreamSmallMode: FC<{ children: ReactNode }> = ({ children }) => {
   const { isSmallVideo } = useSmallVideoMode();

   return (
      <S.LiveStreamSmallMode isSmallLiveStreamMode={isSmallVideo}>{children}</S.LiveStreamSmallMode>
   );
};
