import { TBetType, TTotalBets } from '../../types';

interface IParams {
   betId: number;
   betsHistory: TBetType[];
   totalBets: TTotalBets;
}

export const removeBet = ({ totalBets, betsHistory, betId }: IParams): [TTotalBets, TBetType[]] => {
   // eslint-disable-next-line
   const { [betId]: _, ...totalBetsWithoutRemovedId } = totalBets;
   const betsHistoryWithoutRemovedCommand = betsHistory.filter((command) => {
      return 'id' in command && command.id !== betId;
   });

   return [totalBetsWithoutRemovedId, betsHistoryWithoutRemovedCommand];
};
