import { mapConfigCommonBets, mapConfigSpecialBets } from 'core';
import { FC, useCallback, useRef } from 'react';

import { useEvent } from '@/common/hooks';
import { IPlayChipSound } from '@/common/entities';

import { betsCreatorByPlaceTrack } from '#/core/betsCreator';
import { LimitTooltipWrapper } from '#/modules/LimitTooltip';
import { ITrackProps } from '#/modules/Track';
import { BettingMapContainer, useWinningNumber } from '#/modules/Track/BettingMap/components';
import { useDragAndDrop, useHotAndCold, useHoverBet } from '#/modules/Track/BettingMap/hooks';
import { TCommonBet } from '#/state/features/bets';
import { withChipSound } from '#/hocs';

import { useBettingMapRef } from './hooks/useBettingMapRef/index';
import { BETTING_MAP_CLASSNAME } from './constants';
import { DragChip } from './components/DragChip';
import { BetGrid } from './components/BetGrid';

const BettingMap: FC<ITrackProps & IPlayChipSound> = ({
   className,
   trackStore,
   onClick = null,
   isDisabled = false,
   isCollapsedActivePlayingArea = false,
   playChipSound,
   trackContainerId,
}) => {
   const { bettingMapRef, bettingMapRefCallback } = useBettingMapRef();

   const showMaxBetTooltipRef = useRef(null);

   const { useBetByCellIdSelector, useZIndex, placeTrack } = trackStore();
   const betsCreator = betsCreatorByPlaceTrack(placeTrack);
   const getBetsAmountByCellId = useCallback(useBetByCellIdSelector, [useBetByCellIdSelector]);
   const getZIndex = useCallback(useZIndex, [useZIndex]);

   const makeCommonBet = useEvent((command: TCommonBet): void => {
      betsCreator.makeCommonBet(command);
      playChipSound();
   });
   const { movingChip } = useDragAndDrop({
      trackStore,
      trackContainerId,
      wrapperRef: bettingMapRef,
      handleShowMaxBetTooltip: showMaxBetTooltipRef.current,
   });

   useWinningNumber(bettingMapRef);
   const mouseEvents = useHoverBet(bettingMapRef);
   const hotAndColdTypeCellObject = useHotAndCold();

   return (
      <BettingMapContainer
         ref={bettingMapRefCallback}
         isDisabled={isDisabled}
         isCollapsedActivePlayingArea={isCollapsedActivePlayingArea}
         dataTestId={trackContainerId}
         className={[BETTING_MAP_CLASSNAME, className].join(' ')}
         {...(onClick && { onClick })}
         {...mouseEvents}
      >
         <LimitTooltipWrapper>
            {({ handleShowMaxBetTooltip }) => {
               showMaxBetTooltipRef.current = handleShowMaxBetTooltip;

               return (
                  <>
                     <BetGrid
                        withTitle
                        getBetsAmountByCellId={getBetsAmountByCellId}
                        getZIndex={getZIndex}
                        gridConfig={mapConfigCommonBets}
                        makeCommonBet={makeCommonBet}
                        hotAndColdTypeCellObject={hotAndColdTypeCellObject}
                        onClick={handleShowMaxBetTooltip}
                     />

                     <BetGrid
                        getBetsAmountByCellId={getBetsAmountByCellId}
                        getZIndex={getZIndex}
                        gridConfig={mapConfigSpecialBets}
                        makeCommonBet={makeCommonBet}
                        hotAndColdTypeCellObject={hotAndColdTypeCellObject}
                        onClick={handleShowMaxBetTooltip}
                     />

                     <DragChip {...movingChip} />
                  </>
               );
            }}
         </LimitTooltipWrapper>
      </BettingMapContainer>
   );
};

export const BettingMapWithChipSound = withChipSound(BettingMap);
