import { useEffect, useRef } from 'react';

import { calculateAnimationProgress } from '../../../utils';
import { ITimerProps } from '../../../types';

const _createAnimationKeyframes = ({
   strokeDasharray,
   strokeDashoffset,
}: {
   strokeDasharray: number;
   strokeDashoffset: number;
}) => {
   return [
      { strokeDasharray, strokeDashoffset: strokeDasharray - strokeDashoffset },
      { strokeDasharray, strokeDashoffset: strokeDasharray },
   ];
};

export const useCircularCountDownTimerAnimation = ({ tick, duration, endTime }: ITimerProps) => {
   const timerProgressBarRef = useRef<HTMLDivElement>(null);

   useEffect(() => {
      if (!timerProgressBarRef.current || !endTime || !duration) {
         return;
      }

      const currentTimerTickInMs = tick * 1000;
      const timerProgressRange = calculateAnimationProgress({
         currentTimerTickInSeconds: tick,
         duration,
      });
      const TIMER_RADIUS = 45;
      const strokeDasharray = Math.ceil(2 * Math.PI * TIMER_RADIUS);
      const strokeDashoffset = (Math.ceil(2 * Math.PI * 45) * timerProgressRange) / 100;
      const isShowAnimation = currentTimerTickInMs >= 0;

      if (isShowAnimation) {
         timerProgressBarRef.current?.animate(
            _createAnimationKeyframes({ strokeDasharray, strokeDashoffset }),
            {
               duration: currentTimerTickInMs,
               iterations: 1,
               easing: 'linear',
               fill: 'both',
            },
         );
      }
   }, [endTime, duration]);

   return {
      timerProgressBarRef,
   };
};
