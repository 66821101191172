import { FC, ReactNode } from 'react';

import { EDevice } from '../constants';

import { useDeviceDetector } from './hooks/useDeviceDetector';

import { DesktopContainer, MobileContainer } from '.';

export const MainContainer: FC<{ children: ReactNode }> = ({ children }) => {
   const detectedDevice = useDeviceDetector();

   const containers = {
      [EDevice.Mobile]: MobileContainer,
      [EDevice.Desktop]: DesktopContainer,
   };

   const Component = containers[detectedDevice ?? EDevice.Desktop];

   return <Component>{children}</Component>;
};
