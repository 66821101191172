import { Tooltip, ChatControlView } from '@/common/ui';

import { useTranslation } from '#/translates';
import { withTapSound } from '#/hocs/withTapSound';

export const ChatControl = () => {
   const { t } = useTranslation();

   return (
      <Tooltip position="right" text={t('chat.title')}>
         <Chat />
      </Tooltip>
   );
};

const Chat = withTapSound(ChatControlView);
