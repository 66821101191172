import { Director, View } from '@millicast/sdk';

import { TNullable } from '@/common/types';
import { logger } from '@/common/utils';
import { EStreamingQualities } from '@/common/modules/LiveStreamPlayer/constants';

import { QUALITY_LEVELS, QUALITY_SIZE } from './constants';

type TLayerInfo = {
   bitrate: number;
   encodingId: string;
   height: number;
   simulcastIdx: number;
   spatialLayerId: number;
   temporalLayerId: number;
   totalBitrate: number;
   width: number;
};

export class DolbyController {
   private streamName: string | undefined;
   private view: TNullable<View> | undefined;
   private streamAccountId: string | undefined;
   private videoElement: HTMLVideoElement | undefined;
   private audioElement: HTMLAudioElement | undefined;
   private pinnedSourceId: string | undefined;
   public qualityLevels: Record<EStreamingQualities, string> = QUALITY_LEVELS;
   public qualitySizes: Record<number, EStreamingQualities> = QUALITY_SIZE;

   initializeConnection({
      streamName,
      audioElement,
      streamAccountId,
      videoElement,
   }: {
      streamName: string;
      audioElement: HTMLAudioElement;
      streamAccountId: string;
      videoElement: HTMLVideoElement;
   }): void {
      this.streamName = streamName;
      this.videoElement = videoElement;
      this.streamAccountId = streamAccountId;
      this.audioElement = audioElement;
   }

   private _tokenGenerator = () => {
      if (this.streamName && this.streamAccountId) {
         return Director.getSubscriber({
            streamName: this.streamName,
            streamAccountId: this.streamAccountId,
         });
      }
   };

   public subscribeToLiveStreamQualityEvents = (onQualityChange: () => void): void => {
      this.view?.on('broadcastEvent', (event) => {
         const pinnedSourceId = event.data?.sourceId;
         const isPinnedSourceChanged = this.pinnedSourceId !== pinnedSourceId;
         if (isPinnedSourceChanged && pinnedSourceId) {
            this.pinnedSourceId = pinnedSourceId;

            this.view?.reconnect();
            this.connectToVideo();
            this.connectToAudio();
         }

         const isLayersEvent = event.name === 'layers';
         if (isLayersEvent && event.data.medias[0].layers.length > 0) {
            // Workaround to move from hardcoded config to dynamically generated quality levels
            // As Dolby can provide IDs in different format that can't be hardcoded
            // We map through data that Dolby provides and sort them by sizes
            const qualityLevels = event.data.medias[0].layers
               .map((layer: TLayerInfo) => {
                  const pixelCount = layer.width * layer.height;
                  return {
                     encodingId: layer.encodingId,
                     width: layer.width,
                     pixelCount,
                  };
               })
               .toSorted((level1, level2) => level1.pixelCount - level2.pixelCount);

            // Dolby possibly can return number of layers that is different from 3
            // So we define quality levels as start, median and end of quality levels array
            // For example from 5 layers array we should take 1, 3 and 5th element
            const startingQuality = qualityLevels[0];
            const medianQuality = qualityLevels[Math.ceil((qualityLevels.length - 1) / 2)];
            const endingQuality = qualityLevels[qualityLevels.length - 1];
            this.qualityLevels = {
               [EStreamingQualities.Low]: startingQuality.encodingId,
               [EStreamingQualities.Medium]: medianQuality.encodingId,
               [EStreamingQualities.High]: endingQuality.encodingId,
            };
            this.qualitySizes = {
               [startingQuality.width]: EStreamingQualities.Low,
               [medianQuality.width]: EStreamingQualities.Medium,
               [endingQuality.width]: EStreamingQualities.High,
            };
            onQualityChange();
         }
      });
   };

   public connectToAudio = () => {
      this.view?.on('track', (event) => {
         if (event.track.kind === 'audio') {
            // @ts-ignore
            this.audioElement.srcObject = new MediaStream([event.track]);
            this.audioElement?.play().catch(logger.error);
         }
      });
   };

   public setVolume = (volume: number) => {
      if (this.audioElement) {
         this.audioElement.volume = volume;
      }
   };

   public connectToVideo = (): void => {
      // Contract: streamName: string, tokenGenerator: TokenGeneratorCallback, mediaElement?: HTMLVideoElement, autoReconnect?: boolean
      // @ts-ignore
      this.view = new View(this.streamName, this._tokenGenerator, this.videoElement, true);

      this.view
         ?.connect({
            pinnedSourceId: this.pinnedSourceId,
            events: ['active', 'inactive', 'stopped', 'layers'],
         })
         .catch(() => {
            logger.log('Stream connection error!');

            // try to reconnect on error
            this.view?.reconnect();
         });
   };

   public useAutoLiveStreamReconnect = () => {
      this.view?.on('broadcastEvent', (event) => {
         // When the live stream receives events indicating a need to restore the connection, we must manually reconnect it.
         const isRestoreConnectionEvent = event.name === 'active' && event.data.tracks.length >= 2;
         if (isRestoreConnectionEvent) {
            this.view?.reconnect();
         }
      });
   };

   public setQuality = (quality: EStreamingQualities): void => {
      if (this.view) {
         // @ts-ignore
         this.view?.select({ encodingId: this.qualityLevels[quality] });
      }
   };

   public setAutoQuality = (): void => {
      if (this.view) {
         // select method runs auto quality mode
         this.view?.select();
      }
   };

   public pause = (): void => {
      this.videoElement?.pause();
      this.audioElement?.pause();
   };

   public play = (): void => {
      this.videoElement?.play();
      this.audioElement?.play();
   };

   public reconnect = (): void => {
      this.view?.replaceConnection();
   };

   public destroyConnection = (): void => {
      this.view?.stop();
   };
}

export const dolbyController = new DolbyController();
