import { FC, ReactNode } from 'react';

import * as S from './CircularCountDownContainer.styles';

interface ICircularCountDownContainerProps {
   children: ReactNode;
}
export const CircularCountDownContainer: FC<ICircularCountDownContainerProps> = ({ children }) => (
   <S.CircularCountDownContainer data-testid="circular-timer">
      {children}
   </S.CircularCountDownContainer>
);
