import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

interface IWinSumState {
   winSum: number;
}

const initialState: IWinSumState = {
   winSum: 0,
};

export const winSumSlice = createSlice({
   name: 'winSum',
   initialState,
   reducers: {
      setWin: (state, action: PayloadAction<IWinSumState['winSum']>): void => {
         state.winSum = action.payload;
      },
   },
   selectors: {
      getWinSum: (state) => state.winSum,
   },
});

const useWinSumActions = () => {
   const dispatch = useDispatch();
   const { setWin } = winSumSlice.actions;

   const handleSetWin = (win: IWinSumState['winSum']): void => {
      dispatch(setWin(win));
   };

   const handleResetWin = (): void => {
      dispatch(setWin(0));
   };

   return { handleSetWin, handleResetWin };
};

const useWinSumSelector = () => useSelector(winSumSlice.selectors.getWinSum);

export const useWin = () => {
   const winSum = useWinSumSelector();
   const { handleSetWin, handleResetWin } = useWinSumActions();

   return { winSum, handleSetWin, handleResetWin };
};
