// @ts-nocheck
import styled from 'styled-components/macro';

import { isMobileDevice } from '@/common/utils/checkDevice';

export const LoadingTitle = styled.div`
   width: 100%;
   max-width: 620px;
   box-sizing: border-box;
   ${isMobileDevice() && 'max-width: 335px;'};
`;
