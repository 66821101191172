// @ts-nocheck
import styled, { css, keyframes } from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

const backgroundAnimation = keyframes`
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const circleScale = keyframes`
  0% {
    transform: scale(4, 4);
  }

  3.333333% {
    transform: scale(4, 4);
  }

  16.666667% {
    transform: scale(1, 1);
  }

  33.333333% {
    transform: scale(4, 4);
  }

  56.666667% {
    transform: scale(2, 2);
  }

  86.666667% {
    transform: scale(4.076923, 4.076923);
  }

  100% {
    transform: scale(1, 1);
  }
`;

const lineFadeAnimation = keyframes`
  0% {
    opacity: 0;
  }

  3.333333% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const bottomLineCircleAnimation = keyframes`
  0% {
    transform: translate(419.807157px, 148.268989px);
  }

  3.333333% {
    transform: translate(419.807157px, 148.268989px);
  }

  10% {
    transform: translate(390.4px, 147.499988px);
  }

  56.666667% {
    transform: translate(138.942276px, 148.269989px);
  }

  100% {
    transform: translate(59.807157px, 148.269322px);
  }
`;

const topLineCircleAnimation = keyframes`
  0% {
    transform: translate(85.230887px, 51.769489px);
  }

  3.333333% {
    transform: translate(85.230887px, 51.769489px);
  }

  10% {
    transform: translate(116.965765px, 52.499987px);
  }

  56.666667% {
    transform: translate(336.522769px, 51.269449px);
  }

  100% {
    transform: translate(420.192843px, 51.269451px);
  }
`;

const titleTextAnimation = keyframes`
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const descriptionTextAnimation = keyframes`
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const WinSumViewWrapper = styled.svg`
  width: 100%;
  height: 100%;
  overflow: visible;
  will-change: transform;

  stop {
    &.lighter-color {
      stop-color: ${({ theme }) => `${theme.colors?.main}00`};
    }
    &.darker-color {
      stop-color: ${({ theme }) => theme.colors?.main};
    }
  }

  #title-text {
    font-size: 30px;
    font-weight: 700;
    transform: translate(165px, 90px);

    ${orientation.portrait`
         font-size: 20px;
         transform: translate(190px, 90px);
      `}

    ${orientation.mobileLandscape`
         font-size: 20px;
         transform: translate(190px, 90px);
      `}
  }

  #description-text {
    font-size: 47px;
    font-weight: 600;
    transform: translate(175px, 130px);

    ${orientation.portrait`
         font-size: 36px;
         transform: translate(180px, 125px);
      `}

    ${orientation.mobileLandscape`
        font-size: 36px;
        transform: translate(180px, 125px)
      `}
  }

  ${({ animationDuration }) =>
    animationDuration &&
    css`
      --dark-color: ${({ theme }) => theme.colors?.main};
      /* add alpha channel as 0 */
      --light-color: ${({ theme }) => `${theme.colors?.main}00`};

      #background {
        animation: ${backgroundAnimation} ${animationDuration}ms linear 1 normal forwards;
      }

      #top-line,
      #bottom-line,
      #top-line-circle-inner,
      #bottom-line-circle-inner {
        animation: ${lineFadeAnimation} ${animationDuration}ms linear 1 normal forwards;
      }

      #bottom-line-circle-scale,
      #top-line-circle-scale {
        animation: ${circleScale} ${animationDuration}ms linear 1 normal forwards;
      }

      #top-line-circle {
        animation: ${topLineCircleAnimation} ${animationDuration}ms linear 1 normal forwards;
      }

      #bottom-line-circle {
        animation: ${bottomLineCircleAnimation} ${animationDuration}ms linear 1 normal forwards;
      }

      #title-text {
        animation: ${titleTextAnimation} ${animationDuration}ms linear 1 normal forwards;
      }

      #description-text {
        animation: ${descriptionTextAnimation} ${animationDuration}ms linear 1 normal forwards;
      }
    `}
`;
