import { FC } from 'react';

import { CloseModal } from '@/common/ui/Modal/components/CloseModal';

import * as S from './CloseChat.styles';

interface ICloseChatProps {
   onCloseChat: () => void;
}
export const CloseChat: FC<ICloseChatProps> = ({ onCloseChat }) => (
   <S.CloseChat data-testid="chat-close-button">
      <CloseModal onClose={onCloseChat} />
   </S.CloseChat>
);
