export class SoundVolumeAndRangeController {
   public currentSoundVolume: number;
   public previousSoundVolume: number;
   public currentVolumeRange: number;
   public previousVolumeRange: number;
   public defaultSoundVolumeValue: number;

   constructor({ defaultSoundVolumeValue }: { defaultSoundVolumeValue: number }) {
      const DEFAULT_VOLUME = 0;

      this.currentSoundVolume = DEFAULT_VOLUME;
      this.previousSoundVolume = DEFAULT_VOLUME;
      this.currentVolumeRange = DEFAULT_VOLUME;
      this.previousVolumeRange = DEFAULT_VOLUME;
      this.defaultSoundVolumeValue = defaultSoundVolumeValue;
      this.setCurrentValues = this.setCurrentValues.bind(this);
   }

   get currentVolume(): number {
      return this.currentSoundVolume;
   }

   set currentVolume(currentVolume: number) {
      this.currentSoundVolume = currentVolume;
   }

   get previousVolume(): number {
      return this.previousSoundVolume;
   }

   set previousVolume(currentVolume: number) {
      this.previousSoundVolume = currentVolume;
   }

   // separation from value and range value due to percentage dependence on the general sound
   get currentRange(): number {
      return this.currentVolumeRange;
   }

   set currentRange(inputRange: number) {
      this.currentVolumeRange = inputRange;
   }

   get previousRange(): number {
      return this.previousVolumeRange;
   }

   set previousRange(inputRange: number) {
      this.previousVolumeRange = inputRange;
   }

   setPreviousValues({ soundValue, range }) {
      this.previousVolume = soundValue;
      this.previousVolumeRange = range;
   }

   setCurrentValues({ soundValue, range }) {
      this.currentVolume = soundValue;
      this.currentVolumeRange = range;
   }
}
