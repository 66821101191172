import { deepCopy } from '@/common/utils';

import { rebetSlice } from '#/modules/Rebet/state';
import { TRootState } from '#/state/types';

export const handleSavePreviousRoundBets = (_, listenerApi): void => {
   const { savePreviousRoundBets } = rebetSlice.actions;

   const { dispatch } = listenerApi;
   const state = listenerApi.getOriginalState() as TRootState;
   const { totalBets, betsHistory, zIndexes } = state.bets;

   const whenSavePreviousRoundBets: boolean = betsHistory.length > 0;

   if (whenSavePreviousRoundBets) {
      dispatch(
         savePreviousRoundBets({
            betsHistory: deepCopy(betsHistory),
            zIndexes: { ...zIndexes },
            totalBets: { ...totalBets },
         }),
      );
   }
};
