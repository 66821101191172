import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TRootState } from '#/state/types';

export enum EVideoSize {
   Full = 'full',
   Small = 'small',
}

export interface IVideoSizeState {
   videoSize: EVideoSize;
}

const initialState: IVideoSizeState = {
   videoSize: EVideoSize.Full,
};

export const videoSizeSlice = createSlice({
   name: 'videoSize',
   initialState,
   reducers: {
      setVideoSize: (state, action: PayloadAction<EVideoSize>): void => {
         state.videoSize = action.payload;
      },
   },
});

export const useVideoSizeActions = () => {
   const dispatch = useDispatch();
   const { setVideoSize } = videoSizeSlice.actions;

   const handleToggleVideoSize = (size: EVideoSize): void => {
      dispatch(setVideoSize(size));
   };

   return {
      handleToggleVideoSize,
   };
};

export const useVideoSizeSelector = (): IVideoSizeState =>
   useSelector((state: TRootState) => state.videoSize);

export const useSmallVideoMode = () => {
   const { videoSize } = useVideoSizeSelector() ?? {};
   const { handleToggleVideoSize } = useVideoSizeActions();

   const isSmallVideo: boolean = videoSize === EVideoSize.Small;

   return {
      videoSize,
      isSmallVideo,
      handleToggleVideoSize,
   };
};
