// @ts-nocheck
import styled from 'styled-components/macro';

export const Border = styled.div`
  position: absolute;
  background-color: ${({ color }) => color};
  border-radius: 4px 4px 0 0;
  height: 11px;
  left: 0;
  top: 0;
  width: 100%;
`;
