import { autoKeys } from '@/common/utils';

import { useHoverSpecialBet } from '#/modules/Favourite/components/SpecialBets/hooks/useHoverSpecialBet';
import { useSelectStatisticsByRange } from '#/modules/Statistics/components/InnerStatistics/hooks/useSelectStatisticsByRange';
import {
   Section,
   SectionBorders,
   SectionNumerics,
} from '#/modules/Statistics/components/StatisticsComponents/StatisticsWheel/components';
import { transformPercentToCssScale } from '#/modules/Statistics/components/StatisticsComponents/StatisticsWheel/components/Section';
import { prepareStatisticsWheelNumbers } from '#/modules/Statistics/components/StatisticsComponents/StatisticsWheel/helpers';

import { colors } from './constants';
import * as S from './StatisticsWheel.styles';

export const StatisticsWheel = () => {
   const { statistics } = useSelectStatisticsByRange();

   const statisticsWheelNumbers = statistics ? prepareStatisticsWheelNumbers(statistics) : [];
   const maxWheelNumber = Math.max(...statisticsWheelNumbers.map(({ odds }) => odds));
   const ref = useHoverSpecialBet();

   return (
      <S.StatisticsWheel ref={ref} viewBox="0 0 342 340" xmlns="http://www.w3.org/2000/svg">
         <SectionNumerics />
         {autoKeys(
            statisticsWheelNumbers.map(({ number, type, path, odds }) => {
               return (
                  <Section
                     path={path}
                     key={number}
                     number={number}
                     fillColor={type ? colors[type] : colors.grey}
                     scale={transformPercentToCssScale((odds * 100) / maxWheelNumber)}
                  />
               );
            }),
         )}
         <SectionBorders />
      </S.StatisticsWheel>
   );
};
