import { useEffect } from 'react';

import { useEvent } from '../useEvent';

export const useWindowEvent = <Type extends keyof WindowEventMap>(
   type: Type,
   cb: (event: WindowEventMap[Type]) => void,
   options?: AddEventListenerOptions,
): void => {
   const eventCb = useEvent(cb);

   useEffect(() => {
      window.addEventListener(type, eventCb, options);

      return () => window.removeEventListener(type, eventCb, options);
   }, [eventCb]);
};
