import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TRootState } from '#/state/types';

import { ISavedBet } from '../types';
import { lastBetsStorage } from '../storage';

const initialState = {
   lastBets: lastBetsStorage.get() || [],
};

export const lastBetsSlice = createSlice({
   name: 'lastBets',
   initialState,
   reducers: {
      updateLastBet: (state, action) => {
         const lastBets = [...state.lastBets];
         const index = lastBets.findIndex((item) => item.id === action.payload.id);
         const updatedBet = { ...lastBets[index], name: action.payload.name };
         lastBets.splice(index, 1, updatedBet);

         state.lastBets = lastBets;
      },
      updateLastBets: (state, action: PayloadAction<ISavedBet[]>) => {
         state.lastBets = state.lastBets.concat(action.payload);
      },
      removeLastBetById: (state, action: PayloadAction<ISavedBet['id']>) => {
         state.lastBets = state.lastBets.filter((lastBet) => lastBet.id !== action.payload);
      },
   },
});

export const useLastBetsActions = () => {
   const dispatch = useDispatch();
   const { updateLastBets, updateLastBet, removeLastBetById } = lastBetsSlice.actions;

   const handleUpdateLastBet = (lastBetUpdates): void => {
      dispatch(updateLastBet(lastBetUpdates));
   };

   const handleUpdateLastBets = (lastBets: ISavedBet[]): void => {
      dispatch(updateLastBets(lastBets));
   };

   const handleRemoveLastBetById = (lastBetId: ISavedBet['id']): void => {
      dispatch(removeLastBetById(lastBetId));
   };

   return { handleUpdateLastBet, handleUpdateLastBets, handleRemoveLastBetById };
};

export const useLastBetsSelector = () =>
   useSelector(({ lastBets }: TRootState) => lastBets.lastBets);

export const lastBetsMiddleware = (store) => (next) => (action) => {
   const { updateLastBet, updateLastBets, removeLastBetById } = lastBetsSlice.actions;

   next(action);

   // use interceptors after next(action) to get the latest state
   const lastBets: ISavedBet[] = store.getState().lastBets.lastBets;

   if (updateLastBets.type === action.type) {
      lastBetsStorage.set(lastBets);
   }

   if (updateLastBet.type === action.type) {
      lastBetsStorage.set(lastBets);
   }

   if (removeLastBetById.type === action.type) {
      lastBetsStorage.set(lastBets);
   }
};
