import React, { ElementRef, useRef, useState } from 'react';

import { TNullable } from '@/common/types';
import { useOnClickOutside } from '@/common/hooks';
import { ModalAction } from '@/common/ui';

import { useDealerRateState } from '../../state';
import { RatingStars, Comment, AppreciationScreen } from '..';

import { useCloseModalAfterFeedbackLeft } from './hooks';
import * as S from './ModalFeedbackForm.styles';

export interface IModalFeedbackProps {
   appreciationTitle: string;
   feedbackCommentTitle: string;
   handleSendFeedbackAction: (feedbackData: {
      feedbackId: string;
      rating: number;
      comment?: TNullable<string>;
   }) => void;
   modalTitle: string;
   sendButtonTitle: string;
}

export const ModalFeedbackForm = ({
   modalTitle,
   feedbackCommentTitle,
   sendButtonTitle,
   handleSendFeedbackAction,
   appreciationTitle,
}: IModalFeedbackProps) => {
   const {
      feedbackStars,
      feedbackComment,
      feedbackId,
      isModalShown,
      handleSetLeavingFeedbackAvailable,
      handleSetLeavingFeedbackButtonShown,
      handleSetIsModalShown,
   } = useDealerRateState();
   const [isFeedbackLeft, seIsFeedbackLeft] = useState<boolean>(false);
   const modalRef = useRef<ElementRef<'div'>>(null);

   const handleCloseModal = () => {
      handleSetIsModalShown(false);
   };

   useOnClickOutside({
      ref: modalRef,
      cb: handleCloseModal,
      attached: Boolean(handleCloseModal),
   });

   useCloseModalAfterFeedbackLeft({
      isFeedbackLeft,
      handleCloseModal,
   });

   if (!feedbackId) {
      return;
   }

   const handleSendFeedback = () => {
      handleSendFeedbackAction({ feedbackId, rating: feedbackStars, comment: feedbackComment });
      seIsFeedbackLeft(true);
      handleSetLeavingFeedbackAvailable(false);
      handleSetLeavingFeedbackButtonShown(false);
   };

   if (!isModalShown) {
      return;
   }

   const isDealerRated = Boolean(feedbackComment || feedbackStars);

   return (
      <S.ModalFeedbackForm ref={modalRef}>
         <S.Backdrop />
         <ModalAction
            actionButton={{
               dataId: 'send-feedback-button',
               disabled: !isDealerRated,
               title: sendButtonTitle,
               onClick: handleSendFeedback,
            }}
            onClose={handleCloseModal}
            title={modalTitle}
            content={<Comment title={feedbackCommentTitle} isShown={isDealerRated} />}
            message={<RatingStars size="35px" filledStars={feedbackStars} />}
            feedbackScreen={<AppreciationScreen appreciationTitle={appreciationTitle} />}
            isFeedbackScreenShown={isFeedbackLeft}
         />
      </S.ModalFeedbackForm>
   );
};
