import { splitConfig } from 'core/config';

export const generateSplitGrid = (): string => {
   let styles = '';

   splitConfig
      .getIds()
      .filter((_, index) => index % 2 === 0)
      .forEach((row, rowIndex) => {
         row.forEach((id, columnIndex) => {
            return (styles += `
              .cell_${id} {
                 position: absolute;
                 top: calc((var(--bm-big-cell-height) * ${rowIndex}) + ((var(--bm-big-cell-height) - var(--cell-size)) / 2));
                 width: var(--cell-size);
                 height: var(--cell-size);
                 left: calc(var(--bm-small-cell-width) + (var(--bm-big-cell-width) * ${columnIndex}) - var(--half-cell-width));
                 box-shadow: none;
                 background: transparent;
              }`);
         });
      });

   splitConfig
      .getIds()
      .filter((_, i) => i % 2 !== 0)
      .forEach((row, rowIndex) =>
         row.forEach((id, columnIndex) => {
            styles += `
      .cell_${id} {
         position: absolute;
         top: calc(var(--bm-big-cell-height) * ${rowIndex + 1} - var(--half-cell-width));
         width: var(--cell-size);
         height: var(--cell-size);
         left: calc(var(--bm-small-cell-width) + (var(--bm-big-cell-width) * ${columnIndex}) + ((var(--bm-big-cell-width) - var(--cell-size)) / 2));
         background: transparent;
         box-shadow: none;
      }`;
         }),
      );

   return styles;
};

export const generateSplitGridPortrait = (): string => {
   let styles = '';

   splitConfig
      .getIds()
      .reverse()
      .filter((_, index) => index % 2 === 0)
      .forEach((row, rowIndex) =>
         row.forEach((id, columnIndex) => {
            styles += `
         .cell_${id} {
            position: absolute;
            top: calc(var(--bm-small-cell-height) + (var(--bm-big-cell-height) * ${columnIndex}) - var(--half-cell-width));
            width: var(--cell-size);
            height: var(--cell-size);
            left: calc((var(--bm-small-cell-width) * 2) + (var(--bm-big-cell-width) * ${rowIndex}) + ((var(--bm-big-cell-width) - var(--cell-size)) / 2));
            background: transparent;
            box-shadow: none;
         }`;
         }),
      );

   splitConfig
      .getIds()
      .reverse()
      .filter((_, index) => index % 2 !== 0)
      .forEach((row, rowIndex) =>
         row.forEach((id, columnIndex) => {
            styles += `
         .cell_${id} {
            position: absolute;
            top: calc(var(--bm-small-cell-height) + (var(--bm-big-cell-height) * ${columnIndex}) + ((var(--bm-big-cell-height) - var(--cell-size)) / 2));
            width: var(--cell-size);
            height: var(--cell-size);
            left: calc((var(--bm-small-cell-width) * 2) + (var(--bm-big-cell-width) * ${
               rowIndex + 1
            }) - var(--half-cell-width));
            background: transparent;
            box-shadow: none;
         }`;
         }),
      );

   return styles;
};
