export enum ENotificationActionColors {
   Info = '#4FDFFF',
   Error = '#FF0000',
   Success = '#31C440',
   Warning = '#FFBB3D',
}

export const enum ENotificationIcons {
   Info = 'INFO',
   Error = 'ERROR',
   Success = 'SUCCESS',
   Warning = 'WARNING',
}
