import { StreamChat } from 'stream-chat';

interface IConnectChat {
   isAdmin?: boolean;
   physicalTableUUID: string;
   userId: string;
   userName: string;
   userToken: string;
}
export const connectChat = async ({
   userId,
   userName,
   userToken,
   isAdmin,
   physicalTableUUID,
}: IConnectChat) => {
   const user = {
      id: userId,
      name: userName,
      role: 'admin',
      channel_role: 'admin',
   };

   const RECONNECT_TIMEOUT = 20_000;
   // @ts-ignore
   const chat = StreamChat.getInstance(import.meta.env.VITE_CHAT_V2_ID, {
      timeout: RECONNECT_TIMEOUT,
      timeoutErrorMessage: RECONNECT_TIMEOUT,
   });

   await chat.connectUser(user, userToken);

   if (isAdmin) {
      await chat.partialUpdateUser({
         id: userId,
         set: { role: 'admin' },
      });
   }

   const channel = chat.channel('messaging', physicalTableUUID, {
      members: [user.id],
   });

   return {
      chat,
      channel,
   };
};
