export const formatCurrencySign = (currency?: string): string => {
   const formattedCurrency = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'USD',
   })
      .formatToParts(1)
      .find((x) => x.type === 'currency')?.value;

   return formattedCurrency ?? '';
};
