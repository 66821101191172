import { ReactNode, FC } from 'react';

import { isMobileDevice } from '@/common/utils/checkDevice';
import { FadeBox } from '@/common/ui/FadeBox';

import { DraggableChat } from './components/DraggableChat';
import { CloseChat } from './components/CloseChat';
import * as S from './Chat.styles';

interface IChatModalProps {
   children: ReactNode;
   isChatModalOpen: boolean;
   onCloseChatModal: () => void;
}
export const ChatModal: FC<IChatModalProps> = ({ children, isChatModalOpen, onCloseChatModal }) => {
   const chat = (
      <S.Chat>
         <CloseChat onCloseChat={onCloseChatModal} />
         {children}
      </S.Chat>
   );

   return (
      <FadeBox isVisible={isChatModalOpen}>
         {isMobileDevice() ? (
            <div className="message-container chat-mobile">{chat}</div>
         ) : (
            <DraggableChat>
               <div className="message-container">{chat}</div>
            </DraggableChat>
         )}
      </FadeBox>
   );
};
