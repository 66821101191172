import styled from 'styled-components/macro';

export const Banner = styled.div`
  width: 100%;
  height: 100%;

  background: linear-gradient(
    270deg,
    rgba(3, 3, 3, 0) 3.33%,
    rgba(3, 3, 3, 0.8) 32.61%,
    rgba(3, 3, 3, 0.8) 62.17%,
    rgba(3, 3, 3, 0) 94.93%
  );

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(41, 210, 58, 0) 15.03%,
      #29d23a 50%,
      rgba(41, 210, 58, 0) 84.97%
    );
  }

  &:before {
    top: 0;
  }

  &:after {
    top: 100%;
  }
`;
