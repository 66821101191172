import { z } from 'zod';

export const bettingEndTimeSchema = z.object({
   betting_end_time: z.union([z.string(), z.null()]),
   created: z.coerce.date(),
   end_time: z.union([z.number(), z.null()]),
   physical_table_id: z.string(),
   round_number: z.string(),
   start_time: z.string(),
   updated: z.coerce.date(),
   uuid: z.string().uuid(),
});

export type BettingEndEvent = z.infer<typeof bettingEndTimeSchema>;
