import { FC } from 'react';

import * as S from './ChipValue.styles';

interface IChipValue {
  color: string;
  fontSize: number;
  scale: number;
  value: string;
}

export const ChipValue: FC<IChipValue> = ({ color, scale, fontSize, value }) => (
  <>
    <path
      fill={color}
      d="M1.16016 21.7291L5.18113 22.881C3.84737 27.5328 3.84737 32.4603 5.18113 37.1121L1.16016 38.264C2.31673 42.3054 4.31492 46.0564 7.01688 49.2806L12.3617 44.7962C13.223 45.825 14.1729 46.7701 15.1966 47.6316L10.713 52.9775C13.9317 55.68 17.6869 57.6736 21.7276 58.8353L22.8792 54.8136C27.5301 56.1476 32.4567 56.1476 37.1076 54.8136L38.2592 58.8353C42.2998 57.6785 46.0501 55.68 49.2738 52.9775L44.7902 47.6316C45.8188 46.7701 46.7638 45.8201 47.625 44.7962L52.9699 49.2806C55.6719 46.0613 57.6651 42.3054 58.8266 38.264L54.8056 37.1121C56.1394 32.4603 56.1394 27.5328 54.8056 22.881L58.8266 21.7291C57.67 17.6877 55.6719 13.9367 52.9699 10.7124L47.625 15.1968C46.7638 14.168 45.8139 13.2229 44.7902 12.3614L49.2738 7.01556C46.055 4.31307 42.2998 2.31944 38.2592 1.15771L37.1076 5.17944C32.4567 3.84542 27.5301 3.84542 22.8792 5.17944L21.7276 1.15771C17.6869 2.31452 13.9367 4.31307 10.713 7.01556L15.1966 12.3614C14.168 13.2229 13.223 14.173 12.3617 15.1968L7.01688 10.7124C4.31492 13.9367 2.32165 17.6877 1.16016 21.7291Z"
    />
    <path
      d="M1.16273 21.7295C-0.387576 27.1345 -0.387576 32.8643 1.16273 38.2693L5.1837 37.1174C3.84995 32.4656 3.84995 27.5381 5.1837 22.8863L1.16273 21.7295Z"
      fill="white"
    />
    <path
      d="M7.01953 10.7178L12.3644 15.2023C13.2257 14.1735 14.1755 13.2284 15.1992 12.3669L10.7157 7.021C9.37698 8.14334 8.14166 9.37891 7.01953 10.7178Z"
      fill="white"
    />
    <path
      d="M21.7285 1.16295L22.8802 5.18469C27.5311 3.85068 32.4576 3.85068 37.1085 5.18469L38.2602 1.16295C32.8612 -0.387652 27.1275 -0.387652 21.7285 1.16295Z"
      fill="white"
    />
    <path
      d="M49.2746 7.021L44.791 12.3669C45.8196 13.2284 46.7646 14.1784 47.6259 15.2023L52.9707 10.7178C51.8486 9.37891 50.6133 8.14334 49.2746 7.021Z"
      fill="white"
    />
    <path
      d="M58.8257 21.73L54.8047 22.8819C56.1384 27.5337 56.1384 32.4612 54.8047 37.113L58.8257 38.2649C60.376 32.8648 60.376 27.135 58.8257 21.73Z"
      fill="white"
    />
    <path
      d="M52.9707 49.2852L47.6259 44.8008C46.7646 45.8296 45.8147 46.7747 44.791 47.6362L49.2746 52.9821C50.6133 51.8597 51.8486 50.6193 52.9707 49.2852Z"
      fill="white"
    />
    <path
      d="M38.2582 58.8406L37.1066 54.8188C32.4557 56.1529 27.5291 56.1529 22.8782 54.8188L21.7266 58.8406C27.1256 60.3863 32.8592 60.3863 38.2582 58.8406Z"
      fill="white"
    />
    <path
      d="M10.7157 52.9821L15.1992 47.6362C14.1706 46.7747 13.2257 45.8247 12.3644 44.8008L7.01953 49.2852C8.14166 50.6193 9.37698 51.8597 10.7157 52.9821Z"
      fill="white"
    />
    <path
      d="M29.9986 8.37402C18.0539 8.37402 8.37305 18.0567 8.37305 30.0037C8.37305 41.9508 18.0539 51.6335 29.9986 51.6335C41.9433 51.6335 51.6241 41.9508 51.6241 30.0037C51.6192 18.0567 41.9384 8.37402 29.9986 8.37402ZM29.9986 49.9549C18.9791 49.9549 10.0464 41.0204 10.0464 29.9988C10.0464 18.9772 18.9791 10.0428 29.9986 10.0428C41.0181 10.0428 49.9507 18.9772 49.9507 29.9988C49.9458 41.0204 41.0132 49.9549 29.9986 49.9549Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M29.9926 48.3109C40.1055 48.3109 48.3036 40.1128 48.3036 29.9999C48.3036 19.8871 40.1055 11.689 29.9926 11.689C19.8798 11.689 11.6816 19.8871 11.6816 29.9999C11.6816 40.1128 19.8798 48.3109 29.9926 48.3109Z"
      fill="black"
    />
    <S.G
      style={{
        transform: `scale(${scale})`,
        fontSize: `${fontSize}px`,
      }}
    >
      <S.Text x="50%" y="50%" className="chip-text">
        <S.TSpan data-testid="chip-number">{value}</S.TSpan>
      </S.Text>
    </S.G>
  </>
);
