// @ts-nocheck
import styled from 'styled-components/macro';

import { isHoverSupports, orientation } from '@/common/ui/theme/mixins';

export const RaceTrackRangeButton = styled.button`
   background: rgba(0, 0, 0, 0.5);
   border: 2px solid ${({ theme }) => theme.colors.smokeGrey};
   width: var(--racetrack-control-size);
   height: var(--racetrack-control-size);
   color: ${({ theme }) => theme.colors.smokeGrey};
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   transition: 0.2s;

   ${isHoverSupports`
      &:hover {
         border-color: ${({ theme }) => theme.colors?.main};
         box-shadow: ${({ theme }) => `0 0 0.95rem ${theme.colors?.main}`};

         & path {
            fill: ${({ theme }) => theme.colors?.main};
         }
      }
   `}

   &:active {
      border-color: ${({ theme }) => theme.colors?.main};

      & path {
         fill: ${({ theme }) => theme.colors?.main};
      }
   }

   ${orientation.mobileLandscape`
      > svg {
         width: 15px;
         height: 15px;
      }
   `}
`;

export const RaceTrackRange = styled.span`
   color: ${({ theme }) => theme.colors?.white};
   font-size: 35px;
   margin: 10px 0;
`;

export const RaceTrackRangeControlsWrapper = styled.div`
   --racetrack-control-size: 50px;

   width: 60px;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-right: 20px;

   ${orientation.portrait`
   --racetrack-control-size: 25px;

     margin-right: 10px;

     svg {
        transform: scale(0.6);
     }
   `}

   ${orientation.mobileLandscape`
        --racetrack-control-size: 30px;
        margin-right: 10px;
   `}
`;
