import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Notification = () => (
  <ToastContainer position="bottom-right" theme="dark" autoClose={5000} />
);

export const LargeNotification = () => (
  <ToastContainer
    style={{
      transform: 'scale(3)',
      marginRight: 315,
      marginBottom: 100,
    }}
    position="bottom-right"
    theme="dark"
    autoClose={5000}
  />
);

export const showNotification = toast;
