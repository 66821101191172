import { MutableRefObject, useRef, useState } from 'react';

import { SoundControl, FadeInOut } from '@/common/ui';
import { TNullable, TSetTimeout } from '@/common/types';

import { RangeSlider, VolumeTransformer } from '../RangeSlider';
import { SoundVolumeAndRangeController } from '../GameSounds';

import './animations/index.css';
import * as S from './GeneralSoundSlider.styles';

export const GeneralSoundSlider = ({
   handleSliderRangeChanges,
   generalSound,
   isMuted,
   toggleMute,
   localRange,
   playTapSound,
}: {
   handleSliderRangeChanges: (range: number) => void;
   generalSound: SoundVolumeAndRangeController;
   isMuted: boolean;
   toggleMute: () => void;
   localRange: number;
   playTapSound: () => void;
}) => {
   const timeout: MutableRefObject<TNullable<TSetTimeout>> = useRef(null);
   const [showVolumeSlider, setShowVolumeSlider] = useState(false);

   const handleShowSlider = (): void => {
      if (timeout.current) {
         clearTimeout(timeout.current);
      }

      timeout.current = setTimeout((): void => {
         setShowVolumeSlider(true);
      }, 500);
   };

   const handleHideSlider = (): void => {
      if (timeout.current) {
         clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
         setShowVolumeSlider(false);
      }, 500);
   };

   return (
      <S.SoundControlWrapper
         isMuted={isMuted}
         onMouseEnter={handleShowSlider}
         onMouseLeave={handleHideSlider}
      >
         <S.SliderWrapper>
            <FadeInOut
               id="VolumeControl"
               show={showVolumeSlider}
               styles={S.SliderFadeWrapper}
               className={showVolumeSlider ? 'openVolumeControl' : 'closeVolumeControl'}
            >
               <RangeSlider
                  onChange={handleSliderRangeChanges}
                  previousVolume={VolumeTransformer.toVolume(generalSound.previousVolumeRange)}
                  rangeLevel={localRange}
                  testId="generalSound"
                  playTapSound={playTapSound}
               />
            </FadeInOut>
            <SoundControl
               isMuted={isMuted}
               toggleMute={toggleMute}
               variantButton={showVolumeSlider ? 'primary' : 'borderLess'}
            />
         </S.SliderWrapper>
      </S.SoundControlWrapper>
   );
};
