import { GameInfo, PlayersList } from '#/modules';
import { generalVolume } from '#/core/sounds';
import { VolumeControl } from '#/modules/Control/components/VolumeControl';
import { DealerRatingStarsView } from '#/modules/DealerRatingViews/DealerRatingStarsView';

import * as S from './TopColumn.styles';

export const TopColumn = () => (
   <S.TopColumnContainer>
      <S.GameInfoContainer>
         <GameInfo />
         <DealerRatingStarsView />
      </S.GameInfoContainer>
      <PlayersList />
      <VolumeControl generalVolume={generalVolume} />
   </S.TopColumnContainer>
);
