import styled, { css } from 'styled-components';

import { SVGAnimatedPlace, opacityAnimation } from '../../SeatSVG.styles';

export const G = styled(SVGAnimatedPlace)`
  ${({ isBetBehindAnimation }) =>
    isBetBehindAnimation &&
    css`
      animation: ${opacityAnimation} 1s ease-out infinite alternate;
    `};
`;
