import { useMemo } from 'react';

import { ModalNotificationView } from '@/common/ui/ModalNotification/ModalNotificationView';

import { LIVE_STREAM_ID } from '#/modules/LiveStreamPlayer/components/constants';
import { EModalNotificationType } from '#/ui/ModalNotification/constants';

import * as S from './PowerSavingModeModal.styles';

export const PowerSavingModeModal = ({ isShow, onClose }) => {
   if (!isShow) {
      return null;
   }

   const liveStreamDomElement = useMemo(
      () => document.getElementById(LIVE_STREAM_ID) as HTMLVideoElement,
      [LIVE_STREAM_ID],
   );

   const handlePlayLiveStream = () => {
      if (liveStreamDomElement) {
         liveStreamDomElement.play();
      }
   };

   const handleHideLiveStream = () => {
      if (liveStreamDomElement) {
         liveStreamDomElement.style.opacity = '0';
      }
   };

   return (
      <S.LowPowerModeOverlay>
         <ModalNotificationView
            message=""
            type={EModalNotificationType.Warning}
            onClose={() => {
               handleHideLiveStream();
               onClose();
            }}
            title="Video is disabled due to battery-saving mode. Please confirm to enable video streaming."
            actionButton={{
               title: 'Confirm',
               onClick: () => {
                  handlePlayLiveStream();
                  onClose();
               },
            }}
         />
      </S.LowPowerModeOverlay>
   );
};
