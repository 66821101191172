import { Range } from '@/common/types';

import { ROUND_RESULT_NUMBERS_COUNT } from '#/constants';

export type TWinnerNumbersRange = `round-result-${Range<0, typeof ROUND_RESULT_NUMBERS_COUNT>}`;

export const enum ESoundAnnouncementType {
   BettingTimeStart = 'betting-time-start',
   BettingTimeEnd = 'betting-time-end',
   LastBets = 'last-bets',
}
