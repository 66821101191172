// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { applyZIndex, isHoverSupports, orientation } from '@/common/ui/theme/mixins';

export const RaceTrackWrapper = styled.div`
   --racetrack-width: 892px;
   --racetrack-height: 332px;

   &.racetrack {
      .hot {
         > path:first-child,
         > rect:first-child {
            filter: url('#racetrack_filter__hot-number');
         }
      }

      .cold {
         > path:first-child,
         > rect:first-child {
            filter: url('#racetrack_filter__cold-number');
         }
      }
   }

   &.bet-constructor__racetrack {
      .hot {
         > path:first-child,
         > rect:first-child {
            filter: url('#bet-constructor__racetrack_filter__hot-number');
         }
      }

      .cold {
         > path:first-child,
         > rect:first-child {
            filter: url('#bet-constructor__racetrack_filter__cold-number');
         }
      }
   }

   ${applyZIndex('track')};

   position: relative;
   width: var(--racetrack-width);
   height: var(--racetrack-height);

   display: flex;
   align-items: center;
   justify-content: center;

   transform-origin: bottom;
   transition: transform 0.5s;

   ${orientation.portrait`
      --racetrack-width: calc(100% - 30px);
      --racetrack-height: 100%;
      margin-left: 10px;
   `}

   ${orientation.mobileLandscape`
     --racetrack-width: 566px;
     --racetrack-height: 216px;

      & .race-track {
         height: 100%;
      }
   `}

   ${({ isPortrait }) =>
      isPortrait &&
      css`
         --racetrack-width: 100%;
         --racetrack-height: 100%;
      `}

   ${({ showSmallModeRacetrack }) =>
      showSmallModeRacetrack &&
      css`
         transform: scale(0.68043, 0.68071);
         transform-origin: bottom;
      `}

   ${({ isDisabled }) =>
      isDisabled &&
      css`
         transform: scale(0.7);
         transition: transform 0.5s;
         transform-origin: bottom;

         & > svg {
            pointer-events: none;
         }

         ${orientation.portrait`
            transform: scale(var(--betting-container-scale, 0.42));
         `}

         ${orientation.mobileLandscape`
            transform: scale(0.4);
         `}
      `}
      ${({ isCollapsedActivePlayingArea }) =>
      isCollapsedActivePlayingArea &&
      css`
         ${orientation.portrait`
            transform: scale(var(--betting-container-scale, 0.42));

         `}
         ${orientation.mobileLandscape`transform: translate(-38%,18%) scale(0.28)`};
      `}
   && {
      ${isHoverSupports`
         .active {
            path:first-child,
            rect:first-child {
               fill: ${({ theme }) => theme.colors.lightGrey};
               fill-opacity: 0.5;
               stroke: #c5c5c5;
            }
         }
   `}
   }
`;
