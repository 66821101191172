import { TListenerApiDispatchType } from '@/common/types';
import { authAPI, contractValidator, unwrapResponse } from '@/common/services/api';
import { clientApiSlice } from '@/common/services/clientAPI/clientApiSlice';
import { clientAuthSchema } from '@/common/services/api/authAPI/schemes';
import { addSentryTags } from '@/common/tracing/setupSentry';

import { errors } from '#/constants/errors';
import { authApiAdapter } from '#/services/api/authApiSlice/adapter';
import { authSlice } from '#/services/api/authApiSlice/authSlice';
import { balanceSlice } from '#/modules/Balance';
import { TRootState } from '#/state/types';

export const getAuthData = async (dispatch: TListenerApiDispatchType, getState: () => unknown) => {
   const { gameConfig } = getState() as TRootState;
   const { gameTableId, launchToken } = gameConfig.gameConfig;

   const authResponse = await dispatch(
      authAPI(clientApiSlice).endpoints.clientAuth.initiate({
         launchToken,
         gameTableId,
      }),
   );
   const { data, isError, isException } = unwrapResponse(authResponse);

   if (isError || isException) {
      throw new Error(errors.auth.request);
   }

   const { isValid } = contractValidator({
      schema: clientAuthSchema,
      data,
      path: 'auth API',
   });

   if (!isValid) {
      throw new Error(errors.auth.requestValidation);
   }

   const { user, session } = authApiAdapter(data);

   addSentryTags({ userId: user.id, userName: user.username });

   dispatch(authSlice.actions.updateUserAndSession({ user, session }));
   dispatch(balanceSlice.actions.updateInitialBalance(user.totalBalance));
   dispatch(balanceSlice.actions.updateBalanceFromServer(user.totalBalance));
};
