import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '@/common/types';
import { TGameType } from '@/common/entities/Game';

import {
   IGameHistoryDTO,
   IRoundDetailDTO,
} from '#/services/api/gameHistoryApiSlice/adapters/types';
import { TRootState } from '#/state/types';

export interface IHistoryDateState {
   endDate: string;
   startDate: string;
}

export interface IHistoryState {
   gameType: {
      selectedGameType: TNullable<TGameType>;
      gameTypesList: TGameType[];
   };
   historyDate: IHistoryDateState;
   historyList: {
      list: IGameHistoryDTO[];
      pagination: {
         offset: number;
         limit: number;
         hasMoreLoad: boolean;
      };
   };
   historySelectedRound: {
      id: TNullable<string>;
      roundDetail: TNullable<IRoundDetailDTO>;
   };
}

const initialState: IHistoryState = {
   gameType: {
      selectedGameType: null,
      gameTypesList: [],
   },
   historyList: {
      list: [],
      pagination: {
         offset: 0,
         limit: 15,
         hasMoreLoad: true,
      },
   },
   historySelectedRound: {
      id: null,
      roundDetail: null,
   },
   historyDate: {
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
   },
};

export const gameHistorySlice = createSlice({
   name: 'history',
   initialState,
   reducers: {
      setHistoryList: (state, action: PayloadAction<IGameHistoryDTO[]>) => {
         state.historyList.list.push(...action.payload);
      },

      clearHistoryList: (state) => {
         state.historyList = initialState.historyList;
      },

      clearHistoryPagination: (state) => {
         state.historyList.pagination = initialState.historyList.pagination;
      },

      setHistoryHasMoreLoad: (state, action: PayloadAction<boolean>) => {
         state.historyList.pagination.hasMoreLoad = action.payload;
      },

      setHistoryListOffset: (state, action: PayloadAction<number>) => {
         state.historyList.pagination.offset = action.payload;
      },

      setHistorySelectedRoundId: (state, action: PayloadAction<TNullable<string>>) => {
         state.historySelectedRound.id = action.payload;
      },

      setSelectedRoundDetail: (
         state,
         action: PayloadAction<IHistoryState['historySelectedRound']['roundDetail']>,
      ) => {
         state.historySelectedRound.roundDetail = action.payload;
      },

      setHistoryDate: (state, action: PayloadAction<IHistoryDateState>) => {
         state.historyDate = action.payload;
      },

      setGameType: (
         state,
         action: PayloadAction<IHistoryState['gameType']['selectedGameType']>,
      ) => {
         state.gameType.selectedGameType = action.payload;
      },

      setGameTypesList: (
         state,
         action: PayloadAction<IHistoryState['gameType']['gameTypesList']>,
      ) => {
         state.gameType.gameTypesList = action.payload;
      },
   },
});

const useHistoryActions = () => {
   const {
      setHistorySelectedRoundId,
      setHistoryDate,
      setHistoryList,
      setHistoryListOffset,
      setHistoryHasMoreLoad,
      clearHistoryList,
      setSelectedRoundDetail,
      setGameType,
      setGameTypesList,
      clearHistoryPagination,
   } = gameHistorySlice.actions;
   const dispatch = useDispatch();

   const handleSetHistorySelectedRoundId = (roundId: TNullable<string>) => {
      dispatch(setHistorySelectedRoundId(roundId));
   };

   const handleSetHistoryDate = (date: IHistoryDateState) => {
      dispatch(setHistoryDate(date));
      dispatch(clearHistoryPagination());
   };

   const handleSetHistoryList = (historyList: IGameHistoryDTO[]) => {
      dispatch(setHistoryList(historyList));
   };

   const handleClearHistoryList = () => {
      dispatch(clearHistoryList());
   };

   const handleSetHistoryListOffset = (offset: number) => {
      dispatch(setHistoryListOffset(offset));
   };

   const handleSetHistoryHasMoreLoad = (value: boolean) => {
      dispatch(setHistoryHasMoreLoad(value));
   };

   const handleSetHistorySelectedRoundDetail = (
      roundInfo: IHistoryState['historySelectedRound']['roundDetail'],
   ) => {
      dispatch(setSelectedRoundDetail(roundInfo));
   };

   const handleSetGameType = (gameTypeId: IHistoryState['gameType']['selectedGameType']) => {
      dispatch(setGameType(gameTypeId));
   };

   const handleSetGameTypesList = (gameTypesList: IHistoryState['gameType']['gameTypesList']) => {
      dispatch(setGameTypesList(gameTypesList));
   };

   return {
      handleSetHistorySelectedRoundId,
      handleSetHistoryDate,
      handleSetHistoryList,
      handleSetHistoryListOffset,
      handleSetHistoryHasMoreLoad,
      handleClearHistoryList,
      handleSetHistorySelectedRoundDetail,
      handleSetGameType,
      handleSetGameTypesList,
   };
};

const useGameTypes = () => {
   return useSelector((state: TRootState) => state.history.gameType);
};

const useHistorySelectedRound = () => {
   return useSelector(
      createSelector(
         (state: TRootState) => state.history.historySelectedRound,
         (historySelectedRound) => historySelectedRound,
      ),
   );
};

const useHistoryList = () => {
   return useSelector(
      createSelector(
         (state: TRootState) => state.history.historyList,
         (historyList) => historyList,
      ),
   );
};

const useHistoryDate = () => {
   return useSelector(
      createSelector(
         (state: TRootState) => state.history.historyDate,
         (historyDate) => historyDate,
      ),
   );
};

export const useHistoryState = () => {
   return {
      ...useHistoryActions(),
      useGameTypes,
      useHistorySelectedRound,
      useHistoryList,
      useHistoryDate,
   };
};
