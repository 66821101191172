import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { dealerRateSlice } from '@/common/modules/DealerRating/state';

import { TDealerRatingUpdateEvent } from '#/services/sockets/hooks/event-hooks/useSocketEvents/useDealerRatingUpdateEvent/schemes';

export const dealerRatingUpdateEventMiddleware = createListenerMiddleware();
export const dealerRatingUpdateEventAction =
   createAction<TDealerRatingUpdateEvent>('dealerRatingUpdate');

dealerRatingUpdateEventMiddleware.startListening({
   actionCreator: dealerRatingUpdateEventAction,
   effect: async ({ payload }, listenerApi) => {
      const { dispatch } = listenerApi;
      const { setDealerRate } = dealerRateSlice.actions;
      const { dealerRating } = payload;

      dispatch(setDealerRate(dealerRating));
   },
});
