import { memo } from 'react';

import { ETimerType } from '@/common/modules/CountDown';

import { NextRoundNotification } from '#/modules';
import { BetConfirmedNotification } from '#/modules/BetConfirmedNotification/BetConfirmedNotification';
import { useCheckBettingTime } from '#/modules/ChipTray/hooks';
import { SwitchCountDown } from '#/modules/SwitchCountDown';
import { TrackComponent } from '#/modules/Track/TrackComponent';
import { BettingPanelGroup } from '#/layout/componentsGroup';

import { WinResult } from '../../../WinResult/WinResult';

import * as S from './CenterColumn.styles';

const Notifications = memo(() => (
   <S.Notifications>
      <NextRoundNotification />
      <BetConfirmedNotification />
   </S.Notifications>
));

export const CenterColumn = () => {
   const isBettingTime = useCheckBettingTime();

   return (
      <S.CenterColumnContainer>
         <Notifications />
         <WinResult />
         <S.CountDown isVisible={isBettingTime}>
            <SwitchCountDown timerType={ETimerType.HORIZONTAL} />
         </S.CountDown>
         <TrackComponent />
         <S.BettingPanelGroupContainer isDisabled={!isBettingTime} isVisible={isBettingTime}>
            <BettingPanelGroup />
         </S.BettingPanelGroupContainer>
      </S.CenterColumnContainer>
   );
};
