import { useChannelActionContext, useMessageContext } from 'stream-chat-react';

import { ReplyIconSVG } from './assets';

export const ReplyIcon = () => {
   const { message } = useMessageContext();
   const { openThread } = useChannelActionContext();

   return (
      <div
         className="reply-icon"
         style={{
            marginBottom: 2,
         }}
         onClick={() => openThread(message)}
      >
         <ReplyIconSVG />
      </div>
   );
};
