// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex, orientation } from '@/common/ui/theme/mixins';

export const Tooltip = styled.div`
   ${applyZIndex('biggest')};

   position: absolute;
   top: 5px;
   left: 0;
   display: none;

   & > div {
      scale: var(--scale);
      font-weight: 500;
      font-size: 14px;
      transform-origin: left top;

      background: rgba(45, 45, 45, 0.8);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 15px;

      color: ${({ theme }) => theme.colors?.main};

      ${orientation.portrait`
         scale: none;
      `}
   }
`;

export const TooltipWrapper = styled.div`
   ${orientation.portrait`
      width: inherit;
      height: inherit;
   `}
`;
