import { FC, ReactNode } from 'react';

import { FadeBox } from '@/common/ui/FadeBox';

import * as S from './WinnerListContainer.styles';

interface IWinnerListContainerProps {
   children: ReactNode;
   isShowWinnerList: boolean;
}

export const WinnerListContainer: FC<IWinnerListContainerProps> = ({
   isShowWinnerList,
   children,
}) => (
   <FadeBox isVisible={isShowWinnerList}>
      <S.WinnerListChildrenContainer>{children}</S.WinnerListChildrenContainer>
   </FadeBox>
);
