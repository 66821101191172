import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TRootState } from '#/state/types';

import { ERangeLevelLimitEnum } from '../constants';

export interface IRaceTrackState {
   rangeLevel: number;
}

const initialState: IRaceTrackState = {
   rangeLevel: 0,
};

export const raceTrackSlice = createSlice({
   name: 'raceTrack',
   initialState,
   reducers: {
      increaseRangeLevel: (state): void => {
         if (state.rangeLevel < ERangeLevelLimitEnum.Max) {
            state.rangeLevel = state.rangeLevel + 1;
         }
      },
      decreaseRangeLevel: (state): void => {
         if (state.rangeLevel > ERangeLevelLimitEnum.Min) {
            state.rangeLevel = state.rangeLevel - 1;
         }
      },
   },
});

export const useRaceTrackActions = () => {
   const dispatch = useDispatch();
   const { increaseRangeLevel, decreaseRangeLevel } = raceTrackSlice.actions;

   const handleIncreaseRangeLevel = (): void => {
      dispatch(increaseRangeLevel());
   };

   const handleDecreaseRangeLevel = (): void => {
      dispatch(decreaseRangeLevel());
   };

   return { handleIncreaseRangeLevel, handleDecreaseRangeLevel };
};

export const useRaceTrackSelector = () => useSelector(({ raceTrack }: TRootState) => raceTrack);
