import { FC } from 'react';

import { IPlayTapSound } from '@/common/entities';

import { Control, TControlProps } from '../../Control';
import { EControl } from '../../constants';

export const MenuControlView: FC<Pick<TControlProps, 'isActive' | 'onClick'> & IPlayTapSound> = ({
  isActive,
  onClick,
  playTapSound,
}) => (
  <Control
    isActive={isActive}
    dataTestId="menu-control"
    variant={EControl.Menu}
    onClick={onClick}
    playTapSound={playTapSound}
  />
);
