import { memo, useState } from 'react';

import { isMobileDevice } from '@/common/utils/checkDevice';
import { Volume, VolumeTransformer } from '@/common/modules/RangeSlider';
import { GeneralSoundSlider } from '@/common/modules/GeneralSoundSlider';

import { GeneralSoundControl } from '../../helpers';

import * as S from './VolumeControl.styles';

export const VolumeControl = memo(
   ({
      generalVolume,
      playTapSound,
   }: {
      generalVolume: GeneralSoundControl;
      playTapSound: () => void;
   }) => {
      const generalSound = generalVolume.generalSound;

      const [localRange, setLocalRange] = useState(
         VolumeTransformer.toRange(generalSound.currentVolume),
      );

      const isMuted = generalVolume.isMuted();

      const toggleMute = (): void => {
         // for this one control firstly the sound's volume should be changed and then the sound should be played
         generalVolume.toggleMute();
         playTapSound();
         setLocalRange(VolumeTransformer.toRange(generalSound.currentVolume));
      };

      const handleSliderRangeChanges = (range: number): void => {
         generalVolume.handleSliderRangeChanges(range);
         setLocalRange(VolumeTransformer.toRange(generalSound.currentVolume));
      };

      if (isMobileDevice()) {
         return (
            <S.VolumeControlWrapperMobile onClick={toggleMute}>
               <Volume isOn={!isMuted} />
            </S.VolumeControlWrapperMobile>
         );
      }

      return (
         <GeneralSoundSlider
            handleSliderRangeChanges={handleSliderRangeChanges}
            generalSound={generalSound}
            isMuted={isMuted}
            toggleMute={toggleMute}
            localRange={localRange}
            playTapSound={playTapSound}
         />
      );
   },
);

VolumeControl.displayName = 'VolumeControl';
