import { safeJsonParse } from '../safeJsonParse';

export interface Storage {
   get(): string;
   set(value: string): void;
}

export class LocalStorage implements Storage {
   private readonly name: string;

   constructor(name: string) {
      this.name = name;
   }

   public get() {
      try {
         const storedValue = window.localStorage.getItem(this.name);
         return storedValue ? safeJsonParse(storedValue) : '';
      } catch (e) {
         return [];
      }
   }

   public set(items): void {
      window.localStorage.setItem(this.name, JSON.stringify(items));
   }

   public clear(): void {
      window.localStorage.removeItem(this.name);
   }
}
