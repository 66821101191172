import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TRootState } from '#/state/types';

interface IProgressScreenState {
   isShowVideoLoader: boolean;
}

const initialState: IProgressScreenState = {
   isShowVideoLoader: true,
};

export const videoLoadingSlice = createSlice({
   name: 'videoLoading',
   initialState,
   reducers: {
      updateVideoLoading: (state, action: PayloadAction<boolean>): void => {
         state.isShowVideoLoader = action.payload;
      },
   },
});

const useVideoLoadingActions = () => {
   const dispatch = useDispatch();
   const { updateVideoLoading } = videoLoadingSlice.actions;

   const handleUpdateVideoLoading = (isVideoLoaded: boolean): void => {
      dispatch(updateVideoLoading(isVideoLoaded));
   };

   return { handleUpdateVideoLoading };
};

const useVideoLoadingSelector = (): IProgressScreenState =>
   useSelector(({ videoLoading }: TRootState) => videoLoading);

export const useVideoLoading = () => {
   const { isShowVideoLoader } = useVideoLoadingSelector() ?? {};
   const { handleUpdateVideoLoading } = useVideoLoadingActions();

   const handleShowVideoLoader = (): void => {
      handleUpdateVideoLoading(true);
   };

   const handleHideVideoLoader = (): void => {
      handleUpdateVideoLoading(false);
   };

   return {
      isShowVideoLoader,
      handleUpdateVideoLoading,
      handleShowVideoLoader,
      handleHideVideoLoader,
   };
};
