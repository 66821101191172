import styled from 'styled-components/macro';

interface IBox {
  display?: string;
  gap?: string;
  justifyContent?: string;
  m?: string;
  mb?: string;
  mt?: string;
  rotateLeft?: boolean;
  rotateRight?: boolean;
  width?: string;
}

const rotation = {
  rotateRight: 'rotate(90deg)',
  rotateLeft: 'rotate(-90deg)',
};

export const Box = styled.div<IBox>`
  transform: ${({ rotateLeft }: { rotateLeft: 'rotateLeft' | 'rotateRight' }) =>
      rotation[rotateLeft]}
    translate3d(0, 0, 0);
  overflow: ${({ isHidden }: { isHidden: boolean }) => (isHidden ? 'hidden' : 'visible')};
  margin-top: ${({ mt }: { mt: string }) => mt};
  margin-bottom: ${({ mb }: { mb: boolean }) => mb};
  margin: ${({ m }: { m: string }) => m};
  height: ${({ height }: { height: string }) => height};
  display: ${({ display }: { display: string }) => display};
  width: ${({ width }: { width: string }) => width};
  justify-content: ${({ justifyContent }: { justifyContent: string }) => justifyContent};
  gap: ${({ gap }: { gap: string }) => gap};
  flex-direction: ${({ flexDirection }: { flexDirection: string }) => flexDirection};
  align-items: ${({ alignItems }: { alignItems: string }) => alignItems};
`;

Box.defaultProps = {
  isHidden: false,
};
