import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { handleSendBets } from './sendBetsMiddleware/effect';

export const sendBetsMiddleware = createListenerMiddleware();

const sendBetsActionType = 'sendBets';
export const sendBetsAction = createAction(sendBetsActionType);

sendBetsMiddleware.startListening({
   type: sendBetsActionType,
   effect: handleSendBets,
});
