import { useEffect, useState } from 'react';

import { renderCount } from './renderCount';

export const useLoadingGrid = (duration: number = 500) => {
   const [isLoaded, setIsLoaded] = useState(false);

   useEffect(() => {
      if (renderCount.getRenderCount() > 0) {
         const timeout = setTimeout(() => {
            setIsLoaded((prev) => !prev);
         }, duration);

         return () => {
            clearTimeout(timeout);
         };
      }
      // we set isLoaded immediatly during first render
      setIsLoaded(true);
      renderCount.incrementRenderCount();
   }, []);

   return isLoaded;
};
