import { timeSync } from '@/common/helpers';

const _countDownByDuration = (duration: number, cb): void => {
   let remaining = duration;
   // eslint-disable-next-line func-names
   const intervalId = setInterval(function () {
      remaining--;
      if (remaining < 0) {
         cb();
         clearInterval(intervalId);
      }
   }, 1000);
};

export const fireWinNumberEvent = ({ timestampMs, winNumber, handleSetWinNumber }): void => {
   if (timestampMs) {
      timeSync.getServerTimeDelta().then((delta) => {
         const now: number = Date.now() + delta;
         const durationInSeconds: number = (timestampMs - now) / 1000;
         _countDownByDuration(durationInSeconds, () => handleSetWinNumber(winNumber));
      });
   } else {
      handleSetWinNumber(winNumber);
   }
};
