// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const CollapsedTrackWrapper = styled.div`
   position: relative;
   display: flex;
   align-items: flex-end;

   transition:
      transform 0.7s,
      margin-bottom 0.7s;

   ${({ isCollapsedTrack }) =>
      isCollapsedTrack &&
      css`
         // hack for PMX-3044
         transform: scale(0.001);
      `}

   ${orientation.mobileLandscape`
      --collapsed-track-width: 566px;
      --collapsed-track-height: 215px;

      width: var(--collapsed-track-width);
      height: var(--collapsed-track-height);
      min-height: var(--collapsed-track-height);
      transform-origin: 105% 65%;
      margin-bottom: 65px;

      ${({ isDisabled }) =>
         isDisabled &&
         css`
            margin-bottom: 0;
         `}
   `}

   ${orientation.desktopLandscape`
      transform-origin: 140% 80%;
   `}
`;
