import { BetConfig } from 'core/config/BetConfig';
import { rouletteRedColorNumbers } from 'core/roulette/colors';

import { ETypeBet } from '#/state/features/bets';

class RedBetConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.RED;
   readonly config = [
      {
         id: 42,
         value: 'bettingMap.red',
         numbers: rouletteRedColorNumbers,
      },
   ];
}

export const redConfig = new RedBetConfig();
