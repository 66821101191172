// @ts-nocheck
import styled from 'styled-components/macro';

import { FadeBox } from '@/common/ui';
import { applyZIndex } from '@/common/ui/theme/mixins';

export const RightColumnContainer = styled.div`
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   padding-bottom: 5px;
`;

export const RightControlsContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: space-between;
   margin-top: 24px;
`;
export const BottomContainer = styled.div``;

export const CollapseTrackControl = styled.div`
   margin: 22px 0 0 12px;
`;

export const BettingPanelGroupContainer = styled(FadeBox)`
   ${applyZIndex('bettingTimer')}

   position: relative;
   width: 100%;
   display: flex;
   flex-direction: column-reverse;
   align-items: space-between;
   justify-content: center;
   transition: opacity 500ms;
   gap: 12px;
   pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};

   > :not(:last-child) {
      margin-top: 5px;
   }
`;
