// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const Percentage = styled.div`
   margin-bottom: 3px;
   color: #ddd;
   font-size: 16px;
   text-align: ${({ direction }) => direction};

   ${orientation.mobileLandscape`
      font-size: 12px;
   `}
`;

export const CenterStatisticsBarContainer = styled.div`
   position: relative;
   width: 100%;
   height: 35px;
   display: flex;
   align-items: center;
   justify-content: center;
   color: #fff;
   background: ${({ color }) => color};

   ${orientation.mobileLandscape`
      height: 22px;
   `}
`;

export const CenterStatisticsBarWrapper = styled.div`
   width: ${({ percentage }) => percentage}%;
   min-width: min-content;
   height: 35px;
   cursor: pointer;
   transition: width 0.3s linear;
   text-align: center;

   & span {
      ${orientation.mobileLandscape`
         font-size: 12px;
      `}
   }
`;
