import { memo } from 'react';

import { ErrorBoundary } from '@/common/dev/ErrorBoundary';
import { useCurrentTime } from '@/common/hooks';
import { GameInfoView } from '@/common/ui';
import { formatDate } from '@/common/utils';
import { timeSync } from '@/common/helpers';

import { usePlayersCount } from '#/state/features/playersCount';
import { useTranslation } from '#/translates';
import { useRoundState } from '#/services';

export const GameInfo = memo(() => {
   const { count } = usePlayersCount();
   const { t } = useTranslation();
   const { roundInfo, dealerName } = useRoundState();

   const getTimeValue = () =>
      formatDate({
         date: useCurrentTime({ getServerTimeDelta: timeSync.getServerTimeDelta }),
         options: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hourCycle: 'h24',
         },
      });

   return (
      <ErrorBoundary>
         <GameInfoView
            title={t('gameId')}
            gameId={roundInfo?.number ?? ''}
            getTimeValue={getTimeValue}
            playersCount={count}
            dealerName={dealerName}
         />
      </ErrorBoundary>
   );
});
