import { getNearElements } from '#/modules/Track/RaceTrack/helpers/getActiveElementsIndexes';

import { getWinNumberColors } from '../getColors';
import { winNumberDTO } from '../winNumberDTO';

export const getWinNumbers = (winNumber: string) => {
   const [current, previousNumber, nextNumber] = getNearElements({
      rangeLevel: 1,
      currentNumber: Number(winNumberDTO(winNumber)),
   });
   const [currentColor, leftColors, rightColors] = getWinNumberColors([
      current,
      previousNumber,
      nextNumber,
   ]);

   return {
      leftColors,
      nextNumber,
      rightColors,
      currentColor,
      previousNumber,
   };
};
