import { Rank, Suit } from '@/common/cardGames/ui/Card/types';

export const CARD_SUITS = {
   [Suit.CLUBS]: {
      topIcon:
         'M46.83 24.48C45.76 25.97 43.97 26.94 41.94 26.94C38.67 26.94 36.01 24.41 36.01 21.28C36.01 18.15 38.57 15.72 41.76 15.63C41.25 14.8 40.95 13.83 40.95 12.79C40.95 9.66 43.6 7.13 46.88 7.13C50.16 7.13 52.81 9.66 52.81 12.79C52.81 13.83 52.51 14.79 52 15.63C55.19 15.72 57.75 18.21 57.75 21.28C57.75 24.35 55.1 26.94 51.82 26.94C49.8 26.94 48.02 25.97 46.95 24.5M41.94 31.57C44.9 29 46.89 25.92 46.89 24.88C46.89 25.92 48.86 28.98 51.83 31.57C50.75 31.57 42.94 31.59 41.95 31.57',
      bottomIcon:
         'M16.17 63.52C17.24 62.03 19.03 61.06 21.06 61.06C24.33 61.06 26.99 63.59 26.99 66.72C26.99 69.85 24.43 72.28 21.24 72.37C21.75 73.2 22.05 74.17 22.05 75.21C22.05 78.34 19.4 80.87 16.12 80.87C12.84 80.87 10.19 78.34 10.19 75.21C10.19 74.17 10.49 73.21 11 72.37C7.81 72.28 5.25 69.79 5.25 66.72C5.25 63.65 7.9 61.06 11.18 61.06C13.2 61.06 14.98 62.03 16.05 63.5M21.06 56.43C18.1 59 16.11 62.08 16.11 63.12C16.11 62.08 14.14 59.02 11.17 56.43C12.25 56.43 20.06 56.41 21.05 56.43',
   },
   [Suit.DIAMONDS]: {
      topIcon: 'M55.5999 19.58L46.1199 32.78L36.6299 19.58L46.1199 6.38L55.5999 19.58Z',
      bottomIcon: 'M7.3999 70.42L16.8799 57.22L26.3699 70.42L16.8799 83.62L7.3999 70.42Z',
   },
   [Suit.HEARTS]: {
      topIcon:
         'M46.12 10.22C47.26 8.56998 49.2 7.47998 51.41 7.47998C54.91 7.47998 57.75 10.22 57.75 13.6C57.75 14.91 57.28 16.1 56.6 17.12C56.04 17.96 46.12 29.01 46.12 30.94C46.12 29.01 36.2 17.96 35.64 17.12C34.95 16.1 34.49 14.91 34.49 13.6C34.49 10.22 37.33 7.47998 40.83 7.47998C43.04 7.47998 44.98 8.56998 46.12 10.22Z',
      bottomIcon:
         'M16.88 77.7801C15.74 79.4301 13.8 80.5201 11.59 80.5201C8.09 80.5201 5.25 77.7801 5.25 74.4001C5.25 73.0901 5.72 71.9001 6.4 70.8801C6.96 70.0401 16.88 58.9901 16.88 57.0601C16.88 58.9901 26.8 70.0401 27.36 70.8801C28.05 71.9001 28.51 73.0901 28.51 74.4001C28.51 77.7801 25.67 80.5201 22.17 80.5201C19.96 80.5201 18.02 79.4301 16.88 77.7801Z',
   },
   [Suit.SPADES]: {
      topIcon:
         'M56.7099 17.66C56.1999 16.84 47.2199 8.04002 47.2199 6.15002C47.2199 8.04002 38.2399 16.83 37.7299 17.66C37.1099 18.66 36.6899 19.82 36.6899 21.1C36.6899 24.4 39.2599 27.08 42.4299 27.08C44.4299 27.08 46.1899 26.02 47.2199 24.41C48.2499 26.02 50.0099 27.08 52.0099 27.08C55.1799 27.08 57.7499 24.4 57.7499 21.1C57.7499 19.82 57.3299 18.66 56.7099 17.66ZM52.0099 31.57C49.1399 29.06 47.2299 26.09 47.2299 25.09C47.2299 26.09 45.3099 29.08 42.4399 31.57C43.3999 31.59 50.9699 31.57 52.0099 31.57Z',
      bottomIcon:
         'M6.29 70.34C6.8 71.16 15.78 79.96 15.78 81.85C15.78 79.96 24.76 71.17 25.27 70.34C25.89 69.34 26.31 68.18 26.31 66.9C26.31 63.6 23.74 60.92 20.57 60.92C18.57 60.92 16.81 61.98 15.78 63.59C14.75 61.98 12.99 60.92 10.99 60.92C7.82 60.92 5.25 63.6 5.25 66.9C5.25 68.18 5.67 69.34 6.29 70.34ZM10.99 56.43C13.86 58.94 15.77 61.91 15.77 62.91C15.77 61.91 17.69 58.92 20.56 56.43C19.6 56.41 12.03 56.43 10.99 56.43Z',
   },
};

export const CARD_VALUES = {
   [Rank.ACE]: {
      topIcon:
         'M13.0399 6.48999H20.0599L28.1499 32.58H21.9599L20.3699 26.38H12.4799L10.8899 32.58H4.93994L13.0399 6.48999ZM13.6699 21.77H19.1799L18.5899 19.37C17.8799 16.77 17.1599 13.69 16.4899 10.97H16.3299C15.6999 13.73 15.0199 16.77 14.3099 19.37L13.6799 21.77H13.6699Z',

      bottomIcon:
         'M49.9599 83.5099H42.9399L34.8499 57.4199H41.0399L42.6299 63.6199H50.5199L52.1099 57.4199H58.0599L49.9699 83.5099H49.9599ZM49.3299 68.2299H43.8199L44.4099 70.6299C45.1199 73.2299 45.8399 76.3099 46.5099 79.0299H46.6699C47.2999 76.2699 47.9799 73.2299 48.6899 70.6299L49.3199 68.2299H49.3299Z',
   },
   [Rank.KING]: {
      topIcon:
         'M7.8999 6.48999H13.7299V17.17H13.8899L21.5799 6.48999H28.0099L20.1999 16.89L29.4399 32.58H23.0499L16.7499 21.57L13.7299 25.57V32.58H7.8999V6.48999Z',

      bottomIcon:
         'M55.1001 83.5099H49.2701V72.8299H49.1101L41.4201 83.5099H34.9901L42.8001 73.1099L33.5601 57.4199H39.9501L46.2501 68.4299L49.2701 64.4299V57.4199H55.1001V83.5099Z',
   },
   [Rank.QUEEN]: {
      topIcon:
         'M12.76 19.38C12.76 14.14 15 11 18.55 11C22.1 11 24.34 14.14 24.34 19.38C24.34 24.62 22.1 28.01 18.55 28.01C15 28.01 12.76 24.66 12.76 19.38ZM31.09 35.05L30.05 30.74C29.29 30.98 28.29 31.18 27.17 31.18C26.58 31.18 25.99 31.13 25.41 31.01C28.51 28.74 30.37 24.72 30.37 19.38C30.37 10.84 25.58 5.88 18.54 5.88C11.5 5.88 6.70996 10.8 6.70996 19.38C6.70996 27.96 11.46 33.07 18.44 33.12C20.49 34.86 23.2 35.93 26.48 35.93C28.48 35.93 30.12 35.53 31.07 35.04',

      bottomIcon:
         'M50.2399 70.6201C50.2399 75.8601 47.9999 79.0001 44.4499 79.0001C40.8999 79.0001 38.6599 75.8601 38.6599 70.6201C38.6599 65.3801 40.8999 61.9901 44.4499 61.9901C47.9999 61.9901 50.2399 65.3401 50.2399 70.6201ZM31.9099 54.9501L32.9499 59.2601C33.7099 59.0201 34.7099 58.8201 35.8299 58.8201C36.4199 58.8201 37.0099 58.8701 37.5899 58.9901C34.4899 61.2601 32.6299 65.2801 32.6299 70.6201C32.6299 79.1601 37.4199 84.1201 44.4599 84.1201C51.4999 84.1201 56.2899 79.2001 56.2899 70.6201C56.2899 62.0401 51.5399 56.9301 44.5599 56.8801C42.5099 55.1401 39.7999 54.0701 36.5199 54.0701C34.5199 54.0701 32.8799 54.4701 31.9299 54.9601',
   },
   [Rank.JACK]: {
      topIcon:
         'M5.47998 28.34L9.44998 25.38C10.4 27.14 11.75 27.98 13.06 27.98C15.16 27.98 16.27 26.9 16.27 23.42V6.48999H22.1V23.89C22.1 28.93 19.52 33.05 13.81 33.05C9.87998 33.05 7.26998 31.49 5.47998 28.33',

      bottomIcon:
         'M57.5199 61.6599L53.5499 64.6199C52.5999 62.8599 51.2499 62.0199 49.9399 62.0199C47.8399 62.0199 46.7299 63.0999 46.7299 66.5799V83.4999H40.8999V66.0999C40.8999 61.0599 43.4799 56.9399 49.1899 56.9399C53.1199 56.9399 55.7299 58.4999 57.5199 61.6599Z',
   },
   [Rank.TEN]: {
      topIcon:
         'M15.4001 32.58H9.5701V13.13H4.8501V9.48995C7.5901 8.96995 9.3301 8.24995 11.1201 7.16995H15.4001V32.58ZM16.3401 19.78C16.3401 11.14 19.9501 6.69995 25.3401 6.69995C30.7301 6.69995 34.3401 11.14 34.3401 19.78C34.3401 28.42 30.7301 33.06 25.3401 33.06C19.9501 33.06 16.3401 28.42 16.3401 19.78ZM28.8301 19.78C28.8301 12.82 27.3201 11.26 25.3401 11.26C23.3601 11.26 21.8501 12.82 21.8501 19.78C21.8501 26.74 23.4001 28.5 25.3401 28.5C27.2801 28.5 28.8301 26.7 28.8301 19.78Z',

      bottomIcon:
         'M47.5999 57.4199H53.4299V76.8699H58.1499V80.5099C55.4099 81.0299 53.6699 81.7499 51.8799 82.8299H47.5999V57.4199ZM46.6599 70.2199C46.6599 78.8599 43.0499 83.2999 37.6599 83.2999C32.2699 83.2999 28.6599 78.8599 28.6599 70.2199C28.6599 61.5799 32.2699 56.9399 37.6599 56.9399C43.0499 56.9399 46.6599 61.5799 46.6599 70.2199ZM34.1699 70.2199C34.1699 77.1799 35.6799 78.7399 37.6599 78.7399C39.6399 78.7399 41.1499 77.1799 41.1499 70.2199C41.1499 63.2599 39.5999 61.4999 37.6599 61.4999C35.7199 61.4999 34.1699 63.2999 34.1699 70.2199Z',
   },
   [Rank.NINE]: {
      topIcon:
         'M6.54994 29.02L9.63994 25.5C10.4699 26.46 12.0599 27.3 13.5299 27.3C16.3499 27.3 18.5299 25.3 18.7299 19.38C17.4199 21.06 15.2399 22.06 13.6899 22.06C9.40994 22.06 6.18994 19.58 6.18994 14.22C6.18994 8.86001 9.99994 5.70001 14.5599 5.70001C19.4399 5.70001 24.1199 9.38001 24.1199 18.3C24.1199 27.78 19.1999 32.06 13.9299 32.06C10.5999 32.06 8.13994 30.62 6.54994 29.02ZM18.6099 15.38C18.0899 11.38 16.4699 10.1 14.6799 10.1C12.8899 10.1 11.5099 11.34 11.5099 14.22C11.5099 16.9 12.9399 17.9 14.8399 17.9C16.1499 17.9 17.6199 17.18 18.6099 15.38Z',

      bottomIcon:
         'M56.4499 58.98L53.3599 62.5C52.5299 61.54 50.9399 60.7 49.4699 60.7C46.6499 60.7 44.4699 62.7 44.2699 68.62C45.5799 66.94 47.7599 65.94 49.3099 65.94C53.5899 65.94 56.8099 68.42 56.8099 73.78C56.8099 79.14 52.9999 82.3 48.4399 82.3C43.5599 82.3 38.8799 78.62 38.8799 69.7C38.8799 60.22 43.7999 55.94 49.0699 55.94C52.3999 55.94 54.8599 57.38 56.4499 58.98ZM44.3899 72.62C44.9099 76.62 46.5299 77.9 48.3199 77.9C50.1099 77.9 51.4899 76.66 51.4899 73.78C51.4899 71.1 50.0599 70.1 48.1599 70.1C46.8499 70.1 45.3799 70.82 44.3899 72.62Z',
   },
   [Rank.EIGHT]: {
      topIcon:
         'M6.51 25.09C6.51 22.01 8.41 20.13 10.79 18.85V18.69C8.85 17.21 7.42 15.37 7.42 12.69C7.42 8.37 10.79 5.69 15.47 5.69C20.15 5.69 23.12 8.45 23.12 12.65C23.12 15.09 21.57 17.05 19.83 18.21V18.37C22.25 19.69 24.19 21.65 24.19 25.01C24.19 29.01 20.62 32.05 15.27 32.05C10.23 32.05 6.5 29.21 6.5 25.09M18.76 24.81C18.76 22.57 16.62 21.73 13.45 20.37C12.34 21.41 11.51 22.85 11.51 24.41C11.51 26.65 13.3 27.97 15.4 27.97C17.34 27.97 18.77 26.93 18.77 24.81M18.41 13.05C18.41 11.09 17.26 9.77 15.36 9.77C13.81 9.77 12.54 10.73 12.54 12.69C12.54 14.77 14.29 15.77 16.86 16.77C17.85 15.61 18.41 14.37 18.41 13.05Z',

      bottomIcon:
         'M56.4901 62.91C56.4901 65.99 54.5901 67.87 52.2101 69.15V69.31C54.1501 70.79 55.5801 72.63 55.5801 75.31C55.5801 79.63 52.2101 82.31 47.5301 82.31C42.8501 82.31 39.8801 79.55 39.8801 75.35C39.8801 72.91 41.4301 70.95 43.1701 69.79V69.63C40.7501 68.31 38.8101 66.35 38.8101 62.99C38.8101 58.99 42.3801 55.95 47.7301 55.95C52.7701 55.95 56.5001 58.79 56.5001 62.91M44.2401 63.19C44.2401 65.43 46.3801 66.27 49.5501 67.63C50.6601 66.59 51.4901 65.15 51.4901 63.59C51.4901 61.35 49.7001 60.03 47.6001 60.03C45.6601 60.03 44.2301 61.07 44.2301 63.19M44.5901 74.95C44.5901 76.91 45.7401 78.23 47.6401 78.23C49.1901 78.23 50.4601 77.27 50.4601 75.31C50.4601 73.23 48.7101 72.23 46.1401 71.23C45.1501 72.39 44.5901 73.63 44.5901 74.95Z',
   },
   [Rank.SEVEN]: {
      topIcon:
         'M17.9001 11.13H6.59009V6.16998H24.1201V9.76998C18.2901 16.89 17.4601 21.65 17.0201 31.58H11.1501C11.6301 23.14 13.1301 17.78 17.8901 11.13',

      bottomIcon:
         'M45.1001 76.87H56.4001V81.83H38.8701V78.23C44.7001 71.11 45.5301 66.35 45.9701 56.42H51.8401C51.3601 64.86 49.8601 70.22 45.1001 76.87Z',
   },
   [Rank.SIX]: {
      topIcon:
         'M6.46997 20.4499C6.46997 10.9699 11.35 6.68994 16.66 6.68994C19.99 6.68994 22.41 8.08994 24 9.72994L20.95 13.2499C20.08 12.2899 18.49 11.4499 17.02 11.4499C14.24 11.4499 12.02 13.4499 11.82 19.3699C13.13 17.6899 15.31 16.6899 16.86 16.6899C21.18 16.6899 24.36 19.1699 24.36 24.5299C24.36 29.8899 20.55 33.0499 15.99 33.0499C11.11 33.0499 6.46997 29.3699 6.46997 20.4499ZM19.04 24.5299C19.04 21.8499 17.65 20.8499 15.75 20.8499C14.44 20.8499 12.97 21.5699 11.94 23.3699C12.46 27.3699 14.12 28.6499 15.87 28.6499C17.62 28.6499 19.04 27.4099 19.04 24.5299Z',
      bottomIcon:
         'M56.5299 69.55C56.5299 79.03 51.6499 83.3099 46.3399 83.3099C43.0099 83.3099 40.5899 81.9099 38.9999 80.2699L42.0499 76.75C42.9199 77.7099 44.5099 78.55 45.9799 78.55C48.7599 78.55 50.9799 76.5499 51.1799 70.6299C49.8699 72.3099 47.6899 73.3099 46.1399 73.3099C41.8199 73.3099 38.6399 70.83 38.6399 65.47C38.6399 60.11 42.4499 56.95 47.0099 56.95C51.8899 56.95 56.5299 60.63 56.5299 69.55ZM43.9599 65.47C43.9599 68.15 45.3499 69.15 47.2499 69.15C48.5599 69.15 50.0299 68.4299 51.0599 66.6299C50.5399 62.6299 48.8799 61.3499 47.1299 61.3499C45.3799 61.3499 43.9599 62.59 43.9599 65.47Z',
   },
   [Rank.FIVE]: {
      topIcon:
         'M5.76 28.5L8.38 24.78C9.89 26.18 11.67 27.3 13.81 27.3C16.39 27.3 18.05 25.94 18.05 23.34C18.05 20.74 16.42 19.42 14.08 19.42C12.61 19.42 11.86 19.74 10.35 20.7L7.73 19.02L8.44 6.17999H22.72V11.14H13.48L13.04 15.9C13.99 15.46 14.82 15.3 15.86 15.3C20.18 15.3 23.87 17.78 23.87 23.18C23.87 28.58 19.55 32.06 14.63 32.06C10.51 32.06 7.73 30.5 5.75 28.5',
      bottomIcon:
         'M57.2399 59.5L54.6199 63.22C53.1099 61.82 51.3299 60.7 49.1899 60.7C46.6099 60.7 44.9499 62.06 44.9499 64.66C44.9499 67.26 46.5799 68.58 48.9199 68.58C50.3899 68.58 51.1399 68.26 52.6499 67.3L55.2699 68.98L54.5599 81.82H40.2799V76.86H49.5199L49.9599 72.1C49.0099 72.54 48.1799 72.7 47.1399 72.7C42.8199 72.7 39.1299 70.22 39.1299 64.82C39.1299 59.42 43.4499 55.94 48.3699 55.94C52.4899 55.94 55.2699 57.5 57.2499 59.5',
   },
   [Rank.FOUR]: {
      topIcon:
         'M16.5101 25.5H5.6001V21.46L14.8001 6.17004H21.9401V21.01H24.8401V25.49H21.9401V31.57H16.5101V25.49V25.5ZM16.5101 21.02V16.9C16.5101 15.26 16.6701 12.82 16.7501 11.22H16.5901C15.9601 12.62 15.2401 14.06 14.5301 15.5L11.2001 21.02H16.5101Z',
      bottomIcon:
         'M46.4899 62.5H57.3999V66.54L48.1999 81.82H41.0599V66.98H38.1599V62.5H41.0599V56.42H46.4899V62.5ZM46.4899 66.98V71.1C46.4899 72.74 46.3299 75.18 46.2499 76.78H46.4099C47.0399 75.38 47.7599 73.94 48.4699 72.5L51.7999 66.98H46.4899Z',
   },
   [Rank.THREE]: {
      topIcon:
         'M5.71997 28.46L8.41997 24.74C9.92997 26.22 11.79 27.3 13.93 27.3C16.39 27.3 18.01 26.22 18.01 24.3C18.01 22.06 16.7 20.66 11.23 20.66V16.5C15.75 16.5 17.18 15.14 17.18 13.1C17.18 11.34 16.07 10.3 14.09 10.3C12.31 10.3 10.96 11.14 9.36997 12.54L6.42997 8.94001C8.72997 6.94001 11.27 5.70001 14.36 5.70001C19.56 5.70001 23.05 8.22001 23.05 12.66C23.05 15.3 21.54 17.14 18.85 18.3V18.46C21.75 19.3 23.85 21.38 23.85 24.66C23.85 29.38 19.57 32.06 14.49 32.06C10.4 32.06 7.54997 30.62 5.71997 28.46Z',
      bottomIcon:
         'M57.2799 59.54L54.5799 63.26C53.0699 61.78 51.2099 60.7 49.0699 60.7C46.6099 60.7 44.9899 61.78 44.9899 63.7C44.9899 65.94 46.2999 67.34 51.7699 67.34V71.5C47.2499 71.5 45.8199 72.86 45.8199 74.9C45.8199 76.66 46.9299 77.7 48.9099 77.7C50.6899 77.7 52.0399 76.86 53.6299 75.46L56.5699 79.06C54.2699 81.06 51.7299 82.3 48.6399 82.3C43.4399 82.3 39.9499 79.78 39.9499 75.34C39.9499 72.7 41.4599 70.86 44.1499 69.7V69.54C41.2499 68.7 39.1499 66.62 39.1499 63.34C39.1499 58.62 43.4299 55.94 48.5099 55.94C52.5999 55.94 55.4499 57.38 57.2799 59.54Z',
   },
   [Rank.TWO]: {
      topIcon:
         'M6.31004 28.22C12.74 22.1 17.26 17.74 17.26 14.14C17.26 11.66 15.87 10.3 13.69 10.3C11.87 10.3 10.48 11.54 9.21004 12.9L6.04004 9.74001C8.54004 7.06001 10.88 5.70001 14.49 5.70001C19.45 5.70001 22.82 8.90001 22.82 13.78C22.82 18.06 18.85 22.62 14.77 26.94C16.04 26.78 17.78 26.62 18.97 26.62H24.05V31.58H6.31004V28.22Z',
      bottomIcon:
         'M56.6899 59.78C50.2599 65.9 45.74 70.26 45.74 73.86C45.74 76.34 47.13 77.7 49.31 77.7C51.13 77.7 52.52 76.46 53.79 75.1L56.9599 78.26C54.4599 80.94 52.1199 82.3 48.5099 82.3C43.5499 82.3 40.18 79.1 40.18 74.22C40.18 69.94 44.15 65.38 48.2299 61.06C46.9599 61.22 45.2199 61.38 44.0299 61.38H38.95V56.42H56.68V59.78H56.6899Z',
   },
};
