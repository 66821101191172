// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { isMobileDevice } from '@/common/utils/checkDevice';

export const CircleLoaderWrapper = styled.div`
   position: relative;
   width: 128px;
   height: 128px;
   ${isMobileDevice() && 'width: 78px; height: 78px;'};
`;

export const CircleLoader = styled.svg`
   --percent: ${({ percent }) => css`
      ${percent || percent === 0 ? percent : 0}
   `};
   transform: rotate(-90deg);
`;

export const CubeSpinner = styled.img`
   display: inline-block;
   max-width: 100%;
   max-height: 100%;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   color: ${({ theme }) => theme.colors?.white};
   border-radius: 50%;
   ${isMobileDevice() && 'max-width: 52px; max-height: 52px;'};
`;
