import { ReactNode } from 'react';

import { autoKeys } from '../../../../utils/autoKeys';
import { CloseButton } from '../../../CloseButton';

import * as S from './LimitsTable.styles';

export const LimitsTable = ({
  titles,
  rows,
  handleHideLimitsTable,
}: {
  rows: Array<ReactNode[]>;
  titles: string[];
  handleHideLimitsTable: () => void;
}) => {
  const renderTitle = (title: (typeof titles)[number]) => <S.Th>{title}</S.Th>;
  const renderRow = (row: (typeof rows)[number]) => (
    <S.Tr>
      {autoKeys(
        row.map((field) => (
          <S.Td>
            <>{field}</>
          </S.Td>
        )),
      )}
    </S.Tr>
  );

  return (
    <S.TableWrapper>
      <S.Close>
        <CloseButton onClick={handleHideLimitsTable} />
      </S.Close>
      <S.Table>
        <S.Thead>
          <S.Tr>{autoKeys(titles.map(renderTitle))}</S.Tr>
        </S.Thead>
        <S.Tbody>{autoKeys(rows.map(renderRow))}</S.Tbody>
      </S.Table>
    </S.TableWrapper>
  );
};
