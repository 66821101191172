// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const Image = styled.img`
   position: fixed;
   z-index: -1;
   top: 0;
   filter: blur(50px);
   width: 100%;
   height: 100%;

   ${orientation.mobileLandscape`
   	 display: none;
   `}

   ${orientation.portrait`
   	 display: none;
   `}
`;
