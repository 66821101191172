const formatCurrencySettings = {
   useGrouping: true,
   minimumFractionDigits: 0,
};

export const formatCurrencyWithSign = (
   number: number,
   currency?: string,
   isCompact?: boolean,
): string =>
   new Intl.NumberFormat('en-US', {
      ...formatCurrencySettings,
      style: 'currency',
      currency: currency ?? 'USD',
      notation: isCompact ? 'compact' : 'standard',
   }).format(number);
