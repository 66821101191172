// @ts-nocheck
import styled from 'styled-components/macro';

export const HorizontalCountDownMessage = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   font-size: var(--timer-message-fz);
   font-weight: 600;
   text-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
   text-transform: uppercase;
   white-space: nowrap;
   will-change: transform;
`;
