import { useCallback, useState } from 'react';

import { BettingMapRefType } from './types';

export const useBettingMapRef = () => {
   const [bettingMapRef, setBettingMapRef] = useState<BettingMapRefType>(null);

   const bettingMapRefCallback = useCallback((node: typeof bettingMapRef) => {
      if (node) {
         setBettingMapRef(node);
      }
   }, []);

   return { bettingMapRefCallback, bettingMapRef };
};
