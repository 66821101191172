import { ETypeBet } from '#/state/features/bets';

class CallBets {
   private generateZeroConfig() {
      return {
         [ETypeBet.JEU_ZERO]: [
            { id: 97, numbers: [0, 3], type: ETypeBet.SPLIT },
            { id: 101, numbers: [12, 15], type: ETypeBet.SPLIT },
            { id: 26, numbers: [26], type: ETypeBet.STRAIGHT },
            { id: 132, numbers: [32, 35], type: ETypeBet.SPLIT },
         ],
      };
   }

   private generateOrphelinsConfig() {
      return {
         [ETypeBet.ORPHELINS]: [
            { id: 99, numbers: [6, 9], type: ETypeBet.SPLIT },
            { id: 126, numbers: [14, 17], type: ETypeBet.SPLIT },
            { id: 127, numbers: [17, 20], type: ETypeBet.SPLIT },
            { id: 156, numbers: [31, 34], type: ETypeBet.SPLIT },
            { id: 1, numbers: [1], type: ETypeBet.STRAIGHT },
         ],
      };
   }

   private generateTiersConfig() {
      return {
         [ETypeBet.TIERS]: [
            { id: 123, numbers: [5, 8], type: ETypeBet.SPLIT },
            { id: 136, numbers: [10, 11], type: ETypeBet.SPLIT },
            { id: 150, numbers: [13, 16], type: ETypeBet.SPLIT },
            { id: 116, numbers: [23, 24], type: ETypeBet.SPLIT },
            { id: 106, numbers: [27, 30], type: ETypeBet.SPLIT },
            { id: 108, numbers: [33, 36], type: ETypeBet.SPLIT },
         ],
      };
   }

   private generateVoisinsConfig() {
      return {
         [ETypeBet.VOISIN]: [
            { id: 147, numbers: [4, 7], type: ETypeBet.SPLIT },
            { id: 101, numbers: [12, 15], type: ETypeBet.SPLIT },
            { id: 103, numbers: [18, 21], type: ETypeBet.SPLIT },
            { id: 152, numbers: [19, 22], type: ETypeBet.SPLIT },
            { id: 73, numbers: [0, 2, 3], type: ETypeBet.STREET },
            { id: 73, numbers: [0, 2, 3], type: ETypeBet.STREET },
            { id: 66, numbers: [25, 26, 28, 29], type: ETypeBet.CORNER },
            { id: 66, numbers: [25, 26, 28, 29], type: ETypeBet.CORNER },
            { id: 132, numbers: [32, 35], type: ETypeBet.SPLIT },
         ],
      };
   }

   public getConfig() {
      return {
         ...this.generateZeroConfig(),
         ...this.generateOrphelinsConfig(),
         ...this.generateTiersConfig(),
         ...this.generateVoisinsConfig(),
      };
   }
}

export const callBetsConfig = new CallBets().getConfig();
