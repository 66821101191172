import { iterateObject } from 'helpers';

import { TTotalBets } from '#/state/features/bets';

export const getTotalBetsAmount = (totalBets: TTotalBets): number => {
   let sum = 0;

   iterateObject(totalBets, (_, value) => (sum += value));

   return sum;
};
