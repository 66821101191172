import { TNullable } from '@/common/types';
import { Rank, Suit } from '@/common/cardGames/ui/Card/types';

import { TUserId } from '../entities';

import { EPlayerAction } from './services/constants';
import { EHandResult, EBJSeatStatus, EBJBetType, EdDoubleBetTypes } from './constants';
import { IBlackjackBetAmount } from './adapters/betAmountAdapter/types';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TUnstableServerType = any;
export type TSeatBetAmount = number;
export type TScore = string;

export type TCard = {
   suit: Suit;
   rank: Rank;
};

export type THand = {
   cards: TCard[];
   handScore: TScore;
   isPlaying: boolean;
   handResult: TNullable<EHandResult>;
};

export type TSeatBetType =
   | EBJBetType.Ante
   | EBJBetType.BetBehind
   | EBJBetType.PerfectPairsBet
   | EBJBetType.TopThreeBet
   | EBJBetType.TwentyOnePlusThreeBet;

export type TSeatBet = {
   type: EBJBetType.Split | EdDoubleBetTypes | TSeatBetType;
   amount: TNullable<TSeatBetAmount>;
   uuid: TNullable<string>;
   chips: string[];
};

export type TSeatPosition = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type TSeat = {
   seatPosition: TSeatPosition;
   playerId: TNullable<TUserId>;
   playerName: TNullable<string>;
   isSeatTaken: boolean;
   isSplit: boolean;
   isCurrentPlayerSeat: boolean;
   seatStatus: EBJSeatStatus;
   seatBets: TSeatBet[];
   playerHands: THand[];
   seatEarlyDecision: TNullable<EPlayerAction>;
   insurance: {
      isInsuranceAccepted: boolean;
      isInsuranceRejected: boolean;
      isInsuranceNotChosen: boolean;
   };
   seatLastDecision: TNullable<EPlayerAction>;
   seatBetAmountByBetType: IBlackjackBetAmount;
};

export type TDealerSeat = {
   hand: {
      isPlaying: boolean;
      cards: (TCard & { isFaceDown: boolean })[];
      score: TScore;
      isBusted: boolean;
   };
};

export type TDealerSeatPosition = 7;

export type TPlaceBet = {
   chips: string[];
   type: TSeatBetType;
   token: string;
   currency: string;
};

export const enum ESeatBetsConfig {
   bj21Plus3 = 'bj21Plus3',
   bjBetBehind = 'bjBetBehind',
   bjPerfectPairs = 'bjPerfectPairs',
   bjTop3 = 'bjTop3',
   bjMaxSeatsPerUser = 'bjMaxSeatsPerUser',
}

export type TSeatBetsConfig = {
   [ESeatBetsConfig.bj21Plus3]: boolean;
   [ESeatBetsConfig.bjBetBehind]: boolean;
   [ESeatBetsConfig.bjPerfectPairs]: boolean;
   [ESeatBetsConfig.bjTop3]: boolean;
   [ESeatBetsConfig.bjMaxSeatsPerUser]: number;
};
