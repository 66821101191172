import { autoKeys } from '@/common/utils';

import { WinnerListTitle } from '../WinnerListTitle/WinnerListTitle';
import { IWinnerList } from '../types';
import { splitWinnerList } from '../../hooks/utils';
import { formatAmount } from '../../utils/formatAmount';

import { HorizontalTransparentMask } from './components/HorizontalTransparentMask/HorizontalTransparentMask';
import * as S from './VerticalWinnerList.styles';

export const VerticalWinnerList = ({
   winnerList,
   currencySign,
   animationTriggers,
}: IWinnerList) => {
   if (!winnerList.length) {
      return null;
   }

   const { listOfAmounts, listOfUsernames } = splitWinnerList(winnerList);

   return (
      <>
         <WinnerListTitle winnerList={winnerList} />
         <S.SpaceLine />
         <HorizontalTransparentMask>
            <S.Wrapper>
               <S.VerticalWinnerListInner {...animationTriggers}>
                  <S.AmountList>
                     {autoKeys(
                        listOfAmounts.map((amount) => (
                           <S.Amount>{formatAmount({ amount, currencySign })}</S.Amount>
                        )),
                     )}
                  </S.AmountList>
                  <S.UsernameList>
                     {autoKeys(
                        listOfUsernames.map((username) => <S.Username>{username}</S.Username>),
                     )}
                  </S.UsernameList>
               </S.VerticalWinnerListInner>
            </S.Wrapper>
         </HorizontalTransparentMask>
      </>
   );
};
