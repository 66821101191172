import { z } from 'zod';

import { LoginType } from '@/common/studio/auth/constants/LoginType';
import { ROLES } from '@/common/studio/auth/types';

export const loginSchema = z.object({
   dealerId: z.string().uuid(),
   dealerName: z.string(),
   dealerRole: z.union([
      z.literal(ROLES.GAME_PRESENTER),
      z.literal(ROLES.SHIFT_MANAGER),
      z.literal(ROLES.SMCP_MANAGER),
   ]),
   loginType: z.union([z.literal(LoginType.local), z.literal(LoginType.remote)]),
   sessionId: z.string().uuid(),
   sessionSecret: z.string(),
});

export type Login = z.infer<typeof loginSchema>;
