// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '../../../../ui/theme/mixins';

export const HelpContainer = styled.div`
  --help-title-fz: 16px;
  --help-content-fz: 14px;
  --help-pt: 55px;
  --help-pb: 30px;

  width: calc(100% + var(--sidebar-padding-right));
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: var(--help-pt) 0 var(--help-pb);
  height: calc(100% - var(--help-pt));

  ${orientation.mobileLandscape`
      --help-title-fz: 14px;
      --help-content-fz: 12px;
   `};

  ${orientation.portrait`
      --help-title-fz: 14px;
      --help-content-fz: 12px;
      --help-pt: 28px;
      --help-wrapper-padding: 0;
   `};
`;
