import { TBetType, TDoubleBet, TExcludeBetFromBetList } from '#/state/features/bets/types';
import { isDoubleBetType } from '#/state/features/bets/type-guards';

export const extractDoubleBet = (bets: (TBetType | TDoubleBet)[]) => {
   const betsWithExtractedDouble: TExcludeBetFromBetList<TDoubleBet>[] = [];

   for (const bet of bets) {
      if (isDoubleBetType(bet)) {
         betsWithExtractedDouble.push(...bet.partialDoubled);
      } else {
         betsWithExtractedDouble.push(bet);
      }
   }

   return betsWithExtractedDouble;
};
