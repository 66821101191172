import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { EStreamingUpdateMode } from '@/common/modules/LiveStreamPlayer/constants';

import { TRootState } from '#/state/types';

export interface IVideoState {
   mode: EStreamingUpdateMode;
   mute: boolean;
   volume: number;
}

const initialState: IVideoState = {
   volume: 0,
   mute: true,
   mode: EStreamingUpdateMode.Auto,
};

export const videoSlice = createSlice({
   name: 'video',
   initialState,
   reducers: {
      setMute: (state, action: PayloadAction<boolean>): void => {
         state.mute = action.payload;
      },
      setVideoVolume: (state, action: PayloadAction<number>): void => {
         state.volume = action.payload;
      },
      updateMode: (state, action: PayloadAction<EStreamingUpdateMode>): void => {
         state.mode = action.payload;
      },
   },
});

export const useVideoActions = () => {
   const dispatch = useDispatch();
   const { updateMode, setVideoVolume, setMute } = videoSlice.actions;

   const handleUpdateVolumeState = (volume: number): void => {
      dispatch(setVideoVolume(volume));
   };

   const handleUpdateMode = (mode: EStreamingUpdateMode): void => {
      dispatch(updateMode(mode));
   };

   const handleSetMute = (mute: boolean): void => {
      dispatch(setMute(mute));
   };

   return {
      handleSetMute,
      handleUpdateMode,
      handleUpdateVolumeState,
   };
};

export const useVideoSelector = () => useSelector((state: TRootState) => state.video);

export const useVideo = () => {
   const { mode, volume, mute } = useVideoSelector() ?? {};
   const { handleSetMute, handleUpdateMode, handleUpdateVolumeState } = useVideoActions();

   return {
      mode,
      volume,
      mute,
      handleSetMute,
      handleUpdateMode,
      handleUpdateVolumeState,
   };
};
