import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { dealerRateSlice } from '@/common/modules/DealerRating/state';

import { TCasinoGameTypeSettingsUpdateEvent } from '#/services/sockets/hooks/event-hooks/useSocketEvents/useUserCasinoGameTypeSettingsUpdate/schemes';

export const casinoGameTypeSettingsEventMiddleware = createListenerMiddleware();
export const casinoGameTypeSettingsEventAction = createAction<TCasinoGameTypeSettingsUpdateEvent>(
   'casinoGameTypeSettingsUpdated',
);

casinoGameTypeSettingsEventMiddleware.startListening({
   actionCreator: casinoGameTypeSettingsEventAction,
   effect: async ({ payload }, listenerApi) => {
      const { dispatch } = listenerApi;
      const { setIsFeedbackEnabledOnCasinoGameTypeLevel } = dealerRateSlice.actions;
      const { isFeedbackEnabledOnCasinoGameTypeLevel } = payload;

      dispatch(setIsFeedbackEnabledOnCasinoGameTypeLevel(isFeedbackEnabledOnCasinoGameTypeLevel));
   },
});
