import { useCallback } from 'react';

import { gameSettings } from '@/common/helpers';

import { useGameConfigStore } from '#/services/api/gameConfigApiSlice/state';
import { handleGoToLobby } from '#/helpers';

export const useLobbyRedirect = () => {
   const { gameConfig } = useGameConfigStore();
   const mainLobbyRedirectUrl = gameConfig.lobbyRedirectUrl;
   const fallbackLobbyRedirectUrl = gameSettings.lobbyRedirectUrl;

   return useCallback(() => {
      handleGoToLobby(mainLobbyRedirectUrl ?? fallbackLobbyRedirectUrl);
   }, [mainLobbyRedirectUrl, handleGoToLobby, fallbackLobbyRedirectUrl]);
};
