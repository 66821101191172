import { useMemo } from 'react';

import { EGameState, useGameStateSelector } from '@/common/state/gameState';

import { useSelectStatisticsByRange } from '#/modules/Statistics/components/InnerStatistics';

import { THotAndColdTypeCellObject, getCellTypesFromStatistic } from '../../helpers';

export const useHotAndCold = (): THotAndColdTypeCellObject => {
   const { statistics } = useSelectStatisticsByRange();
   const gameState = useGameStateSelector();
   const isBettingTime = [EGameState.MAKE_BET, EGameState.LAST_BETS].includes(gameState);

   return useMemo(
      () => (isBettingTime ? getCellTypesFromStatistic(statistics?.statisticsByPockets) : {}),
      [isBettingTime, statistics?.statisticsByPockets],
   );
};
